import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();
const EMPTY_MAP = fromJS({});

const DEFAULT_STATE = fromJS({
  jobsLoading: false,
  jobsLoaded: 0,
  jobsList: EMPTY_SET,
  totalJobs: 0,
  filters: EMPTY_MAP,
  zipCode: undefined,
  distance: '15-miles',
  applicationsLoading: false,
  applicationsLoaded: 0,
  savedList: EMPTY_SET,
  appliedList: EMPTY_SET,
  applicationsList: EMPTY_SET,
  page: 0,
});

const CandidateJobListingsReducer = (state = DEFAULT_STATE, action) => {
  let applicationList;
  let newState;
  let jobId;
  switch (action.type) {
    case ACTIONS.SET_CANDIDATE_JOB_LISTINGS_DISTANCE:
      if (state.get('distance') !== action.distance) {
        return state.merge({
          jobsLoaded: false,
          distance: action.distance,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_JOB_LISTINGS_ZIPCODE:
      if (state.get('zipCode') !== action.zipCode) {
        return state.merge({
          jobsLoaded: false,
          zipCode: action.zipCode,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_JOB_LISTINGS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          jobsLoaded: false,
          // applicationsLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_JOB_LISTINGS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          jobsLoaded: false,
          applicationsLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_JOB_LISTINGS_PAGE:
      if (state.get('page') !== action.page) {
        return state.merge({
          page: action.page,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_CANDIDATE.request:
      return state.set('jobsLoading', true);
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_CANDIDATE.success:
      return state.merge({
        jobsList: OrderedSet(action.response.result),
        jobsLoading: false,
        jobsLoaded: Date.now(),
        totalJobs: action.response.totalJobs,
      });
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_CANDIDATE.failure:
      return state.merge({
        jobsLoading: false,
        jobsLoaded: false,
        jobsList: EMPTY_SET,
      });

    case ACTIONS.FETCH_SAVED_JOB_POSTINGS_BY_IDS.success:
      return state.merge({
        jobsList: state.get('jobsList').union(OrderedSet(action.response.result)),
        savedList: state.get('savedList').union(OrderedSet(action.response.result)),
      });

    case ACTIONS.LIST_FILTERED_JOB_APPLICATIONS_FOR_CANDIDATE.request:
      return state.set('applicationsLoading', true);
    case ACTIONS.LIST_FILTERED_JOB_APPLICATIONS_FOR_CANDIDATE.success:
      applicationList = OrderedSet(action.response.result).map((id) => action.response.entities.candidateApplications[id]);
      return state.merge({
        savedList: applicationList.filter((app) => app.candidateState === 'c_saved').map((app) => app.jobPosting.jobPostingId),
        appliedList: applicationList.filter((app) => !['c_saved', 'c_deleted'].includes(app.candidateState)).map((app) => app.jobPosting.jobPostingId),
        applicationsLoading: false,
        applicationsLoaded: Date.now(),
        applicationsList: applicationList.map((app) => app.id),
      });
    case ACTIONS.LIST_FILTERED_JOB_APPLICATIONS_FOR_CANDIDATE.failure:
      return state.merge({
        applicationsLoading: false,
        applicationsLoaded: false,
        savedList: EMPTY_SET,
        appliedList: EMPTY_SET,
      });

    case ACTIONS.CREATE_CANDIDATE_APPLICATION.success:
      jobId = action.response.entities.candidateApplications[action.response.result].jobPosting.jobPostingId;
      return state.update('savedList', (saved) => saved.add(jobId));

    case ACTIONS.TRANSITION_CANDIDATE_APPLICATION_C.success:
      newState = action.response.entities.candidateApplications[action.response.result].candidateState;
      jobId = action.response.entities.candidateApplications[action.response.result].jobPosting.jobPostingId;
      if (newState === 'c_saved') {
        return state.merge({
          savedList: state.get('savedList').add(jobId),
          appliedList: state.get('appliedList').remove(jobId),
        });
      }
      return state.merge({
        savedList: state.get('savedList').remove(jobId),
        appliedList: state.get('appliedList').add(jobId),
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getZipcode = (state) => state.get('zipCode') || DEFAULT_STATE.get('zipCode');
export const getDistance = (state) => state.get('distance') || DEFAULT_STATE.get('distance');
export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search');
export const getLoading = (state) => state.get('jobsLoading');
export const getLoaded = (state) => state.get('jobsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getJobIds = (state) => state.get('jobsList') || DEFAULT_STATE.get('jobsList');
export const getTotalJobs = (state) => state.get('totalJobs') || DEFAULT_STATE.get('totalJobs');

export const getSavedIds = (state) => state.get('savedList') || DEFAULT_STATE.get('savedList');
export const getSavedLoaded = (state) => state.get('applicationsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getSavedLoading = (state) => state.get('applicationsLoading');
export const getAppliedIds = (state) => state.get('appliedList') || DEFAULT_STATE.get('appliedList');
export const getAppliedLoaded = (state) => state.get('applicationsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getAppliedLoading = (state) => state.get('applicationsLoading');

export const getApplicationIds = (state) => state.get('applicationsList');
export const getPage = (state) => state.get('page') || DEFAULT_STATE.get('page');

export default CandidateJobListingsReducer;
