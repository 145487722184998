import styled from 'styled-components';
// import theme from '../../../common/theme';

const ChallengeChecklistStyles = styled.div`
  h4 {
    margin: 20px 10px;
  }
  img {
    height: 75px;
  }
  .challenges {
    margin-bottom: 20px;
  }
`;

export default ChallengeChecklistStyles;
