import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { List } from 'immutable';
import { SelectWrapper } from '../../styles/form';
import theme from '../../theme';

const propTypes = {
  input: PropTypes.shape({ name: PropTypes.string, onChange: PropTypes.func }).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string.isRequired, value: PropTypes.string.isRequired, subLabel: PropTypes.string }))
    .isRequired,
  isMulti: PropTypes.bool,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.string, warning: PropTypes.bool }),
  onInputChange: PropTypes.func,
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  noOptionsMessage: PropTypes.string,
  isSpecialStyles: PropTypes.bool,
};

const defaultProps = {
  isMulti: false,
  required: false,
  meta: {},
  onInputChange: undefined,
  inputValue: undefined,
  placeholder: undefined,
  noOptionsMessage: undefined,
  isSpecialStyles: false,
};

const customStyles = {
  container: (provided, _state) => ({
    ...provided,
    marginTop: '2px',
  }),
  menu: (provided, _state) => ({
    ...provided,
    backgroundColor: '#fff',
    zIndex: '100',
  }),
};

const specialStyles = {
  container: (provided, _state) => ({
    ...provided,
    marginTop: '0px',
  }),
  control: (provided, _state) => ({
    ...provided,
    height: '40px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
  }),
  menu: (provided, _state) => ({
    ...provided,
    backgroundColor: '#fff',
    zIndex: '100',
  }),
  indicatorsContainer: (provided, _state) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (provided, _state) => ({
    ...provided,
    padding: '2px 14px',
  }),
  singleValue: (provided, _state) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.87)',
    cursor: 'text',
    fontSize: '1rem',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: '400',
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
  }),
};

const Option = ({ data: { label, subLabel }, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <components.Option {...props}>
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div>{label}</div>
      {subLabel && <div style={{ color: `${theme.color.gray}`, fontSize: '12px' }}>{subLabel}</div>}
    </div>
  </components.Option>
);
Option.propTypes = {
  props: PropTypes.instanceOf(List),
  data: PropTypes.shape({
    label: PropTypes.string,
    subLabel: PropTypes.string,
  }),
};
Option.defaultProps = {
  props: undefined,
  data: {
    label: undefined,
    subLabel: undefined,
  },
};

const ReactSelect = ({
  input,
  options,
  label,
  required,
  isMulti,
  meta: { touched, error, warning },
  onInputChange,
  inputValue,
  placeholder,
  noOptionsMessage,
  isSpecialStyles,
}) => (
  <SelectWrapper>
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label htmlFor={input.name}>
      <span>
        {label} {required ? '*' : ''}
      </span>
      <Select
        styles={isSpecialStyles ? specialStyles : customStyles}
        onChange={(selections) => {
          let changeValue = null;
          if (Array.isArray(selections)) {
            changeValue = selections.map((selection) => selection.value);
          } else if (selections != null) {
            changeValue = selections.value;
          }
          input.onChange(changeValue);
        }}
        isMulti={isMulti}
        options={options}
        className={touched && error ? 'field-error' : 'no-error'}
        components={{ Option }}
        onInputChange={onInputChange}
        inputValue={inputValue}
        placeholder={placeholder || 'Select...'}
        noOptionsMessage={() => noOptionsMessage || 'No options'}
      />
      {touched && ((error && <span className="error select-error">{error}</span>) || (warning && <span className="warning">{warning}</span>))}
    </label>
  </SelectWrapper>
);

ReactSelect.propTypes = propTypes;
ReactSelect.defaultProps = defaultProps;

export default ReactSelect;
