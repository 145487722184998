import styled from 'styled-components';
import theme from '../../../common/theme';

const DashboardStyles = styled.div`
  .inboxHeader {
    display: flex;
    flex-direction: column;

    @media ${theme.query.desktopMin} {
      flex-direction: row;
      justify-content: space-between;
      margin-top: 30px;
      margin-bottom: ${theme.padding.small};
    }

    button {
      order: -1;
      margin-top: ${theme.padding.base};

      @media ${theme.query.desktopMin} {
        order: 2;
        margin-top: initial;
      }
    }
  }

  .tabberWrapper {
    margin-top: 50px;
    border: solid 1px ${theme.color.grayLight};
    border-radius: 5px;
    .MuiTabs-flexContainer {
      @media ${theme.query.laptopMax} {
        flex-wrap: wrap;
      }
    }

    .MuiTab-root {
      line-height: 1.2;

      @media ${theme.query.laptopMax} {
        flex: initial;
        overflow: initial;
        max-width: initial;
        min-height: initial;
        height: initial;
        padding: ${theme.padding.xsmall} ${theme.padding.small};
        margin: 0 ${theme.padding.xsmall} ${theme.padding.xsmall} 0;
        font-size: 14px;
        white-space: nowrap;
      }

      &.Mui-selected {
        @media ${theme.query.laptopMax} {
          background-color: ${theme.color.secondaryAction};
          color: ${theme.color.text.light};
        }
      }
    }

    .MuiTabs-indicator {
      @media ${theme.query.laptopMax} {
        display: none;
      }
    }
  }
`;

export const ButtonBar = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -${theme.padding.xsmall};
  margin-right: -${theme.padding.xsmall};

  @media ${theme.query.tabletMax} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  button {
    margin: 0 ${theme.padding.xsmall};

    @media ${theme.query.tabletMax} {
      margin-bottom: ${theme.padding.small};
      padding: 4px ${theme.padding.small};
      font-size: 14px;
    }
  }
`;

export default DashboardStyles;
