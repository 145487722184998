import { OrderedSet } from 'immutable';

import ACTIONS from './actions';
import { combineImmutableReducers } from '../util';

const EMPTY_SET = OrderedSet();

const publicLinks = (state = EMPTY_SET, action) => {
  switch (action.type) {
    case ACTIONS.LINK_VISITED:
      return state.add(action.token);
    case ACTIONS.CREATE_LINK.success:
      return state.add(action.response.entities.publicLinks);
    case ACTIONS.USE_LINK.success:
      return state.remove(action.token);
    default:
      return state;
  }
};

const activePublicLink = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.LINK_VISITED:
      return [action.subjectType, action.subjectId];
    case ACTIONS.INVITE_VISITED:
    case ACTIONS.FLUSH_ACTIVE:
      return null;
    default:
      return state;
  }
};

const invites = (state = EMPTY_SET, action) => {
  switch (action.type) {
    case ACTIONS.INVITE_VISITED:
      return state.add(action.token);
    case ACTIONS.USE_INVITE.success:
      return state.remove(action.token);
    default:
      return state;
  }
};

const activeInvite = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.INVITE_VISITED:
      return [action.recommendedType, action.recommendedId];
    case ACTIONS.LINK_VISITED:
    case ACTIONS.FLUSH_ACTIVE:
      return null;
    default:
      return state;
  }
};

const referringOrg = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_INVITE.success:
      return action.response.entities.invitations[action.response.result].owningOrganization.id;
    case ACTIONS.FETCH_LINK.success:
      return action.response.entities.publicLinks[action.response.result].owningOrganization.id;
    default:
      return state;
  }
};

const redirectPath = (state = null, action) => {
  switch (action.type) {
    case ACTIONS.SET_REDIRECT_PATH:
      return action.payload;
    case ACTIONS.RESET_REDIRECT_PATH:
      return null;
    default:
      return state;
  }
};

const reducer = combineImmutableReducers({
  invites,
  publicLinks,
  activeInvite,
  referringOrg,
  activePublicLink,
  redirectPath,
});

export default reducer;

export const getInvites = (state) => state.get('invites');
export const getPublicLinks = (state) => state.get('publicLinks');
export const getReferringOrg = (state) => state.get('referringOrg');
export const getActiveInviteLink = (state) => state.get('activeInvite');
export const getActivePublicLink = (state) => state.get('activePublicLink');
