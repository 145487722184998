import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { Button, Text } from '../../../common/components';
import Resume from '../resume';
import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { getCandidateResumeCompletionPercentage, getCandidateResumeForCandidate, getCurrentUser } from '../../../reducer';
import { listResumes } from '../../../solve/actions';

const ResumeNeeded = ({ onComplete }) => (
  <div className="row center-xs">
    <div className="col-xs-12">
      <h3>The resume needs to be completed before applying for the job.</h3>
      <h3>You are only one step away from applying for this job.</h3>
      <p>Complete your resume so you can apply for this job.</p>

      <img src="/icons/resume/resume-begin.svg" alt="Resume Begin Icon" style={{ marginLeft: '36px' }} />
      <div className="row center-xs">
        <Button buttonType="text" onClick={onComplete}>
          Next
        </Button>
      </div>
    </div>
  </div>
);
ResumeNeeded.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

const ResumeComplete = ({ onComplete, hasSurveys }) => (
  <div className="row center-xs">
    <div className="col-xs-12">
      <img src="/icons/resume/portfolio.svg" alt="Portfolio Icon" />
      <h3>Congratulations, your resume is ready.</h3>
      {/* <StyledLink to="/upload-resume">Upload other resume</StyledLink> */}
      {/* <StyledLink to="/upload-cover-letter">Upload cover letter</StyledLink> */}

      {hasSurveys && (
        <Text>
          <p>As a next step, you have to answer a few screening questions.</p>
        </Text>
      )}

      <Button buttonType="primary" onClick={onComplete}>
        {hasSurveys ? 'Answer Questions' : 'Finish the Application'}
      </Button>
    </div>
  </div>
);
ResumeComplete.propTypes = {
  onComplete: PropTypes.func.isRequired,
  hasSurveys: PropTypes.bool.isRequired,
};

const RESUME_STEPS = [ResumeNeeded, Resume, ResumeComplete];

const ResumeWizardView = ({ user, resume, listResumes: fetchResumes, onComplete, hasSurveys, resumeComplete }) => {
  // First, attempt to load existing resume
  const resolvedResume = useFetchOnce(user, user.get('candidateId'), fetchResumes, resume);

  const [step, setStep] = useState(0);
  const nextStep = step + 1;
  const onNext = nextStep >= RESUME_STEPS.length ? onComplete : () => setStep(nextStep);
  const Step = RESUME_STEPS[step];

  if (!resolvedResume) {
    return <Spinner />;
  }

  if (resumeComplete && step < 2) {
    setStep(2);
    return <Spinner />;
  }

  return <Step resume={resume} onComplete={onNext} hasSurveys={hasSurveys} onContinue={onNext} />;
};
ResumeWizardView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  resume: PropTypes.instanceOf(Map),
  listResumes: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  hasSurveys: PropTypes.bool.isRequired,
  resumeComplete: PropTypes.bool.isRequired,
};
ResumeWizardView.defaultProps = {
  resume: undefined,
};

const RESUME_COMPLETE_THRESHOLD_PERCENT = 69;
const ResumeWizard = connect(
  (state) => {
    const user = getCurrentUser(state);
    return {
      user,
      resume: getCandidateResumeForCandidate(state, user.get('candidateId')),
      resumeComplete: getCandidateResumeCompletionPercentage(state, user.get('candidateId')) >= RESUME_COMPLETE_THRESHOLD_PERCENT,
    };
  },
  {
    listResumes,
  },
)(ResumeWizardView);

export default ResumeWizard;
