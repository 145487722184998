import { connect } from 'react-redux';
import ServiceView from './service_view';
import { getService, getCurrentUser, getPublicLinkForPosting } from '../../../reducer';
import { fetchSupportiveService, fetchAbService } from '../../../solve/actions';
import { createPublicLink } from '../../../public_link/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const service = getService(state, id);
  const user = getCurrentUser(state);
  const canEdit = service && service.getIn(['postingOrganization', 'id']) === user.getIn(['staffOrganization', 'id']);
  return {
    serviceId: id,
    service: getService(state, id),
    user: getCurrentUser(state),
    publicLink: getPublicLinkForPosting(state, id),
    canEdit,
  };
};

const mapDispatchToProps = {
  fetchService: fetchSupportiveService,
  fetchAbService,
  createPublicLink,
};

const ServiceContainer = connect(mapStateToProps, mapDispatchToProps)(ServiceView);

export default ServiceContainer;
