// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

import addressValidator from '../../../../common/form/helpers/address_validator';

const eventOverviewFormValidate = (values) => {
  const errors = {};
  if (!values.get('name')) {
    errors.name = 'Required';
  }
  if (!values.get('phone')) {
    errors.phone = 'Required';
  }

  errors.addressAttributes = addressValidator(values.get('addressAttributes'));

  if (!values.get('postExpirationDate')) {
    errors.postExpirationDate = 'Required';
  }
  if (!values.get('industry')) {
    errors.industry = 'Required';
  }
  if (!values.get('serviceType')) {
    errors.serviceType = 'Required';
  }

  // if (!values.get('industrySpecifics')) {
  //   errors.industrySpecifics = 'Required';
  // }

  if (!values.get('eventDate')) {
    errors.eventDate = 'Required';
  }
  // if (!values.get('startTime')) {
  //   errors.startTime = 'Required';
  // }
  //
  // if (!values.get('endTime')) {
  //   errors.endTime = 'Required';
  // }

  if (!values.get('description')) {
    errors.description = 'Required';
  }
  return errors;
};

export default eventOverviewFormValidate;
