import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  onboarding: PropTypes.bool,
  onboardingMode: PropTypes.string,
};

const defaultProps = {
  onboarding: false,
  onboardingMode: '',
};

const HomeView = ({ signedIn, onboarding, onboardingMode }) => {
  if (onboarding && !onboardingMode) {
    return <Redirect to="/welcome" />;
  }
  if (signedIn) {
    return <Redirect to="/dashboard" />;
  }
  return <Redirect to="/welcome" />;
};

HomeView.propTypes = propTypes;
HomeView.defaultProps = defaultProps;

export default HomeView;
