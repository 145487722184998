import { connect } from 'react-redux';
import ProgramParticipantsView from './program_participants_view';
import {
  getProgram,
  getCurrentUser,
  getCandidatePrograms,
  getStaffCandidateProgramIds,
  getStaffCandidatesForProgramFilters,
  getStaffCandidatesForProgramSearch,
  getStaffCandidatesForProgramLoaded,
  // getInvitation,
  // getInvitations,
  // getStaffInvitations,
  getAllInvitationsForListing,
} from '../../../reducer';
import {
  fetchProgram,
  listFilteredCandidatesForProgram,
  setStaffProgramCandidatesSearch,
  bulkCreateInvitations,
  listInvitations,
  // listStaffInvitations,
  resendInvitation,
} from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: programId },
    },
  },
) => {
  const user = getCurrentUser(state);

  const candidateProgramIds = getStaffCandidateProgramIds(state);
  const candidatePrograms = getCandidatePrograms(state, candidateProgramIds);

  // const invitationIds = getStaffInvitations(state);
  // const allInvitations = getInvitations(state);
  // const invitations = allInvitations.reduce((total, amount) => {
  //   console.log('amount', amount, amount.get('recommendedId'), programId);
  //   if (amount.get('recommendedId') === programId) {
  //     const invitation = getInvitation(state, amount);
  //     total.push(invitation);
  //   }
  //   return total;
  // }, []);
  // console.log('allInvitations', invitationIds, allInvitations, invitations);

  return {
    programId,
    program: getProgram(state, programId),
    user,
    candidatePrograms,
    loaded: getStaffCandidatesForProgramLoaded(state),
    filters: getStaffCandidatesForProgramFilters(state),
    search: getStaffCandidatesForProgramSearch(state),
    // invitationFilters: getStaffInvitationFilters(state),
    // invitationSearch: getStaffInvitationSearch(state),
    invitations: getAllInvitationsForListing(state, programId),
  };
};

const mapDispatchToProps = {
  fetchProgram,
  listFilteredCandidatesForProgram,
  setSearch: setStaffProgramCandidatesSearch,
  // setInvitationSearch: setStaffInvitationsSearch,
  bulkCreateInvitations,
  listInvitations,
  // listStaffInvitations,
  resendInvitation,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  createInvitationsFromFile: (file) => dispatchProps.bulkCreateInvitations(stateProps.user, 'programs', stateProps.programId, file),
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
});

const ProgramParticipantsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProgramParticipantsView);

export default ProgramParticipantsContainer;
