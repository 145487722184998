import { useSelector } from 'react-redux';
import { getCurrentUser } from 'session/reducer';
import { tokenAuth } from 'api/real_backend/config';

const useAuthToken = () => {
  const state = useSelector((store: any) => store.get('session'));
  const user = getCurrentUser(state);
  const token = tokenAuth(user.authToken);
  return token;
};

export default useAuthToken;
