import styled from 'styled-components';
import theme from '../../../common/theme';

const NotificationsStyles = styled.div`
  background-color: #f7f7f7;
  margin: -50px;
  padding: 25px 50px;

  @media only screen and (max-width: 768px) {
    & {
      margin: -25px;
      padding: 25px;
    }
  }
`;

export const Header = styled.div`
  margin: 0px -50px 25px;
  padding: 0px 50px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 14px 40px 0 rgba(0, 0, 0, 0.05);

  h2 {
    margin: 5px 0px;
    font-size: 18px;
    color: ${theme.color.grayDark};
  }

  @media only screen and (max-width: 768px) {
    .header {
      margin: 0px -25px 0px;
      padding: 0px 25px 0px;
    }
  }
`;

export default NotificationsStyles;
