import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser, getLocation } from '../reducer';
import UserModel from '../session/models/user_model';

const SegmentReporterView = ({ currentUser, location }) => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      window.analytics.page();
    }
  }, [location]);
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && currentUser.signedIn) {
      window.analytics.identify(currentUser.get('id'), {
        name: currentUser.name,
        email: currentUser.email,
        phone: currentUser.phone,
        candidateId: currentUser.candidateId,
        staffId: currentUser.staffId,
        organizationId: currentUser.getIn(['staffOrganization', 'id']),
        organizationName: currentUser.getIn(['staffOrganization', 'name']),
        organizationType: currentUser.getIn(['staffOrganization', 'orgType']),
      });
    }
  }, [currentUser]);
  return <Fragment />;
};

SegmentReporterView.propTypes = {
  location: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  location: getLocation(state),
});

const SegmentReporter = connect(mapStateToProps)(SegmentReporterView);
export default SegmentReporter;
