import styled from 'styled-components';
import theme from '../../theme';

const SortHeadingStyles = styled.div`
.triangle {
    position: relative;
    width: 8px;
    height: 8px;
  }
  .triangle:before {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    transition: all 0.2s ease;
  }
  .triangle.fill-down:before {
    top: 0px;
    border-color: ${theme.color.text.medium} transparent transparent transparent;
    border-width: 6px;
  }
  .triangle.fill-up:before {
    top: -6px;
    border-color: transparent transparent ${theme.color.text.medium}; transparent;
    border-width: 6px;
  }
  .triangle.outline-down:before {
    top: 0px;
    border-color: ${theme.color.grayLight} transparent transparent transparent;
    border-width: 6px;
  }
  `;

export default SortHeadingStyles;
