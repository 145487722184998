import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS, Map, OrderedSet } from 'immutable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import { useDebouncedEffect } from '../../../../util/hooks';

// TODO: auto-populate children from ENUM
const FilterSelect = ({ filters, onChange, name, children, disabled }) => (
  <Select
    value={filters.get(name) || ''}
    fullWidth
    disabled={disabled}
    onChange={(e) => {
      onChange(filters.set(name, e.target.value));
    }}
  >
    {children}
  </Select>
);
FilterSelect.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool.isRequired,
};

function useSearch(search, setSearch, loaded) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (loaded && search !== newSearch) {
        setSearch(newSearch);
      }
    },
    1500,
    [loaded, search, newSearch],
  );
  return { newSearch, onSearchChange };
}

// TODO: wire up to SystemEnums!
// eslint-disable-next-line max-len
const JobListFiltersView = ({
  filters,
  onChange,
  distanceFilter,
  jobTitleFilter,
  industryTypeFilter,
  setCandidateJobListingsZipcode,
  shiftTitleFilter,
  salaryFilter,
  zipCode,
  search,
  setSearch,
  loaded,
  loading,
}) => {
  const [newZipcode, setNewZipcode] = useState(zipCode);
  const [zipValid, setZipValid] = useState(true);

  // Ensure we set the local state after updating redux
  useEffect(() => {
    setNewZipcode(zipCode);
  }, [zipCode, setNewZipcode]);

  const ifZip = (zip, action) => {
    if (zip === '') {
      setZipValid(false);
      if (action) {
        action(zip);
      }
    } else if (zip.match(/^\d{5}$/)) {
      if (!zipValid) {
        setZipValid(true);
      }
      if (action) {
        action(zip);
      }
    } else if (zipValid) {
      setZipValid(false);
    }
  };

  let zipInvalidMessage = '';
  zipInvalidMessage = !zipValid ? 'Please enter valid zip code' : zipInvalidMessage;
  zipInvalidMessage = newZipcode === '' ? 'Please enter zip code' : zipInvalidMessage;

  const onZipSubmit = (e) => {
    e.preventDefault();
    const val = e.target.querySelector('input').value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setCandidateJobListingsZipcode);
  };
  const onZipBlur = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setCandidateJobListingsZipcode);
  };
  const onZipChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (newZipcode === val) {
      return;
    }
    ifZip(val);
    setNewZipcode(val);
  };

  const { newSearch, onSearchChange } = useSearch(search, setSearch, loaded);

  window.fjs = fromJS;

  return (
    <Grid container spacing={2} style={{ marginBottom: 15 }}>
      <Grid item xs={12} md={8}>
        <TextField
          id="search"
          placeholder={loading ? 'Loading... ' : 'Search job title, skill, industry'}
          margin="dense"
          variant="outlined"
          fullWidth
          disabled={loading}
          style={{ marginBottom: 10 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={newSearch}
          onChange={onSearchChange}
        />
      </Grid>
      <Grid item xs={6} md={2}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Distance</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="distance" variant="outlined" disabled={loading}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {distanceFilter &&
              distanceFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
            <MenuItem value="" disabled style={{ opacity: 1, float: 'right' }}>
              <img src="/images/powered_by_google_white.png" alt="powered by Google" />
            </MenuItem>
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item xs={6} md={2}>
        <form onSubmit={onZipSubmit}>
          <TextField
            id="zipCode"
            label="Zip Code"
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth
            mask="ddddd"
            onBlur={onZipBlur}
            onChange={onZipChange}
            className={zipValid ? '' : 'error'}
            helperText={zipInvalidMessage}
            error={!zipValid}
            value={newZipcode || ''}
            disabled={loading}
            InputLabelProps={{
              shrink: false,
            }}
          />
        </form>
      </Grid>
      <Grid item xs={6} md={3}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Job Type</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="type" disabled={loading}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {jobTitleFilter &&
              jobTitleFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item xs={6} md={3}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Industry</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="industry" disabled={loading}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {industryTypeFilter &&
              industryTypeFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item xs={6} md={3}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Shift</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="shift" disabled={loading}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {shiftTitleFilter &&
              shiftTitleFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item xs={6} md={3}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Salary</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="salary" disabled={loading}>
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {salaryFilter &&
              salaryFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};
JobListFiltersView.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  setCandidateJobListingsZipcode: PropTypes.func.isRequired,
  distanceFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  jobTitleFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  industryTypeFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  shiftTitleFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  salaryFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  zipCode: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

JobListFiltersView.defaultProps = {
  zipCode: undefined,
  search: '',
};

export default JobListFiltersView;
