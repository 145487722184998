// For job applications
export const candidateStaffStatusEnums = {
  recommended: 's_new',
  reviewed: 's_reviewed',
  appliedOnSolve: 's_applied',
  appliedOnSite: 's_applied_on_website',
  interviewing: 's_interviewing',
  offer: 's_offer',
  hired: 's_hired',
  archived: 's_archived',
  deleted: 's_deleted',
};

// For program applications
export const programStaffStatusEnums = {
  invited: 's_new',
  applied: 's_applied',
  joined: 's_joined',
  graduated: 's_graduated',
  droppedOut: 's_dropped_out',
  declined: 's_declined',
};

// For event applications
export const eventStaffStatusEnums = {
  initial: 's_new',
  registered: 's_registered',
  reminded: 's_reminded',
  attended: 's_attended',
};

// For pathway applications
export const pathwayStaffStatusEnums = {
  pathwayApplied: 's_applied',
  inProgress: 's_in_progress',
  complete: 's_complete',
  transferred: 's_transferred',
  droppedVoluntarily: 's_dropped_voluntarily',
  droppedInVoluntarily: 's_dropped_involuntarily',
  saved: 's_saved',
};
