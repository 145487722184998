import React, { useEffect, useState } from 'react';
import { fromJS, OrderedSet } from 'immutable';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDebouncedEffect } from '../../../../util/hooks';
import ReactSelect from '../../../../common/form/redux_form/react_select';
import UserModel from '../../../../session/models/user_model';
import { getCurrentUser, getExistingCandidates, getStaffCandidatesSearch } from '../../../../reducer';
import { listFilteredCandidatesForStaff, setStaffCandidatesSearch } from '../../../../solve/actions';

function useSearch(search, setSearch) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    setNewSearch(e);
  };
  useDebouncedEffect(
    () => {
      if (search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const CandidateSelectorView = ({ currentUser, existingCandidates, search, setSearch, listCandidates, ...props }) => {
  const { newSearch, onSearchChange } = useSearch(search, setSearch);

  useEffect(() => {
    if (search.length > 0) {
      const query = fromJS({ userFullName: search });
      listCandidates(currentUser, currentUser.getIn(['staffOrganization', 'id']), query);
      setSearch('');
    }
  }, [search, setSearch, listCandidates, currentUser]);

  return (
    <ReactSelect
      placeholder="Search for candidates..."
      noOptionsMessage={newSearch.length > 0 ? 'No results' : 'Start typing to see results'}
      inputValue={newSearch}
      onInputChange={onSearchChange}
      options={existingCandidates?.toJS()}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};
CandidateSelectorView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  existingCandidates: PropTypes.instanceOf(OrderedSet).isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  listCandidates: PropTypes.func.isRequired,
};
CandidateSelectorView.defaultProps = {
  search: '',
};

const CandidateSelector = connect(
  (state) => ({
    currentUser: getCurrentUser(state),
    existingCandidates: getExistingCandidates(state),
    search: getStaffCandidatesSearch(state),
  }),
  {
    listCandidates: listFilteredCandidatesForStaff,
    setSearch: setStaffCandidatesSearch,
  },
)(CandidateSelectorView);

export default CandidateSelector;
