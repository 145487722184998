import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import theme from '../../../../common/theme';
import JobListRowStyles from './job_list_row_styles';
import { IndeedLabel } from '../../../../common/styles/detail';
import JobPostingModel from '../../../../solve/models/job_posting_model';
import UserModel from '../../../../session/models/user_model';
import getListingImage from '../../../../common/components/get_listing_image';

const propTypes = {
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]).isRequired,
  showDrafts: PropTypes.bool.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  isIndeed: PropTypes.bool,
};

const defaultProps = {
  isIndeed: false,
};

const formatPay = (type, amount) => {
  if (!numeral(amount).value()) {
    return 'Not Provided';
  }

  const formattedAmount = numeral(amount).format('$0,0');
  switch (type) {
    case 'hourly':
      return `${formattedAmount}/hour`;
    case 'salary':
      return `${formattedAmount}/year`;
    default:
      return `${formattedAmount} ${type}`;
  }
};

const statusEnums = {
  active: 'Active',
  pre_published: 'Draft',
  closed: 'Closed',
};

const JobListRowView = ({ job, showDrafts, currentUser, isIndeed }) => {
  const isDraft = job.get('state') === 'draft';
  if (showDrafts !== isDraft) {
    return null;
  }
  const logoUrl = getListingImage(job, 'job');
  const canEdit =
    job.getIn(['postingOrganization', 'id']) === currentUser.getIn(['staffOrganization', 'id']) ||
    job.getIn(['employer', 'id']) === currentUser.getIn(['staffOrganization', 'id']);

  const jobDistance = Math.round(parseFloat(job.get('distance')) * 10) / 10;
  const jobDistanceText = jobDistance ? `${jobDistance} miles` : '--';

  return (
    <JobListRowStyles>
      <td>
        <img src={logoUrl} className="job-logo" alt="Job Posting Logo" style={{ margin: 12 }} />
      </td>
      <td>
        <h4>{job.get('title')}</h4>
        <span>
          {job.getIn(['address', 'city'])}, {job.getIn(['address', 'state'])}
        </span>
        {isIndeed && <IndeedLabel>Job by Indeed</IndeedLabel>}
      </td>
      <td>
        <Chip label={job.get('jobType')} style={{ backgroundColor: theme.color.positive, color: '#fff', textTransform: 'uppercase' }} size="small" />
      </td>
      {<td>{jobDistanceText}</td>}
      {job.get('payType') === 'no-share' ? <td>Not Specified</td> : <td>{formatPay(job.get('payType'), job.get('payAmount'))}</td>}
      <td>{canEdit && `${statusEnums[job.get('state')]}`}</td>
      <td>
        {canEdit && (
          <Link to={`/staff/jobs/${job.get('id')}/edit`}>
            <Button color="secondary" size="small">
              Edit
            </Button>
          </Link>
        )}
      </td>
      <td>
        <Link to={`/staff/jobs/${job.get('id')}/details`}>
          <Button variant="contained" color="secondary" size="small" style={{ backgroundColor: theme.color.secondary }}>
            View
          </Button>
        </Link>
      </td>
    </JobListRowStyles>
  );
};

JobListRowView.propTypes = propTypes;
JobListRowView.defaultProps = defaultProps;

export default JobListRowView;
