import { fromJS } from 'immutable';
import { client, ENDPOINT, tokenAuth } from './config';

const oauthOptions = []; // ['linkedin', 'facebook', 'google', 'twitter'];

const axios = client();

if (process.env.NODE_ENV !== 'production') {
  oauthOptions.push('developer');
}

const buildOauthOrigin = (returnTo) => {
  const endpoint = window.location.href.split('/').slice(0, 3).join('/');
  const returnToPath = returnTo || window.location.pathname;
  const origin = `${endpoint}/finish_signin?returnTo=${encodeURIComponent(returnToPath)}`;
  return encodeURIComponent(origin);
};

export const OAUTH = {
  providers: fromJS(oauthOptions),
  getUrl: (provider, returnTo) => `${ENDPOINT}/auth/${provider}?origin=${buildOauthOrigin(returnTo)}`,
};

// Session
export const signIn = (email, password) => axios.post('/v1/auth', { email, password }, { withCredentials: true });
export const finishSignIn = () => axios.post('/v1/auth', undefined, { withCredentials: true });
export const register = (user) => axios.post('/v1/user', { user }, { withCredentials: true });
export const resetPassword = (token, password) => axios.post('/v1/user/reset_password', { token, password });
export const confirmEmail = (token) => axios.post('/v1/user/confirm_email', { token });
export const reconfirmEmail = (token) => axios.post('/v1/user/request_email_validation', { token }, { headers: { Authorization: tokenAuth(token) } });
export const forgotPassword = (email) => axios.post('/v1/user/forgot_password', { email });

export const refreshAuth = (token) => axios.get('/v1/auth', { headers: { Authorization: tokenAuth(token) } });

export const updateCurrentUser = (token, user) => axios.patch('/v1/user', { user }, { headers: { Authorization: tokenAuth(token) } });
