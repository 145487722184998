import styled from '@react-pdf/styled-components';
import theme from '../../common/theme';

const text = {
  sm: '10pt',
  md: '11pt',
  lg: '15pt',
};

const spacing = {
  sm: '10pt',
  md: '15pt',
  lg: '30pt',
};

export const Em = styled.Text`
  ${''}
  font-weight: 300;
  font-style: italic;
  color: ${theme.color.gray};
`;

export const Entry = styled.View`
  margin-bottom: ${spacing.md};
`;

export const EntryHeading = styled.Text`
  font-size: ${text.lg};
  font-weight: 700;
  color: ${theme.color.gray};
  margin-bottom: 5pt;
`;

export const EntrySubHeading = styled.Text`
  font-size: ${text.lg};
  font-weight: 700;
  color: ${theme.color.green};
`;

export const HeaderContact = styled.View`
  display: block;
  width: 150pt;
`;

export const HeaderContent = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 20pt;
`;

export const HeaderLI = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 4pt;
`;

export const HeaderName = styled.View`
  ${''}
  width: 300pt;
`;

export const Heading = styled.Text`
  ${''}
  font-family: 'Helvetica';
  font-size: 32pt;
  color: ${theme.color.grayDarker};
`;

export const Row = styled.View`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const RowMargin = styled.View`
  display: flex;
  flex-direction: row;
  text-align: right;
  width: 80pt;
`;

export const RowContent = styled.View`
  width: 452pt;
`;

export const SectionHeading = styled.View`
  padding-bottom: ${spacing.sm};
  margin-bottom: ${spacing.sm};
  border-bottom: 1pt solid ${theme.color.grayDarker};
`;

export const SectionHeadingText = styled.Text`
  font-size: ${text.lg};
  font-family: 'Helvetica';
  font-weight: 700;
  color: ${theme.color.grayDarker};
`;

export const SectionIcon = styled.View`
  width: 15pt;
  margin-right: 0;
`;

export const SectionDates = styled.View`
  width: 50pt;
  text-align: right;
`;

export const DatesText = styled.Text`
  margin: 3pt 0 1pt;
`;

export const Section = styled.View`
  margin-bottom: ${spacing.md};
`;

export const UL = styled.View`
  margin: ${spacing.sm} 0;
`;

export const LI = styled.Text`
  margin-bottom: 5pt;
  color: ${theme.color.gray};
`;

const PdfViewStyles = styled.View`
  ${''}
  font-family: 'Helvetica';
  weight: 400;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  width: 100%;
  font-size: ${text.sm};
  color: ${theme.color.gray};
`;

export default PdfViewStyles;
