import { connect } from 'react-redux';

import { generateNumberRange } from 'util/common';

import PathwayReportFiltersView from './pathway_report_filters_view';
import { getPathwayReportFilters } from '../../../../reducer';
import { setPathwayReportFilters } from '../../../../solve/actions';

const mapStateToProps = (state) => ({
  filters: getPathwayReportFilters(state),
  yearFilter: generateNumberRange(2014, new Date().getFullYear()),
});

const mapDispatchToProps = {
  onChange: setPathwayReportFilters,
};

const PathwayReportFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayReportFiltersView);

export default PathwayReportFiltersContainer;
