import styled from 'styled-components';
// import theme from '../../../common/theme';

const BackgroundsDisplayStyles = styled.div`
  .backgrounds-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }

  .backgrounds {
    font-size: 14px;
    padding: 5px 20px 5px 25px;
    font-weight: 400;
    margin: 5px;
    border-radius: 17px;
    color: white;
    background-color: #7dbfb7;
    display: flex;
  }

  .backgrounds button {
    color: white;
    padding: 3px;
    margin-left: 10px;
  }
`;

export default BackgroundsDisplayStyles;
