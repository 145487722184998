import { connect } from 'react-redux';

import PostingPreviewsView from './posting_previews_view';
import { getPublicLink, getCurrentUser } from '../../../reducer';
import { fetchPublicLink, visitedLink, setRedirectPath } from '../../../public_link/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  postingId: id,
  currentUser: getCurrentUser(state),
  publicLink: getPublicLink(state, id),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  fetchPublicLink,
  visitedLink,
  setRedirectPath,
};

const PostingPreviewsContainer = connect(mapStateToProps, mapDispatchToProps)(PostingPreviewsView);

export default PostingPreviewsContainer;
