import { connect } from 'react-redux';
import DetailsFormView from './details_form_view';
import { formValueSelector } from '../../../../common/form';
import { getSystemEnumList, getStaff, getCurrentUser } from '../../../../reducer';
import { listStaff } from '../../../../solve/actions';

const selector = formValueSelector('programForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  // prop assignments
  currentUser: getCurrentUser(state),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
  jobTypes: getSystemEnumList(state, 'JobType').map((item) => [item.get('slug'), item.get('label')]),
  certificationCredentialTypes: getSystemEnumList(state, 'CertificationType').map((item) => [item.get('slug'), item.get('label')]),
  teamMembers: getStaff(state).map((staff) => [staff.get('id'), `${staff.getIn(['user', 'firstName'])} ${staff.getIn(['user', 'lastName'])}`]),
  payment: selector(state, 'payment'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listStaff,
};

const DetailsFormContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsFormView);

export default DetailsFormContainer;
