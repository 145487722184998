import styled from 'styled-components';
// import theme from '../../../common/theme';

const NotificationsStyles = styled.div`
  .staff-wrapper {
    border: solid 1px #bdc3c7;
    border-radius: 5px;
  }
`;

export default NotificationsStyles;
