import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { AddButton } from '../../components/buttons';

const propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
    onChange: PropTypes.func,
  }).isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  capture: PropTypes.string,
  multiple: PropTypes.bool,
  label: PropTypes.string,
  clear: PropTypes.bool,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool, warning: PropTypes.bool }).isRequired,
  required: PropTypes.bool,
  optional: PropTypes.bool,
};

const defaultProps = {
  accept: undefined,
  capture: undefined,
  multiple: undefined,
  label: undefined,
  clear: false,
  required: false,
  optional: false,
};

const MuiFileUpload = ({
  input: { value, onChange },
  name,
  accept,
  capture,
  multiple,
  label,
  clear,
  meta: { touched, error, warning },
  required,
  optional,
}) => (
  <Fragment>
    <AddButton containerElement="label" variant="outlined" className="upload-button">
      <label htmlFor={name}>
        {(!value || clear) && (
          <span className="label">
            {label}
            {required ? '*' : ''}
          </span>
        )}
        {value &&
          !clear &&
          Array.from(value).map((file) => (
            <div key={file.name} className="label">
              {file.name}
            </div>
          ))}
        <input
          name={name}
          accept={accept}
          capture={capture}
          multiple={multiple}
          onChange={(e) => {
            onChange(e.target.files);
          }}
          type="file"
          style={{ display: 'none' }}
        />
        {touched && ((error && <span className="error">{error}</span>) || (warning && <span className="warning">{warning}</span>))}
      </label>
    </AddButton>
    {optional && (
      <span className="label" style={{ marginLeft: '5px' }}>
        (Optional)
      </span>
    )}
  </Fragment>
);
MuiFileUpload.propTypes = propTypes;
MuiFileUpload.defaultProps = defaultProps;

export default MuiFileUpload;
