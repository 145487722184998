import styled from 'styled-components';
import theme from '../../../../common/theme';

export const Banner = styled.div`
  background: #f6f2fb;
  color: #8752cd;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 24px;
  margin-top: 12px;
`;

export const PathwayCandidateTile = styled.div`
  background: #f7f7f7;
  padding: 0.25rem;
  margin: 0.25rem 0px;
`;

export const BulkEditPane = styled.div`
  display: flex;
  align-content: center;
  padding-top: 8px;
  .mr-8x {
    margin-right: 8px;
  }
`;

export const SearchTextContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 0px 5px;
  h4 {
    margin: 0;
  }
  button {
    padding: 0.5rem 1rem;
  }
  p {
    margin-top: 8px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media ${theme.query.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Actions = styled.div`
  justify-self: flex-end;
  align-items: bottom;
  text-align: right;
  color: #b8c0bc;
  button {
    font-size: 12px;
    margin-left: 10px;
  }
  p {
    margin: 3px 0px 0px 0px;
    color: #c0c0c0;
    font-size: 0.7em;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;
  background: #fff;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
  h6 {
    margin: 0px;
  }
`;

export const Body = styled.div`
  padding: 10px 0px;
  h4 {
    margin: 0px;
    font-weight: normal;
    color: ${(props) => props.theme.color.secondaryAction};
    b {
      color: #000;
    }
  }
  p {
    margin: 0px;
    color: #c0c0c0;
  }
`;
