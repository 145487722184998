import { createMuiTheme } from '@material-ui/core/styles';
import { darken, lighten } from 'polished';

const widths = {
  mobile: '400px',
  tablet: '768px',
  laptop: '1080px',
  desktop: '1200px',
  wide: '1400px',
};

const theme = {
  color: {
    blue: '#3fa3db',
    blueLight: '#6bb9f0',
    blueLighter: '#9dd3f6',
    blueLightest: '#d7efff',
    blueDark: '#2574a9',
    blueDarker: '#22313f',

    redLight: '#f45142',
    red: '#f22613',
    redDark: '#be000e',

    yellowLight: '#facb62',
    yellow: '#f9bf3b',
    orange: '#f69f1e',
    orangeDark: '#c47618',

    greenLight: '#65CF99',
    green: '#3fc380',
    greenDark: '#019875',
    greenDarker: '#00795D',

    purpleLighter: '#be90d4',
    purpleLight: '#b951cb',
    purple: '#9b07b5',
    purpleDark: '#7C0590',

    white: '#ffffff',
    whiteOffset: '#f4f6fa',
    grayLighter: '#edf1f7',
    grayLight: '#bdc3c7',
    gray: '#6c7a89',
    grayDark: '#394148',
    grayDarker: '#292D30',

    // Reserve for text colors
    text: {}, // children set below

    // Additional colors used in design
    mobileToolbar: '#3D5E7E',
    tile: '#EDF1F7',
    tile2: '#F2F6FF',
    border: '#E4E9F2',
    tileTitle: '#0057C2',
    buttonText: '#059765',
    scoreText: '#52CA5B',

    riseKitRed: '#ed3833',
    riseKitYellow: '#ffe666',
    riseKitBlue: '#3fa3db',
    riseKitBlack: '#181d25',
    riseKitGray: '#545454',
  },
  font: {
    sansSerif: '"Open Sans", "Source Sans Pro", sans-serif',
    serif: '"Source Serif Pro", serif',
    mono: '"Source Code Pro", monospace',
  },
  text: {
    fontSize: 18,
    lineHeight: 24,
  },
  button: {
    borderRadius: 3,
  },
  screen: {},
  section: {},
  padding: {
    xsmall: '0.5rem',
    small: '0.75rem',
    base: '1.5rem',
    large: '3rem',
  },
  query: {
    mobile: `screen and (max-width: calc(${widths.mobile} - 1px))`,
    tablet: `screen and (min-width: ${widths.mobile}) and (max-width: calc(${widths.tablet} - 1px))`,
    tabletMin: `screen and (min-width: ${widths.mobile})`,
    tabletMax: `screen and (max-width: calc(${widths.tablet} - 1px))`,
    laptop: `screen and (min-width: ${widths.tablet}) and (max-width: calc(${widths.laptop} - 1px))`,
    laptopMin: `screen and (min-width: ${widths.tablet})`,
    laptopMax: `screen and (max-width: calc(${widths.laptop} - 1px))`,
    desktop: `screen and (min-width: ${widths.laptop}) and (max-width: calc(${widths.desktop} - 1px))`,
    desktopMin: `screen and (min-width: ${widths.laptop})`,
    desktopMax: `screen and (max-width: calc(${widths.desktop} - 1px))`,
    wide: `screen and (min-width: ${widths.desktop}) and (max-width: calc(${widths.wide} - 1px))`,
    wideMin: `screen and (min-width: ${widths.desktop})`,
    wideMax: `screen and (max-width: calc(${widths.wide} - 1px))`,
  },
  widths,
};

// Set Named Colors
theme.color.primary = theme.color.blueDarker;
theme.color.secondary = theme.color.blueDark;
theme.color.action = theme.color.green;
theme.color.secondaryAction = theme.color.blue;
theme.color.subtleAction = theme.color.grayLight;
theme.color.disabled = theme.color.grayLight;
theme.color.positive = theme.color.green;
theme.color.positiveLight = theme.color.greenLight;
theme.color.negative = theme.color.red;
theme.color.success = theme.color.greenDark;
theme.color.error = theme.color.red;
theme.color.warn = theme.color.yellow;
theme.color.gold = theme.color.orange;

// Set Text child variables
theme.color.text.dark = theme.color.primary;
theme.color.text.medium = theme.color.gray;
theme.color.text.light = theme.color.whiteOffset;

export const muiTheme = createMuiTheme({
  overrides: {
    MuiMenuPaper: {
      top: '55px',
      right: '20px',
      maxWidth: '200px',
      width: '100%',
    },
    MuiFormControl: {
      root: {
        margin: '30px 0px 0px !important',
      },
    },
    MuiFormLabel: {
      root: {
        transform: 'translate(0px, 0px) !important',
        display: 'inline !important',
        top: 'auto !important',
        bottom: '100%',
        paddingBottom: '5px',
        color: theme.color.gray,
        fontSize: '12px',
        textTransform: 'uppercase',
        '&.Mui-focused': {
          color: theme.color.primary,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        top: '100%',
        margin: '5px 0px 0px !important',
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: '#fff',
        padding: 0,
        '&.Mui-focused': {
          backgroundColor: '#fff',
        },
      },
      underline: {
        '&:after': {
          borderBottomColor: theme.color.primary,
        },
      },
      input: {
        border: 'solid 1px #E4E9F2',
        borderRadius: 0,
      },
    },
    MuiStepIcon: {
      root: {
        color: '#BDC3C7',
        '&$completed': {
          color: theme.color.action,
        },
        '&$active': {
          color: theme.color.secondaryAction,
        },
      },
      active: {},
      completed: {},
    },
    MuiStepLabel: {
      label: {
        color: theme.color.text.medium,
        opacity: 1,
        '&$completed': {
          color: theme.color.text.medium,
          fontWeight: 'normal',
        },
        '&$active': {
          color: theme.color.secondaryAction,
        },
      },
      active: {},
      completed: {},
    },
  },
  palette: {
    primary: {
      light: lighten(0.1, theme.color.primary),
      main: theme.color.primary,
      dark: darken(0.1, theme.color.primary),
      // contrastText: getContrastText(palette.primary[500]),
    },
    secondary: {
      light: lighten(0.1, theme.color.secondary),
      main: theme.color.secondary,
      dark: darken(0.1, theme.color.secondary),
      // contrastText: getContrastText(palette.secondary.A400),
    },
  },
});

export default theme;
