import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map } from 'immutable';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import JobListStyles, { ResultsPageNavigator } from './job_list_styles';
import JobListFilters from './job_list_filters';
import { useFetchOnce } from '../../../util/hooks';
import JobTile from '../../../jobs/components/tile';
import IndeedJobTile from '../../../jobs/components/indeed_tile';
import theme from '../../../common/theme';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  currentCandidate: PropTypes.instanceOf(Map).isRequired,
  jobs: PropTypes.instanceOf(OrderedSet).isRequired,
  listFilteredJobPostingsForCandidate: PropTypes.func.isRequired,
  listFilteredJobApplicationsForCandidate: PropTypes.func.isRequired,
  setCandidateJobListingsZipcode: PropTypes.func.isRequired,
  setCandidateJobListingsFilters: PropTypes.func.isRequired,
  distance: PropTypes.string,
  zipCode: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  search: PropTypes.string,
  mode: PropTypes.oneOf(['all', 'saved', 'applied']),
  totalStandardJobs: PropTypes.number.isRequired,
  totalIndeedJobs: PropTypes.number.isRequired,
  indeedLoaded: PropTypes.bool.isRequired,
  listFilteredIndeedJobPostingsForCandidate: PropTypes.func.isRequired,
  listFilteredIndeedJobApplicationsForCandidate: PropTypes.func.isRequired,
  removeFilteredIndeedJobPostingsForCandidate: PropTypes.func.isRequired,
  setCandidateJobListingsPage: PropTypes.func.isRequired,
  listCandidateApplicationsForCandidate: PropTypes.func.isRequired,
  listExternalCandidateApplicationsForCandidate: PropTypes.func.isRequired,
  jobIdsToBeFetched: PropTypes.arrayOf(PropTypes.string).isRequired,
  indeedJobIdsToBeFetched: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchSavedIndeedJobPostingByIds: PropTypes.func.isRequired,
  fetchSavedJobPostingsByIds: PropTypes.func.isRequired,
  fetchCandidateResume: PropTypes.func.isRequired,
};

const defaultProps = {
  search: '',
  mode: 'all',
  zipCode: undefined,
  distance: '',
};

const JobListView = ({
  currentUser,
  currentCandidate,
  mode,
  jobs,
  totalStandardJobs,
  totalIndeedJobs,
  listFilteredJobPostingsForCandidate,
  listFilteredJobApplicationsForCandidate,
  zipCode,
  setCandidateJobListingsZipcode,
  setCandidateJobListingsFilters,
  distance,
  loaded,
  filters,
  search,
  loading,
  indeedLoaded,
  listFilteredIndeedJobPostingsForCandidate,
  listFilteredIndeedJobApplicationsForCandidate,
  removeFilteredIndeedJobPostingsForCandidate,
  setCandidateJobListingsPage,
  listCandidateApplicationsForCandidate,
  listExternalCandidateApplicationsForCandidate,
  jobIdsToBeFetched,
  indeedJobIdsToBeFetched,
  fetchSavedIndeedJobPostingByIds,
  fetchSavedJobPostingsByIds,
  fetchCandidateResume,
}) => {
  const total = totalStandardJobs + totalIndeedJobs;
  const [pageShown, setPageShown] = useState(0);
  const totalPages = Math.ceil(total / 25) || 1;
  const listAll = mode === 'all';
  const isSavedMode = mode === 'saved';
  const candidateZipCode = currentCandidate.getIn(['address', 'zip']) || '10001';

  useFetchOnce(currentUser, currentUser.get('candidateId'), fetchCandidateResume);

  // Go back to page one if filtering or searching occurs
  useEffect(() => {
    setPageShown(1);
  }, [filters, search]);

  useEffect(() => {
    setCandidateJobListingsPage(pageShown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageShown]);

  // Ensure we set the local state after updating redux
  useEffect(() => {
    setCandidateJobListingsZipcode(zipCode || candidateZipCode);
  }, [zipCode, setCandidateJobListingsZipcode, candidateZipCode]);
  const [firstTimeLoad, setFirstTimeLoad] = useState(true);

  useEffect(
    () => {
      if (firstTimeLoad) {
        if (filters.delete('distance').isEmpty()) {
          const newFilters = filters.merge({ distance: '15-miles' });
          const userZipCode = currentUser.zip || candidateZipCode;
          setCandidateJobListingsZipcode(userZipCode);
          setCandidateJobListingsFilters(newFilters);
        }
        setFirstTimeLoad(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser, filters, distance, candidateZipCode],
  );

  const query = filters.merge(listAll ? { title: search, zipCode } : { title: '' });

  useEffect(() => {
    listCandidateApplicationsForCandidate(currentUser);
    listExternalCandidateApplicationsForCandidate(currentUser, currentUser.get('candidateId'));
  }, [currentUser, listCandidateApplicationsForCandidate, listExternalCandidateApplicationsForCandidate]);

  useEffect(() => {
    if (isSavedMode && indeedJobIdsToBeFetched.length > 0) {
      fetchSavedIndeedJobPostingByIds(currentUser, indeedJobIdsToBeFetched);
    }

    if (isSavedMode && jobIdsToBeFetched.length > 0) {
      fetchSavedJobPostingsByIds(currentUser, jobIdsToBeFetched);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, currentUser, JSON.stringify(indeedJobIdsToBeFetched), JSON.stringify(jobIdsToBeFetched)]);

  useEffect(
    () => {
      // TODO commented out until filter applied/saved has been fixed
      // const query = filters.merge(listAll ? { title: search, zipCode } : { title: search } );
      if (listAll) {
        if (!zipCode) {
          return;
        }
        const transitionPage = Math.floor(totalStandardJobs / 25);
        if (pageShown < transitionPage) {
          removeFilteredIndeedJobPostingsForCandidate(currentUser, { page: pageShown, filters: query });
        } else {
          listFilteredIndeedJobPostingsForCandidate(currentUser, { page: pageShown - transitionPage, filters: query });
        }
        listFilteredJobPostingsForCandidate(currentUser, { page: pageShown, filters: query });
      } else {
        listFilteredJobApplicationsForCandidate(currentUser, { page: pageShown, filters: query });
        listFilteredIndeedJobApplicationsForCandidate(currentUser, { page: pageShown, filters: query });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded, filters, search, zipCode, mode, currentUser, distance, pageShown],
  );

  const scrollTop = () => window.scrollTo(0, 0);
  const getNextPageResults = () => {
    setPageShown(pageShown + 1);
    scrollTop();
  };
  const getPrevPageResults = () => {
    setPageShown(pageShown - 1);
    scrollTop();
  };

  const PageNavigator = () => {
    if (!listAll || total < 25) {
      return null;
    }
    return (
      <ResultsPageNavigator>
        <div className="prev-next-container">
          <Button
            style={{ color: pageShown <= 0 ? theme.color.grayLight : theme.color.blue }}
            startIcon={<NavigateBeforeIcon />}
            disabled={pageShown <= 0}
            onClick={() => getPrevPageResults()}
          >
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown} of ${totalPages}`}</Typography>
          <Button
            style={{ color: pageShown >= totalPages ? theme.color.grayLight : theme.color.blue }}
            endIcon={<NavigateNextIcon />}
            disabled={pageShown >= totalPages}
            onClick={() => getNextPageResults()}
          >
            Next
          </Button>
        </div>
      </ResultsPageNavigator>
    );
  };

  return (
    <JobListStyles>
      <div className="header">
        <div className="candidate-wrapper">
          <h1 style={{ marginBottom: 0 }}>
            {loaded && !indeedLoaded && <span style={{ color: theme.color.positive }}>{`${total} Jobs`}</span>}
            {loaded && !indeedLoaded && zipCode && listAll && (
              <small style={{ color: theme.color.text.medium, fontSize: 18, fontStyle: 'italic' }}> near {zipCode}</small>
            )}
          </h1>
          {listAll ? <JobListFilters loaded={loaded} loading={loading} /> : <div style={{ height: 20 }} />}
        </div>
      </div>
      <div className="posting-wrapper">
        <div className="candidate-wrapper">
          {!loaded && !indeedLoaded ? (
            <Spinner />
          ) : (
            <Fragment>
              <PageNavigator />
              {jobs.map((job) => (job.type === 'indeed-job' ? <IndeedJobTile key={job.id} id={job.id} /> : <JobTile key={job.id} id={job.id} />))}
              <PageNavigator />
            </Fragment>
          )}
        </div>
      </div>
    </JobListStyles>
  );
};

JobListView.propTypes = propTypes;
JobListView.defaultProps = defaultProps;

export default JobListView;
