import { ceil } from 'lodash';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import useAuthToken from 'risekit/common/hooks/useAuthToken';
import { TextField, InputAdornment } from '@material-ui/core';
import { useDebounce } from 'risekit/common/hooks/useDebounce';
import CircularSpinner from 'risekit/common/components/spinner';
import usePagination, { RRPagination } from 'risekit/common/components/pagination';

import PathwayRow from '../inc/pathway-row';
import useGetPathwayList from '../hooks/useGetPathwayList';
import { StyledTable, PaginationWrapper } from './pathway-view.style';

const PathwayList = (props: any) => {
  const size = 25;
  const { mode, organizationId: orgId } = props;

  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 1000);

  const token = useAuthToken();
  const pagination = usePagination();

  const params = { page: pagination.page || 1, size, pathway_instance_name: debouncedSearchText || undefined, _sort: 'starts_on', _order: 'desc' };

  const { loading, error, pathways = [], totalCount } = useGetPathwayList({ token, orgId, params });

  if (error) {
    return <div>ERROR OCCURED</div>;
  }

  let pathwaysToShow = pathways;

  if (mode === 'active') {
    pathwaysToShow = pathways.filter((pathway) => pathway.state !== 'pre_published');
  }
  if (mode === 'drafts') {
    pathwaysToShow = pathways.filter((pathway) => pathway.state === 'pre_published');
  }

  return (
    <div>
      <TextField
        id="search"
        placeholder="Search"
        margin="dense"
        variant="outlined"
        fullWidth
        onChange={(e) => setSearchText(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />

      {loading || !pathways ? (
        <CircularSpinner />
      ) : (
        <>
          <StyledTable>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>Pathway</th>
                <th>Type</th>
                <th>Status</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {pathwaysToShow.map((pathway) => (
                <PathwayRow pathwayInstance={pathway} key={pathway.id} />
              ))}
            </tbody>
          </StyledTable>
          <PaginationWrapper>
            <RRPagination {...pagination} count={ceil(totalCount / size)} />
          </PaginationWrapper>
        </>
      )}
    </div>
  );
};

export default PathwayList;
