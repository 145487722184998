import { connect } from 'react-redux';
import ProgramPreviewView from './program_preview_view';
import { getCurrentUser } from '../../../../reducer';
import { setRedirectPath } from 'public_link/actions';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
});

const mapDispatchToProps = {
  setRedirectPath,
};

const ProgramPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramPreviewView);

export default ProgramPreviewContainer;
