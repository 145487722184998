import { connect } from 'react-redux';
import PathwayDetailsView from './pathway_details_view';
import { getCurrentUser, getPathwayInstance } from '../../../reducer';
import { fetchPathwayInstance, draftPathwayInstance, publishPathwayInstance, closePathwayInstance, cancelPathwayInstance } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  user: getCurrentUser(state),
  pathwayInstance: getPathwayInstance(state, id),
  pathwayInstanceId: id,
});

const mapDispatchToProps = { fetchPathwayInstance, draftPathwayInstance, publishPathwayInstance, closePathwayInstance, cancelPathwayInstance };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onChange: (newState) => {
    switch (newState) {
      case 'closed':
        dispatchProps.closePathwayInstance(stateProps.user, stateProps.pathwayInstanceId);
        break;
      case 'canceled':
        dispatchProps.cancelPathwayInstance(stateProps.user, stateProps.pathwayInstanceId);
        break;
      case 'active':
        dispatchProps.publishPathwayInstance(stateProps.user, stateProps.pathwayInstanceId);
        break;
      case 'pre_published':
      default:
        dispatchProps.draftPathwayInstance(stateProps.user, stateProps.pathwayInstanceId);
        break;
    }
  },
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
});

const PathwayDetailsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayDetailsView);

export default PathwayDetailsContainer;
