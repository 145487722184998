import { fromJS } from 'immutable';
import differenceInFullYears from 'date-fns/differenceInYears';
import addressValidator from '../../../../common/form/helpers/address_validator';

const EMPTY_MAP = fromJS({});

const validateUser = (attrs) => {
  const errors = {};
  if (!attrs || !attrs.get('firstName')) {
    errors.firstName = 'Please enter your first name';
  }
  if (!attrs || !attrs.get('lastName')) {
    errors.lastName = 'Please enter your last name';
  }
  if (attrs && attrs.get('phone') && attrs.get('phone').match(/\d/g).length < 10) {
    errors.phone = 'Please enter a valid phone number';
  }

  return errors;
};

const personalInfoFormValidate = (values) => {
  const errors = {};

  errors.userAttributes = validateUser(values.get('userAttributes'));
  errors.addressAttributes = addressValidator(values.get('addressAttributes', EMPTY_MAP).merge({ isPartialAddress: true }));

  if (!values || !values.get('birthday')) {
    errors.birthday = 'Please enter your birthday';
  }

  if (values.get('birthday') && differenceInFullYears(Date.now(), values.get('birthday')) < 14) {
    errors.birthday = 'Age must be 14 or older';
  }

  if (!values || !values.get('genderType')) {
    errors.genderType = 'Please select an option';
  }

  if (!values || !values.get('ethnicityType')) {
    errors.ethnicityType = 'Please select an option';
  }

  return errors;
};

export default personalInfoFormValidate;
