import styled from 'styled-components';
import theme from '../../../common/theme';

const HeaderStyles = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background-color: #f2faff;
`;

export const Contact = styled.div`
  h2 {
    margin: 20px 0 10px 0;
  }
  p {
    font-size: 0.8em;
    margin: 5px 0;
  }
  margin-right: 20px;
`;

export const StatusContainer = styled.div`
  background: #fff;
  padding: 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  h3 {
    color: ${theme.color.blue};
    margin: 0;
    font-size: 0.9em;
  }
  p {
    margin: 0;
    font-size: 0.8em;
    color: #c0c0c0;
  }
`;

export const Statuses = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Status = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 20px;
  color: ${(props) => props.color || '#ccc'};
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
`;

export default HeaderStyles;
