/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';

import UserModel from 'session/models/user_model';

import EventPreviewStyles from './event_preview_styles';
import { MainStyles, MainCard, Logo, MainActions } from '../../../../common/styles/detail';
import getListingImage from '../../../../common/components/get_listing_image';
import EventDetails from '../../../../events/components/event_details';
import PreviewButtons from '../../posting_previews/preview_buttons';

const propTypes = {
  invitation: PropTypes.instanceOf(Map).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  setRedirectPath: PropTypes.func.isRequired,
};

const defaultProps = {};

const EventPreviewView = ({ invitation, currentUser, setRedirectPath }) => {
  const isCurrentUserSignedIn = currentUser?.signedIn;

  const event = invitation.get('recommendedEvent') || new Map();

  const logoUrl = getListingImage(event, 'event');

  const redirectPath = `/candidate/events/${event.get('id')}`;

  useEffect(() => {
    setRedirectPath(redirectPath);
  }, [redirectPath, setRedirectPath]);

  if (isCurrentUserSignedIn) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <EventPreviewStyles>
      {event && (
        <MainStyles noMargin>
          <div className="candidate-wrapper">
            <MainCard>
              <MainActions>
                <Logo>
                  <img src={logoUrl} alt="Event Logo" />
                </Logo>
                <PreviewButtons previewType="event" redirectPath={redirectPath} />
              </MainActions>

              <EventDetails event={event} />
            </MainCard>
          </div>
        </MainStyles>
      )}
    </EventPreviewStyles>
  );
};

EventPreviewView.propTypes = propTypes;
EventPreviewView.defaultProps = defaultProps;

export default EventPreviewView;
