import * as API from '../api';

import { genericAPIActionCreator, genericAPIActionTypes } from '../util';

const ACTIONS = {
  SET_MODE: 'Onboarding:SetMode',
  COMPLETE: 'Onboarding:Complete',
  TOGGLE_NEED: 'Onboarding:ToggleNeed',
  UPDATE_CANDIDATE: genericAPIActionTypes('Onboarding:UpdateCandidate'),
};

export default ACTIONS;

export const completeOnboarding = () => ({
  type: ACTIONS.COMPLETE,
});

export const setOnboardingMode = (mode) => ({
  type: ACTIONS.SET_MODE,
  mode,
});

export const toggleOnboardingNeed = (need) => ({
  type: ACTIONS.TOGGLE_NEED,
  need,
});

export const updateCandidate = (user, candidateId, props) =>
  genericAPIActionCreator(() => API.updateCandidate(user, candidateId, props), ACTIONS.UPDATE_CANDIDATE, undefined, { props });
