import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  LongTextInput,
  ReferenceField,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  NumberInput,
  Pagination,
} from 'react-admin';
import HelpIcon from '@material-ui/icons/Help';

export const Icon = HelpIcon;
// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} pagination={<PostPagination />}>
    <Datagrid>
      <ReferenceField label="Survey" source="surveyId" reference="surveys">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Question Template" source="questionTemplateId" reference="questionTemplates">
        <TextField source="title" />
      </ReferenceField>
      <NumberField source="position" />
      <TextField source="questionImportanceNow" />
      <TextField source="extra" />
      <EditButton basePath="/surveyQuestions" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>GlobalSetting {record ? `"${record.name}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <NumberInput source="position" />
      <TextInput source="questionImportanceType" />
      <LongTextInput source="extra" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <ReferenceInput label="Survey" source="surveyId" reference="surveys">
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <ReferenceInput label="QuestionTemplate" source="questionTemplateId" reference="questionTemplates">
        <AutocompleteInput optionText="title" />
      </ReferenceInput>
      <NumberInput source="position" />
      <TextInput source="questionImportanceType" />
      <LongTextInput source="extra" />
    </SimpleForm>
  </AdminCreate>
);
