import React, { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import SignInStyles from './sign_in_styles';
import { Field, TextField } from '../../../common/form';
import { Spinner, Text } from '../../../common/components';
import { useApiCall } from '../../../util/hooks';
import { OAUTH } from '../../../api';
import PublicContainer from '../../../solve/components/public_container';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  isRisekit: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      returnTo: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({ pathname: PropTypes.string })]),
    }),
  }).isRequired,
};

const defaultProps = {
  pristine: false,
  invalid: false,
};

const oauthButton = (provider, returnTo) => (
  <a key={provider} href={OAUTH.getUrl(provider, returnTo)} className="oauth-button">
    {provider === 'developer' ? <i className="fas fa-user-secret fa-2x" /> : <i className={`fab fa-${provider} fa-5x`} />}
  </a>
);

const DEFAULT_RETURN_TO = '/dashboard';

const SignInView = ({ signedIn, handleSubmit, pristine, invalid, isRisekit, location: { state } }) => {
  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  if (signedIn) {
    return <Redirect to={returnTo} />;
  }

  const oauthRow = (
    <div className="oauth-row">
      <span>Sign in with:</span>
      {OAUTH.providers.map((provider) => oauthButton(provider, returnTo))}
    </div>
  );

  return (
    <PublicContainer>
      <div className="container">
        <div className="auth">
          <SignInStyles>
            <form onSubmit={onSubmit}>
              <h3>Sign In</h3>
              <p>
                <em>{`Welcome to the new ${isRisekit ? 'RiseKit' : 'Solve'}!`}</em>
              </p>
              <p>
                <em>
                  We&apos;ve updated our software platform to give you a better experience. We&apos;re still working on some features so don&apos;t be alarmed
                  if something is missing. If you experience any difficulty or receive an error message, please take a screen capture and forward it with a
                  brief description of what happened to info@risekit.co or call us at 312.500.4885.
                </em>
              </p>
              <p>
                <em>
                  If you are an existing user, please <Link to="/forgot">reset your password</Link> first and then sign in. We&apos;ve moved to new
                  infrastructure that requires a password reset for security purposes. Thank you!
                </em>
              </p>
              <div className="google-translate-container">
                <div id="google_translate_element" />
              </div>
              <Field component={TextField} label="Email or Mobile Phone Number (including area code)" type="text" name="email" fullWidth required />
              <Field component={TextField} label="Password" type="password" name="password" fullWidth required />
              <input type="submit" className="btn" value="Login" disabled={pristine || invalid || submitting} />
              <span className="status">
                {failed && <span className="error">Invalid credentials</span>}
                {submitting && <Spinner />}
              </span>
              <Text>
                <Link to="/forgot">Lost your password?</Link>
                <span style={{ padding: '0 1em' }}>|</span>
                <div style={{ display: 'inline-block' }}>
                  <Link to="/register">Don&apos;t have an account?</Link>
                </div>
              </Text>
              {OAUTH.providers.size > 0 && oauthRow}
            </form>
          </SignInStyles>
        </div>
      </div>
    </PublicContainer>
  );
};

SignInView.propTypes = propTypes;
SignInView.defaultProps = defaultProps;

export default SignInView;
