import { fromJS } from 'immutable';

const EMPTY_MAP = fromJS({});
function addressValidator(values = EMPTY_MAP) {
  const errors = {};

  const isPartial = values?.get('isPartialAddress');

  if (!isPartial) {
    if (!values.get('address1')) {
      errors.address1 = 'Please enter a street address';
    }
    if (!values.get('city')) {
      errors.city = 'Please enter a city';
    }
    if (!values.get('state')) {
      errors.state = 'Please enter a state';
    }
  }

  if (values.get('state') && !/^[a-zA-Z]{2}$/.test(values.get('state'))) {
    errors.state = 'Please enter the two-letter state abbreviation';
  }

  if (!values.get('zip')) {
    errors.zip = 'Please enter a zip code';
  } else if (/(^\d{5}$)/.test(values.get('zip')) === false) {
    errors.zip = 'Zip Code is invalid';
  }

  return errors;
}

export default addressValidator;
