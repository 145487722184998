import React from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { useFetchOnce } from '../../../util/hooks';

import PathwayList from './pathway_list';

const CandidatePathwaysView = ({ user, candidatePathways, fetchCandidatePathwaysForCandidate }) => {
  const resolved = useFetchOnce(user, user.get('candidateId'), fetchCandidatePathwaysForCandidate);

  return (
    <div>
      <h1>My Pathways</h1>
      <PathwayList candidatePathwayInstances={candidatePathways} loaded={resolved} />
    </div>
  );
};

CandidatePathwaysView.propTypes = {
  user: PropTypes.instanceOf(Map).isRequired,
  candidatePathways: PropTypes.instanceOf(List),
  fetchCandidatePathwaysForCandidate: PropTypes.func.isRequired,
};
CandidatePathwaysView.defaultProps = {
  candidatePathways: undefined,
};

export default CandidatePathwaysView;
