import { connect } from 'react-redux';
import { OrderedMap } from 'immutable';

import ProgramListView from './program_list_view';
import {
  getStaffEPFilteredEventIds,
  getStaffEPFilteredProgramIds,
  getStaffEPFilteredServiceIds,
  getStaffEPFilteredEventsLoaded,
  getStaffEPFilteredServicesLoaded,
  getStaffEPFilteredProgramsLoaded,
  getResourceIdsSortedForStaff,
  getStaffEPABServiceIds,
  getStaffEPABServicesLoaded,
  getResourceIdsPaginatedForStaff,
} from '../../../reducer';

const mapStateToProps = (state, { preFilter, pageShown, setPageShown }) => {
  const eventIds = getStaffEPFilteredEventIds(state);
  const programIds = getStaffEPFilteredProgramIds(state);
  const abServiceIds = getStaffEPABServiceIds(state);
  const serviceIds = getStaffEPFilteredServiceIds(state);
  const resourceIdsSorted = getResourceIdsSortedForStaff(state);
  const resourceIdsPaginated = getResourceIdsPaginatedForStaff(state);
  const eventsLoaded = getStaffEPFilteredEventsLoaded(state);
  const programsLoaded = getStaffEPFilteredProgramsLoaded(state);
  const abServicesLoaded = getStaffEPABServicesLoaded(state);
  const servicesLoaded = getStaffEPFilteredServicesLoaded(state);

  const filteredEventsListTotalCount = +state.getIn(['solve', 'staffEPCandidates', 'filteredEventsListTotalCount']);
  const filteredProgramsListTotalCount = +state.getIn(['solve', 'staffEPCandidates', 'filteredProgramsListTotalCount']);
  const filteredServicesTotalCount = +state.getIn(['solve', 'staffEPCandidates', 'filteredServicesTotalCount']);

  const totalPostingCount = filteredEventsListTotalCount + filteredProgramsListTotalCount + filteredServicesTotalCount;
  const programIdsMap = programIds.map((id) => [id, { id, type: 'program' }]);
  const eventIdsMap = eventIds.map((id) => [id, { id, type: 'event' }]);
  const serviceIdsMap = serviceIds.map((id) => [id, { id, type: 'service' }]);
  const abServiceIdsMap = abServiceIds.map((id) => [id, { id, type: 'service' }]);

  const allPostingIds = [...programIdsMap, ...eventIdsMap, ...serviceIdsMap, ...abServiceIdsMap];
  const isFetchingFilteredEvents = state.getIn(['solve', 'staffEPCandidates', 'filteredEventsLoading']);
  const isFetchingFilteredPrograms = state.getIn(['solve', 'staffEPCandidates', 'filteredProgramsLoading']);
  const isFilteredServices = state.getIn(['solve', 'staffEPCandidates', 'filteredServicesLoading']);

  const allPostingsMap = new OrderedMap(allPostingIds);
  const postingIds = resourceIdsPaginated.map((id) => allPostingsMap.get(id));

  return {
    preFilter,
    postingIds,
    totalPostingCount,
    programsLoaded,
    eventsLoaded,
    servicesLoaded,
    abServicesLoaded,
    pageShown,
    setPageShown,
    totalCount: resourceIdsSorted.size,
    isFetchingFilteredEvents,
    isFetchingFilteredPrograms,
    isFilteredServices,
  };
};

const ProgramListContainer = connect(mapStateToProps)(ProgramListView);

export default ProgramListContainer;
