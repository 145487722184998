import React, { useEffect } from 'react';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { startCase } from 'lodash';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import LocationIcon from '@material-ui/icons/Room';
import MoneyIcon from '@material-ui/icons/Money';
import Chip from '@material-ui/core/Chip';
import { Card } from '../../../../common/styles/layout';
import { reduxForm } from '../../../../common/form';
import { Button, TextHeading } from '../../../../common/components';
import reviewFormValidate from './review_form_validate';
import ReviewFormStyles, { JobDetailsStyles, JobReviewStyles } from './review_form_styles';
import theme from '../../../../common/theme';

const formatPay = (type, amount) => {
  if (!numeral(amount).value()) {
    return 'Not Provided';
  }

  const formattedAmount = numeral(amount).format('$0,0');
  switch (type) {
    case 'hourly':
      return `${formattedAmount}/hour`;
    case 'salary':
      return `${formattedAmount}/year`;
    default:
      return `${formattedAmount} ${type}`;
  }
};

const propTypes = {
  // userType: PropTypes.oneOf(['staff', 'employer']).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  // onSaveDraft: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  // jobPosting: PropTypes.instanceOf(Map),
  title: PropTypes.string,
  employer: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
  payType: PropTypes.string,
  salary: PropTypes.string,
  address: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  openings: PropTypes.number,
  logo: PropTypes.instanceOf(FileList),
  industryType: PropTypes.string,
  postExpirationDate: PropTypes.instanceOf(Date),
  applicationUrl: PropTypes.string,
  customFollowUpMessage: PropTypes.string,
  jobResponsibilities: PropTypes.instanceOf(List),
  requiredSkills: PropTypes.instanceOf(List),
  desiredSkills: PropTypes.instanceOf(List),
  tradeCertifications: PropTypes.instanceOf(List),
  benefits: PropTypes.instanceOf(List),
  opportunities: PropTypes.instanceOf(List),
};

const defaultProps = {
  // jobPosting: new Map(),
  employer: undefined,
  title: undefined,
  type: undefined,
  description: undefined,
  payType: undefined,
  salary: undefined,
  address: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
  startDate: undefined,
  endDate: undefined,
  openings: undefined,
  logo: undefined,
  industryType: undefined,
  postExpirationDate: undefined,
  applicationUrl: undefined,
  customFollowUpMessage: undefined,
  jobResponsibilities: new List(),
  requiredSkills: new List(),
  desiredSkills: new List(),
  tradeCertifications: new List(),
  benefits: new List(),
  opportunities: new List(),
};

const ReviewForm = ({
  // userType,
  handleSubmit,
  previousPage,
  onSaveDraft,
  pristine,
  submitting,
  // jobPosting,
  title,
  employer,
  type,
  description,
  payType,
  salary,
  address,
  city,
  state,
  zip,
  startDate,
  endDate,
  openings,
  logo,
  industryType,
  postExpirationDate,
  applicationUrl,
  customFollowUpMessage,
  jobResponsibilities,
  requiredSkills,
  desiredSkills,
  tradeCertifications,
  benefits,
  opportunities,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ReviewFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <TextHeading size="medium" weight="bold" margin="both" uppercase>
              Candidate facing information
            </TextHeading>
            <JobDetailsStyles>
              <Card>
                {logo && <img className="logo" src={URL.createObjectURL(logo[0])} alt={employer} />}
                <h1 style={{ textTransform: 'capitalize' }}>{title}</h1>
                <h4>{employer}</h4>
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
                  {city || state ? (
                    <React.Fragment>
                      <LocationIcon style={{ color: theme.color.positive, marginRight: 5 }} />
                      <h4>
                        {city && `${city}, `}
                        {state}
                      </h4>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <LocationIcon style={{ color: theme.color.positive, marginRight: 5 }} />
                      <h4>{zip}</h4>
                    </React.Fragment>
                  )}
                  <MoneyIcon style={{ color: theme.color.positive, marginRight: 5, marginLeft: 10 }} />
                  <h4>{formatPay(payType, salary)}</h4>
                </div>
                <Chip label={type} />
                <p>
                  <span className="sub-heading">Industry: </span>
                  {startCase(industryType)}
                </p>
                {/* TODO: Other Chips? */}
                <p style={{ marginTop: 30, marginBottom: 60 }}>
                  <div dangerouslySetInnerHTML={{ __html: description }} />
                  {/* TODO: Add "View More" if long */}
                </p>
                {benefits && (
                  <Grid item xs={12}>
                    <p>
                      <span className="sub-heading">Benefits:</span>
                    </p>
                    <ul>
                      {benefits.entrySeq().map((benefit) => (
                        <li key={benefit[0]}>
                          {startCase(benefit[0])} : {startCase(benefit[1])}
                        </li>
                      ))}
                    </ul>
                  </Grid>
                )}
              </Card>
            </JobDetailsStyles>
          </Grid>
          <Grid item xs={12}>
            <TextHeading size="medium" weight="bold" margin="both" uppercase>
              Additional job information (candidate will not see)
            </TextHeading>
            <JobReviewStyles>
              <Card>
                <Grid item xs={12}>
                  <Grid item container>
                    <Grid item xs={12}>
                      <p>
                        <span className="sub-heading">Address: </span>
                        {address && `${address}, `}
                        {city && `${city} `}
                        {state && `${state}, `}
                        {zip}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        <span className="sub-heading">Number of Openings:</span> {`${openings || 'N/A'}`}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        <span className="sub-heading">Start Date:</span> {startDate ? `${moment(startDate).format('MM/DD/YY')}` : 'N/A'}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        <span className="sub-heading">End Date:</span> {endDate ? `${moment(endDate).format('MM/DD/YY')}` : 'N/A'}
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        <span className="sub-heading">Expiration Date:</span> {`${moment(postExpirationDate).format('MM/DD/YY') || 'N/A'}`}
                      </p>
                    </Grid>
                    {applicationUrl ? (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Application URL:</span> {applicationUrl}
                        </p>
                      </Grid>
                    ) : null}
                    {customFollowUpMessage ? (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Custom Follow Up Message:</span> {customFollowUpMessage}
                        </p>
                      </Grid>
                    ) : null}
                    {jobResponsibilities && jobResponsibilities.size ? (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Job Responsibilities:</span>
                        </p>
                        <ul>{jobResponsibilities && jobResponsibilities.map((responsibility) => <li key={responsibility}>{responsibility}</li>)}</ul>
                      </Grid>
                    ) : null}
                    {requiredSkills && requiredSkills.size ? (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Required Skills:</span>
                        </p>
                        <ul>{requiredSkills && requiredSkills.map((skill) => <li key={skill}>{skill}</li>)}</ul>
                      </Grid>
                    ) : null}
                    {desiredSkills && desiredSkills.size ? (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Desired Skills:</span>
                        </p>
                        <ul>{desiredSkills && desiredSkills.map((skill) => <li key={skill}>{skill}</li>)}</ul>
                      </Grid>
                    ) : null}
                    {tradeCertifications && tradeCertifications.size ? (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Desired Certifications:</span>
                        </p>
                        <ul>
                          {tradeCertifications &&
                            tradeCertifications.map((certification) => (
                              <li key={certification}>{startCase(certification.getIn(['certification', 'name']))}</li>
                            ))}
                        </ul>
                      </Grid>
                    ) : null}
                    {/* <Grid item xs={12}>
                      <p>
                        <span className="sub-heading">Desired Education:</span>
                      </p>
                      <ul>{desiredEducation && desiredEducation.map((education) => <li key={education}>{education}</li>)}</ul>
                    </Grid> */}
                    {opportunities && (
                      <Grid item xs={12}>
                        <p>
                          <span className="sub-heading">Opportunities:</span>
                        </p>
                        <ul>
                          {opportunities.entrySeq().map((opportunity) => (
                            <li key={opportunity[0]}>
                              {startCase(opportunity[0])} : {startCase(opportunity[1])}
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    )}
                  </Grid>
                  {/* <Grid item xs={12}>
                    <SurveyBuilder surveyType="screening" />
                  </Grid> */}
                </Grid>
              </Card>
              {/* <Select */}
              {/*  labelId="jobState" */}
              {/*  id="jobState" */}
              {/*  value={jobPosting.get('state') || 'active'} */}
              {/*  onChange={onChange} */}
              {/*  style={{ */}
              {/*    float: 'right', */}
              {/*  }} */}
              {/* > */}
              {/*  <MenuItem value="active">Active</MenuItem> */}
              {/*  <MenuItem value="inactive">Inactive</MenuItem> */}
              {/* </Select> */}
            </JobReviewStyles>
          </Grid>
          {/* userType === 'staff' && (
            <Grid item xs={12}>
              <TextHeading size="medium" weight="bold" margin="both" uppercase>
                Job sharing information
              </TextHeading>
              <Card>
                <TextHeading size="xsmall" color="medium">
                  If you would like to share this Job Posting outside of your own organization, choose from the collaborate networks.
                </TextHeading>
                <TextHeading size="medium" margin="top" weight="bold">
                  Share posting with:*
                </TextHeading>
                <Field name="jobSharingType" component={MuiRadioGroup}>
                  <FormGroup column>
                    <FormControlLabel value="myCandidates" control={<Radio color="primary" />} label="Only my candidates" labelPlacement="end" />
                    <FormControlLabel value="wholeNetwork" control={<Radio color="primary" />} label="The whole network" labelPlacement="end" />
                  </FormGroup>
                </Field>
              </Card>
            </Grid>
          ) */}
        </Grid>

        <div className="button-group">
          <div className="button-left">
            <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
              Previous
            </Button>
          </div>
          {state !== 'active' ? (
            <div className="button-right">
              <Button buttonType="secondary-outline" type="button" disabled={submitting} onClick={handleSubmit((values) => onSaveDraft(values))}>
                Save Draft
              </Button>
              <Button buttonType="secondary" disabled={pristine || submitting} type="submit">
                List Job Posting
              </Button>
            </div>
          ) : (
            <div className="button-right">
              <Link to="/staff/resources/">
                <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting}>
                  Discard Changes
                </Button>
              </Link>
              <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={handleSubmit((values) => onSaveDraft(values))}>
                Save Job
              </Button>
              <Button buttonType="secondary" disabled={pristine || submitting} type="submit">
                Publish Job
              </Button>
            </div>
          )}
        </div>
      </form>
    </ReviewFormStyles>
  );
};

ReviewForm.propTypes = propTypes;
ReviewForm.defaultProps = defaultProps;

const ReviewFormView = reduxForm({
  form: 'jobForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: reviewFormValidate,
})(ReviewForm);

export default ReviewFormView;
