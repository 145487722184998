import styled from 'styled-components';
import theme from '../theme';

export const MainStyles = styled.div`
  margin: ${(props) => (props.noMargin ? '0px' : '-25px')};
  padding: 25px 50px;
  background-color: #f7f7f7;
  min-height: 100vh;

  @media only screen and (max-width: 1000px) {
    padding: 25px 25px;
  }
`;

export const MainCard = styled.div`
  position: relative;
  margin: 10px auto 20px;
  padding: 50px;
  color: ${theme.color.text.dark};
  background-color: white;
  box-shadow: 5px 5px 40px 0 rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 1000px) {
    padding: 35px;
  }
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const Logo = styled.div`
  img {
    max-width: 150px;
    max-height: 100px;
    width: auto;
  }

  @media only screen and (max-width: 1000px) {
    float: right;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    flex-direction: column
    padding-left: 20px;
    padding-bottom: 15px;
  }
`;

export const Status = styled.div`
  float: right;
  text-align: right;
  padding-bottom: 15px;

  a {
    display: block;
    margin-top: 5px;
  }

  @media only screen and (max-width: 1000px) {
    width: 100%;
    border-bottom: solid 1px ${theme.color.grayLighter};
    margin-bottom: 15px;
  }
`;

export const MobileView = styled.div`
  display: none;

  @media only screen and (max-width: 1000px) {
    display: block;
  }
`;

export const DesktopView = styled.div`
  display: block;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
  padding-top: ${(props) => (props.applied ? '25px' : '0px')};
  border-top: ${(props) => (props.applied ? `solid 1px ${theme.color.grayLighter}` : 'none')};
  clear: both;

  @media only screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    padding-top: 0px;
    border-top: 0px;
  }
`;

export const MainColumn = styled.div`
  flex: 1;
  margin-right: 30px;

  @media only screen and (max-width: 1000px) {
    width: 100%;
    margin-right: 0px;
  }
`;

export const Pill = styled.span`
  height: 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px;
  border: solid 1px ${theme.color.grayLight};
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 11px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const Title = styled.div`
  margin: 5px 0px 2px;
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: bold;
`;

export const Organization = styled.div`
  margin-bottom: 35px;
`;

export const MetaCategory = styled.div`
  margin-bottom: 15px;
`;

export const MetaGroup = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;

  svg {
    margin-top: -2px;
  }
`;

export const MetaSubGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MetaLabel = styled.div`
  font-weight: bold;
`;

export const MetaValue = styled.div``;

export const MetaIcon = styled.div`
  color: ${theme.color.grayLight};
  margin-right: 10px;
`;

export const SideBar = styled.div`
  max-width: 250px;
  width: 100%;

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 1000px) {
    & {
      max-width: none;
      margin-bottom: 25px;
    }

    .button {
      max-width: 250px;
    }
  }
`;

export const MetaHeading = styled.div`
  font-weight: bold;
`;

export const MainActions = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: solid 1px ${theme.color.grayLighter};
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-left: 15px;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 400px) {
    a {
      width: 100%;
    }
    button {
      width: 100% !important;
      margin-left: 0px;
    }
  }
`;

export const IndeedLabel = styled.div`
  color: ${theme.color.gray};
`;
