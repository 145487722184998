import BaseNotificationHelper from './base_notification_helper';

class TransitionNotificationHelper extends BaseNotificationHelper {
  constructor(params) {
    super(params);

    ({ subjectIdKey: this.subjectIdKey, subjectTitleKey: this.subjectTitleKey, urlTemplate: this.urlTemplate } = params);
    this.resource = this.notification.getIn(['info', 'resource']);
    this.transition = this.notification.getIn(['info', 'transition']);
  }

  getActivity() {
    let activity = null;

    if (this.template) {
      activity = this.template.activity;
      if (activity) {
        activity = activity.replace('<title>', this.getSubjectTitle());
      }
    }
    return activity || '';
  }

  getDescription() {
    let description = null;

    if (this.template) {
      const title = this.getSubjectTitle();
      const candidateName = this.getResourceCandidate();
      const postingOrgName = this.getPostingOrganization();
      const employerName = this.getEmployer();
      const externalSource = this.getExternalSource();
      const staffName = this.getPerformedByStaff();
      const staffOrgName = this.getPerformedByOrgName();
      const url = this.getUrl();

      description = this.template.description;
      if (description) {
        description = description.replace('<title>', title);
        description = description.replace('<candidateName>', candidateName);
        description = description.replace('<postingOrgName>', postingOrgName);
        description = description.replace('<employerName>', employerName);
        description = description.replace('<externalSource>', externalSource);
        description = description.replace('<staffName>', staffName);
        description = description.replace('<staffOrgName>', staffOrgName);
        if (description.includes('<here>')) {
          description = description.concat('~<here>');
          description = description.concat(`~/${url}`);
        }
      }
    }
    return description || '';
  }

  getUrl() {
    const id = this.getSubjectId();
    return this.urlTemplate.replace('<urlNode>', this.getUrlNode()).replace('<id>', id);
  }

  getSubjectId() {
    return this.resource?.get(this.subjectIdKey) || '';
  }

  getSubjectTitle() {
    return this.resource?.get(this.subjectTitleKey) || '';
  }

  getPerformedByCandidate() {
    const candidate = this.transition?.getIn(['performedBy', 'candidate']) || this.notification.getIn(['info', 'updatedBy', 'candidate']);
    if (candidate) {
      return `${candidate.get('firstName')} ${candidate.get('lastName')}`;
    }
    return '';
  }

  getPerformedByStaff() {
    const staff = this.transition?.getIn(['performedBy', 'staff']) || this.notification.getIn(['info', 'updatedBy', 'staff']);
    if (staff) {
      return `${staff.get('firstName')} ${staff.get('lastName')}`;
    }
    return '';
  }

  getPerformedByOrgName() {
    const staff = this.transition?.getIn(['performedBy', 'staff']) || this.notification.getIn(['info', 'updatedBy', 'staff']);
    if (staff) {
      return staff.getIn(['organization', 'name']);
    }
    return '';
  }

  getPostingOrganization() {
    return this.resource?.get('postingOrganizationName') || '';
  }

  getEmployer() {
    return this.resource?.get('employerName') || '';
  }

  getExternalSource() {
    return this.resource?.get('externalJobSourceType') || '';
  }

  getResourceCandidate() {
    const candidate = this.resource?.get('candidate');
    if (candidate) {
      return `${candidate.get('firstName')} ${candidate.get('lastName')}`;
    }
    return '';
  }
}

export default TransitionNotificationHelper;
