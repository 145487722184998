import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import JobList from '../job_list';
import JobListingsStyles from './job_listings_styles';
import Tabber from '../../../ui/components/tabber';

// NOTE: This is only to avoid a JS Warning in the browser console
const StyledDiv = styled.div``;

const propTypes = {};

const defaultProps = {};

const MODES = ['all', 'myOrg', 'drafts'];

const JobListingsView = () => {
  const [tab, setTab] = useState(0);
  const mode = MODES[tab];

  return (
    <JobListingsStyles>
      <Link to="/staff/jobs/new">
        <Button variant="outlined" color="secondary" size="large" style={{ float: 'right' }}>
          + Add a Job
        </Button>
      </Link>
      <h1>Jobs</h1>
      <Tabber onChange={setTab}>
        <StyledDiv tabLabel="All Jobs" />
        <StyledDiv tabLabel="My Organization Postings" />
        <StyledDiv tabLabel="Drafts" />
      </Tabber>
      <JobList mode={mode} />
    </JobListingsStyles>
  );
};

JobListingsView.propTypes = propTypes;
JobListingsView.defaultProps = defaultProps;

export default JobListingsView;
