import { AUTH_CHECK, AUTH_ERROR, AUTH_LOGIN, AUTH_LOGOUT } from 'react-admin';
import { signIn } from '../api';

export const AUTH_TOKEN_KEY = 'at';

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN:
      return signIn(params.username, params.password).then(
        (response) => {
          const {
            data: { authToken, roles },
          } = response;
          if (roles.find((role) => role === 'Admin')) {
            localStorage.setItem(AUTH_TOKEN_KEY, authToken);
          } else {
            throw Error('Not Authorized');
          }
        },
        (_error) => {
          throw Error('Not Authorized');
        },
      );
    case AUTH_LOGOUT:
      localStorage.removeItem(AUTH_TOKEN_KEY);
      return Promise.resolve();
    case AUTH_ERROR:
      if (params.status === 401 || params.status === 403) {
        localStorage.removeItem(AUTH_TOKEN_KEY);
        return Promise.reject();
      }
      return Promise.resolve();
    case AUTH_CHECK:
      return localStorage.getItem(AUTH_TOKEN_KEY) ? Promise.resolve() : Promise.reject();
    default:
      return Promise.reject(new Error('Unknown Method'));
  }
};
