import React from 'react';
import { Admin as ReactAdmin, Resource, Login } from 'react-admin';
import authProvider from './auth_provider';
import dataProvider from './data_provider';
import * as User from './resource/user';
import * as Address from './resource/address';
import * as SystemEnum from './resource/system_enum';
import * as GlobalSetting from './resource/global_setting';
import * as Organization from './resource/organization';
import * as Candidate from './resource/candidate';
import * as Inmate from './resource/inmate';
import * as CandidateSurvey from './resource/candidate_survey';
import * as JobPosting from './resource/job_posting';
import * as Event from './resource/event';
import * as Program from './resource/program';
import * as Message from './resource/message';
import * as Notification from './resource/notification';
import * as QuestionTemplate from './resource/question_template';
import * as Resume from './resource/resume';
import * as Survey from './resource/survey';
import * as SurveyAnswer from './resource/survey_answer';
import * as SurveyQuestion from './resource/survey_question';
import * as Staff from './resource/staff';

const LoginPage = () => <Login backgroundImage="/images/og.image.png" />;

const Admin = () => (
  <ReactAdmin dataProvider={dataProvider} authProvider={authProvider} loginPage={LoginPage}>
    <Resource name="users" list={User.List} edit={User.Edit} create={User.Create} icon={User.Icon} />
    <Resource name="addresses" list={Address.List} edit={Address.Edit} create={Address.Create} icon={Address.Icon} />
    <Resource name="organizations" list={Organization.List} edit={Organization.Edit} create={Organization.Create} icon={Organization.Icon} />
    <Resource name="global_settings" list={GlobalSetting.List} edit={GlobalSetting.Edit} create={GlobalSetting.Create} icon={GlobalSetting.Icon} />
    <Resource name="system_enums" list={SystemEnum.List} edit={SystemEnum.Edit} create={SystemEnum.Create} icon={SystemEnum.Icon} />

    <Resource name="candidates" list={Candidate.List} edit={Candidate.Edit} create={Candidate.Create} icon={Candidate.Icon} />
    <Resource name="inmates" list={Inmate.List} edit={Inmate.Edit} create={Inmate.Create} icon={Inmate.Icon} />
    <Resource name="candidate_surveys" list={CandidateSurvey.List} edit={CandidateSurvey.Edit} create={CandidateSurvey.Create} icon={CandidateSurvey.Icon} />
    <Resource name="job_postings" list={JobPosting.List} edit={JobPosting.Edit} create={JobPosting.Create} icon={JobPosting.Icon} />
    <Resource name="events" list={Event.List} edit={Event.Edit} create={Event.Create} icon={Event.Icon} />
    <Resource name="programs" list={Program.List} edit={Program.Edit} create={Program.Create} icon={Program.Icon} />
    <Resource name="messages" list={Message.List} edit={Message.Edit} create={Message.Create} icon={Message.Icon} />
    <Resource name="notifications" list={Notification.List} edit={Notification.Edit} create={Notification.Create} icon={Notification.Icon} />
    <Resource
      name="question_templates"
      list={QuestionTemplate.List}
      edit={QuestionTemplate.Edit}
      create={QuestionTemplate.Create}
      icon={QuestionTemplate.Icon}
    />
    <Resource name="resumes" list={Resume.List} edit={Resume.Edit} create={Resume.Create} icon={Resume.Icon} />
    <Resource name="staffs" list={Staff.List} edit={Staff.Edit} create={Staff.Create} icon={Staff.Icon} />
    <Resource name="surveys" list={Survey.List} edit={Survey.Edit} create={Survey.Create} icon={Survey.Icon} />
    <Resource name="survey_answers" list={SurveyAnswer.List} edit={SurveyAnswer.Edit} create={SurveyAnswer.Create} icon={SurveyAnswer.Icon} />
    <Resource name="survey_questions" list={SurveyQuestion.List} edit={SurveyQuestion.Edit} create={SurveyQuestion.Create} icon={SurveyQuestion.Icon} />
  </ReactAdmin>
);

export default Admin;
