import { client, tokenAuth } from './config';

const axios = client();

const AB_CANDIDATES_POSSIBLE_FILTERS = ['terms', 'serviceType', 'targetParticipantType', 'zipCode'];
const toUnderscore = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
const buildABCandidatesFilters = (args) => {
  const filters = [''];
  AB_CANDIDATES_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });

  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

export const listABSupportiveServices = (user, { ...rest } = {}) => {
  const baseUrl = '/ab/services';
  const filters = buildABCandidatesFilters(rest);
  return axios.get(`${baseUrl}?${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listABSupportiveService = (user, aBSupportiveServiceId) => {
  const baseUrl = `/ab/services/${aBSupportiveServiceId}`;
  return axios.get(`${baseUrl}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const createExternalCandidateService = (user, aBSupportiveServiceId) => {
  const baseUrl = `/v1/candidates/${user.get(
    'candidateId',
  )}/external_candidate_services?external_resource_uid=${aBSupportiveServiceId}&external_provider=aunt-bertha`;
  return axios.post(`${baseUrl}`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });
};
export const candidateApplyToExternalService = (user, aBSupportiveServiceId) => {
  const baseUrl = `/v1/candidates/${user.get('candidateId')}/external_candidate_services/${aBSupportiveServiceId}/apply?external_provider=aunt-bertha`;
  return axios.post(`${baseUrl}`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });
};
