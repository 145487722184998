import React from 'react';
import PropTypes from 'prop-types';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Map } from 'immutable';
// import StepperSmallView from '../../../ui/components/stepper_small';
import HeaderStyles, { Contact, StatusContainer, Statuses, Status } from './header_styles';
import CustomApplicantStepper from '../../../jobs/application_list_item/custom_applicant_stepper';
import { candidateStaffStatusEnums } from '../../../enums/staff_status_enums';
import UserModel from '../../../session/models/user_model';
import CandidateApplicationModel from '../../../solve/models/candidate_application_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  transitionCandidateApplicationByStaff: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  application: PropTypes.oneOfType([PropTypes.instanceOf(CandidateApplicationModel), PropTypes.instanceOf(Map)]).isRequired,
};

const defaultProps = {};

const { recommended, appliedOnSolve, appliedOnSite, reviewed, interviewing, offer, hired, archived } = candidateStaffStatusEnums; // eslint-disable-line no-unused-vars, max-len

const candidateSteps = [
  { label: 'Recommended/Saved', value: recommended },
  { label: 'Applied On RiseKit', value: appliedOnSolve, optional: true },
  { label: 'Applied On Company Website', value: appliedOnSite, optional: true },
  { label: 'Reviewed', value: reviewed },
  { label: 'Interviewing', value: interviewing },
  { label: 'Offer', value: offer },
];

const candidateStepMapping = {
  [recommended]: 0,
  [appliedOnSolve]: 1,
  [appliedOnSite]: 2,
  [reviewed]: 3,
  [interviewing]: 4,
  [offer]: 5,
};

// eslint-disable-next-line no-unused-vars, no-shadow, max-len
const HeaderView = ({ application, currentUser, name, email, phone, canEdit, transitionCandidateApplicationByStaff }) => {
  const staffState = application.get('staffState');

  const [activeStep, setActiveStep] = React.useState(candidateStepMapping[staffState]);

  const updateStaffStatus = (status) => canEdit && transitionCandidateApplicationByStaff(currentUser, application.get('id'), status.substring(2));

  const getActiveStep = () => {
    if (staffState === hired) {
      return 5;
    }
    if (staffState === archived) {
      return -1;
    }
    return activeStep;
  };

  return (
    <HeaderStyles>
      <Contact>
        {name && <h2>{name}</h2>}
        {email && (
          <p>
            <b>@</b> {email}
          </p>
        )}
        {phone && (
          <p>
            <b>P</b>
            {phone}
          </p>
        )}
      </Contact>
      <StatusContainer>
        <div style={{ flexGrow: 1 }}>
          <h3>Applicant Status Bar for {application.getIn(['jobPosting', 'jobPostingTitle'])}</h3>
          <p>Update Bar</p>

          <div style={{ width: '100%' }}>
            <CustomApplicantStepper steps={candidateSteps} activeStep={getActiveStep()} setActiveStep={setActiveStep} onChange={updateStaffStatus} />
          </div>

          {/* <StepperSmallView
            steps={[{ label: 'Reviewed Application' }, { label: 'Request Interview' }, { label: 'Interviewing' }, { label: 'Offer' }]}
            activeStep={step}
          /> */}
        </div>
        <Statuses>
          <Status color={staffState === archived ? '#FF6F6F' : null} onClick={() => updateStaffStatus(archived)}>
            <CloseIcon /> Archived
          </Status>
          <Status color={staffState === hired ? '#059765' : null} onClick={() => updateStaffStatus(hired)}>
            <DoneIcon /> Hired
          </Status>
        </Statuses>
      </StatusContainer>
    </HeaderStyles>
  );
};

HeaderView.propTypes = propTypes;
HeaderView.defaultProps = defaultProps;

export default HeaderView;
