import { OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const PeerStaffReducer = (state = EMPTY_SET, action) => {
  switch (action.type) {
    case ACTIONS.LIST_PEER_STAFF.success:
      return OrderedSet(action.response.result);
    default:
      return state;
  }
};

export const getIds = (state) => state; // Identity function, for now, since we're not storing anything else

export default PeerStaffReducer;
