import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import DetailsView from './details_view';
// import { } from '../../../solve/actions';
import { getSystemEnumList } from '../../../reducer';

import lookupTableForEnums from '../../../util/lookup_table_for_enums';

// mapStateToProps(state, { prop })
const mapStateToProps = (state, { job }) => {
  const opportunityTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'OpportunityType'), job?.get('opportunityTypes')));

  return {
    job,
    opportunityTypes,
  };
};

const mapDispatchToProps = {};

const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsView);

export default DetailsContainer;
