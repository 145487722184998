import React from 'react';
import PropTypes from 'prop-types';
import SortHeadingStyles from './sort_heading_styles';

const SortHeadingView = ({ toggleSort, sortBy, sortOrder, columnHeading, type }) => {
  function reverseSortOrder(currentOrder) {
    if (currentOrder === 'ASC') {
      return 'DESC';
    }
    return 'ASC';
  }
  return (
    <SortHeadingStyles
      className="filter-item"
      onClick={() => toggleSort(sortBy, type === 'outline' ? 'ASC' : reverseSortOrder(sortOrder))}
      onKeyDown={() => toggleSort(sortBy, sortOrder)}
      role="button"
      tabIndex={0}
    >
      {columnHeading}
      <div className={sortOrder === 'ASC' ? `triangle ${type}-down` : `triangle ${type}-up`} />
    </SortHeadingStyles>
  );
};
SortHeadingView.propTypes = {
  toggleSort: PropTypes.func.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
  columnHeading: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SortHeadingView;
