import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();
const EMPTY_MAP = fromJS({});

const DEFAULT_STATE = fromJS({
  eventsLoading: false,
  eventsLoaded: 0,
  eventsList: EMPTY_SET,
  filters: EMPTY_MAP,
  zipcode: undefined,
  candidateEventsLoading: false,
  candidateEventsLoaded: 0,
  savedList: EMPTY_SET,
  appliedList: EMPTY_SET,
  candidateEventsList: EMPTY_SET,
  page: 0,
  sortBy: 'distance',
  distance: '15-miles',
});

const CandidateEventListingsReducer = (state = DEFAULT_STATE, action) => {
  let candidateEventsList;
  let newState;
  let eventId;

  switch (action.type) {
    case ACTIONS.SET_CANDIDATE_PROGRAMS_ZIP_CODE:
      if (state.get('zipcode') !== action.zipcode) {
        return state.merge({
          eventsLoaded: false,
          zipcode: action.zipcode,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          eventsLoaded: false,
          // candidateEventsLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_PAGE:
      if (state.get('page') !== action.page) {
        return state.merge({
          page: action.page,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_SORT_BY:
      if (state.get('sortBy') !== action.sortBy) {
        return state.merge({
          sortBy: action.sortBy,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_DISTANCE:
      if (state.get('distance') !== action.distance) {
        return state.merge({
          distance: action.distance,
          eventsLoaded: false,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          eventsLoaded: false,
          candidateEventsLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_EVENTS_FOR_CANDIDATE.request:
      return state.set('eventsLoading', true);
    case ACTIONS.LIST_FILTERED_EVENTS_FOR_CANDIDATE.success:
      return state.merge({
        eventsList: OrderedSet(action.response.result),
        eventsLoading: false,
        eventsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_EVENTS_FOR_CANDIDATE.failure:
      return state.merge({
        eventsLoading: false,
        eventsLoaded: false,
        eventsList: EMPTY_SET,
      });

    case ACTIONS.LIST_FILTERED_CANDIDATE_EVENTS_FOR_CANDIDATE.request:
      return state.set('candidateEventsLoading', true);
    case ACTIONS.LIST_FILTERED_CANDIDATE_EVENTS_FOR_CANDIDATE.success:
      candidateEventsList = OrderedSet(action.response.result).map((id) => action.response.entities.candidateEvents[id]);
      return state.merge({
        savedList: candidateEventsList.filter((app) => app.candidateState === 'c_saved').map((app) => app.event.eventId),
        appliedList: candidateEventsList.filter((app) => !['c_saved', 'c_deleted'].includes(app.candidateState)).map((app) => app.event.eventId),
        candidateEventsLoading: false,
        candidateEventsLoaded: Date.now(),
        candidateEventsList: candidateEventsList.map((app) => app.id),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATE_EVENTS_FOR_CANDIDATE.failure:
      return state.merge({
        candidateEventsLoading: false,
        candidateEventsLoaded: false,
        savedList: EMPTY_SET,
        appliedList: EMPTY_SET,
      });

    case ACTIONS.CREATE_CANDIDATE_EVENT.success:
      eventId = action.response.entities.candidateEvents[action.response.result].event.eventId;
      return state.update('savedList', (saved) => saved.add(eventId));

    case ACTIONS.TRANSITION_CANDIDATE_EVENT_C.success:
      newState = action.response.entities.candidateEvents[action.response.result].candidateState;
      eventId = action.response.entities.candidateEvents[action.response.result].event.eventId;
      if (newState === 'c_saved') {
        return state.merge({
          savedList: state.get('savedList').add(eventId),
          appliedList: state.get('appliedList').remove(eventId),
        });
      }
      return state.merge({
        savedList: state.get('savedList').remove(eventId),
        appliedList: state.get('appliedList').add(eventId),
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getZipcode = (state) => state.get('zipcode');
export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getPage = (state) => state.get('page') || DEFAULT_STATE.get('page');
export const getSortBy = (state) => state.get('sortBy') || DEFAULT_STATE.get('sortBy');
export const getDistance = (state) => state.get('distance') || DEFAULT_STATE.get('distance');
export const getSearch = (state) => state.get('search');
export const getLoading = (state) => state.get('eventsLoading');
export const getLoaded = (state) => state.get('eventsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getEventIds = (state) => state.get('eventsList') || DEFAULT_STATE.get('eventsList');

export const getSavedIds = (state) => state.get('savedList') || DEFAULT_STATE.get('savedList');
export const getSavedLoaded = (state) => state.get('candidateEventsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getAppliedIds = (state) => state.get('appliedList') || DEFAULT_STATE.get('appliedList');
export const getAppliedLoaded = (state) => state.get('candidateEventsLoaded') + RELOAD_TIMEOUT > Date.now();

export const getCandidateEventIds = (state) => state.get('candidateEventsList');

export default CandidateEventListingsReducer;
