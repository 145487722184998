import styled from 'styled-components';
import theme from '../../../common/theme';

const TileStyles = styled.div`
  margin: 0px 35px;
  padding: 20px 0px 10px;
  border-bottom: solid 1px #e5e5e5;

  &:last-child {
    border-bottom: none;
  }

  p {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.45em;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
`;

export const Person = styled.div`
  display: flex;
  flex-direction: row;
  h4 {
    margin: 0px;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Actions = styled.div`
  float: right;
  text-align: right;
  .MuiButtonGroup-root {
    /* background-color: #fff; */
    /* border: solid 1px #E4E9F2; */
    /* border-radius: 25px; */
    /* box-shadow: 0px 5px 5px rgba(0,0,0,.03); */
  }
  p {
    margin: 3px 0px 0px 0px;
    color: #c0c0c0;
    font-size: 0.7em;
  }
`;

export const Date = styled.div`
  margin: 0px;
  color: ${theme.color.grayLight};
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p,
  span {
    margin: 0;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.45em;
  }

  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const Body = styled.div`
  padding: 15px 0px 10px;
  h4 {
    margin: 0px;
  }
  p {
    margin: 0px;
  }
`;

export const IconGroup = styled.div`
  display: flex;
  align-item: center;
  margin: 0px;
  p {
    color: ${theme.color.gray};
    //font-family: 'Open Sans', 'Source Sans Pro', sans-serif;
  }
  span {
    font-weight: 700;
    margin-right: 5px;
  }
  svg {
    margin-top: -2px;
    margin-right: 7px;
    color: #c0c0c0;
  }
`;

export const Activity = styled.div`
  margin-bottom: 3px;

  span {
    color: ${theme.color.gray};
    //font-family: 'Open Sans', 'Source Sans Pro', sans-serif;
  }
`;

export const PerformedBy = styled.div`
  margin-top: 10px;
`;

export const Footer = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & > button {
    margin-left: 10px;
  }
  @media only screen and (max-width: 650px) {
    justify-content: space-between;
    & > button {
      margin: 0px;
    }
  }
`;

export const Icon = styled.div`
  border-radius: 5px;
  background: ${(props) => props.color || '#F1F0F0'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
  margin-right: 15px;
`;

export default TileStyles;
