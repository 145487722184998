/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Map, OrderedSet } from 'immutable';
import PathwayReportSummary from 'risekit/pathways/views/pathway-summary-report';

import ReportDemographics from './report_demographics';
import PathwayReportsStyles from './pathway_reports_styles';
import PathwayReportFilters from './pathway_report_filters';

import Tabber from '../../../ui/components/tabber';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  listPathways: PropTypes.func.isRequired,
  pathwayInstances: PropTypes.instanceOf(OrderedSet).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  selectedFilterYears: PropTypes.arrayOf(PropTypes.number).isRequired,
  selectedFilterState: PropTypes.string.isRequired,
};

const defaultProps = {};

const PathwayReportsView = ({
  listPathways,
  pathwayInstances,
  filters,
  currentUser,
  organizationName,
  organizationId,
  selectedFilterYears,
  selectedFilterState,
}) => {
  useFetchOnce(currentUser, organizationId, listPathways);

  const pathwayInstanceIds = useRef(OrderedSet([]));

  if (pathwayInstances) {
    pathwayInstances.forEach((pi) => {
      pathwayInstanceIds.current = pathwayInstanceIds.current.add(pi.get('id'));
    });
  }

  return (
    <PathwayReportsStyles>
      <h2>
        Organization:
        <span style={{ fontWeight: 'normal' }}> {organizationName}</span>
      </h2>
      <Tabber>
        <div tabLabel="Pathway Summary">
          <PathwayReportFilters />
          <PathwayReportSummary
            selectedFilterYears={selectedFilterYears}
            selectedFilterState={selectedFilterState}
            organizationId={organizationId}
          />
        </div>
        <div tabLabel="Pathway Demographics">
          <PathwayReportFilters />
          <ReportDemographics filters={filters} organizationId={organizationId} />
        </div>
      </Tabber>
    </PathwayReportsStyles>
  );
};

PathwayReportsView.propTypes = propTypes;
PathwayReportsView.defaultProps = defaultProps;

export default PathwayReportsView;
