import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { Link, Redirect } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import ExternalStyles from './external_styles';
import externalValidate from './external_validate';
import CandidateSelector from './candidate_selector';
import { Card } from '../../../../common/styles/layout';
import { Button, TextHeading, Text } from '../../../../common/components';
import { reduxForm, Field, MuiTextField, MuiRadioGroup, phoneMask } from '../../../../common/form';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  inviteInfo: PropTypes.shape({ get: PropTypes.func }),
  indeedInviteInfo: PropTypes.shape({ get: PropTypes.func }),
  inviteCategory: PropTypes.string.isRequired,
  invitePath: PropTypes.string.isRequired,
  toChannel: PropTypes.string,
  candidate: PropTypes.instanceOf(OrderedSet),
  isRisekit: PropTypes.bool.isRequired,
};

const defaultProps = {
  toChannel: '',
  candidate: undefined,
  inviteInfo: new Map(),
  indeedInviteInfo: new Map(),
};

const ExternalForm = ({ handleSubmit, inviteInfo, indeedInviteInfo, inviteCategory, toChannel, candidate, isRisekit }) => {
  const [submitted, setSubmitted] = useState(false);

  const inviteTitle = inviteInfo.get('title') || inviteInfo.get('name');
  const indeedInfoTitle = indeedInviteInfo.get('jobtitle');
  const indeedJobKey = indeedInviteInfo.get('jobkey');

  const handleSubmitAndRedirect = (event) => {
    try {
      handleSubmit(event).then(() => {
        setSubmitted(true);
      });
    } catch (err) {
      setSubmitted(false);
    }
  };

  if (submitted) {
    return <Redirect to={indeedInfoTitle ? `/staff/indeed-jobs/${indeedJobKey}/` : '.'} />;
  }

  const contactSettings = [];
  if (candidate) {
    if (candidate.getIn(['user', 'email']) && candidate.getIn(['user', 'phone'])) {
      contactSettings.push('Email', 'Phone', 'Both');
    } else if (candidate.getIn(['user', 'email'])) {
      contactSettings.push('Email');
    } else if (candidate.getIn(['user', 'phone'])) {
      contactSettings.push('Phone');
    }
  }

  const title = () => {
    if (inviteCategory === 'Job') {
      if (inviteTitle) {
        return inviteTitle;
      }
      if (indeedInfoTitle) {
        return indeedInfoTitle;
      }
    }

    return '';
  };

  return (
    <ExternalStyles>
      <form onSubmit={handleSubmit}>
        <Card>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextHeading size="large" weight="bold" margin="both">
                Applicants:
                <TextHeading size="large" weight="light">
                  Invite
                </TextHeading>
                {/* <TextHeading size="large" weight="bold">
                  External
                </TextHeading> */}
                <TextHeading size="large" weight="light">
                  Candidate to {inviteCategory} - {title()}
                </TextHeading>
              </TextHeading>

              {/* ADD title of linked to id */}

              <Text size="medium" margin="bottom">
                {`You are about to invite a candidate for a ${inviteCategory} on ${isRisekit ? 'Risekit' : 'Solve'}.`}
              </Text>
            </Grid>
            <Grid item md={12}>
              <Field name="channel" component={MuiRadioGroup} required>
                <Text size="small">How would you like to send the invite?</Text>
                <FormGroup row>
                  <FormControlLabel control={<Radio color="primary" />} value="phone" label="Phone" />
                  <FormControlLabel control={<Radio color="primary" />} value="email" label="Email" />
                  <FormControlLabel control={<Radio color="primary" />} value="existing" label="Existing Candidate" />
                </FormGroup>
              </Field>
            </Grid>
            <Grid item md={12}>
              <div style={toChannel === 'phone' ? { visibility: 'visible', height: 'auto' } : { visibility: 'hidden', height: '0px' }}>
                <Grid item md={6}>
                  <h3 style={{ margin: 0 }}>New Candidate Details:</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field component={MuiTextField} variant="outlined" label="First Name" name="firstName" margin="dense" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <Field component={MuiTextField} variant="outlined" label="Last Name" name="lastName" margin="dense" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        variant="outlined"
                        label="Phone Number of New Candidate"
                        name="toPhone"
                        type="cell"
                        margin="dense"
                        fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...phoneMask}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div style={toChannel === 'email' ? { visibility: 'visible', height: 'auto' } : { visibility: 'hidden', height: '0px' }}>
                <Grid item md={6}>
                  <h3 style={{ margin: 0 }}>New Candidate Details:</h3>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field component={MuiTextField} variant="outlined" label="First Name" name="firstName" margin="dense" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                      <Field component={MuiTextField} variant="outlined" label="Last Name" name="lastName" margin="dense" fullWidth />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field component={MuiTextField} variant="outlined" label="Email" type="email" name="toEmail" margin="dense" fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <div style={toChannel === 'existing' ? { visibility: 'visible', height: 'auto' } : { visibility: 'hidden', height: '0px' }}>
                <Grid item md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Field name="toCandidate" component={CandidateSelector} />
                    </Grid>
                  </Grid>
                  {contactSettings && contactSettings.length > 0 && (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Field name="contactMethod" component={MuiRadioGroup} required>
                          <Text size="small">How would you like to send the invite?</Text>
                          <FormGroup row>
                            {contactSettings.map((option) => (
                              <FormControlLabel control={<Radio color="primary" />} key={option} value={`send${option}`} label={option} />
                            ))}
                          </FormGroup>
                        </Field>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>

            {/* <Grid item md={6}>
            <MuiSelect label="Program(s)" name="programs" margin="dense" required options="" />
          </Grid> */}
            {/* <Grid item md={6}>
            <MuiSelect label="Assign Staff Member(s)" name="staffMembers" margin="dense" required options="" />
          </Grid> */}

            {/* TODO ADD REMINDER */}
            {/* <Grid item md={12}>
            <Field name="autoreminder" component={MuiRadioGroup}>
              <Text size="small">Would you like to send a reminder in three days?</Text>
              <FormGroup>
                <FormControlLabel control={<Field component={MuiCheckbox} name="yesAutoreminder" />} label="Yes" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Field component={MuiCheckbox} name="noAutoreminder" />} label="No" />
              </FormGroup>
            </Field>
          </Grid>

          <Grid item md={12}>
            <Field name="helpstart" component={MuiRadioGroup}>
              <Text size="small">Would you like to help the candidate to start setting their profile now?</Text>
              <FormGroup>
                <FormControlLabel control={<Field component={MuiCheckbox} name="yesHelpstart" />} label="Yes" />
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Field component={MuiCheckbox} name="noHelpstart" />} label="No" />
              </FormGroup>
            </Field>
          </Grid> */}

            {toChannel && (
              <Grid item xs={12}>
                <Grid item xs={12} md={6}>
                  <Field component={MuiTextField} variant="outlined" label="Add Message (Optional)" name="extra.message" margin="dense" fullWidth multiline />
                  <br />
                  <br />
                </Grid>
              </Grid>
            )}

            <Grid item md={12} style={{ marginBottom: '20px' }}>
              <Link to="/staff/candidates">
                <Button buttonType="secondary-outline">Cancel</Button>
              </Link>
              <Button buttonType="secondary" onClick={handleSubmitAndRedirect} style={{ float: 'right' }}>
                Invite Candidate
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
    </ExternalStyles>
  );
};

const ExternalView = reduxForm({
  form: 'inviteExternalApplicant',
  enableReinitialize: true,
  validate: externalValidate,
})(ExternalForm);

ExternalForm.propTypes = propTypes;
ExternalForm.defaultProps = defaultProps;

export default ExternalView;
