import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styled from 'styled-components';

import AddIcon from '@material-ui/icons/Add';

import Button from '@material-ui/core/Button';

import PathwayList from './pathway_list';
import Tabber from '../../../ui/components/tabber';

// NOTE: This is only to avoid a JS Warning in the browser console
const StyledDiv = styled.div``;

const modes = ['active', 'drafts'];

const PathwaysView = () => {
  const [tab, setTab] = useState(0);
  const mode = modes[tab];
  return (
    <div>
      <Link to="/staff/pathways/new">
        <Button variant="outlined" color="secondary" size="large" startIcon={<AddIcon />} style={{ float: 'right' }}>
          New Pathway
        </Button>
      </Link>
      <h1>Pathways</h1>
      <Tabber onChange={setTab}>
        <StyledDiv tabLabel="My Organization Pathways" />
        <StyledDiv tabLabel="Drafts" />
      </Tabber>
      <PathwayList mode={mode} />
    </div>
  );
};

PathwaysView.propTypes = {};
PathwaysView.defaultProps = {};

export default PathwaysView;
