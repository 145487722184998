import React from 'react';

import ResumeUpdateInfoStyles from './resume_update_info_styles';

const ResumeUpdateInfo = () => (
  <ResumeUpdateInfoStyles>
    <p>
      To get the best jobs, it's important to keep your information updated. Come back to your resume any time to add skills, experience, and other information
      that will help employers understand why you're a great candidate.
    </p>
  </ResumeUpdateInfoStyles>
);

export default ResumeUpdateInfo;
