import { connect } from 'react-redux';
import OrganizationsSelectorView from './organizations_selector_view';
import { listFilteredOrganizations } from '../../actions';
import { getCurrentUser, getOrganizations, getOnboardingFilteredOrgsCount } from '../../../reducer';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  // prop assignments
  user: getCurrentUser(state),
  orgs: getOrganizations(state),
  totalCount: getOnboardingFilteredOrgsCount(state),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listOrgs: listFilteredOrganizations,
};

const OrganizationsSelectorContainer = connect(mapStateToProps, mapDispatchToProps)(OrganizationsSelectorView);

export default OrganizationsSelectorContainer;
