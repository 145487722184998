import styled from 'styled-components';
import theme from '../../../../../../common/theme';

const SelectCandidatesListStyles = styled.div``;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 24px;
  & th {
    padding: 0px 10px;
    text-align: left;
    text-transform: uppercase;
    color: ${theme.color.text.medium};
    font-size: 12px;
    letter-spacing: 0.1em;
  }
  & th svg {
    margin-bottom: -5px;
  }
  & td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    padding: 0px 10px;
  }
  .filter-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0;
    border: 0;
  }
  .triangle {
    position: relative;
    width: 8px;
    height: 8px;
  }
  .triangle:before {
    content: '';
    display: block;
    position: absolute;
    left: 100%;
    width: 0;
    height: 0;
    border-style: solid;
    transition: all 0.2s ease;
  }
  .triangle.fill-down:before {
    top: 0px;
    border-color: ${theme.color.text.medium} transparent transparent transparent;
    border-width: 6px;
  }
  .triangle.fill-up:before {
    top: -6px;
    border-color: transparent transparent ${theme.color.text.medium}; transparent;
    border-width: 6px;
  }
  .triangle.outline-down:before {
    top: 0px;
    border-color: ${theme.color.grayLight} transparent transparent transparent;
    border-width: 6px;
  }
`;

export const ResultsPageNavigator = styled.div`
  display: flex;
  align-content: flex-start;
  margin: 16px 0px;
  .prev-next-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    & > span {
      margin: 0px 12px;
    }
  }
`;

export default SelectCandidatesListStyles;
