import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, List, OrderedSet } from 'immutable';
// import { useDebouncedEffect } from '../../../util/hooks';
import JobCandidateApplicationsStyles from './job_candidate_applications_styles';
import TabberSolid from '../../../ui/components/tabber_solid';
import ListingApplicantDetailsTile from '../listing_applicant_details_tile';
import UserModel from '../../../session/models/user_model';
import Spinner from '../../../common/components/spinner';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateId: PropTypes.string.isRequired,
  jobs: PropTypes.instanceOf(List).isRequired,
  externalJobs: PropTypes.instanceOf(List).isRequired,
  programs: PropTypes.instanceOf(List).isRequired,
  events: PropTypes.instanceOf(List).isRequired,
  services: PropTypes.instanceOf(OrderedSet).isRequired,
  pathways: PropTypes.instanceOf(OrderedSet).isRequired,
  // jobsLoaded: PropTypes.bool.isRequired,
  programsLoaded: PropTypes.bool.isRequired,
  eventsLoaded: PropTypes.bool.isRequired,
  listFilteredCandidateApplications: PropTypes.func.isRequired,
  listFilteredCandidateExternalApplications: PropTypes.func.isRequired,
  listFilteredCandidatePrograms: PropTypes.func.isRequired,
  listFilteredCandidateEvents: PropTypes.func.isRequired,
  listFilteredCandidateServices: PropTypes.func.isRequired,
  fetchCandidatePathwaysForCandidate: PropTypes.func.isRequired,
  fetchSavedIndeedJobPostingByIds: PropTypes.func.isRequired,
  programFilters: PropTypes.instanceOf(Map).isRequired,
  eventFilters: PropTypes.instanceOf(Map).isRequired,
  jobFilters: PropTypes.instanceOf(Map).isRequired,
  showPathways: PropTypes.bool.isRequired,
  showSupportiveServices: PropTypes.bool.isRequired,
  indeedJobIdsToBeFetched: PropTypes.arrayOf(PropTypes.number).isRequired,
};

const defaultProps = {};

const JobCandidateApplicationsView = ({
  currentUser,
  candidateId,
  jobs,
  externalJobs,
  programs,
  events,
  services,
  pathways,
  // jobsLoaded,
  programsLoaded,
  eventsLoaded,
  listFilteredCandidateApplications,
  listFilteredCandidatePrograms,
  listFilteredCandidateEvents,
  listFilteredCandidateServices,
  fetchCandidatePathwaysForCandidate,
  programFilters,
  eventFilters,
  jobFilters,
  showPathways,
  showSupportiveServices,
  listFilteredCandidateExternalApplications,
  fetchSavedIndeedJobPostingByIds,
  indeedJobIdsToBeFetched,
}) => {
  useEffect(() => {
    if (!programsLoaded || !eventsLoaded) {
      listFilteredCandidatePrograms(currentUser, currentUser.getIn(['staffOrganization', 'id']), programFilters.merge({ candidateId }));
    }
  }, [programsLoaded, eventsLoaded, listFilteredCandidatePrograms, currentUser, programFilters, candidateId]);
  useEffect(() => {
    if (!eventsLoaded || !programsLoaded) {
      listFilteredCandidateEvents(currentUser, currentUser.getIn(['staffOrganization', 'id']), eventFilters.merge({ candidateId }));
    }
  }, [eventsLoaded, programsLoaded, listFilteredCandidateEvents, currentUser, eventFilters, candidateId]);
  useEffect(() => {
    listFilteredCandidateApplications(currentUser, candidateId, Map());
  }, [listFilteredCandidateApplications, currentUser, candidateId, jobFilters]);
  useEffect(() => {
    listFilteredCandidateExternalApplications(currentUser, candidateId, Map());
  }, [listFilteredCandidateExternalApplications, currentUser, candidateId, jobFilters]);
  useEffect(() => {
    listFilteredCandidateServices(currentUser, candidateId, Map());
  }, [listFilteredCandidateServices, currentUser, candidateId]);
  useEffect(() => {
    fetchCandidatePathwaysForCandidate(currentUser, candidateId);
  }, [currentUser, candidateId, fetchCandidatePathwaysForCandidate]);

  useEffect(() => {
    if (indeedJobIdsToBeFetched.length > 0) {
      fetchSavedIndeedJobPostingByIds(currentUser, indeedJobIdsToBeFetched);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <JobCandidateApplicationsStyles>
      <TabberSolid>
        <div tabLabel="Jobs">
          <h1>Jobs</h1>
          {/* eslint-disable-next-line no-nested-ternary */}
          {jobs && jobs.size > 0 ? (
            <React.Fragment>
              <div style={{ height: 20 }} />
              {jobs.map((job) => (
                <ListingApplicantDetailsTile key={job.get('id')} application={job} type="job" />
              ))}
              {externalJobs.map((externalJob) => (
                <ListingApplicantDetailsTile key={externalJob.get('id')} application={externalJob} type="externalJob" />
              ))}
            </React.Fragment>
          ) : jobs && jobs.size === 0 ? (
            <div>This applicant has not applied to any jobs</div>
          ) : (
            <Spinner />
          )}
        </div>

        <div tabLabel="Programs">
          <h1>Programs</h1>
          {/* eslint-disable-next-line no-nested-ternary */}
          {programs && programs.size > 0 ? (
            <React.Fragment>
              <div style={{ height: 20 }} />
              {programs.map((program) => (
                <ListingApplicantDetailsTile key={program.get('id')} application={program} type="program" />
              ))}
            </React.Fragment>
          ) : programs && programs.size === 0 ? (
            <div>This applicant has not applied to any programs</div>
          ) : (
            <Spinner />
          )}
        </div>

        <div tabLabel="Events">
          <h1>Events</h1>
          {/* eslint-disable-next-line no-nested-ternary */}
          {events && events.size > 0 ? (
            <React.Fragment>
              <div style={{ height: 20 }} />
              {events.map((event) => (
                <ListingApplicantDetailsTile key={event.get('id')} application={event} type="event" />
              ))}
            </React.Fragment>
          ) : events && events.size === 0 ? (
            <div>This applicant has not applied to any events</div>
          ) : (
            <Spinner />
          )}
        </div>

        {showSupportiveServices && (
          <div tabLabel="Services">
            <h1>Supportive Services</h1>
            {/* eslint-disable-next-line no-nested-ternary */}
            {services && services.size > 0 ? (
              <React.Fragment>
                <div style={{ height: 20 }} />
                {services.map((service) => (
                  <ListingApplicantDetailsTile key={service.get('id')} application={service} type="service" />
                ))}
              </React.Fragment>
            ) : services && services.size === 0 ? (
              <div>This applicant has not applied to any services</div>
            ) : (
              <Spinner />
            )}
          </div>
        )}

        {showPathways && (
          <div tabLabel="Pathways">
            <h1>Pathways</h1>
            {/* eslint-disable-next-line no-nested-ternary */}
            {pathways ? (
              <React.Fragment>
                <div style={{ height: 20 }} />
                {pathways.map((pathway) => (
                  <ListingApplicantDetailsTile key={pathway.get('id')} application={pathway} type="pathway" />
                ))}
              </React.Fragment>
            ) : pathways.size ? (
              <div>This applicant has not applied to any pathways</div>
            ) : (
              <Spinner />
            )}
          </div>
        )}
      </TabberSolid>
    </JobCandidateApplicationsStyles>
  );
};

JobCandidateApplicationsView.propTypes = propTypes;
JobCandidateApplicationsView.defaultProps = defaultProps;

export default JobCandidateApplicationsView;
