import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import ParentAccountReportSummaryStyles from './parent_account_report_summary_styles';
import OrganizationPathwaySummary from './organization_pathway_summary';
import UserModel from '../../../../session/models/user_model';

const propTypes = {
  pathways: PropTypes.instanceOf(OrderedSet).isRequired,
  childOrganizations: PropTypes.instanceOf(OrderedSet).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
};

const defaultProps = {};

const ParentAccountReportSummaryView = ({ pathways, childOrganizations, currentUser }) => (
  <ParentAccountReportSummaryStyles>
    {pathways.map((pathway) => (
      <React.Fragment key={pathway.get('id')}>
        <h2>{pathway.get('name')} Pathways Report</h2>
        <table className="parent-table">
          <thead>
            <tr>
              <th>Organization Name</th>
              <th>Enrolled</th>
              <th>Active</th>
              <th>Completed</th>
              <th>Overall Retention</th>
              <th>Adjusted Retention</th>
              <th>Total Dropped</th>
              <th># dropped (involuntary)</th>
              <th># dropped (voluntary)</th>
            </tr>
          </thead>
          <tbody>
            {childOrganizations.map((childOrganization) => (
              <OrganizationPathwaySummary
                key={childOrganization.get('id')}
                currentUser={currentUser}
                organization={childOrganization}
                pathwayId={pathway.get('id')}
              />
            ))}
          </tbody>
        </table>
        <br />
      </React.Fragment>
    ))}
  </ParentAccountReportSummaryStyles>
);

ParentAccountReportSummaryView.propTypes = propTypes;
ParentAccountReportSummaryView.defaultProps = defaultProps;

export default ParentAccountReportSummaryView;
