import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import { Link } from 'react-router-dom';

// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';

// import styled from 'styled-components';
// import theme from '../../../common/theme';
import JobStyles from './job_styles';
import JobPostingModel from '../../../solve/models/job_posting_model';
// import UserModel from '../../../session/models/user_model';
// import CandidateFilters from './candidate_filters';

// import Tabber from '../../../ui/components/tabber';
import JobCandidates from '../job_candidates';
import ImmediateUploadButton from '../../../common/components/immediate_upload_button';

// const StyledDiv = styled.div``;
// const MODES = ['all', 'archived'];

const propTypes = {
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Iterable)]).isRequired,
  createInvitationsFromFile: PropTypes.func.isRequired,
};

const JobView = ({ job, createInvitationsFromFile }) => {
  // const [tab, setTab] = useState(0);
  // const mode = MODES[tab];

  const jobId = job.get('id');

  return (
    <JobStyles>
      {/* <Button
        variant="contained"
        style={{
          backgroundColor: theme.color.secondary,
          color: theme.color.white,
          float: 'right',
        }}
      >
        Invite Job Candidates
      </Button> */}

      <div>
        &larr; <Link to={`/staff/jobs/${jobId}`}>Back to Job Details</Link>
      </div>
      <div className="header">
        <h2>
          Job Post:
          <span style={{ fontWeight: 'normal' }}> {job.get('title')}</span>
        </h2>
        <ImmediateUploadButton buttonLabel="+ Invite candidates from a file" onUpload={createInvitationsFromFile} margin="0 10px" />
      </div>

      {/* <Tabber onChange={setTab}>
        <StyledDiv tabLabel="All Candidates" />
        <StyledDiv tabLabel="Archived Candidates" />
      </Tabber> */}

      <JobCandidates jobId={jobId} />
    </JobStyles>
  );
};

JobView.propTypes = propTypes;
JobView.defaultProps = {};

export default JobView;
