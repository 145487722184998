import styled from 'styled-components';
import theme from '../../../common/theme';

const AddJobStyles = styled.div`
  .content-area {
    margin: -20px;
    padding: 50px;
    background-color: #f6f6f6;
  }
  .wrapper {
    background-color: #ffffff;
    border: 1px solid #ececec;
  }
  h1 {
    margin-bottom: 0;
    text-transform: uppercase;
    span {
      font-weight: 300;
    }
  }
  .header {
    padding: 20px 35px;
    background-color: #f2faff;
    border: 1px solid #ececec;
  }
  @media ${theme.query.desktopMax} {
    .content-area {
      padding: 20px;
    }
  }
`;

export default AddJobStyles;
