import React from 'react';
import PropTypes from 'prop-types';
import InvitePreviewContainer from './invite_preview_container';
import PublicContainer from '../public_container';
import InvitePreviewStyles from './invite_preview_styles';

const InvitePreview = ({
  match: {
    params: { id },
  },
}) => (
  <PublicContainer>
    <InvitePreviewStyles>
      <InvitePreviewContainer postingId={id} />
    </InvitePreviewStyles>
  </PublicContainer>
);

InvitePreview.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
};

export default InvitePreview;
