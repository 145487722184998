import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OrderedSet } from 'immutable';
import NotesTile from './notes_tile';
import { Button } from '../../../common/components';
import NotesListStyles from './notes_list_styles';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateId: PropTypes.string.isRequired,
  notes: PropTypes.instanceOf(OrderedSet),
  listNotes: PropTypes.func.isRequired,
};

const defaultProps = {
  notes: new OrderedSet(),
};

const NotesListView = ({ currentUser, candidateId, notes, listNotes }) => {
  useEffect(() => {
    listNotes(currentUser, { candidateId });
  }, [listNotes, candidateId, currentUser]);

  return (
    <NotesListStyles>
      <Link to={`/staff/notes/new/${candidateId}`} className="new-note">
        <Button buttonType="secondary-outline" className="button">
          Add a new note
        </Button>
      </Link>
      {notes
        .filter((note) => note.candidateId === candidateId)
        .map((note) => (
          <NotesTile
            key={note.id}
            icon="/icons/avatar.svg"
            iconColor="#F1F0F0"
            postedBy={note.owningStaff && new UserModel(note.owningStaff.get('user')).name}
            createdAt={note.createdAt}
            subject={note.noteSubject}
            body={note.noteBody}
          />
        ))}
    </NotesListStyles>
  );
};

NotesListView.propTypes = propTypes;
NotesListView.defaultProps = defaultProps;

export default NotesListView;
