import { connect } from 'react-redux';
import PathwayReportsView from './pathway_reports_view';
import { getCurrentUser, getOrganization, getPathwayInstances, getPathwayReportFilters } from '../../../reducer';
import { listPathways, listPathwayInstances, listCandidatePathwaysForPathwayInstance } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: organizationId },
    },
  },
) => ({
  currentUser: getCurrentUser(state),
  pathwayInstances: getPathwayInstances(state),
  organizationName: getOrganization(state, organizationId)?.get('name') || getCurrentUser(state).getIn(['staffOrganization', 'name']),
  filters: getPathwayReportFilters(state),
  selectedFilterYears: getPathwayReportFilters(state).get('year')?.toJS().map(Number) || [],
  selectedFilterState: getPathwayReportFilters(state).get('state')?.last(),
  organizationId,
});

const mapDispatchToProps = {
  listPathways,
  listPathwayInstances,
  listCandidatePathwaysForPathwayInstance,
};

const PathwayReportsContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayReportsView);

export default PathwayReportsContainer;
