import React from 'react';
import PropTypes from 'prop-types';
// import { List } from 'immutable';
import Display from './display';
import Editing from './editing';
// import PDFUpload from './pdf_upload';
import IndustriesStyles from './industries_styles';
import { Card } from '../../../../common/styles/layout';

const propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

const defaultProps = {
  // certifications: List,
};

const IndustriesView = ({ fields }) => (
  <IndustriesStyles>
    <h3>Industries</h3>
    <Card>
      <div className="editing-container">
        <Editing industries={fields} />
      </div>
      <div className="display-container">
        <Display industries={fields} />
      </div>
    </Card>
  </IndustriesStyles>
);

IndustriesView.propTypes = propTypes;
IndustriesView.defaultProps = defaultProps;

export default IndustriesView;
