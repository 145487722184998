function getListingImage(listing, type) {
  let logoUrl;
  logoUrl = listing.get('logoUrl');
  if (!logoUrl) {
    logoUrl = listing.get('employer') && listing.getIn(['employer', 'logoUrl']);
  }
  if (!logoUrl) {
    logoUrl = listing.get('postingOrganization') && listing.getIn(['postingOrganization', 'logoUrl']);
  }
  if (!logoUrl) {
    switch (type) {
      case 'program':
        logoUrl = '/images/solve-programs.png';
        break;
      case 'event':
        logoUrl = '/images/solve-events.png';
        break;
      default:
        logoUrl = '/images/solve-jobs.png';
    }
  }
  return logoUrl;
}

export default getListingImage;
