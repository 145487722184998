import styled from 'styled-components';
// import theme from '../../../common/theme';

const JobDetailsStyles = styled.div`
  h1,
  h4 {
    margin: 0px;
  }
  .logo-wrapper {
    float: right;
  }
  .logo {
    margin: 0px;
    margin-left: auto;
    max-width: 200px;
    max-height: 70px;
    width: auto;
    background: transparent;
  }
  .MuiChip-root {
    background-color: #7dbf9d;
    color: #fff;
  }
`;

export default JobDetailsStyles;
