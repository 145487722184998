import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import { capitalize } from '@material-ui/core';
import ServiceTileView from './service_tile_view';
import { getCurrentUser, getExternalCandidateServiceByResourceId, getService, getSystemEnumList } from '../../../reducer';
import { createExternalCandidateService, transitionExternalCandidateServiceByCandidate } from '../../../solve/actions';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state, { id, isAbService }) => {
  const service = getService(state, id);
  const externalService = getExternalCandidateServiceByResourceId(state, id);

  let serviceTypes;
  let targetTypes;
  if (isAbService) {
    serviceTypes = service?.get('serviceTags').map((x) => [x, capitalize(x)]);
    targetTypes = service?.get('attributeTags').map((x) => [x, capitalize(x)]);
  } else {
    serviceTypes = lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), service?.get('serviceTypes'));
    targetTypes = lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), service?.get('targetParticipantTypes'));
  }

  return {
    id,
    currentUser: getCurrentUser(state),
    service,
    externalService,
    serviceTypes: new OrderedSet(serviceTypes),
    targetTypes: new OrderedSet(targetTypes),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  // transitionCandidateServiceByCandidate: transitionCandidateProgramByCandidate,
  createExternalCandidateService,
  transitionExternalCandidateServiceByCandidate,
};

const ServiceTileContainer = connect(mapStateToProps, mapDispatchToProps)(ServiceTileView);

export default ServiceTileContainer;
