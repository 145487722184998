import { Helmet } from 'react-helmet';
import React from 'react';
import PropTypes from 'prop-types';

const WithCandidateTrackers = ({ children }) => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENABLE_TRACKERS === 'yes') {
    return (
      <React.Fragment>
        <Helmet>
          {/* Global site tag (gtag.js) - Google Analytics */}
          <script async src="https://www.googletagmanager.com/gtag/js?id=UA-96560990-2"></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'UA-96560990-2');
            `}
          </script>

          {/* Facebook Pixel Code  */}
          <script>
            {`
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window,document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '368147431102410');
              fbq('track', 'PageView');
            `}
          </script>
          <noscript>
            {`
              <img height="1" width="1"
              src="https://www.facebook.com/tr?id=368147431102410&ev=PageView
              &noscript=1"/>
            `}
          </noscript>

          {/* End Facebook Pixel Code */}
        </Helmet>
        {children}
      </React.Fragment>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

WithCandidateTrackers.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WithCandidateTrackers;
