import styled from 'styled-components';
import theme from '../../../common/theme';

const JobCandidateQuestionnaireStyles = styled.div`
  color: #777;
  font-size: 13px;
  margin-top: 18px;
  img {
    width: 60px;
    margin: 10px 0px 0px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    margin: 10px 0px;
  }
`;

export const GridTile = styled.div`
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 20px 10px;
  text-align: center;
  margin: 10px;

  .MuiCheckbox-root {
    float: right;
    padding: 0;
    margin: -10px 0 0 -25px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }
  @media ${theme.query.tabletMax} {
    margin: 0px;
  }
`;

export default JobCandidateQuestionnaireStyles;
