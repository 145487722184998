import styled from 'styled-components';

export const ReportSummaryStyles = styled.div`
  h1 {
    margin-bottom: 0px;
  }
  table {
    border-collapse: collapse;
  }

  thead th {
    border-collapse: collapse;
    padding: 16px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #6c7a89;
    text-align: left;
    border-bottom: solid 1px #ccc;
  }

  tbody tr {
    border-bottom: solid 1px #ccc;
  }

  tbody th {
    color: #777;
    font-size: 14px;
  }

  tbody td {
    border-collapse: collapse;
    padding: 16px;
    width: 150px;
    text-align: left;
    color: #777;
    font-size: 14px;
    min-width: 100px;
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 24px;
  & th {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: gray;
    text-align: left;
  }
`;
export const StyledTableRow = styled.tr`
  &:hover td {
    border-color: black;
  }
  & td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    & h4 {
      color: #394148;
    }
    & h4,
    p {
      margin: 0;
    }
  }
`;

export const PaginationWrapper = styled.div`
  padding-top: 1rem;
  display: flex;
  justify-content: center;
`;
