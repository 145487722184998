import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { reduxForm, Field, FieldArray, MuiTextField, MuiCheckbox } from '../../../../common/form';
import { TextHeading } from '../../../../common/components';
import Button from '../../../../common/components/button/index';
import requirementsFormValidate from './requirements_form_validate';
import RequirementsFormStyles from './requirements_form_styles';
// import SurveyBuilder from '../../survey_builder';
import Skills from '../skills';
import Certifications from '../../../../resume/components/resume/certifications';

const propTypes = {
  isRisekit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const defaultProps = {};

const RequirementsForm = ({ isRisekit, handleSubmit, previousPage, pristine, submitting }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <RequirementsFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextHeading size="medium" weight="bold" uppercase>
              External Application (Optional):
            </TextHeading>
            <header>
              {`if applicants are required to submit an application outside of ${
                isRisekit ? 'RiseKit' : 'Solve'
              }'s website, please provide the URL to the external application.`}
            </header>
            <Field component={MuiTextField} variant="outlined" label="Application URL" name="applicationUrl" margin="dense" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Field component={MuiCheckbox} name="sendApplicationUrlToMatches" />}
              label="Send a reminder encouraging applicants to apply at this link provided if a job candidate successfully matches with requirements"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Field
              component={MuiTextField}
              variant="outlined"
              label="Add a custom follow up message to the applicant "
              name="followUpMessage"
              margin="dense"
              multiline
              fullWidth
            />
          </Grid> */}
          <Grid item xs={12}>
            <FieldArray name="requiredSkillTypes" component={Skills} prefixWord="Required" afterContent="(Leave blank if no required skills)" />
            <FieldArray name="desiredSkillTypes" component={Skills} prefixWord="Desired" />
            <FieldArray name="certifications" component={Certifications} prefixWord="Desired" />
          </Grid>
          {/* <Grid item xs={12}>
            <TextHeading size="medium" weight="bold" margin="both">
              Screening Questions
            </TextHeading>
            <SurveyBuilder surveyType="screening" />
          </Grid> */}
        </Grid>
        <div className="button-group">
          <div className="button-left">
            <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
              Previous
            </Button>
          </div>
          <div className="button-right">
            <Button buttonType="secondary" type="submit">
              Next Step
            </Button>
          </div>
        </div>
      </form>
    </RequirementsFormStyles>
  );
};

RequirementsForm.propTypes = propTypes;
RequirementsForm.defaultProps = defaultProps;

const RequirementsFormView = reduxForm({
  form: 'jobForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: requirementsFormValidate,
})(RequirementsForm);

export default RequirementsFormView;
