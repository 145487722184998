/* eslint-disable max-len */
import { List } from 'immutable';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { reduxForm, Field, MuiTextField, MuiCheckbox, MuiSelectMultiple, MuiRadioGroup, currencyMask } from '../../../../common/form';
import { Card } from '../../../../common/styles/layout';
import Button from '../../../../common/components/button/index';
import detailsFormValidate from './details_form_validate';
import DetailsFormStyles from './details_form_styles';
import UserModel from '../../../../session/models/user_model';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  listStaff: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  pristine: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  jobTypes: PropTypes.instanceOf(List).isRequired,
  // teamMembers: PropTypes.instanceOf(List).isRequired,
  certificationCredentialTypes: PropTypes.instanceOf(List).isRequired,
  payment: PropTypes.string,
};

const defaultProps = {
  payment: 'no',
};

const DetailsForm = ({
  currentUser,
  organizationId,
  listStaff,
  handleSubmit,
  previousPage,
  pristine,
  submitting,
  jobTypes,
  certificationCredentialTypes,
  payment,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    listStaff(currentUser, organizationId);
  }, [listStaff, currentUser, organizationId, pristine]);

  return (
    <DetailsFormStyles>
      <form onSubmit={handleSubmit}>
        <h3>Program Details</h3>
        <Card>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={2} className="details-form">
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid container justify="flex-start" alignItems="flex-start" item md={6} xs={12}>
                  <Grid item xs={12}>
                    <h3>Primary Services</h3>
                    <header>What are the primary services that your program provides? </header>

                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.soft-skills" />} label="Soft Skills" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.subsidized" />} label="Subsidized Employment" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.work-readiness" />} label="Work Readiness Training" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.internship" />} label="Internships" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.career-counseling" />} label="Career Counseling" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.resume" />} label="Resume Help" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.training" />} label="Job Specific Training" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.mock-interviews" />} label="Mock Interviews" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.mentorship" />} label="Mentorship" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.ged" />} label="GED" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10}>
                    <h3>Job Types</h3>
                    <header>What job types is the program participant going to get after completing the program?</header>
                    <Field component={MuiSelectMultiple} options={jobTypes} label="Select Job Types" name="gainedJobTypes" margin="dense" />
                  </Grid>
                  <Grid item xs={10}>
                    <h3>Outcomes</h3>
                    <header>What certifications/skills is the program participant going to gain?</header>
                    <Field
                      component={MuiSelectMultiple}
                      options={certificationCredentialTypes}
                      label="Certification / Credential Gained"
                      name="gainedCertifications"
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <h3>Dress code?</h3>
                    <header>Is there any dress code?</header>
                    <Field
                      component={MuiTextField}
                      label="Please type the dresscode here"
                      name="dresscode"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      multiline
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <h3>What is the estimated salary for the participant after placement?</h3>
                    <header>What job types is the program participant going to get after completing the program?</header>
                    <Field
                      component={MuiTextField}
                      label="Please type the estimated salary here"
                      name="estimatedSalary"
                      margin="dense"
                      variant="outlined"
                      required
                      fullWidth
                      multiline
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} item md={6} xs={12}>
                  <Grid item xs={12}>
                    <h3>SECONDARY/SUPPORTIVE SERVICES</h3>
                    <header>What are the supportive services that your program provides?</header>

                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.food" />} label="Food" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.housing" />} label="Housing" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.clothing" />} label="Clothing" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.transportation" />} label="Transportation" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.health-and-support" />} label="Health And Support" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.finances" />} label="Finances" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.child-care" />} label="Child Care" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.education" />} label="Education" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.job-training" />} label="Job Training" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="supportTypes.legal-services" />} label="Legal Services" />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <h3>What career interests do you serve?</h3>
                    <header>We will be sure to show your listing to candidates looking for your area of career expertise.</header>

                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.administrative" />} label="Administrative" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.carpentry" />} label="Carpentry" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.computer" />} label="Computer" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.construction" />} label="Construction" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.customer-support" />} label="Customer Support" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.env-codes" />} label="Environmental codes" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.health-care" />} label="Health Care" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.info-technology" />} label="IT Training" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.manufacturing" />} label="Manufacturing" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.mechanical" />} label="Mechanical" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.plumbing" />} label="Plumbing" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.sales" />} label="Sales" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.site-supervision" />} label="Site supervision" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.teaching" />} label="Teaching" />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.writing" />} label="Writing" />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={10}>
                    <h3>What team members are a part of this program? </h3>
                    <MuiSelectMultiple options={teamMembers} label="Team Members" name="teamMembers" margin="dense" />
                  </Grid> */}
                  <Grid item xs={10}>
                    <h3>Payment</h3>
                    <header>Does the program cost money?*</header>
                    <Field name="payment" component={MuiRadioGroup} required>
                      <Grid container spacing={0}>
                        <Grid item xs={6}>
                          <FormControlLabel control={<Radio color="primary" />} value="yes" label="Yes" />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel control={<Radio color="primary" />} value="no" label="No" />
                        </Grid>
                      </Grid>
                    </Field>
                    {payment === 'yes' && (
                      <Field
                        component={MuiTextField}
                        variant="outlined"
                        label="How much does the program cost"
                        name="cost"
                        margin="dense"
                        fullWidth
                        {...currencyMask} // eslint-disable-line
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <div className="button-group">
          <div className="button-left">
            <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
              Previous
            </Button>
          </div>
          <div className="button-right">
            <Button buttonType="secondary" type="submit">
              Next Step
            </Button>
          </div>
        </div>
      </form>
    </DetailsFormStyles>
  );
};

DetailsForm.propTypes = propTypes;
DetailsForm.defaultProps = defaultProps;

const DetailsFormView = reduxForm({
  form: 'programForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: detailsFormValidate,
})(DetailsForm);

export default DetailsFormView;
