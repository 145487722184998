import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  BooleanField,
  BooleanInput,
  EditButton,
  TextInput,
  ReferenceField,
  DateField,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  Filter,
  Pagination,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/AccessibilityNew';

export const Icon = SettingsIcon;
// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

const SearchFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput label="Search by Name" source="userFullName" alwaysOn />
    <ReferenceInput label="Organization" source="organizationId" reference="organizations" allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} filters={<SearchFilter />} pagination={<PostPagination />}>
    <Datagrid>
      <ReferenceField label="User" source="userId" reference="users">
        <FunctionField render={(x) => `${x.firstName} ${x.lastName}`} />
      </ReferenceField>
      <ReferenceField label="Organization" source="organizationId" reference="organizations">
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Status" source="aasmState" />
      <DateField source="birthday" />
      <TextField label="Gender" source="genderType" />
      <DateField source="lastActivatedAt" />
      <DateField source="createdAt" />
      <DateField source="membershipValidatedAt" />
      <BooleanField source="isOrgAdmin" sortable={false} />
      <EditButton basePath="/staffs" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>Staff {record ? `"${record.id}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput label="Status" source="aasmState" />
      <DateInput source="birthday" />
      <TextInput label="Gender" source="genderType" />
      <DateInput source="membershipValidatedAt" />
      <BooleanInput label="Org Admin" source="isOrgAdmin" />
      <BooleanInput source="sendEmailPermitted" />
      <BooleanInput source="sendTextPermitted" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <ReferenceInput label="User" source="userId" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput label="Organization" source="organizationId" reference="organizations">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <DateInput source="birthday" />
      <TextInput label="Gender" source="genderType" />
      <BooleanInput label="Org Admin" source="isOrgAdmin" />
    </SimpleForm>
  </AdminCreate>
);
