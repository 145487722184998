import styled from 'styled-components';
// import theme from '../../../common/theme';

const JobStyles = styled.div`
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Filters = styled.div`
  .MuiFormControl-root {
    min-width: calc(10vw);
    margin-right: 10px;
  }
  .MuiButtonBase-root {
    margin-right: 10px;
  }
`;

export const Banner = styled.div`
  background: #e8fff7;
  color: #059765;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 24px;
  margin: 10px 0;
`;

export default JobStyles;
