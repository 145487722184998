import styled from 'styled-components';
// import theme from '../../../common/theme';

const ServiceTileStyles = styled.div`
  .externalStatus {
    border-radius: 6px;
    padding: 6px 15px;
    background: #189f9c;
    color: white;
    text-transform: capitalize;
    width: 100%;
  }
`;

export default ServiceTileStyles;
