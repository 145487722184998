import BaseNotificationHelper from './base_notification_helper';

class ReplaceNamesNotificationHelper extends BaseNotificationHelper {
  constructor(params) {
    super(params);

    ({ urlIdKey: this.urlIdKey = null, urlTemplate: this.urlTemplate } = params);
    this.parent = Array.isArray(params.namesParent) ? this.notification.getIn(params.namesParent) : this.notification.get(params.namesParent);
  }

  getActivity() {
    const activity = this.template?.activity;
    if (activity) {
      return this.replaceNames(activity);
    }
    return '';
  }

  getDescription() {
    let description = this.template?.description;
    if (description) {
      description = this.replaceNames(description);
      if (description.includes('<here>')) {
        const url = this.getUrl();
        description = description.concat('~<here>');
        description = description.concat(`~/${url}`);
      }
      return description;
    }
    return '';
  }

  getUrl() {
    if (!this.urlTemplate) return '';

    return this.urlTemplate.replace('<urlNode>', this.getUrlNode()).replace('<id>', this.getUrlId());
  }

  getUrlId() {
    return (Array.isArray(this.urlIdKey) ? this.parent?.getIn(this.urlIdKey) : this.parent?.get(this.urlIdKey)) || '';
  }

  replaceNames(text) {
    const candidateName = this.getPerformedByCandidate();
    const staffName = this.getPerformedByStaff();
    const staffOrgName = this.getPerformedByOrgName();
    let updatedText = text.replace('<candidateName>', candidateName);
    updatedText = updatedText.replace('<staffName>', staffName);
    return updatedText.replace('<staffOrgName>', staffOrgName);
  }

  getPerformedByCandidate() {
    const candidate = this.parent?.get('candidate');
    if (candidate) {
      return `${candidate.get('firstName')} ${candidate.get('lastName')}`;
    }
    return '';
  }

  getPerformedByStaff() {
    const staff = this.parent?.get('staff');
    if (staff) {
      return `${staff.get('firstName')} ${staff.get('lastName')}`;
    }
    return '';
  }

  getPerformedByOrgName() {
    return this.parent?.getIn(['staff', 'organization', 'name']) || '';
  }
}

export default ReplaceNamesNotificationHelper;
