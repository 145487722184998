import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import NotesTileStyles, { Header, Body, Icon, Person, Content } from './notes_tile_styles';
import { Card } from '../../../../common/styles/layout';
import theme from '../../../../common/theme';

const propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  postedBy: PropTypes.string,
  createdAt: PropTypes.string,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
};

const defaultProps = {
  backgroundColor: '#FFFFFF',
  color: theme.color.secondaryAction,
  icon: '/icons/avatar.svg',
  iconColor: '#F1F0F0',
  postedBy: null,
  createdAt: undefined,
};

const NotesTileView = ({ backgroundColor, color, icon, iconColor, postedBy, createdAt, subject, body }) => (
  <Card color={backgroundColor}>
    <NotesTileStyles>
      <Header>
        <Person>
          <Icon color={iconColor}>
            <img alt="avatar" src={icon} />
          </Icon>
          <div>
            <h4>{postedBy}</h4>
            <p>{`${moment(createdAt).format('MMM Do h:mma')} (${moment(createdAt).fromNow()})`}</p>
          </div>
        </Person>
      </Header>
      <Content>
        <Body color={color}>
          <h4>
            <b>Subject: </b>
            {subject}
          </h4>
          <p>{body}</p>
        </Body>
      </Content>
    </NotesTileStyles>
  </Card>
);

NotesTileView.propTypes = propTypes;
NotesTileView.defaultProps = defaultProps;

export default NotesTileView;
