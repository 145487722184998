import styled from 'styled-components';
import theme from '../../../common/theme';

const PublicContainerStyles = styled.div`
  .container {
    margin: 40px auto;

    &.padding {
      padding-left: ${theme.padding.base};
      padding-right: ${theme.padding.base};
      width: calc(100% - (${theme.padding.base} * 2));
    }

    p,
    li {
      color: #555;
    }
  }

  .auth {
    width: 100%;
    margin: calc(10vw) auto;
    color: #777;
    i {
      font-size: 20px;
      color: #777;
    }
    input {
      font-size: 16px;
      padding: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    input[type='submit'] {
      display: block;
      padding: 15px;
      width: 100%;
      background-color: ${(props) => props.theme.color.primary};
      color: #fff;
      border-radius: 5px;
      font-weight: bold;
    }
    .oauth-row {
      margin: 0;
      margin-top: 20px;
      padding: 0;
    }
    a {
      font-size: 14px;
    }
  }
`;

export const TopBar = styled.div`
  background: ${(props) => props.theme.color.primary};
  padding: 20px 30px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.risekit-bar {
    background: ${(props) => props.theme.color.riseKitBlack};
  }

  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }

  a {
    color: #fff;
    display: flex;
    align-items: center;
  }

  ul {
    padding: 0;
    margin: 0;
    /* float: right; */

    @media only screen and (max-width: 650px) {
      margin: 0 -${theme.padding.small};
      text-align: center;
    }

    li {
      padding: 0;
      margin: 0;
      display: inline-block;
      margin-left: 20px;

      @media only screen and (max-width: 650px) {
        margin: ${theme.padding.small} ${theme.padding.small} 0;
      }
    }
  }
`;

export const Footer = styled.div`
  margin: 0;
  padding: 20px;
  border-top: solid 1px #eee;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    .social a {
      color: #ccc;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      display: inline-block;
      margin-left: 20px;
      a {
        color: ${theme.color.text.medium};
        font-size: 14px;
      }
    }
  }
`;

export const Logo = styled.div`
  @media only screen and (max-width: 650px) {
    text-align: center;
  }
`;

export default PublicContainerStyles;
