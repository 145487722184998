import { connect } from 'react-redux';
import TotalsSummaryView from './totals_summary_view';
import { getCandidatePathwaysForPathwayInstances } from '../../../../../reducer';

const mapStateToProps = (state, { pathwayInstances, generateReport }) => {
  const candidatePathways = getCandidatePathwaysForPathwayInstances(
    state,
    pathwayInstances.map((pathwayInstance) => pathwayInstance.get('id')),
  );
  return { reportDetails: generateReport(candidatePathways) };
};

const mapDispatchToProps = {};

const TotalsSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(TotalsSummaryView);

export default TotalsSummaryContainer;
