import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import WelcomeStyles from './welcome_styles';
import PersonalInfoForm from './personal_info_form';
import HowHearForm from './how_hear_form';
// import theme from '../../../common/theme';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  hasCandidate: PropTypes.bool.isRequired,
};

const defaultProps = {};

const WelcomeView = ({ signedIn, hasCandidate }) => {
  const [finished, setFinished] = useState();

  if (!signedIn) {
    return <Redirect to="/register" />;
  }

  if (signedIn && !hasCandidate) {
    return (
      <WelcomeStyles>
        <PersonalInfoForm />
      </WelcomeStyles>
    );
  }

  if (finished) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <WelcomeStyles>
      <HowHearForm onNext={setFinished} />
    </WelcomeStyles>
  );
};

WelcomeView.propTypes = propTypes;
WelcomeView.defaultProps = defaultProps;

export default WelcomeView;
