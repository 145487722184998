import { connect } from 'react-redux';
import { Map } from 'immutable';
import NotificationsSettingsView from './notifications_settings_view';
import { getCurrentUser, getTreatment } from '../../../reducer';
import { updateCurrentUser } from '../../../session/actions';

// mapStateToProps(dispatch, { prop })
const mapStateToProps = (state) => {
  const user = getCurrentUser(state);

  return {
    user,
    isRisekit: !!getTreatment(state, 'isRisekit', true),
    initialValues: {
      sendRecommendationPermitted: user && user.get('sendRecommendationPermitted'),
      notificationFrequency: user && user.get('notificationFrequency'),
      notificationSettings: user && new Map(user.get('notificationSettings')).map((setting) => new Map(setting).filter((val) => val !== 'false')),
    },
  };
};

const mapDispatchToProps = {
  updateCurrentUser,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => dispatchProps.updateCurrentUser(stateProps.user.authToken, values),
  ...stateProps,
  ...ownProps,
});

const NotificationsSettingsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(NotificationsSettingsView);

export default NotificationsSettingsContainer;
