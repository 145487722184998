import styled from 'styled-components';
import theme from '../../../common/theme';

const DashboardStyles = styled.div`
  background-color: #f7f7f7;
  margin: -50px -50px -50px;
  padding: 25px 50px;
  min-height: 100vh;

  .mobile-view {
    display: none;
  }

  @media only screen and (max-width: 1280px) {
    .mobile-view {
      display: block;
      margin-top: 15px;
    }
    .desktop-view {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    & {
      margin: 0px -25px 0px;
      padding: 0px 25px 25px;
    }
  }
`;

export const GridTile = styled.div`
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 20px 10px;
  text-align: center;
  margin: 10px;

  .MuiCheckbox-root {
    float: right;
    padding: 0;
    margin: -10px 0 0 -25px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }
  @media ${theme.query.tabletMax} {
    margin: 0px;
  }
`;

export const Header = styled.div`
  margin: 0px -50px 10px;
  padding: 0px 50px 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 14px 40px 0 rgba(0, 0, 0, 0.05);

  h2 {
    margin: 5px 0px;
    font-size: 18px;
    color: ${theme.color.grayDark};
  }

  @media only screen and (max-width: 768px) {
    .header {
      margin: 0px -25px 0px;
      padding: 0px 25px 0px;
    }
  }
`;

export default DashboardStyles;
