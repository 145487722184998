import { Map } from 'immutable';

const lookupTableForEnums = (potential, actual) => {
  if (actual === undefined) {
    return new Map();
  }
  const basicLookup = Map(potential.map((oppo) => (actual.includes(oppo.get('slug')) ? [oppo.get('slug'), oppo.get('label')] : undefined)));
  return basicLookup;
};

export default lookupTableForEnums;
