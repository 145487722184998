import validateSurveyQuestions from '../../survey_builder/validate';

const requirementsFormValidate = (values) => {
  const errors = {};
  // TODO: validate ApplicationURL
  errors.surveyQuestionAttributes = validateSurveyQuestions(values.get('surveyQuestionAttributes'));
  return errors;
};

export default requirementsFormValidate;
