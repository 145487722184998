import React from 'react';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

const propTypes = {
  organization: PropTypes.instanceOf(Map).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const ParentAccountAgeReportView = ({ reportDetails, organization }) => {
  if (!reportDetails.size) {
    return null;
  }
  return (
    <tr key={organization.get('id')}>
      <th>
        <Link to={`${organization.get('id')}`}>{organization.get('name')}</Link>
      </th>
      <td>{reportDetails.get('range18To24')}</td>
      <td>{reportDetails.get('range25To34')}</td>
      <td>{reportDetails.get('range35To44')}</td>
      <td>{reportDetails.get('range45To54')}</td>
      <td>{reportDetails.get('range55To64')}</td>
      <td>{reportDetails.get('over65')}</td>
    </tr>
  );
};

ParentAccountAgeReportView.propTypes = propTypes;
ParentAccountAgeReportView.defaultProps = defaultProps;

export default ParentAccountAgeReportView;
