import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useApiCall } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';

const StatusTogglerView = ({ user, listingId, publish, close, save, currentState }) => {
  const [doChangeJobState, { submitting: changingJobState }] = useApiCall((state) => {
    if (state === 'active') {
      return publish(user, listingId);
    }
    if (state === 'pre_published') {
      return save(user, listingId);
    }
    return close(user, listingId);
  });

  const onStateChange = async (event) => {
    const { value } = event.target;
    if (value !== currentState) {
      await doChangeJobState(value);
    }
  };

  return (
    <span>
      <Select
        disabled={changingJobState}
        labelId="jobState"
        id="jobState"
        value={currentState}
        onChange={onStateChange}
        style={{
          float: 'right',
        }}
      >
        <MenuItem value="active">Active</MenuItem>
        <MenuItem value="pre_published">Draft</MenuItem>
        <MenuItem value="closed">Closed</MenuItem>
      </Select>
    </span>
  );
};

StatusTogglerView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  listingId: PropTypes.string.isRequired,
  currentState: PropTypes.string.isRequired,
  publish: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
};

export default StatusTogglerView;
