import { connect } from 'react-redux';
import EventApplicantTileView from './event_applicant_tile_view';
import { getCurrentUser } from '../../../reducer';
import { transitionCandidateEventByStaff } from '../../../solve/actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

const mapDispatchToProps = { transitionCandidateEventByStaff };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  updateStaffStatus: (status) => dispatchProps.transitionCandidateEventByStaff(stateProps.user, ownProps.candidateEvent.get('id'), status.substring(2)),
});

const EventApplicantTileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventApplicantTileView);

export default EventApplicantTileContainer;
