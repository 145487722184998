import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import {
  reduxForm,
  EditorField,
  Field,
  MuiTextField,
  MuiSelect,
  MuiDatePicker,
  MuiTimePicker,
  MuiFileUpload,
  zipCodeMask,
  phoneMask,
} from '../../../../common/form';
import Button from '../../../../common/components/button/index';
import { Card } from '../../../../common/styles/layout';
import eventOverviewFormValidate from './event_overview_form_validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  industryTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  industrySpecifics: PropTypes.instanceOf(OrderedSet).isRequired,
  isRisekit: PropTypes.bool.isRequired,
};

const defaultProps = {};

/* eslint-disable react/jsx-props-no-spreading */
const EventOverviewForm = ({ handleSubmit, serviceTypes, industryTypes, isRisekit }) => (
  <form onSubmit={handleSubmit}>
    <h2>Event Overview</h2>
    <Card>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <h3>Event Contact Information</h3>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field component={MuiFileUpload} label="Add A Logo" name="eventLogo" margin="dense" optional />
                </Grid>
                <Grid item xs={8}>
                  <Field variant="outlined" component={MuiTextField} label="Event Name" name="name" margin="dense" fullWidth required />
                </Grid>
                <Grid item xs={4}>
                  <Field variant="outlined" component={MuiTextField} label="Phone Number" name="phone" margin="dense" fullWidth required {...phoneMask} />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    variant="outlined"
                    component={MuiTextField}
                    label="Street Address 1"
                    name="addressAttributes.address1"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={MuiTextField} label="Street Address 2" name="addressAttributes.address2" margin="dense" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={MuiTextField} label="City" name="addressAttributes.city" margin="dense" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Field variant="outlined" component={MuiTextField} label="State" name="addressAttributes.state" margin="dense" fullWidth required />
                    </Grid>
                    <Grid item xs={6}>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading,max-len */}
                      <Field
                        variant="outlined"
                        component={MuiTextField}
                        label="Zip Code"
                        name="addressAttributes.zip"
                        margin="dense"
                        fullWidth
                        required
                        {...zipCodeMask}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <h3 style={{ marginTop: 60 }}>Expiration Date of the event posting</h3>
                  <header>{`When is the last date you would like this event to be posted on ${isRisekit ? 'RiseKit' : 'Solve'}?`}</header>
                  <Field
                    variant="outlined"
                    component={MuiDatePicker}
                    label="Post Expiration Date (MM-DD-YYYY)"
                    name="postExpirationDate"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MuiSelect options={serviceTypes} label="Service Type" name="primaryServiceType" margin="dense" />
                </Grid>
                <Grid item xs={12}>
                  <MuiSelect options={industryTypes} label="Select Industry" name="industryType" margin="dense" />
                </Grid>
                {/* <Grid item xs={12}>
                    <MuiSelect options={industrySpecifics} label="Select Industry Specifics" name="industrySpecificsType" margin="dense" />
                  </Grid> */}
                <Grid item xs={12}>
                  <header style={{ margin: 0 }}>Date Information</header>
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={MuiDatePicker} label="Event Date (MM-DD-YYYY)" name="eventDate" margin="dense" fullWidth required />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={MuiTimePicker} label="From" name="startTime" margin="dense" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <Field variant="outlined" component={MuiTimePicker} label="To" name="endTime" margin="dense" fullWidth />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <h3>Event Description</h3>
          <header>Please provide a thorough description of your event.</header>
          <Field variant="outlined" component={EditorField} label="Description" name="description" margin="dense" fullWidth required multiline />
        </Grid>
      </Grid>
    </Card>
    <Grid container>
      <Grid item xs={8}>
        <Link to="/staff/events">
          <Button buttonType="secondary-outline">Cancel</Button>
        </Link>
      </Grid>
      <Grid item xs={2}>
        <Button buttonType="secondary" type="submit">
          Next Step
        </Button>
      </Grid>
    </Grid>
  </form>
);
/* eslint-enable react/jsx-props-no-spreading */

EventOverviewForm.propTypes = propTypes;
EventOverviewForm.defaultProps = defaultProps;

const EventOverviewFormView = reduxForm({
  form: 'eventForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: eventOverviewFormValidate,
})(EventOverviewForm);

export default EventOverviewFormView;
