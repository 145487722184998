import styled from 'styled-components';
// import theme from '../../../common/theme';

const SelectCandidateRowStyles = styled.tr`
  td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    padding: 10px;
  }
  a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  svg {
    width: 22px;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    margin-right: 6px;
  }
  .in-progress {
    opacity: 0.5;
  }
  .circle {
    width: 17px;
    padding: 0px 1px;
  }
`;

export default SelectCandidateRowStyles;
