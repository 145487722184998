import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List } from 'immutable';
import Grid from '@material-ui/core/Grid';
// import { Autocomplete } from '@material-ui/core/FormControl';
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@material-ui/icons/Add';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import BackgroundsEditingStyles from './backgrounds_editing_styles';
import { MuiThemeProvider, MuiFormTheme } from '../../../../../common/form';
// import ReactSelect from '../../../../../common/form/redux_form/react_select';

const Autocomplete = ({ suggestions, newEntry, setNewEntry, onSubmit }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(newEntry);
  const onChange = (e) => {
    e.preventDefault();
    const newUserInput = e.currentTarget.value;
    setUserInput(newUserInput);
    const newFilteredSuggestions = suggestions.toJS().filter((suggestion) => suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
    setFilteredSuggestions(newFilteredSuggestions);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setShowSuggestions(false);
    setUserInput('');
    onSubmit({ background: { name: e.currentTarget.innerText, slug: e.currentTarget.getAttribute('slug') } });
    setFilteredSuggestions([]);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      let name;
      let slug;
      const selectedSuggestion = filteredSuggestions.filter((x) => x.label === userInput)[0];
      if (selectedSuggestion) {
        name = selectedSuggestion.label;
        slug = selectedSuggestion.slug;
        setUserInput(name);
        setNewEntry(name);
      } else {
        name = userInput;
        setUserInput('');
        setNewEntry('');
      }

      onSubmit({ background: { name, slug } });
      setShowSuggestions(false);
    }
  };

  let suggestionsListComponent;

  if (showSuggestions && userInput) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="background-suggestions">
          {filteredSuggestions.map((suggestion) => (
            <li key={suggestion.value} slug={suggestion.slug} role="presentation" onClick={onClick} onKeyDown={onKeyDown}>
              {suggestion.label}
            </li>
          ))}
        </ul>
      );
    } else {
      suggestionsListComponent = (
        <div className="no-suggestions">
          <em>No suggestions, you&apos;re on your own!</em>
        </div>
      );
    }
  }

  const hideSuggestions = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  return (
    <Fragment>
      <TextField
        id="backgrounds"
        label="Add backgrounds"
        type="text"
        margin="dense"
        variant="outlined"
        fullWidth
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        onBlur={hideSuggestions}
        InputLabelProps={{
          shrink: false,
        }}
      />
      {suggestionsListComponent}
    </Fragment>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(List),
  setNewEntry: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  newEntry: PropTypes.string,
};

Autocomplete.defaultProps = {
  suggestions: [],
  newEntry: '',
};

const propTypes = {
  backgrounds: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, get: PropTypes.func }).isRequired,
  backgroundOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

// TODO: Why are we including MuiThemeProvider at this point??
const BackgroundsEditingView = ({ backgrounds, backgroundOptions }) => {
  const [newEntry, setNewEntry] = useState();
  const existingEntries = backgrounds.map((name, index) => backgrounds.get(index).get('background').get('name'));
  const selectOptions = List(backgroundOptions)
    .filterNot((z) => existingEntries.includes(z[1]))
    .map((x) => ({ value: x[1], label: x[1], slug: x[0] }));

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   setNewEntry(e.target.value);
  // };
  const addToBackgrounds = (e) => {
    backgrounds.push(fromJS(e));
    setNewEntry(undefined);
  };

  return (
    <MuiThemeProvider theme={MuiFormTheme}>
      <BackgroundsEditingStyles>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            {/* <TextField */}
            {/*  id="backgrounds" */}
            {/*  label="Add backgrounds" */}
            {/*  type="test" */}
            {/*  margin="dense" */}
            {/*  variant="outlined" */}
            {/*  fullWidth */}
            {/*  onChange={handleInputChange} */}
            {/*  onSubmit={addToBackgrounds} */}
            {/*  mask="ddddd" */}
            {/*  value={newEntry || ''} */}
            {/*  InputLabelProps={{ */}
            {/*    shrink: false, */}
            {/*  }} */}
            {/* /> */}
            <Autocomplete onSubmit={addToBackgrounds} setNewEntry={setNewEntry} newEntry={newEntry} suggestions={selectOptions} />
          </Grid>
          {/* <Grid item xs={4} sm={12} md={6}>
            <IconButton disabled={!newEntry} aria-label="submit" onClick={addToBackgrounds} className="add-button">
              <AddIcon className="add-icon" /> Add Background
            </IconButton>
          </Grid> */}
        </Grid>
      </BackgroundsEditingStyles>
    </MuiThemeProvider>
  );
};

BackgroundsEditingView.propTypes = propTypes;
BackgroundsEditingView.defaultProps = defaultProps;

export default BackgroundsEditingView;
