import styled from 'styled-components';
// import theme from '../../../common/theme';

const RequirementsFormStyles = styled.div`
  form {
    max-width: 1100px;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  button {
    outline: none;
  }

  .indent {
    margin-left: 50px;
  }
`;

export default RequirementsFormStyles;
