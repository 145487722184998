import validator from 'validator';
import { reduxForm } from '../../../common/form';
import SignInView from './sign_in_view';

const REQUIRED = 'Required';
const INVALID_EMAIL_FORMAT = 'Invalid email format';
const INVALID_PHONE_FORMAT = 'Phone number must be 10 digits';
const INVALID_PHONE = 'Phone is invalid';
const NUMBERS = /^\d+$/;
const PHONE_REGEX = /^([2-9][0-9]{2}){2}[0-9]{4}$/;

const validate = (values) => {
  const errors = {};

  if (!values.get('email')) {
    errors.email = REQUIRED;
  } else if (values.get('email').match(NUMBERS)) {
    if (values.get('email').length !== 10) {
      errors.email = INVALID_PHONE_FORMAT;
    } else if (!values.get('email').match(PHONE_REGEX)) {
      errors.email = INVALID_PHONE;
    }
  } else if (!validator.isEmail(values.get('email'))) {
    errors.email = INVALID_EMAIL_FORMAT;
  }

  if (!values.get('password')) {
    errors.password = REQUIRED;
  }

  return errors;
};

const SignInForm = reduxForm({ form: 'signin', validate })(SignInView);

export default SignInForm;
