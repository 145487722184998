// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

import addressValidator from '../../../common/form/helpers/address_validator';

const organizationFormValidate = (values) => {
  const errors = {
    org: {
      addressAttributes: {},
    },
  };
  if (values.get('org')) {
    // if (!values.getIn(['org', 'logo'])) {
    //   errors.org.logo = true;
    // }
    if (!values.getIn(['org', 'name'])) {
      errors.org.name = 'Please enter your company name';
    }
    // if (!values.getIn(['org', 'ein'])) {
    //   errors.org.ein = 'Please enter your company EIN';
    // } else if (!/^\d{3}-?\d{2}-?\d{4}$/.test(values.getIn(['org', 'ein']))) {
    //   errors.org.ein = 'Invalid EIN';
    // }
    if (!values.getIn(['org', 'primaryWebsite'])) {
      errors.org.primaryWebsite = 'Please enter your company website';
    }
    errors.org.addressAttributes = addressValidator(values.getIn(['org', 'addressAttributes']));
    if (!values.getIn(['org', 'phone'])) {
      errors.org.phone = 'Please enter your company phone number';
    }
    if (values && values.get('org') && values.getIn(['org', 'phone']) && values.getIn(['org', 'phone']).match(/\d/g).length < 10) {
      errors.org.phone = 'Please enter a valid phone number';
    }
    // if (!values.getIn(['org', 'description'])) {
    //   errors.org.description = 'Please enter a description of your company';
    // }
    // if (!values.getIn(['org', 'orgCategoryType'])) {
    //   errors.org.orgType = 'Required';
    // }
  }
  return errors;
};

export default organizationFormValidate;
