import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../common/theme';

const ResumeBox = ({ children, gutters, flex, stretch }) => (
  <Wrapper gutters={gutters} flex={flex} stretch={stretch}>
    {children}
  </Wrapper>
);

ResumeBox.defaultProps = {
  children: undefined,
  flex: 'row',
  gutters: undefined,
  stretch: undefined,
};

ResumeBox.propTypes = {
  children: PropTypes.node,
  flex: PropTypes.string,
  gutters: PropTypes.string,
  stretch: PropTypes.bool,
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => props.flex};

  ${(props) =>
    props.stretch &&
    `
    justify-content: space-between;
  `};

  ${({ gutters }) => {
    switch (gutters) {
      case 'both':
        return `
        margin: 0 1em;
      `;

      case 'left':
        return `
        margin: 0 0 0 1em;
      `;

      case 'right':
        return `
        margin: 0 1em 0 0;
      `;

      default:
        return `
        margin: 0;
      `;
    }
  }};

  @media ${theme.query.wideMin} {
  }

  > * {
    margin: ${(props) => (props.flex === 'column' ? '0' : '0 0.5em')};

    ${(props) =>
    props.stretch &&
      `
      flex-grow: 1;
    `};

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

export default ResumeBox;
