import errorActions from '../actions';
import solveActions from '../../solve/actions';

const ACTIONS = {
  ...errorActions,
  ...solveActions,
};

const DEFAULT_ERROR_STATE = {
  action: '',
  errorMessage: '',
};

const ApiErrorReducer = (state = DEFAULT_ERROR_STATE, { type } = {}) => {
  switch (type) {
    case ACTIONS.TRANSITION_CANDIDATE_PROGRAM_S.failure:
      return { action: ACTIONS.TRANSITION_CANDIDATE_PROGRAM_S.failure, errorMessage: 'You cannot update the applicant to that status' };
    case ACTIONS.CLEAR_API_ERROR:
      return DEFAULT_ERROR_STATE;
    default:
      return state;
  }
};

export default ApiErrorReducer;
