import styled from 'styled-components';
import theme from '../../../common/theme';

const ReportsStyles = styled.div``;

export const Title = styled.h1``;

export const SubTitle = styled.p`
  text-transform: uppercase;
  color: ${theme.color.subtleAction};
  font-size: 1em;
  font-weight: 700;
`;

export const Box = styled.div`
  padding: 20px;
  background-color: ${theme.color.tile2};
  border-radius: 5px;
  cursor: pointer;
  img {
    margin: 0 auto;
  }
`;

export const SupportTitle = styled.p`
  color: ${theme.color.primary};
  font-weight: 600;
  margin: 0;
`;

export const SupportText = styled.p`
  color: ${theme.color.primary};
  font-weight: 500;
  font-style: italic;
  margin: 0;
`;

export default ReportsStyles;
