import styled from 'styled-components';
// import theme from '../../../common/theme';

const ChallengesStyles = styled.div``;

export const Summaries = styled.div`
  margin-top: 10px;
  h4 {
    margin: 0;
  }
`;

export const CircleNumber = styled.div`
  background-color: ${(props) => props.color || '#ccc'};
  color: #fff;
  border-radius: 20px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
`;

export default ChallengesStyles;
