import styled from 'styled-components';
// import theme from '../../../common/theme';

const messagesListInboxStyles = styled.div`
  .new-message {
    float: right;
  }
`;

export default messagesListInboxStyles;
