import ACTIONS from '../actions';
import { ResourcefulReducer } from '../../util';
import CandidateServiceModel from '../models/candidate_service_model';

const CandidateServiceReducer = new ResourcefulReducer('candidateServices', CandidateServiceModel, {
  actionTypes: [
    // ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES,
    // ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES.success,
    // ACTIONS.LIST_FILTERED_SERVICE_CANDIDATES_FOR_STAFF.success,
    // ACTIONS.CREATE_CANDIDATE_SERVICE.success,
    ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE.success,
    ACTIONS.LIST_CANDIDATE_SERVICES.success,
    ACTIONS.FETCH_CANDIDATE_SERVICE.success,
  ],
});

export default CandidateServiceReducer;
