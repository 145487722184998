import { connect } from 'react-redux';
import SplashScreenView from './splash_screen_view';
import { getTreatment } from '../../../reducer';
import { setOnboardingMode } from '../../../onboarding/actions';

const mapStateToProps = (state) => ({
  isRisekit: !!getTreatment(state, 'isRisekit', true),
});

const mapDispatchToProps = {
  setOnboardingMode,
};

const SplashScreenContainer = connect(mapStateToProps, mapDispatchToProps)(SplashScreenView);

export default SplashScreenContainer;
