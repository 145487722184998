import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { reduxForm } from '../../../../common/form';
import SurveyWizard from '../../survey_wizard';
import additionalQuestionsFormValidate from './additional_questions_form_validate';
import AdditionalQuestionsFormStyles from './additional_questions_form_styles';

const propTypes = {
  onComplete: PropTypes.func.isRequired,
  event: PropTypes.instanceOf(Map).isRequired,
};

const AdditionalQuestionsForm = ({ onComplete, event }) => (
  <AdditionalQuestionsFormStyles>
    <SurveyWizard surveys={event.get('surveys')} onComplete={onComplete} />
  </AdditionalQuestionsFormStyles>
);

AdditionalQuestionsForm.propTypes = propTypes;

const AdditionalQuestionsFormView = reduxForm({
  form: 'applyToEventForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: additionalQuestionsFormValidate,
})(AdditionalQuestionsForm);

export default AdditionalQuestionsFormView;
