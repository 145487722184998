import moment from 'moment';

export const formatDate = (date) => moment(date).format('MMMM Do YYYY');

export const formatTime = (time) => {
  if (typeof time === 'string') {
    return time;
  }
  return new Date(time).toLocaleTimeString([], { timeStyle: 'short' });
};
