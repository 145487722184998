import React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet, fromJS } from 'immutable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FilterSelect = ({ filters, onChange, name, children }) => (
  <Select
    value={filters.get(name) || ''}
    fullWidth
    onChange={(e) => {
      onChange(filters.set(name, e.target.value));
    }}
  >
    {children}
  </Select>
);

FilterSelect.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const MultiFilterSelect = ({ filters, onChange, name, children }) => {
  const handleFilterChange = (e) => {
    const selectedYears = e.target.value;
    const isClearClicked = selectedYears.includes('');

    const newValue = isClearClicked ? [] : [...selectedYears];

    onChange(filters.set(name, fromJS(newValue)))
  };

  return (
    <Select value={filters.get(name)?.toJS() || []} fullWidth multiple onChange={handleFilterChange}>
      {children}
    </Select>
  );
};

MultiFilterSelect.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const PathwayReportFiltersView = ({ filters, onChange, yearFilter }) => (
  <Grid container spacing={2} style={{ marginBottom: 15 }}>
    <Grid item md={3} xs={6}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>Year</InputLabel>
        <MultiFilterSelect filters={filters} onChange={onChange} name="year" variant="outlined">
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {yearFilter &&
            yearFilter.map((label) => (
              <MenuItem key={label} value={label}>
                {label}
              </MenuItem>
            ))}
        </MultiFilterSelect>
      </FormControl>
    </Grid>
    <Grid item md={3} xs={6}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>Pathway State</InputLabel>
        <MultiFilterSelect filters={filters} onChange={onChange} name="state">
          <MenuItem key="active" value="active">
            Active
          </MenuItem>
          <MenuItem key="closed" value="closed">
            Closed
          </MenuItem>
        </MultiFilterSelect>
      </FormControl>
    </Grid>
  </Grid>
);

PathwayReportFiltersView.propTypes = {
  filters: PropTypes.instanceOf(Map),
  onChange: PropTypes.func.isRequired,
  yearFilter: PropTypes.instanceOf(OrderedSet).isRequired,
};

PathwayReportFiltersView.defaultProps = {
  filters: new Map(),
};

export default PathwayReportFiltersView;
