import styled from 'styled-components';
import theme from '../../../common/theme';

const CreateProgramStyles = styled.div``;

export const Title = styled.h1``;

export const SubTitle = styled.p`
  text-transform: uppercase;
  color: ${theme.color.subtleAction};
  font-size: 1em;
  font-weight: 700;
`;

export const Box = styled.div`
  padding: 40px;
  background-color: ${theme.color.tile2};
  text-align: center;
  height: 300px;
  width: 300px;
  border-radius: 5px;

  img {
    margin: 0 auto;
  }
`;

export const SupportText = styled.p`
  font-size: 1em;
  color: ${theme.color.primary};
  text-transform: uppercase;
  font-weight: 500;
`;

export default CreateProgramStyles;
