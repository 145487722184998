import React from 'react';
import PropTypes from 'prop-types';
import { List, OrderedSet } from 'immutable';
import { Field } from 'redux-form/immutable';
import { Select } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.oneOfType([PropTypes.instanceOf(OrderedSet), PropTypes.instanceOf(List)]),
};

const defaultProps = {
  label: undefined,
  required: false,
  options: new OrderedSet(),
};

const MuiSelect = ({ name, options, label, required, ...rest }) => (
  <FormControl fullWidth margin="dense" variant="outlined">
    <InputLabel
      htmlFor="select-checkbox"
      InputLabelProps={{
        shrink: false,
      }}
    >
      {label}
      {required ? '*' : ''}
    </InputLabel>
    <Field component={Select} name={name} {...rest /* eslint-disable-line react/jsx-props-no-spreading */}>
      {options &&
        options.map((option) => (
          <MenuItem key={option[0]} value={option[0]}>
            {option[1]}
          </MenuItem>
        ))}
    </Field>
  </FormControl>
);

MuiSelect.propTypes = propTypes;
MuiSelect.defaultProps = defaultProps;

export default MuiSelect;
