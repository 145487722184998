import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import ConfirmEmailStyles from './confirm_email_styles';
import Spinner from '../../../common/components/spinner';
import { useApiCall } from '../../../util/hooks';
import UserModel from '../../models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  confirmEmail: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

const ConfirmEmailView = ({ currentUser, confirmEmail, token }) => {
  const [doConfirm, { failed, success }] = useApiCall(confirmEmail);
  useEffect(() => {
    doConfirm(token);
  }, [doConfirm, token]);

  if (success) {
    // TODO: add a flash notification?
    return <Redirect to="/" />;
  }

  if (failed) {
    if (currentUser.validEmail) {
      return <Redirect to="/" />;
    }
    return <ConfirmEmailStyles>We were unable to verify your email. Please check your email and ensure you followed the correct link.</ConfirmEmailStyles>;
  }

  return <Spinner />;
};

ConfirmEmailView.propTypes = propTypes;

export default ConfirmEmailView;
