import { connect } from 'react-redux';
import TileView from './tile_view';
import { dismissNotification, setNotificationAsViewed } from '../../../solve/actions';
import { getCurrentUser } from '../../../reducer';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  dismissNotification,
  setNotificationAsViewed,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  dismiss: (notificationId) => {
    dispatchProps.setNotificationAsViewed(stateProps.currentUser, notificationId).then(() => {
      dispatchProps.dismissNotification(stateProps.currentUser, notificationId);
    });
  },
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
});

const TileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(TileView);

export default TileContainer;
