import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import Select from '@material-ui/core/Select';

const FilterSelect = ({ filters, onChange, children }) => (
  <Select
    value={filters || ''}
    fullWidth
    onChange={(e) => {
      onChange(e.target.value);
    }}
  >
    {children}
  </Select>
);
FilterSelect.propTypes = {
  filters: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const ZipDistanceFilter = ({
  distanceFilterChoices,
  sortByFilterChoices,
  zipCode,
  setCandidateProgramsZipCode,
  setCandidateProgramsSortBy,
  sortBy,
  distance,
  setCandidateProgramsDistance,
}) => {
  const [newZipcode, setNewZipcode] = useState(zipCode);
  const [zipValid, setZipValid] = useState(true);

  // Ensure we set the local state after updating redux
  useEffect(() => {
    setNewZipcode(zipCode);
  }, [zipCode, setNewZipcode]);

  const ifZip = (zip, action) => {
    if (zip === '') {
      setZipValid(true);
      if (action) {
        action(zip);
      }
    } else if (zip.match(/^\d{5}$/)) {
      if (!zipValid) {
        setZipValid(true);
      }
      if (action) {
        action(zip);
      }
    } else if (zipValid) {
      setZipValid(false);
    }
  };

  const onZipSubmit = (e) => {
    e.preventDefault();
    const val = e.target.querySelector('input').value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setCandidateProgramsZipCode);
  };
  const onZipBlur = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setCandidateProgramsZipCode);
  };
  const onZipChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (newZipcode === val) {
      return;
    }
    ifZip(val);
    setNewZipcode(val);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={4}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Distance</InputLabel>
          <FilterSelect filters={distance} onChange={setCandidateProgramsDistance} variant="outlined">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {distanceFilterChoices &&
              distanceFilterChoices.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
            <MenuItem value="" disabled style={{ opacity: 1, float: 'right' }}>
              <img src="/images/powered_by_google_white.png" alt="powered by Google" />
            </MenuItem>
          </FilterSelect>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={4}>
        <form onSubmit={onZipSubmit}>
          <TextField
            id="zipCode"
            label="Zip Code"
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth
            mask="ddddd"
            onBlur={onZipBlur}
            onChange={onZipChange}
            className={zipValid ? '' : 'error'}
            error={!zipValid}
            value={newZipcode || ''}
            InputLabelProps={{
              shrink: false,
            }}
          />
        </form>
      </Grid>
      <Grid item xs={12} md={4}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Sort by</InputLabel>
          <FilterSelect filters={sortBy} onChange={setCandidateProgramsSortBy} variant="outlined">
            {sortByFilterChoices &&
              sortByFilterChoices.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};
ZipDistanceFilter.propTypes = {
  distanceFilterChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  sortByFilterChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  zipCode: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  sortBy: PropTypes.string.isRequired,
  setCandidateProgramsZipCode: PropTypes.func.isRequired,
  setCandidateProgramsDistance: PropTypes.func.isRequired,
  setCandidateProgramsSortBy: PropTypes.func.isRequired,
};

ZipDistanceFilter.defaultProps = {};

export default ZipDistanceFilter;
