import styled from 'styled-components';
import theme from '../../../../common/theme';

const FinalizeFormStyles = styled.div`
  img {
    margin: auto;
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 128;
    height: 128;
  }

  form {
    width: 100%;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }
  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }
  .button-right button:nth-child(1),
  .button-right button:nth-child(2) {
    margin-right: 20px;
  }
  button {
    outline: none;
  }
  .finalize-form {
    padding: 5px 10px 25px;
  }
  @media ${theme.query.desktopMax} {
    form {
      padding: 0px 5px;
    }
    .finalize-form {
      padding: 0px 0px 20px;
    }
    .button-group {
      flex-direction: row;
      align-items: flex-start;
    }
    .button-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .button-right button:nth-child(1),
    .button-right button:nth-child(2) {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
  @media ${theme.query.tabletMax} {
    .button-group {
      flex-direction: column !important;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .button-left {
      width: 100%;
    }
    button,
    .button {
      margin: 8px 0px;
      max-width: 210px;
      width: 100%;
    }
  }

  @media ${theme.query.desktopMax} {
    form {
      padding: 0px 5px;
    }
    .finalize-form {
      padding: 0px 0px 20px;
    }
    .button-group {
      flex-direction: row;
      align-items: flex-start;
    }
    .button-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .button-right button:nth-child(1),
    .button-right button:nth-child(2) {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
`;

export const EventReviewStyles = styled.div`
  .sub-heading {
    font-weight: bold;
    color: #394148;
  }
  p {
    margin: 5px 0px;
  }
  ul {
    padding-left: 30px;
    margin: 0px;
  }
  li {
    font-size: 16px;
    line-height: 24px;
    color: #6c7a89;
  }
`;

export default FinalizeFormStyles;
