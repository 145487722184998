import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import RecidivismReportsStyles from './recidivism_reports_styles';
import { useFetchOnce } from '../../../../util/hooks';
import UserModel from '../../../../session/models/user_model';
import { Spinner } from '../../../../common/components';

const EMPTY_MAP = Map();
const RecidivismTable = ({ title, data = EMPTY_MAP }) => (
  <div>
    <h2>{title}</h2>
    <table>
      <thead>
        <tr>
          <th>Region</th>
          <th>Ready</th>
          <th>Unable</th>
          <th>Pending</th>
          <th>Cleared</th>
          <th>Hit</th>
          {/* <th>Eligible</th> */}
          {/* <th>Rate</th> */}
          <th>Upcoming</th>
        </tr>
      </thead>
      <tbody>
        {data
          .map((row, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={key}>
              <th>{key}</th>
              <td>{row.get('ready') || 0}</td>
              <td>{row.get('unable') || 0}</td>
              <td>{row.get('pending') || 0}</td>
              <td>{row.get('cleared') || 0}</td>
              <td>{row.get('hit') || 0}</td>
              {/* <td>{row.get('eligible') || 0}</td> */}
              {/* <td>{row.get('rate') || 0}</td> */}
              <td>{row.get('upcoming') || 0}</td>
            </tr>
          ))
          .valueSeq()}
      </tbody>
    </table>
  </div>
);
RecidivismTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
};

const RecidivismReportsView = ({ user, report, fetchRecidivismReport }) => {
  useFetchOnce(user, user.getIn(['staffOrganization', 'id']), fetchRecidivismReport);
  if (!report) {
    return <Spinner />;
  }
  return (
    <RecidivismReportsStyles>
      <h1>Defy Recidivism Report</h1>
      <RecidivismTable title="1-year Recidivism Report" data={report.get('year1')} />
      <RecidivismTable title="3-year Recidivism Report" data={report.get('year3')} />
    </RecidivismReportsStyles>
  );
};

RecidivismReportsView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  report: PropTypes.instanceOf(Map),
  fetchRecidivismReport: PropTypes.func.isRequired,
};
RecidivismReportsView.defaultProps = {
  report: null,
};

export default RecidivismReportsView;
