import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import ResourcesListView from './resources_list_view';
import {
  getCurrentUser,
  getCandidate,
  getCandidateEventIds,
  getCandidateProgramIds,
  getCandidateEventsLoaded,
  getCandidateProgramsLoaded,
  getCandidateSavedEventIds,
  getCandidateSavedEventsLoaded,
  getCandidateSavedProgramIds,
  getCandidateSavedProgramsLoaded,
  getCandidateAppliedEventIds,
  getCandidateAppliedEventsLoaded,
  getCandidateAppliedProgramIds,
  getCandidateAppliedProgramsLoaded,
  getCandidateProgramsFilters,
  getCandidateProgramsSearch,
  getCandidateProgramsZipCode,
  getCandidateProgramsSortBy,
  getCandidateProgramsDistance,
  getEnumsForFormSelect,
  getCandidateAppliedServiceIds,
  getCandidateAppliedServicesLoaded,
  getCandidateServiceIds,
  getCandidateServicesLoaded,
  getCandidateSavedServiceIds,
  getCandidateSavedServicesLoaded,
  getResourceIdsSortedForCandidate,
  getSavedResourceIdsSortedForCandidate,
  getAppliedResourceIdsSortedForCandidate,
  getResourcesLoadedForCandidate,
  getAppliedResourcesLoadedForCandidate,
  getSavedResourcesLoadedForCandidate,
  getExternalServiceIds,
  getExternalServicesLoaded,
  getTreatment,
  getResourceIdsPaginated,
  getSavedResourceIdsPaginated,
  getAppliedResourceIdsPaginated,
} from '../../../reducer';

import {
  listPrograms,
  listEvents,
  listFilteredCandidateEventsForCandidate,
  listFilteredEventsForCandidate,
  listFilteredCandidateProgramsForCandidate,
  listFilteredProgramsForCandidate,
  setCandidateProgramsSearch,
  setCandidateProgramsZipCode,
  setCandidateProgramsFilters,
  setCandidateProgramsPage,
  setCandidateProgramsSortBy,
  setCandidateProgramsDistance,
  listSupportiveServices,
  listFilteredServicesForCandidate,
  listFilteredCandidateServicesForCandidate,
  listFilteredABServicesForCandidate,
  fetchExternalCandidateServicesServices,
  fetchExternalCandidateServices,
} from '../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state, { mode }) => {
  let resourceIdsSorted;
  let resourcesLoaded;
  let programIds;
  let programsLoaded;
  let eventIds;
  let eventsLoaded;
  let serviceIds;
  let servicesLoaded;
  let externalServiceIds;
  let externalServicesLoaded;
  let resourceIdsPaginated;
  const sortBy = getCandidateProgramsSortBy(state);
  const distance = getCandidateProgramsDistance(state);

  switch (mode) {
    case 'saved':
      eventIds = getCandidateSavedEventIds(state);
      eventsLoaded = getCandidateSavedEventsLoaded(state);
      programIds = getCandidateSavedProgramIds(state);
      programsLoaded = getCandidateSavedProgramsLoaded(state);
      serviceIds = getCandidateSavedServiceIds(state);
      servicesLoaded = getCandidateSavedServicesLoaded(state);
      resourceIdsSorted = getSavedResourceIdsSortedForCandidate(state);
      resourceIdsPaginated = getSavedResourceIdsPaginated(state);
      resourcesLoaded = getSavedResourcesLoadedForCandidate(state);
      externalServiceIds = getExternalServiceIds(state);
      externalServicesLoaded = getExternalServicesLoaded(state);
      break;
    case 'applied':
      eventIds = getCandidateAppliedEventIds(state);
      eventsLoaded = getCandidateAppliedEventsLoaded(state);
      programIds = getCandidateAppliedProgramIds(state);
      programsLoaded = getCandidateAppliedProgramsLoaded(state);
      serviceIds = getCandidateAppliedServiceIds(state);
      servicesLoaded = getCandidateAppliedServicesLoaded(state);
      resourceIdsSorted = getAppliedResourceIdsSortedForCandidate(state);
      resourcesLoaded = getAppliedResourcesLoadedForCandidate(state);
      resourceIdsPaginated = getAppliedResourceIdsPaginated(state);
      externalServiceIds = getExternalServiceIds(state);
      externalServicesLoaded = getExternalServicesLoaded(state);
      break;
    case 'all':
    default:
      eventIds = getCandidateEventIds(state);
      eventsLoaded = getCandidateEventsLoaded(state);
      programIds = getCandidateProgramIds(state);
      programsLoaded = getCandidateProgramsLoaded(state);
      serviceIds = getCandidateServiceIds(state);
      servicesLoaded = getCandidateServicesLoaded(state);
      externalServiceIds = getExternalServiceIds(state);
      externalServicesLoaded = getExternalServicesLoaded(state);
      resourceIdsSorted = getResourceIdsSortedForCandidate(state);
      resourceIdsPaginated = getResourceIdsPaginated(state);
      resourcesLoaded = getResourcesLoadedForCandidate(state);
  }

  const showAB = getTreatment(state, 'showAb');

  let allPostings =
    eventIds &&
    eventIds
      .map((id) => [id, { id, type: 'event' }])
      .union(programIds.map((id) => [id, { id, type: 'program' }]))
      .union(serviceIds.map((id) => [id, { id, type: 'service' }]));

  if (showAB && externalServicesLoaded && externalServiceIds?.size !== 0) {
    allPostings = allPostings.union(externalServiceIds.map((id) => [id, { id, type: 'service', ab_service: true }]));
  }
  const allPostingsMap = new Map(allPostings);
  const postings = resourceIdsPaginated.map((id) => allPostingsMap.get(id));
  const sortByFilterChoices = new OrderedSet([
    ['distance', 'Distance'],
    ['name', 'Name'],
  ]);

  const currentUser = getCurrentUser(state);
  const currentCandidate = getCandidate(state, currentUser.get('candidateId'));

  return {
    currentUser,
    currentCandidate,
    programsLoaded,
    eventsLoaded,
    servicesLoaded,
    externalServicesLoaded,
    resourcesLoaded,
    postings,
    distanceFilterChoices: getEnumsForFormSelect(state, 'DistanceType'),
    sortByFilterChoices,
    sortBy,
    distance,
    serviceTypeChoices: getEnumsForFormSelect(state, 'ServiceType'),
    targetTypeChoices: getEnumsForFormSelect(state, 'TargetParticipantType'),
    filters: getCandidateProgramsFilters(state),
    zipCode: getCandidateProgramsZipCode(state),
    search: getCandidateProgramsSearch(state),
    showAB,
    totalCount: resourceIdsSorted.size,
  };
};

const mapDispatchToProps = {
  listPrograms,
  listEvents,
  listSupportiveServices,
  fetchExternalCandidateServicesServices,
  listFilteredABServicesForCandidate,
  listFilteredServicesForCandidate,
  listFilteredCandidateServicesForCandidate,
  listFilteredCandidateEventsForCandidate,
  listFilteredEventsForCandidate,
  listFilteredCandidateProgramsForCandidate,
  listFilteredProgramsForCandidate,
  fetchExternalCandidateServices,
  setCandidateProgramsZipCode,
  setCandidateProgramsPage,
  setCandidateProgramsSortBy,
  setCandidateProgramsDistance,
  onChange: setCandidateProgramsFilters,
  setSearch: setCandidateProgramsSearch,
};

const ResourcesListContainer = connect(mapStateToProps, mapDispatchToProps)(ResourcesListView);

export default ResourcesListContainer;
