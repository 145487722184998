import { connect } from 'react-redux';
import SelectTypeFormForm from './select_type_form_form';
import { listPathways } from '../../../../solve/actions';
import { getPathways, getCurrentUser } from '../../../../reducer';

const mapStateToProps = (state) => {
  const organizationId = getCurrentUser(state).getIn(['staffOrganization', 'id']);
  return {
    pathwayTypes: getPathways(state).map((item) => [item.get('id'), item.get('name')]),
    currentUser: getCurrentUser(state),
    organizationId,
  };
};

const mapDispatchToProps = {
  listPathways,
};

const SelectTypeFormContainer = connect(mapStateToProps, mapDispatchToProps)(SelectTypeFormForm);

export default SelectTypeFormContainer;
