import { fromJS } from 'immutable';
import { reset } from 'redux-form/immutable';
import * as api from '../../api';
import { genericAPIActionTypes, genericAPIActionCreator } from '../../util';
import paginatedAPIAction from '../../util/paginated_api_action';
import bufferedPaginatedAPIAction from '../../util/buffered_paginated_api_action';

const ACTIONS = {
  CONFIRM_EMAIL: genericAPIActionTypes('Solve:ConfirmEmail'),
  RECONFIRM_EMAIL: genericAPIActionTypes('Solve:ReconfirmEmail'),
  LIST_ALL_CANDIDATES: genericAPIActionTypes('Solve:ListCandidates'),
  LIST_CANDIDATE_ORGANIZATIONS: genericAPIActionTypes('Solve:ListCandidateOrganizations'),
  LIST_CANDIDATE_APPLICATIONS_FOR_CANDIDATE: genericAPIActionTypes('Solve:ListCandidateApplications:Candidate'),
  LIST_EXTERNAL_CANDIDATE_APPLICATIONS_FOR_CANDIDATE: genericAPIActionTypes('Solve:ListExternalCandidateApplications:Candidate'),
  TRANSITION_EXTERNAL_CANDIDATE_APPLICATIONS_FOR_CANDIDATE: genericAPIActionTypes('Solve:TransistionExternalCandidateApplications:Candidate'),
  LIST_FILTERED_CANDIDATE_APPLICATIONS: genericAPIActionTypes('Solve:ListFilteredCandidateApplications'),
  SET_CANDIDATE_APPLICATIONS_SEARCH: 'Solve:CA:SetSearch',
  SET_CANDIDATE_PROGRAMS_SEARCH: 'Solve:CP:SetSearch',
  SET_CANDIDATE_PROGRAMS_FILTERS: 'Solve:CP:SetFilters',
  SET_CANDIDATE_PROGRAMS_PAGE: 'Solve:CP:SetPage',
  SET_CANDIDATE_PROGRAMS_SORT_BY: 'Solve:CP:SetSortBy',
  SET_CANDIDATE_PROGRAMS_DISTANCE: 'Solve:CP:SetDistance',
  SET_CANDIDATE_PROGRAMS_ZIP_CODE: 'Solve:CP:SetZipCode',
  LIST_CANDIDATES_FOR_PROGRAM: genericAPIActionTypes('Solve:ListCandidatesForProgram'),
  LIST_CANDIDATES_FOR_EVENT: genericAPIActionTypes('Solve:ListCandidatesForEvent'),
  CREATE_CANDIDATE_APPLICATION: genericAPIActionTypes('Solve:CreateCandidateApplication'),
  CREATE_EXTERNAL_CANDIDATE_APPLICATION: genericAPIActionTypes('Solve:CreateExternalCandidateApplication'),
  FETCH_CANDIDATE_APPLICATION: genericAPIActionTypes('Solve:FetchCandidateApplication'),
  CREATE_CANDIDATE_PROGRAM: genericAPIActionTypes('Solve:CreateCandidateProgram'),
  FETCH_CANDIDATE_PROGRAM: genericAPIActionTypes('Solve:FetchCandidateProgram'),
  CREATE_CANDIDATE_EVENT: genericAPIActionTypes('Solve:CreateCandidateEvent'),
  FETCH_CANDIDATE_EVENT: genericAPIActionTypes('Solve:FetchCandidateEvent'),
  LIST_CANDIDATE_PATHWAYS: genericAPIActionTypes('Solve:ListCandidatePathways'),
  FETCH_CANDIDATE_PATHWAY: genericAPIActionTypes('Solve:FetchCandidatePathway'),
  FETCH_CANDIDATE_PATHWAYS: genericAPIActionTypes('Solve:FetchCandidatePathways'),
  UPDATE_CANDIDATE_PATHWAY: genericAPIActionTypes('Solve:UpdateCandidatePathway'),
  SET_PATHWAY_REPORT_FILTERS: 'Solve:PathwayReport:SetFilters',
  TRANSITION_CANDIDATE_APPLICATION_C: genericAPIActionTypes('Solve:TransitionCandidateApplication:Candidate'),
  TRANSITION_CANDIDATE_APPLICATION_S: genericAPIActionTypes('Solve:TransitionCandidateApplication:Staff'),
  TRANSITION_CANDIDATE_PROGRAM_S: genericAPIActionTypes('Solve:TransitionCandidateProgram:Staff'),
  TRANSITION_CANDIDATE_EVENT_S: genericAPIActionTypes('Solve:TransitionCandidateEvent:Staff'),
  TRANSITION_CANDIDATE_PROGRAM_C: genericAPIActionTypes('Solve:TransitionCandidateProgram:Candidate'),
  TRANSITION_CANDIDATE_SERVICE_S: genericAPIActionTypes('Solve:TransitionCandidateService:Staff'),
  TRANSITION_CANDIDATE_SERVICE_C: genericAPIActionTypes('Solve:TransitionCandidateService:Candidate'),
  TRANSITION_CANDIDATE_EVENT_C: genericAPIActionTypes('Solve:TransitionCandidateEvent:Candidate'),
  LIST_CANDIDATE_SURVEYS: genericAPIActionTypes('Solve:ListCandidateSurveys'),
  START_SURVEY: genericAPIActionTypes('Solve:StartSurvey'),
  UPDATE_SURVEY: genericAPIActionTypes('Solve:UpdateSurvey'),
  LIST_EMPLOYERS: genericAPIActionTypes('Solve:ListEmployers'),
  LIST_JOB_POSTINGS: genericAPIActionTypes('Solve:ListJobPostings'),
  LIST_INDEED_JOB_POSTINGS: genericAPIActionTypes('Solve:ListJobPostings'),
  CREATE_JOB_POSTING: genericAPIActionTypes('Solve:CreateJobPosting'),
  PUBLISH_JOB_POSTING: genericAPIActionTypes('Solve:PublishJobPosting'),
  FETCH_JOB_POSTING: genericAPIActionTypes('Solve:FetchJobPostings'),
  FETCH_INDEED_JOB_POSTING: genericAPIActionTypes('Solve:FetchIndeedJobPostings'),
  UPDATE_JOB_POSTING: genericAPIActionTypes('Solve:UpdateJobPosting'),
  LIST_APPLICATIONS_TO_JOB_POSTING: genericAPIActionTypes('Solve:ListApplicationsToJobPosting'),
  FETCH_JOB_POSTING_REPORT: genericAPIActionTypes('Solve:FetchJobPostingReport'),
  LIST_ORGANIZATIONS: genericAPIActionTypes('Solve:ListOrganizations'),
  LIST_FILTERED_ORGANIZATIONS: genericAPIActionTypes('Solve:ListFilteredOrganizations'),
  LIST_CHILD_ORGANIZATIONS: genericAPIActionTypes('Solve:ListChildOrganizations'),
  FETCH_ORGANIZATION: genericAPIActionTypes('Solve:FetchOrganization'),
  CREATE_ORGANIZATION: genericAPIActionTypes('Solve:CreateOrganization'),
  UPDATE_ORGANIZATION: genericAPIActionTypes('Solve:UpdateOrganization'),
  FETCH_ORGANIZATION_CANDIDATES_REPORT: genericAPIActionTypes('Solve:FetchOrganizationCandidatesReport'),
  FETCH_ORGANIZATION_NEW_CANDIDATES_REPORT: genericAPIActionTypes('Solve:FetchOrganizationNewCandidatesReport'),
  FETCH_ORGANIZATION_CAREER_FAIR_REPORT: genericAPIActionTypes('Solve:FetchOrganizationCareerFairReport'),
  FETCH_RECIDIVISM_REPORT: genericAPIActionTypes('Solve:FetchRecidivismReport'),
  LIST_RESUMES: genericAPIActionTypes('Solve:ListResumes'),
  FETCH_RESUME: genericAPIActionTypes('Solve:FetchResume'),
  CREATE_RESUME: genericAPIActionTypes('Solve:CreateResume'),
  UPDATE_RESUME: genericAPIActionTypes('Solve:UpdateResume'),
  UPLOAD_RESUME: genericAPIActionTypes('Solve:UploadResume'),
  FETCH_INMATE: genericAPIActionTypes('Solve:FetchInmate'),
  CREATE_INMATE: genericAPIActionTypes('Solve:CreateInmate'),
  UPDATE_INMATE: genericAPIActionTypes('Solve:UpdateInmate'),
  LIST_CHALLENGES: genericAPIActionTypes('Solve:ListChallenges'),
  FETCH_CHALLENGE: genericAPIActionTypes('Solve:FetchChallenge'),
  UPDATE_CHALLENGE: genericAPIActionTypes('Solve:UpdateChallenge'),
  LIST_GLOBAL_SURVEYS: genericAPIActionTypes('Solve:ListGlobalSurveys'),
  CHECK_LIST_GLOBAL_SURVEYS_BACKOFF: 'Solve:ListGlobalSurveys:BackoffCheck',
  LIST_SURVEYS: genericAPIActionTypes('Solve:ListSurveys'),
  LIST_PROGRAMS: genericAPIActionTypes('Solve:ListPrograms'),
  FETCH_PROGRAM: genericAPIActionTypes('Solve:FetchProgram'),
  CREATE_PROGRAM: genericAPIActionTypes('Solve:CreateProgram'),
  UPDATE_PROGRAM: genericAPIActionTypes('Solve:UpdateProgram'),
  LIST_SERVICES: genericAPIActionTypes('Solve:ListServices'),
  FETCH_SERVICE: genericAPIActionTypes('Solve:FetchService'),
  CREATE_SERVICE: genericAPIActionTypes('Solve:CreateService'),
  UPDATE_SERVICE: genericAPIActionTypes('Solve:UpdateService'),
  LIST_CANDIDATE_SERVICES: genericAPIActionTypes('Solve:ListCandidateServices'),
  FETCH_CANDIDATE_SERVICE: genericAPIActionTypes('Solve:FetchCandidateService'),
  LIST_INVITATIONS: genericAPIActionTypes('Solve:ListInvitations'),
  LIST_STAFF_INVITATIONS: genericAPIActionTypes('Solve:ListFilteredInvitations'),
  // SET_STAFF_INVITATIONS_FILTERS: 'Solve:InvitationsFilters:SetFilters',
  // SET_STAFF_INVITATIONS_SEARCH: 'Solve:InvitationsSearch:SetSearch',
  CREATE_INVITATION: genericAPIActionTypes('Solve:CreateInvitation'),
  RESEND_INVITATION: genericAPIActionTypes('Solve:ResendInvitation'),
  BULK_CREATE_INVITATION: genericAPIActionTypes('Solve:BulkCreateInvitation'),
  FETCH_INVITATION: genericAPIActionTypes('Solve:CreateInvitation'),
  ACCEPT_INVITATION: genericAPIActionTypes('Solve:AcceptInvitation'),
  SET_CANDIDATE_JOB_LISTINGS_ZIPCODE: 'Solve:CJL:SetZipcode',
  SET_CANDIDATE_JOB_LISTINGS_DISTANCE: 'Solve:CJL:SetDistance',
  SET_CANDIDATE_JOB_LISTINGS_FILTERS: 'Solve:CJL:SetFilters',
  SET_CANDIDATE_JOB_LISTINGS_SEARCH: 'Solve:CJL:SetSearch',
  SET_CANDIDATE_JOB_LISTINGS_PAGE: 'Solve:CJL:SetPage',
  SET_INDEED_JOB_LISTINGS_FILTERS: 'Solve:ICJL:SetFilters',
  LIST_RECOMMENDED_POSTINGS_FOR_CANDIDATE: genericAPIActionTypes('Solve:RecommendedListingsForCandidate'),
  LIST_FILTERED_JOB_POSTINGS_FOR_CANDIDATE: genericAPIActionTypes('Solve:CJL:ListFiltered'),
  FETCH_SAVED_INDEED_JOB_POSTINGS_BY_IDS: genericAPIActionTypes('Solve:CJL:FetchSavedIndeedJobsByIds'),
  FETCH_SAVED_JOB_POSTINGS_BY_IDS: genericAPIActionTypes('Solve:CJL:FetchSavedJobsByIds'),
  LIST_FILTERED_INDEED_JOB_POSTINGS_FOR_CANDIDATE: genericAPIActionTypes('Solve:ICJL:ListFiltered'),
  REMOVE_FILTERED_INDEED_JOB_POSTINGS_FOR_CANDIDATE: genericAPIActionTypes('Solve:ICJL:ListRemoved'),
  LIST_FILTERED_EVENTS_FOR_CANDIDATE: genericAPIActionTypes('Solve:EventsForCandidate:ListFiltered'),
  LIST_FILTERED_PROGRAMS_FOR_CANDIDATE: genericAPIActionTypes('Solve:ProgramsForCandidate:ListFiltered'),
  LIST_FILTERED_JOB_APPLICATIONS_FOR_CANDIDATE: genericAPIActionTypes('Solve:CJA:ListFiltered'),
  LIST_FILTERED_INDEED_JOB_APPLICATIONS_FOR_CANDIDATE: genericAPIActionTypes('Solve:ICJA:ListFiltered'),
  LIST_FILTERED_CANDIDATE_PROGRAMS_FOR_CANDIDATE: genericAPIActionTypes('Solve:CandidateProgramsForCandidate:ListFiltered'),
  LIST_FILTERED_CANDIDATE_EVENTS_FOR_CANDIDATE: genericAPIActionTypes('Solve:CandidateEventsForCandidate:ListFiltered'),
  LIST_FILTERED_SERVICES_FOR_CANDIDATE: genericAPIActionTypes('Solve:ServicesForCandidate:ListFiltered'),
  CREATE_EXTERNAL_CANDIDATE_SERVICE: genericAPIActionTypes('Solve:ExternalServicesForCandidate:Apply'),
  CANDIDATE_APPLY_TO_EXTERNAL_SERVICE: genericAPIActionTypes('Solve:ExternalServicesForCandidate:Create'),
  TRANSITION_EXTERNAL_CANDIDATE_SERVICE_C: genericAPIActionTypes('Solve:ExternalServicesForCandidate:TransitionC'),
  FETCH_EXTERNAL_CANDIDATE_SERVICES: genericAPIActionTypes('Solve:ExternalServicesForCandidate:Show'),
  FETCH_EXTERNAL_CANDIDATE_SERVICES_SERVICES: genericAPIActionTypes('Solve:ExternalServicesForCandidate:List'),
  LIST_FILTERED_EXTERNAL_SERVICES_FOR_CANDIDATE: genericAPIActionTypes('Solve:ExternalServicesForCandidate:ListFiltered'),
  FETCH_EXTERNAL_SERVICE: genericAPIActionTypes('Solve:ServicesForCandidate:FetchExternalService'),
  LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE: genericAPIActionTypes('Solve:CandidateServicesForCandidate:ListFiltered'),
  LIST_FILTERED_CANDIDATE_PROGRAMS: genericAPIActionTypes('Solve:FilteredCandidatePrograms:ListFiltered'),
  LIST_FILTERED_CANDIDATE_EVENTS: genericAPIActionTypes('Solve:FilteredCandidateEvents:ListFiltered'),
  SET_STAFF_JOB_LISTINGS_ZIP_CODE: 'Solve:SJL:SetZipCode',
  SET_STAFF_JOB_LISTINGS_FILTERS: 'Solve:SJL:SetFilters',
  SET_STAFF_JOB_LISTINGS_SEARCH: 'Solve:SJL:SetSearch',
  SET_STAFF_EP_CANDIDATES_FILTERS: 'Solve:EPC:SetFilters',
  SET_STAFF_EP_CANDIDATES_ZIP_CODE: 'Solve:EPC:SetZipCode',
  SET_STAFF_EP_CANDIDATES_SEARCH: 'Solve:EPC:SetSearch',
  SET_STAFF_EP_CANDIDATES_PAGE: 'Solve:EPC:SetPage',
  SET_FILTERED_CANDIDATES_APPLICATIONS_FILTERS: 'Solve:Staff:CA:SetFilters',
  SET_FILTERED_CANDIDATES_APPLICATIONS_SEARCH: 'Solve:Staff:CA:SetSearch',
  SET_STAFF_CANDIDATES_FILTERS: 'Solve:Staff:Candidates:SetFilters',
  SET_STAFF_CANDIDATES_SEARCH: 'Solve:Staff:Candidates:SetSearch',
  SET_STAFF_PROGRAM_CANDIDATES_FILTERS: 'Solve:Staff:ProgramCandidates:SetFilters',
  SET_STAFF_PROGRAM_CANDIDATES_SEARCH: 'Solve:Staff:ProgramCandidates:SetSearch',
  SET_STAFF_EVENT_CANDIDATES_FILTERS: 'Solve:Staff:EventCandidates:SetFilters',
  SET_STAFF_EVENT_CANDIDATES_SEARCH: 'Solve:Staff:EventCandidates:SetSearch',
  SET_STAFF_JOB_CANDIDATES_FILTERS: 'Solve:Staff:JobCandidates:SetFilters',
  SET_STAFF_JOB_CANDIDATES_SEARCH: 'Solve:Staff:JobCandidates:SetSearch',
  LIST_FILTERED_CANDIDATES_APPLICATIONS: genericAPIActionTypes('Solve:CandidateApplications:ListFiltered'),
  LIST_FILTERED_CANDIDATES_EXTERNAL_APPLICATIONS: genericAPIActionTypes('Solve:CandidateExternalApplications:ListFiltered'),
  LIST_FILTERED_CANDIDATES_FOR_STAFF: genericAPIActionTypes('Solve:CandidatesForStaff:ListFiltered'),
  LIST_FILTERED_PROGRAM_CANDIDATES_FOR_STAFF: genericAPIActionTypes('Solve:ProgramCandidatesForStaff:ListFiltered'),
  LIST_FILTERED_EVENT_CANDIDATES_FOR_STAFF: genericAPIActionTypes('Solve:EventCandidatesForStaff:ListFiltered'),
  LIST_FILTERED_JOB_CANDIDATES_FOR_STAFF: genericAPIActionTypes('Solve:JobCandidatesForStaff:ListFiltered'),
  LIST_FILTERED_PROGRAMS_FOR_STAFF: genericAPIActionTypes('Solve:ProgramsForStaff:ListFiltered'),
  LIST_FILTERED_EVENTS_FOR_STAFF: genericAPIActionTypes('Solve:EventsForStaff:ListFiltered'),
  LIST_FILTERED_SERVICES_FOR_STAFF: genericAPIActionTypes('Solve:ServicesForStaff:ListFiltered'),
  LIST_ALL_PROGRAMS_FOR_STAFF: genericAPIActionTypes('Solve:ProgramsForStaff:ListAll'),
  LIST_ALL_EVENTS_FOR_STAFF: genericAPIActionTypes('Solve:EventsForStaff:ListAll'),
  LIST_ALL_SERVICES_FOR_STAFF: genericAPIActionTypes('Solve:ServicesForStaff:ListAll'),
  LIST_FILTERED_AB_SERVICES_FOR_STAFF: genericAPIActionTypes('Solve:ABServicesForStaff:ListFiltered'),
  LIST_FILTERED_JOB_POSTINGS_FOR_STAFF: genericAPIActionTypes('Solve:JobPostingsForStaff:ListFiltered'),
  LIST_FILTERED_INDEED_JOB_POSTINGS_FOR_STAFF: genericAPIActionTypes('Solve:IndeedJobPostingsForStaff:ListFiltered'),
  CLEAR_FILTERED_PROGRAMS_AND_EVENTS: 'Solve:ClearFilteredProgramsAndEvents',
  REMOVE_FILTERED_INDEED_JOB_POSTINGS_FOR_STAFF: genericAPIActionTypes('Solve:IndeedJobPostingsForStaff:RemoveFiltered'),
  LIST_FILTERED_JOB_POSTINGS_FOR_STAFF_ORG: genericAPIActionTypes('Solve:JobPostingsForStaffOrg:ListFiltered'),
  LIST_FILTERED_CANDIDATES_PROGRAMS: genericAPIActionTypes('Solve:StaffCandidatePrograms:ListFiltered'),
  LIST_FILTERED_CANDIDATES_EVENTS: genericAPIActionTypes('Solve:StaffCandidateEvents:ListFiltered'),
  LIST_EVENTS: genericAPIActionTypes('Solve:ListEvents'),
  FETCH_EVENT: genericAPIActionTypes('Solve:FetchEvent'),
  CREATE_EVENT: genericAPIActionTypes('Solve:CreateEvent'),
  UPDATE_EVENT: genericAPIActionTypes('Solve:UpdateEvent'),
  CREATE_CANDIDATE: genericAPIActionTypes('Solve:CreateCandidate'),
  FETCH_CANDIDATE: genericAPIActionTypes('Solve:FetchCandidate'),
  UPDATE_CANDIDATE: genericAPIActionTypes('Solve:UpdateCandidate'),
  CREATE_STAFF_MEMBER: genericAPIActionTypes('Solve:CreateStaffMember'),
  FETCH_STAFF_MEMBER: genericAPIActionTypes('Solve:FetchStaffMember'),
  UPDATE_STAFF_MEMBER: genericAPIActionTypes('Solve:UpdateStaffMember'),
  DELETE_STAFF_MEMBER: genericAPIActionTypes('Solve:DeleteStaffMember'),
  LIST_STAFF: genericAPIActionTypes('Solve:ListStaff'),
  LIST_PEER_STAFF: genericAPIActionTypes('Solve:ListPeerStaff'),
  LIST_MESSAGES: genericAPIActionTypes('Solve:ListMessages'),
  FETCH_MESSAGE: genericAPIActionTypes('Solve:FetchMessage'),
  UPDATE_MESSAGE: genericAPIActionTypes('Solve:UpdateMessage'),
  CREATE_MESSAGE: genericAPIActionTypes('Solve:CreateMessage'),
  LIST_NOTES: genericAPIActionTypes('Solve:ListNotes'),
  FETCH_NOTE: genericAPIActionTypes('Solve:FetchNote'),
  UPDATE_NOTE: genericAPIActionTypes('Solve:UpdateNote'),
  CREATE_NOTE: genericAPIActionTypes('Solve:CreateNote'),
  SEND_ORG_INVITES: genericAPIActionTypes('Solve:SendOrgInvites'),
  LIST_NOTIFICATIONS: genericAPIActionTypes('Solve:ListNotifications'),
  FETCH_NOTIFICATION: genericAPIActionTypes('Solve:FetchNotification'),
  DISMISS_NOTIFICATION: genericAPIActionTypes('Solve:DismissNotification'),
  SET_NOTIFICATION_AS_VIEWED: genericAPIActionTypes('Solve:setNotificationAsViewed'),
  LIST_PATHWAYS: genericAPIActionTypes('Solve:ListPathways'),
  FETCH_PATHWAY: genericAPIActionTypes('Solve:FetchPathway'),
  LIST_PATHWAY_INSTANCES: genericAPIActionTypes('Solve:ListPathwayInstances'),
  FETCH_PATHWAY_INSTANCE: genericAPIActionTypes('Solve:FetchPathwayInstance'),
  CREATE_PATHWAY_INSTANCE: genericAPIActionTypes('Solve:CreatePathwayInstance'),
  UPDATE_PATHWAY_INSTANCE: genericAPIActionTypes('Solve:UpdatePathwayInstance'),
  TRANSITION_PATHWAY_STEP_STAFF_STATE: genericAPIActionTypes('Solve:TransitionPathwayStepStaffState'),
  UPDATE_PATHWAY_INSTANCE_STEPS: genericAPIActionTypes('Solve:UpdatePathwayInstanceSteps'),
  TRANSITION_PATHWAY_STEP_CANDIDATE_STATE: genericAPIActionTypes('Solve:TransitionPathwayStepCandidateState'),
  TRANSITION_CANDIDATE_PATHWAY_STAFF_STATE: genericAPIActionTypes('Solve:TransitionCandidatePathwayStaffState'),
  TRANSITION_CANDIDATE_PATHWAYS_STAFF_STATE: genericAPIActionTypes('Solve:TransitionCandidatePathwaysStaffState'),
  TRANSITION_CANDIDATE_PATHWAY_CANDIDATE_STATE: genericAPIActionTypes('Solve:TransitionCandidatePathwayCandidateState'),
  UPDATE_PATHWAY_INSTANCE_STEP: genericAPIActionTypes('Solve:UpdatePathwayInstanceStep'),
};

global.noDups = fromJS(ACTIONS);

// User confirm email
export const confirmEmail = (user) => genericAPIActionCreator(() => api.confirmEmail(user.get('authToken')), ACTIONS.CONFIRM_EMAIL, 'Unable to confirm email');
export const reconfirmEmail = (user) =>
  genericAPIActionCreator(() => api.reconfirmEmail(user.get('authToken')), ACTIONS.RECONFIRM_EMAIL, 'Unable to reconfirm email');

// Candidate
export const createCandidate = (user, candidate) =>
  genericAPIActionCreator(() => api.createCandidate(user, candidate), ACTIONS.CREATE_CANDIDATE, 'Unable to create candidate');
export const fetchCandidate = (user, candidateId) =>
  genericAPIActionCreator(() => api.fetchCandidate(user, candidateId), ACTIONS.FETCH_CANDIDATE, 'Unable to fetch candidate');
export const updateCandidate = (user, candidateId, candidate) =>
  genericAPIActionCreator(() => api.updateCandidate(user, candidateId, candidate), ACTIONS.UPDATE_CANDIDATE, 'Unable to update candidate');

// Job Candidate
export const listAllCandidates = (user, props) =>
  bufferedPaginatedAPIAction((page) => api.listCandidates(user, props, { page }), ACTIONS.LIST_ALL_CANDIDATES, 'Unable to list job candidates');

// Candidate Organizations
export const listCandidateOrganizations = (user, candidateId) =>
  bufferedPaginatedAPIAction(
    (page) => api.listCandidateOrganizations(user, candidateId, { page }),
    ACTIONS.LIST_CANDIDATE_ORGANIZATIONS,
    'Unable to list candidate organizations',
  );

// Candidate Application
export const listCandidateApplicationsForCandidate = (user, props) =>
  paginatedAPIAction(
    (page) => api.listCandidateApplicationsForCandidate(user, { candidateId: user.get('candidateId'), page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_CANDIDATE_APPLICATIONS_FOR_CANDIDATE,
    'Unable to list candidate applications',
  );
export const createCandidateApplication = (user, jobPostingId) =>
  genericAPIActionCreator(() => api.createCandidateApplication(user, jobPostingId), ACTIONS.CREATE_CANDIDATE_APPLICATION);

// External candidate application
export const createExternalCandidateApplication = (user, jobPostingId, jobTitle) =>
  genericAPIActionCreator(() => api.createExternalCandidateApplication(user, jobPostingId, jobTitle), ACTIONS.CREATE_EXTERNAL_CANDIDATE_APPLICATION);

export const transitionExternalCandidateApplicationByCandidate = (user, candidateApplicationId, newState) =>
  genericAPIActionCreator(
    () => api.transitionExternalCandidateApplicationByCandidate(user, candidateApplicationId, newState),
    ACTIONS.TRANSITION_EXTERNAL_CANDIDATE_APPLICATIONS_FOR_CANDIDATE,
  );

export const listExternalCandidateApplicationsForCandidate = (user, candidateId, props) =>
  paginatedAPIAction(
    (page) => api.listExternalCandidateApplicationsForCandidate(user, { candidateId, page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_EXTERNAL_CANDIDATE_APPLICATIONS_FOR_CANDIDATE,
    'Unable to list candidate applications',
  );

export const fetchCandidateApplication = (user, candidateApplicationId) =>
  genericAPIActionCreator(() => api.fetchCandidateApplication(user, candidateApplicationId), ACTIONS.FETCH_CANDIDATE_APPLICATION);
export const transitionCandidateApplicationByCandidate = (user, candidateApplicationId, newState) =>
  genericAPIActionCreator(
    () => api.transitionCandidateApplicationByCandidate(user, candidateApplicationId, newState),
    ACTIONS.TRANSITION_CANDIDATE_APPLICATION_C,
  );
export const transitionCandidateApplicationByStaff = (user, candidateApplicationId, newState) =>
  genericAPIActionCreator(() => api.transitionCandidateApplicationByStaff(user, candidateApplicationId, newState), ACTIONS.TRANSITION_CANDIDATE_APPLICATION_S);

// Candidate Event
export const fetchCandidateEvent = (user, candidateEventId) =>
  genericAPIActionCreator(() => api.fetchCandidateEvent(user, candidateEventId), ACTIONS.FETCH_CANDIDATE_EVENT, 'Unable to fetch candidate event');
export const listCandidatesForEvent = (user, eventId) =>
  genericAPIActionCreator(() => api.listCandidatesForEvent(user, eventId), ACTIONS.LIST_CANDIDATES_FOR_EVENT, 'Unable to list candidates for an event');
export const createCandidateEvent = (user, eventId) => genericAPIActionCreator(() => api.createCandidateEvent(user, eventId), ACTIONS.CREATE_CANDIDATE_EVENT);
export const transitionCandidateEventByStaff = (user, candidateEventId, newState) =>
  genericAPIActionCreator(
    () => api.transitionCandidateEventByStaff(user, candidateEventId, newState),
    ACTIONS.TRANSITION_CANDIDATE_EVENT_S,
    'Unable to transition candidate',
  );
export const transitionCandidateEventByCandidate = (user, candidateEventId, newState) =>
  genericAPIActionCreator(() => api.transitionCandidateEventByCandidate(user, candidateEventId, newState), ACTIONS.TRANSITION_CANDIDATE_EVENT_C);

// Candidate Program
export const fetchCandidateProgram = (user, candidateProgramId) =>
  genericAPIActionCreator(() => api.fetchCandidateProgram(user, candidateProgramId), ACTIONS.FETCH_CANDIDATE_PROGRAM, 'Unable to fetch candidate program');
export const listCandidatesForProgram = (user, programId) =>
  genericAPIActionCreator(() => api.listCandidatesForProgram(user, programId), ACTIONS.LIST_CANDIDATES_FOR_PROGRAM, 'Unable to list candidates for a program');
export const createCandidateProgram = (user, programId) =>
  genericAPIActionCreator(() => api.createCandidateProgram(user, programId), ACTIONS.CREATE_CANDIDATE_PROGRAM);
export const transitionCandidateProgramByStaff = (user, candidateProgramId, newState) =>
  genericAPIActionCreator(
    () => api.transitionCandidateProgramByStaff(user, candidateProgramId, newState),
    ACTIONS.TRANSITION_CANDIDATE_PROGRAM_S,
    'Unable to transition candidate',
  );
export const transitionCandidateProgramByCandidate = (user, candidateProgramId, newState) =>
  genericAPIActionCreator(() => api.transitionCandidateProgramByCandidate(user, candidateProgramId, newState), ACTIONS.TRANSITION_CANDIDATE_PROGRAM_C);

export const transitionCandidateServiceByStaff = (user, candidateServiceId, newState) =>
  genericAPIActionCreator(() => api.transitionCandidateServiceByStaff(user, candidateServiceId, newState), ACTIONS.TRANSITION_CANDIDATE_SERVICE_S);

export const transitionCandidateServiceByCandidate = (user, candidateServiceId, newState) =>
  genericAPIActionCreator(() => api.transitionCandidateServiceByCandidate(user, candidateServiceId, newState), ACTIONS.TRANSITION_CANDIDATE_SERVICE_C);

// Candidate Surveys
export const listCandidateSurveys = (user, candidateId) =>
  bufferedPaginatedAPIAction(
    (page) => api.listCandidateSurveys(user, candidateId, { page }),
    ACTIONS.LIST_CANDIDATE_SURVEYS,
    'Unable to list candidate surveys',
  );
export const startSurvey = (user, id) => genericAPIActionCreator(() => api.startSurvey(user, id), ACTIONS.START_SURVEY);
export const updateCandidateSurvey = (user, id, props) => genericAPIActionCreator(() => api.updateCandidateSurvey(user, id, props), ACTIONS.UPDATE_SURVEY);

export const listStaffCandidates = (props) =>
  genericAPIActionCreator(() => api.listStaffCandidates(props), ACTIONS.LIST_STAFF_CANDIDATES, 'Unable to list staff candidates');

// Job Postings
export const listJobPostings = (user, props) =>
  paginatedAPIAction((page) => api.listJobPostings(user, { page, ...(props?.toJS() || {}) }), ACTIONS.LIST_JOB_POSTINGS, 'Unable to list job postings');
export const fetchJobPosting = (user, id) => genericAPIActionCreator(() => api.fetchJobPosting(user, id), ACTIONS.FETCH_JOB_POSTING);

export const fetchIndeedJobPosting = (user, id) => genericAPIActionCreator(() => api.fetchIndeedJobPosting(user, id), ACTIONS.FETCH_INDEED_JOB_POSTING);
export const fetchSavedIndeedJobPostingByIds = (user, ids) =>
  genericAPIActionCreator(
    () => api.fetchIndeedJobPostingByIds(user, ids),
    ACTIONS.FETCH_SAVED_INDEED_JOB_POSTINGS_BY_IDS,
    'Unable to fetch indeed jobs posting by ids.',
  );
export const fetchSavedJobPostingsByIds = (user, ids) =>
  genericAPIActionCreator(
    () => api.fetchJobPostingsByIds(user, ids),
    ACTIONS.FETCH_SAVED_JOB_POSTINGS_BY_IDS,
    'Unable to fetch saved jobs posting by ids.',
  );
export const createJobPosting = (user, organizationId, jobPosting) =>
  genericAPIActionCreator(() => api.createJobPosting(user, organizationId, jobPosting), ACTIONS.CREATE_JOB_POSTING, 'Unable to create job posting');

export const updateJobPosting = (user, jobPostingId, jobPosting) =>
  genericAPIActionCreator(() => api.updateJobPosting(user, jobPostingId, jobPosting), ACTIONS.UPDATE_JOB_POSTING, 'Unable to update job posting');

export const draftJobPosting = (user, jobPostingId) =>
  genericAPIActionCreator(() => api.draftJobPosting(user, jobPostingId), ACTIONS.UPDATE_JOB_POSTING, 'Unable to save job posting draft');

export const publishJobPosting = (user, jobPostingId) =>
  genericAPIActionCreator(() => api.publishJobPosting(user, jobPostingId), ACTIONS.UPDATE_JOB_POSTING, 'Unable to publish job posting');

export const closeJobPosting = (user, jobPostingId) =>
  genericAPIActionCreator(() => api.closeJobPosting(user, jobPostingId), ACTIONS.UPDATE_JOB_POSTING, 'Unable to close job posting');

export const cancelJobPosting = (user, jobPostingId) =>
  genericAPIActionCreator(() => api.cancelJobPosting(user, jobPostingId), ACTIONS.UPDATE_JOB_POSTING, 'Unable to cancel job posting');

export const activateJobPosting = (user, jobPostingId) =>
  genericAPIActionCreator(() => api.activateJobPosting(user, jobPostingId), ACTIONS.UPDATE_JOB_POSTING, 'Unable to activate job posting');

export const listApplicationsToJobPosting = (user, jobPostingId) =>
  genericAPIActionCreator(
    () => api.listApplicationsToJobPosting(user, jobPostingId),
    ACTIONS.LIST_APPLICATIONS_TO_JOB_POSTING,
    'Unable to list applications to job posting',
  );

export const fetchJobPostingParticipantsReport = (user, jobPostingId) =>
  genericAPIActionCreator(
    () => api.fetchJobPostingParticipantsReport(user, jobPostingId),
    ACTIONS.FETCH_JOB_POSTING_REPORT,
    'Unable to create job posting report',
  );

// Organization
export const listOrganizations = (user) =>
  bufferedPaginatedAPIAction((page) => api.listOrganizations(user, { page }), ACTIONS.LIST_ORGANIZATIONS, 'Unable to list organizations');
export const listFilteredOrganizations = (user, props) =>
  genericAPIActionCreator(() => api.listFilteredOrganizations(user, props), ACTIONS.LIST_FILTERED_ORGANIZATIONS, 'Unable to filter organizations');

export const listChildOrganizations = (user, parentOrganizationId) =>
  bufferedPaginatedAPIAction(
    (page) => api.listChildOrganizations(user, parentOrganizationId, { page }),
    ACTIONS.LIST_CHILD_ORGANIZATIONS,
    'Unable to list child organizations',
    { parentOrganizationId },
  );

export const fetchOrganization = (user, id) =>
  genericAPIActionCreator(() => api.fetchOrganization(user, id), ACTIONS.FETCH_ORGANIZATION, 'Unable to fetch organization');

export const createOrganization = (user, org) =>
  genericAPIActionCreator(() => api.createOrganization(user, org), ACTIONS.CREATE_ORGANIZATION, 'Unable to create organization');

export const updateOrganization = (user, organizationId, props) =>
  genericAPIActionCreator(() => api.updateOrganization(user, organizationId, props), ACTIONS.UPDATE_ORGANIZATION);

export const fetchOrganizationCandidatesReport = (user, organizationId) =>
  genericAPIActionCreator(
    () => api.fetchOrganizationCandidatesReport(user, organizationId),
    ACTIONS.FETCH_ORGANIZATION_CANDIDATES_REPORT,
    'Unable to create organization',
  );

export const fetchOrganizationNewCandidatesReport = (user, organizationId) =>
  genericAPIActionCreator(
    () => api.fetchOrganizationNewCandidatesReport(user, organizationId),
    ACTIONS.FETCH_ORGANIZATION_NEW_CANDIDATES_REPORT,
    'Unable to create organization',
  );

export const fetchOrganizationCareerFairReport = (user, organizationId) =>
  genericAPIActionCreator(
    () => api.fetchOrganizationCareerFairReport(user, organizationId),
    ACTIONS.FETCH_ORGANIZATION_CAREER_FAIR_REPORT,
    'Unable to create organization',
  );

export const fetchRecidivismReport = (user, organizationId) =>
  genericAPIActionCreator(() => api.fetchRecidivismReport(user, organizationId), ACTIONS.FETCH_RECIDIVISM_REPORT, 'Unable to fetch report');

// Resumes
export const listResumes = (user, candidateId) =>
  genericAPIActionCreator(() => api.listResumes(user, candidateId), ACTIONS.LIST_RESUMES, 'Unable to list resumes');
export const fetchResume = (user, id) => genericAPIActionCreator(() => api.fetchResume(user, id), ACTIONS.FETCH_RESUME);
export const createResume = (user, candidateId, props) => genericAPIActionCreator(() => api.createResume(user, candidateId, props), ACTIONS.CREATE_RESUME);
export const updateResume = (user, id, props) => genericAPIActionCreator(() => api.updateResume(user, id, props), ACTIONS.UPDATE_RESUME);
export const approveResumeChanges = (user, id) => genericAPIActionCreator(() => api.approveResumeChanges(user, id), ACTIONS.UPDATE_RESUME);
export const declineResumeChanges = (user, id) => genericAPIActionCreator(() => api.declineResumeChanges(user, id), ACTIONS.UPDATE_RESUME);
export const uploadResume = (user, candidateId, props) => genericAPIActionCreator(() => api.uploadResume(user, candidateId, props), ACTIONS.UPLOAD_RESUME);

// Inmate Info
export const fetchInmate = (user, id) => genericAPIActionCreator(() => api.fetchInmate(user, id), ACTIONS.FETCH_INMATE);
export const createInmate = (user, candidateId, props) => genericAPIActionCreator(() => api.createInmate(user, candidateId, props), ACTIONS.CREATE_INMATE);
export const updateInmate = (user, id, props) => genericAPIActionCreator(() => api.updateInmate(user, id, props), ACTIONS.UPDATE_INMATE);

// Challenges
export const listChallenges = (user, candidateId) =>
  genericAPIActionCreator(() => api.listChallenges(user, candidateId), ACTIONS.LIST_CHALLENGES, 'Unable to list challenges');
export const fetchChallenge = (user, id) => genericAPIActionCreator(() => api.fetchChallenge(user, id), ACTIONS.FETCH_CHALLENGE);
export const staffChallengeTransition = (user, challengeId) =>
  genericAPIActionCreator(() => api.staffChallengeTransition(user, challengeId), ACTIONS.UPDATE_CHALLENGE);
export const candidateChallengeTransition = (user, challengeId) =>
  genericAPIActionCreator(() => api.candidateChallengeTransition(user, challengeId), ACTIONS.UPDATE_CHALLENGE);

// Surveys
export const listSurveys = (user, organizationId) =>
  genericAPIActionCreator(() => api.listSurveys(user, organizationId), ACTIONS.LIST_SURVEYS, 'Unable to list surveys');

export const listGlobalSurveys = (user) => genericAPIActionCreator(() => api.listGlobalSurveys(user), ACTIONS.LIST_GLOBAL_SURVEYS);
export const checkListGlobalSurveysBackoff = () => ({
  type: ACTIONS.CHECK_LIST_GLOBAL_SURVEYS_BACKOFF,
});

// Programs
export const listPrograms = (user, props) =>
  paginatedAPIAction((page) => api.listPrograms(user, { page, ...props }), ACTIONS.LIST_PROGRAMS, 'Unable to list programs');

export const fetchProgram = (user, programId) => genericAPIActionCreator(() => api.fetchProgram(user, programId), ACTIONS.FETCH_PROGRAM);

export const createProgram = (user, organizationId, program) =>
  genericAPIActionCreator(() => api.createProgram(user, organizationId, program), ACTIONS.CREATE_PROGRAM, 'Unable to create program');

export const updateProgram = (user, programId, program) =>
  genericAPIActionCreator(() => api.updateProgram(user, programId, program), ACTIONS.UPDATE_PROGRAM, 'Unable to update program');

export const draftProgram = (user, programId) =>
  genericAPIActionCreator(() => api.draftProgram(user, programId), ACTIONS.UPDATE_PROGRAM, 'Unable to save program draft');

export const publishProgram = (user, programId) =>
  genericAPIActionCreator(() => api.publishProgram(user, programId), ACTIONS.UPDATE_PROGRAM, 'Unable to publish program');

export const closeProgram = (user, programId) =>
  genericAPIActionCreator(() => api.closeProgram(user, programId), ACTIONS.UPDATE_PROGRAM, 'Unable to close program');

export const cancelProgram = (user, programId) =>
  genericAPIActionCreator(() => api.cancelProgram(user, programId), ACTIONS.UPDATE_PROGRAM, 'Unable to cancel program');

export const activateProgram = (user, programId) =>
  genericAPIActionCreator(() => api.activateProgram(user, programId), ACTIONS.UPDATE_PROGRAM, 'Unable to activate program');

// Supportive Services
export const listSupportiveServices = (user, props) =>
  paginatedAPIAction((page) => api.listSupportiveServices(user, { page, ...props }), ACTIONS.LIST_SERVICES, 'Unable to list supportive services');
export const fetchSupportiveService = (user, supportiveServiceId) =>
  genericAPIActionCreator(() => api.fetchSupportiveService(user, supportiveServiceId), ACTIONS.FETCH_SERVICE, 'Unable to get supportive service');
export const fetchAbService = (user, supportiveServiceId) =>
  genericAPIActionCreator(() => api.fetchAbService(user, supportiveServiceId), ACTIONS.FETCH_SERVICE, 'Unable to get supportive service');
// Invitations
export const listInvitations = (user) =>
  bufferedPaginatedAPIAction((page) => api.listInvitations(user, { page }), ACTIONS.LIST_INVITATIONS, 'Unable to list invitations');

export const listFilteredInvitations = (user, { page, filters }) =>
  genericAPIActionCreator(() => api.listInvitations(user, { page, ...(filters?.toJS() || {}) }), ACTIONS.LIST_STAFF_INVITATIONS, 'Unable to list invitations');

// export const listStaffInvitations = (user, referencedType, referencedId, query) =>
//   paginatedAPIAction(
//     (page) => api.listStaffInvitations(user, referencedType, referencedId, { page, ...query.toJS() }),
//     ACTIONS.LIST_STAFF_INVITATIONS,
//     'Unable to list invitations',
//   );
export const createInvitation = (user, invitation) =>
  genericAPIActionCreator(() => api.createInvitation(user, invitation), ACTIONS.CREATE_INVITATION, 'Unable to create invitation');
export const resendInvitation = (user, invitationId) =>
  genericAPIActionCreator(() => api.resendInvitation(user, invitationId), ACTIONS.RESEND_INVITATION, 'Unable to resend invitation');
export const bulkCreateInvitations = (user, referencedType, referencedId, invitationsFile) =>
  genericAPIActionCreator(
    () => api.bulkCreateInvitations(user, referencedType, referencedId, invitationsFile),
    ACTIONS.CREATE_INVITATION,
    'Unable to create invitation',
  );
export const acceptInvitations = (user, invitationToken) =>
  genericAPIActionCreator(() => api.listInvitations(user, invitationToken), ACTIONS.ACCEPT_INVITATION, 'Unable to accept invitation');
export const fetchInvitation = (user, invitationToken) =>
  genericAPIActionCreator(() => api.listInvitations(user, invitationToken), ACTIONS.FETCH_INVITATION, 'Unable to fetch invitation');

// export const setStaffInvitationsFilters = (filters) => ({
//   type: ACTIONS.SET_STAFF_INVITATIONS_FILTERS,
//   filters,
// });

// export const setStaffInvitationsSearch = (search) => ({
//   type: ACTIONS.SET_STAFF_INVITATIONS_SEARCH,
//   search,
// });

// CJL
export const setCandidateJobListingsZipcode = (zipCode) => ({
  type: ACTIONS.SET_CANDIDATE_JOB_LISTINGS_ZIPCODE,
  zipCode,
});

export const setCandidateJobListingsDistance = (distance) => ({
  type: ACTIONS.SET_CANDIDATE_JOB_LISTINGS_DISTANCE,
  distance,
});

export const setCandidateJobListingsFilters = (filters) => ({
  type: ACTIONS.SET_CANDIDATE_JOB_LISTINGS_FILTERS,
  filters,
});

export const setCandidateJobListingsSearch = (search) => ({
  type: ACTIONS.SET_CANDIDATE_JOB_LISTINGS_SEARCH,
  search,
});

export const setCandidateJobListingsPage = (page) => ({
  type: ACTIONS.SET_CANDIDATE_JOB_LISTINGS_PAGE,
  page,
});

export const setCandidateProgramsSearch = (search) => ({
  type: ACTIONS.SET_CANDIDATE_PROGRAMS_SEARCH,
  search,
});

export const setCandidateProgramsFilters = (filters) => ({
  type: ACTIONS.SET_CANDIDATE_PROGRAMS_FILTERS,
  filters,
});

export const setCandidateProgramsPage = (page) => ({
  type: ACTIONS.SET_CANDIDATE_PROGRAMS_PAGE,
  page,
});

export const setCandidateProgramsSortBy = (sortBy) => ({
  type: ACTIONS.SET_CANDIDATE_PROGRAMS_SORT_BY,
  sortBy,
});

export const setCandidateProgramsDistance = (distance) => ({
  type: ACTIONS.SET_CANDIDATE_PROGRAMS_DISTANCE,
  distance,
});

export const setCandidateProgramsZipCode = (zipCode) => ({
  type: ACTIONS.SET_CANDIDATE_PROGRAMS_ZIP_CODE,
  zipCode,
});

const recommendedQuery = (user) => ({
  distance: '15-miles',
  zipCode: user.zip,
  _sort: 'createdAt',
  _order: 'DESC',
});

export const listRecommendedPostingsForCandidate = (user) =>
  genericAPIActionCreator(
    () => api.listJobPostings(user, { page: 1, size: 3, ...(recommendedQuery(user) || {}) }),
    ACTIONS.LIST_RECOMMENDED_POSTINGS_FOR_CANDIDATE,
    'Unable to list recommended job postings for candidate',
  );

export const listFilteredJobPostingsForCandidate = (user, { page, filters }) =>
  genericAPIActionCreator(
    () => api.listJobPostings(user, { page, ...(filters?.toJS() || {}) }),

    ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_CANDIDATE,
    'Unable to list job postings for candidate',
  );

export const listFilteredIndeedJobPostingsForCandidate = (user, { page, filters }) =>
  genericAPIActionCreator(
    () => api.listIndeedJobPostings(user, { page, ...(filters?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_INDEED_JOB_POSTINGS_FOR_CANDIDATE,
    'Unable to list indeed job postings for candidate',
  );

export const removeFilteredIndeedJobPostingsForCandidate = (user, { page, filters }) =>
  genericAPIActionCreator(
    () => api.listIndeedJobPostings(user, { page, ...(filters?.toJS() || {}) }),
    ACTIONS.REMOVE_FILTERED_INDEED_JOB_POSTINGS_FOR_CANDIDATE,
    'Unable to list indeed job postings for candidate',
  );

export const listFilteredEventsForCandidate = (user, props) =>
  paginatedAPIAction(
    (page) => api.listEvents(user, { page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_EVENTS_FOR_CANDIDATE,
    'Unable to list events for candidate',
  );

export const listFilteredProgramsForCandidate = (user, props) =>
  paginatedAPIAction(
    (page) => api.listPrograms(user, { page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_PROGRAMS_FOR_CANDIDATE,
    'Unable to list programs for candidate',
  );

export const listFilteredServicesForCandidate = (user, props) =>
  paginatedAPIAction(
    (page) => api.listSupportiveServices(user, { page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_SERVICES_FOR_CANDIDATE,
    'Unable to list supportive services for candidate',
  );

export const createExternalCandidateService = (user, programId) =>
  genericAPIActionCreator(
    () => api.createExternalCandidateService(user, programId),
    ACTIONS.CREATE_EXTERNAL_CANDIDATE_SERVICE,
    'Unable to fetch to the service. Try later.',
  );
export const candidateApplyToExternalService = (user, programId) =>
  genericAPIActionCreator(
    () => api.candidateApplyToExternalService(user, programId),
    ACTIONS.CANDIDATE_APPLY_TO_EXTERNAL_SERVICE,
    'Unable to fetch to the service. Try later.',
  );
export const transitionExternalCandidateServiceByCandidate = (user, candidateProgramId, newState) =>
  genericAPIActionCreator(
    () => api.transitionExternalCandidateServiceByCandidate(user, candidateProgramId, newState),
    ACTIONS.TRANSITION_EXTERNAL_CANDIDATE_SERVICE_C,
  );

export const fetchExternalCandidateServicesServices = (user, props = []) =>
  genericAPIActionCreator(
    () => api.fetchExternalCandidateServicesServices(user, props),
    ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES_SERVICES,
    'Unable to fetch. Try later.',
  );

export const fetchExternalCandidateServices = (user, externalCandidateServiceIds = []) =>
  genericAPIActionCreator(
    () => api.fetchExternalCandidateServices(user, externalCandidateServiceIds),
    ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES,
    'Unable to fetch. Try later.',
  );

export const listFilteredABServicesForCandidate = (user, props) =>
  genericAPIActionCreator(
    () => api.listABSupportiveServices(user, { ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_EXTERNAL_SERVICES_FOR_CANDIDATE,
    'Unable to list AB supportive services for candidate',
  );

export const fetchABService = (user, serviceId) =>
  genericAPIActionCreator(() => api.listABSupportiveService(user, serviceId), ACTIONS.FETCH_EXTERNAL_SERVICE, 'Unable to fetch AB supportive service');

export const listFilteredJobApplicationsForCandidate = (user, { filters }) =>
  // use buffered helper because reducers cannot handle the non-buffered partial updates
  bufferedPaginatedAPIAction(
    (page) => api.listCandidateApplicationsForCandidate(user, { candidateId: user.get('candidateId'), page, ...(filters?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_JOB_APPLICATIONS_FOR_CANDIDATE,
    'Unable to list job applications',
  );

export const listFilteredIndeedJobApplicationsForCandidate = (user, { filters }) =>
  // use buffered helper because reducers cannot handle the non-buffered partial updates
  bufferedPaginatedAPIAction(
    (page) => api.listIndeedCandidateApplicationsForCandidate(user, { page, candidateId: user.get('candidateId'), ...(filters?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_INDEED_JOB_APPLICATIONS_FOR_CANDIDATE,
    'Unable to list indeed job applications',
  );

export const listFilteredCandidateServicesForCandidate = (user, props) =>
  genericAPIActionCreator(
    () => api.listCandidateServicesForCandidate(user, { candidateId: user.get('candidateId'), ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE,
    'Unable to list job Candidate Services For Candidate',
  );

export const listFilteredCandidateServices = (user, candidateId, props) =>
  genericAPIActionCreator(
    () => api.listCandidateServicesForCandidate(user, { candidateId, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE,
    'Unable to list job Candidate Services For Candidate',
  );

export const listFilteredCandidateProgramsForCandidate = (user, props) =>
  genericAPIActionCreator(
    () => api.listCandidateProgramsForCandidate(user, { candidateId: user.get('candidateId'), ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATE_PROGRAMS_FOR_CANDIDATE,
    'Unable to list job Candidate Programs For Candidate',
  );

export const listFilteredCandidateEventsForCandidate = (user, props) =>
  genericAPIActionCreator(
    () => api.listCandidateEventsForCandidate(user, { candidateId: user.get('candidateId'), ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATE_EVENTS_FOR_CANDIDATE,
    'Unable to list job Candidate Events For Candidate',
  );

// SJL
export const setStaffJobListingsZipcode = (zipCode) => ({
  type: ACTIONS.SET_STAFF_JOB_LISTINGS_ZIP_CODE,
  zipCode,
});

export const setStaffEPCandidatesFilters = (filters) => ({
  type: ACTIONS.SET_STAFF_EP_CANDIDATES_FILTERS,
  filters,
});

export const setStaffEPCandidatesZipCode = (zipCode) => ({
  type: ACTIONS.SET_STAFF_EP_CANDIDATES_ZIP_CODE,
  zipCode,
});

export const setStaffEPCandidatesPage = (page) => ({
  type: ACTIONS.SET_STAFF_EP_CANDIDATES_PAGE,
  page,
});

export const setStaffCandidatesFilters = (filters) => ({
  type: ACTIONS.SET_STAFF_CANDIDATES_FILTERS,
  filters,
});

export const setStaffCandidatesSearch = (search) => ({
  type: ACTIONS.SET_STAFF_CANDIDATES_SEARCH,
  search,
});

export const setStaffProgramCandidatesFilters = (filters) => ({
  type: ACTIONS.SET_STAFF_PROGRAM_CANDIDATES_FILTERS,
  filters,
});

export const setStaffProgramCandidatesSearch = (search) => ({
  type: ACTIONS.SET_STAFF_PROGRAM_CANDIDATES_SEARCH,
  search,
});

export const setStaffEventCandidatesFilters = (filters) => ({
  type: ACTIONS.SET_STAFF_EVENT_CANDIDATES_FILTERS,
  filters,
});

export const setStaffEventCandidatesSearch = (search) => ({
  type: ACTIONS.SET_STAFF_EVENT_CANDIDATES_SEARCH,
  search,
});

export const setStaffJobCandidatesFilters = (filters) => ({
  type: ACTIONS.SET_STAFF_JOB_CANDIDATES_FILTERS,
  filters,
});

export const setStaffJobCandidatesSearch = (search) => ({
  type: ACTIONS.SET_STAFF_JOB_CANDIDATES_SEARCH,
  search,
});

export const setStaffJobListingsFilters = (filters) => ({
  type: ACTIONS.SET_STAFF_JOB_LISTINGS_FILTERS,
  filters,
});

export const setStaffJobListingsSearch = (search) => ({
  type: ACTIONS.SET_STAFF_JOB_LISTINGS_SEARCH,
  search,
});

export const setStaffEPCandidatesSearch = (search) => ({
  type: ACTIONS.SET_STAFF_EP_CANDIDATES_SEARCH,
  search,
});

export const setCandidatesApplicationsFilters = (filters) => ({
  type: ACTIONS.SET_FILTERED_CANDIDATES_APPLICATIONS_FILTERS,
  filters,
});

export const setCandidatesApplicationsSearch = (search) => ({
  type: ACTIONS.SET_FILTERED_CANDIDATES_APPLICATIONS_SEARCH,
  search,
});

export const listFilteredCandidatesForStaff = (user, organizationId, query, page) =>
  genericAPIActionCreator(
    () => api.listStaffCandidates(user, { page, organizationId, ...query.toJS() }),
    ACTIONS.LIST_FILTERED_CANDIDATES_FOR_STAFF,
    'Unable to list filtered candidates',
  );

export const listFilteredCandidatesForProgram = (user, programId, query) =>
  paginatedAPIAction(
    (page) => api.listStaffCandidatesForProgram(user, { page, programId, ...query.toJS() }),
    ACTIONS.LIST_FILTERED_PROGRAM_CANDIDATES_FOR_STAFF,
    'Unable to list filtered candidates',
  );

export const listFilteredCandidatesForEvent = (user, eventId, query) =>
  paginatedAPIAction(
    (page) => api.listStaffCandidatesForEvent(user, { page, eventId, ...query.toJS() }),
    ACTIONS.LIST_FILTERED_EVENT_CANDIDATES_FOR_STAFF,
    'Unable to list filtered candidates',
  );

export const listFilteredCandidatesForJob = (user, jobId, query) =>
  paginatedAPIAction(
    (page) => api.listStaffCandidatesForJob(user, { page, jobId, ...query.toJS() }),
    ACTIONS.LIST_FILTERED_JOB_CANDIDATES_FOR_STAFF,
    'Unable to list filtered candidates',
  );

export const listFilteredJobPostingsForStaff = (user, { page, filters }) =>
  genericAPIActionCreator(
    () => api.listJobPostings(user, { page, ...(filters?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF,
    'Unable to list job postings',
  );

export const listFilteredIndeedJobPostingsForStaff = (user, { page, filters }) =>
  genericAPIActionCreator(
    () => api.listIndeedJobPostings(user, { page, ...(filters?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_INDEED_JOB_POSTINGS_FOR_STAFF,
    'Unable to list job postings',
  );

export const removeFilteredIndeedJobPostingsForStaff = (user, { page, filters }) =>
  genericAPIActionCreator(
    () => api.listIndeedJobPostings(user, { page, ...(filters?.toJS() || {}) }),
    ACTIONS.REMOVE_FILTERED_INDEED_JOB_POSTINGS_FOR_STAFF,
    'Unable to list job postings',
  );

export const listFilteredJobPostingsForStaffOrg = (user, { page, organizationId, filters }) =>
  genericAPIActionCreator(
    () => api.listJobPostings(user, { page, organizationId, ...(filters?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF_ORG,
    'Unable to list job postings',
  );

export const listFilteredProgramsForStaff = (user, props) =>
  genericAPIActionCreator(() => api.listPrograms(user, { ...props.toJS() }), ACTIONS.LIST_FILTERED_PROGRAMS_FOR_STAFF, 'Unable to list programs');

export const listFilteredEventsForStaff = (user, props) =>
  genericAPIActionCreator(() => api.listEvents(user, { ...props.toJS() }), ACTIONS.LIST_FILTERED_EVENTS_FOR_STAFF, 'Unable to list events');

export const listFilteredServicesForStaff = (user, props) =>
  genericAPIActionCreator(
    () => api.listSupportiveServices(user, { ...props.toJS() }),
    ACTIONS.LIST_FILTERED_SERVICES_FOR_STAFF,
    'Unable to list events',
  );

export const listFilteredABServicesForStaff = (user, props) =>
  genericAPIActionCreator(
    (page = 1) => api.listABSupportiveServices(user, { page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_AB_SERVICES_FOR_STAFF,
    'Unable to list AB supportive services for staff',
  );

export const clearProgramsAndEvents = () => (dispatch) =>
  dispatch({
    type: ACTIONS.CLEAR_FILTERED_PROGRAMS_AND_EVENTS,
  });

export const listAllStaffPrograms = (user, props) =>
  genericAPIActionCreator((page = 1) => api.listPrograms(user, { page, ...props.toJS() }), ACTIONS.LIST_ALL_PROGRAMS_FOR_STAFF, 'Unable to list programs');
export const listAllStaffEvents = (user, props) =>
  genericAPIActionCreator((page = 1) => api.listEvents(user, { page, ...props.toJS() }), ACTIONS.LIST_ALL_EVENTS_FOR_STAFF, 'Unable to list events');
export const listAllStaffServices = (user, props) =>
  genericAPIActionCreator(
    (page = 1) => api.listSupportiveServices(user, { page, ...props.toJS() }),
    ACTIONS.LIST_ALL_SERVICES_FOR_STAFF,
    'Unable to list services',
  );

export const listFilteredCandidatePrograms = (user, organizationId, props) =>
  paginatedAPIAction(
    (page) => api.listCandidatePrograms(user, { page, organizationId, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATES_PROGRAMS,
    'Unable to list candidate programs',
  );

export const listFilteredCandidateEvents = (user, organizationId, props) =>
  paginatedAPIAction(
    (page) => api.listCandidateEvents(user, { page, organizationId, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATES_EVENTS,
    'Unable to list candidate events',
  );

export const listFilteredCandidateApplications = (user, candidateId, props) =>
  paginatedAPIAction(
    (page) => api.listCandidateApplicationsForCandidate(user, { page, candidateId, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATES_APPLICATIONS,
    'Unable to list candidate applications',
  );

export const listFilteredCandidateExternalApplications = (user, candidateId, props) =>
  paginatedAPIAction(
    (page) => api.listExternalCandidateApplicationsForCandidate(user, { page, candidateId, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_FILTERED_CANDIDATES_EXTERNAL_APPLICATIONS,
    'Unable to list candidates external applications',
  );

// Event

export const listEvents = (user, props) => paginatedAPIAction((page) => api.listEvents(user, { page, ...props }), ACTIONS.LIST_EVENTS, 'Unable to list events');

export const fetchEvent = (user, eventId) => genericAPIActionCreator(() => api.fetchEvent(user, eventId), ACTIONS.FETCH_EVENT);

export const createEvent = (user, organization, event) =>
  genericAPIActionCreator(() => api.createEvent(user, organization, event), ACTIONS.CREATE_EVENT, 'Unable to create event');

export const updateEvent = (user, eventId, event) =>
  genericAPIActionCreator(() => api.updateEvent(user, eventId, event), ACTIONS.UPDATE_EVENT, 'Unable to update event');

export const draftEvent = (user, eventId) => genericAPIActionCreator(() => api.draftEvent(user, eventId), ACTIONS.UPDATE_EVENT, 'Unable to save event draft');

export const publishEvent = (user, eventId) => genericAPIActionCreator(() => api.publishEvent(user, eventId), ACTIONS.UPDATE_EVENT, 'Unable to publish event');

export const closeEvent = (user, eventId) => genericAPIActionCreator(() => api.closeEvent(user, eventId), ACTIONS.UPDATE_EVENT, 'Unable to close event');

export const cancelEvent = (user, eventId) => genericAPIActionCreator(() => api.cancelEvent(user, eventId), ACTIONS.UPDATE_EVENT, 'Unable to cancel event');

export const activateEvent = (user, eventId) =>
  genericAPIActionCreator(() => api.activateEvent(user, eventId), ACTIONS.UPDATE_EVENT, 'Unable to activate event');

// Staff
export const createStaffMember = (user, staff) =>
  genericAPIActionCreator(() => api.createStaffMember(user, staff), ACTIONS.CREATE_STAFF_MEMBER, 'Unable to create staff');

export const fetchStaffMember = (user, staffId) =>
  genericAPIActionCreator(() => api.fetchStaffMember(user, staffId), ACTIONS.FETCH_STAFF_MEMBER, 'Unable to get staff');

export const updateStaffMember = (user, staffId, staff) =>
  genericAPIActionCreator(() => api.updateStaffMember(user, staffId, staff), ACTIONS.UPDATE_STAFF_MEMBER, 'Unable to update staff');

export const deleteStaffMember = (user, staffId) =>
  genericAPIActionCreator(() => api.deleteStaffMember(user, staffId), ACTIONS.DELETE_STAFF_MEMBER, 'Unable to delete staff');

export const listStaff = (user, organizationId) =>
  bufferedPaginatedAPIAction((page) => api.listStaff(user, organizationId, { page }), ACTIONS.LIST_STAFF, 'Unable to list staff');

export const listPeerStaff = (user, organizationId) =>
  bufferedPaginatedAPIAction((page) => api.listPeerStaff(user, organizationId, { page }), ACTIONS.LIST_PEER_STAFF);

// Messages
export const fetchMessage = (user, messageId) =>
  genericAPIActionCreator(() => api.fetchMessage(user, messageId), ACTIONS.FETCH_MESSAGE, 'Unable to fetch message');

export const createMessage = (user, message) =>
  genericAPIActionCreator(() => api.createMessage(user, message), ACTIONS.CREATE_MESSAGE, 'Unable to create message');

export const listMessages = (user, props) =>
  paginatedAPIAction((page) => api.listMessages(user, { page, ...props }), ACTIONS.LIST_MESSAGES, 'Unable to list messages');

export const updateMessage = (user, messageId, message) =>
  genericAPIActionCreator(() => api.updateMessage(user, messageId, message), ACTIONS.UPDATE_MESSAGE, 'Unable to update message');

// Notes
export const fetchNote = (user, noteId) => genericAPIActionCreator(() => api.fetchNote(user, noteId), ACTIONS.FETCH_NOTE, 'Unable to fetch note');

export const createNote = (user, candidateId, note) =>
  genericAPIActionCreator(() => api.createNote(user, candidateId, note), ACTIONS.CREATE_NOTE, 'Unable to create note');

export const listNotes = (user, props) => paginatedAPIAction((page) => api.listNotes(user, { page, ...props }), ACTIONS.LIST_NOTES, 'Unable to list notes');

export const updateNote = (user, noteId, note) =>
  genericAPIActionCreator(() => api.updateNote(user, noteId, note), ACTIONS.UPDATE_NOTE, 'Unable to update note');

// Org Invites
export const sendOrgInvites = (user, orgId, values) =>
  genericAPIActionCreator(() => api.inviteRolesToOrganization(user, orgId, values), ACTIONS.SEND_ORG_INVITES, 'Unable to send invites');

// Notifications
export const listNotifications = (user, props) =>
  bufferedPaginatedAPIAction(
    (page) => api.listNotifications(user, { page, ...(props?.toJS() || {}) }),
    ACTIONS.LIST_NOTIFICATIONS,
    'Unable to list notifications',
  );

export const fetchNotification = (user, notificationId) =>
  genericAPIActionCreator(() => api.fetchNotification(user, notificationId), ACTIONS.FETCH_NOTIFICATION, 'Unable to fetch notification');

export const dismissNotification = (user, notificationId) =>
  genericAPIActionCreator(() => api.dismissNotification(user, notificationId), ACTIONS.DISMISS_NOTIFICATION, 'Unable to dismiss notification');

export const setNotificationAsViewed = (user, notificationId) =>
  genericAPIActionCreator(() => api.setNotificationAsViewed(user, notificationId), ACTIONS.SET_NOTIFICATION_AS_VIEWED, 'Unable to set notification as viewed');

// Pathways
export const listPathways = (user, organizationId) =>
  genericAPIActionCreator(() => api.listPathways(user, organizationId), ACTIONS.LIST_PATHWAYS, 'Unable to list Pathways');

export const fetchPathway = (user, pathwayId) =>
  genericAPIActionCreator(() => api.fetchPathway(user, pathwayId), ACTIONS.FETCH_PATHWAY, 'Unable to fetch Pathway');

export const listPathwayInstances = (user, organizationId) =>
  bufferedPaginatedAPIAction(
    (page) => api.listPathwayInstances(user, organizationId, { page }),
    ACTIONS.LIST_PATHWAY_INSTANCES,
    'Unable to list Pathway instances',
  );

export const fetchPathwayInstance = (user, pathwayId) =>
  genericAPIActionCreator(() => api.fetchPathwayInstance(user, pathwayId), ACTIONS.FETCH_PATHWAY_INSTANCE, 'Unable to fetch Pathway instance');

export const createPathwayInstance = (user, organizationId, pathwayInstance) =>
  genericAPIActionCreator(
    () => api.createPathwayInstance(user, organizationId, pathwayInstance),
    ACTIONS.CREATE_PATHWAY_INSTANCE,
    'Unable to create pathway instance',
  );

export const updatePathwayInstance = (user, pathwayInstanceId, pathwayInstance) =>
  genericAPIActionCreator(
    () => api.updatePathwayInstance(user, pathwayInstanceId, pathwayInstance),
    ACTIONS.UPDATE_PATHWAY_INSTANCE,
    'Unable to update pathway instance',
  );

export const draftPathwayInstance = (user, pathwayInstanceId) =>
  genericAPIActionCreator(() => api.draftPathwayInstance(user, pathwayInstanceId), ACTIONS.UPDATE_PATHWAY_INSTANCE, 'Unable to save Pathway instance draft');

export const publishPathwayInstance = (user, pathwayInstanceId) =>
  genericAPIActionCreator(() => api.publishPathwayInstance(user, pathwayInstanceId), ACTIONS.UPDATE_PATHWAY_INSTANCE, 'Unable to publish Pathway instance');

export const closePathwayInstance = (user, pathwayInstanceId) =>
  genericAPIActionCreator(() => api.closePathwayInstance(user, pathwayInstanceId), ACTIONS.UPDATE_PATHWAY_INSTANCE, 'Unable to close Pathway instance');

export const cancelPathwayInstance = (user, pathwayInstanceId) =>
  genericAPIActionCreator(() => api.cancelPathwayInstance(user, pathwayInstanceId), ACTIONS.UPDATE_PATHWAY_INSTANCE, 'Unable to cancel Pathway instance');

export const setPathwayReportFilters = (filters) => ({
  type: ACTIONS.SET_PATHWAY_REPORT_FILTERS,
  filters,
});

// Pathway Instance Steps
export const transitionPathwayStepStaffState = (user, pathwayInstanceStepId, state, extra) =>
  genericAPIActionCreator(
    () => api.transitionPathwayStepStaffState(user, pathwayInstanceStepId, state, extra),
    ACTIONS.TRANSITION_PATHWAY_STEP_STAFF_STATE,
    'Unable to transition pathway step state',
  );

/**
 * Action to update pathway instance on the redux store without calling external API.
 */
export const updatePathwayInstanceV2 = (response) => (dispatch) =>
  dispatch({
    type: ACTIONS.UPDATE_PATHWAY_INSTANCE_STEPS,
    response,
  });

export const transitionPathwayStepCandidateState = (user, pathwayInstanceStepId, state, extra) =>
  genericAPIActionCreator(
    () => api.transitionPathwayStepCandidateState(user, pathwayInstanceStepId, state, extra),
    ACTIONS.TRANSITION_PATHWAY_STEP_CANDIDATE_STATE,
    'Unable to transition pathway step state',
  );

// Candidate Pathways
export const listCandidatePathwaysForPathwayInstance = (user, pathwayInstanceId, { userName }) =>
  bufferedPaginatedAPIAction(
    (page) => api.listCandidatePathwaysForPathwayInstance(user, pathwayInstanceId, { page, userName }),
    ACTIONS.LIST_CANDIDATE_PATHWAYS,
    'Unable to list candidate Pathways',
    {
      pathwayInstanceId,
    },
  );

export const listCandidatePathwaysForPathwayInstanceByPage = (user, pathwayInstanceId, { userName, page, size }) =>
  genericAPIActionCreator(
    () => api.listCandidatePathwaysForPathwayInstance(user, pathwayInstanceId, { userName, page, size }),
    ACTIONS.LIST_CANDIDATE_PATHWAYS,
    'Unable to list candidate Pathways',
    {
      pathwayInstanceId,
    },
  );

export const listCandidatePathwaysForOrganization = (user, organizationId) =>
  bufferedPaginatedAPIAction(
    (page) => api.listCandidatePathwaysForOrganization(user, organizationId, { page }),
    ACTIONS.LIST_CANDIDATE_PATHWAYS,
    'Unable to list candidate Pathways',
    {
      organizationId,
    },
  );

export const fetchCandidatePathway = (user, candidatePathwayId) =>
  genericAPIActionCreator(() => api.fetchCandidatePathway(user, candidatePathwayId), ACTIONS.FETCH_CANDIDATE_PATHWAY, 'Unable to fetch candidate Pathway');

export const updateCandidatePathway = (user, candidatePathwayId, candidatePathwayInstance) =>
  genericAPIActionCreator(
    () => api.updateCandidatePathway(user, candidatePathwayId, candidatePathwayInstance),
    ACTIONS.UPDATE_CANDIDATE_PATHWAY,
    'Unable to update candidate pathway',
  );

export const fetchCandidatePathwaysForCandidate = (user, candidateId) =>
  // eslint-disable-next-line max-len
  genericAPIActionCreator(
    () => api.fetchCandidatePathwaysForCandidate(user, candidateId),
    ACTIONS.FETCH_CANDIDATE_PATHWAYS,
    'Unable to fetch candidate Pathways',
  );

export const bulkCreateCandidatePathways = (user, pathwayInstanceId, data) =>
  genericAPIActionCreator(
    () => api.bulkCreateCandidatePathways(user, pathwayInstanceId, data),
    ACTIONS.FETCH_CANDIDATE_PATHWAY,
    'Unable to fetch candidate Pathway',
  );

export const transitionCandidatePathwayStaffState = (user, candidatePathwayId, state) =>
  genericAPIActionCreator(
    () => api.transitionCandidatePathwayStaffState(user, candidatePathwayId, state),
    ACTIONS.TRANSITION_CANDIDATE_PATHWAY_STAFF_STATE,
    'Unable to transition candidate pathway staff state',
  );

export const updateCandidatePathways = (response) => (dispatch) =>
  dispatch({
    type: ACTIONS.TRANSITION_CANDIDATE_PATHWAYS_STAFF_STATE.success,
    response,
  });

export const transitionCandidatePathwayCandidateState = (user, candidatePathwayId, state) =>
  genericAPIActionCreator(
    () => api.transitionCandidatePathwayCandidateState(user, candidatePathwayId, state),
    ACTIONS.TRANSITION_CANDIDATE_PATHWAY_STAFF_STATE,
    'Unable to transition candidate pathway candidate state',
  );

export const updatePathwayInstanceStep = (user, pathwayInstanceStepId, pathwayInstanceStep) =>
  genericAPIActionCreator(
    () => api.updatePathwayInstanceStep(user, pathwayInstanceStepId, pathwayInstanceStep),
    ACTIONS.UPDATE_PATHWAY_INSTANCE_STEP,
    'Unable to update pathway instance step',
  );

export const resetForm = (formName) => reset(formName);

export default ACTIONS;
