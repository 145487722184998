import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../common/theme';

const percentColor = ({ percent, color }) => {
  if (color) return color;
  if (percent < 50) return theme.color.error;
  if (percent < 70) return theme.color.warn;
  if (percent < 80) return theme.color.secondaryAction;
  return theme.color.success;
};

const ProgressStyles = styled.div`
  font-family: 'Open Sans', sans-serif;
  margin-bottom: 20px;
`;

export const ProgressBar = styled.div`
  background: #f0f0f0;
  border-radius: 10px;
  height: 10px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
`;

const ProgressBarProgressPropTypes = {
  percent: PropTypes.number.isRequired,
  color: PropTypes.string,
};

const ProgressBarProgressDefaultProps = {
  color: '',
};

export const ProgressBarProgress = ({ percent, color }) => (
  <div
    style={{
      height: '10px',
      borderRadius: '10px',
      backgroundColor: `${percentColor({ percent, color })}`,
      width: `${percent}%`,
    }}
  />
);

ProgressBarProgress.propTypes = ProgressBarProgressPropTypes;
ProgressBarProgress.defaultProps = ProgressBarProgressDefaultProps;

const ProgressLabelPropTypes = {
  percent: PropTypes.number.isRequired,
  label: PropTypes.string,
  percentLabel: PropTypes.string,
  color: PropTypes.string,
};

const ProgressLabelDefaultProps = {
  label: 'Completed',
  percentLabel: undefined,
  color: '',
};

export const ProgressLabel = ({ percent, percentLabel, label, color }) => (
  <div
    style={{
      color: `${percentColor({ percent, color })}`,
    }}
  >
    <span
      style={{
        fontSize: '2em',
        fontWeight: 'bold',
        marginRight: '10px',
        letterSpacing: '2px',
      }}
      className="percent"
    >
      {percentLabel || `${percent}%`}
    </span>
    {label}
  </div>
);

ProgressLabel.propTypes = ProgressLabelPropTypes;
ProgressLabel.defaultProps = ProgressLabelDefaultProps;

export default ProgressStyles;
