import PropTypes from 'prop-types';
import { normalize } from 'normalizr';
import { List, Map } from 'immutable';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { xor, intersectionWith } from 'lodash';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { MuiDatePicker } from 'common/form';
import * as toast from 'risekit/common/components/toast';
import { candidatePathway as candidatePathwaySchema } from 'api/schema';
import { bulkTransitionPathwayInstanceStepsStaffStates, bulkUpdatePathwayInstanceStepsStaffNotes, bulkUpdatePathwayInstanceStepsStaffStates } from 'api/real_backend';

import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { BulkEditPane, PathwayStepCandidate } from './pathway_step_styles';
import { Note } from '../pathway_candidate_details/candidate_step/candidate_step_styles';
import { SingleStep } from '../pathway_candidate_details/candidate_step/candidate_step_view';

const candidateStates = [
  { label: 'Not Started', value: 'not_started' },
  { label: 'In Progress', value: 'in_progress' },
  { label: 'Completed', value: 'complete' },
];

const PathwayStepDetailsView = ({
  user,
  pathwayStepId,
  pathwayInstanceId,
  pathwayInstance,
  candidatePathways,
  fetchPathwayInstance,
  updatePathwayInstanceV2,
  listCandidatePathwaysForPathwayInstance,
}) => {
  const [selectedPathwayInstanceStepIds, setSelectedPathwayInstanceStepIds] = useState([]);

  const [stepNote, setStepNote] = useState('');

  useFetchOnce(user, pathwayInstanceId, fetchPathwayInstance, pathwayInstance);
  useFetchOnce(user, pathwayInstanceId, listCandidatePathwaysForPathwayInstance, candidatePathways);

  if (!pathwayInstance || !candidatePathways) {
    return <Spinner />;
  }

  const pathwayStep = pathwayInstance
    .getIn(['pathway', 'pathwayMilestones'])
    .map((x) => x.getIn(['pathwayMilestone', 'pathwaySteps']))
    .flatten(1)
    .find((x) => x.get('id') === pathwayStepId);

  const clearSelectedPathwayInstanceStepIds = () => setSelectedPathwayInstanceStepIds([]);

  const togglePathwayInstanceStep = (pathwayInstanceStepId) => {
    const newSelectedPathwayInstanceStepIds = xor(selectedPathwayInstanceStepIds, [pathwayInstanceStepId]);

    setSelectedPathwayInstanceStepIds(newSelectedPathwayInstanceStepIds);
  };

  const toggleSelectAllPathwayInstanceStepIds = () => {
    const allPathwayInstanceSteps = candidatePathways.map((candidatePathway) =>
      candidatePathway.get('pathwayInstanceSteps').find((x) => x.get('pathwayStepId') === pathwayStepId),
    );
    const pathwayInstanceStepsIds = allPathwayInstanceSteps.map((pathwayInstanceStep) => pathwayInstanceStep.get('id')).toJS();

    setSelectedPathwayInstanceStepIds(pathwayInstanceStepsIds);
  };

  /**
   * Update pathway instances on the redux store without fetching each pathway instance.
   *
   * @param {Array} updatedPathwayInstanceSteps
   */
  const updatePathwayInstance = (updatedPathwayInstanceSteps) => {
    const candidatePathwaysMap = Map(candidatePathways.toJS().map((candidatePathway) => [candidatePathway.id, candidatePathway]));

    updatedPathwayInstanceSteps.forEach((pathwayInstanceStep) => {
      const { parentId: candidatePathwayInstanceId } = pathwayInstanceStep;

      const candidatePathway = candidatePathwaysMap.get(candidatePathwayInstanceId);
      const { pathwayInstanceSteps: prevPathwayInstanceSteps } = candidatePathway;

      const newPathwayInstanceSteps = prevPathwayInstanceSteps.map((step) => {
        if (step.id !== pathwayInstanceStep.id) {
          return step;
        }

        return pathwayInstanceStep;
      });

      const newPathwayInstanceStep = {
        ...candidatePathway,
        pathwayInstanceSteps: newPathwayInstanceSteps,
      };

      const updateActionPayload = normalize(newPathwayInstanceStep, candidatePathwaySchema);

      updatePathwayInstanceV2(updateActionPayload);
    });
  };

  const onBulkTargetEndDateChange = async (value) => {
    const requestPayload = {
      targetCompletionDate: value,
    };

    try {
      const { data: pathwayInstanceSteps } = await bulkUpdatePathwayInstanceStepsStaffStates(user, selectedPathwayInstanceStepIds, requestPayload);

      updatePathwayInstance(pathwayInstanceSteps);

      setSelectedPathwayInstanceStepIds([]);

      toast.success({ title: 'Success', message: 'Candidate pathways step has been updated.' });
    } catch (error) {
      toast.error({ title: 'Error', message: 'Opps something went wrong!' });
    }
  };

  const updatePathwayInstanceStepsStaffStates = async (requestPayload) => {
    try {
      const { data: pathwayInstanceSteps } = await bulkTransitionPathwayInstanceStepsStaffStates(user, selectedPathwayInstanceStepIds, requestPayload);

      updatePathwayInstance(pathwayInstanceSteps);

      setSelectedPathwayInstanceStepIds([]);

      toast.success({ title: 'Success', message: 'Candidate pathways step has been updated.' });
    } catch (error) {
      toast.error({ title: 'Error', message: 'Opps something went wrong!' });
    }
  };

  const onBulkActualEndDateChange = (value) => {
    const patchRequestPayload = {
      completedOn: value,
      state: 'complete',
    };

    updatePathwayInstanceStepsStaffStates(patchRequestPayload);
  };

  const onBulkStatusChange = (e) => {
    const patchRequestPayload = {
      state: e.target.value,
    };

    updatePathwayInstanceStepsStaffStates(patchRequestPayload);
  };

  const onBulkNoteUpdate = async () => {
    const newStepNote = stepNote;

    const updatePathwayInstanceNote = (pathwayInstanceToUpdate) => ({
      ...pathwayInstanceToUpdate,
      note: newStepNote,
    });

    const pathwayInstanceSteps = candidatePathways
      .map((candidatePathway) => candidatePathway.get('pathwayInstanceSteps').find((x) => x.get('pathwayStepId') === pathwayStepId))
      .toJS();

    const pathwayInstanceStepsToUpdate = intersectionWith(
      pathwayInstanceSteps,
      selectedPathwayInstanceStepIds,
      (pathwayInstanceStep, selectedPathwayInstanceStepId) => pathwayInstanceStep.id === selectedPathwayInstanceStepId,
    );

    const updatedPathwayInstanceSteps = pathwayInstanceStepsToUpdate.map(updatePathwayInstanceNote);

    try {
      const { data } = await bulkUpdatePathwayInstanceStepsStaffNotes(user, updatedPathwayInstanceSteps);

      updatePathwayInstance(data);

      setSelectedPathwayInstanceStepIds([]);

      toast.success({ title: 'Success', message: 'Candidate pathways step has been updated.' });
    } catch (error) {
      toast.error({ title: 'Error', message: 'Opps something went wrong!' });
    }
  };

  const isPathwayInstanceStepSelected = (id) => selectedPathwayInstanceStepIds.includes(id);
  const isAllPathwayInstanceStepsSelected = selectedPathwayInstanceStepIds.length === candidatePathways.size;

  const handleNoteChange = (e) => setStepNote(e.target.value);
  const resetNoteValue = () => setStepNote('');

  return (
    <div>
      <div>
        <Link to={`/staff/pathways/${pathwayInstanceId}`}>
          <Button color="primary" size="small" startIcon={<KeyboardBackspaceIcon />}>
            Back to Pathway
          </Button>
        </Link>
      </div>
      <h1>
        Pathway Name: {pathwayInstance.get('name')} Step Name: {pathwayStep.get('name')}{' '}
      </h1>

      {candidatePathways.length > 0 ? (
        ''
      ) : (
        <BulkEditPane>
          <div className="edit-pane-container">
            <div className="width-140 mt-22">
              {isAllPathwayInstanceStepsSelected ? (
                <Checkbox defaultChecked indeterminate onChange={clearSelectedPathwayInstanceStepIds} inputProps={{ 'aria-label': 'indeterminate checkbox' }} />
              ) : (
                <Checkbox
                  checked={isAllPathwayInstanceStepsSelected}
                  onChange={toggleSelectAllPathwayInstanceStepIds}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
              <span className="mr-8">Bulk edit</span>
            </div>
            {selectedPathwayInstanceStepIds.length === 0 ? (
              ''
            ) : (
              <div className="edit-pane">
                <div className="step-dates-container">
                  <div className="width-220">
                    <MuiDatePicker
                      input={{ onChange: onBulkTargetEndDateChange }}
                      variant="outlined"
                      name="targetCompletionDate"
                      margin="dense"
                      fullWidth
                      label="Target End Date"
                    />
                  </div>
                  <ArrowRightAltIcon className="step-arrow" />
                  <div className="width-220">
                    <MuiDatePicker
                      input={{ onChange: onBulkActualEndDateChange }}
                      variant="outlined"
                      name="completedOn"
                      margin="dense"
                      fullWidth
                      label="Actual End Date"
                    />
                  </div>
                  <Note>
                    <div item className="note-container">
                      <InputLabel>Note</InputLabel>
                      <TextField multiline rowsMax={4} variant="outlined" onChange={handleNoteChange} />
                      {stepNote !== '' && (
                        <div className="btn-container">
                          <Button onClick={onBulkNoteUpdate} size="small" variant="contained" color="primary">
                            Save Note
                          </Button>
                          <Button onClick={resetNoteValue} size="small">
                            Reset
                          </Button>
                        </div>
                      )}
                    </div>
                  </Note>
                </div>
                <div className="status-container">
                  <FormControl>
                    <InputLabel>Status</InputLabel>
                    <Select className="width-220" color="primary" margin="dense" variant="outlined" onChange={onBulkStatusChange}>
                      {candidateStates.map((candidateState) => (
                        <MenuItem key={candidateState.value} value={candidateState.value}>
                          {candidateState.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            )}
          </div>
        </BulkEditPane>
      )}
      {/* <Typography variant="h6" className={classes.candidatesCompletedText}> */}
      {/*  4/10 Candidates Completed */}
      {/* </Typography> */}
      {/* <LinearProgress variant="determinate" value={40} className={classes.progressBar} color="secondary" /> */}
      {/* <TextField */}
      {/*  id="search" */}
      {/*  placeholder="Search" */}
      {/*  margin="dense" */}
      {/*  variant="outlined" */}
      {/*  fullWidth */}
      {/*  InputProps={{ */}
      {/*    startAdornment: ( */}
      {/*      <InputAdornment position="start"> */}
      {/*        <SearchIcon /> */}
      {/*      </InputAdornment> */}
      {/*    ), */}
      {/*  }} */}
      {/* /> */}
      {/* <Grid container justify="space-between" alignItems="center" spacing={2} className={classes.rowsHeader}>
        <Grid item xs={1} />
        <Grid item xs={3}>
          <Typography variant="overline">Candidate</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">Target Completion</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="overline">Actual Completion</Typography>
        </Grid>
        <Grid item container xs={4} justify="center">
          <Typography variant="overline">Status</Typography>
        </Grid>
      </Grid> */}
      {candidatePathways.map((candidatePathway) => {
        const pathwayInstanceStep = candidatePathway.get('pathwayInstanceSteps').find((x) => x.get('pathwayStepId') === pathwayStepId);

        return (
          <PathwayStepCandidate key={candidatePathway.get('id')}>
            <div className="link-wrapper">
              <Checkbox
                checked={isPathwayInstanceStepSelected(pathwayInstanceStep.get('id'))}
                onChange={() => togglePathwayInstanceStep(pathwayInstanceStep.get('id'))}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <h4>
                <Link className="candidate-link" to={`/staff/candidates/${candidatePathway.getIn(['candidate', 'candidateId'])}`}>
                  {candidatePathway.getIn(['candidate', 'user', 'firstName'])}
                  &nbsp;{candidatePathway.getIn(['candidate', 'user', 'lastName'])}
                </Link>
              </h4>
              <Link className="pathway-link" to={`/staff/pathways/${pathwayInstanceId}/candidates/${candidatePathway.get('id')}`}>
                View Pathway
              </Link>
            </div>

            <SingleStep
              step={pathwayStep}
              candidatePathwayId={candidatePathway.get('id')}
              pathwayInstanceStep={pathwayInstanceStep}
              key={candidatePathway.get('id')}
            />
          </PathwayStepCandidate>
        );
      })}
    </div>
  );
};

PathwayStepDetailsView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  pathwayStepId: PropTypes.string.isRequired,
  pathwayInstanceId: PropTypes.string.isRequired,
  pathwayInstance: PropTypes.instanceOf(Map),
  fetchPathwayInstance: PropTypes.func.isRequired,
  listCandidatePathwaysForPathwayInstance: PropTypes.func.isRequired,
  candidatePathways: PropTypes.instanceOf(List),
  updatePathwayInstanceV2: PropTypes.func.isRequired,
};
PathwayStepDetailsView.defaultProps = {
  pathwayInstance: undefined,
  candidatePathways: undefined,
};

export default PathwayStepDetailsView;
