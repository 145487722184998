import { connect } from 'react-redux';
import DashboardView from './dashboard_view';
import { getUserType } from '../../../reducer';

// mapStateToProps(dispatch, { prop })
const mapStateToProps = (state) => ({
  // prop assignments
  userType: getUserType(state),
});

const DashboardContainer = connect(mapStateToProps)(DashboardView);

export default DashboardContainer;
