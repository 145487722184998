import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import Stepper from 'react-stepper-horizontal';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button } from '../../../common/components';

import { MuiFormTheme, MuiThemeProvider } from '../../../common/form';
import theme from '../../../common/theme';
import AddPathwayStyles from './add_pathway_styles';

import ReviewForm from './review_form';
import SelectTypeForm from './select_type_form';
import SetScheduleForm from './set_schedule_form';

const steps = [{ title: '1. Select Pathway Type' }, { title: '2. Set Schedule' }, { title: '3. Review' }];

const AddPathwayView = ({ initialValues, handleSubmit, handleSaveDraft, resetForm }) => {
  const [page, setPage] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    resetForm('pathwayForm');
  }, [resetForm]);

  const handleSubmitAndRedirect = async (formValues) => {
    await handleSubmit(formValues);
    setSubmitted(true);
    alert('Your event has been successfully saved and posted.');
  };

  const handleSaveDraftAndRedirect = async (formValues) => {
    await handleSaveDraft(formValues);
    setSubmitted(true);
    alert('Your event draft has been successfully saved.');
  };

  if (submitted) {
    return <Redirect to="/staff/pathways" />;
  }

  return (
    <AddPathwayStyles>
      <div className="content-area">
        <Link to="/staff/pathways">
          <Button buttonType="back" size="small">
            <KeyboardBackspaceIcon />
            Go Back to Pathways
          </Button>
        </Link>
        <div className="wrapper">
          <div className="header">
            <h1>
              Add: <span>New Pathway</span>
            </h1>
            <Stepper
              steps={steps}
              activeStep={page}
              activeColor={theme.color.secondaryAction}
              activeTitleColor={theme.color.secondaryAction}
              completeColor={theme.color.secondaryAction}
              completeBarColor={theme.color.secondaryAction}
              completeTitleColor={theme.color.secondaryAction}
              circleFontSize={0}
              titleFontSize={14}
              titleFontWeight="bold"
              size={8}
            />
          </div>

          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {page === 0 && <SelectTypeForm onSubmit={() => setPage(page + 1)} initialValues={initialValues} />}
              {page === 1 && <SetScheduleForm previousPage={() => setPage(page - 1)} onSubmit={() => setPage(page + 1)} initialValues={initialValues} />}
              {page === 2 && (
                <ReviewForm
                  previousPage={() => setPage(page - 1)}
                  onSubmit={(values) => handleSubmitAndRedirect(values)}
                  onSaveDraft={(values) => handleSaveDraftAndRedirect(values)}
                />
              )}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </AddPathwayStyles>
  );
};

AddPathwayView.propTypes = {
  initialValues: PropTypes.shape({ startDate: PropTypes.string }),
  handleSubmit: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
};
AddPathwayView.defaultProps = {
  initialValues: {},
};

export default AddPathwayView;
