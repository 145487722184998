import styled from 'styled-components';
import theme from '../../../common/theme';

const ListingApplicantDetailsTileStyles = styled.div`
  background: #f7f7f7;
  padding: 20px;
  margin-bottom: 20px;

  .check-group {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    margin: 10px 0px;
  }
  .title-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 0px 20px;
  h4 {
    margin: 0;
  }
  .button {
    text-transform: none;
    border: solid 1px;
  }
  .blank {
    color: #222b45;
    background: transparent;
    border: 0;
    padding: 4px 5px;
    margin: 0;
    font-size: 12px;
    text-transform: none;
    letter-spacing: 0.03px;
    line-height: 1.5em;
    font-weight: 400;
    outline: 0;
  }
  .blank:hover {
    color: #222b45;
  }
  .delete {
    padding: 0px 0px 8px;
    font-style: italic;
  }

  @media ${theme.query.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Person = styled.div`
  display: flex;
  flex-direction: row;
  h4 {
    margin: 0px;
    font-weight: normal;
  }
  p {
    margin: 0px;
    color: #c0c0c0;
    text-transform: uppercase;
    font-size: 0.8em;
  }
`;

export const Actions = styled.div`
  justify-self: flex-end;
  align-items: bottom;
  text-align: right;
  color: #b8c0bc;
  button {
    margin-left: 10px;
  }
  .MuiButtonGroup-root {
    /* background-color: #fff; */
    /* border: solid 1px #E4E9F2; */
    /* border-radius: 25px; */
    /* box-shadow: 0px 5px 5px rgba(0,0,0,.03); */
  }
  p {
    margin: 3px 0px 0px 0px;
    color: #c0c0c0;
    font-size: 0.7em;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const Body = styled.div`
  padding: 10px 0px;
  h4 {
    margin: 0px;
    font-weight: normal;
    color: ${(props) => props.theme.color.secondaryAction};
    b {
      color: #000;
    }
  }
  p {
    margin: 0px;
    color: #c0c0c0;
  }
`;

export const Footer = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  & > button {
    margin-left: 10px;
  }
  @media only screen and (max-width: 650px) {
    justify-content: space-between;
    & > button {
      margin: 0px;
    }
  }
`;

export const Statuses = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Status = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 20px;
  color: ${(props) => props.color || '#ccc'};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
`;

export const Icon = styled.div`
  border-radius: 5px;
  background: ${(props) => props.color || '#F1F0F0'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 33px;
  margin-right: 20px;
`;

export default ListingApplicantDetailsTileStyles;
