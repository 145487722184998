import './app.css';

import React from 'react';
import { Provider } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider } from 'styled-components';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router/immutable';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider as MuiPickersUtilsProvider2 } from '@material-ui/pickers';

import Solve from './solve';
import configureStore from './store';
import apolloClient from './apollo_gql';
import theme, { muiTheme } from './common/theme';
import { setTreatment } from './session/actions';
import ErrorBoundary from './util/error_boundary';
import Spinner from './common/components/spinner';
import SegmentReporter from './util/segment_reporter';
import GlobalPrecacheHelper from './global_precache_helper';

export const { store, history, persistor } = configureStore();

// Flamelink.init(store);

global.setTreatment = (key, value) => store.dispatch(setTreatment(key, value));

global.mystore = store;

const App = () => (
  <PersistGate loading={<Spinner />} persistor={persistor}>
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <GlobalPrecacheHelper />
        <SegmentReporter />
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <MuiThemeProvider theme={muiTheme}>
              <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MuiPickersUtilsProvider2 utils={DateFnsUtils}>
                    <Solve />
                  </MuiPickersUtilsProvider2>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </ErrorBoundary>
        </ConnectedRouter>
      </ApolloProvider>
    </Provider>
  </PersistGate>
);

export default App;
