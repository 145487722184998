import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import { startCase } from 'lodash';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// import theme from '../../../../common/theme';
import DashboardStepsStyles from './dashboard_steps_styles';
import { CardHeader, CardSubHeader, CardBody, CardItem } from '../../../../common/styles/card';

const propTypes = {
  candidate: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const DashboardStepsView = ({ candidate }) => {
  const resumeStatus = candidate?.get('resumeStatus');
  const questionnaireStatus = candidate?.get('challengesCompletion');

  return (
    <DashboardStepsStyles>
      <CardHeader padding>
        <CardSubHeader>
          <h2>Next Steps</h2>
        </CardSubHeader>
      </CardHeader>
      <CardBody paddingBottom paddingTop>
        <CardItem className={resumeStatus !== 'complete' ? 'in-progress' : 'complete'}>
          <p>
            {resumeStatus !== 'complete' ? <RadioButtonUncheckedIcon /> : <CheckCircleIcon />}
            {resumeStatus !== 'complete' ? (
              <Link to="/candidate/profile">
                Build resume
                <ChevronRightIcon className="chev-right" />
              </Link>
            ) : (
              'Build resume'
            )}
          </p>
          <span>{startCase(resumeStatus)}</span>
        </CardItem>
        <CardItem className={questionnaireStatus !== 'complete' ? 'in-progress' : 'complete'}>
          <p>
            {questionnaireStatus !== 'complete' ? <RadioButtonUncheckedIcon /> : <CheckCircleIcon />}
            {questionnaireStatus !== 'complete' ? (
              <Link to="/candidate/profile/questionnaire">
                Fill out challenges questionnaire
                <ChevronRightIcon className="chev-right" />
              </Link>
            ) : (
              'Fill out challenges questionnaire'
            )}
          </p>
          <span>{startCase(questionnaireStatus)}</span>
        </CardItem>
        <CardItem className="not-started">
          <p>
            <RadioButtonUncheckedIcon />
            Find a job or resource
          </p>
        </CardItem>
      </CardBody>
    </DashboardStepsStyles>
  );
};

DashboardStepsView.propTypes = propTypes;
DashboardStepsView.defaultProps = defaultProps;

export default DashboardStepsView;
