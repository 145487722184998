import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router-dom';
import Spinner from '../../../common/components/spinner';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import EventPreview from './event_preview';
import ProgramPreview from './program_preview';
import JobPreview from './job_preview';
import JoinSolveAsStaffPreviewView from './join_solve_as_staff_preview/join_solve_as_staff_preview_view';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  invitation: PropTypes.instanceOf(Map),
  postingId: PropTypes.string.isRequired,
  fetchInviteLink: PropTypes.func.isRequired,
  visitedInvite: PropTypes.func.isRequired,
  setRedirectPath: PropTypes.func.isRequired,
};

const defaultProps = {
  invitation: undefined,
};

const InvitePreviewView = ({ currentUser, postingId, invitation, fetchInviteLink, visitedInvite, setRedirectPath }) => {
  const resolvedInviteLink = useFetchOnce(currentUser, postingId, fetchInviteLink);

  useEffect(() => {
    if (invitation) {
      visitedInvite({ token: invitation.get('id'), recommendedType: invitation.get('recommendedType'), recommendedId: invitation.get('recommendedId') });
    }
  }, [postingId, invitation, visitedInvite]);

  if (!postingId) {
    return <Redirect to="/" />;
  }

  if (!resolvedInviteLink) {
    return <Spinner />;
  }

  if (!invitation) {
    return <Redirect to="/" />;
  }

  if (invitation.get('invitationType') === 'join-solve-as-staff') {
    return <JoinSolveAsStaffPreviewView publicLinkOrInvitation={invitation} currentUser={currentUser} />;
  }

  switch (invitation.get('recommendedType')) {
    case 'Program':
      return <ProgramPreview currentUser={currentUser} invitation={invitation} setRedirectPath={setRedirectPath} />;
    case 'Event':
      return <EventPreview currentUser={currentUser} invitation={invitation} setRedirectPath={setRedirectPath} />;
    default:
      return <JobPreview currentUser={currentUser} invitation={invitation} setRedirectPath={setRedirectPath} />;
  }
};

InvitePreviewView.propTypes = propTypes;
InvitePreviewView.defaultProps = defaultProps;

export default InvitePreviewView;
