import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const DEFAULT_STATE = fromJS({
  candidatesLoading: false,
  candidatesLoaded: 0,
  candidatesList: EMPTY_SET,
  totalCandidates: 0,
  filters: {},
});

const StaffCandidatesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_STAFF_CANDIDATES_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          candidatesLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_CANDIDATES_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          candidatesLoaded: false,
          // applicationsLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_CANDIDATES_FOR_STAFF.request:
      return state.set('candidatesLoading', true);
    case ACTIONS.LIST_FILTERED_CANDIDATES_FOR_STAFF.success:
      return state.merge({
        candidatesList: OrderedSet(action.response.result),
        candidatesLoading: false,
        candidatesLoaded: Date.now(),
        totalCandidates: action.response.totalCandidates,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_FOR_STAFF.failure:
      return state.merge({
        candidatesLoading: false,
        candidatesLoaded: false,
        candidatesList: EMPTY_SET,
        totalCandidates: 0,
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search');

export const getLoading = (state) => state.get('candidatesLoading');
export const getLoaded = (state) => state.get('candidatesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getCandidateIds = (state) => state.get('candidatesList') || DEFAULT_STATE.get('candidatesList');
export const getTotalCandidates = (state) => state.get('totalCandidates') || DEFAULT_STATE.get('totalCandidates');

export default StaffCandidatesReducer;
