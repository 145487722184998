import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Redirect, NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { Button, Text, Spinner } from '../../../common/components';
import { reduxForm } from '../../../common/form';
import JobTile from '../../../jobs/components/tile';
import { ApplyToJobStyles, Results } from './apply_to_job_styles';
import JobPostingModel from '../../../solve/models/job_posting_model';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import SurveyWizard from '../survey_wizard';
import ResumeWizard from '../resume_wizard';
import CandidateApplicationModel from '../../../solve/models/candidate_application_model';
import { getCandidateSurveys } from '../../../reducer';

const propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  fetchJobPosting: PropTypes.func.isRequired,
  createCandidateApplication: PropTypes.func.isRequired,
  transitionCandidateApplicationByCandidate: PropTypes.func.isRequired,
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]),
  candidateApplication: PropTypes.oneOfType([PropTypes.instanceOf(CandidateApplicationModel), PropTypes.instanceOf(Map)]),
};

const defaultProps = {
  job: undefined,
  candidateApplication: undefined,
};

function countCorrectSurveyAnswers(answers) {
  return answers.filter((answer) => answer.getIn(['surveyAnswer', 'isCorrectAnswer'])).size;
}

function countAskedQuestions(answers) {
  return answers.filter((answer) => answer.getIn(['surveyQuestion', 'questionTemplate', 'answerType']) !== 'none').size;
}

function countAnsweredQuestions(answers) {
  return answers.filter(
    (answer) => answer.getIn(['surveyQuestion', 'questionTemplate', 'answerType']) !== 'none' && !answer.getIn(['surveyAnswer', 'isSkipped']),
  ).size;
}

function evaluateCandidateSurveys(surveys) {
  const counts = surveys.reduce(
    (prev, survey) => ({
      total: prev.total + survey.get('numberOfQuestions'),
      correct: prev.correct + countCorrectSurveyAnswers(survey.get('surveyAnswers')),
      asked: prev.asked + countAskedQuestions(survey.get('surveyAnswers')),
      answered: prev.answered + countAnsweredQuestions(survey.get('surveyAnswers')),
    }),
    { total: 0, correct: 0, asked: 0, answered: 0 },
  );
  const percentCorrect = Math.round((counts.correct * 100) / counts.total);
  const percent = Math.round((counts.answered * 100) / counts.asked);
  return {
    percentCorrect,
    percent,
    ...counts,
  };
}

function FinishApplicationView({ candidateSurveys, onOpenWebsite, onFinish, job }) {
  const { percentCorrect, percent, answered, asked } = evaluateCandidateSurveys(candidateSurveys);

  const FinishLink = ({ externalLink }) => {
    const hasExternalLink = externalLink && externalLink.length;
    useEffect(() => {
      if (!hasExternalLink && candidateSurveys.isEmpty()) {
        onFinish();
      }
    });
    if (percentCorrect < 70) {
      return (
        <Results>
          <div style={{ textAlign: 'center' }}>
            <NavLink to="/candidate/jobs">
              <Button buttonType="primary">View More Jobs</Button>
            </NavLink>
          </div>
        </Results>
      );
    }
    if (externalLink && externalLink.length) {
      return (
        <div>
          <Text>
            <p>This job posting requires filling out a job application that is hosted on an external website.</p>
          </Text>
          <div style={{ textAlign: 'center' }}>
            <Button buttonType="primary" onClick={onOpenWebsite}>
              Open job application website
            </Button>
          </div>
        </div>
      );
    }
    if (candidateSurveys.isEmpty()) {
      return <Spinner />;
    }
    return (
      <Button buttonType="primary" onClick={onFinish}>
        Finish the Application
      </Button>
    );
  };

  FinishLink.propTypes = {
    externalLink: PropTypes.string,
  };

  FinishLink.defaultProps = {
    externalLink: undefined,
  };

  return (
    <Grid item xs={11} container justify="flex-start" alignItems="center" spacing={2}>
      {candidateSurveys && candidateSurveys.size >= 1 && (
        <Fragment>
          <Results>
            <Grid item xs={12}>
              <h2>Thank you for completing the questions.</h2>
            </Grid>
            <Grid item xs={12} container justify="flex-start" alignItems="center" spacing={2}>
              <Grid item>
                <h1>{percent}%</h1>
                <header>
                  {answered}/{asked} Questions
                </header>
              </Grid>
            </Grid>
          </Results>
        </Fragment>
      )}
      <Grid item xs={11}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <FinishLink externalLink={job.get('applicationUrl')} percent={percent} />
        </Grid>
      </Grid>
    </Grid>
  );
}

FinishApplicationView.propTypes = {
  candidateSurveys: PropTypes.instanceOf(OrderedSet),
  job: PropTypes.instanceOf(Map).isRequired,
  onOpenWebsite: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
};

FinishApplicationView.defaultProps = {
  candidateSurveys: OrderedSet(),
};

const mapFinishApplicationState = (state, { candidateSurveyIds }) => ({
  candidateSurveys: getCandidateSurveys(state, candidateSurveyIds),
});
const FinishApplication = connect(mapFinishApplicationState)(FinishApplicationView);

const EMPTY_SET = OrderedSet();

const ApplyToJobForm = ({
  user,
  id: jobPostingId,
  job,
  candidateApplication,
  fetchJobPosting,
  createCandidateApplication,
  transitionCandidateApplicationByCandidate,
}) => {
  const resolvedPosting = useFetchOnce(user, jobPostingId, fetchJobPosting, job && job.get('surveys'));
  const resolvedApplication = useFetchOnce(user, jobPostingId, createCandidateApplication, candidateApplication);
  const [resumeComplete, setResumeComplete] = useState(false);
  const [candidateSurveyIds, setCandidateSurveyIds] = useState(undefined);

  // 1) FIND JOB
  // 2) CREATE DRAFT APPLICATION
  // 3) IF RESUME NEEDED
  // -> RUN RESUME WIZARD
  // 4) IF HAS SURVEY
  // -> RUN SURVEY WIZARD
  // 5) FINISH APP CTA -> Mark Submitted (forward to website?)

  const hasSurveys = job ? job.get('surveys') && job.get('surveys').size > 0 : true;
  useEffect(() => {
    if (!hasSurveys) {
      setCandidateSurveyIds(EMPTY_SET);
    }
  }, [hasSurveys]);

  if (!resolvedPosting || !resolvedApplication) {
    return <Spinner />;
  }

  if (!job || !candidateApplication) {
    // Redirect up one level to be handled there.
    console.log('Unable to resolve job or candidateApplication', { job, candidateApplication });
    return <Redirect to="." />;
  }

  // TODO: if already applied... ?
  if (candidateApplication.get('candidateState') !== 'c_saved') {
    if (candidateApplication.get('candidateState') === 'c_applied_on_website' && job.get('applicationUrl')) {
      let url = '';
      if (job.get('applicationUrl').startsWith('http')) {
        url = job.get('applicationUrl');
      } else {
        url = `//${job.get('applicationUrl')}`;
      }
      window.open(url, '_blank');
    } else {
      return <Redirect to="/candidate/jobs" />;
    }
  }

  if (!resumeComplete) {
    return (
      <ResumeWizard
        hasSurveys={hasSurveys}
        onComplete={() => {
          // TODO: when resume upload is supported in the backend...
          // setUploadedResumeId(uploadedId);
          setResumeComplete(true);
        }}
      />
    );
  }

  if (hasSurveys && !candidateSurveyIds) {
    return (
      <div className="container-wrapper">
        <div className="candidate-wrapper">
          <SurveyWizard surveys={job.get('surveys')} onComplete={setCandidateSurveyIds} />
        </div>
      </div>
    );
  }

  const onOpenWebsite = () => {
    transitionCandidateApplicationByCandidate(user, candidateApplication.get('id'), 'applied_on_website');
  };
  const onFinish = () => {
    transitionCandidateApplicationByCandidate(user, candidateApplication.get('id'), 'applied');
  };
  // const onAbort = () => {
  //   return <Redirect to="/candidate/jobs" />;
  // };

  return (
    <ApplyToJobStyles className="container-wrapper">
      <div className="candidate-wrapper">
        <Grid container direction="column" justify="center" alignItems="center" spacing={2}>
          <Grid item xs={11}>
            <JobTile key={job.get('id')} id={job.get('id')} hideActions />
          </Grid>
          <FinishApplication
            candidateSurveyIds={hasSurveys ? candidateSurveyIds : EMPTY_SET}
            onOpenWebsite={onOpenWebsite}
            onFinish={onFinish}
            // onAbort={onAbort}
            job={job}
          />
        </Grid>
      </div>
    </ApplyToJobStyles>
  );
};

ApplyToJobForm.propTypes = propTypes;
ApplyToJobForm.defaultProps = defaultProps;

const ApplyToJobView = reduxForm({
  form: 'applyToJobForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ApplyToJobForm);

export default ApplyToJobView;
