import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map } from 'immutable';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
// import ProgramListFilters from './program_list_filters';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ServiceTile from '../service_tile';
import EventTile from '../../../events/components/tile';
import ProgramTile from '../../../programs/components/tile';
import ResourcesListStyles, { ResultsPageNavigator } from './resources_list_styles';
import UserModel from '../../../session/models/user_model';
import { useDebouncedEffect } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import ZipDistanceFilter from './zip_distance_filter';
import ResourceListFilters from './resources_list_filters';
import theme from '../../../common/theme';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  currentCandidate: PropTypes.instanceOf(Map).isRequired,
  listFilteredEventsForCandidate: PropTypes.func.isRequired,
  servicesLoaded: PropTypes.bool.isRequired,
  resourcesLoaded: PropTypes.bool.isRequired,
  externalServicesLoaded: PropTypes.bool.isRequired,
  listFilteredServicesForCandidate: PropTypes.func.isRequired,
  listFilteredCandidateServicesForCandidate: PropTypes.func.isRequired,
  listFilteredProgramsForCandidate: PropTypes.func.isRequired,
  listFilteredCandidateEventsForCandidate: PropTypes.func.isRequired,
  listFilteredCandidateProgramsForCandidate: PropTypes.func.isRequired,
  listFilteredABServicesForCandidate: PropTypes.func.isRequired,
  fetchExternalCandidateServicesServices: PropTypes.func.isRequired,
  fetchExternalCandidateServices: PropTypes.func.isRequired,
  programsLoaded: PropTypes.bool.isRequired,
  eventsLoaded: PropTypes.bool.isRequired,
  postings: PropTypes.instanceOf(OrderedSet).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  search: PropTypes.string,
  sortBy: PropTypes.string.isRequired,
  distance: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setCandidateProgramsZipCode: PropTypes.func.isRequired,
  setCandidateProgramsDistance: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  distanceFilterChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  sortByFilterChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  serviceTypeChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  // targetTypeChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  mode: PropTypes.oneOf(['all', 'saved', 'applied']),
  showAB: PropTypes.bool,
  setCandidateProgramsPage: PropTypes.func.isRequired,
  setCandidateProgramsSortBy: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
};
const defaultProps = {
  search: '',
  mode: 'all',
  zipCode: '',
  showAB: false,
};

function useSearch(search, setSearch, resourcesLoaded) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (resourcesLoaded && search !== newSearch) {
        setSearch(newSearch);
      }
    },
    1500,
    [resourcesLoaded, search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const ResourcesListView = ({
  programsLoaded,
  eventsLoaded,
  servicesLoaded,
  resourcesLoaded,
  externalServicesLoaded,
  listFilteredServicesForCandidate,
  listFilteredCandidateServicesForCandidate,
  listFilteredEventsForCandidate,
  listFilteredProgramsForCandidate,
  listFilteredCandidateEventsForCandidate,
  listFilteredCandidateProgramsForCandidate,
  listFilteredABServicesForCandidate,
  fetchExternalCandidateServicesServices,
  fetchExternalCandidateServices,
  currentUser,
  postings,
  search,
  filters,
  setSearch,
  mode,
  zipCode,
  distance,
  setCandidateProgramsZipCode,
  setCandidateProgramsDistance,
  distanceFilterChoices,
  sortByFilterChoices,
  serviceTypeChoices,
  // targetTypeChoices,
  onChange,
  showAB,
  totalCount,
  setCandidateProgramsPage,
  setCandidateProgramsSortBy,
  sortBy,
  currentCandidate,
}) => {
  const listAllPostings = mode === 'all';
  const [firstLoad, setFirstLoad] = useState(true);
  const [serviceSelected, setServiceSelected] = useState(!!filters.get('serviceType'));
  const [pageShown, setPageShown] = useState(0);
  const totalPages = Math.ceil(totalCount / 25) || 1;

  const scrollTop = () => window.scrollTo(0, 0);
  // let paginatedPostings = postings;
  useEffect(() => {
    setCandidateProgramsPage(pageShown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageShown]);

  const getNextPageResults = () => {
    setPageShown(pageShown + 1);
    scrollTop();
  };
  const getPrevPageResults = () => {
    setPageShown(pageShown - 1);
    scrollTop();
  };

  useEffect(() => {
    setPageShown(0);
  }, [filters, search, zipCode, distance, listAllPostings]);

  const PageNavigator = () => {
    if (totalCount < 25 || !resourcesLoaded || !externalServicesLoaded || !postings || postings.size === 0) {
      return null;
    }
    return (
      <ResultsPageNavigator>
        <div className="prev-next-container">
          <Button
            style={{ color: pageShown <= 0 ? theme.color.grayLight : theme.color.blue }}
            startIcon={<NavigateBeforeIcon />}
            disabled={pageShown <= 0}
            onClick={() => getPrevPageResults()}
          >
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown + 1} of ${totalPages}`}</Typography>
          <Button
            style={{ color: pageShown >= totalPages - 1 ? theme.color.grayLight : theme.color.blue }}
            endIcon={<NavigateNextIcon />}
            disabled={pageShown >= totalPages - 1}
            onClick={() => getNextPageResults()}
          >
            Next
          </Button>
        </div>
      </ResultsPageNavigator>
    );
  };
  const query = filters.merge(
    listAllPostings ?
      { helperName: search, progname: search, eventname: search, zipCode, distance, sortBy } :
      { helperName: search, progname: search, eventname: search, zipCode: '', distance: '', sortBy: 'distance', serviceType: '' },
  );
  const canFetch = (!listAllPostings || serviceSelected) && !firstLoad;
  const loadEvents = !eventsLoaded && canFetch;
  const loadPrograms = !programsLoaded && canFetch;
  const loadServices = !servicesLoaded && canFetch;
  const candidateZipCode = currentCandidate.getIn(['address', 'zip']) || '10001';
  const userZip = currentUser?.zip || candidateZipCode;
  const serviceType = filters.get('serviceType');
  const listFilteredEvents = listAllPostings ? listFilteredEventsForCandidate : listFilteredCandidateEventsForCandidate;
  const listFilteredPrograms = listAllPostings ? listFilteredProgramsForCandidate : listFilteredCandidateProgramsForCandidate;
  const listFilteredServices = listAllPostings ? listFilteredServicesForCandidate : listFilteredCandidateServicesForCandidate;

  useEffect(() => {
    if (firstLoad) {
      setCandidateProgramsZipCode(userZip);
      setFirstLoad(false);
      fetchExternalCandidateServicesServices(currentUser);
      fetchExternalCandidateServices(currentUser);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLoad]);

  useEffect(() => {
    if (loadEvents) {
      listFilteredEvents(currentUser, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadEvents, distance, serviceType, search, zipCode, listAllPostings]);

  useEffect(() => {
    if (loadPrograms) {
      listFilteredPrograms(currentUser, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadPrograms, distance, serviceType, search, zipCode, listAllPostings]);
  useEffect(() => {
    if (loadServices) {
      listFilteredServices(currentUser, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadServices, distance, serviceType, search, zipCode, listAllPostings]);

  useEffect(() => {
    if (showAB && canFetch) {
      const loadABServices = () => {
        if (externalServicesLoaded || !filters.get('serviceType') || !zipCode) {
          return false;
        }

        const aBServiceTypeList = ['food', 'housing', 'transit', 'education', 'legal', 'money', 'care', 'goods', 'health', 'work'];
        const aBServiceTypeSelected = aBServiceTypeList.includes(filters.get('serviceType'));
        return aBServiceTypeSelected;
      };

      if (loadABServices()) {
        const abQuery = filters.merge({ terms: search, zipCode });
        listFilteredABServicesForCandidate(currentUser, abQuery);
      } else {
        fetchExternalCandidateServices(currentUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceType, search, zipCode, externalServicesLoaded, showAB, canFetch]);

  const { newSearch, onSearchChange } = useSearch(search, setSearch, resourcesLoaded);

  let postingTiles;
  const allResourcesLoaded = resourcesLoaded && (!showAB || externalServicesLoaded);
  if (allResourcesLoaded && postings && postings.size > 0) {
    postingTiles = postings.map((posting) => {
      if (posting) {
        switch (posting.type) {
          case 'event':
            return <EventTile id={posting.id} key={`event-${posting.id}`} />;
          case 'service':
            return <ServiceTile id={posting.id} key={`service-${posting.id}`} isAbService={posting.ab_service && posting.ab_service} />;
          case 'program':
          default:
            return <ProgramTile id={posting.id} key={`program-${posting.id}`} />;
        }
      }
      return null;
    });
  } else if (allResourcesLoaded) {
    postingTiles = <h3>No resources found</h3>;
  } else {
    postingTiles = (
      <h3>
        <Spinner /> Please wait while we find the best resources for you
      </h3>
    );
  }

  return (
    <ResourcesListStyles>
      <div className="header">
        <div className="candidate-wrapper">
          <Grid container spacing={2}>
            <Grid item xs={12} md={listAllPostings ? 6 : 12}>
              <TextField
                id="search"
                placeholder="Search resources by name"
                margin="dense"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                value={newSearch}
                onChange={onSearchChange}
              />{' '}
            </Grid>
            {listAllPostings ? (
              <Grid item xs={12} md={6}>
                <ZipDistanceFilter
                  distanceFilterChoices={distanceFilterChoices}
                  sortByFilterChoices={sortByFilterChoices}
                  filters={filters}
                  setCandidateProgramsZipCode={setCandidateProgramsZipCode}
                  onChange={onChange}
                  zipCode={zipCode}
                  setCandidateProgramsSortBy={setCandidateProgramsSortBy}
                  setCandidateProgramsDistance={setCandidateProgramsDistance}
                  sortBy={sortBy}
                  distance={distance}
                />
              </Grid>
            ) : (
              <div />
            )}
            {listAllPostings ? (
              <Grid item md={12} xs={12}>
                <ResourceListFilters filters={filters} onChange={onChange} serviceTypeChoices={serviceTypeChoices} setServiceSelected={setServiceSelected} />
              </Grid>
            ) : (
              <div />
            )}
          </Grid>
        </div>
      </div>
      {/* <ProgramListFilters value={searchFilter} onChange={setSearchFilter} /> */}
      {canFetch ? (
        <div className="posting-wrapper">
          <div className="candidate-wrapper">
            <div className="posting-wrapper">
              {allResourcesLoaded ? <PageNavigator /> : ''}
              {postingTiles}
              {allResourcesLoaded ? <PageNavigator /> : ''}
            </div>
          </div>
        </div>
      ) : (
        <div className="user-instructions">
          <h2>
            <ArrowUpwardIcon /> Select a service type to get started
          </h2>
        </div>
      )}
    </ResourcesListStyles>
  );
};

ResourcesListView.propTypes = propTypes;
ResourcesListView.defaultProps = defaultProps;

export default ResourcesListView;
