import { connect } from 'react-redux';
import MessageFormView from './message_form_view';
import { getCurrentUser } from '../../../reducer';
import { createMessage } from '../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  createMessage,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => dispatchProps.createMessage(stateProps.currentUser, values),
  ...ownProps,
  ...stateProps,
});

const MessageFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(MessageFormView);

export default MessageFormContainer;
