import { connect } from 'react-redux';
import ContainerView from './container_view';
import { getCurrentUser, getOnboarding, getTreatment, getUserType, getCandidate } from '../../../reducer';
import { fetchCandidate } from '../../../solve/actions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  return {
    // prop assignments
    userType: getUserType(state),
    userName: currentUser.name,
    currentUser,
    companyName: currentUser.getIn(['staffOrganization', 'name']),
    candidate: currentUser.get('candidateId') && getCandidate(state, currentUser.get('candidateId')),
    onboarding: getOnboarding(state),
    signedIn: currentUser.signedIn,
    showPathways: !!getTreatment(state, 'showPathways'),
    isRisekit: !!getTreatment(state, 'isRisekit', true),
    cobrandingLogoUrl: getTreatment(state, 'cobrandingLogoUrl'),
  };
};

const mapDispatchToProps = {
  fetchCandidate,
};

const ContainerContainer = connect(mapStateToProps, mapDispatchToProps)(ContainerView);

export default ContainerContainer;
