import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import JobCandidateDetailsStyles from './job_candidate_details_styles';

import UserModel from '../../../session/models/user_model';
import CandidateApplicationModel from '../../../solve/models/candidate_application_model';

import Header from '../../../applicant/components/header';
import Tabber from '../../../ui/components/tabber';
import JobCandidateResume from '../job_candidate_resume';
// import JobCandidateApplications from '../job_candidate_applications';
// import JobCandidateNotifications from '../job_candidate_notifications';
import ReadOnlySurveyWizard from '../read_only_survey_wizard/read_only_survey_wizard';

const JobCandidateDetailsView = ({ currentUser, application, candidateApplicationId, fetchCandidateApplication }) => {
  if (!application) {
    fetchCandidateApplication(currentUser, candidateApplicationId);
  }
  const candidate = application && application.get('candidate');
  const candidateId = application && application.getIn(['candidate', 'candidateId']);

  return (
    <JobCandidateDetailsStyles>
      {application && (
        <React.Fragment>
          <Header
            name={`${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`}
            email={candidate.getIn(['user', 'email'])}
            phone={candidate.getIn(['user', 'phone'])}
            application={application}
          />
          <Tabber>
            <div tabLabel="Resume">
              <JobCandidateResume candidateId={candidateId} />
            </div>
            {/* <div tabLabel="Applications"> <JobCandidateApplications /></div> */}
            <div tabLabel="Screening Questions">
              {application.get('candidateSurveys').map((survey) => (
                <ReadOnlySurveyWizard survey={survey} key={survey.get('id')} />
              ))}
            </div>
            {/* <div tabLabel="Notifications" badge="3">
              <JobCandidateNotifications />
            </div> */}
          </Tabber>
        </React.Fragment>
      )}
    </JobCandidateDetailsStyles>
  );
};

JobCandidateDetailsView.propTypes = {
  application: PropTypes.oneOfType([PropTypes.instanceOf(CandidateApplicationModel), PropTypes.instanceOf(Map)]),
  fetchCandidateApplication: PropTypes.func.isRequired,
  candidateApplicationId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
};

JobCandidateDetailsView.defaultProps = {
  application: undefined,
};

export default JobCandidateDetailsView;
