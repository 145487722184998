import { connect } from 'react-redux';
import PathwayStepStatusView from './pathway_step_status_view';
import { getCurrentUser } from '../../../../reducer';
import { transitionPathwayStepStaffState, updatePathwayInstanceStep } from '../../../../solve/actions';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});
const mapDispatchToProps = {
  updatePathwayInstanceStep,
  transitionPathwayStepStaffState,
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onUpdate: (id, pis) => {
    dispatchProps.updatePathwayInstanceStep(stateProps.currentUser, id, pis);
  },
  onTransition: (id, state, extra) => {
    dispatchProps.transitionPathwayStepStaffState(stateProps.currentUser, id, state.substring(2), extra);
  },
  ...ownProps,
  ...stateProps,
});

const PathwayStepStatusContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayStepStatusView);

export default PathwayStepStatusContainer;
