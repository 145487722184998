import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';
import { ACTIVE } from '../../constants/options';

const EMPTY_SET = OrderedSet();

const DEFAULT_FILTERS = fromJS({
  state: ACTIVE,
});

const DEFAULT_STATE = fromJS({
  jobsLoading: false,
  jobsLoaded: 0,
  totalJobs: 0,
  jobsList: EMPTY_SET,
  filters: DEFAULT_FILTERS,
  zipCode: '',
  orgJobsLoading: false,
  orgJobsLoaded: 0,
  orgJobsList: EMPTY_SET,
  totalOrgJobs: 0,
});

const StaffJobListingsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_STAFF_JOB_LISTINGS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          jobsLoaded: false,
          orgJobsLoaded: false,
          // applicationsLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_JOB_LISTINGS_ZIP_CODE:
      if (state.get('zipCode') !== action.zipCode) {
        return state.merge({
          jobsLoaded: false,
          orgJobsLoaded: false,
          // applicationsLoaded: false,
          zipCode: action.zipCode,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_JOB_LISTINGS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          jobsLoaded: false,
          orgJobsLoaded: false,
          applicationsLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF.request:
      return state.set('jobsLoading', true);
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF.success:
      return state.merge({
        jobsList: OrderedSet(action.response.result),
        jobsLoading: false,
        jobsLoaded: Date.now(),
        totalJobs: action.response.totalJobs,
      });
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF.failure:
      return state.merge({
        jobsLoading: false,
        jobsLoaded: false,
        jobsList: EMPTY_SET,
        totalJobs: 0,
      });

    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF_ORG.request:
      return state.set('orgJobsLoading', true);
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF_ORG.success:
      return state.merge({
        orgJobsList: OrderedSet(action.response.result),
        orgJobsLoading: false,
        orgJobsLoaded: Date.now(),
        totalOrgJobs: action.response.totalJobs,
      });
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_STAFF_ORG.failure:
      return state.merge({
        orgJobsLoading: false,
        orgJobsLoaded: false,
        orgJobsList: EMPTY_SET,
        totalOrgJobs: 0,
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getZipCode = (state) => state.get('zipCode') || DEFAULT_STATE.get('zipCode');
export const getSearch = (state) => state.get('search');

export const getLoaded = (state) => state.get('jobsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getJobIds = (state) => state.get('jobsList') || DEFAULT_STATE.get('jobsList');
export const getTotalJobs = (state) => state.get('totalJobs') || DEFAULT_STATE.get('jobsList');

export const getOrgJobIds = (state) => state.get('orgJobsList') || DEFAULT_STATE.get('orgJobsList');
export const getOrgJobsLoaded = (state) => state.get('orgJobsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getTotalOrgJobs = (state) => state.get('totalOrgJobs') || DEFAULT_STATE.get('totalOrgJobs');

export default StaffJobListingsReducer;
