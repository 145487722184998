import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { Link, Redirect, useHistory } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import External from './external';
import { Button } from '../../../common/components';
import InviteApplicantsStyles from './invite_applicants_styles';

// import Internal from './internal';

const propTypes = {
  inviteCategory: PropTypes.string.isRequired,
  inviteType: PropTypes.string.isRequired,
  inviteInfo: PropTypes.shape({ get: PropTypes.func }),
  indeedInviteInfo: PropTypes.shape({ get: PropTypes.func }),
  invitePath: PropTypes.string.isRequired,
};

const defaultProps = {
  inviteInfo: new Map(),
  indeedInviteInfo: new Map(),
};

const InviteApplicantsView = ({ inviteCategory, inviteType, inviteInfo, indeedInviteInfo, invitePath }) => {
  const history = useHistory();

  if (!inviteInfo && !indeedInviteInfo) {
    return <Redirect to="." />;
  }

  const indeedJobKey = indeedInviteInfo?.get('jobkey');

  const navigateBack = () => {
    if (indeedJobKey) {
      push(`/staff/indeed-jobs/${indeedJobKey}`);
    }
    history.goBack();
  };

  return (
    <InviteApplicantsStyles>
      <Link to=".">
        <Button size="small" onClick={navigateBack} buttonType="back">
          <KeyboardBackspaceIcon />
          Back to {inviteCategory}
        </Button>
      </Link>
      {/* <Internal inviteType={inviteType} inviteInfo={inviteInfo} invitePath={invitePath} /> */}
      <External inviteCategory={inviteCategory} inviteType={inviteType} inviteInfo={inviteInfo} indeedInviteInfo={indeedInviteInfo} invitePath={invitePath} />
    </InviteApplicantsStyles>
  );
};

InviteApplicantsView.propTypes = propTypes;
InviteApplicantsView.defaultProps = defaultProps;

export default InviteApplicantsView;
