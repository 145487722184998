import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { Card } from '../../../common/styles/layout';
import JobCandidateResumeStyles from './job_candidate_resume_styles';
import { Spinner } from '../../../common/components';
import { useFetchOnce } from '../../../util/hooks';
import ResumeViewer from '../../../resume_viewer';
import DownloadResumeButton from '../../../resume_viewer/components/download_resume_button';

const JobCandidateResumeView = ({ resumes, currentUser, canEdit, listResumes, candidateId }) => {
  const fetchDone = useFetchOnce(currentUser, candidateId, listResumes);

  if (!fetchDone) {
    return <Spinner />;
  }

  if (!resumes.size) {
    return (
      <React.Fragment>
        <div>This candidate has not added a resume</div>
        <br />
        <Link to={`${candidateId}/resume/edit`}>
          <Button variant="contained" size="large">
            Create a new Resume
          </Button>
        </Link>
      </React.Fragment>
    );
  }

  return resumes.map((resume) => {
    if (!resume) {
      return '';
    }
    const candidate = resume.get('candidate');
    const liveResume = resume.get('liveResume');
    if (!liveResume) {
      // Still loading details...
      return <Spinner />;
    }

    return (
      <JobCandidateResumeStyles key={resume.get('id')}>
        <Grid container className="grid-wrapper" spacing={4}>
          <Grid item xs={12} md={6} lg={3}>
            <Card className="share-card">
              <img src="/images/resume.png" alt="Resume" width="50" />
              <h4>{`${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])} Resume`}</h4>
              <DownloadResumeButton candidate={candidate} resume={liveResume} />

              {canEdit && liveResume && liveResume.get('canUpdate') && (
                <React.Fragment>
                  <Link to={`/staff/candidates/${candidateId}/resume/edit`}>
                    <Button className="action-contained" fullWidth startIcon={<EditIcon />} variant="contained">
                      Edit resume
                    </Button>
                  </Link>
                  <br />
                  <br />
                </React.Fragment>
              )}
            </Card>
          </Grid>

          {/* Simulated PDF View */}
          <Grid item xs={12} lg={9}>
            <ResumeViewer candidate={candidate} liveResume={liveResume} />
          </Grid>
        </Grid>
      </JobCandidateResumeStyles>
    );
  });
};

JobCandidateResumeView.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  resume: PropTypes.instanceOf(Map),
};

JobCandidateResumeView.defaultProps = {
  resume: undefined,
};

export default JobCandidateResumeView;
