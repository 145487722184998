import { connect } from 'react-redux';

import { setOnboardingMode } from 'onboarding/actions';
import { resetRedirectPath } from 'public_link/actions';

import PublicContainerView from './public_container_view';
import { getCurrentUser, getRedirectPath, getTreatment } from '../../../reducer';

// import { SomeSelectors } from '../../../reducer';
// import { SomeActions } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
  isRisekit: !!getTreatment(state, 'isRisekit', true),
  redirectPath: getRedirectPath(state),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  resetRedirectPath,
  setOnboardingMode,
};

const PublicContainerContainer = connect(mapStateToProps, mapDispatchToProps)(PublicContainerView);

export default PublicContainerContainer;
