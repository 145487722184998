import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import ServiceView from './service_view';
import { fetchExternalCandidateServicesServices, fetchABService } from '../../../solve/actions';
import { getService, getCurrentUser, getExternalCandidateServiceByResourceId, getSystemEnumList } from '../../../reducer';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const service = getService(state, id);
  const serviceTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), service?.get('serviceTypes')));
  const targetTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), service?.get('targetParticipantTypes')));

  return {
    id,
    service,
    currentUser: getCurrentUser(state),
    externalService: getExternalCandidateServiceByResourceId(state, id),
    serviceTypes,
    targetTypes,
  };
};

const mapDispatchToProps = {
  fetchService: fetchABService,
  listExternalCandidateServices: fetchExternalCandidateServicesServices,
};

const ServiceContainer = connect(mapStateToProps, mapDispatchToProps)(ServiceView);

export default ServiceContainer;
