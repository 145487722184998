import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { startCase } from 'lodash';

import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import theme from '../../../common/theme';
import ProgramStyles, { MetaLabel, MetaValue } from './program_styles';

import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import ProgramModel from '../../../solve/models/program_model';
import formatPhoneNumber from '../../../util/format_phone_number';
import StatusToggler from '../status_toggler';

const propTypes = {
  programId: PropTypes.string.isRequired,
  fetchProgram: PropTypes.func.isRequired,
  publishProgram: PropTypes.func.isRequired,
  closeProgram: PropTypes.func.isRequired,
  draftProgram: PropTypes.func.isRequired,
  program: PropTypes.oneOfType([PropTypes.instanceOf(ProgramModel), PropTypes.instanceOf(Map)]),
  user: PropTypes.instanceOf(UserModel).isRequired,
  createPublicLink: PropTypes.func.isRequired,
  publicLink: PropTypes.instanceOf(Map),
  canEdit: PropTypes.bool,
};

const defaultProps = {
  program: undefined,
  publicLink: undefined,
  canEdit: false,
};

const ProgramView = ({ createPublicLink, publicLink, programId, user, canEdit, program, fetchProgram, publishProgram, closeProgram, draftProgram }) => {
  const resolved = useFetchOnce(user, programId, fetchProgram);

  useEffect(() => {
    createPublicLink(user, 'broadcast-program', programId);
  }, [user, programId, createPublicLink]);

  if (!program) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  let logoUrl;
  logoUrl = program.get('logoUrl');
  if (!logoUrl) {
    logoUrl = program.getIn(['postingOrganization', 'logoUrl']);
  }
  if (!logoUrl) {
    logoUrl = '/images/solve-programs.png';
  }

  return (
    <ProgramStyles>
      {/* TODO: Switch to use history.goBack with useHistory hook */}
      <Link to="/staff/programs-and-events">
        <Button color="primary" size="small" startIcon={<KeyboardBackspaceIcon />}>
          Back to resources
        </Button>
      </Link>
      {canEdit && (
        <span>
          {' '}
          |{' '}
          <Link to={`/staff/programs/${programId}/edit`}>
            <Button color="primary" size="small">
              Edit Program
            </Button>
          </Link>
        </span>
      )}
      &nbsp; | &nbsp; Share link: &nbsp;
      {publicLink && publicLink.get('publicUrl')}
      {canEdit && (
        <StatusToggler
          user={user}
          listingId={programId}
          currentState={program.get('state')}
          publish={publishProgram}
          close={closeProgram}
          save={draftProgram}
        />
      )}
      <hr />
      <div style={{ maxWidth: 800, margin: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <img src={logoUrl} className="program-image" alt="Program Logo" />
          </Grid>

          <Grid item xs={7} style={{ textAlign: 'right' }}>
            {canEdit && (
              <Link to={`/staff/programs/${programId}/participants`}>
                <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action, marginBottom: 10, width: 200 }}>
                  View Applicants
                </Button>
              </Link>
            )}
            <br />
            <Link to={`/staff/programs/${programId}/invite`}>
              <Button variant="outlined" color="secondary" size="large" style={{ borderColor: theme.color.action, color: theme.color.action, width: 200 }}>
                Invite Applicants
              </Button>
            </Link>
          </Grid>
        </Grid>
        <hr />
        <h2 style={{ margin: 0 }}>{program.get('name')}</h2>
        <em>{startCase(program.get('primaryServiceType'))}</em>
        <div style={{ height: 30 }} />

        <h3>Program Description</h3>
        <div dangerouslySetInnerHTML={{ __html: program.get('description') }} />

        <div style={{ height: 40 }} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MetaLabel>Hosting Organization</MetaLabel>
            <MetaValue>{program.getIn(['postingOrganization', 'name'])}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Average Salary</MetaLabel>
            <MetaValue>{program.get('estimatedSalary')}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Skills you will gain</MetaLabel>
            {program.get('gainedSkillTypes').map((skill) => (
              <MetaValue key={skill}>{startCase(skill)}</MetaValue>
            ))}
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Certifications you will gain</MetaLabel>
            {program.get('gainedCertifications').map((certification) => (
              <MetaValue key={certification}>{certification}</MetaValue>
            ))}
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Website</MetaLabel>
            <MetaValue>{program.get('applicationWebsite')}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Phone</MetaLabel>
            <MetaValue>{formatPhoneNumber(program.get('phone'))}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Industry</MetaLabel>
            <MetaValue>{startCase(program.get('industryType'))}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Program Starts</MetaLabel>
            <MetaValue>
              {moment(program.get('startDate')).format('Do MMM YYYY')}
              <br />
              {program.get('startTime')}
            </MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Program Days</MetaLabel>
            {program.get('days').map((day) => (
              <MetaValue key={day}>{day}</MetaValue>
            ))}
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Support Offered</MetaLabel>
            {program.get('supportTypes').map((support) => (
              <MetaValue key={support}>{startCase(support)}</MetaValue>
            ))}
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Cost</MetaLabel>
            <MetaValue>{`$${Math.round(program.get('cost'))}`}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Dress Code</MetaLabel>
            <MetaValue>{program.get('dresscode')}</MetaValue>
          </Grid>
        </Grid>

        <div style={{ height: 40 }} />

        {/* NOTE: Will need the api to return description for the hosting organization if we want to include that here */}
        {/* <h3>{`Learn More About ${program.getIn(['postingOrganization', 'name'])}`}</h3>
        <p>
          Description Placeholder
        </p> */}

        <div style={{ height: 40 }} />
      </div>
    </ProgramStyles>
  );
};

ProgramView.propTypes = propTypes;
ProgramView.defaultProps = defaultProps;

export default ProgramView;
