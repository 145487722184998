import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { fromJS, List } from 'immutable';
import Grid from '@material-ui/core/Grid';

import EditingStyles from './editing_styles';
import { preventEventBubbling } from '../../../../../util/common';
import { MuiThemeProvider, MuiFormTheme } from '../../../../../common/form';
import ReactSelect from '../../../../../common/form/redux_form/react_select';

const propTypes = {
  certifications: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, get: PropTypes.func }).isRequired,
  certificationOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

// TODO: Why are we including MuiThemeProvider at this point??
const EditingView = ({ certifications, certificationOptions }) => {
  const [newEntry, setNewEntry] = useState();
  const existingEntries = certifications.map((name, index) => certifications.get(index).getIn(['certification', 'name']));
  const selectOptions = List(certificationOptions)
    .filterNot((z) => existingEntries.includes(z[1]))
    .map((x) => ({ value: x[1], label: x[1] }));

  // const addToCertifications = () => {
  //   if (newEntry) {
  //     certifications.push(fromJS({ certification: { name: newEntry } }));
  //     setNewEntry(undefined);
  //   }
  // };

  const handleOnChange = (value) => {
    setNewEntry(value);
    certifications.push(fromJS({ certification: { name: value } }));
  };

  return (
    <MuiThemeProvider theme={MuiFormTheme}>
      <EditingStyles>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <form onSubmit={preventEventBubbling}>
              <ReactSelect input={{ name: 'addcertification', onChange: handleOnChange, value: newEntry }} options={selectOptions} />
            </form>
          </Grid>
          {/* <Grid item xs={4} sm={12} md={6}>
            <IconButton disabled={!newEntry} aria-label="submit" onClick={addToCertifications} className="add-button">
              <AddIcon fontSize="small" className="add-icon" /> Add Certification
            </IconButton>
          </Grid> */}
        </Grid>
      </EditingStyles>
    </MuiThemeProvider>
  );
};

EditingView.propTypes = propTypes;
EditingView.defaultProps = defaultProps;

export default EditingView;
