import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { OrderedSet, Map, List } from 'immutable';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { Button } from '../../../common/components';
import SettingsStyles, { Card } from './settings_styles';
import Spinner from '../../../common/components/spinner';
import settingsFormValidate from '../../../settings/settings_form_validate';
import { Field, MuiDatePicker, MuiSelect, MuiTextField, phoneMask, reduxForm, zipCodeMask } from '../../../common/form';

const propTypes = {
  pristine: PropTypes.bool.isRequired,
  candidate: PropTypes.instanceOf(Map),
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  emailValidated: PropTypes.bool.isRequired,
  reconfirmEmail: PropTypes.func.isRequired,
  genderTypes: PropTypes.instanceOf(OrderedSet),
  ethnicityTypes: PropTypes.instanceOf(OrderedSet),
};

const defaultProps = {
  candidate: undefined,
  genderTypes: new List(),
  ethnicityTypes: new List(),
};

const SettingsForm = ({ handleSubmit, reconfirmEmail, fetchCandidate, emailValidated, genderTypes, ethnicityTypes, pristine, submitting, candidate }) => {
  const history = useHistory();
  useEffect(() => {
    if (!candidate) {
      fetchCandidate();
    }
  }, [candidate, fetchCandidate]);

  return (
    <div className="container-wrapper">
      <SettingsStyles>
        <div className="candidate-wrapper" style={{ maxWidth: '850px' }}>
          {!candidate && <Spinner />}

          {candidate && (
            <Card>
              <form onSubmit={handleSubmit}>
                <h2>Settings</h2>
                <div className="fields-2">
                  <Field component={MuiTextField} label="First Name" name="userAttributes.firstName" margin="dense" variant="outlined" fullWidth required />
                  <Field component={MuiTextField} label="Last Name" name="userAttributes.lastName" margin="dense" variant="outlined" fullWidth required />
                </div>
                <div>
                  <Field
                    component={MuiTextField}
                    label="New Password (leave blank to stay the same)"
                    type="password"
                    name="userAttributes.password"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <div>
                  <Field
                    component={MuiTextField}
                    label="Confirm New Password"
                    type="password"
                    name="userAttributes.passwordConfirmation"
                    margin="dense"
                    variant="outlined"
                    fullWidth
                    required
                  />
                </div>
                <br />
                <div className="fields-2">
                  <Field component={MuiDatePicker} label="Birthday (mm-dd-yyyy)" name="birthday" margin="dense" variant="outlined" fullWidth required />
                  <Field
                    variant="outlined"
                    component={MuiTextField}
                    label="Zip Code"
                    name="addressAttributes.zip"
                    margin="dense"
                    fullWidth
                    required
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...zipCodeMask}
                  />
                </div>

                <div className="fields-2">
                  <Field
                    variant="outlined"
                    component={MuiTextField}
                    label="Phone Number"
                    name="userAttributes.phone"
                    margin="dense"
                    type="cell"
                    fullWidth
                    required
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...phoneMask}
                  />
                  <div className="column">
                    <Field
                      component={MuiTextField}
                      label="Email"
                      name="userAttributes.email"
                      type="email"
                      margin="dense"
                      variant="outlined"
                      fullWidth
                      disabled
                    />
                    {emailValidated ? (
                      <div className="email-validation">
                        <CancelIcon style={{ color: red[500] }} /> Email Not Confirmed.{' '}
                        <small>
                          <a
                            href="/"
                            onClick={(event) => {
                              event.preventDefault();
                              reconfirmEmail();
                              alert('Confirmation mail sent!');
                            }}
                          >
                            Resend Link
                          </a>
                        </small>
                      </div>
                    ) : (
                      <div className="email-validation">
                        <CheckCircleIcon style={{ color: green[500] }} /> Email Confirmed.
                      </div>
                    )}
                  </div>
                </div>

                <h4>Some additional information will help us suggest even better job opportunities and resources:</h4>

                <div className="fields-2">
                  <MuiSelect options={genderTypes} label="Gender" name="genderType" margin="dense" required />
                  <MuiSelect options={ethnicityTypes} label="Ethnicity Type" name="ethnicityType" margin="dense" required />
                </div>

                <div className="fields-2">
                  <Field component={MuiTextField} label="LinkedIn Url" name="linkedInUrl" margin="dense" variant="outlined" />
                  <MuiSelect
                    options={[
                      [true, 'Yes'],
                      [false, 'No'],
                    ]}
                    label="Have you been in the military"
                    name="military"
                    margin="dense"
                  />
                </div>
                <br />
                <Button
                  buttonType="primary"
                  type="submit"
                  disabled={pristine || submitting}
                  onClick={() => {
                    handleSubmit();
                    history.goBack();
                  }}
                >
                  Submit
                </Button>
              </form>
            </Card>
          )}
        </div>
      </SettingsStyles>
    </div>
  );
};

SettingsForm.propTypes = propTypes;
SettingsForm.defaultProps = defaultProps;

const SettingsView = reduxForm({
  form: 'settingsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: settingsFormValidate,
})(SettingsForm);

export default SettingsView;
