import styled from 'styled-components';
import theme from '../../../common/theme';

const ListingInvitationRowStyles = styled.tr`
  td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    padding: 10px;
  }
  a.resend {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
  a.invitation-link {
    display: flex;
    align-items: center;
    color: #777;
  }
  .invitation-posting {
    text-decoration: underline;
  }
  p {
    color: #777;
    font-size: 14px;
    padding: 0px;
    margin: 0px;
  }
  svg {
    width: 22px;
  }
`;

export const Status = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    margin-right: 6px;
  }
  .in-progress {
    opacity: 0.5;
  }
  .circle {
    width: 17px;
    padding: 0px 1px;
  }
`;

export const Pill = styled.span`
  height: 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px;
  border: solid 1px ${theme.color.grayLight};
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 11px;
  margin-right: 8px;
  color: ${theme.color.gray};
`;

export default ListingInvitationRowStyles;
