import TransitionNotificationHelper from './transition_notification_helper';

class PathwayNotificationHelper extends TransitionNotificationHelper {
  getUrl() {
    const id1 = this.getSubjectId();
    const id2 = this.getCandidatePathwayInstanceId();
    return this.urlTemplate.replace('<urlNode>', this.getUrlNode()).replace('<id1>', id1).replace('<id2>', id2);
  }

  getCandidatePathwayInstanceId() {
    return this.resource?.get('candidatePathwayInstanceId') || '';
  }
}

export default PathwayNotificationHelper;
