import React from 'react';
import { startCase } from 'lodash';
import { Map, List } from 'immutable';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DisplayStyles from './display_styles';

const propTypes = {
  certifications: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

const EMPTY_MAP = Map();

const DisplayView = ({ certifications }) => (
  <DisplayStyles>
    <div className="field">
      <div className="certifications-container">
        {certifications &&
          certifications
            .map((_, idx) => certifications.get(idx) || EMPTY_MAP)
            .map((certification, idx) => (
              <span key={certification} className="value certifications">
                {startCase(certification.getIn(['certification', 'name']))}
                <IconButton onClick={() => certifications.remove(idx)} aria-label="delete">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            ))}
      </div>
    </div>
  </DisplayStyles>
);

DisplayView.propTypes = propTypes;
DisplayView.defaultProps = defaultProps;

export default DisplayView;
