import axios from 'axios';
import env from '../app/app.env';
import { STATUS_CODE } from '../app/app.status';
import { withError, withData } from '../common/common.helper';

const axiosInstance = axios.create({
  baseURL: env.API_V1_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const CancelToken = axios.CancelToken;
let cancel: any;

axiosInstance.interceptors.request.use(
  (config) => {
    if (cancel) {
      cancel(STATUS_CODE.REQUEST_CANCEL);
    }
    config.cancelToken = new CancelToken(function executor(c) {
      cancel = c;
    });

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response: any): any => {
    const totalCount = response?.headers['x-total-count'] || 0;
    return withData(response.data, totalCount);
  },
  (error: any): any => {
    if (error.message === STATUS_CODE.NETWORK_ERROR) {
      return withError(error.message);
    }

    return withError(error.response?.data ? error.response.data : error);
  },
);

// FIXME: passing token with method will be changed later;
export function get(url: string, params: object = {}, token?: string, baseURL?: string): any {
  const headers = token ? { authorization: token } : undefined;
  return axiosInstance({
    method: 'get',
    url,
    params,
    headers,
    baseURL,
  });
}

export function post(url: string, data: any, token?: string, params?: any): any {
  const headers = token ? { authorization: token } : undefined;
  return axiosInstance({
    method: 'post',
    url,
    params,
    data,
    headers,
  });
}

export function put(url: string, data: any, token?: string): any {
  const headers = token ? { authorization: token } : undefined;

  return axiosInstance({
    method: 'put',
    url,
    data,
    headers,
  });
}

export function remove(url: string, params: object = {}, token?: string): any {
  const headers = token ? { authorization: token } : undefined;
  return axiosInstance({
    method: 'delete',
    url,
    params,
    headers,
  });
}
