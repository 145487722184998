import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const WithZendeskSupport = ({ children }) => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENABLE_TRACKERS === 'yes') {
    return (
      <React.Fragment>
        <Helmet>
          <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=3cc7d91d-3735-4185-aa87-7e1a6abdcca4"></script>
          <script>
            {`
            window.zESettings = {
              webWidget: {
                offset: {
                  horizontal: '44%',
                }
              }
            };
            `}
          </script>
        </Helmet>
        {children}
      </React.Fragment>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

WithZendeskSupport.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WithZendeskSupport;
