/* eslint-disable react/jsx-key */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import CheckIcon from '@material-ui/icons/CheckCircle';
// import IconButton from '@material-ui/core/IconButton';
// import StarBorderIcon from '@material-ui/icons/StarBorder';
// import ReplyIcon from '@material-ui/icons/Reply';
// import MoreVertIcon from '@material-ui/icons/MoreVert';
// import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { TextHeading } from '../../../common/components';
// import theme from '../../../common/theme';
import DashboardStyles, { ButtonBar } from './dashboard_styles';
// import TabberSolid from '../../../ui/components/tabber_solid';
import Notifications from '../../../notification/components/notifications';
import UserModel from '../../../session/models/user_model';
// import MessagesListInbox from '../messages_list_inbox';
// import MessagesListOutbox from '../messages_list_outbox';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  listMessages: PropTypes.func.isRequired,
  // unreadMessagesCount: PropTypes.number.isRequired,
};

const defaultProps = {};

const DashboardView = ({ currentUser, listMessages }) => {
  useEffect(() => {
    listMessages(currentUser);
  }, [listMessages, currentUser]);

  return (
    <DashboardStyles>
      <TextHeading margin="top" size="large">{`Welcome, ${currentUser.firstName}!`}</TextHeading>
      <hr />

      <ButtonBar>
        {/* TODO: Add Job Candidate functionality */}
        {/* <Button variant="contained" size="large" className="action-contained"> */}
        {/*  + Add Job Candidate */}
        {/* </Button> */}
        <Link to="/staff/resources/new">
          <Button variant="outlined" size="large" className="secondary-outlined">
            + Add Program/Event
          </Button>
        </Link>
        <Link to="/staff/jobs/new">
          <Button variant="outlined" size="large" className="secondary-outlined">
            + Add Job Posting
          </Button>
        </Link>
        {/* <Link to="/staff/organizations/new">
          <Button variant="outlined" size="large" className="secondary-outlined">
            + Add Employer
          </Button>
        </Link> */}
      </ButtonBar>

      {/* <header className="inboxHeader">
        <TextHeading margin="top" sizes="small" uppercase>
          Inbox <small>(NEW)</small>
        </TextHeading>
        <Button icon="upload" variant="outlined" size="large" className="secondary-outlined" startIcon={<CloudUploadIcon />}>
          Upload Participants
        </Button>
      </header> */}

      <div className="tabberWrapper">
        {/*  <TabberSolid>
          <div tabLabel="Notifications / Reminders"> */}
        <Notifications isLimited />
        {/* </div>
          <div tabLabel="New Programs &amp; Resources" badge="3">
            <h4>New Programs &amp; Resources</h4>
          </div>
          <div tabLabel="New Job Postings" badge="4">
            <h4>New Job Postings</h4>
          </div>
          <div tabLabel="Messages/Inbox" badge={unreadMessagesCount > 0 ? unreadMessagesCount : null}>
            <h4>Messages</h4>
            <hr />
            <Link to="/staff/messages/new">
              <Button variant="outlined" size="large" className="secondary-outlined">
                + Send A New Message
              </Button>
            </Link>
            <MessagesListInbox />
          </div>
          Messages Outbox feature
          <div tabLabel="Messages/Sent" badge={unreadMessagesCount > 0 ? unreadMessagesCount : null}>
            <h4>Messages</h4>
            <hr />
            <MessagesListOutbox />
          </div>
        </TabberSolid> */}
      </div>
    </DashboardStyles>
  );
};

DashboardView.propTypes = propTypes;
DashboardView.defaultProps = defaultProps;

export default DashboardView;
