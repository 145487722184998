import { connect } from 'react-redux';
import CandidateSettingsView from './candidate_settings_view';
// import { SomeSelectors } from '../../../reducer';
// import { SomeActions } from '../../actions';
import { fetchCandidate, updateCandidate, confirmEmail, reconfirmEmail } from '../../../solve/actions';
import { getCurrentUser, getCandidate, getSystemEnumList, getTreatment } from '../../../reducer';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (
  state,
  {
    match: {
      params: { id: candidateId },
    },
  },
) => {
  const user = getCurrentUser(state);
  const candidate = getCandidate(state, candidateId);

  return {
    user,
    candidateId,
    candidate,
    genderTypes: getSystemEnumList(state, 'GenderType').map((item) => [item.get('slug'), item.get('label')]),
    emailValidated: !(candidate.emailValidatedAt === '' || typeof candidate.emailValidatedAt === 'undefined'),
    ethnicityTypes: getSystemEnumList(state, 'EthnicityType').map((item) => [item.get('slug'), item.get('label')]),
    showInmates: !!getTreatment(state, 'showInmates'),
    initialValues: {
      birthday: candidate && candidate.get('birthday'),
      military: candidate && candidate.get('military'),
      linkedInUrl: candidate && candidate.get('linkedInUrl'),
      ethnicityType: candidate && candidate.get('ethnicityType'),
      genderType: candidate && candidate.get('genderType'),
      addressAttributes: {
        zip: candidate && candidate.getIn(['user', 'zip']),
      },
      userAttributes: {
        firstName: candidate && candidate.getIn(['user', 'firstName']),
        lastName: candidate && candidate.getIn(['user', 'lastName']),
        phone: candidate && candidate.getIn(['user', 'phone']),
        email: candidate && candidate.getIn(['user', 'email']),
      },
    },
  };
};

const mapDispatchToProps = {
  fetchCandidate,
  updateCandidate,
  confirmEmail,
  reconfirmEmail,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    const cleanAddress = values.get('addressAttributes').merge({
      address1: '',
      city: '',
      state: '',
      isPartialAddress: true,
    });
    const cleanValues = values.set('addressAttributes', cleanAddress);
    dispatchProps.updateCandidate(stateProps.user, stateProps.candidateId, cleanValues);
  },
  fetchCandidate: () => dispatchProps.fetchCandidate(stateProps.user, stateProps.candidateId),
  confirmEmail: () => dispatchProps.confirmEmail(stateProps.user),
  reconfirmEmail: () => dispatchProps.reconfirmEmail(stateProps.user),
  ...stateProps,
  ...ownProps,
});

const CandidateSettingsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CandidateSettingsView);

export default CandidateSettingsContainer;
