import React from 'react';
import { startCase } from 'lodash';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import SkillsDisplayStyles from './skills_display_styles';

const propTypes = {
  skills: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

const SkillsDisplayView = ({ skills }) => (
  <SkillsDisplayStyles>
    <div className="field">
      <div className="skills-container">
        {skills &&
          skills
            .map((_, idx) => skills.get(idx))
            .map((skill, idx) => (
              <span key={skill} className="value skills">
                {startCase(skill)}
                <IconButton onClick={() => skills.remove(idx)} aria-label="delete">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            ))}
      </div>
    </div>
  </SkillsDisplayStyles>
);

SkillsDisplayView.propTypes = propTypes;
SkillsDisplayView.defaultProps = defaultProps;

export default SkillsDisplayView;
