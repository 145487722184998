import { fromJS } from 'immutable';
// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const EMPTY_MAP = fromJS({});

const validateUserAttributes = (values = EMPTY_MAP) => {
  const errors = {};
  if (!values.get('firstName')) {
    errors.firstName = 'Required';
  }
  if (!values.get('lastName')) {
    errors.lastName = 'Required';
  }
  if (values && values.get('phone') && values.get('phone').match(/\d/g).length < 10) {
    errors.phone = 'Please enter a valid phone number';
  }
  if (!values.get('email')) {
    errors.primaryEmail = 'Required';
  }
  // TODO: validate email format for primaryEmail && alternateEmails.a
  return errors;
};

const profileFormValidate = (values) => {
  const errors = {};
  errors.userAttributes = validateUserAttributes(values.get('userAttributes'));
  if (values && values.get('workPhone') && values.get('workPhone').match(/\d/g).length < 10) {
    errors.workPhone = 'Please enter a valid phone number';
  }

  // TODO: formatting for workPhone and workPhoneExtension
  return errors;
};

export default profileFormValidate;
