import styled from 'styled-components';
import theme from '../../../../common/theme';

const NotesTileStyles = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0px;
`;

export const Person = styled.div`
  display: flex;
  flex-direction: row;
  h4 {
    margin: 0px;
    font-weight: normal;
  }
  p {
    margin: 0px;
    color: #c0c0c0;
    text-transform: uppercase;
    font-size: 0.8em;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
`;

export const Body = styled.div`
  padding: 10px 0px;
  h4 {
    margin: 0px;
    font-weight: normal;
    color: ${(props) => props.color || theme.color.secondaryAction};
    b {
      color: #000;
    }
  }
  p {
    margin: 0px;
    color: #c0c0c0;
  }
`;

export const Icon = styled.div`
  border-radius: 5px;
  background: ${(props) => props.color || '#F1F0F0'};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
  width: 33px;
  margin-right: 20px;
`;

export const Actions = styled.div``;

export const Footer = styled.div``;

export default NotesTileStyles;
