import React from 'react';
import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ProgramListStyles, { ResultsPageNavigator } from './program_list_styles';
import StaffProgramTile from './staff_program_tile';
import Spinner from '../../../common/components/spinner';

const propTypes = {
  programsLoaded: PropTypes.bool.isRequired,
  eventsLoaded: PropTypes.bool.isRequired,
  servicesLoaded: PropTypes.bool.isRequired,
  // abServicesLoaded: PropTypes.bool.isRequired,
  postingIds: PropTypes.instanceOf(OrderedSet).isRequired,
  totalCount: PropTypes.number.isRequired,
  setPageShown: PropTypes.func.isRequired,
  pageShown: PropTypes.number.isRequired,
  preFilter: PropTypes.string,
  isFetchingFilteredEvents: PropTypes.bool.isRequired,
  isFetchingFilteredPrograms: PropTypes.bool.isRequired,
  isFilteredServices: PropTypes.bool.isRequired,
  totalPostingCount: PropTypes.bool.isRequired,
};
const defaultProps = {
  preFilter: undefined,
};

const ProgramListView = ({
  totalPostingCount,
  programsLoaded,
  eventsLoaded,
  servicesLoaded,
  postingIds,
  totalCount,
  setPageShown,
  pageShown,
  preFilter,
  isFetchingFilteredEvents,
  isFetchingFilteredPrograms,
  isFilteredServices,
}) => {
  const totalPages = Math.ceil(totalPostingCount / 25) || 1;
  const scrollTop = () => window.scrollTo(0, 0);

  if (isFetchingFilteredEvents || isFetchingFilteredPrograms || isFilteredServices) {
    return (
      <ProgramListStyles>
        <Spinner />
      </ProgramListStyles>
    );
  }

  if (!postingIds || postingIds.size === 0) {
    if (eventsLoaded && programsLoaded && servicesLoaded) {
      return (
        <ProgramListStyles>
          <p>There currently no programs that match this filter</p>
        </ProgramListStyles>
      );
    }
    return (
      <ProgramListStyles>
        <Spinner />
      </ProgramListStyles>
    );
  }

  const getNextPageResults = () => {
    setPageShown(pageShown + 1);
    scrollTop();
  };
  const getPrevPageResults = () => {
    setPageShown(pageShown - 1);
    scrollTop();
  };

  const PageNavigator = () => {
    if (totalCount < 25) {
      return null;
    }
    return (
      <ResultsPageNavigator>
        <div className="prev-next-container">
          <Button color="secondary" startIcon={<NavigateBeforeIcon />} disabled={pageShown <= 0} onClick={() => getPrevPageResults()}>
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" color="secondary">{`Page ${pageShown + 1} of ${totalPages}`}</Typography>
          <Button color="secondary" endIcon={<NavigateNextIcon />} disabled={pageShown >= totalPages - 1} onClick={() => getNextPageResults()}>
            Next
          </Button>
        </div>
      </ResultsPageNavigator>
    );
  };

  const postingTileList = OrderedSet(postingIds.filter(Boolean)).map((postingId) => (
    <StaffProgramTile id={postingId.id} key={postingId.id} type={postingId.type} />
  ));

  return (
    <ProgramListStyles>
      <PageNavigator />
      <table>
        <thead>
          <tr>
            <th width="60">&nbsp;</th>
            <th className="table-heading">Program/Event Post</th>
            <th className="table-heading" width={preFilter ? '350' : 'auto'}>
              Program/Event Type
            </th>
            <th className="table-heading">Zip Code</th>
            <th className="table-heading">Target Population</th>
            <th className="table-heading">{preFilter && 'Status'}</th>
            <th width="50">&nbsp;</th>
            <th width="50">&nbsp;</th>
          </tr>
        </thead>
        <tbody>{postingTileList}</tbody>
      </table>
      <PageNavigator />
    </ProgramListStyles>
  );
};

ProgramListView.propTypes = propTypes;
ProgramListView.defaultProps = defaultProps;

export default ProgramListView;
