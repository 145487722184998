import React from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';
import MuiTextField from './mui_text_field';

const MuiTextFieldArrayStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > span {
    > div {
      width: calc(100% - 50px);
      max-width: 400px;
    }

    button {
      margin-top: 30px;
    }
  }
`;

const propTypes = {
  label: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string }).isRequired,
  fields: PropTypes.shape([]),
};

const defaultProps = {
  label: undefined,
  fields: undefined,
};

const MuiTextFieldArray = ({ label, fields, meta: { error }, ...rest }) => (
  <MuiTextFieldArrayStyles>
    {fields.map((name, index) => (
      <span key={name}>
        <Field name={name} type="text" margin="none" component={MuiTextField} {...rest /* eslint-disable-line react/jsx-props-no-spreading */} />
        <IconButton margin="" aria-label="cancel" onClick={() => fields.remove(index)} className="cancel">
          <CancelIcon fontSize="small" />
        </IconButton>
      </span>
    ))}
    {error && <span className="error">{error}</span>}
    <div className="add-item">
      <IconButton aria-label="cancel" onClick={() => fields.push()}>
        <AddIcon fontSize="small" />
      </IconButton>
      {label}
    </div>
  </MuiTextFieldArrayStyles>
);

MuiTextFieldArray.propTypes = propTypes;
MuiTextFieldArray.defaultProps = defaultProps;

export default MuiTextFieldArray;
