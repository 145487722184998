import { connect } from 'react-redux';
import EthnicityReportView from './ethnicity_report_view';
import { listCandidatePathwaysForPathwayInstance } from '../../../../../solve/actions';
import { getCandidatePathwaysForPathwayInstance } from '../../../../../reducer';
import { generateEthnicityReportDetails } from '../../../../../util/generate_pathway_reports';

const mapStateToProps = (state, { pathwayInstance }) => {
  const candidatePathways = getCandidatePathwaysForPathwayInstance(state, pathwayInstance.get('id'));
  return { reportDetails: generateEthnicityReportDetails(candidatePathways) };
};

const mapDispatchToProps = {
  listCandidatePathwaysForPathwayInstance,
};

const EthnicityReportContainer = connect(mapStateToProps, mapDispatchToProps)(EthnicityReportView);

export default EthnicityReportContainer;
