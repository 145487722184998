import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FlagIcon from '@material-ui/icons/Flag';
import { fromJS, List, Map } from 'immutable';
import { connect, useSelector } from 'react-redux';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { getCandidateSurveyForSurvey, getCurrentUser, getEnumsForFormSelect } from '../../../reducer';
import { reduxForm, formValueSelector, Field, MuiCheckbox, MuiRadioGroup, MuiSelect, MuiTextField, changeReduxFormField } from '../../../common/form';
import Progress from '../../../ui/components/progress';
import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import Button from '../../../common/components/button';
import UserModel from '../../../session/models/user_model';
import { startSurvey, updateCandidateSurvey } from '../../../solve/actions';
import CandidateSurveyModel from '../../../solve/models/candidate_survey_model';
import { SurveyWizardStyles, QuestionPromptStyles } from './survey_wizard_styles';

import * as toast from 'risekit/common/components/toast';

const EMPTY_MAP = fromJS({});

const QuestionPrompt = ({ question, needsApproval }) => {
  const templatePrompt = question.getIn(['questionTemplate', 'question']);
  const questionImage = question.getIn(['questionTemplate', 'iconUrl']);
  const questionTitle = question.getIn(['questionTemplate', 'title']);
  // Convert from Array of Maps [ { "<0>": "banana" }, { "<1>": 28 } ] -> Map { "<0>": "banana", "<1>": 28 }
  // const placeholders = question.getIn(['extra', 'questionPlaceholders'], EMPTY_LIST).reduce((a, b) => a.merge(b), EMPTY_MAP);
  const placeholdersUnfiltered = question.getIn(['extra', 'questionPlaceholders'], EMPTY_MAP);
  const placeholders = List.isList(placeholdersUnfiltered) ? placeholdersUnfiltered.reduce((a, b) => a.merge(b), EMPTY_MAP) : placeholdersUnfiltered;
  let prompt;
  if (placeholders && placeholders.size > 0) {
    prompt = placeholders.reduce((str, value, placeholder) => str.replace(placeholder, value), templatePrompt);
  } else {
    prompt = templatePrompt;
  }
  return (
    <QuestionPromptStyles>
      {questionImage ? (
        <div>
          <img src={questionImage} alt={questionTitle} />
          {questionTitle ? <h3>{questionTitle}</h3> : ''}
        </div>
      ) : (
        ''
      )}
      {needsApproval && (
        <div>
          <FlagIcon style={{ color: 'red' }} /> Please review the answer and make any required changes, and click Next Step below to save your survey.
        </div>
      )}
      <h4>{prompt}</h4>
    </QuestionPromptStyles>
  );
};
QuestionPrompt.propTypes = {
  question: PropTypes.instanceOf(Map).isRequired,
  needsApproval: PropTypes.bool.isRequired,
};

const useChoices = (template) => {
  const enumId = template.getIn(['extra', 'enumId']);
  const templateAttribute = template.getIn(['howToApplyAnswer', 'attribute']);

  const enumList = useSelector((state) => getEnumsForFormSelect(state, enumId));

  if (enumId && templateAttribute === 'current_employment_job_type') {
    const unemployedOption = ['currently-unemployed', 'Currently unemployed'];

    return [...enumList, unemployedOption];
  }

  if (enumId) {
    return enumList;
  }

  return template
    .get('answers')
    .map((x) => (typeof x === 'string' ? [x, x] : x))
    .toList();
};

const choiceRendererDropdownPropTypes = {
  answerId: PropTypes.string.isRequired,
};

const choiceRendererPropTypes = {
  template: PropTypes.instanceOf(Map).isRequired,
  answerId: PropTypes.string.isRequired,
};

const SingleChoiceRenderer = ({ template, answerId }) => {
  const choices = useChoices(template);

  return (
    <div>
      <Field name={`answer.${answerId}`} component={MuiRadioGroup}>
        <FormGroup>
          {choices.map((choice) => (
            <FormControlLabel key={choice[0]} value={choice[0]} control={<Radio color="primary" />} label={choice[1]} labelPlacement="end" />
          ))}
        </FormGroup>
      </Field>
    </div>
  );
};
SingleChoiceRenderer.propTypes = choiceRendererPropTypes;

const DropdownYesNoRenderer = ({ answerId }) => {
  const choices = fromJS(['Yes', 'No']).map((x) => [x, x]);

  return (
    <div>
      <Field name={`answer.${answerId}`} component={MuiRadioGroup}>
        <FormGroup>
          {choices.map((choice) => (
            <FormControlLabel key={choice[0]} value={choice[0]} control={<Radio color="primary" />} label={choice[1]} labelPlacement="end" />
          ))}
        </FormGroup>
      </Field>
    </div>
  );
};
DropdownYesNoRenderer.propTypes = choiceRendererDropdownPropTypes;

const DropdownChoiceRenderer = ({ template, answerId }) => {
  const choices = useChoices(template);
  return (
    <div>
      <MuiSelect options={choices} name={`answer.${answerId}`} margin="dense" />
    </div>
  );
};
DropdownChoiceRenderer.propTypes = choiceRendererPropTypes;

const MultiChoiceRenderer = ({ template, answerId }) => {
  const choices = useChoices(template);
  return (
    <div>
      <FormGroup>
        {choices.map((choice) => (
          <FormControlLabel key={choice[0]} control={<Field component={MuiCheckbox} name={`answer.${answerId}.${choice[0]}`} />} label={choice[1]} />
        ))}
      </FormGroup>
    </div>
  );
};
MultiChoiceRenderer.propTypes = choiceRendererPropTypes;

const FreeTextChoiceRenderer = ({ answerId }) => (
  <div>
    <Field name={`answer.${answerId}`} component={MuiTextField} margin="dense" variant="outlined" fullWidth />
  </div>
);
FreeTextChoiceRenderer.propTypes = {
  answerId: PropTypes.string.isRequired,
};

// For Questions where answer is derived from Candidate Profile (eg, minimum age)
const BlankRendererView = ({ change, answerId }) => {
  change('candidateSurvey', `answer.${answerId}`, 'none');
  return '';
};
BlankRendererView.propTypes = {
  change: PropTypes.func.isRequired,
  answerId: PropTypes.string.isRequired,
};
const BlankRenderer = connect(undefined, { change: changeReduxFormField })(BlankRendererView);

const CHOICE_RENDERERS = {
  'single-choice': SingleChoiceRenderer,
  dropdown: DropdownChoiceRenderer,
  'dropdown-yes-no': DropdownYesNoRenderer,
  'multi-choice': MultiChoiceRenderer,
  'free-text': FreeTextChoiceRenderer,
  none: BlankRenderer,
};
const unknownAnswerTypeRenderer = (type) => () => `Unknown question type: "${type}"`;

const SingleChoiceIngester = (list) => list.get(0);
const MultiChoiceIngester = (list) => Map(list.map((item) => [item, true]));
const ANSWER_CONVERTERS = {
  'single-choice': SingleChoiceIngester,
  dropdown: SingleChoiceIngester,
  'dropdown-yes-no': SingleChoiceIngester,
  'multi-choice': MultiChoiceIngester,
  'free-text': SingleChoiceIngester,
};

const SurveyQuestionRenderer = ({ question, answerId, needsApproval }) => {
  const type = question.getIn(['questionTemplate', 'answerType']);
  const ChoiceRenderer = CHOICE_RENDERERS[type] || unknownAnswerTypeRenderer(type);
  return (
    <div className="something">
      <QuestionPrompt question={question} needsApproval={needsApproval} />
      <ChoiceRenderer template={question.get('questionTemplate')} answerId={answerId} />
    </div>
  );
};
SurveyQuestionRenderer.propTypes = {
  question: PropTypes.instanceOf(Map).isRequired,
  answerId: PropTypes.string.isRequired,
  needsApproval: PropTypes.bool.isRequired,
};

const ProgressViewRender = ({ formValues, color }) => {
  const checkNonEmpty = (value) => value && !(typeof value === 'object' && value.size === 0);

  const percentage = Math.ceil((formValues.filter(checkNonEmpty).length / formValues.length) * 100);
  const percentLabel = `${formValues.filter(checkNonEmpty).length}/${formValues.length}`;

  return <Progress percent={percentage} percentLabel={percentLabel} color={color} />;
};

ProgressViewRender.propTypes = {
  formValues: PropTypes.instanceOf([]).isRequired,
  color: PropTypes.string.isRequired,
};

const ProgressView = connect((state, { sortedQuestions, color }) => {
  const selector = formValueSelector('candidateSurvey');

  const formValues = [];
  // eslint-disable-next-line array-callback-return
  sortedQuestions.map((data) => {
    const answerId = data.getIn(['surveyAnswer', 'id']).toString();
    const question = selector(state, `answer.${answerId}`);
    formValues.push(question);
  });

  return {
    formValues,
    color,
  };
})(ProgressViewRender);

const getSectionColor = (currentSurvey) => {
  switch (currentSurvey) {
    case 0:
      return '#67B4FF';
    case 1:
      return '#FFBF76';
    case 2:
      return '#7DBF9D';
    case 3:
      return '#B787F6';
    default:
      return '#000000';
  }
};
getSectionColor.propTypes = {
  currentSurvey: PropTypes.number.isRequired,
};

const SurveyView = ({ survey, handleSubmit, handlePrevious, currentSurvey }) => {
  const sortedQuestions = survey.get('surveyAnswers').sortBy((item) => item.getIn(['surveyQuestion', 'position']));
  const formName = survey.getIn(['survey', 'title']).toLowerCase().replace(/ /g, '_');

  const surveyColor = getSectionColor(currentSurvey);

  switch (survey.getIn(['survey', 'surveyType'])) {
    case 'challenges':
    case 'readiness':
      return (
        <SurveyWizardStyles>
          <form onSubmit={handleSubmit} name={formName}>
            <div className="form-header">
              <div className="challenges-title">
                <img src={survey.getIn(['survey', 'iconUrl'])} alt="" />
                <h2 style={{ color: surveyColor }}>{survey.getIn(['survey', 'title'])}</h2>
              </div>
              {currentSurvey > 0 && (
                <IconButton className="button-left" onClick={handlePrevious}>
                  <ArrowBackIosIcon />
                </IconButton>
              )}
              <IconButton className="button-right" onClick={handleSubmit}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
            <div className="progress">
              <ProgressView formName={formName} sortedQuestions={sortedQuestions} color={surveyColor} />
            </div>
            <div className="questions">
              {sortedQuestions.map((data) => {
                const id = data.getIn(['surveyAnswer', 'id']).toString();
                const needsApproval = data.getIn(['surveyAnswer', 'needsApproval']);
                return (
                  <div className="question" key={id}>
                    <SurveyQuestionRenderer key={id} question={data.get('surveyQuestion')} needsApproval={needsApproval} answerId={id} />
                  </div>
                );
              })}
            </div>
            <div className="button-group">
              <div className="button-left">
                {currentSurvey > 0 && (
                  <Button buttonType="secondary-outline" type="button" onClick={handlePrevious}>
                    Previous
                  </Button>
                )}
              </div>
              <div className="button-right">
                <Button buttonType="secondary" type="submit">
                  Next Step
                </Button>
              </div>
            </div>
          </form>
        </SurveyWizardStyles>
      );

    default:
      return (
        <form onSubmit={handleSubmit}>
          <h3>Please answer the questions this employer requires</h3>
          {sortedQuestions.map((data) => {
            const id = data.getIn(['surveyAnswer', 'id']).toString();
            return <SurveyQuestionRenderer key={id} question={data.get('surveyQuestion')} answerId={id} />;
          })}
          <Button buttonType="primary" type="submit" style={{ marginTop: '35px' }}>
            Submit Questions NOW
          </Button>
        </form>
      );
  }
};
SurveyView.propTypes = {
  survey: PropTypes.oneOfType([PropTypes.instanceOf(CandidateSurveyModel), PropTypes.instanceOf(Map)]).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  currentSurvey: PropTypes.number.isRequired,
};

const SurveyForm = reduxForm({ form: 'candidateSurvey' })(SurveyView);

const buildAPIAnswers = (survey, values) => {
  const surveyAnswersAttributes = survey.get('surveyAnswers').map((data) => {
    const answerId = data.getIn(['surveyAnswer', 'id']).toString();
    const rawAnswer = values.getIn(['answer', answerId]);
    let answer = rawAnswer;
    if (typeof rawAnswer === 'string') {
      answer = [rawAnswer];
    } else if (Map.isMap(rawAnswer)) {
      answer = rawAnswer
        .filter((x) => x)
        .keySeq()
        .toList();
    }
    return {
      id: answerId,
      answer: answer || [],
      isSkipped: answer === undefined,
    };
  });
  return { candidateSurvey: { surveyAnswersAttributes } };
};

const buildInitialValues = (survey) => {
  const initial = {};
  survey.get('surveyAnswers').forEach((answer) => {
    const id = answer.getIn(['surveyAnswer', 'id']).toString();
    const rawAnswer = answer.getIn(['surveyAnswer', 'answer']);
    const questionType = answer.getIn(['surveyQuestion', 'questionTemplate', 'answerType']);
    const converter = ANSWER_CONVERTERS[questionType] || MultiChoiceIngester;
    const rawAnswerList = List.isList(rawAnswer) ? rawAnswer : List([rawAnswer]);
    initial[id] = converter(rawAnswerList);
  });
  return { answer: initial };
};

const SurveyManager = ({ user, id, startSurvey: fetchSurvey, updateCandidateSurvey: saveSurvey, survey, onSubmit, onPrevClick, currentSurvey }) => {
  const resolved = useFetchOnce(user, id, fetchSurvey);
  const handleSubmit = useCallback(
    (values) => {
      saveSurvey(user, survey.get('id'), buildAPIAnswers(survey, values))
        .then(() => {
          onSubmit(survey.get('id'));
        })
        .catch(() => {
          return toast.error({ title: 'Error', message: 'Error while saving the survey.' });
        });
    },
    [user, survey, saveSurvey, onSubmit],
  );
  const handlePrevious = useCallback(() => {
    // TODO: handle save on prev
    // saveSurvey(user, survey.get('id'), buildAPIAnswers(survey, values));
    onPrevClick(survey.get('id'));
  }, [survey, onPrevClick]);
  if (!resolved || !survey || !survey.get('surveyAnswers')) {
    return <Spinner />;
  }

  return (
    <SurveyForm
      survey={survey}
      canEdit={user.permissions && user.permissions.includes('edit_challenges')}
      canView={user.permissions && user.permissions.includes('view_challenges')}
      onSubmit={handleSubmit}
      handlePrevious={handlePrevious}
      currentSurvey={currentSurvey}
      initialValues={buildInitialValues(survey)}
    />
  );
};
SurveyManager.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  id: PropTypes.string.isRequired,
  startSurvey: PropTypes.func.isRequired,
  updateCandidateSurvey: PropTypes.func.isRequired,
  survey: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(CandidateSurveyModel)]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPrevClick: PropTypes.func.isRequired,
  currentSurvey: PropTypes.number.isRequired,
};

const Survey = connect(
  (state, { id }) => ({
    user: getCurrentUser(state),
    survey: getCandidateSurveyForSurvey(state, id),
  }),
  { startSurvey, updateCandidateSurvey },
)(SurveyManager);

const EMPTY_ANSWERS = fromJS([]);
const SurveyWizard = ({ surveys, onComplete, onSurveySubmitted }) => {
  const [currentSurvey, setCurrentSurvey] = useState(0);
  const [answers, setAnswers] = useState(EMPTY_ANSWERS);
  const nextSurvey = useCallback(
    (results = {}) => {
      if (onSurveySubmitted) {
        onSurveySubmitted(results);
      }
      setAnswers(answers.merge(results));
      setCurrentSurvey(currentSurvey + 1);
    },
    [answers, currentSurvey, onSurveySubmitted],
  );
  const prevSurvey = useCallback(
    (results = {}) => {
      setAnswers(answers.merge(results));
      setCurrentSurvey(currentSurvey - 1);
    },
    [answers, currentSurvey],
  );
  useEffect(() => {
    if (currentSurvey >= surveys.size) {
      onComplete(answers);
    }
  }, [currentSurvey, surveys.size, onComplete, answers]);
  if (currentSurvey >= surveys.size) {
    return <Spinner />;
  }

  return (
    <div>
      <Survey id={surveys.get(currentSurvey).get('id')} onSubmit={nextSurvey} onPrevClick={prevSurvey} currentSurvey={currentSurvey} />
    </div>
  );
};
SurveyWizard.propTypes = {
  surveys: PropTypes.instanceOf(List).isRequired,
  onComplete: PropTypes.func.isRequired,
  onSurveySubmitted: PropTypes.func,
};
SurveyWizard.defaultProps = {
  onSurveySubmitted: undefined,
};

export default SurveyWizard;
