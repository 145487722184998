import styled from 'styled-components';
// import theme from '../../../common/theme';

const ListStaffRowStyles = styled.tr`
  text-align: center;
  td {
    padding: 0px 16px;
  }
  td label {
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export default ListStaffRowStyles;
