import React from 'react';
import moment from 'moment';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import StarIcon from '@material-ui/icons/Star';
import CheckIcon from '@material-ui/icons/Check';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';

import MyStatus from '../my_status';
import UserModel from '../../../session/models/user_model';
import { ExpandableText } from '../../../common/components';
import Button from '../../../common/components/button/index';
import getListingImage from '../../../common/components/get_listing_image';
import StatusUpdateTile from '../../../solve/components/status_update_tile';
import IndeedJobPostingModel from '../../../solve/models/indeed_job_posting_model';
import { TileWrapper, TileStyles, Header, Details, Logo, Status, Body, Pill, Title, Organization, Footer, ButtonGroup } from '../../../common/styles/tile';

import * as toast from 'risekit/common/components/toast';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  // id: PropTypes.string.isRequired,
  job: PropTypes.oneOfType([PropTypes.instanceOf(IndeedJobPostingModel), PropTypes.instanceOf(Map)]),
  indeedCandidateApplication: PropTypes.shape({
    get: PropTypes.func,
    candidateState: PropTypes.string,
  }),
  createIndeedCandidateApplication: PropTypes.func.isRequired,
  deleteIndeedCandidateApplication: PropTypes.func.isRequired,
  transitionIndeedCandidateApplicationByCandidate: PropTypes.func.isRequired,
  hideActions: PropTypes.bool,
  isCandidateResumeEmpty: PropTypes.bool.isRequired,
};

const defaultProps = {
  job: undefined,
  indeedCandidateApplication: undefined,
  hideActions: false,
};

const IndeedTileView = ({
  currentUser,
  job,
  indeedCandidateApplication,
  createIndeedCandidateApplication,
  deleteIndeedCandidateApplication,
  transitionIndeedCandidateApplicationByCandidate,
  hideActions,
  isCandidateResumeEmpty,
}) => {
  const history = useHistory();

  if (job === undefined) {
    return null;
  }

  const logoUrl = getListingImage(job, 'job');

  const candidateState = indeedCandidateApplication && indeedCandidateApplication.get('candidateState');
  const applied = ![undefined, 'c_deleted', 'c_saved'].includes(candidateState);
  const jobUrl = job.get('url');

  const openIndeedJobPortal = () => {
    if (isCandidateResumeEmpty) {
      return toast.error({
        title: 'Resume Empty',
        message: 'Please complete your resume so you can apply for this job.',
        extraSettingParams: {
          timeout: 8000,
          displayMode: 'replace',
          buttons: [
            [
              '<button>Complete Resume</button>',
              (instance, toastInstance) => {
                history.push('/candidate/profile');

                instance.hide({}, toastInstance, 'Resume Incomplete');
              },
            ],
          ],
        },
      });
    }

    if (jobUrl) {
      window.open(jobUrl, '_blank');
    }
    return false;
  };

  return (
    <TileWrapper>
      <StatusUpdateTile
        record={indeedCandidateApplication}
        setCandidateState={(state) => transitionIndeedCandidateApplicationByCandidate(currentUser, indeedCandidateApplication.get('id'), state)}
      />
      <TileStyles>
        <Logo>
          <img src={logoUrl} alt="Job Posting Logo" />
          <span id="indeed_at">
            <a href="https://www.indeed.com/" rel="nofollow noreferrer">
              jobs
            </a>
            by
            <a href="https://www.indeed.com/" rell="nofollow" title="Job Search">
              <img src="https://www.indeed.com/p/jobsearch.gif" style={{ border: 0, verticalAlign: 'middle' }} alt="Indeed job search" />
            </a>
          </span>
          <Status>{applied && <MyStatus candidateApplication={indeedCandidateApplication} />}</Status>
        </Logo>

        <Header>
          <Pill>Job</Pill>
          <Details>
            {job.get('date') && job.get('date') !== 'Invalid date' && (
              <span className="detail-info">
                <ScheduleIcon />
                Posted {`${moment.utc(job.get('date')).fromNow()}`}
              </span>
            )}
            {!!job.get('distance') && (
              <span className="detail-info">
                <LocationOnOutlinedIcon />
                {`${Math.round(parseFloat(job.get('distance')) * 10) / 10} mi`}
              </span>
            )}
          </Details>
        </Header>

        <Body>
          <Title>{job.get('jobtitle')}</Title>
          <Organization>{job.get('company')}</Organization>
          <ExpandableText>
            <div dangerouslySetInnerHTML={{ __html: job.get('snippet') }} />
          </ExpandableText>
        </Body>
        <br />
        <Footer className="jobs">
          {/* <Services>
            {opportunityTypes && (
              <div className="service">
                <LabelOutlinedIcon />
                <span>
                  <span className="heading">Benefits: </span>
                  <span className="body">{opportunityTypes.map((item) => item[1]).join(', ')}</span>
                </span>
              </div>
            )}
          </Services> */}
          {!hideActions && (
            <ButtonGroup>
              <div className="subtle-buttons">
                <Button
                  buttonType="subtle-outline"
                  onClick={candidateState && candidateState === 'c_saved' ? deleteIndeedCandidateApplication : createIndeedCandidateApplication}
                >
                  {candidateState && candidateState === 'c_saved' ? (
                    <React.Fragment>
                      <CheckIcon />
                      Saved
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <StarIcon />
                      Save
                    </React.Fragment>
                  )}
                </Button>
              </div>
              <Button buttonType="secondary-outline" onClick={openIndeedJobPortal}>
                See Details
                <ChevronRightIcon />
              </Button>
            </ButtonGroup>
          )}
        </Footer>
      </TileStyles>
    </TileWrapper>
  );
};

IndeedTileView.propTypes = propTypes;
IndeedTileView.defaultProps = defaultProps;

export default IndeedTileView;
