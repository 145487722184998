import { connect } from 'react-redux';
import DashboardView from './dashboard_view';
import { getCurrentUser, getUnreadMessagesCount, getReadMessages, getUnreadMessages, getUserType } from '../../../reducer';
import { listMessages } from '../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  userType: getUserType(state),
  currentUser: getCurrentUser(state),
  readMessages: getReadMessages(state),
  unreadMessages: getUnreadMessages(state),
  unreadMessagesCount: getUnreadMessagesCount(state) || 0,
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })

const mapDispatchToProps = {
  listMessages,
};

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardView);

export default DashboardContainer;
