import React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Link, Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
// import Step from '@material-ui/core/Step';
import Select from '@material-ui/core/Select';
// import Stepper from '@material-ui/core/Stepper';
import MenuItem from '@material-ui/core/MenuItem';
// import StepLabel from '@material-ui/core/StepLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

import EmailIcon from '@material-ui/icons/AlternateEmail';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../common/theme';
import { ReactComponent as StepIcon } from '../../../common/assets/svgs/stepper_icon.svg';

import PathwayCandidateDetailsStyles from './pathway_candidate_details_styles';

import { useFetchOnce } from '../../../util/hooks';

import CandidateStep from './candidate_step';
import UserModel from '../../../session/models/user_model';

import { Button } from '../../../common/components';
import Spinner from '../../../common/components/spinner';

const candidateStates = [
  { label: 'Applied', value: 's_applied' },
  { label: 'In Progress', value: 's_in_progress' },
  { label: 'Completed', value: 's_complete' },
  { label: 'Dropped Voluntarily', value: 's_dropped_voluntarily' },
  { label: 'Dropped Involuntarily', value: 's_dropped_involuntarily' },
  { label: 'Saved', value: 's_saved' },
];

// const mockMilestones = ['Milestone 1', 'Milestone 2', 'Milestone 3', 'Milestone 4', 'Milestone 5', 'Milestone 6', 'Milestone 7'];

const useStyles = makeStyles(() => ({
  header: {
    padding: '20px 30px',
    backgroundColor: '#f2faff',
  },
  candidateInfo: {
    margin: '6px 0px',
    fontSize: '0.9em',
    '& svg': {
      fontSize: '1.2em',
      marginRight: '12px',
    },
  },
  statusSelect: {
    width: '200px',
    marginRight: '20px !important',
  },
  statusContainer: {
    margin: '6px 0px',
  },
  stepper: {
    justifyContent: 'center',
  },
  step: {
    maxWidth: '100px',
  },
  completedStepLabel: {
    color: theme.color.green,
  },
  incompleteStepLabel: {
    color: theme.color.grayLight,
  },
}));

const useStepStyles = makeStyles(() => ({
  stepIcon: {
    width: '100%',
    '& polygon': {
      fill: ({ completed }) => (completed ? theme.color.greenLight : 'none'),
      stroke: ({ completed, active }) => (completed || active ? theme.color.greenLight : theme.color.grayLight),
      strokeWidth: 2,
    },
  },
}));

const CustomStepIcon = ({ active, completed }) => {
  const stepClasses = useStepStyles({ completed, active });
  return <StepIcon className={stepClasses.stepIcon} />;
};

// const CustomConnect = () => <span />;

CustomStepIcon.propTypes = {
  active: PropTypes.bool.isRequired,
  completed: PropTypes.bool.isRequired,
};
CustomStepIcon.defaultProps = {};

const PathwayCandidateDetailsView = ({
  transitionCandidateState,
  user,
  candidatePathwayId,
  candidatePathway,
  pathwayDroppedReasonTypes,
  fetchCandidatePathway,
  onUpdate,
}) => {
  const classes = useStyles();
  // const [activeStep] = React.useState(3);
  const resolved = useFetchOnce(user, candidatePathwayId, fetchCandidatePathway);

  if (!candidatePathway) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }
  const candidate = candidatePathway.getIn(['candidate', 'user']);
  const steps = candidatePathway.getIn(['pathwayInstance', 'pathway', 'pathwayMilestones']);
  const pathwayInstanceSteps = candidatePathway.get('pathwayInstanceSteps');

  return (
    <PathwayCandidateDetailsStyles>
      <Grid container justify="space-between" alignItems="flex-start" className={classes.header}>
        <div>
          <Link to={`/staff/candidates/${candidatePathway.getIn(['candidate', 'candidateId'])}`}>
            <h2>{`${candidate.get('firstName')} ${candidate.get('lastName')}`}</h2>
          </Link>
          <Grid container className={classes.candidateInfo}>
            <EmailIcon />
            <span>{candidate.get('email')}</span>
          </Grid>
          <Grid container className={classes.candidateInfo}>
            <LocalPhoneIcon />
            <span>{candidate.get('phone')}</span>
          </Grid>
          <Grid container className={classes.candidateInfo}>
            <LocationOnIcon />
            <span>
              {/* Using Zip for now, address not on user model returned by candidatePathway */}
              {candidate.get('zip')}
            </span>
          </Grid>
          <Grid container alignItems="flex-end" spacing={2} className={classes.statusContainer}>
            <Grid item className={`pathway-status pathway-status-${candidatePathway.get('staffState')}`}>
              <img src="/icons/check-green-circle.png" alt="status-indicator" />
            </Grid>
            <Grid item>
              <FormControl className={classes.statusSelect}>
                <InputLabel>Status</InputLabel>
                <Select
                  margin="dense"
                  variant="outlined"
                  value={candidatePathway.get('staffState')}
                  onChange={(e) => transitionCandidateState(candidatePathway.get('id'), e.target.value)}
                >
                  {candidateStates.map((candidateState) => (
                    <MenuItem key={candidateState.value} value={candidateState.value}>
                      {candidateState.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {(candidatePathway.get('staffState') === 's_dropped_voluntarily' || candidatePathway.get('staffState') === 's_dropped_involuntarily') && (
                <FormControl className={`${classes.statusSelect}`}>
                  <InputLabel>Dropped Reason</InputLabel>
                  <Select
                    margin="dense"
                    variant="outlined"
                    onChange={(e) => onUpdate(candidatePathway.get('id'), { pathwayDroppedReasonType: e.target.value })}
                  >
                    {pathwayDroppedReasonTypes.map((droppedReason) => (
                      <MenuItem key={droppedReason[0]} value={droppedReason[0]}>
                        {droppedReason[1]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
        </div>
        <Link to={`/staff/messages/new/${candidatePathway.getIn(['candidate', 'candidateId'])}`}>
          <Button buttonType="secondary-outline">Send Message</Button>
        </Link>
      </Grid>
      {/* <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnect />} className={classes.stepper}>
        {mockMilestones.map((milestone, idx) => (
          <Step key={milestone} className={classes.step}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <span className={idx <= activeStep ? classes.completedStepLabel : classes.incompleteStepLabel}>{milestone}</span>
            </StepLabel>
          </Step>
        ))}
      </Stepper> */}
      <Grid container direction="column">
        {steps &&
          steps.map((milestone) => (
            <Grid item key={milestone.getIn(['pathwayMilestone', 'id'])}>
              <CandidateStep candidatePathwayId={candidatePathwayId} pathwayInstanceSteps={pathwayInstanceSteps} milestone={milestone} />
            </Grid>
          ))}
      </Grid>
    </PathwayCandidateDetailsStyles>
  );
};

PathwayCandidateDetailsView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  fetchCandidatePathway: PropTypes.func.isRequired,
  candidatePathwayId: PropTypes.string.isRequired,
  candidatePathway: PropTypes.instanceOf(Map),
  pathwayDroppedReasonTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  transitionCandidateState: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
PathwayCandidateDetailsView.defaultProps = {
  candidatePathway: undefined,
};

export default PathwayCandidateDetailsView;
