import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { OrderedSet, Map } from 'immutable';

import { startCase } from 'lodash';
import { Link, Redirect } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import {
  MainStyles,
  MainCard,
  Body,
  Status,
  MobileView,
  DesktopView,
  MainColumn,
  Logo,
  Pill,
  Title,
  Organization,
  MetaCategory,
  MetaGroup,
  MetaSubGroup,
  MetaLabel,
  MetaValue,
  MetaIcon,
  SideBar,
  MetaHeading,
} from '../../../common/styles/detail';
import Button from '../../../common/components/button/index';
// import theme from '../../../common/theme';
import JobPostingModel from '../../../solve/models/job_posting_model';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { useFetchOnce } from '../../../util/hooks';
import MyStatus from '../../../jobs/components/my_status';
import CandidateApplicationModel from '../../../solve/models/candidate_application_model';
import getListingImage from '../../../common/components/get_listing_image';

const propTypes = {
  id: PropTypes.string.isRequired,
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]),
  user: PropTypes.instanceOf(UserModel).isRequired,
  fetchJobPosting: PropTypes.func.isRequired,
  candidateApplication: PropTypes.instanceOf(CandidateApplicationModel),
  opportunityTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {
  job: undefined,
  candidateApplication: undefined,
};

// const EMPTY_LIST = fromJS([]);

const formatPay = (type, amount) => {
  if (!numeral(amount).value()) {
    return 'Not Provided';
  }

  const formattedAmount = numeral(amount).format('$0,0');
  switch (type) {
    case 'hourly':
      return `${formattedAmount}/hour`;
    case 'salary':
      return `${formattedAmount}/year`;
    default:
      return `${formattedAmount} ${type}`;
  }
};

const JobView = ({ id, job, fetchJobPosting, user, candidateApplication, opportunityTypes }) => {
  const resolved = useFetchOnce(user, id, fetchJobPosting);

  if (!job) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  const logoUrl = getListingImage(job, 'job');

  const candidateState = candidateApplication && candidateApplication.get('candidateState');
  const applied = ![undefined, 'c_deleted', 'c_saved'].includes(candidateState);
  const benefits =
    job.get('opportunityTypes') &&
    job.get('opportunityTypes').filter((value) => value === 'child-care' || value === 'health-care' || value === 'transportation');

  return (
    <MainStyles>
      <div className="candidate-wrapper">
        <Link to="/candidate/jobs">
          <Button buttonType="back" size="small">
            <KeyboardBackspaceIcon />
            Back to all jobs
          </Button>
        </Link>
      </div>
      {job && (
        <MainCard className="candidate-wrapper">
          {applied && (
            <Status>
              Change your status? <MyStatus candidateApplication={candidateApplication} />
              {job.get('applicationUrl') && <a href={`//${job.get('applicationUrl')}`}>See application website</a>}
            </Status>
          )}
          <Logo>
            <img src={logoUrl} alt="Job Posting Logo" />
          </Logo>

          <MobileView>
            <Pill>Job</Pill>
            <Title>{job.get('title')}</Title>
            <Organization>{job.getIn(['employer', 'name'])}</Organization>
          </MobileView>

          <Body applied={applied}>
            <MainColumn>
              <DesktopView>
                <Pill>Job</Pill>
                <Title>{job.get('title')}</Title>
                <Organization>{job.get('employerVisibilityType') === 'show-employer' && job.getIn(['employer', 'name'])}</Organization>
              </DesktopView>

              <MetaCategory>
                <MetaLabel>Description</MetaLabel>
                <MetaValue>
                  <div dangerouslySetInnerHTML={{ __html: job.get('description') }} />
                </MetaValue>
              </MetaCategory>

              {job.get('employerVisibilityType') === 'show-employer' && job.getIn(['employer', 'name']) && job.getIn(['employer', 'description']) && (
                <MetaCategory>
                  <MetaLabel>{`About ${job.getIn(['employer', 'name'])}`}</MetaLabel>
                  <MetaValue>{job.getIn(['employer', 'description'])}</MetaValue>
                </MetaCategory>
              )}

              {job.get('jobType') && (
                <MetaCategory>
                  <MetaLabel>Job Type</MetaLabel>
                  <MetaValue>{startCase(job.get('jobType'))}</MetaValue>
                </MetaCategory>
              )}

              {job.get('industryType') && (
                <MetaCategory>
                  <MetaLabel>Industry Type</MetaLabel>
                  <MetaValue>{startCase(job.get('industryType'))}</MetaValue>
                </MetaCategory>
              )}

              {benefits && benefits.size > 0 && (
                <MetaCategory>
                  <MetaLabel>Benefits</MetaLabel>
                  <MetaValue>
                    {benefits.map((benefit) => (
                      <React.Fragment key={benefit}>
                        {startCase(benefit)}
                        <br />
                      </React.Fragment>
                    ))}
                  </MetaValue>
                </MetaCategory>
              )}
            </MainColumn>
            <SideBar>
              {!applied && (
                <Link to={`/candidate/jobs/${job.get('id')}/apply`}>
                  <Button buttonType="secondary" fullWidth className="button">
                    Apply to job
                    <ChevronRightIcon />
                  </Button>
                </Link>
              )}
              {job.get('payAmount') && (
                <MetaGroup>
                  <MetaIcon>
                    <AttachMoneyIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Salary:</MetaHeading>
                    {job.get('payType') === 'no-share' ? 'Not Specified' : formatPay(job.get('payType'), job.get('payAmount'))}
                  </MetaValue>
                </MetaGroup>
              )}
              {(job.get('startDate') || job.get('endDate')) && (
                <MetaGroup>
                  <MetaIcon>
                    <CalendarTodayIcon />
                  </MetaIcon>
                  <MetaSubGroup>
                    <MetaValue>
                      <MetaHeading>Date:</MetaHeading>
                    </MetaValue>
                    {job.get('startDate') && <MetaValue>{`${moment(job.get('startDate')).format('dddd, LL')}`}</MetaValue>}
                    {job.get('endDate') && <MetaValue>{`${moment(job.get('endDate')).format('dddd, LL')}`}</MetaValue>}
                  </MetaSubGroup>
                </MetaGroup>
              )}
              {job.getIn(['address', 'address1']) ? (
                <MetaGroup>
                  <MetaIcon>
                    <LocationOnOutlinedIcon />
                  </MetaIcon>
                  <MetaSubGroup>
                    <MetaValue>
                      <MetaHeading>Address:</MetaHeading>
                    </MetaValue>
                    <MetaValue>{job.getIn(['address', 'address1'])}</MetaValue>
                    {job.getIn(['address', 'address2']) ? <MetaValue>{job.getIn(['address', 'address2'])}</MetaValue> : ''}
                    <MetaValue>{`${job.getIn(['address', 'city'])}, ${job.getIn(['address', 'state'])} ${job.getIn(['address', 'zip'])}`}</MetaValue>
                  </MetaSubGroup>
                </MetaGroup>
              ) : (
                <MetaGroup>
                  <MetaIcon>
                    <LocationOnOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Address:</MetaHeading>
                    {job.getIn(['address', 'zip'])}
                  </MetaValue>
                </MetaGroup>
              )}
              {opportunityTypes && (
                <MetaGroup>
                  <MetaIcon>
                    <LabelOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Serving:</MetaHeading>
                    {opportunityTypes.map((item) => item[1]).join(', ')}
                  </MetaValue>
                </MetaGroup>
              )}
            </SideBar>
          </Body>
        </MainCard>
      )}
    </MainStyles>
  );
};

JobView.propTypes = propTypes;
JobView.defaultProps = defaultProps;

export default JobView;
