import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncState } from '../../../util/hooks';
import { listOrganizationNames } from '../../../api';
import OrgSearchableSelectorStyles from './org_searchable_selector_styles';
import ReactSelect from '../../form/redux_form/react_select';
import UserModel from '../../../session/models/user_model';
import OrganizationModel from '../../../solve/models/organization_model';

const OrgSearchableSelectorView = ({ currentUser, selectedOrg, fetchOrganization: fetchOrg, ...props }) => {
  const {
    input: { value },
  } = props;
  const {
    meta: { initial },
  } = props;

  const [orgSearch, onSearchChange] = useState('');
  const [orgList, setOrgList] = useAsyncState();

  useEffect(() => {
    if (initial && initial.length > 1 && !selectedOrg) {
      fetchOrg(currentUser, initial);
    }
  }, [currentUser, fetchOrg, initial, selectedOrg]);

  useEffect(() => {
    if (selectedOrg && value === initial) {
      onSearchChange(selectedOrg.get('name'));
    }
  }, [selectedOrg, value, initial]);

  useEffect(() => {
    listOrganizationNames(currentUser, { orgname: orgSearch }).then((rez) => {
      setOrgList(rez.data.map((x) => ({ value: x[0], label: x[1] })));
    });
  }, [currentUser, orgSearch, setOrgList]);

  return (
    <OrgSearchableSelectorStyles>
      <ReactSelect
        placeholder="Search for an Organization..."
        noOptionsMessage={orgSearch.length > 0 ? 'No results' : 'Start typing to see results'}
        inputValue={orgSearch}
        onInputChange={onSearchChange}
        options={orgList || []}
        isSpecialStyles
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    </OrgSearchableSelectorStyles>
  );
};
OrgSearchableSelectorView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  selectedOrg: PropTypes.instanceOf(OrganizationModel),
  input: PropTypes.shape({ value: PropTypes.string }).isRequired,
  meta: PropTypes.shape({ initial: PropTypes.string }).isRequired,
  fetchOrganization: PropTypes.func.isRequired,
};
OrgSearchableSelectorView.defaultProps = {
  selectedOrg: undefined,
};

export default OrgSearchableSelectorView;
