/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map, OrderedSet, getIn } from 'immutable';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { EmployeeTypeEnum } from 'risekit/common/enum/employee.enum';

import DefyForm from '../defy_form';
import Tabber from '../../../ui/components/tabber';
import { useFetchOnce } from '../../../util/hooks';
import JobCandidateResume from '../job_candidate_resume';
import UserModel from '../../../session/models/user_model';
import { Header } from './listing_applicant_details_styles';
import { TextHeading, Button } from '../../../common/components';
import JobCandidateApplications from '../job_candidate_applications';
import JobCandidateQuestionnaire from '../job_candidate_questionnaire';
import JobCandidateNotifications from '../job_candidate_notifications';

const ListingApplicantDetailsView = ({ showInmates, candidateId, candidate, currentUser, candidateSurveys, fetchCandidate, listCandidateSurveys }) => {
  useFetchOnce(currentUser, candidateId, fetchCandidate);
  useFetchOnce(currentUser, candidateId, listCandidateSurveys);

  const questionnaireSurveys = candidateSurveys
    ? candidateSurveys.filter((survey) => survey.getIn(['survey', 'surveyType']) === 'challenges' || survey.getIn(['survey', 'surveyType']) === 'readiness')
    : null;

  const isEmployer = getIn(currentUser, ['staffOrganization', 'orgType']) === EmployeeTypeEnum.EMPLOYER;

  return (
    <div>
      {candidate && (
        <React.Fragment>
          <Header>
            <div className="candidate-info">
              <AccountCircle style={{ fontSize: 48, marginRight: 10 }} />
              <div>
                <div className="candidate-wrapper">
                  <TextHeading size="medium" weight="bold">{`${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`}</TextHeading>
                  {!currentUser.getIn(['staffOrganization', 'isEmployer']) && (
                    <Link className="edit-candidate" to={`/staff/candidates/${candidateId}/edit`}>
                      <EditIcon /> Edit
                    </Link>
                  )}
                </div>
                {candidate.getIn(['user', 'email']) && (
                  <div className="candidate-contact">
                    <EmailIcon className="icon" />
                    {candidate.getIn(['user', 'email'])}
                  </div>
                )}
                {candidate.getIn(['user', 'phone']) && (
                  <div className="candidate-contact">
                    <LocalPhoneIcon className="icon" />
                    {candidate.getIn(['user', 'phone'])}
                  </div>
                )}
              </div>
            </div>
            <Link to={`/staff/messages/new/${candidateId}`}>
              <Button buttonType="secondary-outline" className="button">
                Send Message
              </Button>
            </Link>
          </Header>

          <Tabber>
            <div tabLabel="Resume">
              <JobCandidateResume candidateId={candidateId} />
            </div>
            <div tabLabel="Applications">
              <JobCandidateApplications candidateId={candidateId} />
            </div>
            {!isEmployer ? (
              <div tabLabel="Questionnaires">
                <JobCandidateQuestionnaire surveys={questionnaireSurveys} />
              </div>
            ) : null}

            <div tabLabel="Messages / Activities / Notes">
              <JobCandidateNotifications candidateId={candidateId} />
            </div>
            {!!showInmates && (
              <div tabLabel="Defy">
                <DefyForm candidateId={candidateId} />
              </div>
            )}
          </Tabber>
        </React.Fragment>
      )}
    </div>
  );
};
ListingApplicantDetailsView.propTypes = {
  candidate: PropTypes.instanceOf(Map).isRequired,
  candidateId: PropTypes.string.isRequired,
  candidateSurveys: PropTypes.instanceOf(OrderedSet).isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  listCandidateSurveys: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  showInmates: PropTypes.bool.isRequired,
};

ListingApplicantDetailsView.defaultProps = {};

export default ListingApplicantDetailsView;
