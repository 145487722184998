import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import SurveyWizard from '../survey_wizard';
import QuestionnaireStyles from './questionnaire_styles';

const propTypes = {
  candidateQuestionnaire: PropTypes.instanceOf(List).isRequired,
  onComplete: PropTypes.func.isRequired,
};

const defaultProps = {};

const QuestionnaireView = ({ candidateQuestionnaire, onComplete }) => (
  <QuestionnaireStyles>
    <SurveyWizard surveys={candidateQuestionnaire} onSurveySubmitted={(survey) => console.log(survey, 'submitted a survey!')} onComplete={onComplete} />
  </QuestionnaireStyles>
);

QuestionnaireView.propTypes = propTypes;
QuestionnaireView.defaultProps = defaultProps;

export default QuestionnaireView;
