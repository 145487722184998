import React from 'react';
import styled from 'styled-components';
import Spinner from 'common/components/spinner/spinner';

const CircularSpinner = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

export default CircularSpinner;

const SpinnerContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;
