import styled from 'styled-components';
import theme from '../../../common/theme';

const ProgramsStyles = styled.div`
  .filter-button-item {
    padding: 9px 14px;
    border: 1px solid lightgray;
    border-radius: 9px;
    margin: 0 5px 5px 0;
    text-align: center;
    font-size: 0.8em;
    text-transform: capitalize;
    color: #4c4c4c;
    &.active {
      background: ${theme.color.blue};
      color: white;
    }
  }
`;

export default ProgramsStyles;
