import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import OrganizationInviteStyles from './organization_invite_styles';
import { reduxForm, Field, FieldArray, MuiTextField, MuiTextFieldArray } from '../../../common/form';
import Button from '../../../common/components/button/index';
import { Card } from '../../../common/styles/layout';
import organizationInviteValidate from './organization_invite_validate';
import Spinner from '../../../common/components/spinner';
import OrganizationModel from '../../../solve/models/organization_model';
import UserModel from '../../../session/models/user_model';

const NonProfitRoleFields = () => (
  <Fragment>
    <Grid item xs={6}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Intake" name="invite.intake" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Case Management" name="invite.caseManagement" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={MuiTextField}
            label="Employer Partners & Sharing Jobs"
            name="invite.employerPartner"
            type="email"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={MuiTextField}
            label="Finding Supportive Services & Referring Candidates"
            name="invite.supportiveServices"
            type="email"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Resume Help" name="invite.resumeHelp" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Field
            component={MuiTextField}
            label="Measuring Outcomes and Reporting"
            name="invite.reporting"
            type="email"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Recruiting Participants" name="invite.recruiting" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
        {/* TODO: hook this up
          <Grid item xs={12}>
            <FieldArray component={MuiTextFieldArray} label="Other Team Members" name="invite.other" margin="dense" type="email" multiline required />
          </Grid> */}
      </Grid>
    </Grid>
  </Fragment>
);

const EmployerRoleFields = () => (
  <Fragment>
    <Grid item xs={6}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Recruiter" name="invite.recruiter" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Human Resources" name="invite.humanResources" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={MuiTextField}
            label="Human Resources Director"
            name="invite.humanResourcesDirector"
            type="email"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={MuiTextField}
            label="Human Resources Manager"
            name="invite.humanResourcesManager"
            type="email"
            margin="dense"
            variant="outlined"
            fullWidth
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={6}>
      <Grid item xs={12}>
        <Field
          component={MuiTextField}
          label="Diversity & Inclusion Specialist"
          name="invite.diversityAndInclusionSpecialist"
          type="email"
          margin="dense"
          variant="outlined"
          fullWidth
        />
      </Grid>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Field component={MuiTextField} label="Supervisor" name="invite.supervisor" type="email" margin="dense" variant="outlined" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FieldArray component={MuiTextFieldArray} label="Other Team Members" name="invite.other" margin="dense" type="email" multiline required />
        </Grid>
      </Grid>
    </Grid>
  </Fragment>
);

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  org: PropTypes.instanceOf(OrganizationModel).isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  isEmployer: PropTypes.bool.isRequired,
  listOrganizations: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired,
};

const defaultProps = {};

const OrganizationInviteForm = ({ handleSubmit, pristine, submitting, org, user, isEmployer, listOrganizations, onSkip }) => {
  useEffect(() => {
    if (!org) {
      listOrganizations(user, isEmployer);
    }
  }, [org, listOrganizations, isEmployer, user]);

  const RoleFields = isEmployer ? EmployerRoleFields : NonProfitRoleFields;

  if (!org) {
    return <Spinner />;
  }

  onSkip();

  return (
    <OrganizationInviteStyles>
      <form onSubmit={handleSubmit}>
        <h2>Invite Your Co-Workers to RiseKit</h2>
        <Card>
          <h4 style={{ float: 'right' }}>{org.get('staffCount') || 0} of your team members are already using RiseKit!</h4>
          <h3>Send Invitations</h3>
          <cite>We&apos;ve listed some common roles below to the help us tailor your invite to them.</cite>
          <hr />
          <p>Enter your team members&apos; email(s) in the appropriate role(s):</p>
          <Grid container spacing={2}>
            <RoleFields />
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item>
                  <Button type="button" buttonType="text" onClick={onSkip}>
                    Skip
                  </Button>
                  <Button buttonType="primary" disabled={pristine || submitting} onClick={handleSubmit}>
                    Invite Coworkers
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div style={{ height: 30 }} />
        </Card>
      </form>
    </OrganizationInviteStyles>
  );
};

OrganizationInviteForm.propTypes = propTypes;
OrganizationInviteForm.defaultProps = defaultProps;

const OrganizationInviteView = reduxForm({
  form: 'orgInvite',
  enableReinitialize: true,
  validate: organizationInviteValidate,
})(OrganizationInviteForm);

export default OrganizationInviteView;
