import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import PageNavigationButton from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import OrganizationTile from '../organization_tile';
import OrganizationsStyles, { ResultsPageNavigator } from './organizations_styles';
import Spinner from '../../../common/components/spinner';
import Button from '../../../common/components/button';
import UserModel from '../../../session/models/user_model';
import theme from '../../../common/theme';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  orgs: PropTypes.instanceOf(OrderedSet).isRequired,
  totalCount: PropTypes.number.isRequired,
  isEmployer: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  listOrgs: PropTypes.func.isRequired,
  onOrgFound: PropTypes.func.isRequired,
  onOrgNotFound: PropTypes.func.isRequired,
};

const defaultProps = {};

// RegExp.escape = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
// const buildSearchMatcher = (string) => new RegExp(RegExp.escape(string), 'i');

const PageNavigator = ({ totalCount, orgs, pageShown, getPrevPageResults, getNextPageResults, totalPages }) => {
  if (totalCount < 25 || !orgs || orgs.size === 0) {
    return null;
  }
  return (
    <ResultsPageNavigator>
      <div className="prev-next-container">
        <PageNavigationButton
          style={{ color: pageShown <= 0 ? theme.color.grayLight : theme.color.blue }}
          startIcon={<NavigateBeforeIcon />}
          disabled={pageShown <= 0}
          onClick={() => getPrevPageResults()}
        >
          Prev
        </PageNavigationButton>
        <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown + 1} of ${totalPages}`}</Typography>
        <PageNavigationButton
          style={{ color: pageShown >= totalPages - 1 ? theme.color.grayLight : theme.color.blue }}
          endIcon={<NavigateNextIcon />}
          disabled={pageShown >= totalPages - 1}
          onClick={() => getNextPageResults()}
        >
          Next
        </PageNavigationButton>
      </div>
    </ResultsPageNavigator>
  );
};
PageNavigator.propTypes = {
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  orgs: PropTypes.instanceOf(OrderedSet),
  pageShown: PropTypes.number,
  getPrevPageResults: PropTypes.func.isRequired,
  getNextPageResults: PropTypes.func.isRequired,
};
PageNavigator.defaultProps = {
  totalCount: undefined,
  totalPages: undefined,
  orgs: undefined,
  pageShown: undefined,
};

const OrganizationsView = ({ user, onOrgFound, onOrgNotFound, isEmployer, orgs, loaded, listOrgs, totalCount }) => {
  const [orgSearch, setOrgSearch] = useState('');
  const [pageShown, setPageShown] = useState(0);
  const [selectedOrg, setSelectedOrg] = useState();
  const totalPages = Math.ceil(totalCount / 25) || 1;

  const orgType = isEmployer ? 'Company or Institution' : 'Organization';
  const orgTypeAction = isEmployer ? 'Company' : 'Organization';
  const filterType = isEmployer ? 'employer' : 'nonprofit';

  useEffect(() => {
    // if (!loaded) {
    listOrgs(user, { page: pageShown + 1, type: filterType, orgname: orgSearch });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgSearch, pageShown]);

  const getNextPageResults = () => {
    setPageShown(pageShown + 1);
    // scrollTop();
  };
  const getPrevPageResults = () => {
    setPageShown(pageShown - 1);
    // scrollTop();
  };

  useEffect(() => {
    setPageShown(0);
  }, [orgSearch]);

  const onSearchChange = (e) => {
    e.preventDefault();
    setOrgSearch(e.target.value);
    setSelectedOrg(undefined);
  };

  // const matcher = buildSearchMatcher(orgSearch);
  // eslint-disable-next-line new-cap
  const filteredOrgs = orgs; // selectedOrg ? List([selectedOrg]) : orgs.filter((org) => org.get('name').match(matcher));

  if (!loaded) {
    return <Spinner />;
  }

  const foundOrg = selectedOrg || (filteredOrgs.size === 1 && filteredOrgs.first());

  const confirmButton = foundOrg && (
    <Grid item xs={4} key="confirm">
      <Button buttonType="primary" name="joinOrg" onClick={() => onOrgFound(foundOrg)}>
        Join {orgTypeAction}
      </Button>
    </Grid>
  );

  const notFoundButton = filteredOrgs.isEmpty() && (
    <Grid item xs={8} key="newOrg">
      <h4>
        Don&apos;t see your {orgType.toLowerCase()}? Create a new profile for your {orgType.toLowerCase()}
        {isEmployer && ' and start posting jobs'}!
      </h4>
      <Button buttonType="primary" onClick={() => onOrgNotFound(orgSearch)}>
        Add My {orgTypeAction}
      </Button>
    </Grid>
  );

  return (
    <OrganizationsStyles>
      {/* <div className="header"> */}
      {/*  <h4>Recommended based on you email address</h4> */}
      {/*  <Button variant="contained" className="action-contained" style={{ float: 'right' }}> */}
      {/*    Join Organization */}
      {/*  </Button> */}
      {/*  <OrganizationTile /> */}
      {/* </div> */}
      <h1>Search For Your {orgType}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextField
            onChange={onSearchChange}
            value={orgSearch}
            id="search"
            placeholder="Search Organizations"
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: orgSearch && (
                <InputAdornment position="end">
                  <CancelIcon
                    onClick={() => {
                      setOrgSearch('');
                      setSelectedOrg(undefined);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <div style={{ height: 20 }} />
      <PageNavigator {...{ totalCount, orgs, pageShown, getPrevPageResults, getNextPageResults, totalPages }} />
      <Grid container spacing={2} className="organization-list">
        {filteredOrgs.map((org) => (
          <Grid item xs={12} sm={12} md={6} lg={4} key={org.get('id')}>
            <OrganizationTile
              org={org}
              onClick={() => {
                setOrgSearch(org.get('name'));
                setSelectedOrg(org);
              }}
            />
          </Grid>
        ))}
        {confirmButton}
        {notFoundButton}
      </Grid>
      <PageNavigator {...{ totalCount, orgs, pageShown, getPrevPageResults, getNextPageResults, totalPages }} />
      <div style={{ height: 40 }} />
    </OrganizationsStyles>
  );
};

OrganizationsView.propTypes = propTypes;
OrganizationsView.defaultProps = defaultProps;

export default OrganizationsView;
