import styled from 'styled-components';
// import theme from '../../../common/theme';

const NotesNewStyles = styled.div`
  h2 span {
    font-weight: normal;
  }

  .note-button {
    margin-top: 25px;
  }
`;

export default NotesNewStyles;
