import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import OrganizationFormView from './organization_form_view';
import { formValueSelector } from '../../../common/form';
import { createOrganization, fetchOrganization, updateOrganization } from '../../../solve/actions';
import { getCurrentUser, getTreatment, getEnumsForFormSelect, getSystemEnumList, getOnboarding, getOnboardingMode, getOrganization } from '../../../reducer';

// import { SomeSelectors } from '../../../reducer';
// import { SomeActions } from '../../actions';

const formSelector = formValueSelector('orgProfileForm');

const buildInitialValues = (organization) => {
  let org = {};
  if (organization) {
    org = {
      logoUrl: organization.get('logoUrl'),
      name: organization.get('name'),
      primaryWebsite: organization.get('primaryWebsite'),
      ein: organization.get('ein'),
      addressAttributes: {
        address1: organization.getIn(['address', 'address1']),
        address2: organization.getIn(['address', 'address2']),
        city: organization.getIn(['address', 'city']),
        state: organization.getIn(['address', 'state']),
        zip: organization.getIn(['address', 'zip']),
      },
      phone: organization.get('phone').replace(/\D+/g, ''),
      industryType: organization.get('industryType'),
      willHireBackgrounds: organization.get('willHireBackgrounds'),
      description: organization.get('description'),
      orgCategoryType: organization.get('orgCategoryType'),
    };
  }
  return fromJS(org);
};

function buildSubmitValues(values, isEmployer, showBarriers) {
  const logo = values.get('logo');
  let newValues = values.delete('logo');
  if (logo) {
    newValues = newValues.set('logo', logo[0]).delete('logoUrl');
  }
  if (isEmployer && !showBarriers) {
    newValues = newValues.set('orgCategoryType', 'employer');
  }

  return fromJS(newValues);
}

// const Barriers = fromJS([
//   { label: 'Transportation', slug: 'transportation' },
//   { label: 'Mental Health', slug: 'mentalHealth' },
//   { label: 'Health Care', slug: 'healthCare' },
//   { label: 'Housing', slug: 'housing' },
//   { label: 'Legal Counsel', slug: 'legalCounsel' },
//   { label: 'Food', slug: 'food' },
//   { label: 'Child Care', slug: 'childCare' },
//   { label: 'Membership', slug: 'membership' },
// ]);

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  const isOnboarding = getOnboarding(state);
  const organizationId = user && user.get('staffOrganization') && user.getIn(['staffOrganization', 'id']);
  const organization = organizationId && getOrganization(state, organizationId);
  return {
    user,
    isOnboarding,
    organization,
    organizationId,
    barriers: getSystemEnumList(state, 'SupportType'),
    industryTypes: getEnumsForFormSelect(state, 'IndustryType'),
    oldLogo: formSelector(state, 'logoUrl'),
    logo: formSelector(state, 'logo'),
    showBarriers: !!getTreatment(state, 'showBarriers'),
    isEmployer: isOnboarding ? getOnboardingMode(state) === 'employer' : user.getIn(['staffOrganization', 'orgType'])?.startsWith('employer'),
    initialValues: buildInitialValues(organization),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  // action creator assignments
  createOrganization,
  fetchOrganization,
  updateOrganization,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  onSubmit: (values) => {
    if (stateProps.organization) {
      const cleanValues = buildSubmitValues(values, stateProps.isEmployer, stateProps.showBarriers);
      return dispatchProps.updateOrganization(stateProps.user, stateProps.organizationId, cleanValues);
    }
    const cleanValues = buildSubmitValues(values, stateProps.isEmployer, stateProps.showBarriers);
    const orgType = stateProps.isEmployer ? 'employer' : 'nonprofit';
    return dispatchProps.createOrganization(stateProps.user, cleanValues.merge({ orgType })).then((response) => ownProps.setOrgId(response.result));
  },
});

const OrganizationFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(OrganizationFormView);

export default OrganizationFormContainer;
