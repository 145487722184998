import styled from 'styled-components';
import theme from '../../../common/theme';

export const ApplyToJobStyles = styled.div``;

export const Results = styled.div`
  h1 {
    color: ${theme.color.action};
    margin: 0px;
    font-size: 2.5em;
  }
  header {
    color: ${theme.color.gray};
    margin-bottom: 50px;
    font-size: 1.25em;
  }
  .return-link {
    outline: 0;
  }
  .return-link:hover {
    color: inherit;
  }
`;

export default ApplyToJobStyles;
