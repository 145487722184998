import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { makeStyles } from '@material-ui/core/styles';
import PathwayStepStatus from '../pathway_step_status';

import theme from '../../../../common/theme';

const getStepColor = (stepType) => {
  switch (stepType) {
    case 'document':
      return '#9D71D6';
    case 'event':
      return '#FFAB4A';
    case 'assessment':
      return '#67B4FF';
    case 'action':
    default:
      return '#4BC076';
  }
};
const useStyles = makeStyles(() => ({
  bannerContainer: {
    height: '70px',
    textAlign: 'right',
  },
  leftColumn: {
    paddingRight: '30px',
    marginTop: '7px',
    flex: 1,
    minWidth: '275px',
  },
  stepNameContainer: {
    position: 'relative',
  },
  stepName: {
    color: ({ stepType }) => getStepColor(stepType),
    fontWeight: 'bold',
  },
  progressBar: {
    height: '8px',
    maxWidth: '150px',
    borderRadius: '6px',
    backgroundColor: theme.color.grayLighter,
    '& > div': {
      borderRadius: '6px',
    },
  },
  dashedConnectorContainer: {
    position: 'relative',
    marginLeft: '20px',
    flexGrow: 1,
  },
  dashedConnector: {
    borderBottom: `1px dashed ${theme.color.gray}`,
    position: 'absolute',
    right: '10px',
    top: '12px',
    width: '100%',
  },
  descriptionText: {
    width: '80%',
  },
  showMoreButton: {
    minWidth: '100px',
    marginTop: '-6px',
  },
  stepIcon: {
    marginRight: '12px',
    color: ({ stepType }) => getStepColor(stepType),
  },
}));

const staffStepStatuses = [
  { label: 'Not Started', value: 's_not_started' },
  { label: 'In Progress', value: 's_in_progress' },
  { label: 'Completed', value: 's_complete' },
];

const PathwayStepView = ({ step, pathwayInstance, transitionStaffState }) => {
  const classes = useStyles({ stepType: step.get('pathwayStepType') });
  const [showFullDesc, toggleShowFullDesc] = useState(false);

  const truncatedDesc = (desc) => {
    if (desc.length > 150) {
      return `${desc.substring(0, 150)}...`;
    }
    return desc;
  };
  const pathwayInstanceSteps = pathwayInstance && pathwayInstance.get('pathwayInstanceSteps');
  const getInstanceStep = (stepId) => pathwayInstanceSteps.find((instanceStep) => instanceStep.get('pathwayStepId') === stepId);

  return (
    <Grid item container direction="column">
      <Grid item container style={{ alignItems: 'flex-start' }}>
        <Grid item style={{ marginTop: '7px' }}>
          <AssignmentTurnedInIcon className={classes.stepIcon} />
        </Grid>
        <Grid item container direction="column" xs={10} className={classes.leftColumn}>
          <Grid item container wrap="nowrap">
            <Grid item className={classes.stepName}>
              {step.get('name')}
            </Grid>
            <Grid item className={classes.dashedConnectorContainer}>
              <div className={classes.dashedConnector} />
            </Grid>
          </Grid>
          <Grid item container wrap="nowrap" justify="space-between">
            <Grid item className={classes.descriptionText}>
              <Typography variant="body2" color="primary">
                {showFullDesc ? step.get('description') : truncatedDesc(step.get('description'))}
              </Typography>
            </Grid>
            <Grid item>
              {step.get('description').length > 150 && (
                <Button size="small" color="secondary" className={classes.showMoreButton} onClick={() => toggleShowFullDesc(!showFullDesc)}>
                  {showFullDesc ? 'show less' : 'show more'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {step.get('pathwayStepCategoryType') === 'pathway' ? (
          <React.Fragment>
            <PathwayStepStatus step={step} pathwayInstanceStep={getInstanceStep(step.get('id'))} />
            <Grid item container direction="column" xs={2}>
              <FormControl>
                <InputLabel>Update Status</InputLabel>
                <Select
                  margin="dense"
                  variant="outlined"
                  value={getInstanceStep(step.get('id')) && getInstanceStep(step.get('id')).get('staffState')}
                  onChange={(e) => transitionStaffState(getInstanceStep(step.get('id')).get('id'), e.target.value)}
                >
                  {staffStepStatuses.map((staffState) => (
                    <MenuItem key={staffState.value} value={staffState.value}>
                      {staffState.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </React.Fragment>
        ) : (
          <Grid item container direction="column" xs={2}>
            <Link to={`/staff/pathways/${pathwayInstance.get('id')}/step/${step.get('id')}`}>
              <Button size="small" color="secondary" variant="outlined">
                view
              </Button>
            </Link>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

PathwayStepView.propTypes = {
  step: PropTypes.instanceOf(Map).isRequired,
  transitionStaffState: PropTypes.func.isRequired,
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
};
PathwayStepView.defaultProps = {};

export default PathwayStepView;
