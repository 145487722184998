import { reduxForm } from '../../../common/form';
import ListStaffView from './list_staff_view';

const ListStaffForm = reduxForm({
  form: 'staffSettingsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(ListStaffView);

export default ListStaffForm;
