import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import TileView from './tile_view';
import { getJobPosting, getCandidateApplicationForJobPosting, getCurrentUser, getSystemEnumList } from '../../../reducer';
import { transitionCandidateApplicationByCandidate, createCandidateApplication } from '../../../solve/actions';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state, { id }) => {
  const job = getJobPosting(state, id);
  const opportunityTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'OpportunityType'), job?.get('opportunityTypes')));

  return {
    job,
    currentUser: getCurrentUser(state),
    candidateApplication: getCandidateApplicationForJobPosting(state, id),
    opportunityTypes,
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  createCandidateApplication,
  transitionCandidateApplicationByCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createCandidateApplication: () => dispatchProps.createCandidateApplication(stateProps.currentUser, ownProps.id),
  deleteCandidateApplication: () =>
    dispatchProps.transitionCandidateApplicationByCandidate(stateProps.currentUser, stateProps.candidateApplication.get('id'), 'deleted'),
  handleSaveDraft: (values) => console.log('Submitting Program Posting Draft', values),
  handleSubmit: (values) => console.log('Submitting Program Posting', values),
});

const TileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(TileView);

export default TileContainer;
