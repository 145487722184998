import styled from 'styled-components';
// import theme from '../../../common/theme';

const RegisterStyles = styled.div`
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;

  p.candidate-onboarding {
    margin: -40px 0px 40px;
    font-size: 18px;
    line-height: 26px;
    font-style: italic;
  }

  .status {
    margin: 0 1em;
  }
  .error {
    color: ${(props) => props.theme.color.error};

    input[type='text'],
    input[type='password'] {
      border: thin solid ${(props) => props.theme.color.error};
    }
  }

  .oauth-row {
    margin: auto;
    width: fit-content;
    padding: 1em;
  }

  .oauth-button {
    margin: 5px;
    color: ${(props) => props.theme.color.action};
    &:hover {
      color: ${(props) => props.theme.color.actionHover};
    }
  }

  .terms-and-condition__label {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .accept-terms-and-condition--disabled {
    opacity: .5;
  }
`;

export default RegisterStyles;
