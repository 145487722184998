import React from 'react';
import PropTypes from 'prop-types';
// import { Map } from 'immutable';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MyStatusStyles from './my_status_styles';

const MyStatusView = ({ candidateProgram, onChange }) =>
  candidateProgram && (
    <MyStatusStyles className="program-status">
      <FormControl margin="dense" variant="outlined">
        {/* <InputLabel>Distance</InputLabel> */}
        <div>
          <Select
            // fullWidth
            value={candidateProgram.get('candidateState')}
            name="candidateProgramState"
            variant="outlined"
            onChange={(e) => {
              onChange(e.target.value);
            }}
          >
            <MenuItem>
              <em>My Status</em>
            </MenuItem>

            {/* <MenuItem value="c_saved">Saved</MenuItem> */}
            <MenuItem value="c_applied">Applied</MenuItem>
            <MenuItem value="c_declined">Declined</MenuItem>
            <MenuItem value="c_joined">Joined</MenuItem>
            <MenuItem value="c_dropped_out">Dropped-out</MenuItem>
            <MenuItem value="c_graduated">Graduated</MenuItem>
          </Select>
        </div>
      </FormControl>
    </MyStatusStyles>
  );

MyStatusView.propTypes = {
  onChange: PropTypes.func.isRequired,
  candidateProgram: PropTypes.shape({
    get: PropTypes.func,
  }),
  candidateApplication: PropTypes.shape({
    get: PropTypes.func,
  }),
};

MyStatusView.defaultProps = {
  candidateProgram: undefined,
  candidateApplication: undefined,
};

export default MyStatusView;
