import styled from 'styled-components';
import theme from '../theme';

// Example of style collection
// this is imported and exported in ./index.js

// Usage
// <Grid>
//   <Head>
//     <Header>Title</Header>
//   </Head>
//   <Row>
//     <Col>
//       <Text>Text</Text>
//     </Col>
//   </Row>
// </Grid>

const Grid = styled.div`
  /* padding: ${(props) => props.theme.padding.small}; */
  display: flex;
  flex-direction: column;
  border-left: ${(props) => (props.borderLeft ? '#DEE1E3' : 'none')};
`;

export const Head = styled.div`
  border-bottom-width: 1px;
  border-color: #ccc;
`;

export const Header = styled.div`
  font-weight: bold;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom-width: 1px;
  border-color: #eee;
  /* padding: ${(props) => props.theme.padding.small}; */
`;

export const Col = styled.div`
  /* padding: ${(props) => props.theme.padding.small}; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => (props.half ? 'none' : 1)}
  width: ${(props) => (props.half ? '50%' : 'auto')}
  border-style: solid;
  border-color: ${(props) => (props.border ? '#DEE1E3' : '#fff')};
  border-width: 0.25px;
  background-color: ${(props) => (props.selected ? '#EAF9F4' : '#fff')};
`;

export const Text = styled.div`
  color: ${theme.color.text.medium};
`;

export default Grid;
