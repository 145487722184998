import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import ProgramView from './program_view';
import { fetchProgram } from '../../../solve/actions';
import { getProgram, getOrganization, getCurrentUser, getCandidateProgramForProgram, getSystemEnumList } from '../../../reducer';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const program = getProgram(state, id);
  const serviceTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), program?.get('serviceTypes')));
  const targetTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), program?.get('targetParticipantTypes')));

  return {
    id,
    program,
    employer: program && getOrganization(state, program.getIn(['postingOrganization', 'id'])),
    currentUser: getCurrentUser(state),
    candidateProgram: getCandidateProgramForProgram(state, id),
    serviceTypes,
    targetTypes,
  };
};

const mapDispatchToProps = {
  fetchProgram,
};

const ProgramContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramView);

export default ProgramContainer;
