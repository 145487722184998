import React from 'react';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

const propTypes = {
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const getPercentageValue = (statNum, totalNum) => Math.round((statNum / totalNum) * 100);

const defaultProps = {};

const GenderReportView = ({ reportDetails, pathwayInstance }) => {
  if (!reportDetails.size) {
    return null;
  }
  return (
    <tr key={pathwayInstance.get('id')}>
      <th>
        <Link to={`/staff/pathways/${pathwayInstance.get('id')}`}>{pathwayInstance.get('name')}</Link>
      </th>
      <td>
        {reportDetails.get('male')}{' '}
        {reportDetails.get('male') > 0 && <span>({getPercentageValue(reportDetails.get('male'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('female')}{' '}
        {reportDetails.get('female') > 0 && <span>({getPercentageValue(reportDetails.get('female'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('nonBinary')}{' '}
        {reportDetails.get('nonBinary') > 0 && <span>({getPercentageValue(reportDetails.get('nonBinary'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('other')}{' '}
        {reportDetails.get('other') > 0 && <span>({getPercentageValue(reportDetails.get('other'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('noShare')}{' '}
        {reportDetails.get('noShare') > 0 && <span>({getPercentageValue(reportDetails.get('noShare'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>{reportDetails.get('total')}</td>
    </tr>
  );
};
GenderReportView.propTypes = propTypes;
GenderReportView.defaultProps = defaultProps;

export default GenderReportView;
