import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  LongTextInput,
  ImageField,
  Pagination,
} from 'react-admin';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const Icon = AssignmentIcon;
// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="title" />
      <TextField source="aasmState" label="State" />
      <TextField source="surveyType" />
      <ImageField label="Icon" source="iconUrl" />
      <EditButton basePath="/globalSettings" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>GlobalSetting {record ? `"${record.title}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <DisabledInput source="title" />
      <LongTextInput source="value" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <LongTextInput source="value" />
    </SimpleForm>
  </AdminCreate>
);
