import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Link } from 'react-router-dom';
import ScheduleIcon from '@material-ui/icons/Schedule';
import StarIcon from '@material-ui/icons/Star';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
// import SendIcon from '@material-ui/icons/Send';
// import StarIcon from '@material-ui/icons/Star';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckIcon from '@material-ui/icons/Check';
import Button from '../../../common/components/button/index';
import { TileStyles, Header, Pill, Details, Logo, Status, Body, Title, Organization, Footer, Services, ButtonGroup } from '../../../common/styles/tile';
import ServiceTileStyles from './service_tile_styles';
import { ExpandableText } from '../../../common/components';
import UserModel from '../../../session/models/user_model';
// import MyProgramStatus from '../../../programs/components/my_status'; // TODO: replace with serviceStatus
// import StatusUpdateTile from '../../../solve/components/status_update_tile';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  id: PropTypes.string.isRequired,
  service: PropTypes.instanceOf(Map).isRequired,
  externalService: PropTypes.shape({
    get: PropTypes.func,
    externalStatus: PropTypes.string,
  }),
  isAbService: PropTypes.bool,
  createExternalCandidateService: PropTypes.func.isRequired,
  transitionExternalCandidateServiceByCandidate: PropTypes.func.isRequired,
  // deleteExternalCandidateService: PropTypes.func.isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  targetTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {
  externalService: undefined,
  isAbService: false,
};

const ExternalResourceStatus = ({ externalService }) => (
  <ServiceTileStyles>
    External status: <span className="externalStatus">{externalService.get('externalStatus')}</span>
  </ServiceTileStyles>
);
ExternalResourceStatus.defaultProps = {
  externalService: undefined,
};
ExternalResourceStatus.propTypes = {
  externalService: PropTypes.shape({
    get: PropTypes.func,
    externalStatus: PropTypes.string,
  }),
};

const ServiceTileView = ({
  currentUser,
  id,
  service,
  externalService,
  isAbService,
  // transitionCandidateServiceByCandidate,
  // deleteExternalCandidateService,
  createExternalCandidateService,
  transitionExternalCandidateServiceByCandidate,
  serviceTypes,
  targetTypes,
}) => {
  if (service === undefined) {
    return null;
  }
  let logoUrl;
  if (isAbService) {
    logoUrl = '/images/aunt-bertha-logo.png';
  } else {
    logoUrl = service.get('logoUrl');
    if (!logoUrl) {
      logoUrl = service.getIn(['postingOrganization', 'logoUrl']);
    }
  }

  const createCandidateApplication = () => {
    createExternalCandidateService(currentUser, id);
  };
  const deleteCandidateApplication = () => {
    transitionExternalCandidateServiceByCandidate(currentUser, externalService.get('id'), 'delete');
  };

  const resourceDistanceOrZip =
    service.get('distance') || service.get('distance') === 0 ?
      `${Math.round(parseFloat(service.get('distance')) * 100) / 100} mi` :
      service.getIn(['address', 'zip']);

  const candidateState = externalService && externalService.get('candidateState');
  const externalStatus = externalService && externalService.get('candidateState');

  const appliedToService = !['c_deleted', undefined].includes(candidateState);
  const externalState = !['initialized', undefined].includes(externalStatus);

  return (
    <TileStyles>
      <Logo>
        <img src={logoUrl} alt={service.get('name')} />
        <br />
        <Status>{appliedToService && externalState && <ExternalResourceStatus externalService={externalService} />}</Status>
      </Logo>
      <Header>
        <Pill>Supportive Services</Pill>
        <Details>
          <span className="detail-info">
            <ScheduleIcon />
            {/* TODO: change to whether they're currently open or not */}
            {moment(service.get('startDate')).format('MM/DD/YYYY')}
          </span>
          <span className="detail-info">
            <LocationOnOutlinedIcon />
            {/* {service.getIn(['address', 'zip'])} */}
            {resourceDistanceOrZip}
          </span>
        </Details>
      </Header>
      <Body>
        <Title>{service.get('name')}</Title>
        <Organization>{service.getIn(['postingOrganization', 'name'])}</Organization>
        <ExpandableText>
          <div dangerouslySetInnerHTML={{ __html: service.get('description') }} />
        </ExpandableText>
      </Body>
      <Footer>
        <Services>
          {targetTypes && (
            <div className="service">
              <PersonOutlineOutlinedIcon />
              <span>
                <span className="heading">Serving: </span>
                <span className="body">{targetTypes.map((item) => item[1]).join(', ')}</span>
              </span>
            </div>
          )}
          {serviceTypes && (
            <div className="service">
              <LabelOutlinedIcon />
              <span>
                <span className="heading">Services Provided: </span>
                <span className="body">{serviceTypes.map((item) => item[1]).join(', ')}</span>
              </span>
            </div>
          )}
        </Services>
        <ButtonGroup>
          <div className="subtle-buttons">
            {/* <Button buttonType="subtle-outline"> */}
            {/*  <SendIcon /> */}
            {/*  Share */}
            {/* </Button> */}
            {/* eslint-disable-next-line max-len */}
            <Button
              buttonType="subtle-outline"
              onClick={candidateState && candidateState === 'c_saved' ? deleteCandidateApplication : createCandidateApplication}
            >
              {candidateState && candidateState === 'c_saved' ? (
                <React.Fragment>
                  <CheckIcon />
                  Saved
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <StarIcon />
                  Save
                </React.Fragment>
              )}
            </Button>
            {/* // </div> */}
            {/* // <div className="subtle-buttons"> */}
            {/* <Button buttonType="subtle-outline"> */}
            {/*  <SendIcon /> */}
            {/*  Share */}
            {/* </Button> */}
          </div>
          <Link to={`/candidate/services/${id}`}>
            <Button buttonType="secondary-outline">
              See Details
              <ChevronRightIcon />
            </Button>
          </Link>
        </ButtonGroup>
      </Footer>
    </TileStyles>
  );
};

ServiceTileView.propTypes = propTypes;
ServiceTileView.defaultProps = defaultProps;

export default ServiceTileView;
