import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import JobListStyles, { ResultsPageNavigator } from './job_list_styles';
import JobListRow from './job_list_row';
import IndeedJobListRow from './indeed_job_list_row';
import JobListFilters from './job_list_filters';
import Spinner from '../../../common/components/spinner';
import { useDebouncedEffect } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import theme from '../../../common/theme';

const propTypes = {
  jobs: PropTypes.instanceOf(OrderedSet).isRequired,
  totalStandardJobs: PropTypes.number.isRequired,
  totalIndeedJobs: PropTypes.number.isRequired,
  listFilteredJobPostingsForStaff: PropTypes.func.isRequired,
  listFilteredJobPostingsForStaffOrg: PropTypes.func.isRequired,
  listFilteredIndeedJobPostingsForStaff: PropTypes.func.isRequired,
  removeFilteredIndeedJobPostingsForStaff: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  filters: PropTypes.instanceOf(Map),
  distanceFilter: PropTypes.instanceOf(OrderedSet),
  jobTitleFilter: PropTypes.instanceOf(OrderedSet),
  industryTypeFilter: PropTypes.instanceOf(OrderedSet),
  shiftTitleFilter: PropTypes.instanceOf(OrderedSet),
  salaryFilter: PropTypes.instanceOf(OrderedSet),
  mode: PropTypes.oneOf(['all', 'myOrg', 'drafts']).isRequired,
  totalJobCount: PropTypes.number.isRequired,
};

const defaultProps = {
  search: '',
  filters: Map(),
  distanceFilter: Map(),
  jobTitleFilter: Map(),
  industryTypeFilter: Map(),
  shiftTitleFilter: Map(),
  salaryFilter: Map(),
  zipCode: '',
};

function useSearch(search, setSearch, loaded) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (loaded && search !== newSearch) {
        setSearch(newSearch);
      }
    },
    1500,
    [loaded, search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const JobListView = ({
  jobs,
  totalStandardJobs,
  totalIndeedJobs,
  listFilteredJobPostingsForStaff,
  listFilteredJobPostingsForStaffOrg,
  listFilteredIndeedJobPostingsForStaff,
  removeFilteredIndeedJobPostingsForStaff,
  filters,
  currentUser,
  loaded,
  search,
  setSearch,
  zipCode,
  distanceFilter,
  jobTitleFilter,
  industryTypeFilter,
  shiftTitleFilter,
  salaryFilter,
  mode,
  totalJobCount,
}) => {
  const totalJobs = totalJobCount;
  const [pageShown, setPageShown] = useState({
    all: 1,
    myOrg: 1,
  });
  const totalPages = Math.ceil(totalJobs / 25) || 1;
  const listAll = mode === 'all';
  const isMyOrgTab = mode === 'myOrg';

  // Go back to page one if filtering or searching occurs
  useEffect(() => {
    setPageShown({ all: 1, myOrg: 1 });
  }, [filters, search]);

  // Currently 'search' is mapping text search to server-side 'title'
  const listForStaff = (page) => listFilteredJobPostingsForStaff(currentUser, { page, filters: filters.merge({ title: search, zipCode }) });
  const listIndeedForStaff = (page) => listFilteredIndeedJobPostingsForStaff(currentUser, { page, filters: filters.merge({ title: search, zipCode }) });
  const removeIndeedForStaff = (page) => removeFilteredIndeedJobPostingsForStaff(currentUser, { page, filters: filters.merge({ title: search, zipCode }) });
  const listForStaffOrg = (page) => {
    const mergedFilters = filters.merge({ title: search, zipCode });
    const filtersForRequest = !isMyOrgTab ? mergedFilters : mergedFilters.filter((_, key) => !['distance', 'zipCode'].includes(key));

    return listFilteredJobPostingsForStaffOrg(currentUser, {
      page,
      organizationId: currentUser.getIn(['staffOrganization', 'id']),
      filters: filtersForRequest,
    });
  };

  useEffect(
    () => {
      if (zipCode || isMyOrgTab) {
        if (listAll) {
          listForStaff(pageShown[mode]);
        } else {
          listForStaffOrg(pageShown[mode]);
        }
        const transitionPage = Math.floor(totalStandardJobs / 25) + 1;
        if (mode !== 'myOrg') {
          if (pageShown[mode] < transitionPage) {
            removeIndeedForStaff(pageShown[mode]);
          } else {
            listIndeedForStaff(pageShown[mode] - transitionPage);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loaded, currentUser, filters, search, mode, zipCode, pageShown],
  );

  const scrollTop = () => window.scrollTo(0, 0);
  const getNextPageResults = () => {
    setPageShown({ ...pageShown, [mode]: pageShown[mode] + 1 });
    scrollTop();
  };
  const getPrevPageResults = () => {
    setPageShown({ ...pageShown, [mode]: pageShown[mode] - 1 });
    scrollTop();
  };

  const { newSearch, onSearchChange } = useSearch(search, setSearch, loaded);

  const PageNavigator = ({ bottom }) => {
    if (mode === 'drafts') {
      return null;
    }
    return (
      <ResultsPageNavigator>
        {!bottom && (
          <div>
            <Typography color="secondary">{`Jobs Found: ${totalJobs}`}</Typography>
          </div>
        )}
        {totalJobs > 25 && (
          <div className="prev-next-container">
            <Button
              style={{ color: pageShown <= 0 ? theme.color.grayLight : theme.color.blue }}
              startIcon={<NavigateBeforeIcon />}
              disabled={pageShown[mode] <= 1}
              onClick={() => getPrevPageResults()}
            >
              Prev
            </Button>
            <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown[mode]} of ${totalPages}`}</Typography>
            <Button
              style={{ color: pageShown >= totalPages - 1 ? theme.color.grayLight : theme.color.blue }}
              endIcon={<NavigateNextIcon />}
              disabled={pageShown[mode] >= totalPages}
              onClick={() => getNextPageResults()}
            >
              Next
            </Button>
          </div>
        )}
      </ResultsPageNavigator>
    );
  };

  PageNavigator.propTypes = {
    bottom: PropTypes.bool,
  };
  PageNavigator.defaultProps = {
    bottom: false,
  };

  return (
    <JobListStyles>
      <JobListFilters
        distanceFilter={distanceFilter}
        jobTitleFilter={jobTitleFilter}
        industryTypeFilter={industryTypeFilter}
        shiftTitleFilter={shiftTitleFilter}
        salaryFilter={salaryFilter}
        newSearch={newSearch}
        onSearchChange={onSearchChange}
        isMyOrgTab={isMyOrgTab}
      />
      {!loaded ? (
        <Spinner />
      ) : (
        <div>
          <PageNavigator />
          <table>
            <thead>
              <tr>
                <th width="100">&nbsp;</th>
                <th>Job Post</th>
                <th>Job Type</th>
                <th>Distance</th>
                <th>Salary</th>
                <th>{mode !== 'all' && 'Status'}</th>
                <th width="50">&nbsp;</th>
                <th width="80">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job) => {
                if (job.type === 'indeed-job') {
                  return <IndeedJobListRow key={job.id} jobId={job.id} showDrafts={mode === 'drafts'} />;
                }
                return <JobListRow key={job.id} jobId={job.id} showDrafts={mode === 'drafts'} />;
              })}
            </tbody>
          </table>
          <PageNavigator bottom />
        </div>
      )}
    </JobListStyles>
  );
};

JobListView.propTypes = propTypes;
JobListView.defaultProps = defaultProps;

export default JobListView;
