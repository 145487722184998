import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, MuiFileUpload } from '../../../../../common/form';

const propTypes = {
  pisId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  candidatePathwayId: PropTypes.string.isRequired,
};

const defaultProps = {};

const CandidateStepFileUploadForm = ({ candidatePathwayId, pisId, handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <Field name={`file-${pisId}-${candidatePathwayId}`} component={MuiFileUpload} label="Upload New Document" margin="dense" />
  </form>
);

CandidateStepFileUploadForm.propTypes = propTypes;
CandidateStepFileUploadForm.defaultProps = defaultProps;

const CandidateStepFileUploadView = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  onChange: (values, dispatch, props) => {
    props.submit();
  },
})(CandidateStepFileUploadForm);

export default CandidateStepFileUploadView;
