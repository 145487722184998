import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const DEFAULT_STATE = fromJS({
  invitationsLoading: false,
  invitationsLoaded: 0,
  invitationsList: EMPTY_SET,
  totalInvitations: 0,
  filters: {},
});

const StaffInvitationReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_STAFF_INVITATIONS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          invitationsLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_INVITATIONS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          invitationsLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_STAFF_INVITATIONS.request:
      return state.merge({
        invitationsLoading: true,
      });
    case ACTIONS.LIST_STAFF_INVITATIONS.success:
      return state.merge({
        invitationsList: OrderedSet(action.response.result),
        invitationsLoading: false,
        invitationsLoaded: Date.now(),
        totalInvitations: action.response.totalInvitations,
      });
    case ACTIONS.LIST_STAFF_INVITATIONS.failure:
      return state.merge({
        invitationsLoading: false,
        invitationsLoaded: false,
        invitationsList: EMPTY_SET,
        totalInvitations: 0,
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search');

export const getLoaded = (state) => state.get('invitationsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getInvitationIds = (state) => state.get('invitationsList') || DEFAULT_STATE.get('invitationsList');

export const getTotalInvitations = (state) => state.get('totalInvitations') || DEFAULT_STATE.get('invitationsList');

export default StaffInvitationReducer;
