import { connect } from 'react-redux';
import PathwayStepView from './pathway_step_view';
import { getCurrentUser } from '../../../../reducer';
import { transitionPathwayStepStaffState, fetchPathwayInstance } from '../../../../solve/actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

const mapDispatchToProps = {
  transitionPathwayStepStaffState,
  fetchPathwayInstance,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  transitionStaffState: async (instanceStepId, state) => {
    await dispatchProps.transitionPathwayStepStaffState(stateProps.user, instanceStepId, state.substring(2));
    dispatchProps.fetchPathwayInstance(stateProps.user, ownProps.pathwayInstance.get('id'));
  },
  ...stateProps,
  ...ownProps,
});

const PathwayStepContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayStepView);

export default PathwayStepContainer;
