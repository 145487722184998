import { connect } from 'react-redux';
import ParentAccountReportDemographicsView from './parent_account_report_demographics_view';
import { listPathwayInstances } from '../../../../solve/actions';
import { getChildOrganizations, getCurrentUser, getPathways } from '../../../../reducer';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  childOrganizations: getChildOrganizations(state, getCurrentUser(state).getIn(['staffOrganization', 'id'])),
  pathways: getPathways(state),
});

const mapDispatchToProps = {
  listPathwayInstances,
};
const ParentAccountReportDemographicsContainer = connect(mapStateToProps, mapDispatchToProps)(ParentAccountReportDemographicsView);

export default ParentAccountReportDemographicsContainer;
