const REQUIRED = 'Required';

const NotesNewValidate = (values) => {
  const errors = {};

  if (!values.get('toUserId')) {
    errors.toUserId = REQUIRED;
  }
  if (!values.get('messageSubject')) {
    errors.messageSubject = REQUIRED;
  }
  if (!values.get('messageBody')) {
    errors.messageBody = REQUIRED;
  }
  return errors;
};

export default NotesNewValidate;
