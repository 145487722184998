/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import moment from 'moment';
import { Map, getIn } from 'immutable';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import { extractCandidateFullName } from 'util/common';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EmployeeTypeEnum } from 'risekit/common/enum/employee.enum';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import { isNonProfitInmateUser } from 'util/common';

import { useFetchOnce } from '../../../util/hooks';
import { Field, MuiCheckbox } from '../../../common/form';
import UserModel from '../../../session/models/user_model';
import RowStyles, { Status } from './listing_applicant_row_styles';

function getStatusIcon(status) {
  let icon;
  switch (status) {
    case 'in progress':
      icon = <FiberManualRecordIcon className="in-progress" />;
      break;
    case 'complete':
      icon = <CheckCircleIcon className="circle" />;
      break;
    default:
    case 'not started':
      icon = <RadioButtonUncheckedIcon className="circle" />;
  }
  return icon;
}

const ListingApplicantRowView = ({ currentUser, candidateId, candidate, fetchCandidate, checkbox }) => {
  useFetchOnce(currentUser, candidateId, fetchCandidate, candidate);

  const resumeStatusIcon = getStatusIcon(candidate.get('resumeStatus'));
  const challengesStatusIcon = getStatusIcon(candidate.get('challengesCompletion'));
  const isEmployer = getIn(currentUser, ['staffOrganization', 'orgType']) === EmployeeTypeEnum.EMPLOYER;

  return (
    <React.Fragment>
      {candidate && (
        <RowStyles>
          {checkbox && (
            <td>
              <FormControlLabel
                className="answer"
                control={
                  <Field
                    component={MuiCheckbox}
                    name={candidate.get('id')}
                    variant="outlined"
                    label={`${candidate.get('user').get('firstName')} ${candidate.get('user').get('lastName')}`}
                  />
                }
              />
            </td>
          )}
          <td>{extractCandidateFullName(candidate.get('user'), currentUser)}</td>
          <td>
            <Status>
              {resumeStatusIcon} {startCase(candidate.get('resumeStatus'))}
            </Status>
          </td>
          {!isEmployer ? (
            <td>
              <Status>
                {challengesStatusIcon} {startCase(candidate.get('challengesCompletion'))}
              </Status>
            </td>
          ) : null}

          {!isNonProfitInmateUser(currentUser) ? (
            <td>{candidate.getIn(['address', 'zip'])}</td>
          ) : (
            <React.Fragment>
              <td>{candidate.getIn(['inmate', 'facility'])}</td>{' '}
              <td>{candidate.getIn(['inmate', 'inmateNumber'])}</td>
            </React.Fragment>
          )}
          <td>{`${moment(candidate.get('createdAt')).format('MM/DD/YYYY')}`}</td>
          {!checkbox && <td>{candidate.get('lastActivityDate') && `${moment(candidate.get('lastActivityDate')).format('MM/DD/YYYY')}`}</td>}
          {!checkbox && (
            <td>
              <Link to={`/staff/candidates/${candidateId}`}>
                View
                <ChevronRightIcon />
              </Link>
            </td>
          )}
        </RowStyles>
      )}
    </React.Fragment>
  );
};

ListingApplicantRowView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map).isRequired,
  candidateId: PropTypes.string.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  checkbox: PropTypes.bool,
};
ListingApplicantRowView.defaultProps = {
  checkbox: false,
};

export default ListingApplicantRowView;
