import styled from 'styled-components';
import theme from '../../../common/theme';

const CandidatesStyles = styled.div``;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 24px;
  & th {
    padding: 0px 10px;
    text-align: left;
    text-transform: uppercase;
    color: ${theme.color.text.medium};
    font-size: 12px;
    letter-spacing: 0.1em;
  }
  & th svg {
    margin-bottom: -5px;
  }
  & td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    padding: 0px 10px;
  }
  .filter-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: 0;
    border: 0;
  }
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f2fb;
  color: #8752cd;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 24px;
  margin: 10px 0px 0px;

  @media ${theme.query.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ResultsPageNavigator = styled.div`
  display: flex;
  align-content: flex-start;
  margin: 0px 0px 16px;
  .prev-next-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    & > span {
      margin: 0px 12px;
    }
  }
`;

export default CandidatesStyles;
