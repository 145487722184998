import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { useHistory } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as toast from 'risekit/common/components/toast';

import { ActionRow } from '../welcome_styles';
import howHearFormValidate from './how_hear_form_validate';
import UserModel from '../../../../session/models/user_model';
import Button from '../../../../common/components/button/index';
import { reduxForm, Field, MuiRadioGroup, MuiTextField } from '../../../../common/form';
import OrganizationsSelector from '../../../../solve/components/organizations_selector';

const propTypes = {
  listFilteredOrganizations: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  orgTypeSelected: PropTypes.string,
};

const defaultProps = {
  orgTypeSelected: '',
};

const HowHearForm = ({ listFilteredOrganizations, currentUser, handleSubmit, submitting, orgTypeSelected }) => {
  const history = useHistory();

  useEffect(() => {
    listFilteredOrganizations(currentUser, { type: orgTypeSelected });
  }, [currentUser, listFilteredOrganizations, orgTypeSelected]);

  const onFormSubmit = async (values) => {
    try {
      await handleSubmit(values);
      history.push('/notifications-settings');
    } catch (error) {
      toast.error({ title: 'Error', message: 'Error submitting the form.' });
    }
  };

  return (
    <form onSubmit={onFormSubmit}>
      <Grid container justify="center" alignItems="flex-start" spacing={0}>
        <Grid item xs={12}>
          <Grid container justify="center" alignItems="flex-start" spacing={2}>
            <Grid item xs={11}>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <p>We use this information to help you find a job</p>
                  <h1>How did you hear about us?</h1>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={11}>
                  <Field name="howFoundReferralOrgType" component={MuiRadioGroup}>
                    <FormGroup row>
                      <FormControlLabel value="nonprofit" control={<Radio color="primary" />} label="From a nonprofit" />
                      <FormControlLabel value="employer" control={<Radio color="primary" />} label="From an employer" />
                      <FormControlLabel value="other" control={<Radio color="primary" />} label="From another source" />
                    </FormGroup>
                  </Field>
                </Grid>
                <Grid item xs={12}>
                  {/* {orgTypeSelected && orgTypeSelected === 'nonprofit' && (
                    <Grid item xs={12}>
                      <Field component={MuiTextField} name="howFoundReferralName" label="Type nonprofit name" variant="outlined" fullWidth />
                      <p>or</p>
                      <MuiSelect name="howFoundReferralName" options={nonprofitNames} label="Select nonprofit" />
                    </Grid>
                  )}
                  {orgTypeSelected && orgTypeSelected === 'employer' && (
                    <Grid item xs={12}>
                      <Field component={MuiTextField} name="howFoundReferralName" label="Type employer name" variant="outlined" fullWidth />
                      <p>or</p>
                      <MuiSelect name="howFoundReferralName" options={employerNames} label="Select employer" />
                    </Grid>
                  )}
                  {orgTypeSelected && orgTypeSelected === 'other' && (
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        name="howFoundReferralName"
                        label="Please type in the name/company/organization that referred you"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  )} */}
                  {orgTypeSelected &&
                    (orgTypeSelected !== 'other' ? (
                      <React.Fragment>
                        <Field component={OrganizationsSelector} mode={orgTypeSelected} name="howFoundReferralName" />
                      </React.Fragment>
                    ) : (
                      <Field
                        component={MuiTextField}
                        name="howFoundReferralName"
                        label="Please type in the name/company/organization that referred you"
                        variant="outlined"
                        fullWidth
                      />
                    ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <ActionRow>
                    <Button buttonType="text" type="submit" disabled={submitting} onClick={onFormSubmit}>
                      Submit
                    </Button>
                  </ActionRow>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

HowHearForm.propTypes = propTypes;
HowHearForm.defaultProps = defaultProps;

const HowHearFormView = reduxForm({
  form: 'howHearForm',
  enableReinitialize: true,
  validate: howHearFormValidate,
})(HowHearForm);

export default HowHearFormView;
