import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
// import EditableCardToggle from '../editable_card_toggle';
// import Display from './display';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import Editing from './editing';
import EducationStyles from './education_styles';
import { Card, Right } from '../../../../common/styles/layout';
import { AddButton } from '../../../../common/components/buttons';

const propTypes = {
  // educations: PropTypes.instanceOf(List),
  // onSubmit: PropTypes.func.isRequired,
  // onEducationCreate: PropTypes.func.isRequired,
  // onEducationDelete: PropTypes.func.isRequired,
  fields: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, remove: PropTypes.func, length: PropTypes.number }).isRequired,
};

const defaultProps = {};

const EducationRender = ({ namespace, onDelete }) => (
  // const [editing, setEditing] = useState(false);
  // const activeView = editing ? <Editing initialValues={education.toJS()} onSubmit={onSubmit} /> : <Display education={education} />;
  // const onDelete = () => onEducationDelete(education.get('id'));
  // const onSave = () => {
  //   if (onProgress) {
  //     onProgress();
  //   }
  //   setEditing(false);
  // };
  // const onEdit = () => setEditing(true);
  // const onCancel = () => {
  //   setEditing(false);
  // };

  <Card>
    {/* <EditableCardToggle editing={editing} onDelete={onDelete} onSave={onSave} onEdit={onEdit} onCancel={onCancel} formName="education" /> */}
    <Right>
      <IconButton onClick={onDelete} aria-label="delete">
        <ClearIcon fontSize="small" />
      </IconButton>
    </Right>
    <Editing namespace={namespace} />
  </Card>
);
EducationRender.propTypes = {
  // education: PropTypes.instanceOf(Map).isRequired,
  // onProgress: PropTypes.func,
  // onSubmit: PropTypes.func.isRequired,
  // onEducationDelete: PropTypes.func.isRequired,
  namespace: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

EducationRender.defaultProps = {
  // onProgress: undefined,
};

const EducationView = ({ fields }) => (
  <EducationStyles>
    <h3>Education</h3>
    {fields.map((namespace, index) => (
      <EducationRender key={namespace} namespace={namespace} onDelete={() => fields.remove(index)} />
    ))}
    <Right>
      <AddButton onClick={() => fields.push(fromJS({ education: {} }))}>{fields.length <= 0 ? 'Add An Education' : 'Add Another Education'}</AddButton>
    </Right>
  </EducationStyles>
);

EducationView.propTypes = propTypes;
EducationView.defaultProps = defaultProps;

export default EducationView;
