import { createSelector } from 'reselect';
import { combineImmutableReducers } from '../../util';
import StatusReducer from './status_reducer';
import CandidateSurveyReducer from './candidate_survey_reducer';
import CandidateOrganizationReducer from './candidate_organization_reducer';
import CandidateApplicationReducer from './candidate_application_reducer';
import CandidateEventReducer from './candidate_event_reducer';
import CandidateProgramReducer from './candidate_program_reducer';
import ExternalCandidateServiceReducer from './external_candidate_service_reducer';
import JobPostingReducer from './job_posting_reducer';
import IndeedJobPostingReducer from './indeed_job_posting_reducer';
import OrganizationReducer from './organization_reducer';
import ResumeReducer from './resume_reducer';
import ChallengeReducer from './challenge_reducer';
import SurveyReducer from './survey_reducer';
import ProgramReducer from './program_reducer';
import EventReducer from './event_reducer';
import PublicLinkReducer from './public_link_reducer';
import InvitationReducer from './invitation_reducer';
import InmateReducer from './inmate_reducer';
import StaffInvitationReducer, * as StaffInvitationSelectors from './staff_invitation_reducer';
import StaffReducer from './staff_reducer';
import MessageReducer from './message_reducer';
import NoteReducer from './note_reducer';
import NotificationReducer from './notification_reducer';
import PathwaysReducer, * as PathwaysSelectors from './pathways_reducer';
import CandidateJobListingsReducer, * as CandidateJobListingsSelectors from './candidate_job_listings_reducer';
import IndeedJobListingsReducer, * as IndeedJobListingsSelectors from './indeed_job_listings_reducer';
import CandidateRecommendedListingsReducer, * as CandidateRecommendedListingsSelectors from './candidate_recommended_listings_reducer';
import StaffJobListingsReducer, * as StaffJobListingsSelectors from './staff_job_listings_reducer';
import StaffIndeedJobListingsReducer, * as StaffIndeedJobListingsSelectors from './staff_indeed_job_listings_reducer';
import StaffCandidatesReducer, * as StaffCandidatesSelectors from './staff_candidates_reducer';
import StaffProgramCandidatesReducer, * as StaffProgramCandidatesSelectors from './staff_program_candidates_reducer';
import StaffEventCandidatesReducer, * as StaffEventCandidatesSelectors from './staff_event_candidates_reducer';
import StaffJobCandidatesReducer, * as StaffJobCandidatesSelectors from './staff_job_candidates_reducer';
import StaffEPCandidatesReducer, * as StaffEPCandidatesSelectors from './staff_ep_candidates_reducer';
import StaffCandidateApplicationsReducer, * as StaffCandidateApplicationsSelectors from './staff_candidate_applications_reducer';
import CandidateEventListingsReducer, * as CandidateEventListingsSelectors from './candidate_event_listings_reducer';
import CandidateProgramListingsReducer, * as CandidateProgramListingsSelectors from './candidate_program_listings_reducer';
import CandidateServiceListingsReducer, * as CandidateSupportiveServicesListingSelectors from './candidate_service_listings_reducer';
import GlobalSurveyReducer, * as GlobalSurveySelectors from './global_survey_reducer';
import CandidateReducer from './candidate_reducer';
import PeerStaffReducer, * as PeerStaffSelectors from './peer_staff_reducer';
import ChildOrganizationReducer, * as ChildOrganizationSelectors from './child_organization_reducer';
import ReportsReducer, * as ReportsSelectors from './reports_reducer';
import ServiceReducer from './service_reducer';
import CandidateServiceReducer from './candidate_service_reducer';

const REDUCERS = {
  formStatus: StatusReducer,
  candidateOrganizations: CandidateOrganizationReducer,
  candidateApplications: CandidateApplicationReducer,
  candidateEvents: CandidateEventReducer,
  candidateEventListings: CandidateEventListingsReducer,
  candidateServicesListings: CandidateServiceListingsReducer,
  candidatePrograms: CandidateProgramReducer,
  externalCandidateServices: ExternalCandidateServiceReducer,
  candidateServices: CandidateServiceReducer,
  candidateProgramListings: CandidateProgramListingsReducer,
  candidateSurveys: CandidateSurveyReducer,
  jobPostings: JobPostingReducer,
  indeedJobPostings: IndeedJobPostingReducer,
  organizations: OrganizationReducer,
  childOrganizations: ChildOrganizationReducer,
  resumes: ResumeReducer,
  challenges: ChallengeReducer,
  surveys: SurveyReducer,
  gs: GlobalSurveyReducer,
  programs: ProgramReducer,
  services: ServiceReducer,
  publicLinks: PublicLinkReducer,
  events: EventReducer,
  invitations: InvitationReducer,
  inmates: InmateReducer,
  staffInvitations: StaffInvitationReducer,
  candidateJobListings: CandidateJobListingsReducer,
  indeedJobListings: IndeedJobListingsReducer,
  candidateRecommendedListings: CandidateRecommendedListingsReducer,
  staffJobListings: StaffJobListingsReducer,
  staffIndeedJobListings: StaffIndeedJobListingsReducer,
  staffCandidates: StaffCandidatesReducer,
  staffProgramCandidates: StaffProgramCandidatesReducer,
  staffEventCandidates: StaffEventCandidatesReducer,
  staffJobCandidates: StaffJobCandidatesReducer,
  staffEPCandidates: StaffEPCandidatesReducer,
  staffCandidateApplications: StaffCandidateApplicationsReducer,
  staff: StaffReducer,
  peerStaff: PeerStaffReducer,
  candidates: CandidateReducer,
  messages: MessageReducer,
  notes: NoteReducer,
  notifications: NotificationReducer,
  reports: ReportsReducer,
  pathways: PathwaysReducer,
};

const Reducer = combineImmutableReducers(REDUCERS);

export default Reducer;

// Selectors
// Note: these functions operate on state at the level of the Solve, not the RootReducer.
export const getFormStatus = (state) => state.get('formStatus');

export const getCandidateOrganizations = (state) => CandidateOrganizationReducer.selectors.getList(state.get('candidateOrganizations'));
export const getCandidateOrganization = (state, id) => CandidateOrganizationReducer.selectors.getOne(state.get('candidateOrganizations'), id);

export const getCandidateApplications = (state, list) => CandidateApplicationReducer.selectors.getList(state.get('candidateApplications'), list);
export const getCandidateApplication = (state, id) => CandidateApplicationReducer.selectors.getOne(state.get('candidateApplications'), id);

export const getCandidateApplicationIds = (state) => StaffCandidateApplicationsSelectors.getCandidateApplicationIds(state.get('staffCandidateApplications'));
export const getCandidateApplicationFilters = (state) => StaffCandidateApplicationsSelectors.getFilters(state.get('staffCandidateApplications'));
export const getCandidateApplicationSearch = (state) => StaffCandidateApplicationsSelectors.getSearch(state.get('staffCandidateApplications'));

export const getCandidateExternalApplicationIds = (state) =>
  StaffCandidateApplicationsSelectors.getCandidateExternalApplicationIds(state.get('staffCandidateApplications'));

export const getCandidateEvents = (state, list) => CandidateEventReducer.selectors.getList(state.get('candidateEvents'), list);
export const getCandidateEvent = (state, id) => CandidateEventReducer.selectors.getOne(state.get('candidateEvents'), id);

export const getCandidatePrograms = (state, list) => CandidateProgramReducer.selectors.getList(state.get('candidatePrograms'), list);
export const getCandidateProgram = (state, id) => CandidateProgramReducer.selectors.getOne(state.get('candidatePrograms'), id);

export const getCandidateServices = (state, list) => CandidateServiceReducer.selectors.getList(state.get('candidateServices'), list);
export const getCandidateService = (state, id) => CandidateServiceReducer.selectors.getOne(state.get('candidateServices'), id);

export const getCandidateSurveys = (state, list) => CandidateSurveyReducer.selectors.getList(state.get('candidateSurveys'), list);
export const getCandidateSurvey = (state, id) => CandidateSurveyReducer.selectors.getOne(state.get('candidateSurveys'), id);
export const getCandidateSurveysForStaff = createSelector(
  getCandidateSurveys,
  (_, candidateId) => candidateId,
  (surveys, candidateId) => surveys.filter((survey) => survey.getIn(['candidate', 'candidateId']) === candidateId),
);

export const getJobPostings = (state) => JobPostingReducer.selectors.getList(state.get('jobPostings'));
export const getJobPosting = (state, id) => JobPostingReducer.selectors.getOne(state.get('jobPostings'), id);
export const getIndeedJobPosting = (state, id) => IndeedJobPostingReducer.selectors.getOne(state.get('indeedJobPostings'), id);

const MINUTES = 60 * 1000;
export const getOrganizationsLoaded = (state) => {
  const lastUpdated = OrganizationReducer.selectors.getLastUpdated(state.get('organizations'));
  return lastUpdated && Date.now() < lastUpdated + 5 * MINUTES;
};

export const getOrganizations = (state) => OrganizationReducer.selectors.getList(state.get('organizations'));
export const getOrganization = (state, id) => OrganizationReducer.selectors.getOne(state.get('organizations'), id);

export const getChildOrganizations = (state, organizationId) =>
  ChildOrganizationSelectors.getChildOrganizations(state.get('childOrganizations'), organizationId);

// If there are no resumes, there may be an unsaved resume, so return the unsaved (id=undefined) record if found
export const getResumes = createSelector(
  (state) => ResumeReducer.selectors.getList(state.get('resumes')),
  (state) => ResumeReducer.selectors.getOne(state.get('resumes'), 'null'),
  (list, unsaved) => (unsaved ? list.add(unsaved) : list),
);
export const getResume = (state, id) => ResumeReducer.selectors.getOne(state.get('resumes'), id);
export const getCandidateResumeForCandidate = createSelector(
  getResumes,
  (_, candidateId) => candidateId,
  (resumes, candidateId) => resumes.find((resume) => resume.getIn(['candidate', 'candidateId']) === candidateId),
);
export const getCandidateResumesForStaff = createSelector(
  getResumes,
  (_, candidateId) => candidateId,
  (resumes, candidateId) => resumes.filter((resume) => resume.getIn(['candidate', 'candidateId']) === candidateId),
);

export const getInmate = (state, candidateId) => InmateReducer.selectors.getOne(state.get('inmates'), candidateId);

export const getChallenges = (state) => ChallengeReducer.selectors.getList(state.get('challenges'));
export const getChallenge = (state, id) => ChallengeReducer.selectors.getOne(state.get('challenges'), id);

export const getSurveys = (state) => SurveyReducer.selectors.getList(state.get('surveys'));
export const getSurvey = (state, id) => SurveyReducer.selectors.getOne(state.get('surveys'), id);

export const getGlobalSurveysOfType = (state, type) => GlobalSurveySelectors.getByType(state.get('gs'), type);
export const getNeedGlobalSurveys = (state) => GlobalSurveySelectors.getNeed(state.get('gs'));
export const getGlobalSurveysBackingOff = (state) => GlobalSurveySelectors.getBackingOff(state.get('gs'));

export const getPrograms = (state, list) => ProgramReducer.selectors.getList(state.get('programs'), list);
export const getProgram = (state, id) => ProgramReducer.selectors.getOne(state.get('programs'), id);

export const getEvents = (state, list) => EventReducer.selectors.getList(state.get('events'), list);
export const getServices = (state, list) => ServiceReducer.selectors.getList(state.get('services'), list);
export const getService = (state, id) => ServiceReducer.selectors.getOne(state.get('services'), id);

export const getEvent = (state, id) => EventReducer.selectors.getOne(state.get('events'), id);

export const getInvitations = (state, list) => InvitationReducer.selectors.getList(state.get('invitations'), list);
export const getAllInvitationsForListing = (state, recommendedId) => getInvitations(state).filter((invite) => invite.get('recommendedId') === recommendedId);
export const getInvitation = (state, id) => InvitationReducer.selectors.getOne(state.get('invitations'), id);
export const getStaffInvitationIds = (state) => StaffInvitationSelectors.getInvitationIds(state.get('staffInvitations'));
export const getStaffInvitationsTotal = (state) => StaffInvitationSelectors.getTotalInvitations(state.get('staffInvitations'));
// export const getStaffInvitationFilters = (state) => StaffInvitationSelectors.getFilters(state.get('staffInvitations'));
// export const getStaffInvitationSearch = (state) => StaffInvitationSelectors.getSearch(state.get('staffInvitations'));

export const getPathways = (state, list) => PathwaysSelectors.getPathways(state.get('pathways'), list);
export const getPathway = (state, id) => PathwaysSelectors.getPathway(state.get('pathways'), id);

export const getPathwayInstances = (state, list) => PathwaysSelectors.getPathwayInstances(state.get('pathways'), list);
export const getPathwayInstance = (state, id) => PathwaysSelectors.getPathwayInstance(state.get('pathways'), id);

export const getCandidatePathways = (state, list) => PathwaysSelectors.getCandidatePathways(state.get('pathways'), list);
export const getCandidatePathway = (state, id) => PathwaysSelectors.getCandidatePathway(state.get('pathways'), id);
export const getCandidatePathwaysForPathwayInstance = (state, pathwayInstanceId) =>
  PathwaysSelectors.getCandidatePathwaysForPathwayInstance(state.get('pathways'), pathwayInstanceId);
export const getCandidatePathwaysForPathwayInstances = (state, pathwayInstanceIds) =>
  PathwaysSelectors.getCandidatePathwaysForPathwayInstances(state.get('pathways'), pathwayInstanceIds);
export const getCandidatePathwaysFromOrganizationIdAndPathwayId = (state, organizationId, pathwayId) =>
  PathwaysSelectors.getCandidatePathwaysFromOrganizationIdAndPathwayId(state.get('pathways'), organizationId, pathwayId);

export const getPathwayReportFilters = (state) => PathwaysSelectors.getPathwayReportFilters(state.get('pathways'));

export const getCandidateJobListingsZipcode = (state) => CandidateJobListingsSelectors.getZipcode(state.get('candidateJobListings'));
export const getCandidateJobListingsDistance = (state) => CandidateJobListingsSelectors.getDistance(state.get('candidateJobListings'));
export const getCandidateJobListingsFilters = (state) => CandidateJobListingsSelectors.getFilters(state.get('candidateJobListings'));
export const getCandidateJobListingsSearch = (state) => CandidateJobListingsSelectors.getSearch(state.get('candidateJobListings'));
export const getCandidateJobListingsLoaded = (state) => CandidateJobListingsSelectors.getLoaded(state.get('candidateJobListings'));
export const getCandidateJobListingsLoading = (state) => CandidateJobListingsSelectors.getLoading(state.get('candidateJobListings'));
export const getCandidateJobListingIds = (state) => CandidateJobListingsSelectors.getJobIds(state.get('candidateJobListings'));
export const getCandidateJobListingsPage = (state) => CandidateJobListingsSelectors.getPage(state.get('candidateJobListings'));
export const getCandidateTotalJobListings = (state) => CandidateJobListingsSelectors.getTotalJobs(state.get('candidateJobListings'));
export const getCandidateAppliedJobIds = (state) => CandidateJobListingsSelectors.getAppliedIds(state.get('candidateJobListings'));
export const getCandidateAppliedJobsLoaded = (state) => CandidateJobListingsSelectors.getAppliedLoaded(state.get('candidateJobListings'));
export const getCandidateAppliedJobsLoading = (state) => CandidateJobListingsSelectors.getAppliedLoading(state.get('candidateJobListings'));
export const getCandidateSavedJobIds = (state) => CandidateJobListingsSelectors.getSavedIds(state.get('candidateJobListings'));
export const getCandidateSavedJobsLoaded = (state) => CandidateJobListingsSelectors.getSavedLoaded(state.get('candidateJobListings'));
export const getCandidateSavedJobsLoading = (state) => CandidateJobListingsSelectors.getSavedLoading(state.get('candidateJobListings'));
export const getCandidateFilteredCandidateApplicationIds = (state) => CandidateJobListingsSelectors.getApplicationIds(state.get('candidateJobListings'));

export const getIndeedCandidateJobListingsZipcode = (state) => IndeedJobListingsSelectors.getZipcode(state.get('indeedJobListings'));
export const getIndeedCandidateJobListingsDistance = (state) => IndeedJobListingsSelectors.getDistance(state.get('indeedJobListings'));
export const getIndeedCandidateJobListingsFilters = (state) => IndeedJobListingsSelectors.getFilters(state.get('indeedJobListings'));
export const getIndeedCandidateJobListingsSearch = (state) => IndeedJobListingsSelectors.getSearch(state.get('indeedJobListings'));
export const getIndeedCandidateJobListingsLoaded = (state) => IndeedJobListingsSelectors.getLoaded(state.get('indeedJobListings'));
export const getIndeedCandidateJobListingsLoading = (state) => IndeedJobListingsSelectors.getLoading(state.get('indeedJobListings'));
export const getIndeedCandidateJobListingIds = (state) => IndeedJobListingsSelectors.getJobIds(state.get('indeedJobListings'));
export const getIndeedCandidateTotalJobListings = (state) => IndeedJobListingsSelectors.getTotalJobs(state.get('indeedJobListings'));
export const getIndeedCandidateAppliedJobIds = (state) => IndeedJobListingsSelectors.getAppliedIds(state.get('indeedJobListings'));
export const getIndeedCandidateAppliedJobsLoaded = (state) => IndeedJobListingsSelectors.getAppliedLoaded(state.get('indeedJobListings'));
export const getIndeedCandidateAppliedJobsLoading = (state) => IndeedJobListingsSelectors.getAppliedLoading(state.get('indeedJobListings'));
export const getIndeedCandidateSavedJobIds = (state) => IndeedJobListingsSelectors.getSavedIds(state.get('indeedJobListings'));
export const getIndeedCandidateSavedJobsLoaded = (state) => IndeedJobListingsSelectors.getSavedLoaded(state.get('indeedJobListings'));
export const getIndeedCandidateSavedJobsLoading = (state) => IndeedJobListingsSelectors.getSavedLoading(state.get('indeedJobListings'));
export const getIndeedCandidateFilteredCandidateApplicationIds = (state) => IndeedJobListingsSelectors.getApplicationIds(state.get('indeedJobListings'));

export const getRecommendedListingIds = (state) => CandidateRecommendedListingsSelectors.getJobIds(state.get('candidateRecommendedListings'));

export const getCandidateApplicationLoaded = (state) =>
  StaffCandidateApplicationsSelectors.getCandidateApplicationLoaded(state.get('staffCandidateApplications'));

export const getCandidateEventsZipcode = (state) => CandidateEventListingsSelectors.getZipcode(state.get('candidateEventListings'));
export const getCandidateEventsFilters = (state) => CandidateEventListingsSelectors.getFilters(state.get('candidateEventListings'));
export const getCandidateEventsSearch = (state) => CandidateEventListingsSelectors.getSearch(state.get('candidateEventListings'));
export const getCandidateEventsLoaded = (state) => CandidateEventListingsSelectors.getLoaded(state.get('candidateEventListings'));
export const getCandidateEventIds = (state) => CandidateEventListingsSelectors.getEventIds(state.get('candidateEventListings'));
export const getCandidateAppliedEventIds = (state) => CandidateEventListingsSelectors.getAppliedIds(state.get('candidateEventListings'));
export const getCandidateAppliedEventsLoaded = (state) => CandidateEventListingsSelectors.getAppliedLoaded(state.get('candidateEventListings'));
export const getCandidateSavedEventIds = (state) => CandidateEventListingsSelectors.getSavedIds(state.get('candidateEventListings'));
export const getCandidateSavedEventsLoaded = (state) => CandidateEventListingsSelectors.getSavedLoaded(state.get('candidateEventListings'));
export const getCandidateFilteredCandidateEventIds = (state) => CandidateEventListingsSelectors.getCandidateEventIds(state.get('candidateEventListings'));

export const getCandidateProgramsZipCode = (state) => CandidateProgramListingsSelectors.getZipCode(state.get('candidateProgramListings'));
export const getCandidateProgramsFilters = (state) => CandidateProgramListingsSelectors.getFilters(state.get('candidateProgramListings'));
export const getCandidateProgramsPage = (state) => CandidateProgramListingsSelectors.getPage(state.get('candidateProgramListings'));
export const getCandidateProgramsSortBy = (state) => CandidateProgramListingsSelectors.getSortBy(state.get('candidateProgramListings'));
export const getCandidateProgramsDistance = (state) => CandidateProgramListingsSelectors.getDistance(state.get('candidateProgramListings'));
export const getCandidateProgramsFiltersSortBy = (state) => CandidateProgramListingsSelectors.getSortBy(state.get('candidateProgramListings'));
export const getCandidateProgramsSearch = (state) => CandidateProgramListingsSelectors.getSearch(state.get('candidateProgramListings'));
export const getCandidateProgramsLoaded = (state) => CandidateProgramListingsSelectors.getLoaded(state.get('candidateProgramListings'));
export const getCandidateProgramIds = (state) => CandidateProgramListingsSelectors.getProgramIds(state.get('candidateProgramListings'));
export const getCandidateAppliedProgramIds = (state) => CandidateProgramListingsSelectors.getAppliedIds(state.get('candidateProgramListings'));
export const getCandidateAppliedProgramsLoaded = (state) => CandidateProgramListingsSelectors.getAppliedLoaded(state.get('candidateProgramListings'));
export const getCandidateSavedProgramIds = (state) => CandidateProgramListingsSelectors.getSavedIds(state.get('candidateProgramListings'));
export const getCandidateSavedProgramsLoaded = (state) => CandidateProgramListingsSelectors.getSavedLoaded(state.get('candidateProgramListings'));
export const getCandidateFilteredCandidateProgramIds = (state) =>
  CandidateProgramListingsSelectors.getCandidateProgramIds(state.get('candidateProgramListings'));

// CandidateSupportiveServicesListingSelectors
export const getCandidateServiceIds = (state) => CandidateSupportiveServicesListingSelectors.getServiceIds(state.get('candidateServicesListings'));
export const getCandidateServicesLoaded = (state) => CandidateSupportiveServicesListingSelectors.getServicesLoaded(state.get('candidateServicesListings'));
export const getExternalServiceIds = (state) => CandidateSupportiveServicesListingSelectors.getExternalServiceIds(state.get('candidateServicesListings'));
export const getExternalServicesLoaded = (state) =>
  CandidateSupportiveServicesListingSelectors.getExternalServicesLoaded(state.get('candidateServicesListings'));
export const getCandidateAppliedServiceIds = (state) =>
  CandidateSupportiveServicesListingSelectors.getAppliedServiceIds(state.get('candidateServicesListings'));
export const getCandidateAppliedServicesLoaded = (state) =>
  CandidateSupportiveServicesListingSelectors.getAppliedServicesLoaded(state.get('candidateServicesListings'));
export const getCandidateSavedServiceIds = (state) => CandidateSupportiveServicesListingSelectors.getSavedServiceIds(state.get('candidateServicesListings'));
export const getCandidateSavedServicesLoaded = (state) =>
  CandidateSupportiveServicesListingSelectors.getSavedServicesLoaded(state.get('candidateServicesListings'));

export const getStaffEPCandidatesFilters = (state) => StaffEPCandidatesSelectors.getFilters(state.get('staffEPCandidates'));
export const getStaffEPCandidatesSearch = (state) => StaffEPCandidatesSelectors.getSearch(state.get('staffEPCandidates'));
export const getStaffEPCandidatesZipCode = (state) => StaffEPCandidatesSelectors.getZipCode(state.get('staffEPCandidates'));
export const getStaffEPCandidatesLoaded = (state) => StaffEPCandidatesSelectors.getCandidatesEventsLoaded(state.get('staffEPCandidates'));
export const getStaffEPFilteredEventIds = (state) => StaffEPCandidatesSelectors.getFilteredEventIds(state.get('staffEPCandidates'));
export const getStaffEPFilteredEventsLoaded = (state) => StaffEPCandidatesSelectors.getFilteredEventsLoaded(state.get('staffEPCandidates'));
export const getStaffEPFilteredProgramsLoaded = (state) => StaffEPCandidatesSelectors.getFilteredProgramsLoaded(state.get('staffEPCandidates'));
export const getStaffEPFilteredProgramIds = (state) => StaffEPCandidatesSelectors.getFilteredProgramIds(state.get('staffEPCandidates'));
export const getStaffEPFilteredServicesLoaded = (state) => StaffEPCandidatesSelectors.getFilteredServicesLoaded(state.get('staffEPCandidates'));
export const getStaffEPFilteredServiceIds = (state) => StaffEPCandidatesSelectors.getFilteredServiceIds(state.get('staffEPCandidates'));
export const getStaffEPABServicesLoaded = (state) => StaffEPCandidatesSelectors.getABServicesLoaded(state.get('staffEPCandidates'));
export const getStaffEPABServiceIds = (state) => StaffEPCandidatesSelectors.getABServiceIds(state.get('staffEPCandidates'));
export const getCandidateProgramIdsForStaff = (state) => StaffEPCandidatesSelectors.getCandidatesProgramsIds(state.get('staffEPCandidates'));
export const getCandidateEventIdsForStaff = (state) => StaffEPCandidatesSelectors.getCandidatesEventsIds(state.get('staffEPCandidates'));
export const getABServiceIdsForStaff = (state) => StaffEPCandidatesSelectors.getABServiceIds(state.get('staffEPCandidates'));
export const getABServicesLoadedForStaff = (state) => StaffEPCandidatesSelectors.getABServicesLoaded(state.get('staffEPCandidates'));

export const getStaffEPCandidatesSortBy = (state) => StaffEPCandidatesSelectors.getSortBy(state.get('staffEPCandidates'));
export const getStaffEPCandidatesPage = (state) => StaffEPCandidatesSelectors.getPage(state.get('staffEPCandidates'));

export const getStaffEPAllProgramsLoaded = (state) => StaffEPCandidatesSelectors.getStaffEPAllProgramsLoaded(state.get('staffEPCandidates'));
export const getStaffEPAllProgramIds = (state) => StaffEPCandidatesSelectors.getStaffEPAllProgramIds(state.get('staffEPCandidates'));
export const getStaffEPAllEventsLoaded = (state) => StaffEPCandidatesSelectors.getStaffEPAllEventsLoaded(state.get('staffEPCandidates'));
export const getStaffEPAllEventIds = (state) => StaffEPCandidatesSelectors.getStaffEPAllEventIds(state.get('staffEPCandidates'));
export const getStaffEPAllServicesLoaded = (state) => StaffEPCandidatesSelectors.getStaffEPAllServicesLoaded(state.get('staffEPCandidates'));
export const getStaffEPAllServiceIds = (state) => StaffEPCandidatesSelectors.getStaffEPAllServiceIds(state.get('staffEPCandidates'));

export const getStaffCandidatesFilters = (state) => StaffCandidatesSelectors.getFilters(state.get('staffCandidates'));
export const getStaffCandidatesSearch = (state) => StaffCandidatesSelectors.getSearch(state.get('staffCandidates'));
export const getStaffCandidatesLoaded = (state) => StaffCandidatesSelectors.getLoaded(state.get('staffCandidates'));
export const getStaffCandidatesLoading = (state) => StaffCandidatesSelectors.getLoading(state.get('staffCandidates'));
export const getStaffCandidateIds = (state) => StaffCandidatesSelectors.getCandidateIds(state.get('staffCandidates'));
export const getStaffTotalCandidates = (state) => StaffCandidatesSelectors.getTotalCandidates(state.get('staffCandidates'));

export const getStaffCandidatesForProgramFilters = (state) => StaffProgramCandidatesSelectors.getFilters(state.get('staffProgramCandidates'));
export const getStaffCandidatesForProgramSearch = (state) => StaffProgramCandidatesSelectors.getSearch(state.get('staffProgramCandidates'));
export const getStaffCandidatesForProgramLoaded = (state) => StaffProgramCandidatesSelectors.getLoaded(state.get('staffProgramCandidates'));
export const getStaffCandidateProgramIds = (state) => StaffProgramCandidatesSelectors.getCandidateIds(state.get('staffProgramCandidates'));

export const getStaffCandidatesForEventFilters = (state) => StaffEventCandidatesSelectors.getFilters(state.get('staffEventCandidates'));
export const getStaffCandidatesForEventSearch = (state) => StaffEventCandidatesSelectors.getSearch(state.get('staffEventCandidates'));
export const getStaffCandidatesForEventLoaded = (state) => StaffEventCandidatesSelectors.getLoaded(state.get('staffEventCandidates'));
export const getStaffCandidateEventIds = (state) => StaffEventCandidatesSelectors.getCandidateIds(state.get('staffEventCandidates'));

export const getStaffCandidatesForJobFilters = (state) => StaffJobCandidatesSelectors.getFilters(state.get('staffJobCandidates'));
export const getStaffCandidatesForJobSearch = (state) => StaffJobCandidatesSelectors.getSearch(state.get('staffJobCandidates'));
export const getStaffCandidatesForJobLoaded = (state) => StaffJobCandidatesSelectors.getLoaded(state.get('staffJobCandidates'));
export const getStaffCandidateJobIds = (state) => StaffJobCandidatesSelectors.getCandidateIds(state.get('staffJobCandidates'));

export const getStaffJobListingsFilters = (state) => StaffJobListingsSelectors.getFilters(state.get('staffJobListings'));
export const getStaffJobListingZipCode = (state) => StaffJobListingsSelectors.getZipCode(state.get('staffJobListings'));
export const getStaffJobListingsSearch = (state) => StaffJobListingsSelectors.getSearch(state.get('staffJobListings'));
export const getStaffJobListingsLoaded = (state) => StaffJobListingsSelectors.getLoaded(state.get('staffJobListings'));
export const getStaffJobListingIds = (state) => StaffJobListingsSelectors.getJobIds(state.get('staffJobListings'));
export const getStaffTotalJobs = (state) => StaffJobListingsSelectors.getTotalJobs(state.get('staffJobListings'));
export const getStaffOrgJobIds = (state) => StaffJobListingsSelectors.getOrgJobIds(state.get('staffJobListings'));
export const getStaffOrgJobsLoaded = (state) => StaffJobListingsSelectors.getOrgJobsLoaded(state.get('staffJobListings'));
export const getStaffTotalOrgJobs = (state) => StaffJobListingsSelectors.getTotalOrgJobs(state.get('staffJobListings'));

export const getStaffIndeedJobListingsFilters = (state) => StaffIndeedJobListingsSelectors.getFilters(state.get('staffIndeedJobListings'));
export const getStaffIndeedJobListingZipCode = (state) => StaffIndeedJobListingsSelectors.getZipCode(state.get('staffIndeedJobListings'));
export const getStaffIndeedJobListingsSearch = (state) => StaffIndeedJobListingsSelectors.getSearch(state.get('staffIndeedJobListings'));
export const getStaffIndeedJobListingsLoaded = (state) => StaffIndeedJobListingsSelectors.getLoaded(state.get('staffIndeedJobListings'));
export const getStaffIndeedJobListingIds = (state) => StaffIndeedJobListingsSelectors.getIndeedJobIds(state.get('staffIndeedJobListings'));
export const getStaffTotalIndeedJobs = (state) => StaffIndeedJobListingsSelectors.getTotalIndeedJobs(state.get('staffIndeedJobListings'));
export const getStaffOrgIndeedJobIds = (state) => StaffIndeedJobListingsSelectors.getOrgIndeedJobIds(state.get('staffIndeedJobListings'));
export const getStaffIndeedOrgIndeedJobIds = (state) => StaffIndeedJobListingsSelectors.getOrgIndeedJobIds(state.get('staffIndeedJobListings'));
export const getStaffIndeedOrgIndeedJobsLoaded = (state) => StaffIndeedJobListingsSelectors.getOrgIndeedJobsLoaded(state.get('staffIndeedJobListings'));
export const getStaffTotalOrgIndeedJobs = (state) => StaffIndeedJobListingsSelectors.getTotalOrgIndeedJobs(state.get('staffIndeedJobListings'));

export const getCandidateJobRecommendations = (state) => getInvitations(state).filter((invite) => invite.get('recommendedType') === 'JobPosting');

export const getStaff = (state, ids = undefined) => StaffReducer.selectors.getList(state.get('staff'), ids);
export const getStaffMember = (state, id) => StaffReducer.selectors.getOne(state.get('staff'), id);

const getPeerStaffIds = (state) => PeerStaffSelectors.getIds(state.get('peerStaff'));
export const getPeerStaff = createSelector(
  (x) => x, // state
  getPeerStaffIds,
  getStaff,
);

export const getCandidates = (state, ids = undefined) => CandidateReducer.selectors.getList(state.get('candidates'), ids);
export const getCandidate = (state, id) => CandidateReducer.selectors.getOne(state.get('candidates'), id);

export const getMessages = (state) => MessageReducer.selectors.getList(state.get('messages'));
export const getMessage = (state, id) => MessageReducer.selectors.getOne(state.get('messages'), id);

export const getNotes = (state) => NoteReducer.selectors.getList(state.get('notes'));
export const getNote = (state, id) => NoteReducer.selectors.getOne(state.get('notes'), id);

export const getNotifications = (state) => NotificationReducer.selectors.getList(state.get('notifications'));
export const getNotification = (state, id) => NotificationReducer.selectors.getOne(state.get('notifications'), id);

export const getPublicLink = (state, id) => PublicLinkReducer.selectors.getOne(state.get('publicLinks'), id);
export const getPublicLinks = (state) => PublicLinkReducer.selectors.getList(state.get('publicLinks'));

// Aggregate Functions

const getAllCandidateApplications = createSelector(
  (state, _) => getCandidateApplications(state),
  (state, _) => getCandidateApplications(state, getCandidateFilteredCandidateApplicationIds(state)),
  (a, b) => a.merge(b),
);
const getAllCandidateEvents = createSelector(
  (state, _) => getCandidateEvents(state),
  (state, _) => getCandidateEvents(state, getCandidateFilteredCandidateEventIds(state)),
  (a, b) => a.merge(b),
);
const getAllCandidatePrograms = createSelector(
  (state, _) => getCandidatePrograms(state),
  (state, _) => getCandidatePrograms(state, getCandidateFilteredCandidateProgramIds(state)),
  (a, b) => a.merge(b),
);
const getAllCandidateServices = createSelector(
  (state, _) => getCandidateServices(state),
  (state, _) => getCandidateServices(state, getCandidateServiceIds(state)),
  (a, b) => a.merge(b),
);
const getAllStaffCandidateApplications = createSelector(
  (state, _) => getCandidateApplications(state),
  (state, _) => getCandidateApplications(state, getCandidateApplicationIds(state)),
  (a, b) => a.merge(b),
);

export const getCandidateApplicationForJobPosting = createSelector(
  getAllCandidateApplications,
  (_, jobPostingId) => jobPostingId,
  (applications, jobPostingId) =>
    applications.find(
      (app) => app && (app.getIn(['jobPosting', 'jobPostingId']) === jobPostingId || app.getIn(['externalJobPosting', 'externalId']) === jobPostingId),
    ),
);

export const getCandidateEventForEvent = createSelector(
  getAllCandidateEvents,
  (_, eventId) => eventId,
  (candidateEvents, eventId) => candidateEvents.find((app) => app && app.getIn(['event', 'eventId']) === eventId),
);

export const getCandidateProgramForProgram = createSelector(
  getAllCandidatePrograms,
  (_, programId) => programId,
  (candidatePrograms, programId) => candidatePrograms.find((app) => app && app.getIn(['program', 'programId']) === programId),
);

export const getCandidateServiceForService = createSelector(
  getAllCandidateServices,
  (_, serviceId) => serviceId,
  (candidateServices, serviceId) => candidateServices.find((app) => app && app.getIn(['service', 'serviceId']) === serviceId),
);

export const getStaffCandidateApplicationForJobPosting = createSelector(
  getAllStaffCandidateApplications,
  (_, jobPostingId) => jobPostingId,
  (applications, jobPostingId) => applications.filter((app) => app && app.getIn(['jobPosting', 'jobPostingId']) === jobPostingId),
);

export const getPublicLinkForPosting = createSelector(
  (state, _) => getPublicLinks(state),
  (_, postingId) => postingId,
  (publicLinks, postingId) => publicLinks.find((publicLink) => publicLink && publicLink.get('subjectId') === postingId),
);

// Resources
const getResourcesFactory = (getResources, getResourceIds) =>
  createSelector(
    (state, _) => getResources(state),
    (state, _) => getResources(state, getResourceIds(state)),
    (a, b) => a.merge(b),
  );

export const getFilteredABServiceIds = (resourcesList) =>
  createSelector([resourcesList, getCandidateProgramsFilters], (services, filters) => {
    const dist = parseInt(filters.get('distance', 25000), 0);
    return services.filter((x) => parseInt(x.get('distance', 25000), 0) <= dist).map((x) => x.get('id'));
  });

export const getFilteredABServiceIdsForCandidate = getFilteredABServiceIds(getResourcesFactory(getServices, getExternalServiceIds));
export const getFilteredABServiceIdsForStaff = getFilteredABServiceIds(getResourcesFactory(getServices, getStaffEPABServiceIds));

export const getResourcesForCandidate = createSelector(
  [
    getResourcesFactory(getPrograms, getCandidateProgramIds),
    getResourcesFactory(getEvents, getCandidateEventIds),
    getResourcesFactory(getServices, getCandidateServiceIds),
    getResourcesFactory(getServices, getFilteredABServiceIdsForCandidate),
  ],
  (programs, events, services, aBServices) => programs.merge(events).merge(services).merge(aBServices),
);

export const getResourcesForStaff = createSelector(
  [
    getResourcesFactory(getPrograms, getStaffEPFilteredProgramIds),
    getResourcesFactory(getEvents, getStaffEPFilteredEventIds),
    getResourcesFactory(getServices, getStaffEPFilteredServiceIds),
    getResourcesFactory(getServices, getFilteredABServiceIdsForStaff),
  ],
  (programs, events, services, aBServices) => programs.merge(events).merge(services).merge(aBServices),
);
export const getAllResourcesForStaff = createSelector(
  [
    getResourcesFactory(getPrograms, getStaffEPAllProgramIds),
    getResourcesFactory(getEvents, getStaffEPAllEventIds),
    getResourcesFactory(getServices, getStaffEPAllServiceIds),
  ],
  (programs, events, services) => programs.merge(events).merge(services),
);
export const getSavedResourcesForCandidate = createSelector(
  [
    getResourcesFactory(getPrograms, getCandidateSavedProgramIds),
    getResourcesFactory(getEvents, getCandidateSavedEventIds),
    getResourcesFactory(getServices, getCandidateSavedServiceIds),
  ],
  (programs, events, services) => programs.merge(events).merge(services),
);
export const getAppliedResourcesForCandidate = createSelector(
  [
    getResourcesFactory(getPrograms, getCandidateAppliedProgramIds),
    getResourcesFactory(getEvents, getCandidateAppliedEventIds),
    getResourcesFactory(getServices, getCandidateAppliedServiceIds),
  ],
  (programs, events, services) => programs.merge(events).merge(services),
);
export const getResourcesLoadedForCandidate = createSelector(
  [getCandidateEventsLoaded, getCandidateProgramsLoaded, getCandidateServicesLoaded],
  (programsLoaded, eventsLoaded, servicesLoaded) => programsLoaded && eventsLoaded && servicesLoaded,
);
export const getSavedResourcesLoadedForCandidate = createSelector(
  [getCandidateSavedProgramsLoaded, getCandidateSavedEventsLoaded, getCandidateSavedServicesLoaded],
  (programsLoaded, eventsLoaded, servicesLoaded) => programsLoaded && eventsLoaded && servicesLoaded,
);
export const getAppliedResourcesLoadedForCandidate = createSelector(
  [getCandidateAppliedProgramsLoaded, getCandidateAppliedEventsLoaded, getCandidateAppliedServicesLoaded],
  (programsLoaded, eventsLoaded, servicesLoaded) => programsLoaded && eventsLoaded && servicesLoaded,
);

export const createSortedResourceFactory = (resourceList, getSortBy) =>
  createSelector([resourceList, getSortBy], (resources, sortBy) => {
    let result;
    switch (sortBy) {
      case 'name':
        result = resources.sort((a, b) => {
          const bandA = a.get('name').toUpperCase();
          const bandB = b.get('name').toUpperCase();
          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        });
        break;
      case 'distance':
      default:
        result = resources.sort((a, b) => a.get('distance', 25000) - b.get('distance', 25000));
    }
    return result.map((x) => x.get('id'));
  });

export const createPaginatedResourceFactory = (resourceList, getPage) =>
  createSelector([resourceList, getPage], (resources, page) => resources.slice(page * 25, page * 25 + 25));

export const getResourceIdsSortedForCandidate = createSortedResourceFactory(getResourcesForCandidate, getCandidateProgramsFiltersSortBy);
export const getResourceIdsSortedForStaff = createSortedResourceFactory(getResourcesForStaff, getStaffEPCandidatesSortBy);
export const getResourceIdsPaginatedForStaff = createPaginatedResourceFactory(getResourceIdsSortedForStaff, getStaffEPCandidatesPage);
export const getAllResourceIdsSortedForStaff = createSortedResourceFactory(getAllResourcesForStaff, getStaffEPCandidatesSortBy);
export const getMyOrgResourceIdsSortedForStaff = createSelector(
  (state, _) => getAllResourcesForStaff(state),
  (_, myOrgId) => myOrgId,
  (resourceList, myOrgId) => resourceList.filter((x) => myOrgId === x.getIn(['postingOrganization', 'id'])).map((resource) => resource.get('id')),
);
export const getMyDraftResourceIdsSortedForStaff = createSelector(
  (state, _) => getAllResourcesForStaff(state),
  (resourceList) => resourceList.filter((resource) => resource.get('state') === 'pre_published').map((resource) => resource.get('id')),
);

export const getMyOrgResourceIdsPaginatedForStaff = createPaginatedResourceFactory(getMyOrgResourceIdsSortedForStaff, getStaffEPCandidatesPage);
export const getMyDraftResourceIdsPaginatedForStaff = createPaginatedResourceFactory(getMyDraftResourceIdsSortedForStaff, getStaffEPCandidatesPage);
export const getAllResourceIdsPaginatedForStaff = createPaginatedResourceFactory(getAllResourcesForStaff, getStaffEPCandidatesPage);
export const getAppliedResourceIdsSortedForCandidate = createSortedResourceFactory(getAppliedResourcesForCandidate, getCandidateProgramsFiltersSortBy);
export const getSavedResourceIdsSortedForCandidate = createSortedResourceFactory(getSavedResourcesForCandidate, getCandidateProgramsFiltersSortBy);
export const getResourceIdsPaginated = createPaginatedResourceFactory(getResourceIdsSortedForCandidate, getCandidateProgramsPage);
export const getAppliedResourceIdsPaginated = createPaginatedResourceFactory(getAppliedResourceIdsSortedForCandidate, getCandidateProgramsPage);
export const getSavedResourceIdsPaginated = createPaginatedResourceFactory(getSavedResourceIdsSortedForCandidate, getCandidateProgramsPage);

export const getExternalCandidateService = (state, id) => ExternalCandidateServiceReducer.selectors.getOne(state.get('externalCandidateServices'), id);
export const getExternalCandidateServices = (state) => ExternalCandidateServiceReducer.selectors.getList(state.get('externalCandidateServices'));
export const getExternalCandidateServiceByResourceId = createSelector(
  getExternalCandidateServices,
  (_, resourceId) => resourceId,
  (resourceList, resourceId) => resourceList.find((resource) => resource.get('externalResourceUid') === resourceId),
);

export const getRecidivismReport = (state) => ReportsSelectors.getRecidivismReport(state.get('reports'));
