import { connect } from 'react-redux';

import { setRedirectPath } from 'public_link/actions';

import JobPreviewView from './job_preview_view';
import { getCurrentUser } from '../../../../reducer';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
});

const mapDispatchToProps = {
  setRedirectPath,
};

const JobPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(JobPreviewView);

export default JobPreviewContainer;
