import React from 'react';
import PropTypes from 'prop-types';
import ProgressStyles, { ProgressBar, ProgressBarProgress, ProgressLabel } from './progress_styles';

const propTypes = {
  percent: PropTypes.number.isRequired,
  label: PropTypes.string,
  percentLabel: PropTypes.string,
  color: PropTypes.string,
};

const defaultProps = {
  label: 'Completed',
  color: undefined,
  percentLabel: undefined,
};

const ProgressView = ({ percent, percentLabel, label, color }) => (
  <ProgressStyles>
    <ProgressLabel percent={percent} color={color} percentLabel={percentLabel} label={label} />
    <ProgressBar>
      <ProgressBarProgress percent={percent} color={color} />
    </ProgressBar>
  </ProgressStyles>
);

ProgressView.propTypes = propTypes;
ProgressView.defaultProps = defaultProps;

export default ProgressView;
