export const isNonProfitInmateUser = (user) => {
  const userOrgType = user.getIn(['staffOrganization', 'orgType']);

  return userOrgType === 'nonprofit-inmate';
};

export const extractCandidateFullName = (candidateUser, currentUser) => {
  const firstName = candidateUser.get('firstName');
  const lastName = candidateUser.get('lastName');

  const shouldRevertName = isNonProfitInmateUser(currentUser);

  return shouldRevertName ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
};

export const generateNumberRange = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, index) => start + index);

export const preventEventBubbling = (event) => {
  event.preventDefault();
  event.stopPropagation();
};
