import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import Organizations from '../organizations';
import OrganizationForm from '../organization_form';
import ProfileForm from '../profile_form';
import HowHearForm from '../../../candidate/components/welcome/how_hear_form';

const propTypes = {
  isEmployer: PropTypes.bool.isRequired,
  hasStaff: PropTypes.bool.isRequired,
  isOnboarding: PropTypes.bool.isRequired,
};

const OnboardingView = ({ isEmployer, hasStaff, isOnboarding }) => {
  const [orgId, setOrgId] = useState(undefined);
  const [orgNotFound, setOrgNotFound] = useState(false);

  if (!isOnboarding) {
    return <Redirect to="/dashboard" />;
  }
  // Currently not hooked up
  // if (hasStaff) {
  //   return <OrganizationInvite isEmployer={isEmployer} />;
  // }
  if (hasStaff) {
    return <HowHearForm />;
  }
  if (orgId) {
    return <ProfileForm orgId={orgId} isEmployer={isEmployer} />;
  }
  if (orgNotFound) {
    return <OrganizationForm setOrgId={setOrgId} />;
  }
  return <Organizations isEmployer={isEmployer} setOrgId={setOrgId} onOrgFound={(org) => setOrgId(org.get('id'))} onOrgNotFound={() => setOrgNotFound(true)} />;
};

OnboardingView.propTypes = propTypes;

export default OnboardingView;
