import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import { RadioGroup } from 'redux-form-material-ui';

const propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

const defaultProps = {
  label: undefined,
  disabled: false,
  required: false,
};

/* eslint-disable react/jsx-props-no-spreading */
const MuiRadioGroup = ({ input, meta: { touched, error }, disabled, required, children, ...rest }) => (
  <RadioGroup className={error ? 'error' : ''} disabled={disabled} required={required} input={input} {...rest}>
    <InputLabel error={touched && error} htmlFor="select-radio">
      {touched && error}
    </InputLabel>
    {children}
  </RadioGroup>
);

MuiRadioGroup.propTypes = propTypes;
MuiRadioGroup.defaultProps = defaultProps;

export default MuiRadioGroup;
