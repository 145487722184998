import React, { useEffect } from 'react';
import SplashScreenContainer from './splash_screen_container';
import { Spinner } from '../../../common/components';

const CMSRedirector = () => {
  useEffect(() => {
    if (process.env.REACT_APP_CMS) {
      window.location = process.env.REACT_APP_CMS;
    }
  });
  return <Spinner />;
};

const SplashScreen = () => {
  if (process.env.REACT_APP_CMS) {
    return <CMSRedirector />;
  }
  return <SplashScreenContainer />;
};
export default SplashScreen;
