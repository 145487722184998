import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import SettingsView from './settings_view';
import { getCurrentUser, getStaffMember, getSystemEnumList } from '../../../reducer';
import { updateCurrentUser } from '../../../session/actions';
import { updateStaffMember, fetchStaffMember } from '../../../solve/actions';

const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  const staff = getStaffMember(state, user.staffId);
  const staffResponsibilityTypes = {};
  if (staff && staff.get('staffResponsibilityTypes')) {
    staff.get('staffResponsibilityTypes').forEach((key) => {
      staffResponsibilityTypes[key] = true;
    });
  }
  return {
    user,
    staff,
    genderTypes: getSystemEnumList(state, 'GenderType').map((item) => [item.get('slug'), item.get('label')]),
    staffResponsibilities: getSystemEnumList(state, 'StaffResponsibilityType'),
    initialValues: {
      genderType: staff && staff.get('genderType'),
      birthday: staff && staff.get('birthday'),
      workPhone: staff && staff.get('workPhone'),
      workPhoneExtension: staff && staff.get('workPhoneExtension'),
      staffResponsibilityTypes,
      userAttributes: { firstName: user.firstName, lastName: user.lastName, phone: user.phone },
    },
  };
};

const mapDispatchToProps = {
  fetchStaffMember,
  updateCurrentUser,
  updateStaffMember,
};

const EMPTY_MAP = fromJS({});
const mapValuesToList = (x = EMPTY_MAP) => x.toList();
const testSet = (x) => x;
const mapKeysToList = (x = EMPTY_MAP) => x.filter(testSet).keySeq();

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    const avatar = values.get('avatar');
    const cleanValues = values
      .update('userAttributes', (attrs) => attrs.update('alternateEmails', mapValuesToList))
      .update('userAttributes', (attrs) => attrs.update('avatar', () => avatar && avatar[0]))
      .delete('avatar')
      .update('staffResponsibilityTypes', mapKeysToList)
      .set('organizationId', ownProps.orgId);
    return dispatchProps.updateStaffMember(stateProps.user, stateProps.user.staffId, cleanValues);
  },
  ...dispatchProps,
  ...stateProps,
  ...ownProps,
});

//  onSubmit: (values) => {
//     console.log('Submitting Staff Profile', values);
//     const cleanValues = values
//       .update('userAttributes', (attrs) => attrs.update('alternateEmails', mapValuesToList))
//       .update('staffResponsibilityTypes', mapKeysToList)
//       .set('organizationId', ownProps.orgId);
//     return dispatchProps.createStaffMember(stateProps.user, cleanValues);
//   },

const SettingsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SettingsView);

export default SettingsContainer;

// // Wire up specific actions using this function. If you directly map an
// // action creator, it will be automatically wrapped with `dispatch()`,
// // so do not define new functions here unless you need to override or
// // explicitly specify a parameter of an action creator based on the
// // nature of the component or its props.
// //
// // mapDispatchToProps(dispatch, { prop })
// const mapDispatchToProps = {
//   createStaffMember,
// };

// const EMPTY_MAP = fromJS({});
// const mapValuesToList = (x = EMPTY_MAP) => x.toList();
// const mapKeysToList = (x = EMPTY_MAP) => x.keySeq();

// const mergeProps = (stateProps, dispatchProps, ownProps) => ({
//   ...ownProps,
//   ...stateProps,
//   onSubmit: (values) => {
//     console.log('Submitting Staff Profile', values);
//     const cleanValues = values
//       .update('userAttributes', (attrs) => attrs.update('alternateEmails', mapValuesToList))
//       .update('staffResponsibilityTypes', mapKeysToList)
//       .set('organizationId', ownProps.orgId);
//     return dispatchProps.createStaffMember(stateProps.user, cleanValues);
//   },
// });

// const ProfileFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProfileFormView);

// export default ProfileFormContainer;
