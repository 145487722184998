import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map } from 'immutable';
import { Text, TextHeading } from '../../../common/components';
import MessageStyles from './message_styles';
import MessageForm from '../message_form';

const propTypes = {
  message: PropTypes.instanceOf(Map),
  replyTo: PropTypes.instanceOf(OrderedSet),
};

const defaultProps = {
  message: Map(),
  replyTo: OrderedSet(),
};

const MessageView = ({ message, replyTo }) => (
  <MessageStyles className="container-wrapper">
    <div className="candidate-wrapper">
      <TextHeading size="large" weight="bold" margin="both">
        {message.get('messageSubject')}
      </TextHeading>
      <TextHeading size="medium" margin="both">
        {message.getIn(['fromUser', 'firstName'])} {message.getIn(['fromUser', 'lastName'])}
      </TextHeading>
      <Text>{message.get('messageBody')}</Text>
      <MessageForm sendToOptions={replyTo} />
    </div>
  </MessageStyles>
);

MessageView.propTypes = propTypes;
MessageView.defaultProps = defaultProps;

export default MessageView;
