import React from 'react';
import PropTypes from 'prop-types';
import FeatureStyles from './feature_styles';

const propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

const defaultProps = {
  title: undefined,
  body: undefined,
};

const FeatureView = ({ title, body }) => (
  <FeatureStyles>
    <h4>{title}</h4>
    <p>{body}</p>
  </FeatureStyles>
);

FeatureView.propTypes = propTypes;
FeatureView.defaultProps = defaultProps;

export default FeatureView;
