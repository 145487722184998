import { Map, fromJS } from 'immutable';
import { random } from 'lodash';
import { sleep } from './index';

export function mockResourcefulAPI(key, initialResources = [], options = {}) {
  const ID = options.id || 'id';

  let resourcesById = new Map();

  fromJS(initialResources).forEach((resource) => {
    resourcesById = resourcesById.set(resource.get(ID), resource);
  });

  async function list() {
    await sleep(random(500, 1500));
    return { data: resourcesById.toList().toJS() };
  }

  async function get(id) {
    if (!resourcesById.has(id)) {
      throw new Error(`${key} ${id} does not exist!`);
    }
    return { data: resourcesById.get(id).toJS() };
  }

  async function create(id, resource) {
    if (resourcesById.has(id)) {
      throw new Error(`${key} ${id} already exists!`);
    }
    resourcesById = resourcesById.set(id, fromJS(resource));
    return get(id);
  }

  async function update(id, props) {
    if (!resourcesById.has(id)) {
      throw new Error(`${key} ${id} does not exist!`);
    }
    resourcesById = resourcesById.update(id, (resource) => resource.merge(props));
    return get(id);
  }

  async function destroy(id) {
    if (!resourcesById.has(id)) {
      throw new Error(`${key} ${id} does not exist!`);
    }
    resourcesById = resourcesById.delete(id);
    return list();
  }

  return {
    list,
    get,
    create,
    update,
    destroy,
  };
}

export function mockAPI(responseMap) {
  const responses = fromJS(responseMap);
  async function preparedResponse(rawResponse) {
    const response = await Promise.resolve(rawResponse);
    if (response.data || response.error) {
      return response;
    }
    if (response.mock_error) {
      throw new Error(response.mock_error);
    }
    return { data: response };
  }

  async function evaluate(argList, response) {
    if (response === undefined) {
      throw new Error(`unexpected API arguments: ${argList}`);
    }
    if (typeof response === 'function') {
      return preparedResponse(response(...argList));
    }
    if (argList.length > 0) {
      const arg = argList.shift();
      const next = response.get(arg);
      if (next === undefined) {
        argList.unshift(arg);
        throw new Error(`unexpected API arguments: ${argList}`);
      }
      return evaluate(argList, next);
    }
    return preparedResponse(response);
  }

  return async function apiCall(...args) {
    // make all api calls delay a bit...
    await sleep(random(500, 1500));
    return evaluate(args, responses);
  };
}
