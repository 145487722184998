import { connect } from 'react-redux';
import ResumeViewerView from './resume_viewer_view';
import { getCandidateResumeForCandidate, getCurrentUser } from '../../../reducer';
import { listResumes } from '../../../solve/actions';

const mapStateToProps = (state, { candidateId: id }) => {
  const currentUser = getCurrentUser(state);
  const candidateId = id || currentUser.get('candidateId');

  return {
    currentUser,
    resume: getCandidateResumeForCandidate(state, candidateId),
    candidateId,
  };
};

const mapDispatchToProps = {
  listResumes,
};

const ResumeViewerContainer = connect(mapStateToProps, mapDispatchToProps)(ResumeViewerView);
export default ResumeViewerContainer;
