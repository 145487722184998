import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';

import IndeedTileView from './indeed_tile_view';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';
import { getSavedIds } from '../../../solve/reducer/indeed_job_listings_reducer';
import { transitionExternalCandidateApplicationByCandidate, createExternalCandidateApplication } from '../../../solve/actions';
import {
  getIndeedJobPosting,
  getCandidateApplicationForJobPosting,
  getCurrentUser,
  getSystemEnumList,
  getCandidateResumeCompletionPercentage,
} from '../../../reducer';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state, { id }) => {
  const job = getIndeedJobPosting(state, id);
  const opportunityTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'OpportunityType'), job?.get('opportunityTypes')));

  const savedIndeedJobIds = getSavedIds(state.get('solve').get('indeedJobListings'));
  const isSaved = savedIndeedJobIds.includes(id);

  const currentUser = getCurrentUser(state);
  const candidateResumeCompletionPercentage = getCandidateResumeCompletionPercentage(state, currentUser?.get('candidateId'));
  const isCandidateResumeEmpty = candidateResumeCompletionPercentage === 0;

  return {
    job,
    isSaved,
    currentUser: getCurrentUser(state),
    indeedCandidateApplication: getCandidateApplicationForJobPosting(state, id),
    opportunityTypes,
    isCandidateResumeEmpty,
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  createIndeedCandidateApplication: createExternalCandidateApplication,
  transitionIndeedCandidateApplicationByCandidate: transitionExternalCandidateApplicationByCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createIndeedCandidateApplication: () => dispatchProps.createIndeedCandidateApplication(stateProps.currentUser, ownProps.id, stateProps.job.get('jobtitle')),
  deleteIndeedCandidateApplication: () =>
    dispatchProps.transitionIndeedCandidateApplicationByCandidate(stateProps.currentUser, stateProps.indeedCandidateApplication.get('id'), 'deleted'),
  handleSaveDraft: (values) => console.log('Submitting Program Posting Draft', values),
  handleSubmit: (values) => console.log('Submitting Program Posting', values),
});

const IndeedTileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(IndeedTileView);

export default IndeedTileContainer;
