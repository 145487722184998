import { connect } from 'react-redux';
import { fromJS, List, Map } from 'immutable';
import AddEventView from './add_event_view';
import { getCurrentUser, getEvent } from '../../../reducer';
import { createEvent, updateEvent, publishEvent, cancelEvent, draftEvent, resetForm } from '../../../solve/actions';

// adding these for dev purposes to speed up form submission
// initialValues: {
//   name: `Event ${Math.random().toString(36).substring(7)} Name`,
//   phone: '5555555555',
//   addressAttributes: {
//     address1: '123 Main St.',
//     city: 'Seattle',
//     state: 'WA',
//     zip: '98122',
//   },
//   postExpirationDate: '12-12-2020',
//   industryType: 'industry type 2',
//   industrySpecificsType: 'industry specifics 3',
//   primaryServiceType: 'service type 1',
//   eventDate: '03-12-2020',
//   startTime: '2020-12-29T21:21:49.144Z',
//   endTime: '2020-12-29T22:21:49.144Z',
//   description: 'This is the description',
//   serviceTypes: {
//     internships: true,
//   },
//   careerInterestTypes: {
//     administrative: true,yarn
//   },
//   staffIds: '1',
// },
const LEGACY_SERVICE_TYPES_MAPPING = {
  softSkills: 'soft-skills',
  subsidizedEmployment: 'subsidized',
  workReadinessTraining: 'work-readiness',
  internships: 'internship',
  careerCounseling: 'career-counseling',
  resumeHelp: 'resume',
  jobSpecificTraining: 'training',
  mockInterviews: 'mock-interviews',
};

const POTENTIAL_SERVICE_TYPES = [
  'soft-skills',
  'subsidized',
  'work-readiness',
  'internship',
  'career-counseling',
  'resume',
  'training',
  'mock-interviews',
  'mentorship',
  'ged',
];

const LEGACY_CAREER_INTEREST_TYPES_MAPPING = {
  customerSupport: 'customer-support',
  envCodes: 'env-codes',
  healthCare: 'health-care',
  infoTechnology: 'info-technology',
  siteSupervision: 'site-supervision',
};

const POTENTIAL_CAREER_INTEREST_TYPES = [
  'administrative',
  'carpentry',
  'computer',
  'construction',
  'customer-support',
  'env-codes',
  'health-care',
  'info-technology',
  'manufacturing',
  'mechanical',
  'plumbing',
  'sales',
  'site-supervision',
  'teaching',
  'writing',
];

const LEGACY_TARGET_PARTICIPANT_TYPES_MAPPING = {
  twoYearDegree: '2-year-degree',
  fourYearDegree: '4-year-degree',
  designatedCommunityResidentEmpowermentZone: 'empowerment-zone',
  englishSecondLanguage: 'english-2nd-lang',
  formerlyIncarcerated: 'was-incarcerated',
  highSchoolDropOut: 'hs-drop-out',
  highSchoolStudents: 'hs-student',
  opportunityYouth: 'opportunity-youth',
  singleMoms: 'single-moms',
  singleFathers: 'single-fathers',
  singleParents: 'single-parents',
  snapRecipient: 'snap',
  supplementalSocialSecurityIncome: 'supplement-social-security',
  tanfRecipient: 'tanf',
  wioaEligibleAdult: 'wioa-adult',
  wioaEligibleYouth: 'wioa-youth',
};

const POTENTIAL_TARGET_PARTICIPANT_TYPES = [
  '2-year-degree',
  '4-year-degree',
  'empowerment-zone',
  'disability',
  'english-2nd-lang',
  'was-incarcerated',
  'hs-drop-out',
  'hs-student',
  'homeless',
  'hud',
  'opportunity-youth',
  'single-moms',
  'single-fathers',
  'single-parents',
  'snap',
  'supplement-social-security',
  'tanf',
  'unemployment',
  'veterans',
  'wioa-adult',
  'wioa-youth',
];

function ingestSimpleList(potential, actual) {
  const basicLookup = Map(potential.map((oppo) => [oppo, actual.includes(oppo) ? 'yes' : undefined]));
  return basicLookup;
}

function digestSimpleList(list) {
  if (!list) {
    return List();
  }
  return list
    .filter((value) => value)
    .keySeq()
    .toList();
}

const DEFAULT_INITIAL_VALUES = fromJS({ addressAttributes: { isPartialAddress: true } });

function buildInitialValues(existingEvent) {
  if (existingEvent) {
    const addressAttributes = fromJS({ isPartialAddress: true }).merge(existingEvent.get('address'));
    const serviceTypes = ingestSimpleList(
      POTENTIAL_SERVICE_TYPES,
      existingEvent.get('serviceTypes').map((item) => LEGACY_SERVICE_TYPES_MAPPING[item] || item),
    );
    const careerInterestTypes = ingestSimpleList(
      POTENTIAL_CAREER_INTEREST_TYPES,
      existingEvent.get('careerInterestTypes').map((item) => LEGACY_CAREER_INTEREST_TYPES_MAPPING[item] || item),
    );
    const targetParticipantTypes = ingestSimpleList(
      POTENTIAL_TARGET_PARTICIPANT_TYPES,
      existingEvent.get('targetParticipantTypes').map((item) => LEGACY_TARGET_PARTICIPANT_TYPES_MAPPING[item] || item),
    );
    const allAgesWelcomed = !existingEvent.get('targetMinimumAge') && !existingEvent.get('targetMaximumAge');
    const allGendersWelcomed = !existingEvent.get('targetGenderType');
    // TODO: need to convert surveys into custom screening question attributes
    return DEFAULT_INITIAL_VALUES.merge(existingEvent).merge({
      addressAttributes,
      serviceTypes,
      careerInterestTypes,
      targetParticipantTypes,
      allAgesWelcomed,
      allGendersWelcomed,
    });
  }
  return DEFAULT_INITIAL_VALUES;
}

function buildSubmitValues(formValues) {
  const logo = formValues.get('eventLogo');

  const targetMinimumAge = formValues.get('allAgesWelcomed') ? null : formValues.get('targetMinimumAge');
  const targetMaximumAge = formValues.get('allAgesWelcomed') ? null : formValues.get('targetMaximumAge');
  const targetGenderType = formValues.get('allGendersWelcomed') ? null : formValues.get('targetGenderType');

  const adjustedValues = formValues.delete('eventLogo').merge({
    serviceTypes: digestSimpleList(formValues.get('serviceTypes')),
    careerInterestTypes: digestSimpleList(formValues.get('careerInterestTypes')),
    targetParticipantTypes: digestSimpleList(formValues.get('targetParticipantTypes')),
    targetMinimumAge,
    targetMaximumAge,
    targetGenderType,
  });
  if (logo) {
    return adjustedValues.merge({ logo: logo[0] });
  }
  return adjustedValues;
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  initialValues: buildInitialValues(id && getEvent(state, id)),
  event: id && getEvent(state, id),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  createEvent,
  updateEvent,
  publishEvent,
  cancelEvent,
  draftEvent,
  resetForm,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  handleSaveDraft: (formValues) => {
    const values = buildSubmitValues(formValues);
    if (ownProps.match.params.id) {
      return dispatchProps
        .updateEvent(stateProps.currentUser, ownProps.match.params.id, values)
        .then((res) => dispatchProps.draftEvent(stateProps.currentUser, res.result));
    }
    return dispatchProps.createEvent(stateProps.currentUser, stateProps.currentUser.staffOrganization.id, values);
  },
  handleSubmit: (formValues) => {
    const values = buildSubmitValues(formValues);
    if (ownProps.match.params.id && stateProps.event && stateProps.event.get('state') === 'active') {
      return dispatchProps.cancelEvent(stateProps.currentUser, ownProps.match.params.id);
    }
    if (ownProps.match.params.id) {
      return dispatchProps.updateEvent(stateProps.currentUser, ownProps.match.params.id, values).then((res) => {
        if (stateProps.event && stateProps.event.get('state') === 'active') {
          return res;
        }
        return dispatchProps.publishEvent(stateProps.currentUser, res.result).then(() => res);
      });
    }
    return dispatchProps
      .createEvent(stateProps.currentUser, stateProps.currentUser.staffOrganization.id, values)
      .then((res) => dispatchProps.publishEvent(stateProps.currentUser, res.result));
  },
  handleCancel: () => dispatchProps.cancelEvent(stateProps.currentUser, ownProps.match.params.id),
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const AddEventContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddEventView);

export default AddEventContainer;
