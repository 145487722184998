import { connect } from 'react-redux';
import { getCurrentUser, getTreatment } from '../../../reducer';
import SignInForm from './sign_in_form';
import { signIn } from '../../actions';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
  isRisekit: !!getTreatment(state, 'isRisekit', true),
});

const mapDispatchToProps = {
  onSubmit: (args) => signIn(args.get('email'), args.get('password')),
};

const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignInForm);

export default SignInContainer;
