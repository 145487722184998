import React from 'react';
// import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import UpgradeWidgetStyles from './upgrade_widget_styles';

const propTypes = {};

const defaultProps = {};

const UpgradeWidgetView = () => (
  <UpgradeWidgetStyles>
    {/* <Progress percent={33} percentLabel="15" color={theme.color.positiveLight} label="/45 Applicants" /> */}
    <a href="mailto:support@risekit.co?subject=Upgrading plan on Risekit.co">
      <Button fullWidth variant="outlined">
        Upgrade Plan
      </Button>
    </a>
  </UpgradeWidgetStyles>
);

UpgradeWidgetView.propTypes = propTypes;
UpgradeWidgetView.defaultProps = defaultProps;

export default UpgradeWidgetView;
