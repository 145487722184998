/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { Card } from '../../../common/styles/layout';
import { Button } from '../../../common/components';
import { GridTile } from '../dashboard/dashboard_styles';
import UserModel from '../../../session/models/user_model';
import ChallengeChecklistStyles from './challenge_checklist_styles';

const propTypes = {
  listChallenges: PropTypes.func.isRequired,
  candidateChallengeTransition: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateId: PropTypes.string.isRequired,
  challenges: PropTypes.instanceOf(OrderedSet),
};

const defaultProps = {
  challenges: OrderedSet(),
};

const ChallengeChecklistView = ({ listChallenges, candidateChallengeTransition, currentUser, candidateId, challenges }) => {
  const [markedChallenges, setMarkedChallenges] = useState([]);

  useEffect(() => {
    listChallenges(currentUser, candidateId);
  }, [listChallenges, currentUser, candidateId]);

  const updateMarkedChallenges = (id) => {
    if (markedChallenges.includes(id)) return setMarkedChallenges(markedChallenges.filter((markedChallenge) => markedChallenge !== id));
    return setMarkedChallenges([...markedChallenges, id]);
  };

  const completeChallenges = (user, markedChallengesChecked) => markedChallengesChecked.forEach((challenge) => candidateChallengeTransition(user, challenge));

  return (
    <ChallengeChecklistStyles>
      <p>
        Based on your answers, it seems like these are some challenges you are facing. If you don't feel like a specific category applies to you, or if you have
        overcome this challenge, please select it so we can update your information.
      </p>
      <Card>
        {challenges && challenges.size >= 1 ? (
          <div className="challenges">
            <h4>Mark challenge as completed</h4>
            <Grid container spacing={2}>
              {challenges.map((challenge) => (
                <Grid xs={6} lg={3} key={`${challenge.get('challengeType')}-key`} item>
                  <GridTile className="tile">
                    <Checkbox onChange={() => updateMarkedChallenges(challenge.get('id'))} value="checked" style={{ float: 'right' }} />
                    <img src={challenge.get('iconUrl') || '/images/challenges-housing.png'} alt={`${challenge.get('challengeType')}-icon`} />
                    <p>{challenge.get('challengeType')}</p>
                  </GridTile>
                </Grid>
              ))}
            </Grid>
          </div>
        ) : (
          <h4 style={{ marginTop: 5 }}>All Challenges Completed</h4>
        )}
        {challenges && challenges.size >= 1 && markedChallenges.length >= 1 && (
          <Button buttonType="primary" onClick={() => completeChallenges(currentUser, markedChallenges)}>
            Confirm Completion
          </Button>
        )}
      </Card>
    </ChallengeChecklistStyles>
  );
};

ChallengeChecklistView.propTypes = propTypes;
ChallengeChecklistView.defaultProps = defaultProps;

export default ChallengeChecklistView;
