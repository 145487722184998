import { connect } from 'react-redux';
import ParentAccountReportSummaryView from './parent_account_report_summary_view';
import { getChildOrganizations, getCurrentUser, getPathways } from '../../../../reducer';

const mapStateToProps = (state) => ({
  pathways: getPathways(state),
  childOrganizations: getChildOrganizations(state, getCurrentUser(state).getIn(['staffOrganization', 'id'])),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {};

const ParentAccountReportSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(ParentAccountReportSummaryView);

export default ParentAccountReportSummaryContainer;
