// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
// import EditableCardToggle from '../editable_card_toggle';
import ContactInfoStyles from './contact_info_styles';
import { Card } from '../../../../common/styles/layout';
import Display from './display';
// import Editing from './editing';

const propTypes = {
  contactInfo: PropTypes.instanceOf(Map),
  onProgress: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onContactInfoDelete: PropTypes.func.isRequired,
};

const defaultProps = {
  contactInfo: undefined,
  onProgress: undefined,
};

// NOTE: This is not resume data, it is profile data that we want to be able to edit in the resume context
// TODO: wire back up so that it can toggle editable and update the user's profile data
// TODO: should accept a candidateId and do all the data management iself, no `contactInfo` parameter
// eslint-disable-next-line no-unused-vars
const ContactInfoView = ({ contactInfo, onProgress, onSubmit, onContactInfoDelete }) => (
  // const [editing, setEditing] = useState(false);
  // const activeView = editing ? <Editing initialValues={contactInfo.toJS()} onSubmit={onSubmit} /> : <Display contactInfo={contactInfo} />;
  // const onDelete = () => onContactInfoDelete(contactInfo.get('id'));
  // const onSave = () => {
  //   if (onProgress) {
  //     onProgress();
  //   }
  //   setEditing(false);
  // };
  // const onEdit = () => setEditing(true);
  // const onCancel = () => {
  //   setEditing(false);
  // };

  <ContactInfoStyles>
    <h3>Contact Info</h3>
    <Card>
      {/* <EditableCardToggle editing={editing} onDelete={onDelete} onSave={onSave} onEdit={onEdit} onCancel={onCancel} formName="contactInfo" /> */}
      {/* <Editing initialValues={contactInfo.toJS()} onSubmit={onSubmit} /> */}
      {contactInfo ? <Display contactInfo={contactInfo} /> : 'Info will load once resume is saved'}

      <div className="contact-info-note">
        * You can edit this information in personal settings.
      </div>
    </Card>
  </ContactInfoStyles>
);
ContactInfoView.propTypes = propTypes;
ContactInfoView.defaultProps = defaultProps;

export default ContactInfoView;
