import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import React, { useState, useLayoutEffect } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as toast from 'risekit/common/components/toast';

import { OAUTH } from '../../../api';
import RegisterStyles from './register_styles';
import { useApiCall } from '../../../util/hooks';
import SelectOnboarding from '../select_onboarding';
import Spinner from '../../../common/components/spinner';
import TermsAndConditionView from './terms_and_condition_view';
import { Field, TextField, MuiCheckbox } from '../../../common/form';
import PublicContainer from '../../../solve/components/public_container';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  onboardingMode: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  isRisekit: PropTypes.bool.isRequired,
  location: PropTypes.shape({ state: PropTypes.shape({ returnTo: PropTypes.string }) }).isRequired,
};

const defaultProps = {
  onboardingMode: undefined,
  pristine: false,
  invalid: false,
};

const oauthButton = (provider, returnTo) => (
  <a key={provider} href={OAUTH.getUrl(provider, returnTo)} className="oauth-button">
    {provider === 'developer' ? <i className="fas fa-user-secret fa-2x" /> : <i className={`fab fa-${provider} fa-5x`} />}
  </a>
);

const DEFAULT_RETURN_TO = '/dashboard';

const RegisterView = ({ signedIn, onboardingMode, handleSubmit, pristine, invalid, isRisekit, location: { state } }) => {
  const [hasReadTermsAndCondition, setHasReadTermsAndCondition] = useState(false);

  const handleOnReadTermsAndConditions = () => setHasReadTermsAndCondition(true);

  useLayoutEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);
  const returnTo = (state && state.returnTo) || DEFAULT_RETURN_TO;

  const isRegisterBtnDisabled = !hasReadTermsAndCondition || pristine || invalid || submitting;

  if (signedIn && onboardingMode) {
    return <Redirect to={returnTo} />;
  }

  const onConsentCheckboxClick = () => {
    if (!hasReadTermsAndCondition) {
      toast.warning({
        title: '',
        message: 'Please view the User Consent by scrolling to the bottom of the text above',
      });
    }
  };

  const oauthRow = (
    <div className="oauth-row">
      <span>Sign in with:</span>
      {OAUTH.providers.map((provider) => oauthButton(provider, returnTo))}
    </div>
  );

  const agreeStatement = (
    <p>
      I consent to the above provisions.
    </p>
  );

  return (
    <PublicContainer>
      <div className="container padding">
        <div className="auth">
          {!onboardingMode ? (
            <SelectOnboarding />
          ) : (
            <RegisterStyles>
              <form onSubmit={onSubmit}>
                {onboardingMode === 'candidate' && (
                  <p className="candidate-onboarding">
                    {`Welcome to ${isRisekit ? 'RiseKit' : 'Solve'}! `}
                    Please create an account so that we can help you <strong>find the best jobs</strong>, <strong>training opportunities</strong>, and{' '}
                    <strong>resources needed</strong> to succeed.
                  </p>
                )}
                <h3>Sign Up</h3>
                <Field component={TextField} label="Email or Mobile Phone Number (including area code)" type="text" name="email" fullWidth required />
                <Field component={TextField} label="Password" type="password" name="password" fullWidth required />
                <Field component={TextField} label="Password Confirmation" type="password" name="password_confirmation" fullWidth required />
                <FormGroup>
                  <span className="terms-and-condition__label">User Consent (scroll down) *</span>
                  <TermsAndConditionView onReadTermsAndCondition={handleOnReadTermsAndConditions} />
                  <FormControlLabel
                    onClick={onConsentCheckboxClick}
                    control={<Field component={MuiCheckbox} name="privacyAgreement" variant="outlined" required disabled={!hasReadTermsAndCondition} />}
                    label={agreeStatement}
                    className={!hasReadTermsAndCondition ? 'accept-terms-and-condition--disabled' : ''}
                  />
                </FormGroup>
                <input type="submit" className="btn" value="Register" disabled={isRegisterBtnDisabled} />
                <span className="status">
                  {failed && <span className="error">Unable to register a new account.</span>}
                  {submitting && <Spinner />}
                </span>
                {OAUTH.canRegister && OAUTH.providers.size > 0 && oauthRow}
                <p>
                  <Link to="/signin">Already have an account?</Link>
                </p>
                <div id="google_translate_element" />
              </form>
            </RegisterStyles>
          )}
        </div>
      </div>
    </PublicContainer>
  );
};

RegisterView.propTypes = propTypes;
RegisterView.defaultProps = defaultProps;

export default RegisterView;
