import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Field, reduxForm } from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { MuiRadioGroup, MuiTextField } from '../../../common/form/material_ui';
import { Card } from '../../../common/styles/layout';
import { Text, TextHeading } from '../../../common/components/text';
import { Button } from '../../../common/components';
import { phoneMask } from '../../../common/form/helpers';
import InviteStaffToSolveStyles from './invite_staff_to_solve_styles';
import InviteStaffToSolveValidate from './invite_staff_to_solve_validate';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  createPublicLink: PropTypes.func.isRequired,
  publicLink: PropTypes.instanceOf(Map),
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  toChannel: PropTypes.string,
};

const defaultProps = {
  publicLink: undefined,
  toChannel: '',
};

const InviteStaffToSolveForm = ({ handleSubmit, createPublicLink, publicLink, currentUser, toChannel }) => {
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    if (!publicLink) {
      createPublicLink(currentUser, 'join-solve-as-staff', currentUser.getIn(['staffOrganization', 'id']));
    }
  }, [currentUser, createPublicLink, publicLink]);

  const handleSubmitAndRedirect = (event) => {
    try {
      handleSubmit(event)
        .then(() => {
          setSubmitted(true);
        })
        .catch(() => {
          setSubmitted(false);
          alert('Not saved, please try again');
        });
    } catch (err) {
      setSubmitted(false);
      alert('Not saved. Please check the fields for errors and try again.');
    }
  };

  if (submitted) {
    history.goBack();
  }
  return (
    <InviteStaffToSolveStyles>
      <form onSubmit={handleSubmitAndRedirect}>
        <Card>
          &nbsp; Invite link: &nbsp; {publicLink && publicLink.get('publicUrl')}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextHeading size="large" weight="bold" margin="both">
                Invite Staff to join RiseKit
              </TextHeading>
            </Grid>
            <Grid item md={12}>
              <Field name="channel" component={MuiRadioGroup} required>
                <Text size="small">How would you like to send the invite?</Text>
                <FormGroup row>
                  <FormControlLabel control={<Radio color="primary" />} value="phone" label="Phone" />
                  <FormControlLabel control={<Radio color="primary" />} value="email" label="Email" />
                </FormGroup>
              </Field>
            </Grid>
            <Grid item md={12}>
              <div style={toChannel === 'phone' ? { visibility: 'visible', height: 'auto' } : { visibility: 'hidden', height: '0px' }}>
                <Grid item md={2}>
                  <Field
                    component={MuiTextField}
                    variant="outlined"
                    label="Phone Number of Staff"
                    name="toPhone"
                    type="cell"
                    margin="dense"
                    fullWidth
                    required
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...phoneMask}
                  />
                </Grid>
              </div>
              <div style={toChannel === 'email' ? { visibility: 'visible', height: 'auto' } : { visibility: 'hidden', height: '0px' }}>
                <Grid item md={6}>
                  <Field component={MuiTextField} variant="outlined" label="Email of Staff" type="email" name="toEmail" margin="dense" fullWidth required />
                </Grid>
              </div>
            </Grid>

            <Grid item md={12}>
              <Grid item md={6}>
                <Field component={MuiTextField} variant="outlined" label="Add Message (Optional)" name="extra.message" margin="dense" fullWidth multiline />
                <br />
                <br />
              </Grid>
            </Grid>

            <Grid item md={12}>
              <Link to="/staff/clients">
                <Button buttonType="secondary-outline">Cancel</Button>
              </Link>

              <Button buttonType="secondary" onClick={handleSubmitAndRedirect} style={{ float: 'right' }}>
                Invite User
              </Button>
              <br />
              <br />
            </Grid>
          </Grid>
        </Card>
      </form>
    </InviteStaffToSolveStyles>
  );
};

const InviteStaffToSolveView = reduxForm({
  form: 'inviteStaffToSolve',
  enableReinitialize: true,
  validate: InviteStaffToSolveValidate,
})(InviteStaffToSolveForm);

InviteStaffToSolveForm.propTypes = propTypes;
InviteStaffToSolveForm.defaultProps = defaultProps;

export default InviteStaffToSolveView;
