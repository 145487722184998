import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List, Iterable } from 'immutable';
import moment from 'moment';

import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import DoneIcon from '@material-ui/icons/Done';
import { Button } from '../../common/components';

import { candidateStaffStatusEnums } from '../../enums/staff_status_enums';
import CandidateApplicationModel from '../../solve/models/candidate_application_model';

import Dialog from '../../ui/components/dialog';
import CustomApplicantStepper from './custom_applicant_stepper';
import ReadOnlySurveyWizard from '../../staff/components/read_only_survey_wizard/read_only_survey_wizard';
import ApplicationListItemStyles, { Header, Footer, Status, Statuses } from './application_list_item_styles';
import UserModel from '../../session/models/user_model';
import StatusUpdateTile from '../../solve/components/status_update_tile';

const { recommended, appliedOnSolve, appliedOnSite, reviewed, interviewing, offer, hired, archived } = candidateStaffStatusEnums;

const candidateSteps = [
  { label: 'Recommended/Saved', value: recommended },
  { label: 'Applied On RiseKit', value: appliedOnSolve, optional: true },
  { label: 'Applied On Company Website', value: appliedOnSite, optional: true },
  { label: 'Reviewed', value: reviewed },
  { label: 'Interviewing', value: interviewing },
  { label: 'Offer', value: offer },
];

const candidateStepMapping = {
  [recommended]: 0,
  [appliedOnSolve]: 1,
  [appliedOnSite]: 2,
  [reviewed]: 3,
  [interviewing]: 4,
  [offer]: 5,
};

const CandidateSurveyAnswers = ({ surveys }) => (
  <div>
    {surveys.size ? (
      surveys.map((survey) => (
        /* NOTE: Once styles and data ingestion for surveys are finalized,
      create a common component that shares all survey-related rendering/util logic */
        <ReadOnlySurveyWizard survey={survey} key={survey.get('id')} />
      ))
    ) : (
      <Typography>This candidate has not completed any surveys, or this job has no surveys associated with it.</Typography>
    )}
  </div>
);

CandidateSurveyAnswers.propTypes = {
  surveys: PropTypes.instanceOf(List).isRequired,
};

const ApplicationListItemView = ({ currentUser, application, transitionCandidateApplicationByStaff, canEdit }) => {
  const staffState = application.get('staffState');
  const [activeStep, setActiveStep] = React.useState(candidateStepMapping[staffState]);
  const [screenerOpen, setScreenerOpen] = React.useState(false);
  const candidateSurveys = application.get('candidateSurveys');
  const candidateApplicationId = application.get('id');
  const jobId = application.getIn(['jobPosting', 'jobPostingId']);

  const totalQuestions = candidateSurveys.reduce((acc, survey) => acc + survey.get('numberOfQuestions'), 0);
  const answeredQuestions = candidateSurveys.reduce((acc, survey) => acc + survey.get('answered'), 0);

  const updateStaffStatus = (status) => canEdit && transitionCandidateApplicationByStaff(currentUser, application.get('id'), status.substring(2));

  const getActiveStep = () => {
    if (staffState === hired) {
      return 5;
    }
    if (staffState === archived) {
      return -1;
    }
    return activeStep;
  };

  // TODO: if candidate has only been invited but not created, use invited_list_item_view.js

  return (
    <Fragment>
      <StatusUpdateTile record={application} setStaffState={(state) => transitionCandidateApplicationByStaff(currentUser, application.get('id'), state)} />
      <ApplicationListItemStyles>
        <Header>
          <div className="applicant">
            <div style={{ width: '175px' }}>
              <h2>
                {application.getIn(['candidate', 'user', 'firstName'])} {application.getIn(['candidate', 'user', 'lastName'])}
              </h2>
            </div>
            <div className="table-entry">
              <p>Referred By</p>
              <h4>{application.getIn(['helpingOrganization', 'name'])}</h4>
            </div>
            <div className="table-entry">
              <p>Questions</p>
              <Button onClick={() => setScreenerOpen(true)} style={{ textAlign: 'left' }}>
                <span>{answeredQuestions}</span>
                <span> / </span>
                <span>{totalQuestions}</span>
              </Button>
              <Dialog open={screenerOpen} handleClose={() => setScreenerOpen(false)} title="Job Candidate Screening Questions">
                <CandidateSurveyAnswers surveys={candidateSurveys} />
              </Dialog>
            </div>
            <div className="table-entry">
              <p>Applied</p>
              <h4>{application.get('appliedAt') ? moment(application.get('appliedAt')).format('Do MMM') : 'Unknown'}</h4>
            </div>
          </div>
          <div>
            <Link to={`/staff/jobs/${jobId}/candidates/${candidateApplicationId}`}>
              <Button buttonType="secondary-outline">View</Button>
            </Link>
            <Link to={`/staff/messages/new/${application.getIn(['candidate', 'candidateId'])}`}>
              <Button buttonType="secondary-outline">Send Message</Button>
            </Link>
          </div>
        </Header>
        <Footer>
          <div style={{ flex: 1 }}>
            <CustomApplicantStepper
              steps={candidateSteps}
              activeStep={getActiveStep()}
              setActiveStep={canEdit ? setActiveStep : () => {}}
              onChange={updateStaffStatus}
            />
          </div>
          <Statuses>
            <Status color={staffState === archived ? '#FF6F6F' : null} onClick={() => updateStaffStatus(archived)}>
              <CloseIcon /> Archived
            </Status>
            <Status color={staffState === hired ? '#059765' : null} onClick={() => updateStaffStatus(hired)}>
              <DoneIcon /> Hired
            </Status>
          </Statuses>
        </Footer>
      </ApplicationListItemStyles>
    </Fragment>
  );
};

ApplicationListItemView.propTypes = {
  application: PropTypes.oneOfType([PropTypes.instanceOf(CandidateApplicationModel), PropTypes.instanceOf(Iterable)]).isRequired,
  transitionCandidateApplicationByStaff: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  canEdit: PropTypes.bool.isRequired,
};

ApplicationListItemView.defaultProps = {};

export default ApplicationListItemView;
