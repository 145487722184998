import styled from 'styled-components';
import theme from '../../../common/theme';

const ListStaffStyles = styled.div`
  h1 {
    text-transform: uppercase;
  }
  table {
    width: 100%;
    border-spacing: 0px;
    th {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.1em;
      color: ${theme.color.text.medium};
      text-align: center;
      padding: 0px 16px 10px;
    }
    td {
      border-top: solid 1px #ccc;
    }
    td {
      h4 {
        color: #394148;
      }
      h4,
      p {
        margin: 0;
      }
      color: #777;
      font-size: 14px;
    }
  }
`;

export default ListStaffStyles;
