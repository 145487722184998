// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

const REQUIRED = 'Required';

// const DATE_INVALID = 'Date is not valid';
// const DATE_IS_AFTER = 'Date is after start date';

const resumeFormValidate = (values) => {
  const errors = {};

  const workExperienceValidate = (workExperience) => {
    const workExperienceErrors = { experience: {} };

    if (!workExperience || !workExperience.getIn(['experience', 'title'])) {
      workExperienceErrors.experience.title = REQUIRED;
    }
    if (!workExperience || !workExperience.getIn(['experience', 'company'])) {
      workExperienceErrors.experience.company = REQUIRED;
    }
    if (!workExperience || !workExperience.getIn(['experience', 'city'])) {
      workExperienceErrors.experience.city = REQUIRED;
    }
    if (!workExperience || !workExperience.getIn(['experience', 'state'])) {
      workExperienceErrors.experience.state = REQUIRED;
    }
    if (!workExperience || !workExperience.getIn(['experience', 'startDate'])) {
      workExperienceErrors.experience.startDate = REQUIRED;
    }

    // else if (!isValid(workExperience.getIn(['experience', 'startDate']))) {
    //   workExperienceErrors.experience.startDate = DATE_INVALID;
    // }

    if (
      !workExperience ||
      (workExperience.getIn(['experience', 'isCurrent']) !== true &&
        (workExperience.getIn(['experience', 'endDate']) === 'Invalid date' || !workExperience.getIn(['experience', 'endDate'])))
    ) {
      workExperienceErrors.experience.endDate = REQUIRED;
    }

    // else if (!isValid(workExperience.getIn(['experience', 'endDate']))) {
    //   workExperienceErrors.experience.endDate = DATE_INVALID;
    // } else if (!isAfter(workExperience.getIn(['experience', 'endDate']), workExperience.getIn(['experience', 'startDate']))) {
    //   workExperienceErrors.experience.endDate = DATE_IS_AFTER;
    // }

    return workExperienceErrors;
  };

  const educationValidate = (education) => {
    const educationErrors = { education: {} };

    if (!education || !education.getIn(['education', 'school'])) {
      educationErrors.education.school = REQUIRED;
    }
    if (!education || !education.getIn(['education', 'educationLevel'])) {
      educationErrors.education.educationLevel = REQUIRED;
    }
    // if (!education || !education.getIn(['education', 'major'])) {
    //   educationErrors.education.major = REQUIRED;
    // }
    if (!education || !education.getIn(['education', 'city'])) {
      educationErrors.education.city = REQUIRED;
    }
    if (!education || !education.getIn(['education', 'state'])) {
      educationErrors.education.state = REQUIRED;
    }

    if (!education || !education.getIn(['education', 'startDate'])) {
      educationErrors.education.startDate = REQUIRED;
    }
    if (
      !education ||
      (education.getIn(['education', 'isCurrent']) !== true &&
        (education.getIn(['education', 'endDate']) === 'Invalid date' || !education.getIn(['education', 'endDate'])))
    ) {
      educationErrors.education.endDate = REQUIRED;
    }

    return educationErrors;
  };

  const trainingProgramValidate = (program) => {
    const programErrors = { program: {} };
    if (!program || !program.getIn(['program', 'title'])) {
      programErrors.program.title = REQUIRED;
    }
    if (!program || !program.getIn(['program', 'city'])) {
      programErrors.program.city = REQUIRED;
    }
    if (!program || !program.getIn(['program', 'state'])) {
      programErrors.program.state = REQUIRED;
    }
    if (!program || !program.getIn(['program', 'startDate'])) {
      programErrors.program.startDate = REQUIRED;
    }
    if (
      !program ||
      (program.getIn(['program', 'isCurrent']) !== true &&
        (!program.getIn(['program', 'endDate']) === 'Invalid date' || !program.getIn(['program', 'endDate'])))
    ) {
      programErrors.program.endDate = REQUIRED;
    }

    return programErrors;
  };

  const workExperienceArray = values.get('workExperience');
  if (workExperienceArray) {
    errors.workExperience = workExperienceArray.map(workExperienceValidate).toJS();
  }

  const educationArray = values.get('education');
  if (educationArray) {
    errors.education = educationArray.map(educationValidate).toJS();
  }

  const trainingProgramsArray = values.get('trainingPrograms');
  if (trainingProgramsArray) {
    errors.trainingPrograms = trainingProgramsArray.map(trainingProgramValidate).toJS();
  }

  return errors;
};

export default resumeFormValidate;
