/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import EventPreviewStyles from './event_preview_styles';
import { MainStyles, MainCard, Logo, MainActions } from '../../../../common/styles/detail';
import getListingImage from '../../../../common/components/get_listing_image';
import EventDetails from '../../../../events/components/event_details';
import PreviewButtons from '../preview_buttons';

const propTypes = {
  publicLink: PropTypes.instanceOf(Map).isRequired,
  signedIn: PropTypes.bool.isRequired,
};

const defaultProps = {};

const EventPreviewView = ({ signedIn, publicLink }) => {
  const event = publicLink.get('subjectEvent') || new Map();

  const logoUrl = getListingImage(event, 'event');

  const redirectPath = `/candidate/events/${event.get('id')}`;

  if (signedIn) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <EventPreviewStyles>
      {event && (
        <MainStyles noMargin>
          <div className="candidate-wrapper">
            <MainCard>
              <MainActions>
                <Logo>
                  <img src={logoUrl} alt="Event Logo" />
                </Logo>
                <PreviewButtons previewType="event" redirectPath={redirectPath} />
              </MainActions>

              <EventDetails event={event} />
            </MainCard>
          </div>
        </MainStyles>
      )}
    </EventPreviewStyles>
  );
};

EventPreviewView.propTypes = propTypes;
EventPreviewView.defaultProps = defaultProps;

export default EventPreviewView;
