import { fromJS } from 'immutable';
import ACTIONS from '../../actions';

const DEFAULT_STATE = fromJS({
  filters: { state: [] },
});

const PathwayReportFiltersReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_PATHWAY_REPORT_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          filters: action.filters,
        });
      }
      return state;
    default:
      return state;
  }
};

export const getPathwayReportFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');

export default PathwayReportFiltersReducer;
