import { connect } from 'react-redux';
import { fromJS, Map } from 'immutable';
import ListStaffForm from './list_staff_form';
import { getCurrentUser, getActiveStaff } from '../../../reducer';
import { listStaff, deleteStaffMember, updateStaffMember } from '../../../solve/actions';

// Build a new map lookup for staff, only populate `permissions` for now.
const buildInitialValues = (allStaff) =>
  Map(
    allStaff.map((staffMember) => [
      staffMember.get('id'),
      Map({
        permissions: Map(staffMember.get('permissions').map((key) => [key, true])),
      }),
    ]),
  );

const mapStateToProps = (state) => {
  const currentUser = fromJS(getCurrentUser(state));
  const organization = fromJS(currentUser.get('staffOrganization'));
  const initialValues = buildInitialValues(getActiveStaff(state));
  return {
    currentUser,
    organization,
    organizationId: organization.get('id'),
    staff: getActiveStaff(state),
    initialValues,
  };
};

const mapDispatchToProps = {
  listStaff,
  updateStaffMember,
  onDelete: (user, staffId) => deleteStaffMember(user, staffId),
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    stateProps.staff.forEach((staff) => {
      const permissionsMap = values.get(staff.get('id'));
      const permissionsList = Object.entries(permissionsMap.get('permissions').toJS()).reduce((acc, arr) => {
        if (arr[1]) {
          acc.push(arr[0]);
        }
        return acc;
      }, []);
      dispatchProps.updateStaffMember(stateProps.currentUser, staff.get('id'), new Map({ permissions: permissionsList }));
    });
  },
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
});

const ListStaffContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListStaffForm);

export default ListStaffContainer;
