import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import ParentAccountReportDemographicsStyles from './parent_account_report_demographics_styles';
import UserModel from '../../../../session/models/user_model';
import ParentAccountGenderReport from './parent_account_gender_report';
import ParentAccountEthnicityReport from './parent_account_ethnicity_report';
import ParentAccountAgeReport from './parent_account_age_report';

const propTypes = {
  pathways: PropTypes.instanceOf(OrderedSet).isRequired,
  childOrganizations: PropTypes.instanceOf(OrderedSet).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
};

const defaultProps = {};

const ParentAccountReportDemographicsView = ({ pathways, childOrganizations, currentUser }) => (
  <ParentAccountReportDemographicsStyles>
    {pathways.map((pathway) => (
      <React.Fragment key={pathway.get('id')}>
        <h2>{pathway.get('name')} Demographics Report</h2>
        <div className="gender">
          <h4>Gender</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Male</th>
                <th>Female</th>
                <th>Non-binary</th>
                <th>Other</th>
                <th>Prefer not to Share</th>
              </tr>
            </thead>
            <tbody>
              {childOrganizations.map((childOrganization) => (
                <ParentAccountGenderReport
                  key={childOrganization.get('id')}
                  currentUser={currentUser}
                  organization={childOrganization}
                  pathwayId={pathway.get('id')}
                />
              ))}
            </tbody>
          </table>
        </div>

        <div className="ethnicity">
          <h4>Ethnicity Origin</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Black or African American</th>
                <th>White or Caucasian</th>
                <th>East Asian</th>
                <th>Latin American</th>
                <th>Middle Eastern</th>
                <th>Mixed Ethnicity</th>
                <th>American Indian or Alaskan Native</th>
                <th>Native Hawaiian or Pacific Islander</th>
                <th>South Asian or Indian</th>
                <th>Other</th>
                <th>Not Specified</th>
              </tr>
            </thead>
            <tbody>
              {childOrganizations.map((childOrganization) => (
                <ParentAccountEthnicityReport
                  key={childOrganization.get('id')}
                  currentUser={currentUser}
                  organization={childOrganization}
                  pathwayId={pathway.get('id')}
                />
              ))}
            </tbody>
          </table>
        </div>

        <div className="age">
          <h4>Age</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>18-24</th>
                <th>25-34</th>
                <th>35-44</th>
                <th>45-54</th>
                <th>55-64</th>
                <th>65+</th>
              </tr>
            </thead>
            <tbody>
              {childOrganizations.map((childOrganization) => (
                <ParentAccountAgeReport
                  key={childOrganization.get('id')}
                  currentUser={currentUser}
                  organization={childOrganization}
                  pathwayId={pathway.get('id')}
                />
              ))}
            </tbody>
          </table>
        </div>
        <br />
      </React.Fragment>
    ))}
  </ParentAccountReportDemographicsStyles>
);

ParentAccountReportDemographicsView.propTypes = propTypes;
ParentAccountReportDemographicsView.defaultProps = defaultProps;

export default ParentAccountReportDemographicsView;
