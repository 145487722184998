import { connect } from 'react-redux';
import { memoize } from 'lodash';
import JobDetailsFormView from './job_details_form_view';
import { formValueSelector } from '../../../../common/form';
import { getSystemEnumList, getEnumsForFormSelect, getStaff, getCurrentUser } from '../../../../reducer';
import { listStaff } from '../../../../solve/actions';

const jobFormSelector = formValueSelector('jobForm');
const mapContacts = memoize(
  (set) => set.map((staff) => [staff.get('id'), `${staff.getIn(['user', 'firstName'])} ${staff.getIn(['user', 'lastName'])}`]),
  (set) => set.size,
);
const resolveEnumSlugAndLabel = memoize((name, state) => getSystemEnumList(state, name).map((item) => [item.get('slug'), item.get('label')]));

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
  industryTypes: getEnumsForFormSelect(state, 'IndustryType'),
  jobTypes: resolveEnumSlugAndLabel('JobType', state),
  payTypes: resolveEnumSlugAndLabel('PayType', state),
  orgCategoryType: resolveEnumSlugAndLabel('OrgCategoryType', state),
  contacts: mapContacts(getStaff(state)),
  noStartDate: jobFormSelector(state, 'noStartDate'),
  wageSpecified: jobFormSelector(state, 'wageSpecified'),
  logo: jobFormSelector(state, 'jobPostingLogo'),
  employerId: jobFormSelector(state, 'employerId'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listStaff,
};

const JobDetailsFormContainer = connect(mapStateToProps, mapDispatchToProps)(JobDetailsFormView);

export default JobDetailsFormContainer;
