import styled from 'styled-components';
import theme from '../../../../../common/theme';

const SkillsEditingStyles = styled.div`
  .add-button {
    padding: 10px 25px;
    border-radius: 5px;
    font-size: 1em;
    font-style: italic;
    text-align: left;
    color: ${theme.color.grayDark};
  }
  .add-icon {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    padding: 2px;
    margin-right: 10px;
  }
  @media ${theme.query.tabletMax} {
    .add-button {
      padding: 10px;
    }
  }
`;

export default SkillsEditingStyles;
