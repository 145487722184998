import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { intersection } from 'lodash';
import { OrderedSet } from 'immutable';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { isNonProfitInmateUser } from 'util/common';
import { useDebounce } from 'risekit/common/hooks/useDebounce';

import { Spinner } from '../../../../../../common/components';
import { FormSection } from '../../../../../../common/form';
import { StyledTable, ResultsPageNavigator } from './select_candidates_list_styles';
import UserModel from '../../../../../../session/models/user_model';
import SortHeading from '../../../../../../common/components/sort_heading';
import SelectCandidateRow from './select_candidate_row';
import theme from '../../../../../../common/theme';

const propTypes = {
  candidateIds: PropTypes.instanceOf(OrderedSet).isRequired,
  organizationId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  listFilteredCandidatesForStaff: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  setFilters: PropTypes.func.isRequired,
  totalCandidates: PropTypes.number.isRequired,
  changeSelectedCandidateState: PropTypes.func.isRequired,
  selectedCandidateIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const defaultProps = {};

const SelectCandidatesListView = ({
  candidateIds,
  organizationId,
  listFilteredCandidatesForStaff,
  currentUser,
  loaded,
  filters,
  setFilters,
  totalCandidates,
  selectedCandidateIds,
  changeSelectedCandidateState,
}) => {
  const [sortBy, setSortBy] = useState('lastName');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [pageShown, setPageShown] = useState(1);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchText = useDebounce(searchText, 1000);

  const totalPages = Math.ceil(totalCandidates / 25) || 1;

  useEffect(() => {
    const filterValues = filters.merge({ userFullName: debouncedSearchText || undefined });

    setFilters(filterValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText]);
  useEffect(() => {
    setPageShown(1);
  }, [filters]);

  useEffect(() => {
    if (!loaded) {
      listFilteredCandidatesForStaff(currentUser, organizationId, filters, pageShown);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, filters, currentUser, organizationId, pageShown]);

  function toggleSort(sortByValue, sortOrderValue) {
    if (sortBy !== sortByValue) {
      setSortBy(sortByValue);
    }
    if (sortOrder !== sortOrderValue) {
      setSortOrder(sortOrderValue);
    }
    const filterValues = filters.merge({ _sort: sortByValue, _order: sortOrderValue });
    setFilters(filterValues);
  }

  const scrollTop = () => window.scrollTo(0, 0);
  const getNextPageResults = () => {
    listFilteredCandidatesForStaff(currentUser, organizationId, filters, pageShown + 1).then(() => scrollTop());
    setPageShown(pageShown + 1);
  };
  const getPrevPageResults = () => {
    listFilteredCandidatesForStaff(currentUser, organizationId, filters, pageShown - 1).then(() => scrollTop());
    setPageShown(pageShown - 1);
  };

  const PageNavigator = () => (
    <ResultsPageNavigator>
      {totalCandidates > 25 && (
        <div className="prev-next-container">
          <Button
            style={{ color: pageShown <= 1 ? theme.color.grayLight : theme.color.blue }}
            startIcon={<NavigateBeforeIcon />}
            disabled={pageShown <= 1}
            onClick={() => getPrevPageResults()}
          >
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown} of ${totalPages}`}</Typography>
          <Button
            style={{ color: pageShown >= totalPages ? theme.color.grayLight : theme.color.blue }}
            endIcon={<NavigateNextIcon />}
            disabled={pageShown >= totalPages}
            onClick={() => getNextPageResults()}
          >
            Next
          </Button>
        </div>
      )}
    </ResultsPageNavigator>
  );

  PageNavigator.propTypes = {};
  PageNavigator.defaultProps = {};

  const isAllCandidatesSelected = intersection(candidateIds.toJS(), selectedCandidateIds).length === candidateIds.size;

  const setCandidateSelectedState = (state) => candidateIds.toJS().forEach((candidateId) => changeSelectedCandidateState(candidateId, state));

  const clearSelectedCandidate = () => setCandidateSelectedState(false);

  const selectAllCandidates = () => setCandidateSelectedState(true);

  return (
    <FormSection name="candidates">
      <TextField
        id="search"
        placeholder="Search"
        onChange={(e) => setSearchText(e.target.value)}
        value={searchText}
        margin="dense"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <PageNavigator />
      <div>
        Select All
        {isAllCandidatesSelected ? (
          <Checkbox indeterminate onChange={clearSelectedCandidate} inputProps={{ 'aria-label': 'indeterminate checkbox' }} />
        ) : (
          <Checkbox checked={false} onChange={selectAllCandidates} inputProps={{ 'aria-label': 'primary checkbox' }} />
        )}
      </div>
      <StyledTable>
        <thead>
          <tr>
            <th width="30">&nbsp;</th>
            <th width="200">
              <SortHeading
                toggleSort={toggleSort}
                sortBy="lastName"
                sortOrder={sortBy === 'lastName' ? sortOrder : 'ASC'}
                columnHeading="Name"
                type={sortBy === 'lastName' ? 'fill' : 'outline'}
              />
            </th>
            <th>Email</th>
            {!isNonProfitInmateUser(currentUser) ? (
              <th width="150">Zip</th>
            ) : (
              <React.Fragment>
                <th width="150">Inmate facility</th>
                <th width="150">Inmate number</th>
              </React.Fragment>
            )}
            <th width="150">
              <SortHeading
                toggleSort={toggleSort}
                sortBy="createdAt"
                sortOrder={sortBy === 'createdAt' ? sortOrder : 'ASC'}
                columnHeading="Date Joined"
                type={sortBy === 'createdAt' ? 'fill' : 'outline'}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {candidateIds ? candidateIds.map((candidateId) => <SelectCandidateRow key={candidateId} candidateId={candidateId} checkbox />) : <Spinner />}
        </tbody>
      </StyledTable>
      <PageNavigator bottom />
    </FormSection>
  );
};

SelectCandidatesListView.propTypes = propTypes;
SelectCandidatesListView.defaultProps = defaultProps;

export default SelectCandidatesListView;
