import { connect } from 'react-redux';
import EventApplicantDetailsView from './event_applicant_details_view';
import { getCandidateEvent, getCandidateSurveys, getCurrentUser } from '../../../reducer';
import { fetchCandidateEvent, listCandidateSurveys } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: candidateEventId },
    },
  },
) => ({
  candidateEventId,
  currentUser: getCurrentUser(state),
  candidateSurveys: getCandidateSurveys(state),
  application: getCandidateEvent(state, candidateEventId),
});

const mapDispatchToProps = {
  fetchCandidateEvent,
  listCandidateSurveys,
};

const EventApplicantDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(EventApplicantDetailsView);

export default EventApplicantDetailsContainer;
