import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router-dom';
import Spinner from '../../../common/components/spinner';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import EventPreview from './event_preview';
import ProgramPreview from './program_preview';
import JobPreview from './job_preview';
import JoinSolveAsStaffPreviewView from '../invite_preview/join_solve_as_staff_preview/join_solve_as_staff_preview_view';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  publicLink: PropTypes.instanceOf(Map),
  postingId: PropTypes.string.isRequired,
  fetchPublicLink: PropTypes.func.isRequired,
  visitedLink: PropTypes.func.isRequired,
};

const defaultProps = {
  publicLink: undefined,
};

const PostingPreviewsDetailView = ({ currentUser, postingId, publicLink, fetchPublicLink, visitedLink }) => {
  const resolvedPublicLink = useFetchOnce(currentUser, postingId, fetchPublicLink);

  useEffect(() => {
    if (publicLink) {
      const subjectType = publicLink.get('subjectType');
      const subjectId = subjectType !== 'ExternalJobPosting' ? publicLink.get('subjectId') : publicLink.getIn(['subjectExternalJobPosting', 'externalId']);

      visitedLink({ token: publicLink.get('id'), subjectType, subjectId });
    }
  }, [postingId, publicLink, visitedLink]);

  if (!postingId) {
    return <Redirect to="/" />;
  }

  if (!resolvedPublicLink) {
    return <Spinner />;
  }

  if (!publicLink) {
    return <Redirect to="/" />;
  }

  if (publicLink.get('publicLinkType') === 'join-solve-as-staff') {
    return <JoinSolveAsStaffPreviewView publicLinkOrInvitation={publicLink} currentUser={currentUser} />;
  }

  switch (publicLink.get('subjectType')) {
    case 'Program':
      return <ProgramPreview publicLink={publicLink} />;
    case 'Event':
      return <EventPreview publicLink={publicLink} />;
    default:
      return <JobPreview publicLink={publicLink} />;
  }
};

PostingPreviewsDetailView.propTypes = propTypes;
PostingPreviewsDetailView.defaultProps = defaultProps;

export default PostingPreviewsDetailView;
