import { connect } from 'react-redux';
import CandidateStepFileUploadView from './candidate_step_file_upload_view';
import { fetchCandidatePathway, transitionPathwayStepStaffState } from '../../../../../solve/actions';
import { getCurrentUser } from '../../../../../reducer';

// import { SomeSelectors } from '../../../reducer';
// import { SomeActions } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  fetchCandidatePathway,
  transitionPathwayStepStaffState,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (fields) => {
    const date_today = new Date();
    const the_file = fields.first()[0];
    dispatchProps
      .transitionPathwayStepStaffState(stateProps.user, ownProps.pisId, 'complete', { file: the_file, completed_on: date_today })
      .then(() => dispatchProps.fetchCandidatePathway(stateProps.user, ownProps.candidatePathwayId));
  },
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
});
const CandidateStepFileUploadContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(CandidateStepFileUploadView);

export default CandidateStepFileUploadContainer;
