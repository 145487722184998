import { connect } from 'react-redux';
import NotificationsView from './notifications_view';
import { listNotifications } from '../../../solve/actions';
import { getCurrentUser, getNotifications, getLabelsForNotifications } from '../../../reducer';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  notifications: getNotifications(state),
  notificationLabels: getLabelsForNotifications(state),
  isCandidate: !!getCurrentUser(state).get('candidateId'),
});

const mapDispatchToProps = {
  listNotifications,
};

const NotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(NotificationsView);

export default NotificationsContainer;
