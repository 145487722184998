import FlashReducer from './flash_reducer';
import ApiErrorReducer from './api_error_reducer';
import { combineImmutableReducers } from '../../util';

const Reducer = combineImmutableReducers({
  flashList: FlashReducer,
  apiErrors: ApiErrorReducer,
});

export default Reducer;

// Note: these functions operate on state at the level of the errors, not the RootReducer.
export const getApiError = (state, action) => (state.get('apiErrors').action === action ? state.get('apiErrors') : {});
export const getFlashList = (state) => state.flashList;
