import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DisplayStyles from './display_styles';

const propTypes = {
  industries: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

const DisplayView = ({ industries }) => (
  <DisplayStyles>
    <div className="field">
      <div className="industries-container">
        {industries &&
          industries
            .map((_, idx) => industries.get(idx))
            .map((industry, idx) => (
              <span key={industry} className="value industries">
                {industry}
                <IconButton onClick={() => industries.remove(idx)} aria-label="delete">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            ))}
      </div>
    </div>
  </DisplayStyles>
);

DisplayView.propTypes = propTypes;
DisplayView.defaultProps = defaultProps;

export default DisplayView;
