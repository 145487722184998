import ACTIONS from '../actions';
import { ResourcefulReducer } from '../../util';
import ExternalCandidateService from '../models/external_candidate_service_model';

const ExternalCandidateServiceReducer = new ResourcefulReducer('externalCandidateServices', ExternalCandidateService, {
  actionTypes: [
    ACTIONS.CREATE_EXTERNAL_CANDIDATE_SERVICE,
    ACTIONS.CREATE_EXTERNAL_CANDIDATE_SERVICE.success,
    ACTIONS.CANDIDATE_APPLY_TO_EXTERNAL_SERVICE,
    ACTIONS.CANDIDATE_APPLY_TO_EXTERNAL_SERVICE.success,
    ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES,
    ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES.success,
  ],
});

export default ExternalCandidateServiceReducer;
