import { connect } from 'react-redux';
import JobCandidatesView from './job_candidates_view';

import {
  getCurrentUser,
  getStaffCandidateJobIds,
  getStaffCandidatesForJobFilters,
  getStaffCandidatesForJobSearch,
  getStaffCandidatesForJobLoaded,
  getAllInvitationsForListing,
  // getStaffInvitations,
} from '../../../reducer';

import {
  listFilteredCandidatesForJob,
  setStaffJobCandidatesSearch,
  listInvitations,
  resendInvitation,
  fetchJobPostingParticipantsReport,
} from '../../../solve/actions';

const mapStateToProps = (state, { jobId }) => {
  const candidateApplicationIds = getStaffCandidateJobIds(state);

  return {
    currentUser: getCurrentUser(state),
    jobId,
    candidateApplicationIds,
    loaded: getStaffCandidatesForJobLoaded(state),
    filters: getStaffCandidatesForJobFilters(state),
    search: getStaffCandidatesForJobSearch(state),
    invitations: getAllInvitationsForListing(state, jobId),
  };
};

const mapDispatchToProps = {
  listFilteredCandidatesForJob,
  fetchJobPostingParticipantsReport,
  setSearch: setStaffJobCandidatesSearch,
  listInvitations,
  resendInvitation,
};

const JobCandidatesContainer = connect(mapStateToProps, mapDispatchToProps)(JobCandidatesView);

export default JobCandidatesContainer;
