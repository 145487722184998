import React from 'react';
import PropTypes from 'prop-types';
// import { List } from 'immutable';
import BackgroundsDisplay from './backgrounds_display';
import BackgroundsEditing from './backgrounds_editing';
import BackgroundStyles from './backgrounds_styles';
import { Card } from '../../../../common/styles/layout';

const propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  prefixWord: PropTypes.string,
  afterContent: PropTypes.string,
};

const defaultProps = {
  prefixWord: undefined,
  afterContent: undefined,
};

const BackgroundsView = ({ fields, prefixWord, afterContent }) => (
  <BackgroundStyles>
    <h3>
      {prefixWord} Backgrounds {afterContent}
    </h3>
    <Card>
      <div className="editing-container">
        <BackgroundsEditing backgrounds={fields} />
      </div>
      <div className="display-container">
        <BackgroundsDisplay backgrounds={fields} />
      </div>
    </Card>
  </BackgroundStyles>
);

BackgroundsView.propTypes = propTypes;
BackgroundsView.defaultProps = defaultProps;

export default BackgroundsView;
