import { mockResourcefulAPI } from '../../util/mock_api';
import CANDIDATES from './mocks/candidates';
import CANDIDATE_APPLICATIONS from './mocks/candidate_applications';
import INDEED_CANDIDATE_APPLICATIONS from './mocks/indeed_candidate_applications';
import CANDIDATE_EVENTS from './mocks/candidate_events';
import CANDIDATE_PROGRAMS from './mocks/candidate_programs';
import CANDIDATE_SERVICES from './mocks/candidate_services';
import CANDIDATE_SURVEYS from './mocks/candidate_surveys';
import CANDIDATE_PATHWAYS from './mocks/candidate_pathways';
import JOB_POSTINGS from './mocks/job_postings';
import INDEED_JOB_POSTINGS from './mocks/indeed_job_postings';
import ORGANIZATIONS from './mocks/organizations';
import RESUMES from './mocks/resumes';
import INMATES from './mocks/inmates';
import SURVEYS from './mocks/surveys';
import PROGRAMS from './mocks/programs';
import SERVICES from './mocks/services';
import EXTERNAL_CANDIDATE_SERVICES from './mocks/external_candidate_service';
import INVITATIONS from './mocks/invitations';
import NOTIFICATIONS from './mocks/notifications';
import EVENTS from './mocks/events';
import PATHWAYS from './mocks/pathways';
import PATHWAYINSTANCES from './mocks/pathway_instances';

import * as User from './user';

export const { signIn, OAUTH, finishSignIn, register, confirmEmail, reconfirmEmail, forgotPassword, resetPassword, refreshAuth, updateCurrentUser } = User;

const CANDIDATE_API = mockResourcefulAPI('candidates', CANDIDATES);

export const listCandidates = CANDIDATE_API.list;
export const createCandidate = CANDIDATE_API.create;
export const updateCandidate = CANDIDATE_API.update;
export const fetchCandidate = CANDIDATE_API.get;
export const listStaffCandidates = CANDIDATE_API.list;

const CANDIDATE_ORGANIZATION_API = mockResourcefulAPI('candidateApplications', []);
export const listCandidateOrganizations = CANDIDATE_ORGANIZATION_API.list;

const CANDIDATE_APPLICATION_API = mockResourcefulAPI('candidateApplications', CANDIDATE_APPLICATIONS);
export const listCandidateApplications = CANDIDATE_APPLICATION_API.list;
export const listCandidateApplicationsForCandidate = CANDIDATE_APPLICATION_API.list;
export const createCandidateApplication = CANDIDATE_APPLICATION_API.create;
export const createExternalCandidateApplication = CANDIDATE_APPLICATION_API.create;
export const fetchCandidateApplication = CANDIDATE_APPLICATION_API.get;
export const transitionCandidateApplicationByCandidate = CANDIDATE_APPLICATION_API.update;
export const listExternalCandidateApplicationsForCandidate = CANDIDATE_APPLICATION_API.list;
export const transitionExternalCandidateApplicationByCandidate = CANDIDATE_APPLICATION_API.update;
export const transitionCandidateApplicationByStaff = CANDIDATE_APPLICATION_API.update;

const INDEED_CANDIDATE_APPLICATION_API = mockResourcefulAPI('indeedCandidateApplications', INDEED_CANDIDATE_APPLICATIONS);
export const listIndeedCandidateApplicationsForCandidate = INDEED_CANDIDATE_APPLICATION_API.list;
export const fetchIndeedCandidateApplication = INDEED_CANDIDATE_APPLICATION_API.get;

const CANDIDATE_EVENT_API = mockResourcefulAPI('candidateEvents', CANDIDATE_EVENTS);
export const listCandidateEvents = CANDIDATE_EVENT_API.list;
export const listCandidatesForEvent = CANDIDATE_EVENT_API.list;
export const listCandidateEventsForCandidate = CANDIDATE_EVENT_API.list;
export const fetchCandidateEvent = CANDIDATE_EVENT_API.get;
export const createCandidateEvent = CANDIDATE_EVENT_API.create;
export const transitionCandidateEventByCandidate = CANDIDATE_EVENT_API.update;
export const transitionCandidateEventByStaff = CANDIDATE_EVENT_API.update;
export const listStaffCandidatesForEvent = CANDIDATE_EVENT_API.list;

const CANDIDATE_PROGRAM_API = mockResourcefulAPI('candidatePrograms', CANDIDATE_PROGRAMS);
export const listCandidatePrograms = CANDIDATE_PROGRAM_API.list;
export const listCandidatesForProgram = CANDIDATE_PROGRAM_API.list;
export const listCandidateProgramsForCandidate = CANDIDATE_PROGRAM_API.list;
export const fetchCandidateProgram = CANDIDATE_PROGRAM_API.get;
export const createCandidateProgram = CANDIDATE_PROGRAM_API.create;
export const transitionCandidateProgramByCandidate = CANDIDATE_PROGRAM_API.update;
export const transitionCandidateProgramByStaff = CANDIDATE_PROGRAM_API.update;
export const listStaffCandidatesForProgram = CANDIDATE_PROGRAM_API.list;

const CANDIDATE_SURVEY_API = mockResourcefulAPI('candidateSurveys', CANDIDATE_SURVEYS);
export const listCandidateSurveys = CANDIDATE_SURVEY_API.list;
export const startSurvey = CANDIDATE_SURVEY_API.get; // TODO
export const updateCandidateSurvey = CANDIDATE_SURVEY_API.update; // TODO

const CANDIDATE_PATHWAY_API = mockResourcefulAPI('candidatePathways', CANDIDATE_PATHWAYS);
export const fetchCandidatePathwaysForCandidate = CANDIDATE_PATHWAY_API.list;
export const listCandidatePathwaysForPathwayInstance = CANDIDATE_PATHWAY_API.list;
export const listCandidatePathwaysForOrganization = CANDIDATE_PATHWAY_API.list;
export const fetchCandidatePathway = CANDIDATE_PATHWAY_API.get;
export const updateCandidatePathway = CANDIDATE_PATHWAY_API.update;
export const bulkCreateCandidatePathways = CANDIDATE_PATHWAY_API.create;
export const transitionCandidatePathwayStaffState = CANDIDATE_PATHWAY_API.update;
export const transitionCandidatePathwaysStaffState = CANDIDATE_PATHWAY_API.get;
export const transitionCandidatePathwayCandidateState = CANDIDATE_PATHWAY_API.update;

const JOB_POSTINGS_API = mockResourcefulAPI('jobPostings', JOB_POSTINGS);
export const listJobPostings = JOB_POSTINGS_API.list;
export const createJobPosting = JOB_POSTINGS_API.create;
export const updateJobPosting = JOB_POSTINGS_API.update;
export const draftJobPosting = JOB_POSTINGS_API.update;
export const publishJobPosting = JOB_POSTINGS_API.update;
export const fetchJobPosting = (user, id) => JOB_POSTINGS_API.get(id);
export const fetchJobPostingsByIds = JOB_POSTINGS_API.list;
export const closeJobPosting = JOB_POSTINGS_API.update;
export const cancelJobPosting = JOB_POSTINGS_API.update;
export const activateJobPosting = JOB_POSTINGS_API.update;
export const listApplicationsToJobPosting = JOB_POSTINGS_API.list;
export const listStaffCandidatesForJob = JOB_POSTINGS_API.list;
export const fetchJobPostingParticipantsReport = JOB_POSTINGS_API.create;

const INDEED_JOB_POSTINGS_API = mockResourcefulAPI('indeedJobPostings', INDEED_JOB_POSTINGS);
export const listIndeedJobPostings = INDEED_JOB_POSTINGS_API.list;
export const fetchIndeedJobPosting = INDEED_JOB_POSTINGS_API.get;
export const fetchIndeedJobPostingByIds = INDEED_JOB_POSTINGS_API.list;

const ORGANIZATIONS_API = mockResourcefulAPI('organizations', ORGANIZATIONS);
export const listOrganizations = ORGANIZATIONS_API.list;
export const listFilteredOrganizations = ORGANIZATIONS_API.list;
export const listOrganizationNames = ORGANIZATIONS_API.list;
export const listChildOrganizations = ORGANIZATIONS_API.list;
export const fetchOrganization = ORGANIZATIONS_API.get;
export const updateOrganization = ORGANIZATIONS_API.update;
export const fetchOrganizationCandidatesReport = ORGANIZATIONS_API.create;
export const fetchOrganizationNewCandidatesReport = ORGANIZATIONS_API.create;
export const fetchOrganizationCareerFairReport = ORGANIZATIONS_API.create;
export const fetchRecidivismReport = ORGANIZATIONS_API.get;
export const createOrganization = (user, org) => ORGANIZATIONS_API.create(Math.random().toString(), org);

const RESUMES_API = mockResourcefulAPI('resumes', RESUMES);
export const listResumes = RESUMES_API.list;
export const fetchResume = RESUMES_API.get;
export const createResume = RESUMES_API.create;
export const updateResume = RESUMES_API.update;
export const approveResumeChanges = RESUMES_API.update;
export const declineResumeChanges = RESUMES_API.update;
export const uploadResume = RESUMES_API.create;

const INMATE_API = mockResourcefulAPI('inmates', INMATES);
export const fetchInmate = INMATE_API.get;
export const createInmate = INMATE_API.create;
export const updateInmate = INMATE_API.update;

const CHALLENGES_API = mockResourcefulAPI('challenges', []);
export const fetchChallenge = CHALLENGES_API.get;
export const listChallenges = CHALLENGES_API.list;
export const staffChallengeTransition = CHALLENGES_API.update;
export const candidateChallengeTransition = CHALLENGES_API.update;

const SURVEYS_API = mockResourcefulAPI('surveys', SURVEYS);
export const listGlobalSurveys = SURVEYS_API.list;
export const listSurveys = SURVEYS_API.list;

const EXTERNAL_CANDIDATE_SERVICES_API = mockResourcefulAPI('externalCandidateServices', EXTERNAL_CANDIDATE_SERVICES);
export const fetchExternalCandidateServices = EXTERNAL_CANDIDATE_SERVICES_API.list;
export const createExternalCandidateService = EXTERNAL_CANDIDATE_SERVICES_API.create;
export const candidateApplyToExternalService = EXTERNAL_CANDIDATE_SERVICES_API.create;
export const transitionExternalCandidateServiceByCandidate = EXTERNAL_CANDIDATE_SERVICES_API.create;

const SUPPORTIVE_SERVICES_API = mockResourcefulAPI('services', SERVICES);
export const listSupportiveServices = SUPPORTIVE_SERVICES_API.list;
export const listCandidateServicesForCandidate = SUPPORTIVE_SERVICES_API.list;
export const fetchSupportiveService = SUPPORTIVE_SERVICES_API.get;
export const fetchABService = SUPPORTIVE_SERVICES_API.get;
export const listABSupportiveServices = SUPPORTIVE_SERVICES_API.list;
export const listABSupportiveService = SUPPORTIVE_SERVICES_API.get;
export const fetchExternalCandidateServicesServices = SUPPORTIVE_SERVICES_API.get;

const CANDIDATE_SUPPORTIVE_SERVICES_API = mockResourcefulAPI('candidateServices', CANDIDATE_SERVICES);
export const transitionCandidateServiceByCandidate = CANDIDATE_SUPPORTIVE_SERVICES_API.update;
export const transitionCandidateServiceByStaff = CANDIDATE_SUPPORTIVE_SERVICES_API.update;

const PROGRAMS_API = mockResourcefulAPI('programs', PROGRAMS);
export const listPrograms = PROGRAMS_API.list;
export const fetchProgram = PROGRAMS_API.get;
export const createProgram = PROGRAMS_API.create;
export const draftProgram = PROGRAMS_API.update;
export const updateProgram = PROGRAMS_API.update;
export const publishProgram = PROGRAMS_API.update;
export const closeProgram = PROGRAMS_API.update;
export const cancelProgram = PROGRAMS_API.update;
export const activateProgram = PROGRAMS_API.update;

const INVITATIONS_API = mockResourcefulAPI('invitations', INVITATIONS);
export const listInvitations = INVITATIONS_API.list;
// export const listStaffInvitations = INVITATIONS_API.list;
export const createInvitation = INVITATIONS_API.create;
export const acceptInvitation = INVITATIONS_API.update;
export const resendInvitation = INVITATIONS_API.update;
export const bulkCreateInvitations = INVITATIONS_API.create;
export const fetchInvitation = INVITATIONS_API.get;

const EVENTS_API = mockResourcefulAPI('events', EVENTS);
export const createEvent = EVENTS_API.create;
export const updateEvent = EVENTS_API.update;
export const listEvents = EVENTS_API.list;
export const fetchEvent = EVENTS_API.get;
export const draftEvent = EVENTS_API.update;
export const publishEvent = EVENTS_API.update;
export const closeEvent = EVENTS_API.update;
export const cancelEvent = EVENTS_API.update;
export const activateEvent = EVENTS_API.update;

const STAFF_API = mockResourcefulAPI('staff', []);
export const createStaffMember = STAFF_API.create;
export const fetchStaffMember = STAFF_API.get;
export const updateStaffMember = STAFF_API.update;
export const deleteStaffMember = STAFF_API.destroy;
export const listStaff = STAFF_API.list;
export const listPeerStaff = STAFF_API.list;

const MESSAGES_API = mockResourcefulAPI('messages', []);
export const createMessage = MESSAGES_API.create;
export const listMessages = MESSAGES_API.list;
export const fetchMessage = MESSAGES_API.get;
export const updateMessage = MESSAGES_API.update;

const NOTES_API = mockResourcefulAPI('notes', []);
export const createNote = NOTES_API.create;
export const listNotes = NOTES_API.list;
export const fetchNote = NOTES_API.get;
export const updateNote = NOTES_API.update;

const NOTIFICATIONS_API = mockResourcefulAPI('notifications', NOTIFICATIONS);
export const listNotifications = NOTIFICATIONS_API.list;
export const fetchNotification = NOTIFICATIONS_API.get;
export const dismissNotification = NOTIFICATIONS_API.update;
export const setNotificationAsViewed = NOTIFICATIONS_API.update;

const PATHWAYS_API = mockResourcefulAPI('pathways', PATHWAYS);
export const listPathways = PATHWAYS_API.list;
export const fetchPathway = PATHWAYS_API.get;

const PATHWAY_INSTANCES_API = mockResourcefulAPI('pathwayInstances', PATHWAYINSTANCES);
export const listPathwayInstances = PATHWAY_INSTANCES_API.list;
export const fetchPathwayInstance = PATHWAY_INSTANCES_API.get;
export const createPathwayInstance = PATHWAY_INSTANCES_API.create;
export const updatePathwayInstance = PATHWAY_INSTANCES_API.update;
export const draftPathwayInstance = PATHWAY_INSTANCES_API.update;
export const publishPathwayInstance = PATHWAY_INSTANCES_API.update;
export const closePathwayInstance = PATHWAY_INSTANCES_API.update;
export const cancelPathwayInstance = PATHWAY_INSTANCES_API.update;
export const transitionPathwayStepStaffState = PATHWAY_INSTANCES_API.update;
export const transitionPathwayStepCandidateState = PATHWAY_INSTANCES_API.update;
export const updatePathwayInstanceStep = async () => ({});
export const bulkUpdatePathwayInstanceStepsStaffStates = async () => ({});
export const bulkTransitionPathwayInstanceStepsStaffStates = async () => ({});
export const inviteRolesToOrganization = async () => ({});
export const createPublicLink = async () => ({});
export const fetchInviteLinkForPreview = async () => ({});
export const fetchPublicLinkForPreview = async () => ({});
export const usePublicLink = async () => ({});
export const useInviteLink = async () => ({});
export const listSystemEnums = async () => ({ data: [] });
