import { connect } from 'react-redux';
import ProgramApplicantTileView from './program_applicant_tile_view';
import { getCurrentUser, getApiError } from '../../../reducer';
import { clearApiError } from '../../../errors/actions';
import ACTIONS, { transitionCandidateProgramByStaff } from '../../../solve/actions';

const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  return {
    user,
    statusUpdateError: getApiError(state, ACTIONS.TRANSITION_CANDIDATE_PROGRAM_S.failure),
  };
};

const mapDispatchToProps = { transitionCandidateProgramByStaff, clearApiError };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  updateStaffStatus: (status) => dispatchProps.transitionCandidateProgramByStaff(stateProps.user, ownProps.candidateProgram.get('id'), status.substring(2)),
});

const ProgramApplicantTileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProgramApplicantTileView);

export default ProgramApplicantTileContainer;
