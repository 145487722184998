import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router-dom';
import { MuiFormTheme, MuiThemeProvider } from '../../../common/form';
import ApplyToServiceStyles from './apply_to_service_styles';
import ChallengesForm from './challenges_form';
import PrivacyAgreementForm from './privacy_agreement_form';
import QuestionnaireCompleted from './questionnaire_completed';
import AdditionalQuestionsForm from './additional_questions_form';
import AdditionalQuestionsCompleted from './additional_questions_completed';
import Questionnaire from '../questionnaire';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import Spinner from '../../../common/components/spinner';
import CandidateServiceModel from '../../../solve/models/candidate_service_model';

const propTypes = {
  service: PropTypes.instanceOf(Map),
  fetchSupportiveService: PropTypes.func.isRequired,
  fetchABService: PropTypes.func.isRequired,
  createExternalCandidateService: PropTypes.func.isRequired,
  listExternalCandidateServices: PropTypes.func.isRequired,
  serviceId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map).isRequired,
  externalCandidateService: PropTypes.oneOfType([PropTypes.instanceOf(CandidateServiceModel), PropTypes.instanceOf(Map)]),
};

const defaultProps = {
  service: undefined,
  externalCandidateService: undefined,
};

const ApplyToServiceView = ({
  service,
  fetchABService,
  fetchSupportiveService,
  serviceId,
  currentUser,
  candidate,
  externalCandidateService,
  createExternalCandidateService,
  listExternalCandidateServices,
}) => {
  const abService = service && !!service.get('attributeTags');
  const fetchService = abService ? fetchABService : fetchSupportiveService;
  const resolvedService = useFetchOnce(currentUser, serviceId, fetchService, service && service.get('surveys'));
  useFetchOnce(currentUser, currentUser.get('candidateId'), listExternalCandidateServices);
  const resolvedCandidateService = useFetchOnce(currentUser, serviceId, createExternalCandidateService, externalCandidateService);
  const [page, setPage] = useState(1);

  const questionnaireStatus = candidate?.get('challengesCompletion');
  const shouldSkipQuestionnaire = questionnaireStatus === 'complete';

  if (!resolvedService || !resolvedCandidateService) {
    return <Spinner />;
  }
  if (!service) {
    // Redirect up one level to be handled there.
    return <Redirect to="/candidate/resources" />;
  }
  if (!externalCandidateService) {
    return 'Sorry, we are temporarily unable to process your request.';
  }

  const handlePrivacyAgreementFormSubmit = () => {
    const newPage = questionnaireStatus === 'complete' ? 4 : 3;

    setPage(newPage);
  };

  return (
    <ApplyToServiceStyles>
      <div className="content-area">
        <div className="wrapper">
          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {page === 1 && <ChallengesForm onSubmit={() => setPage(page + 1)} />}
              {page === 2 && <PrivacyAgreementForm previousPage={() => setPage(page - 1)} onSubmit={handlePrivacyAgreementFormSubmit} />}
              {page === 3 && <Questionnaire onComplete={() => setPage(page + 1)} />}
              {page === 4 && (!service || !service.get('surveys') || !(service.get('surveys').size > 0)) ? (
                <AdditionalQuestionsCompleted skippedQuestionnaire={shouldSkipQuestionnaire} service={service} />
              ) : (
                page === 4 && <QuestionnaireCompleted skippedQuestionnaire={shouldSkipQuestionnaire} onSubmit={() => setPage(page + 1)} />
              )}
              {page === 5 && <AdditionalQuestionsForm onComplete={() => setPage(page + 1)} service={service} />}
              {page === 6 && <AdditionalQuestionsCompleted service={service} candidateService={externalCandidateService} />}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </ApplyToServiceStyles>
  );
};

ApplyToServiceView.propTypes = propTypes;
ApplyToServiceView.defaultProps = defaultProps;

export default ApplyToServiceView;
