import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import JobIndeedView from './job_indeed_view';
import { getCurrentUser, getCandidateApplicationForJobPosting, getIndeedJobPosting, getSystemEnumList } from '../../../reducer';
import { fetchIndeedJobPosting } from '../../../solve/actions';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

// mapStateToProps(state, { prop })
const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const job = getIndeedJobPosting(state, id);
  const opportunityTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'OpportunityType'), job?.get('opportunityTypes')));

  return {
    id,
    job,
    user: getCurrentUser(state),
    candidateApplication: getCandidateApplicationForJobPosting(state, id),
    opportunityTypes,
  };
};

const mapDispatchToProps = {
  fetchIndeedJobPosting,
};

const JobIndeedContainer = connect(mapStateToProps, mapDispatchToProps)(JobIndeedView);

export default JobIndeedContainer;
