import styled from 'styled-components';

const WelcomeStyles = styled.div`
  .col-xs-6 {
    margin-bottom: 2em;
  }
`;

export const Title = styled.h2`
  margin-bottom: 0;
  font-weight: 400;
  text-transform: uppercase;
`;

export const Subtitle = styled.h1`
  margin-top: 0;
  font-weight: bold;
`;

export const WeightedSubTitle = styled.b`
  font-weight: 300;
  text-transform: uppercase;
`;

export const Tile = styled.div`
  padding: 3em;
  background-color: ${({ theme, selected }) => (selected ? theme.color.secondaryAction : theme.color.tile)};
  transition: background-color 0.5s;
  margin-bottom: 15px;
  text-align: left;

  > h3 {
    margin-top: 0;
    font-weight: 400;
    letter-spacing: 0.01em;
    text-transform: none;
  }

  img {
    width: 2em;
  }

  .col-xs-6 & {
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    height: 0;

    .square {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
    }

    img {
      width: 4em;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .col-xs-10 {
    > p {
      margin-top: 0;
      margin-bottom: 1.5em;
      color: ${({ theme }) => theme.color.primary};
      opacity: 0.85;
    }
  }

  .row-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px 0px;
  }
`;

export const NextLink = styled.a`
  text-decoration: underline;
  font-size: 14px;
  text-align: center;
`;

export const ActionRow = styled.div`
  text-align: center;

  button {
    background: none !important;
    border: none;
    padding: 0 !important;
    font-size: 16pt;
    text-decoration: underline;
    text-align: center;
    text-transform: none;
  }

  button:hover {
    color: black;
  }
`;

export default WelcomeStyles;
