import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import moment from 'moment';
import { OrderedSet, Map } from 'immutable';
import { startCase } from 'lodash';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import {
  Body,
  MainColumn,
  Pill,
  Title,
  Organization,
  MetaCategory,
  MetaGroup,
  MetaSubGroup,
  MetaLabel,
  MetaValue,
  MetaIcon,
  SideBar,
  MetaHeading,
} from '../../../common/styles/detail';
import DetailsStyles from './details_styles';
import JobPostingModel from '../../../solve/models/job_posting_model';

const propTypes = {
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]).isRequired,
  opportunityTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {};

const formatPay = (type, amount) => {
  if (!numeral(amount).value()) {
    return 'Not Provided';
  }

  const formattedAmount = numeral(amount).format('$0,0');
  switch (type) {
    case 'hourly':
      return `${formattedAmount}/hour`;
    case 'salary':
      return `${formattedAmount}/year`;
    default:
      return `${formattedAmount} ${type}`;
  }
};

const DetailsView = ({ job, opportunityTypes }) => {
  const benefits =
    job &&
    job.get('opportunityTypes') &&
    job.get('opportunityTypes').filter((value) => value === 'child-care' || value === 'health-care' || value === 'transportation');

  return (
    <DetailsStyles>
      {job && (
        <React.Fragment>
          <Pill>Job</Pill>
          <Title>{job.get('title')}</Title>
          <Organization>{job.getIn(['employer', 'name'])}</Organization>
          <Body>
            <MainColumn>
              <MetaCategory>
                <MetaLabel>Description</MetaLabel>
                <MetaValue>
                  <div dangerouslySetInnerHTML={{ __html: job.get('description') }} />
                </MetaValue>
              </MetaCategory>

              {job.get('employerVisibilityType') === 'show-employer' && job.getIn(['employer', 'name']) && job.getIn(['employer', 'description']) && (
                <MetaCategory>
                  <MetaLabel>{`About ${job.getIn(['employer', 'name'])}`}</MetaLabel>
                  <MetaValue>{job.getIn(['employer', 'description'])}</MetaValue>
                </MetaCategory>
              )}

              {job.get('jobType') && (
                <MetaCategory>
                  <MetaLabel>Job Type</MetaLabel>
                  <MetaValue>{startCase(job.get('jobType'))}</MetaValue>
                </MetaCategory>
              )}

              {job.get('industryType') && (
                <MetaCategory>
                  <MetaLabel>Industry Type</MetaLabel>
                  <MetaValue>{startCase(job.get('industryType'))}</MetaValue>
                </MetaCategory>
              )}

              {benefits && benefits.size > 0 && (
                <MetaCategory>
                  <MetaLabel>Benefits</MetaLabel>
                  <MetaValue>
                    {benefits.map((benefit) => (
                      <React.Fragment key={benefit}>
                        {startCase(benefit)}
                        <br />
                      </React.Fragment>
                    ))}
                  </MetaValue>
                </MetaCategory>
              )}
            </MainColumn>
            <SideBar>
              {job.get('payAmount') && (
                <MetaGroup>
                  <MetaIcon>
                    <AttachMoneyIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Salary:</MetaHeading>
                    {job.get('payType') === 'no-share' ? 'Not Specified' : formatPay(job.get('payType'), job.get('payAmount'))}
                  </MetaValue>
                </MetaGroup>
              )}
              {(job.get('startDate') || job.get('endDate')) && (
                <MetaGroup>
                  <MetaIcon>
                    <CalendarTodayIcon />
                  </MetaIcon>
                  <MetaSubGroup>
                    <MetaValue>
                      <MetaHeading>Date:</MetaHeading>
                    </MetaValue>
                    {job.get('startDate') && <MetaValue>{`${moment(job.get('startDate')).format('dddd, LL')}`}</MetaValue>}
                    {job.get('endDate') && <MetaValue>{`${moment(job.get('endDate')).format('dddd, LL')}`}</MetaValue>}
                  </MetaSubGroup>
                </MetaGroup>
              )}
              {job.getIn(['address', 'address1']) ? (
                <MetaGroup>
                  <MetaIcon>
                    <LocationOnOutlinedIcon />
                  </MetaIcon>
                  <MetaSubGroup>
                    <MetaValue>
                      <MetaHeading>Address:</MetaHeading>
                    </MetaValue>
                    <MetaValue>{job.getIn(['address', 'address1'])}</MetaValue>
                    {job.getIn(['address', 'address2']) ? <MetaValue>{job.getIn(['address', 'address2'])}</MetaValue> : ''}
                    <MetaValue>{`${job.getIn(['address', 'city'])}, ${job.getIn(['address', 'state'])} ${job.getIn(['address', 'zip'])}`}</MetaValue>
                  </MetaSubGroup>
                </MetaGroup>
              ) : (
                <MetaGroup>
                  <MetaIcon>
                    <LocationOnOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Address:</MetaHeading>
                    {job.getIn(['address', 'zip'])}
                  </MetaValue>
                </MetaGroup>
              )}
              {opportunityTypes && (
                <MetaGroup>
                  <MetaIcon>
                    <LabelOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Serving:</MetaHeading>
                    {opportunityTypes.map((item) => item[1]).join(', ')}
                  </MetaValue>
                </MetaGroup>
              )}
            </SideBar>
          </Body>
        </React.Fragment>
      )}
    </DetailsStyles>
  );
};

DetailsView.propTypes = propTypes;
DetailsView.defaultProps = defaultProps;

export default DetailsView;
