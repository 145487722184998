import validator from 'validator';
import { reduxForm } from '../../../common/form';
import ForgotPasswordView from './forgot_password_view';

const REQUIRED = 'Required';
const INVALID_EMAIL_FORMAT = 'Invalid email format';
const INVALID_PHONE_FORMAT = 'Phone number must be 10 digits';
const INVALID_PHONE = 'Phone is invalid';
const MINIMUM_LENGTH = 'Password must be at least 4 characters long';
const NUMBERS = /^\d+$/;
const PHONE_REGEX = /^([2-9][0-9]{2}){2}[0-9]{4}$/;

const validate = (values, _props) => {
  const errors = {};

  if (!values.get('email')) {
    errors.email = REQUIRED;
  } else if (values.get('email').match(NUMBERS)) {
    if (values.get('email').length !== 10) {
      errors.email = INVALID_PHONE_FORMAT;
    } else if (!values.get('email').match(PHONE_REGEX)) {
      errors.email = INVALID_PHONE;
    }
  } else if (!validator.isEmail(values.get('email'))) {
    errors.email = INVALID_EMAIL_FORMAT;
  }

  if (!values.get('password')) {
    errors.password = REQUIRED;
  } else if (values.get('password').length < 4) {
    errors.password = MINIMUM_LENGTH;
  }

  return errors;
};

const ForgotPasswordForm = reduxForm({ form: 'forgotPassword', validate })(ForgotPasswordView);

export default ForgotPasswordForm;
