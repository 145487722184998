import styled from 'styled-components';
import theme from '../../../../common/theme';

const PersonalInfoFormStyles = styled.div`
  max-width: 900px;

  .btn {
    appearance: none;
    border: 0;
    border-radius: 0.5em;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.1em;
    line-height: 1;
    padding: 0.75rem 1.5rem;
    text-align: center;
    text-decoration: none;
    transition: background-color 150ms ease-in;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
    background-color: ${theme.color.green};
    color: #fff;
  }

  .fields-2 {
    margin-bottom: ${theme.padding.small};

    @media ${theme.query.tabletMin} {
      display: flex;
      flex-direction: row;
    }

    > * {
      @media ${theme.query.tabletMin} {
        width: 50%;

        &:first-of-type {
          margin-right: ${theme.padding.xsmall} !important;
        }

        &:last-of-type {
          margin-left: ${theme.padding.xsmall} !important;
        }
      }
    }
  }
`;

export default PersonalInfoFormStyles;
