import { connect } from 'react-redux';
import GenderReportView from './gender_report_view';
import { listCandidatePathwaysForPathwayInstance } from '../../../../../solve/actions';
import { getCandidatePathwaysForPathwayInstance } from '../../../../../reducer';
import { generateGenderReportDetails } from '../../../../../util/generate_pathway_reports';

const mapStateToProps = (state, { pathwayInstance }) => {
  const candidatePathways = getCandidatePathwaysForPathwayInstance(state, pathwayInstance.get('id'));
  return { reportDetails: generateGenderReportDetails(candidatePathways) };
};

const mapDispatchToProps = {
  listCandidatePathwaysForPathwayInstance,
};

const GenderReportContainer = connect(mapStateToProps, mapDispatchToProps)(GenderReportView);

export default GenderReportContainer;
