import { Map, fromJS } from 'immutable';

const DEFAULT_STATE = Map();

const ChildOrganizationReducer = (state = DEFAULT_STATE, action) => {
  if (action.type === 'Solve:ListChildOrganizations:SUCCESS') {
    const { parentOrganizationId } = action;
    const childOrganizations = Object.values(action.response.entities.organizations);
    return state.set(parentOrganizationId, fromJS(childOrganizations));
  }
  return state;
};

export const getChildOrganizations = (state, parentOrganizationId) => state.get(parentOrganizationId, fromJS([])).toOrderedSet();

export default ChildOrganizationReducer;
