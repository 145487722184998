import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const WithStaffTrackers = ({ children }) => {
  if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_ENABLE_TRACKERS === 'yes') {
    return (
      <React.Fragment>
        <Helmet>
          {/* Start of HubSpot Embed Code */}
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/5409255.js"></script>
          {/* End of HubSpot Embed Code */}
        </Helmet>
        {children}
      </React.Fragment>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};

WithStaffTrackers.propTypes = {
  children: PropTypes.element.isRequired,
};

export default WithStaffTrackers;
