import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import ProfileFormView from './profile_form_view';
import { getCurrentUser, getSystemEnumList } from '../../../reducer';
import { createStaffMember } from '../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  return {
    user,
    genderTypes: getSystemEnumList(state, 'GenderType').map((item) => [item.get('slug'), item.get('label')]),
    nonProfitStaffResponsibilities: getSystemEnumList(state, 'StaffResponsibilityType'),
    employerStaffResponsibilities: getSystemEnumList(state, 'EmployerResponsibilityType'),
    initialValues: fromJS({ userAttributes: { email: user.get('email'), phone: user.get('phone') } }),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  createStaffMember,
};

const EMPTY_MAP = fromJS({});
const mapValuesToList = (x = EMPTY_MAP) => x.toList();
const mapKeysToList = (x = EMPTY_MAP) => x.keySeq();

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: (values) => {
    const cleanValues = values
      .update('userAttributes', (attrs) => attrs.update('alternateEmails', mapValuesToList))
      .update('staffResponsibilityTypes', mapKeysToList)
      .set('organizationId', ownProps.orgId);
    return dispatchProps.createStaffMember(stateProps.user, cleanValues);
  },
});

const ProfileFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ProfileFormView);

export default ProfileFormContainer;
