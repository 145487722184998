import React from 'react';
import { Map, OrderedSet } from 'immutable';
import PropTypes from 'prop-types';

const propTypes = {
  reportColumns: PropTypes.instanceOf(OrderedSet).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const getPercentageValue = (statNum, totalNum) => Math.round((statNum / totalNum) * 100);

const TotalsSummaryView = ({ reportColumns, reportDetails }) => (
  <tr key="summary">
    <th>
      <b>TOTALS</b>
    </th>
    {reportColumns.map(([slug, _]) => (
      <td key={slug}>
        <b>
          {reportDetails.get(slug) || 0}{' '}
          {reportDetails.get(slug) > 0 && <span>({getPercentageValue(reportDetails.get(slug), reportDetails.get('total'))}%)</span>}
        </b>
      </td>
    ))}
  </tr>
);

TotalsSummaryView.propTypes = propTypes;
TotalsSummaryView.defaultProps = defaultProps;

export default TotalsSummaryView;
