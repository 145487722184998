import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../common/theme';

const ResumePage = ({ children, id, size }) => (
  <Page size={size} id={id}>
    <Container>{children}</Container>
  </Page>
);

ResumePage.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  size: PropTypes.string,
};

ResumePage.defaultProps = {
  children: undefined,
  id: undefined,
  size: undefined,
};

const Page = styled.div`
  position: relative;
  font-size: 1.26459vw;

  /* @media ${theme.query.wideMin} {
    font-size: 1.01325vw;
  } */

  ${({ size }) => {
    switch (size) {
      case 'a4':
      case 'A4':
        return `
        width: 100%;
        ${''}
      `;

      case 'letter':
      default:
        return `
        width: 100%;
        ${''}
      `;
    }
  }};
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 6.25%;
`;

export default ResumePage;
