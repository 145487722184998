import BaseNotificationHelper from './base_notification_helper';

class CaseNoteNotificationHelper extends BaseNotificationHelper {
  constructor(params) {
    super(params);

    ({ urlIdKey: this.urlIdKey, urlTemplate: this.urlTemplate } = params);
    this.info = this.notification.get('info');
  }

  getActivity() {
    const activity = this.template?.activity;
    if (activity) {
      return activity.replace('<candidateName>', this.getCaseNoteCandidate());
    }
    return '';
  }

  getDescription() {
    let description = null;

    if (this.template) {
      const candidateName = this.getCaseNoteCandidate();
      const staffName = this.getPerformedByStaff();

      description = this.template.description;
      if (description) {
        description = description.replace('<candidateName>', candidateName);
        description = description.replace('<staffName>', staffName);
        if (description.includes('<here>')) {
          const url = this.getUrl();
          description = description.concat('~<here>');
          description = description.concat(`~/${url}`);
        }
      }
    }
    return description || '';
  }

  getCaseNoteCandidate() {
    let candidateName = null;

    if (this.info) {
      const candidate = this.info.get('candidate');
      if (candidate) {
        candidateName = `${candidate.get('firstName')} ${candidate.get('lastName')}`;
      }
    }
    return candidateName || '';
  }

  getPerformedByStaff() {
    let staffName = null;

    if (this.info) {
      const staff = this.info.getIn(['performedBy', 'staff']);
      if (staff) {
        staffName = `${staff.get('firstName')} ${staff.get('lastName')}`;
      }
    }
    return staffName || '';
  }

  getUrl() {
    if (!this.urlTemplate) return '';

    return this.urlTemplate.replace('<urlNode>', this.getUrlNode()).replace('<id>', this.getUrlId());
  }

  getUrlId() {
    return (Array.isArray(this.urlIdKey) ? this.info.getIn(this.urlIdKey) : this.info.get(this.urlIdKey)) || '';
  }
}

export default CaseNoteNotificationHelper;
