const ACTIONS = {
  SHOW_FLASH: 'SESSION:SHOW_FLASH',
  DISMISS_FLASH: 'SESSION:DISMISS_FLASH',
  CLEAR_API_ERROR: 'Errors:ClearApiError',
};

// Display a new flash message
export const showFlash = (flash) => ({
  type: ACTIONS.SHOW_FLASH,
  flash,
});

// Dismiss a flash message by id
export const dismissFlash = (flashId) => ({
  type: ACTIONS.DISMISS_FLASH,
  flashId,
});

export const clearApiError = () => ({
  type: ACTIONS.CLEAR_API_ERROR,
});

export default ACTIONS;
