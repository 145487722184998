import { connect } from 'react-redux';
import AgeReportView from './age_report_view';
import { listCandidatePathwaysForPathwayInstance } from '../../../../../solve/actions';
import { getCandidatePathwaysForPathwayInstance } from '../../../../../reducer';
import { generateAgeReportDetails } from '../../../../../util/generate_pathway_reports';

const mapStateToProps = (state, { pathwayInstance }) => {
  const candidatePathways = getCandidatePathwaysForPathwayInstance(state, pathwayInstance.get('id'));
  return { reportDetails: generateAgeReportDetails(candidatePathways) };
};

const mapDispatchToProps = {
  listCandidatePathwaysForPathwayInstance,
};

const AgeReportContainer = connect(mapStateToProps, mapDispatchToProps)(AgeReportView);

export default AgeReportContainer;
