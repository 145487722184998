import * as toast from "risekit/common/components/toast";

export const genericAPIActionTypes = (namespace) => ({
  request: `${namespace}:REQUEST`,
  success: `${namespace}:SUCCESS`,
  failure: `${namespace}:FAILURE`,
});

function failAPI(response) {
  const err = new Error(response.error);
  err.response = response;
  throw err;
}

export const genericAPIActionCreator = (action, actionTypes, errorMessage, meta = {}, after = undefined) => async (dispatch) => {
  dispatch({ type: actionTypes.request, ...meta });
  try {
    const response = await action();
    if (response.error) {
      failAPI(response);
    }
    dispatch({
      type: actionTypes.success,
      response,
      ...meta,
    });
    if (after) {
      const afterResult = await after(dispatch, response);
      return afterResult;
    }
    return response;
  } catch (err) {
    if (err.response?.data?.error) {
      dispatch({
        type: actionTypes.failure,
        message: err.response.data.error,
        errors: err.response.data.errorMessages,
        ...meta,
        err: err?.toString(),
      });
      // if (process.env.NODE_ENV !== 'production') {
      toast.error({ title: 'Error', message: err.response.data.error });
      // }
    } else if (err.response) {
      dispatch({
        type: actionTypes.failure,
        message: err.response.error,
        errors: err.response.errorMessages,
        ...meta,
        err: err?.toString(),
      });
    } else {
      dispatch({
        type: actionTypes.failure,
        message: errorMessage || err.message || err,
        ...meta,
        err: err?.toString(),
      });
    }
    throw err;
  }
};
