import styled from 'styled-components';
import theme from '../../theme';

const PillStyles = styled.div`
  padding: 0.25rem ${theme.padding.small};
  border-radius: ${theme.padding.base};
  color: ${theme.color.text.light};

  ${({ color }) => {
    switch (color) {
      case 'green':
        return `
        background-color: ${theme.color.greenLight};
      `;

      case 'blue':
        return `
        background-color: ${theme.color.blue};
      `;

      case 'red':
        return `
        background-color: ${theme.color.red};
      `;

      case 'gray':
        return `
        background-color: ${theme.color.grayDark};
      `;

      case 'disabled':
        return `
        background-color: ${theme.color.grayLight};
        color: ${theme.color.text.dark};
      `;

      default:
        return `
        background-color: ${color};
      `;
    }
  }};
`;

export default PillStyles;
