import { connect } from 'react-redux';
import ProgramsView from './programs_view';
import {
  listFilteredProgramsForStaff,
  listFilteredEventsForStaff,
  listFilteredServicesForStaff,
  listFilteredABServicesForStaff,
  setStaffEPCandidatesFilters,
  setStaffEPCandidatesZipCode,
  setStaffEPCandidatesPage,
  listAllStaffPrograms,
  listAllStaffEvents,
  listAllStaffServices,
  clearProgramsAndEvents,
} from '../../../solve/actions';
import {
  getCurrentUser,
  getStaffEPABServicesLoaded,
  getStaffEPCandidatesFilters,
  getStaffEPCandidatesSearch,
  getStaffEPCandidatesZipCode,
  getStaffEPFilteredEventsLoaded,
  getStaffEPFilteredProgramsLoaded,
  getStaffEPFilteredServicesLoaded,
  getTreatment,
  getStaffEPAllProgramsLoaded,
  getStaffEPAllEventsLoaded,
  getStaffEPAllServicesLoaded,
} from '../../../reducer';

// import { SomeSelectors } from '../../reducer';
// import { SomeActions } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  eventsLoaded: getStaffEPFilteredEventsLoaded(state),
  programsLoaded: getStaffEPFilteredProgramsLoaded(state),
  servicesLoaded: getStaffEPFilteredServicesLoaded(state),
  aBServicesLoaded: getStaffEPABServicesLoaded(state),
  allStaffProgramsLoaded: getStaffEPAllProgramsLoaded(state),
  allStaffEventsLoaded: getStaffEPAllEventsLoaded(state),
  allStaffServicesLoaded: getStaffEPAllServicesLoaded(state),
  filters: getStaffEPCandidatesFilters(state),
  zipCode: getStaffEPCandidatesZipCode(state),
  search: getStaffEPCandidatesSearch(state),
  showAB: getTreatment(state, 'showAb'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listFilteredEventsForStaff,
  listFilteredProgramsForStaff,
  listFilteredServicesForStaff,
  listFilteredABServicesForStaff,
  listAllStaffPrograms,
  listAllStaffEvents,
  listAllStaffServices,
  setStaffEPCandidatesFilters,
  setStaffEPCandidatesZipCode,
  setStaffEPCandidatesPage,
  clearProgramsAndEvents,
};

const ProgramsContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramsView);

export default ProgramsContainer;
