import { connect } from 'react-redux';
import JobCandidateQuestionnaireView from './job_candidate_questionnaire_view';
import { getCurrentUser, getChallenges } from '../../../reducer';
import { listCandidateSurveys, listChallenges, updateCandidateSurvey } from '../../../solve/actions';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  challenges: getChallenges(state),
});

const mapDispatchToProps = {
  listCandidateSurveys,
  listChallenges,
  onSubmit: updateCandidateSurvey,
};

const JobCandidateQuestionnaireContainer = connect(mapStateToProps, mapDispatchToProps)(JobCandidateQuestionnaireView);

export default JobCandidateQuestionnaireContainer;
