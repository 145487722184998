import { connect } from 'react-redux';
import { Map } from 'immutable';
import InviteStaffToSolveView from './invite_staff_to_solve_view';
import { formValueSelector } from '../../../common/form';
import { getCurrentUser, getPublicLinkForOrganization } from '../../../reducer';
import { createInvitation } from '../../../solve/actions';
import { createPublicLink } from '../../../public_link/actions';

const selector = formValueSelector('inviteStaffToSolve');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })

// const hardCodedValues = Map({ invitationType: 'job-recommendation', })

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  toChannel: selector(state, 'channel'),
  publicLink: getPublicLinkForOrganization(state, getCurrentUser(state).getIn(['staffOrganization', 'id'])),
});
// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  createPublicLink,
  createInvitation,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    let updatedValues;
    if (values.get('channel') === 'email') {
      updatedValues = values.delete('toPhone').merge({ contactMethodType: 'email' });
    } else if (values.get('channel') === 'phone') {
      updatedValues = values.delete('toEmail').merge({ contactMethodType: 'phone' });
    } else {
      updatedValues = values;
    }
    const hardCodedValues = Map({
      invitationType: 'join-solve-as-staff',
      recommendedId: stateProps.currentUser.getIn(['staffOrganization', 'id']),
      referralSourceType: 'not-applicable',
    });
    return dispatchProps.createInvitation(stateProps.currentUser, updatedValues.merge(hardCodedValues));
  },
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const InviteStaffToSolveContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InviteStaffToSolveView);

export default InviteStaffToSolveContainer;
