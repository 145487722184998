import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  DateField,
  ReferenceField,
  EditButton,
  DisabledInput,
  TextInput,
  ImageField,
  Pagination,
  // eslint-disable-next-line no-unused-vars
  ImageInput,
  ReferenceInput,
  AutocompleteInput,
  Filter,
} from 'react-admin';
import BusinessIcon from '@material-ui/icons/Business';

export const Icon = BusinessIcon;

// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

const SearchFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput label="Search by Name" source="qName" alwaysOn />
    <ReferenceInput label="Parent Organization" source="parentOrganizationId" reference="organizations">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);
// :parent_organization_id, :address_id, :name, :description, :org_type,
// :org_category_type, :primary_website, :secondary_website, :phone, :ein, :logo

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} filters={<SearchFilter />} pagination={<PostPagination />}>
    <Datagrid>
      address1, :address2, :city, :state, :zip
      <TextField source="id" sortable={false} />
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="orgType" />
      <TextField source="orgCategoryType" />
      <TextField source="primaryWebsite" />
      <DateField source="createdAt" />
      <TextField source="secondaryWebsite" />
      <TextField source="phone" />
      <TextField source="ein" />
      <ImageField source="logoUrl" sortable={false} />
      <ReferenceField label="Address" source="addressId" reference="addresses" sortable={false}>
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField allowEmpty label="Parent Organization" source="parentOrganizationId" reference="organizations">
        <TextField source="name" />
      </ReferenceField>
      <EditButton basePath="/organizations" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>Address{record ? ` "${record.name}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <DisabledInput source="parentOrganizationId" />
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="orgType" />
      <ImageInput source="logo" label="New Logo" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageField source="logoUrl" title="title" />
      <ImageInput source="cobrandingLogo" label="New Cobranding Logo" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageField source="cobrandingLogoUrl" title="title" />
      <TextInput source="orgCategoryType" />
      <TextInput source="primaryWebsite" />
      <TextInput source="secondaryWebsite" />
      <TextInput source="phone" />
      <TextInput source="ein" />
      <TextInput source="industryType" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a Address" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="orgType" />
      <TextInput source="orgCategoryType" />
      <TextInput source="primaryWebsite" />
      <TextInput source="secondaryWebsite" />
      <TextInput source="phone" />
      <TextInput source="ein" />
      <ImageInput source="logo" label="New Logo" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageField source="logoUrl" title="title" />
      <ImageInput source="cobrandingLogo" label="New Cobranding Logo" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageField source="cobrandingLogoUrl" title="title" />
      <TextInput source="addressAttributes.address1" />
      <TextInput source="addressAttributes.addressAttributes2" />
      <TextInput source="addressAttributes.city" />
      <TextInput source="addressAttributes.state" />
      <TextInput source="addressAttributes.zip" />
      <ReferenceInput label="Parent Organization" source="parentOrganizationId" reference="organizations">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </AdminCreate>
);
