import React from 'react';

export function downloadFileFromBlob(blob, fileName) {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  link.setAttribute('hidden', true);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const inputRef = React.createRef();
export const onButtonClick = () => {
  // `current` points to the mounted file input element
  inputRef.current.click();
};

export const onFileUpload = (event, callbackFunction, disableUploadButtonFunction) => {
  const data = new FormData();
  data.append('file', event.target.files[0]);
  callbackFunction(data);
  disableUploadButtonFunction(true);
};
