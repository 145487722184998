import styled from 'styled-components';
import theme from '../../../../common/theme';

const AdditionalQuestionsCompletedStyles = styled.div`
  padding: 25px 50px;
  .message {
    position: relative;
    margin-top: 25px;
    padding-top: 15px;
  }
  .message:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    border-top: 1px solid #979797;
  }
  h4 {
    margin-bottom: 0px;
  }
  button {
    margin-top: 75px;
    outline: 0;
  }

  @media ${theme.query.desktopMax} {
    & {
      padding: 10px 20px;
    }
  }
`;

export default AdditionalQuestionsCompletedStyles;
