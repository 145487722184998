import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import { Link } from 'react-router-dom';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import CheckIcon from '@material-ui/icons/Check';
import StarIcon from '@material-ui/icons/Star';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '../../../common/components/button/index';
import {
  TileWrapper,
  TileStyles,
  Header,
  Pill,
  Details,
  Logo,
  Status,
  Body,
  Title,
  Organization,
  Footer,
  Services,
  ButtonGroup,
} from '../../../common/styles/tile';
import MyEventStatus from '../my_status';
import { ExpandableText } from '../../../common/components';
import StatusUpdateTile from '../../../solve/components/status_update_tile';
import UserModel from '../../../session/models/user_model';
import getListingImage from '../../../common/components/get_listing_image';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  id: PropTypes.string.isRequired,
  event: PropTypes.instanceOf(Map).isRequired,
  candidateEvent: PropTypes.shape({
    get: PropTypes.func,
    candidateState: PropTypes.string,
  }),
  createCandidateEvent: PropTypes.func.isRequired,
  deleteCandidateEvent: PropTypes.func.isRequired,
  transitionCandidateEventByCandidate: PropTypes.func.isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  targetTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {
  candidateEvent: undefined,
};

const TileView = ({
  currentUser,
  id,
  event,
  candidateEvent,
  createCandidateEvent,
  deleteCandidateEvent,
  transitionCandidateEventByCandidate,
  serviceTypes,
  targetTypes,
}) => {
  if (event === undefined) {
    return null;
  }

  const logoUrl = getListingImage(event, 'event');
  const resourceDistanceOrZip =
    event.get('distance') || event.get('distance') === 0 ? `${Math.round(parseFloat(event.get('distance')) * 100) / 100} mi` : event.getIn(['address', 'zip']);

  const candidateState = candidateEvent && candidateEvent.get('candidateState');

  const appliedToEvent = ![undefined, 'c_deleted', 'c_saved'].includes(candidateState);

  return (
    <TileWrapper>
      <StatusUpdateTile
        record={candidateEvent}
        setCandidateState={(state) => transitionCandidateEventByCandidate(currentUser, candidateEvent.get('id'), state)}
      />
      <TileStyles>
        <Logo>
          <img src={logoUrl} alt={event.get('name')} />
          <Status>{appliedToEvent && candidateEvent && <MyEventStatus candidateEvent={candidateEvent} />}</Status>
        </Logo>
        <Header>
          <Pill>Event</Pill>
          <Details>
            <span className="detail-info">
              <ScheduleIcon />
              {`${moment(event.get('eventDate')).format('ll')} ${event.get('startTime')}`}
            </span>
            <span className="detail-info">
              <LocationOnOutlinedIcon />
              {/* {event.getIn(['address', 'zip'])} */}
              {resourceDistanceOrZip}
            </span>
          </Details>
        </Header>
        <Body>
          <Title>{event.get('name')}</Title>
          <Organization>{event.getIn(['postingOrganization', 'name'])}</Organization>
          <ExpandableText>
            <div dangerouslySetInnerHTML={{ __html: event.get('description') }} />
          </ExpandableText>
        </Body>

        <Footer>
          <Services>
            {targetTypes && (
              <div className="service">
                <PersonOutlineOutlinedIcon />
                <span>
                  <span className="heading">Serving: </span>
                  <span className="body">{targetTypes.map((item) => item[1]).join(', ')}</span>
                </span>
              </div>
            )}
            {serviceTypes && (
              <div className="service">
                <LabelOutlinedIcon />
                <span>
                  <span className="heading">Services Provided: </span>
                  <span className="body">{serviceTypes.map((item) => item[1]).join(', ')}</span>
                </span>
              </div>
            )}
          </Services>
          <ButtonGroup>
            <div className="subtle-buttons">
              <Button buttonType="subtle-outline" onClick={candidateState && candidateState === 'c_saved' ? deleteCandidateEvent : createCandidateEvent}>
                {candidateState && candidateState === 'c_saved' ? (
                  <React.Fragment>
                    <CheckIcon />
                    Saved
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <StarIcon />
                    Save
                  </React.Fragment>
                )}
              </Button>
            </div>
            <Link to={`/candidate/events/${id}`}>
              <Button buttonType="secondary-outline">
                See Details
                <ChevronRightIcon />
              </Button>
            </Link>
          </ButtonGroup>
        </Footer>
      </TileStyles>
    </TileWrapper>
  );
};

TileView.propTypes = propTypes;
TileView.defaultProps = defaultProps;

export default TileView;
