import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import EventView from './event_view';
import { fetchEvent } from '../../../solve/actions';
import { getEvent, getOrganization, getCurrentUser, getCandidateEventForEvent, getSystemEnumList } from '../../../reducer';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const event = getEvent(state, id);
  const serviceTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), event?.get('serviceTypes')));
  const targetTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), event?.get('targetParticipantTypes')));

  return {
    id,
    event,
    employer: event && getOrganization(state, event.getIn(['postingOrganization', 'id'])),
    currentUser: getCurrentUser(state),
    candidateEvent: getCandidateEventForEvent(state, id),
    serviceTypes,
    targetTypes,
  };
};

const mapDispatchToProps = {
  fetchEvent,
};

const EventContainer = connect(mapStateToProps, mapDispatchToProps)(EventView);

export default EventContainer;
