import { connect } from 'react-redux';
import ParentAccountGenderReportView from './parent_account_gender_report_view';
import { listCandidatePathwaysForOrganization } from '../../../../../solve/actions';
import { getCandidatePathwaysFromOrganizationIdAndPathwayId } from '../../../../../reducer';
import { generateGenderReportDetails } from '../../../../../util/generate_pathway_reports';

const mapStateToProps = (state, { organization, pathwayId }) => {
  const candidatePathways = getCandidatePathwaysFromOrganizationIdAndPathwayId(state, organization.get('id'), pathwayId);
  return { reportDetails: generateGenderReportDetails(candidatePathways) };
};

const mapDispatchToProps = {
  listCandidatePathwaysForOrganization,
};

const ParentAccountGenderReportContainer = connect(mapStateToProps, mapDispatchToProps)(ParentAccountGenderReportView);

export default ParentAccountGenderReportContainer;
