import React from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import Button from 'common/components/button/button';
import useAuthToken from 'risekit/common/hooks/useAuthToken';
import CircularSpinner from 'risekit/common/components/spinner';

import { ModeReportPreviewStyles } from './mode-report-preview-styles';
import useGetModeReportSignedUrl from '../../hooks/useGetModeReportSignedUrl';

const ModeReportPreview = () => {
  const userAuthToken = useAuthToken();
  const { reportToken }: { reportToken: string } = useParams();

  const { isLoading, error, signedUrl } = useGetModeReportSignedUrl({ userAuthToken, reportToken  });

  const renderModeReportIframe = () => {
    if (isLoading) {
      return (
        <>
          <CircularSpinner />
        </>
      );
    }

    return (
      <div>
        <iframe className="mode-report-preview-iframe" src={signedUrl} title="Mode Report"></iframe>
      </div>
    );
  };

  if (error) {
    return <div>Opps something went wrong.</div>;
  }

  return (
    <ModeReportPreviewStyles>
      <div>
        <Link to="/staff/reporting/mode-reports">
          <Button buttonType="back" size="small">
            <KeyboardBackspaceIcon />
            <span>Go Back to Mode Reports</span>
          </Button>
        </Link>

        <h1>Mode Report</h1>

        {renderModeReportIframe()}
      </div>
    </ModeReportPreviewStyles>
  );
};

export default ModeReportPreview;
