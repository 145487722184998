import { connect } from 'react-redux';
import OrganizationPathwaySummaryView from './organization_pathway_summary_view';
import { getCandidatePathwaysFromOrganizationIdAndPathwayId } from '../../../../../reducer';
import { generateSummaryReportDetails } from '../../../../../util/generate_pathway_reports';

const mapStateToProps = (state, { organization, pathwayId }) => {
  const candidatePathways = getCandidatePathwaysFromOrganizationIdAndPathwayId(state, organization.get('id'), pathwayId);
  return { reportDetails: generateSummaryReportDetails(candidatePathways) };
};

const OrganizationPathwaySummaryContainer = connect(mapStateToProps)(OrganizationPathwaySummaryView);

export default OrganizationPathwaySummaryContainer;
