import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Iterable } from 'immutable';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import ResumeViewer from '../../../resume_viewer';
import { Spinner } from '../../../common/components';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import ResumeUpdateInfo from '../../../resume/components/resume/resume_update_info';
import DownloadResumeButton from '../../../resume_viewer/components/download_resume_button';

const ResumeViewerView = ({ currentUser, candidateId, resume, listResumes }) => {
  const fetchDone = useFetchOnce(currentUser, candidateId, listResumes, resume?.get('liveResume'));
  const candidate = resume?.get('candidate');
  const liveResume = resume?.get('liveResume');
  const isUserCandidate = currentUser?.get('candidateId');

  if (!fetchDone) {
    return <Spinner />;
  }

  if (!liveResume) {
    return (
      <React.Fragment>
        <div>This candidate has not added a resume</div>
        <br />
        <Link to="candidate/resume">
          <Button variant="contained" size="large">
            Create a new Resume
          </Button>
        </Link>
      </React.Fragment>
    );
  }

  return (
    <Grid container spacing={4}>
      {isUserCandidate && (
        <Grid item xs={12}>
          <ResumeUpdateInfo />
        </Grid>
      )}
      <Grid item xs={6}>
        <Link to="/candidate/resume">
          <Button className="action-outlined" startIcon={<EditIcon />} variant="outlined">
            Edit Resume
          </Button>
        </Link>
      </Grid>
      <Grid item xs={6} style={{ textAlign: 'right' }}>
        <DownloadResumeButton candidate={candidate} resume={liveResume} />
      </Grid>
      <Grid item xs={12}>
        <ResumeViewer candidate={candidate} liveResume={liveResume} />
      </Grid>
    </Grid>
  );
};

ResumeViewerView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateId: PropTypes.string.isRequired,
  resume: PropTypes.instanceOf(Iterable).isRequired,
  listResumes: PropTypes.func.isRequired,
};

export default ResumeViewerView;
