import React from 'react';
// import PropTypes from 'prop-types';
import { TextHeading } from '../../../common/components';
import MessageListStyles from './message_list_styles';
import MessagesListInbox from '../messages_list_inbox';
import MessagesListOutbox from '../messages_list_outbox';

const propTypes = {};

const defaultProps = {};

const MessageListView = () => (
  <MessageListStyles className="container-wrapper">
    <div className="candidate-wrapper">
      <TextHeading size="large" weight="bold" margin="none">
        Inbox
      </TextHeading>
      <MessagesListInbox />
      <TextHeading size="large" weight="bold" margin="both">
        Outbox
      </TextHeading>
      <MessagesListOutbox />
    </div>
  </MessageListStyles>
);

MessageListView.propTypes = propTypes;
MessageListView.defaultProps = defaultProps;

export default MessageListView;
