import { connect } from 'react-redux';
import ContactInfoView from './contact_info_view';
// import { SomeSelectors } from '../../../../reducer';
// import { SomeActions } from '../../actions';

// const EXAMPLE_CONTACT_INFO = new Map({
//   id: 'Contact Info Id',
//   firstName: 'John',
//   lastName: 'Miller',
//   email: 'john@miller.net',
//   cell: '2045594040',
//   gender: 'Male',
//   ethnicity: '',
//   linkedIn: 'jmiller292',
//   military: true,
// });

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = () => ({
  // prop assignments
  // contactInfo: EXAMPLE_CONTACT_INFO,
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  // onSubmit: (values) => {
  //   console.log('Contact Form Submitted', values);
  // },
  // onContactInfoDelete: (id) => {
  //   console.log(`Contact Info ${id} deleted`);
  // },
};

const ContactInfoContainer = connect(mapStateToProps, mapDispatchToProps)(ContactInfoView);

export default ContactInfoContainer;
