/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { TextHeading } from '../../../common/components';

import theme from '../../../common/theme';
import TileStyles, { Header, Content, Statuses, Status, Actions } from './listing_applicant_details_tile_styles';
import CustomApplicantStepper from '../../../jobs/application_list_item/custom_applicant_stepper';
import { programStaffStatusEnums, candidateStaffStatusEnums, eventStaffStatusEnums, pathwayStaffStatusEnums } from '../../../enums/staff_status_enums';
import CandidateProgramModel from '../../../solve/models/candidate_program_model';
import UserModel from '../../../session/models/user_model';
import StatusUpdateTile from '../../../solve/components/status_update_tile';

const { recommended, appliedOnSolve, appliedOnSite, reviewed, interviewing, offer, hired, archived } = candidateStaffStatusEnums;

const candidateSteps = [
  { label: 'Recommended / Saved', value: recommended },
  { label: 'Applied On RiseKit', value: appliedOnSolve, optional: true },
  { label: 'Applied On Company Website', value: appliedOnSite, optional: true },
  { label: 'Reviewed', value: reviewed },
  { label: 'Interviewing', value: interviewing },
  { label: 'Offer', value: offer },
];

const candidateStepMapping = {
  [recommended]: 0,
  [appliedOnSolve]: 1,
  [appliedOnSite]: 2,
  [reviewed]: 3,
  [interviewing]: 4,
  [offer]: 5,
};

const externalJobSteps = [{ label: 'Recommended / Saved', value: recommended }];

const externalJobStepMapping = {
  [recommended]: 0,
};

const { invited, applied, joined, graduated, droppedOut, declined } = programStaffStatusEnums;

const programSteps = (application) => [
  { label: 'Invited/Unassigned', value: invited },
  { label: `Applied ${application.get('appliedAt') ? moment(application.get('appliedAt')).format('Do MMM YY') : ''}`, value: applied },
  { label: `Joined ${application.get('joinedAt') ? moment(application.get('joinedAt')).format('Do MMM YY') : ''}`, value: joined },
];

const programStepMapping = {
  [invited]: 0,
  [applied]: 1,
  [joined]: 2,
};

const { initial, registered, reminded, attended } = eventStaffStatusEnums;

const eventSteps = (application) => [
  { label: `Registered ${application.get('registeredAt') ? moment(application.get('registeredAt')).format('Do MMM YY') : ''}`, value: registered },
  { label: `Reminded ${application.get('remindedAt') ? moment(application.get('remindedAt')).format('Do MMM YY') : ''}`, value: reminded },
  { label: `Attended ${application.get('confirmedAttendedAt') ? moment(application.get('confirmedAttendedAt')).format('Do MMM YY') : ''}`, value: attended },
];

const eventStepMapping = {
  [initial]: -1,
  [registered]: 0,
  [reminded]: 1,
  [attended]: 2,
};

const { pathwayApplied, inProgress, complete, transferred, droppedVoluntarily, droppedInVoluntarily } = pathwayStaffStatusEnums;

const pathwaySteps = () => [
  { label: 'Applied', value: pathwayApplied },
  { label: 'In Progress', value: inProgress },
  { label: 'Complete', value: complete },
];

const pathwayStepMapping = {
  [pathwayApplied]: 0,
  [inProgress]: 1,
  [complete]: 2,
};

const ListingApplicantDetailsTileView = ({
  application,
  currentUser,
  canEdit,
  type,
  transitionCandidateApplicationByStaff,
  transitionCandidateProgramByStaff,
  transitionCandidateEventByStaff,
  transitionCandidateServiceByStaff,
  transitionCandidatePathwayStaffState,
  statusUpdateError: { errorMessage },
  clearApiError,
}) => {
  let updateStaffStatus;
  let mapping;
  let steps;
  let applicationName;
  let organizationName = application.getIn(['helpingOrganization', 'name']) || application.getIn(['postingOrganization', 'name']);

  switch (type) {
    case 'job':
      updateStaffStatus = (status) => transitionCandidateApplicationByStaff(currentUser, application.get('id'), status.substring(2));
      mapping = candidateStepMapping;
      steps = candidateSteps;
      applicationName = application.getIn(['jobPosting', 'jobPostingTitle']);
      break;
    case 'externalJob':
      updateStaffStatus = () => {};
      mapping = externalJobStepMapping;
      steps = externalJobSteps;
      applicationName = application?.getIn(['externalJobPosting', 'title']);
      organizationName = application?.getIn(['externalJobPosting', 'company']);

      break;
    case 'event':
      updateStaffStatus = (status) => transitionCandidateEventByStaff(currentUser, application.get('id'), status.substring(2));
      mapping = eventStepMapping;
      steps = eventSteps(application);
      applicationName = application.getIn(['event', 'eventName']);
      break;
    case 'service':
      updateStaffStatus = (status) => transitionCandidateServiceByStaff(currentUser, application.get('id'), status.substring(2));
      mapping = programStepMapping;
      steps = programSteps(application);
      applicationName = application.getIn(['helper', 'helperName']);
      break;
    case 'pathway':
      updateStaffStatus = (status) => transitionCandidatePathwayStaffState(currentUser, application.get('id'), status.substring(2));
      mapping = pathwayStepMapping;
      steps = pathwaySteps(application);
      applicationName = application.getIn(['pathwayInstance', 'name']);
      break;
    case 'program':
    default:
      updateStaffStatus = (status) => transitionCandidateProgramByStaff(currentUser, application.get('id'), status.substring(2));
      mapping = programStepMapping;
      steps = programSteps(application);
      applicationName = application.getIn(['program', 'programName']);
  }

  const staffState = application.get('staffState');
  const [activeStep, setActiveStep] = useState(mapping[staffState]);
  const userDropped = staffState === droppedOut || staffState === droppedInVoluntarily;
  const userGraduated = staffState === graduated || staffState === complete;
  const userDeclined = staffState === declined || staffState === droppedVoluntarily;
  const userArchived = staffState === archived;
  const userHired = staffState === hired;
  const userTransferred = staffState === transferred;
  const fullWidth = steps.length > 1;

  return (
    <React.Fragment>
      {canEdit && <StatusUpdateTile record={application} setStaffState={(state) => updateStaffStatus(`s_${state}`)} />}
      <TileStyles>
        <Header>
          <div className="title-container">
            <div>
              <TextHeading size="medium" weight="bold">
                {applicationName}
              </TextHeading>
              <TextHeading size="small">{organizationName}</TextHeading>
            </div>
            {type === 'externalJob' && (
              <div>
                <a href="https://www.indeed.com/" rel="nofollow noreferrer" title="Job Search">
                  jobs by <img src="https://www.indeed.com/p/jobsearch.gif" style={{ border: 0, verticalAlign: 'middle' }} alt="Indeed job search" />
                </a>
              </div>
            )}
          </div>
          <Actions />
        </Header>
        <Content>
          {/* eslint-disable-next-line no-nested-ternary */}
          {!canEdit ? (
            <CustomApplicantStepper fullWidth={fullWidth} steps={steps} activeStep={activeStep} setActiveStep={() => {}} onChange={() => {}} disabled />
          ) : (
            <CustomApplicantStepper fullWidth={fullWidth} steps={steps} activeStep={activeStep} setActiveStep={setActiveStep} onChange={updateStaffStatus} />
          )}
          {type === 'job' && (
            <Statuses>
              <Status color={userArchived ? '#FF6F6F' : null} onClick={() => canEdit && updateStaffStatus(archived)} disabled={!canEdit}>
                <CloseIcon /> Archived
              </Status>
              <Status color={userHired ? '#059765' : null} onClick={() => canEdit && updateStaffStatus(hired)} disabled={!canEdit}>
                <DoneIcon /> Hired
              </Status>
            </Statuses>
          )}
          {type === 'program' ||
            (type === 'service' && (
              <React.Fragment>
                <div>
                  <FormControlLabel
                    disabled={userDropped || userGraduated || !canEdit}
                    control={<Checkbox checked={userDeclined} onChange={() => canEdit && updateStaffStatus(userDeclined ? joined : declined)} />}
                    label="Applicant Declined"
                  />
                </div>
                <Statuses>
                  <Status onClick={() => canEdit && (userDeclined ? {} : updateStaffStatus(droppedOut))} disabled={userDeclined || !canEdit}>
                    <img src={`/icons/dropped_out${userDropped ? '' : '_disabled'}.svg`} height="40" alt="dropped out" />
                    <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userDropped ? theme.color.positive : null }} />
                    <span style={{ color: userDropped ? theme.color.redLight : null }}>Dropped Out</span>
                  </Status>
                  <Status onClick={() => canEdit && (userDeclined ? {} : updateStaffStatus(graduated))} disabled={userDeclined || !canEdit}>
                    <img src={`/icons/graduated${userGraduated ? '' : '_disabled'}.svg`} height="40" alt="graduated" />
                    <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userGraduated ? theme.color.positive : null }} />
                    <span style={{ color: userGraduated ? theme.color.green : null }}>Graduated</span>
                  </Status>
                </Statuses>
              </React.Fragment>
            ))}
          {type === 'program' && (
            <React.Fragment>
              <div>
                <FormControlLabel
                  disabled={userDropped || userGraduated || !canEdit}
                  control={<Checkbox checked={userDeclined} onChange={() => canEdit && updateStaffStatus(userDeclined ? joined : declined)} />}
                  label="Applicant Declined"
                />
              </div>
              <Statuses>
                <Status onClick={() => canEdit && (userDeclined ? {} : updateStaffStatus(droppedOut))} disabled={!canEdit}>
                  <img src={`/icons/dropped_out${userDropped ? '' : '_disabled'}.svg`} height="40" alt="dropped out" />
                  <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userDropped ? theme.color.positive : null }} />
                  <span style={{ color: userDropped ? theme.color.redLight : null }}>Dropped Out</span>
                </Status>
                <Status onClick={() => canEdit && (userDeclined ? {} : updateStaffStatus(graduated))} disabled={!canEdit}>
                  <img src={`/icons/graduated${userGraduated ? '' : '_disabled'}.svg`} height="40" alt="graduated" />
                  <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userGraduated ? theme.color.positive : null }} />
                  <span style={{ color: userGraduated ? theme.color.green : null }}>Graduated</span>
                </Status>
              </Statuses>
            </React.Fragment>
          )}
          {type === 'pathway' && (
            <div className="check-group">
              <FormControlLabel
                disabled={!canEdit}
                control={<Checkbox checked={userTransferred} onChange={() => canEdit && updateStaffStatus(transferred)} />}
                label="Transferred"
              />
              <FormControlLabel
                disabled={!canEdit}
                control={<Checkbox checked={userDeclined} onChange={() => canEdit && updateStaffStatus(droppedVoluntarily)} />}
                label="Dropped Voluntarily"
              />
              <FormControlLabel
                disabled={!canEdit}
                control={<Checkbox checked={userDropped} onChange={() => canEdit && updateStaffStatus(droppedInVoluntarily)} />}
                label="Dropped Involuntarily"
              />
            </div>
          )}
        </Content>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(errorMessage)}
          message={errorMessage}
          onClose={() => clearApiError()}
          autoHideDuration={3000}
        />
      </TileStyles>
    </React.Fragment>
  );
};

ListingApplicantDetailsTileView.propTypes = {
  application: PropTypes.oneOfType([PropTypes.instanceOf(CandidateProgramModel), PropTypes.instanceOf(Iterable)]),
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  canEdit: PropTypes.bool.isRequired,
  type: PropTypes.string,
  transitionCandidateApplicationByStaff: PropTypes.func.isRequired,
  transitionCandidateProgramByStaff: PropTypes.func.isRequired,
  transitionCandidateEventByStaff: PropTypes.func.isRequired,
  transitionCandidateServiceByStaff: PropTypes.func.isRequired,
  transitionCandidatePathwayStaffState: PropTypes.func.isRequired,
  clearApiError: PropTypes.func.isRequired,
  statusUpdateError: PropTypes.shape({
    errorMessage: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
};
ListingApplicantDetailsTileView.defaultProps = {
  type: '',
  application: undefined,
};

export default ListingApplicantDetailsTileView;
