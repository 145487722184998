import styled from 'styled-components';
import theme from '../../../../common/theme';

export const CandidateStepStyles = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px) {
    .show-more {
      margin-top: 0px;
      margin-left: -7px;
    }
  }
`;

export const MilestoneCard = styled.div`
  padding: 36px;
  position: relative;
  margin: 20px;
  background-color: #f7f7f7;
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

  @media only screen and (max-width: 768px) {
    margin: 20px 0px;
  }
`;

export const Step = styled.div`
  border-radius: 5px;
  background-color: white;
  margin: 15px 0px 45px;
  padding: 15px 50px 25px;
  text-align: center;

  .step-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .step-wrapper {
    display: flex;
    flex: 1;
    padding: 5px 20px 5px 0px;
  }

  .step-date-picker > div {
    padding: 0px;
  }

  .step-item {
    min-width: 300px;
    width: auto;
    padding: 5px 0px;
  }

  .step-arrow {
    color: #979797;
    margin: 7px 10px;
  }

  .step-info {
    display: flex;
    flex: 1;
    flex-wrap: no-wrap;
    justify-content: space-between;
  }

  .status-container {
    position: relative;
    width: 300px;
    padding: 5px 0px;
    display: flex;
    flex-wrap: no-wrap;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .status-container:before {
    content: '';
    position: absolute;
    border-left: solid 1px #d7d7d7;
    left: 50px;
    top: 5px;
    height: 100%;
  }
  .status-container img {
    margin: 0px 20px 5px 0px;
  }

  @media ${theme.query.desktopMax} {
    .step-item {
      width: 100%;
    }
    .step-info {
      flex-wrap: wrap;
    }
    .status-container {
      width: auto;
    }
    .status-container:before {
      display: none;
    }
  }

  @media ${theme.query.tabletMax} {
    padding: 20px;
  }

  @media only screen and (max-width: 800px) {
    .step-wrapper {
      padding: 5px 0px;
      flex-direction: column;
      align-items: flex-start;
    }
    .step-arrow {
      display: none;
    }
    .step-date {
      flex: 1;
      min-width: 170px;
      width: 100%;
    }
    .step-date > div {
      width: 100%;
    }
  }
`;

export const Banner = styled.div`
  position: absolute;
  top: 24px;
  left: -10px;
  background-color: ${theme.color.blueDark};
  color: ${theme.color.white};
  padding: 8px 16px;
  font-weight: 600;
  box-shadow: 2px 2px 5px ${theme.color.gray};
`;
