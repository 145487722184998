import { fromJS } from 'immutable';

const EMPTY_LIST = fromJS([]);
const EMPTY_MAP = fromJS({});

const ParentPathwayReportReducer = (state = EMPTY_MAP, action) => {
  if (action.organizationId && action.response?.entities?.candidatePathways) {
    const { organizationId } = action;
    const { pathwayInstances } = action.response.entities;
    const { candidatePathways } = action.response.entities;
    const obj = {};
    obj[organizationId] = {};
    Object.values(candidatePathways).forEach((candidatePathway) => {
      const pathwayId = pathwayInstances[candidatePathway.pathwayInstance].pathway;
      if (!obj[organizationId][pathwayId]) {
        obj[organizationId][pathwayId] = [];
      }
      obj[organizationId][pathwayId].push(candidatePathway.id);
    });
    return state.merge(fromJS(obj));
  }
  return state;
};

export const getCandidatePathwayIdsFromOrganizationIdAndPathwayId = (state, organizationId, pathwayId) => {
  if (state.get(organizationId)) {
    return state.getIn([organizationId, pathwayId]);
  }
  return EMPTY_LIST;
};

export default ParentPathwayReportReducer;
