import { connect } from 'react-redux';
import { getFormValues } from 'redux-form/immutable';
import ApplyToJobView from './apply_to_job_view';
import { fetchJobPosting, createCandidateApplication, transitionCandidateApplicationByCandidate } from '../../../solve/actions';
import { getCandidateApplicationForJobPosting, getCurrentUser, getJobPosting } from '../../../reducer';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  id,
  job: getJobPosting(state, id),
  valuesCount: getFormValues('applyToJobForm')(state) && getFormValues('applyToJobForm')(state).size,
  user: getCurrentUser(state),
  candidateApplication: getCandidateApplicationForJobPosting(state, id),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  fetchJobPosting,
  createCandidateApplication,
  transitionCandidateApplicationByCandidate,
};

const ApplyToJobContainer = connect(mapStateToProps, mapDispatchToProps)(ApplyToJobView);

export default ApplyToJobContainer;
