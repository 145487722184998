import { fromJS } from 'immutable';
import { combineImmutableReducers } from '../../util';
import ACTIONS from '../actions';

const RecidivismReportReducer = (state = null, action) => {
  if (action.type === ACTIONS.FETCH_RECIDIVISM_REPORT.success) {
    return fromJS(action.response.data);
  }
  return state;
};

const ReportsReducer = combineImmutableReducers({
  recidivism: RecidivismReportReducer,
});

export default ReportsReducer;

export const getRecidivismReport = (state) => state.get('recidivism');
