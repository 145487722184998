import styled from 'styled-components';
// import theme from '../../../common/theme';

const FeatureStyles = styled.div`
  color: #fff;
  width: 100%;
  padding: 15px;
  background: ${(props) => props.theme.color.primary};
  h4 {
    margin-top: 0;
    margin-bottom: -10px;
    text-transform: uppercase;
  }
  p {
    font-size: 12px;
    line-height: 16px;
  }
`;

export default FeatureStyles;
