import styled from 'styled-components';

const TermsAndConditionStyles = styled.div`
  .terms-and-condition__wrapper {
    height: 180px;
    overflow-y: scroll;
    border-radius: 4px;
    border: solid 1px #979797;
    padding: 4px 8px;
  }
`;

export default TermsAndConditionStyles;
