import styled from 'styled-components';
import theme from '../../theme';

const TextHeadingStyles = styled.div`
  font-family: ${theme.font.sansSerif};

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
        font-size: 1.75rem;
        line-height: 1.25;
      `;

      default:
      case 'medium':
        return `
        font-size: 1.2rem;
      `;

      case 'small':
        return `
        font-size: 1rem;
      `;

      case 'xsmall':
        return `
        font-size: 0.75rem;
      `;
    }
  }};

  ${({ color }) => {
    switch (color) {
      case 'dark':
        return `
        color: ${theme.color.text.dark};
      `;

      case 'medium':
        return `
        color: ${theme.color.text.medium};
      `;

      case 'light':
        return `
        color: ${theme.color.text.light};
      `;

      default:
        return `
        color: ${color};
      `;
    }
  }};

  ${({ margin }) => {
    switch (margin) {
      case 'top':
        return `
        margin-top: 1em;
      `;

      case 'bottom':
        return `
        margin-bottom: 1em;
      `;

      case 'both':
        return `
        margin-top: 1em;
        margin-bottom: 1em;
      `;

      case 'none':
        return '';

      default:
        return `
        margin: ${margin};
      `;
    }
  }};

  ${({ weight }) => {
    switch (weight) {
      case 'light':
        return `
        font-weight: 300;
      `;

      case 'bold':
        return `
        font-weight: 700;
      `;

      default:
        return `
        font-weight: 400;
      `;
    }
  }};

  ${({ italic }) => {
    switch (italic) {
      case true:
        return `
        font-style: italic;
      `;

      default:
      case false:
        return '';
    }
  }};

  ${({ uppercase }) => {
    switch (uppercase) {
      case true:
        return `
          text-transform: uppercase;
          letter-spacing: 0.1em;
        `;

      default:
      case false:
        return '';
    }
  }};

  div {
    display: inline-block;
    margin-left: ${theme.padding.small};
  }
`;

export default TextHeadingStyles;
