import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  fromUserId: undefined,
  toUserId: undefined,
  message: undefined,
  messageSubject: undefined,
  messageBody: undefined,
  extra: undefined,
  isStarred: false,
  isRead: false,
  isDeleted: false,
  fileUrl: undefined,
  createdAt: undefined,
  fromUser: undefined,
  toUser: undefined,
};

class MessageModel extends Record(DEFAULT_ARGS) {
  static _name = 'Message';
}

export default MessageModel;
