import BaseNotificationHelper from './base_notification_helper';

class InvitationNotificationHelper extends BaseNotificationHelper {
  constructor(params) {
    super(params);

    ({ recommendedIdKey: this.idKey = 'id', recommendedTitleKey: this.titleKey = 'name', urlTemplate: this.urlTemplate } = params);
    this.info = this.notification.get('info');
    this.parent = this.info.get(params.recommendedParent);
  }

  getActivity() {
    let activity = null;

    if (this.template) {
      activity = this.template.activity;
      if (activity) {
        activity = activity.replace('<title>', this.getRecommendedTitle());
      }
    }
    return activity || '';
  }

  getDescription() {
    let description = null;

    if (this.template) {
      const candidateName = this.getToUserCandidate();
      const candidateInfo = this.getExternalCandidateInfo();
      const title = this.getRecommendedTitle();
      const postingOrgName = this.getRecommendedPostingOrgName();
      const employerName = this.getRecommendedEmployerName();
      const externalSource = this.getRecommendedExternalSource();
      const staffName = this.getFromUserStaff();
      const staffOrgName = this.getFromUserOrgName();
      const url = externalSource !== 'indeed' ? this.getUrl() : this.getUrl().replace('changeme', 'indeed-jobs');

      description = this.template.description;
      if (description) {
        description = description.replace('<title>', title);
        description = description.replace('<candidateName>', candidateName);
        description = description.replace('<candidateInfo>', candidateInfo);
        description = description.replace('<postingOrgName>', postingOrgName);
        description = description.replace('<employerName>', employerName);
        description = description.replace('<externalSource>', externalSource);
        description = description.replace('<staffName>', staffName);
        description = description.replace('<staffOrgName>', staffOrgName);
        if (description.includes('<here>')) {
          description = description.concat('~<here>');
          description = description.concat(`~/${url}`);
        }
      }
    }
    return description || '';
  }

  getUrl() {
    const id = this.getRecommendedId();
    return this.urlTemplate.replace('<urlNode>', this.getUrlNode()).replace('<id>', id);
  }

  getRecommendedId() {
    return this.parent?.get(this.idKey) || '';
  }

  getRecommendedEmployerName() {
    return this.parent?.get('employerName') || '';
  }

  getRecommendedExternalSource() {
    return this.parent?.get('externalJobSourceType') || '';
  }

  getRecommendedPostingOrgName() {
    return this.parent?.get('postingOrganizationName') || '';
  }

  getRecommendedTitle() {
    return this.parent?.get(this.titleKey) || '';
  }

  getToUserCandidate() {
    const candidate = this.info.getIn(['toUser', 'candidate']);
    if (candidate) {
      return `${candidate.get('firstName')} ${candidate.get('lastName')}`;
    }
    return '';
  }

  getExternalCandidateInfo() {
    const contactInfo = this.info.getIn(['contactInfo']);
    if (contactInfo) {
      if (contactInfo.get('firstName') || contactInfo.get('lastName')) {
        return `${contactInfo.get('firstName')} ${contactInfo.get('lastName')}`;
      }
      if (contactInfo.get('toEmail')) {
        return contactInfo.get('toEmail');
      }
      if (contactInfo.get('toPhone')) {
        return contactInfo.get('toPhone');
      }
    }
    return '';
  }

  getFromUserStaff() {
    const staff = this.info.getIn(['fromUser', 'staff']);
    if (staff) {
      return `${staff.get('firstName')} ${staff.get('lastName')}`;
    }
    return '';
  }

  getFromUserOrgName() {
    return this.info.getIn(['fromUser', 'staff', 'organization', 'name']) || '';
  }

  getPerformedByCandidate() {
    const candidate = this.info.getIn(['performedBy', 'candidate']);
    if (candidate) {
      return `${candidate.get('firstName')} ${candidate.get('lastName')}`;
    }
    return '';
  }

  getPerformedByStaff() {
    const staff = this.info.getIn(['performedBy', 'staff']);
    if (staff) {
      return `${staff.get('firstName')} ${staff.get('lastName')}`;
    }
    return '';
  }

  getPerformedByOrgName() {
    return this.info.getIn(['performedBy', 'staff', 'organization', 'name']) || '';
  }
}

export default InvitationNotificationHelper;
