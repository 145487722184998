import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { onButtonClick, inputRef, onFileUpload } from '../../../util/file_operations';
import ImmediateUploadButtonStyles from './immediate_upload_button_styles';

const ImmediateUploadButtonView = ({ onUpload, buttonLabel, ...styles }) => {
  const [disabledUploadButton, setDisabledUploadButton] = useState(false);
  const pass = { ...styles };
  const uploadFile = (event) => {
    onFileUpload(event, onUpload, setDisabledUploadButton);
  };

  return (
    <ImmediateUploadButtonStyles className="immediateUploadButton" style={pass}>
      <input type="file" id="file" ref={inputRef} onChange={uploadFile} style={{ display: 'none' }} />
      <Button onClick={onButtonClick} size="small" disabled={disabledUploadButton}>
        {buttonLabel}
      </Button>
    </ImmediateUploadButtonStyles>
  );
};

ImmediateUploadButtonView.propTypes = {
  onUpload: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default ImmediateUploadButtonView;
