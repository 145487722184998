import { connect } from 'react-redux';
import ReportsView from './reports_view';
import { getCurrentUser, getTreatment, getChildOrganizations } from '../../../reducer';
import {
  listChildOrganizations,
  fetchOrganizationCandidatesReport,
  fetchOrganizationCareerFairReport,
  fetchOrganizationNewCandidatesReport,
} from '../../../solve/actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  childOrganizations: getChildOrganizations(state, getCurrentUser(state).getIn(['staffOrganization', 'id'])),
  showPathways: !!getTreatment(state, 'showPathways'),
  showInmates: !!getTreatment(state, 'showInmates'),
  isRisekit: !!getTreatment(state, 'isRisekit', true),
});

const mapDispatchToProps = {
  listChildOrganizations,
  fetchOrganizationCandidatesReport,
  fetchOrganizationCareerFairReport,
  fetchOrganizationNewCandidatesReport,
};

const ReportsContainer = connect(mapStateToProps, mapDispatchToProps)(ReportsView);

export default ReportsContainer;
