import { connect } from 'react-redux';
import { getCurrentUser, getOrganization } from '../../../reducer';
import { fetchOrganization } from '../../../solve/actions';
import OrgSearchableSelectorView from './org_searchable_selector_view';

const OrgSearchableSelector = connect(
  (state, { input: { value } }) => ({
    currentUser: getCurrentUser(state),
    selectedOrg: getOrganization(state, value),
  }),
  {
    fetchOrganization,
  },
)(OrgSearchableSelectorView);

export default OrgSearchableSelector;
