import styled from 'styled-components';
import theme from '../../../common/theme';

const ResumeStyles = styled.div`
  .button-group {
    display: flex;
    justify-content: space-between;
  }
  .back-link {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: ${theme.color.grayDark};
  }
  .back-link svg {
    margin-right: 3px;
    font-size: 18px;
  }
  .upload-button label {
    padding: 20px 30px;
    cursor: pointer;
  }
  .upload .MuiButtonBase-root{
    width: 100%;
    padding: 0px;
    .MuiButton-label label {
      width: 100%;
    }
  }
  .add-icon {
    display: none;
  }
  .view-continue-buttons__container {
    display: flex;
    height: 31px;
  }
  .continue-button {
    margin-left: 12px;
  }
`;

export default ResumeStyles;
