import { Map, fromJS } from 'immutable';
import { createSelector } from 'reselect';
import { combineImmutableReducers, ResourcefulReducer } from '../../util';
import ACTIONS from '../actions';

const resourceReducer = new ResourcefulReducer('globalSurveys', Map, {
  actionTypes: [ACTIONS.LIST_GLOBAL_SURVEYS.success],
});

const BACKOFF_INTERVAL = 2400; // 2+ seconds, max w/ 7 retries at 5 minutes
const NO_BACKOFF = fromJS({});
const backoffReducer = (state = NO_BACKOFF, action) => {
  const attempt = state.get('attempt') || 0;
  const timeout = state.get('timeout');
  switch (action.type) {
    case ACTIONS.LIST_GLOBAL_SURVEYS.failure:
      return state.merge({
        attempt: attempt < 7 ? attempt + 1 : 7,
        timeout: Date.now() + BACKOFF_INTERVAL * 2 ** attempt,
      });
    case ACTIONS.LIST_GLOBAL_SURVEYS.success:
      return NO_BACKOFF;
    case ACTIONS.CHECK_LIST_GLOBAL_SURVEYS_BACKOFF:
      if (timeout && timeout < Date.now()) {
        return state.delete('timeout');
      }
      return state;
    default:
      return state;
  }
};

const globalSurveyReducer = combineImmutableReducers({
  resource: resourceReducer,
  backoff: backoffReducer,
});

export const getByType = createSelector(
  (state) => resourceReducer.selectors.getList(state.get('resource')),
  (_, type) => type,
  (list, type) => list.filter((x) => x.get('surveyType') === type),
);

const HOURS = 60 * 60 * 1000;
const STALE_THRESHOLD = 8 * HOURS;
export const getLoaded = (state) => {
  const lastUpdated = resourceReducer.selectors.getLastUpdated(state.get('resource'));
  return lastUpdated && Date.now() < lastUpdated + STALE_THRESHOLD;
};

export const getBackingOff = (state) => state.get('backoff').get('timeout') || 0;

export const getNeed = (state) => !getLoaded(state);

export default globalSurveyReducer;
