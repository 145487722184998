import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import OrganizationInviteView from './organization_invite_view';
import { getCurrentUser, getOrganization } from '../../../reducer';
import { listOrganizations, sendOrgInvites } from '../../../solve/actions';
import { completeOnboarding } from '../../../onboarding/actions';
// import { SomeActions } from '../../actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  return {
    user,
    org: getOrganization(state, user.getIn(['staffOrganization', 'id'])),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })

const mapDispatchToProps = {
  completeOnboarding,
  listOrganizations,
  sendOrgInvites,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...dispatchProps,
  ...stateProps,
  onSkip: dispatchProps.completeOnboarding,
  onSubmit: (values) => {
    console.log('Submitting Org Invite', values);
    const invite = values.get('invite');
    if (!invite) {
      if (window.confirm('Skip inviting co-workers?')) {
        dispatchProps.completeOnboarding();
      }
      return;
    }
    const others = invite.get('other');
    const roles = invite.delete('other');
    const orgId = stateProps.user.getIn(['staffOrganization', 'id']);
    dispatchProps.sendOrgInvites(stateProps.user, orgId, fromJS({ roles, others })).then(dispatchProps.completeOnboarding);
  },
});

const OrganizationInviteContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(OrganizationInviteView);

export default OrganizationInviteContainer;
