import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../common/theme';

const ResumeText = ({ align, children, topPadding }) => (
  <Wrapper align={align} topPadding={topPadding}>
    {children}
  </Wrapper>
);

ResumeText.defaultProps = {
  align: undefined,
  children: undefined,
  topPadding: '0',
};

ResumeText.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  topPadding: PropTypes.string,
};

const Wrapper = styled.div`
  text-align: ${(props) => (props.align ? props.align : 'initial')};
  padding-top: ${(props) => props.topPadding};

  h1 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    /* font-size: 307.6925%; */
    font-size: calc(var(--base-font-size) * 3);
    font-weight: 400;
    ${''}
    color: ${theme.color.grayDark};
  }

  h2 {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    /* font-size: 138.3535%; */
    font-size: calc(var(--base-font-size) * 1.4);
    font-weight: 700;
    color: ${theme.color.grayDarker};
    margin-top: 2.5em;
    margin-bottom: 0.4em;
  }

  h3 {
    /* font-size: 138.3535%; */
    font-size: calc(var(--base-font-size) * 1.4);
    color: ${theme.color.gray};
  }

  h4 {
    display: inline-block;
    /* font-size: 138.3535%; */
    font-size: calc(var(--base-font-size) * 1.4);
    font-weight: 700;
    color: ${theme.color.green};
  }

  em {
    display: inline-block;
    /* font-size: 138.3535%; */
    font-size: calc(var(--base-font-size) * 1.4);
    color: ${theme.color.gray};
  }

  span {
    font-size: var(--base-font-size);
  }

  hr {
    border: 0;
    margin-top: 0.75em;
    margin-bottom: 1.5em;
    width: 15%;
    border-bottom: 1px solid ${theme.color.grayLight};
  }
`;

export default ResumeText;
