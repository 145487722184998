import styled from 'styled-components';
import theme from '../../../common/theme';

const OrganizationStyles = styled.div`
  h3 {
    color: ${(props) => props.theme.color.secondary};
    letter-spacing: 0;
  }
  h4 {
    margin: 0;
    margin-top: 20px;
  }
  cite {
    font-style: normal;
    text-transform: uppercase;
    color: ${theme.color.text.medium};
    font-size: 12px;
  }
  .edit-link {
    float: right;
    text-align: center;
    line-height: 10px;
    span {
      color: ${theme.color.text.dark};
      font-size: 11px;
    }
  }
  .category {
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    max-width: calc(30vw);
    h4 {
      margin: 0;
    }
  }
`;

export default OrganizationStyles;
