import React, { useEffect } from 'react';

import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import ErrorBoundary from '../../util/error_boundary';
import Home from './home';
import SignIn from '../../session/components/sign_in';
import FinishSignIn from '../../session/components/finish_sign_in';
import Dashboard from './dashboard';
import SignOut from '../../session/components/sign_out';
import MockAuth from '../../session/components/mock_auth';
import Register from '../../session/components/register';
import ForgotPassword from '../../session/components/forgot_password';
import ResetPassword from '../../session/components/reset_password';
import AuthenticatedRoute from '../../util/authenticated_route';
import ConfirmEmail from '../../session/components/confirm_email';
import Container from '../../navigation/components/container';
import Message from '../../messaging/components/message';
import MessageNew from '../../messaging/components/message_new';
import { ScrollToTop } from '../../common/components/scroll_to_top';
import PublicLinkHandler from './public_link_handler';
// import UserModel from '../../session/models/user_model';
import { setOnboardingMode } from '../../onboarding/actions';
import NotificationsSettings from './notifications_settings';

// Staff
import StaffAddEvent from '../../staff/components/add_event';
import StaffAddJob from '../../staff/components/add_job';
import StaffAddProgram from '../../staff/components/add_program';
import StaffAddPathway from '../../staff/components/add_pathway';
import StaffCandidates from '../../staff/components/candidates_list';
import StaffListingApplicantView from '../../staff/components/listing_applicant_details';
import StaffCreateProgramsEvents from '../../staff/components/create_program';
import StaffDashboard from '../../staff/components/dashboard';
import StaffInviteApplicants from '../../staff/components/invite_applicants';
import StaffJob from '../../staff/components/job';
import StaffJobDetails from '../../staff/components/job_details';
import StaffIndeedJobDetails from '../../staff/components/indeed_job_details';
import StaffJobCandidateDetails from '../../staff/components/job_candidate_details';
import StaffJobListings from '../../staff/components/job_listings';
import StaffProgramsEvents from '../../staff/components/programs';
import StaffProgram from '../../staff/components/program';
import StaffProgramParticipants from '../../staff/components/program_participants';
import StaffProgramApplicantDetails from '../../staff/components/program_applicant_details';
import StaffEvent from '../../staff/components/event';
import StaffService from '../../staff/components/service';
import StaffEventParticipants from '../../staff/components/event_participants';
import StaffEventApplicantDetails from '../../staff/components/event_applicant_details';
import StaffOrgs from '../../staff/components/organizations';
import StaffOrg from '../../staff/components/organization';
import StaffOrgForm from '../../staff/components/organization_form';
import StaffOnboarding from '../../staff/components/onboarding';
import StaffOrgInvite from '../../staff/components/organization_invite';
import StaffReporting from '../../staff/components/reports';
import StaffReportingRecidivism from '../../staff/components/reports/recidivism_reports';
// import StaffReportingCandidate from '../../staff/components/reports/candidate_reports';
// import StaffReportingDefy from '../../staff/components/reporting';
// import StaffReportingJob from '../../staff/components/reports/job_reports';
// import StaffReportingProgram from '../../staff/components/reports/program_reports';
// import StaffReportingEvent from '../../staff/components/reports/event_reports';
import StaffReportingPathway from '../../staff/components/pathway_reports';
import StaffParentAccountReportingPathway from '../../staff/components/pathway_parent_account_reports';
import StaffUpgrade from '../../staff/components/upgrade';
import StaffUpgradeCheckout from '../../staff/components/upgrade_checkout';
import StaffProfileForm from '../../staff/components/profile_form';
import StaffMessageList from '../../staff/components/message_list/message_list_view';

import StaffList from '../../staff/components/list_staff';
import StaffSettings from '../../staff/components/settings';
import StaffVerifyDomain from '../../staff/components/verify_domain';
import StaffPathways from '../../staff/components/pathways';
import StaffPathwayDetails from '../../staff/components/pathway_details';
import StaffNewNote from '../../staff/components/notes_new';
import StaffPathwayStepDetails from '../../staff/components/pathway_step_details';
import StaffPathwayCandidateDetails from '../../staff/components/pathway_candidate_details';
import StaffPathwaysInviteCandidates from '../../staff/components/pathway_details/pathway_invite_candidates';
import StaffCandidateResumeForm from '../../staff/components/candidate_resume';
import StaffEditCandidateSettings from '../../staff/components/candidate_settings';
import StaffNotifications from '../../staff/components/notifications';
import ModeReports from 'risekit/reporting/views/mode-reports/mode-reports';
import ModeReportPreview from 'risekit/reporting/views/mode-report-preview/mode-report-preview';

// Candidate
import CandidateWelcome from '../../candidate/components/welcome';
import CandidateDashboard from '../../candidate/components/dashboard';
import CandidateResume from '../../candidate/components/resume';
import CandidateResumeViewer from '../../candidate/components/resume_viewer';
import CandidateJobListings from '../../candidate/components/job_listings';
import CandidateApplyToJob from '../../candidate/components/apply_to_job';
import CandidateJob from '../../candidate/components/job';
import CandidateIndeedJob from '../../candidate/components/job_indeed';
import CandidateResourcesListings from '../../candidate/components/resources_listings';
import CandidateProgram from '../../candidate/components/program';
import CandidateApplyToProgram from '../../candidate/components/apply_to_program';
import CandidateApplyToService from '../../candidate/components/apply_to_service';
import CandidateEvent from '../../candidate/components/event';
import CandidateApplyToEvent from '../../candidate/components/apply_to_event';
import CandidateService from '../../candidate/components/service';
import CandidateQuestionnaire from '../../candidate/components/questionnaire';
import CandidateProfile from '../../candidate/components/profile';
import CandidateSettings from '../../candidate/components/settings';
import CandidateMessagesInbox from '../../candidate/components/messages_list';
import CandidateNotifications from '../../candidate/components/notifications';

import InviteUser from '../../staff/components/invite_staff_to_solve';
import SplashScreen from './splash_screen';
import PostingPreviews from './posting_previews';
import { getCurrentUser, getOnboarding, getOnboardingMode, getTreatment } from '../../reducer';
import InvitePreview from './invite_preview';
import UserModel from '../../session/models/user_model';
import { Spinner } from '../../common/components';
import CandidatePathways from '../../candidate/components/candidate_pathways';
import CandidatePathwayDetails from '../../candidate/components/pathway_details';

import WithStaffTrackers from './with_staff_trackers';
import WithZendeskSupport from './with_zendesk_support';
import WithCandidateTrackers from './with_candidate_trackers';

let mockAuthRoute = null;
if (process.env.NODE_ENV !== 'production') {
  mockAuthRoute = <Route path="/mockauth/:provider/:returnTo" component={MockAuth} />;
}

const CandidateRoutes = () => (
  <Switch>
    {/* Redirects to correct dashboard */}
    <AuthenticatedRoute path="/dashboard" component={Dashboard} />

    {/* Candidate */}
    <AuthenticatedRoute path="/settings" component={CandidateSettings} />
    <AuthenticatedRoute path="/notifications-settings" component={NotificationsSettings} />
    <AuthenticatedRoute path="/candidate/notifications" component={CandidateNotifications} />
    <AuthenticatedRoute path="/candidate/resume/view" component={CandidateResumeViewer} />
    <AuthenticatedRoute path="/candidate/resume" component={CandidateResume} />
    <AuthenticatedRoute path="/candidate/jobs/:id/apply" component={CandidateApplyToJob} />
    <AuthenticatedRoute path="/candidate/jobs/:id" component={CandidateJob} />
    <AuthenticatedRoute path="/candidate/indeed-jobs/:id" component={CandidateIndeedJob} />
    <AuthenticatedRoute path="/candidate/jobs" component={CandidateJobListings} />
    <AuthenticatedRoute path="/candidate/programs/:id/apply" component={CandidateApplyToProgram} />
    <AuthenticatedRoute path="/candidate/programs/:id" component={CandidateProgram} />
    <AuthenticatedRoute path="/candidate/services/:id/apply" component={CandidateApplyToService} />
    <AuthenticatedRoute path="/candidate/services/:id" component={CandidateService} />
    <AuthenticatedRoute path="/candidate/resources" component={CandidateResourcesListings} />
    <AuthenticatedRoute path="/candidate/events/:id/apply" component={CandidateApplyToEvent} />
    <AuthenticatedRoute path="/candidate/events/:id" component={CandidateEvent} />
    <AuthenticatedRoute path="/candidate/events/" component={CandidateResourcesListings} />
    <AuthenticatedRoute path="/candidate/questionnaire" component={CandidateQuestionnaire} onComplete={() => {}} />
    <AuthenticatedRoute path="/candidate/profile" component={CandidateProfile} />
    <AuthenticatedRoute path="/candidate/dashboard" component={CandidateDashboard} />
    <AuthenticatedRoute path="/candidate/settings" component={CandidateSettings} />
    <AuthenticatedRoute path="/candidate/messages/new" component={MessageNew} />
    <AuthenticatedRoute path="/candidate/messages/:id" component={Message} />
    <AuthenticatedRoute path="/candidate/messages" component={CandidateMessagesInbox} />
    <AuthenticatedRoute path="/candidate/pathways/:id" component={CandidatePathwayDetails} />
    <AuthenticatedRoute path="/candidate/pathways" component={CandidatePathways} />

    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
);

const StaffRoutes = () => (
  <Switch>
    {/* Redirects to correct dashboard */}
    <AuthenticatedRoute path="/dashboard" component={Dashboard} />
    {/* Staff */}
    <AuthenticatedRoute path="/settings" component={StaffSettings} />
    <AuthenticatedRoute path="/notifications-settings" component={NotificationsSettings} />
    <AuthenticatedRoute path="/staff/notifications" component={StaffNotifications} />
    <AuthenticatedRoute path="/staff/organization-profile" component={StaffOrgForm} />
    <AuthenticatedRoute path="/staff/onboarding" component={StaffOnboarding} />
    <AuthenticatedRoute path="/staff/organizations/new" component={StaffOrgForm} />
    <AuthenticatedRoute path="/staff/organizations/:id/invite" component={StaffOrgInvite} />
    <AuthenticatedRoute path="/staff/organizations/:id/edit" component={StaffOrgForm} />
    <AuthenticatedRoute path="/staff/organizations/:id" component={StaffOrg} />
    <AuthenticatedRoute path="/staff/organizations" component={StaffOrgs} />
    <AuthenticatedRoute path="/staff/dashboard" component={StaffDashboard} />
    {/* <AuthenticatedRoute path="/staff/candidates/new" component={StaffInviteApplicants} /> */}
    <AuthenticatedRoute path="/staff/candidates/:id/resume/edit" component={StaffCandidateResumeForm} />
    <AuthenticatedRoute path="/staff/candidates/:id/edit" component={StaffEditCandidateSettings} />
    <AuthenticatedRoute path="/staff/candidates/:id" component={StaffListingApplicantView} />
    <AuthenticatedRoute path="/staff/candidates" component={StaffCandidates} />
    <AuthenticatedRoute path="/staff/jobs/new" component={StaffAddJob} />
    <AuthenticatedRoute path="/staff/jobs/:id/invite" component={StaffInviteApplicants} />
    <AuthenticatedRoute path="/staff/jobs/:id/edit" component={StaffAddJob} />
    <AuthenticatedRoute path="/staff/jobs/:id/candidates/:id" component={StaffJobCandidateDetails} />
    <AuthenticatedRoute path="/staff/jobs/:id/candidates" component={StaffJob} />
    <AuthenticatedRoute path="/staff/jobs/:id" component={StaffJobDetails} />
    <AuthenticatedRoute path="/staff/indeed-jobs/:id" component={StaffIndeedJobDetails} />
    <AuthenticatedRoute path="/staff/jobs" component={StaffJobListings} />
    <AuthenticatedRoute path="/staff/events/new" component={StaffAddEvent} />
    <AuthenticatedRoute path="/staff/programs/new" component={StaffAddProgram} />
    <AuthenticatedRoute path="/staff/programs/:id/edit" component={StaffAddProgram} />
    <AuthenticatedRoute path="/staff/programs/:id/invite" component={StaffInviteApplicants} />
    <AuthenticatedRoute path="/staff/programs/:id/participants/:id" component={StaffProgramApplicantDetails} />
    <AuthenticatedRoute path="/staff/programs/:id/participants" component={StaffProgramParticipants} />
    <AuthenticatedRoute path="/staff/programs/:id" component={StaffProgram} />
    <AuthenticatedRoute path="/staff/events/:id/edit" component={StaffAddEvent} />
    <AuthenticatedRoute path="/staff/events/:id/participants/:id" component={StaffEventApplicantDetails} />
    <AuthenticatedRoute path="/staff/events/:id/invite" component={StaffInviteApplicants} />
    <AuthenticatedRoute path="/staff/events/:id/participants" component={StaffEventParticipants} />
    <AuthenticatedRoute path="/staff/events/:id" component={StaffEvent} />
    <AuthenticatedRoute path="/staff/services/:id" component={StaffService} />
    <AuthenticatedRoute path="/staff/programs-and-events/new" component={StaffCreateProgramsEvents} />
    <AuthenticatedRoute path="/staff/programs-and-events" component={StaffProgramsEvents} />
    <AuthenticatedRoute path="/staff/resources/new" component={StaffCreateProgramsEvents} />
    <AuthenticatedRoute path="/staff/resources" component={StaffProgramsEvents} />
    <AuthenticatedRoute path="/staff/manage" component={StaffList} />
    <AuthenticatedRoute path="/staff/profile" component={StaffProfileForm} />
    <AuthenticatedRoute path="/staff/reporting/recidivism" component={StaffReportingRecidivism} />
    {/* <AuthenticatedRoute path="/staff/reporting/defy" component={StaffReportingDefy} />
    <AuthenticatedRoute path="/staff/reporting/jobs" component={StaffReportingJob} />
    <AuthenticatedRoute path="/staff/reporting/programs" component={StaffReportingProgram} />
    <AuthenticatedRoute path="/staff/reporting/events" component={StaffReportingEvent} />
    <AuthenticatedRoute path="/staff/reporting/candidates" component={StaffReportingCandidate} /> */}
    <AuthenticatedRoute path="/staff/reporting/pathways/:id" component={StaffReportingPathway} />
    <AuthenticatedRoute path="/staff/reporting/pathways" component={StaffParentAccountReportingPathway} />
    <AuthenticatedRoute path="/staff/reporting/mode-reports/:reportToken" component={ModeReportPreview} />
    <AuthenticatedRoute path="/staff/reporting/mode-reports" component={ModeReports} />

    <AuthenticatedRoute path="/staff/reporting" component={StaffReporting} />
    <AuthenticatedRoute path="/staff/settings" component={StaffSettings} />
    <AuthenticatedRoute path="/staff/upgrade/checkout" component={StaffUpgradeCheckout} />
    <AuthenticatedRoute path="/staff/upgrade" component={StaffUpgrade} />
    <AuthenticatedRoute path="/staff/messages/new/:candidateId" component={MessageNew} />
    <AuthenticatedRoute path="/staff/messages/new" component={MessageNew} />
    <AuthenticatedRoute path="/staff/messages/:id" component={Message} />
    <AuthenticatedRoute path="/staff/messages" component={StaffMessageList} />
    <AuthenticatedRoute path="/staff/notes/new/:id" component={StaffNewNote} />
    <AuthenticatedRoute path="/staff/pathways/new" component={StaffAddPathway} />
    <AuthenticatedRoute path="/staff/pathways/:id/edit" component={StaffAddPathway} />
    <AuthenticatedRoute path="/staff/pathways/:pathwayInstanceId/step/:pathwayStepId" component={StaffPathwayStepDetails} />
    <AuthenticatedRoute path="/staff/pathways/:id/invite" component={StaffPathwaysInviteCandidates} />
    <AuthenticatedRoute path="/staff/pathways/:id/candidates/:id" component={StaffPathwayCandidateDetails} />
    <AuthenticatedRoute path="/staff/pathways/:id" component={StaffPathwayDetails} />
    <AuthenticatedRoute path="/staff/pathways" component={StaffPathways} />
    <AuthenticatedRoute path="/staff/invite" component={InviteUser} />
    <Route>
      <Redirect to="/" />
    </Route>
  </Switch>
);

const OnboardingRoutes = ({ to, auth, component }) => {
  const MyRoute = auth ? AuthenticatedRoute : Route;
  return (
    <Switch>
      <MyRoute path={to} component={component} authPath="/register" />
      <Route>
        <Redirect to={to} />
      </Route>
    </Switch>
  );
};
OnboardingRoutes.propTypes = {
  to: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  auth: PropTypes.bool,
};
OnboardingRoutes.defaultProps = {
  auth: false,
};

const StartOnboardingView = ({ mode, setOnboardingMode: setMode }) => {
  useEffect(() => {
    setMode(mode);
  }, [mode, setMode]);
  return <Spinner />;
};
StartOnboardingView.propTypes = {
  mode: PropTypes.string.isRequired,
  setOnboardingMode: PropTypes.func.isRequired,
};

const StartOnboarding = connect(undefined, { setOnboardingMode })(StartOnboardingView);

const InAppView = ({ onboardingMode, onboarding, user, forceValidation }) => {
  switch (onboardingMode) {
    case 'candidate':
      if (onboarding) {
        return (
          <Switch>
            <Route exact path="/staff/onboarding/nonprofit">
              <StartOnboarding mode="staff" />
            </Route>
            <Route exact path="/staff/onboarding/employer">
              <StartOnboarding mode="employer" />
            </Route>
            <Route>
              <OnboardingRoutes to="/candidate/welcome" component={CandidateWelcome} />
            </Route>
          </Switch>
        );
      }
      if (forceValidation && user.needsValidation) {
        return <Redirect to="/validation" />;
      }
      return (
        <WithCandidateTrackers>
          <CandidateRoutes />
        </WithCandidateTrackers>
      );
    case 'staff':
      if (onboarding) {
        return (
          <Switch>
            <Route exact path="/candidate/welcome">
              <StartOnboarding mode="candidate" />
            </Route>
            <Route exact path="/staff/onboarding/employer">
              <StartOnboarding mode="employer" />
            </Route>
            <Route>
              <OnboardingRoutes to="/staff/onboarding" auth component={StaffOnboarding} />
            </Route>
          </Switch>
        );
      }
      if (forceValidation && user.needsValidation) {
        return <Redirect to="/validation" />;
      }
      return (
        <WithStaffTrackers>
          <StaffRoutes />
        </WithStaffTrackers>
      );
    case 'employer':
      if (onboarding) {
        return (
          <Switch>
            <Route exact path="/candidate/welcome">
              <StartOnboarding mode="candidate" />
            </Route>
            <Route exact path="/staff/onboarding/nonprofit">
              <StartOnboarding mode="staff" />
            </Route>
            <Route>
              <OnboardingRoutes to="/staff/onboarding" auth component={StaffOnboarding} />
            </Route>
          </Switch>
        );
      }
      if (forceValidation && user.needsValidation) {
        return <Redirect to="/validation" />;
      }
      return (
        <WithStaffTrackers>
          <StaffRoutes />
        </WithStaffTrackers>
      );
    default:
      if (forceValidation && user.needsValidation) {
        return <Redirect to="/validation" />;
      }
      return (
        <Switch>
          <Route exact path="/candidate/welcome">
            <StartOnboarding mode="candidate" />
          </Route>
          <Route exact path="/staff/onboarding/nonprofit">
            <StartOnboarding mode="staff" />
          </Route>
          <Route exact path="/staff/onboarding/employer">
            <StartOnboarding mode="employer" />
          </Route>
          <Route>
            <Redirect to={onboarding ? '/register' : '/welcome'} />;
          </Route>
        </Switch>
      );
  }
};
InAppView.propTypes = {
  onboardingMode: PropTypes.string,
  onboarding: PropTypes.bool,
  user: PropTypes.instanceOf(UserModel).isRequired,
  forceValidation: PropTypes.bool,
};
InAppView.defaultProps = {
  onboardingMode: undefined,
  onboarding: false,
  forceValidation: false,
};

const mapStateToProps = (state) => ({
  onboarding: getOnboarding(state),
  onboardingMode: getOnboardingMode(state),
  user: getCurrentUser(state),
  forceValidation: !!getTreatment(state, 'forceValidation'),
});

const InApp = connect(mapStateToProps)(InAppView);

const AdminCandidateResume = connect((state) => ({ isAdmin: getCurrentUser(state)?.isAdmin }))(
  ({
    isAdmin,
    match: {
      params: { candidateId },
    },
  }) => {
    if (!isAdmin) {
      return <Redirect to="/welcome" />;
    }
    return <CandidateResume candidateId={candidateId} />;
  },
);

const RootView = () => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/welcome" component={SplashScreen} />
    <Route
      path="/terms"
      render={() => {
        window.location = 'https://www.risekit.co/terms';
      }}
    />
    <Route
      path="/privacy"
      render={() => {
        window.location = 'https://www.risekit.co/privacy';
      }}
    />
    <Route
      path="/personal_information"
      render={() => {
        window.location = 'https://www.risekit.co/personal-information';
      }}
    />
    <Route path="/signin" component={SignIn} />
    <Route path="/finish_signin" component={FinishSignIn} />
    <Route path="/register" component={Register} />
    <Route path="/signout" component={SignOut} />
    <Route path="/forgot" component={ForgotPassword} />
    <Route path="/reset_password/:token" component={ResetPassword} />
    <Route path="/ref/1/:id" component={PostingPreviews} />
    <Route path="/accept_invitation/1/:id" component={InvitePreview} />
    <AuthenticatedRoute path="/x/candidate/:candidateId/resume" component={AdminCandidateResume} />
    <AuthenticatedRoute path="/confirm_email/:token" component={ConfirmEmail} />
    <AuthenticatedRoute path="/validation" component={StaffVerifyDomain} />
    {mockAuthRoute}
    <Route>
      <ScrollToTop />
      <PublicLinkHandler />
      <Container>
        <ErrorBoundary>
          <WithZendeskSupport>
            <InApp />
          </WithZendeskSupport>
        </ErrorBoundary>
      </Container>
    </Route>
  </Switch>
);

export default RootView;
