/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import numeral from 'numeral';
import moment from 'moment';
import { startCase } from 'lodash';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import {
  Body,
  MainColumn,
  Pill,
  Title,
  Organization,
  MetaCategory,
  MetaGroup,
  MetaSubGroup,
  MetaLabel,
  MetaValue,
  MetaIcon,
  SideBar,
  MetaHeading,
} from '../../../common/styles/detail';
import DetailsStyles from './details_styles';

const propTypes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    estimatedSalary: PropTypes.string,
    gainedSkillTypes: PropTypes.string,
    distance: PropTypes.string,
    applicationMethod: PropTypes.string,
    industryType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    eventsOffered: PropTypes.arrayOf(PropTypes.string),
    supportTypes: PropTypes.arrayOf(PropTypes.string),
    cost: PropTypes.string,
    dressCode: PropTypes.string,
    get: PropTypes.func,
    getIn: PropTypes.func,
  }).isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  targetTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {};

const DetailsView = ({ event, serviceTypes, targetTypes }) => (
  <DetailsStyles>
    {event && (
      <React.Fragment>
        <Pill>Event</Pill>
        <Title>{event.get('name')}</Title>
        <Organization>{event.getIn(['postingOrganization', 'name'])}</Organization>

        <Body>
          <MainColumn>
            <MetaCategory>
              <MetaLabel>Description</MetaLabel>
              <MetaValue>
                <div dangerouslySetInnerHTML={{ __html: event.get('description') }} />
              </MetaValue>
            </MetaCategory>

            {event && event.get('careerInterestTypes').size > 0 && (
              <MetaCategory>
                <MetaLabel>Career Interest Types</MetaLabel>
                <MetaSubGroup>
                  {event &&
                    event.get('careerInterestTypes').map((offering) => (
                      <MetaValue key={offering}>
                        {startCase(offering)}
                        <br />
                      </MetaValue>
                    ))}
                </MetaSubGroup>
              </MetaCategory>
            )}
            {event && event.get('gainedCertifications').size > 0 && (
              <MetaCategory>
                <MetaLabel>Certifications</MetaLabel>
                {event.get('gainedCertifications').map((certification) => (
                  <MetaValue key={certification}>{certification}</MetaValue>
                ))}
              </MetaCategory>
            )}
          </MainColumn>

          <SideBar>
            <MetaGroup>
              <MetaIcon>
                <AttachMoneyIcon />
              </MetaIcon>
              <MetaValue>
                <MetaHeading>Cost:</MetaHeading>
                {event.get('cost') ? numeral(event.get('cost')).format('0.00') : 'Free'}
              </MetaValue>
            </MetaGroup>
            {event.get('eventDate') && (
              <MetaGroup>
                <MetaIcon>
                  <CalendarTodayIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Date:</MetaHeading>
                  {`${moment(event.get('eventDate')).format('dddd, LL') || ''}`}
                </MetaValue>
              </MetaGroup>
            )}
            {event.get('startTime') && (
              <MetaGroup>
                <MetaIcon>
                  <ScheduleIcon />
                </MetaIcon>
                <MetaSubGroup>
                  <MetaValue>
                    <MetaHeading>Time:</MetaHeading>
                    {`${event.get('startTime')} – ${event.get('endTime')}`}
                  </MetaValue>
                </MetaSubGroup>
              </MetaGroup>
            )}
            {event.getIn(['address', 'address1']) ? (
              <MetaGroup>
                <MetaIcon>
                  <LocationOnOutlinedIcon />
                </MetaIcon>
                <MetaSubGroup>
                  <MetaValue>
                    <MetaHeading>Address:</MetaHeading>
                  </MetaValue>
                  <MetaValue>{event.getIn(['address', 'address1'])}</MetaValue>
                  {event.getIn(['address', 'address2']) ? <MetaValue>{event.getIn(['address', 'address2'])}</MetaValue> : ''}
                  <MetaValue>{`${event.getIn(['address', 'city'])}, ${event.getIn(['address', 'state'])} ${event.getIn(['address', 'zip'])}`}</MetaValue>
                </MetaSubGroup>
              </MetaGroup>
            ) : (
              <MetaGroup>
                <MetaIcon>
                  <LocationOnOutlinedIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Address:</MetaHeading>
                  {event.getIn(['address', 'zip'])}
                </MetaValue>
              </MetaGroup>
            )}
            {targetTypes && (
              <MetaGroup>
                <MetaIcon>
                  <PersonOutlineOutlinedIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Serving:</MetaHeading>
                  {targetTypes.map((item) => item[1]).join(', ')}
                </MetaValue>
              </MetaGroup>
            )}
            {serviceTypes && (
              <MetaGroup>
                <MetaIcon>
                  <LabelOutlinedIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Services Provided:</MetaHeading>
                  {serviceTypes.map((item) => item[1]).join(', ')}
                </MetaValue>
              </MetaGroup>
            )}
          </SideBar>
        </Body>
      </React.Fragment>
    )}
  </DetailsStyles>
);

DetailsView.propTypes = propTypes;
DetailsView.defaultProps = defaultProps;

export default DetailsView;
