/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import numeral from 'numeral';
import moment from 'moment';
import { startCase } from 'lodash';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import {
  MainStyles,
  MainCard,
  Body,
  Status,
  MobileView,
  DesktopView,
  MainColumn,
  Logo,
  Pill,
  Title,
  Organization,
  MetaCategory,
  MetaGroup,
  MetaSubGroup,
  MetaLabel,
  MetaValue,
  MetaIcon,
  SideBar,
  MetaHeading,
} from '../../../common/styles/detail';
import Button from '../../../common/components/button/index';
import UserModel from '../../../session/models/user_model';
import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import MyStatus from '../../../events/components/my_status';
import getListingImage from '../../../common/components/get_listing_image';

const propTypes = {
  id: PropTypes.string.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    estimatedSalary: PropTypes.string,
    gainedSkillTypes: PropTypes.string,
    distance: PropTypes.string,
    applicationMethod: PropTypes.string,
    industryType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    eventsOffered: PropTypes.arrayOf(PropTypes.string),
    supportTypes: PropTypes.arrayOf(PropTypes.string),
    cost: PropTypes.string,
    dressCode: PropTypes.string,
    get: PropTypes.func,
    getIn: PropTypes.func,
  }).isRequired,
  employer: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    get: PropTypes.func,
  }).isRequired,
  // listEvents: PropTypes.func.isRequired,
  // listOrganizations: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateEvent: PropTypes.shape({
    get: PropTypes.func,
    candidateState: PropTypes.string,
  }).isRequired,
  fetchEvent: PropTypes.func.isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  targetTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {};

const EventView = ({ id, fetchEvent, candidateEvent, event, employer, currentUser, serviceTypes, targetTypes }) => {
  const resolved = useFetchOnce(currentUser, id, fetchEvent);

  // useEffect(() => {
  //   listOrganizations(currentUser);
  // }, [currentUser, listOrganizations]);

  // useEffect(() => {
  //   listEvents(currentUser);
  // }, [currentUser, listEvents]);

  const candidateState = candidateEvent && candidateEvent.get('candidateState');
  const applied = ![undefined, 'c_deleted', 'c_saved'].includes(candidateState);

  if (!event) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  const logoUrl = getListingImage(event, 'event');

  return (
    <MainStyles>
      <div className="candidate-wrapper">
        <Link to="/candidate/resources">
          <Button buttonType="back" size="small">
            <KeyboardBackspaceIcon />
            Back to all resources
          </Button>
        </Link>
      </div>
      {event && (
        <MainCard className="candidate-wrapper">
          {applied && (
            <Status>
              Change your status? <MyStatus className="status" candidateEvent={candidateEvent} />
            </Status>
          )}

          <Logo>
            <img src={logoUrl} alt="Event Logo" />
          </Logo>

          <MobileView>
            <Pill>Event</Pill>
            <Title>{event.get('name')}</Title>
            <Organization>{event.getIn(['postingOrganization', 'name'])}</Organization>
          </MobileView>

          <Body applied={applied}>
            <MainColumn>
              <DesktopView>
                <Pill>Event</Pill>
                <Title>{event.get('name')}</Title>
                <Organization>{event.getIn(['postingOrganization', 'name'])}</Organization>
              </DesktopView>

              <MetaCategory>
                <MetaLabel>Description</MetaLabel>
                <MetaValue>
                  <div dangerouslySetInnerHTML={{ __html: event.get('description') }} />
                </MetaValue>
              </MetaCategory>

              {employer && (
                <MetaCategory>
                  <MetaLabel>{`About ${employer.get('name')}`}</MetaLabel>
                  <MetaValue>{employer.get('description')}</MetaValue>
                </MetaCategory>
              )}
              {event && event.get('careerInterestTypes').size > 0 && (
                <MetaCategory>
                  <MetaLabel>Career Interest Types</MetaLabel>
                  <MetaSubGroup>
                    {event &&
                      event.get('careerInterestTypes').map((offering) => (
                        <MetaValue key={offering}>
                          {startCase(offering)}
                          <br />
                        </MetaValue>
                      ))}
                  </MetaSubGroup>
                </MetaCategory>
              )}
              {event && event.get('gainedCertifications').size > 0 && (
                <MetaCategory>
                  <MetaLabel>Certifications</MetaLabel>
                  {event.get('gainedCertifications').map((certification) => (
                    <MetaValue key={certification}>{certification}</MetaValue>
                  ))}
                </MetaCategory>
              )}
            </MainColumn>

            <SideBar>
              {!applied && (
                <Link to={`/candidate/events/${event.get('id')}/apply`}>
                  <Button buttonType="secondary" fullWidth className="button">
                    Apply to Event
                    <ChevronRightIcon />
                  </Button>
                </Link>
              )}
              <MetaGroup>
                <MetaIcon>
                  <AttachMoneyIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Cost:</MetaHeading>
                  {event.get('cost') ? numeral(event.get('cost')).format('0.00') : 'Free'}
                </MetaValue>
              </MetaGroup>
              {event.get('eventDate') && (
                <MetaGroup>
                  <MetaIcon>
                    <CalendarTodayIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Date:</MetaHeading>
                    {`${moment(event.get('eventDate')).format('dddd, LL') || ''}`}
                  </MetaValue>
                </MetaGroup>
              )}
              {event.get('startTime') && (
                <MetaGroup>
                  <MetaIcon>
                    <ScheduleIcon />
                  </MetaIcon>
                  <MetaSubGroup>
                    <MetaValue>
                      <MetaHeading>Time:</MetaHeading>
                      {`${event.get('startTime')} – ${event.get('endTime')}`}
                    </MetaValue>
                  </MetaSubGroup>
                </MetaGroup>
              )}
              {event.getIn(['address', 'address1']) ? (
                <MetaGroup>
                  <MetaIcon>
                    <LocationOnOutlinedIcon />
                  </MetaIcon>
                  <MetaSubGroup>
                    <MetaValue>
                      <MetaHeading>Address:</MetaHeading>
                    </MetaValue>
                    <MetaValue>{event.getIn(['address', 'address1'])}</MetaValue>
                    {event.getIn(['address', 'address2']) ? <MetaValue>{event.getIn(['address', 'address2'])}</MetaValue> : ''}
                    <MetaValue>{`${event.getIn(['address', 'city'])}, ${event.getIn(['address', 'state'])} ${event.getIn(['address', 'zip'])}`}</MetaValue>
                  </MetaSubGroup>
                </MetaGroup>
              ) : (
                <MetaGroup>
                  <MetaIcon>
                    <LocationOnOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Address:</MetaHeading>
                    {event.getIn(['address', 'zip'])}
                  </MetaValue>
                </MetaGroup>
              )}
              {targetTypes && (
                <MetaGroup>
                  <MetaIcon>
                    <PersonOutlineOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Serving:</MetaHeading>
                    {targetTypes.map((item) => item[1]).join(', ')}
                  </MetaValue>
                </MetaGroup>
              )}
              {serviceTypes && (
                <MetaGroup>
                  <MetaIcon>
                    <LabelOutlinedIcon />
                  </MetaIcon>
                  <MetaValue>
                    <MetaHeading>Services Provided:</MetaHeading>
                    {serviceTypes.map((item) => item[1]).join(', ')}
                  </MetaValue>
                </MetaGroup>
              )}
            </SideBar>
          </Body>
        </MainCard>
      )}
    </MainStyles>
  );
};

EventView.propTypes = propTypes;
EventView.defaultProps = defaultProps;

export default EventView;
