import { Map, List } from 'immutable';
import { combineReducers } from 'redux-immutable';
import * as ParamsContext from './params_context';
import * as GenericAPIAction from './generic_api_action';
import RR from './resourceful_reducer';

const DEFAULT_STATE = new Map();

export function combineImmutableReducers(reducers, initialState = DEFAULT_STATE) {
  // redux-immutable is lame and changed the second param type to a generator instead of the actual default...
  return combineReducers(reducers, () => initialState);
}

export const createMockListForSelect = (type) =>
  new List([
    [`${type} 1`, `${type} label 1`],
    [`${type} 2`, `${type} label 2`],
    [`${type} 3`, `${type} label 3`],
    [`${type} 4`, `${type} label 4`],
    [`${type} 5`, `${type} label 5`],
    [`${type} 6`, `${type} label 6`],
    [`${type} 7`, `${type} label 7`],
  ]);

export const createMockListForReactSelect = (type) =>
  new List([
    { label: `${type} 1`, value: `${type} label 1` },
    { label: `${type} 2`, value: `${type} label 2` },
    { label: `${type} 3`, value: `${type} label 3` },
    { label: `${type} 4`, value: `${type} label 4` },
    { label: `${type} 5`, value: `${type} label 5` },
    { label: `${type} 6`, value: `${type} label 6` },
    { label: `${type} 7`, value: `${type} label 7` },
  ]);

export const createMockMapForTest = (type) =>
  new Map([
    ['label', `${type} 1`],
    ['label', `${type} 2`],
    ['label', `${type} 3`],
    ['label', `${type} 4`],
    ['label', `${type} 5`],
    ['label', `${type} 6`],
    ['label', `${type} 7`],
  ]);

// Simple little helper that wraps setTimeout in a Promise
export const sleep = (duration, ...args) => new Promise((resolve) => setTimeout(() => resolve(...args), duration));

export const { withParams, exportingParams } = ParamsContext;

export const ResourcefulReducer = RR;

export const { genericAPIActionTypes, genericAPIActionCreator } = GenericAPIAction;
