import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import ListStaffStyles from './list_staff_styles';
import UserModel from '../../../session/models/user_model';
import Button from '../../../common/components/button';
import ListStaffRow from './list_staff_row';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  listStaff: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  staff: PropTypes.instanceOf(OrderedSet),
  organization: PropTypes.instanceOf(Map),
  organizationId: PropTypes.string.isRequired,
};

const defaultProps = {
  staff: OrderedSet(),
  organization: Map(),
};

const ListStaffView = ({ handleSubmit, pristine, submitting, listStaff, onDelete, currentUser, staff, organization, organizationId }) => {
  useEffect(() => {
    listStaff(currentUser, organizationId);
  }, [listStaff, currentUser, organizationId]);

  return (
    <ListStaffStyles>
      <h1>{organization.get('name')} Staff</h1>
      <br />
      <form>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Created</th>
              <th>Admin</th>
              <th>Edit Resume</th>
              <th>Edit Applications</th>
              <th>View Challenges</th>
              <th>Edit Challenges</th>
              <th width="80">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {staff.map((staffMember) => (
              <ListStaffRow
                key={staffMember.get('id')}
                staffMemberId={staffMember.get('id')}
                staff={staffMember}
                onDelete={() => onDelete(currentUser, staffMember.get('id'))}
              />
            ))}
          </tbody>
        </table>
        <br />
        <Button variant="contained" className="action-contained" disabled={pristine || submitting} onClick={handleSubmit}>
          Save
        </Button>
      </form>
    </ListStaffStyles>
  );
};

ListStaffView.propTypes = propTypes;
ListStaffView.defaultProps = defaultProps;

export default ListStaffView;
