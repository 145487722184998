import ACTIONS from '../actions';
import { ResourcefulReducer } from '../../util';
import CandidateEventModel from '../models/candidate_event_model';

const CandidateEventReducer = new ResourcefulReducer('candidateEvents', CandidateEventModel, {
  actionTypes: [
    ACTIONS.LIST_FILTERED_CANDIDATES_EVENTS,
    ACTIONS.LIST_FILTERED_CANDIDATES_EVENTS.success,
    ACTIONS.LIST_FILTERED_EVENT_CANDIDATES_FOR_STAFF.success,
    ACTIONS.LIST_CANDIDATES_FOR_EVENT.success,
    ACTIONS.CREATE_CANDIDATE_EVENT.success,
    ACTIONS.TRANSITION_CANDIDATE_EVENT_S.success,
    ACTIONS.FETCH_CANDIDATE_EVENT.success,
  ],
});

export default CandidateEventReducer;
