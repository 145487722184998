import { List } from 'immutable';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import SkillsEditingStyles from './skills_editing_styles';
import { preventEventBubbling } from '../../../../../util/common';
import { MuiThemeProvider, MuiFormTheme } from '../../../../../common/form';
import ReactSelect from '../../../../../common/form/redux_form/react_select';

const propTypes = {
  skills: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, get: PropTypes.func }).isRequired,
  skillOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

// TODO: Why are we including MuiThemeProvider at this point??
const SkillsEditingView = ({ skills, skillOptions }) => {
  const [newEntry, setNewEntry] = useState();
  const existingEntries = skills.map((name, index) => skills.get(index));
  const selectOptions = List(skillOptions)
    .filterNot((z) => existingEntries.includes(z[1]))
    .map((x) => ({ value: x[1], label: x[1] }));
  const handleInputChange = (e) => {
    e.preventDefault();
    setNewEntry(e.target.value);
  };
  const addToSkills = (e) => {
    preventEventBubbling(e);

    if (newEntry) {
      skills.push(newEntry);
      setNewEntry(undefined);
    }
  };

  const handleSelectChange = (value) => {
    skills.push(value);
    setNewEntry(value);
  };

  return (
    <MuiThemeProvider theme={MuiFormTheme}>
      <SkillsEditingStyles>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <form onSubmit={preventEventBubbling}>
              <ReactSelect input={{ name: 'addskill', onChange: handleSelectChange, value: newEntry }} options={selectOptions} />
            </form>
            <form onSubmit={addToSkills}>
              <TextField
                id="skills"
                label="Add skills"
                type="test"
                margin="dense"
                variant="outlined"
                fullWidth
                onChange={handleInputChange}
                onSubmit={addToSkills}
                mask="ddddd"
                value={newEntry || ''}
                InputLabelProps={{
                  shrink: false,
                }}
              />
            </form>
          </Grid>
          {/* <Grid item xs={4} sm={12} md={6}>
            <IconButton disabled={!newEntry} aria-label="submit" onClick={addToSkills} className="add-button">
              <AddIcon className="add-icon" /> Add Skill
            </IconButton>
          </Grid> */}
        </Grid>
      </SkillsEditingStyles>
    </MuiThemeProvider>
  );
};

SkillsEditingView.propTypes = propTypes;
SkillsEditingView.defaultProps = defaultProps;

export default SkillsEditingView;
