import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getCurrentUser, getGlobalSurveysBackingOff, getNeedGlobalSurveys, getNeedSystemEnums, getSystemEnumsBackingoff, getTreatment } from './reducer';
import { listSystemEnums as lse, checkListSystemEnumsBackoff as clseb } from './enums/actions';
import { listGlobalSurveys as lgs, checkListGlobalSurveysBackoff as clgsb } from './solve/actions';
import UserModel from './session/models/user_model';
import { useApiCall } from './util/hooks';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  forceValidation: !!getTreatment(state, 'forceValidation'),
  needSystemEnums: getNeedSystemEnums(state),
  systemEnumsBackingOff: getSystemEnumsBackingoff(state),
  needGlobalSurveys: getNeedGlobalSurveys(state),
  globalSurveysBackingOff: getGlobalSurveysBackingOff(state),
});

const mapDispatchToProps = {
  listSystemEnums: lse,
  checkListSystemEnumsBackoff: clseb,
  listGlobalSurveys: lgs,
  checkListGlobalSurveysBackoff: clgsb,
};

const handleBackoff = (until, doIt) => {
  if (until) {
    const delay = until - Date.now() + 50;
    const handle = setInterval(doIt, delay);
    return () => clearInterval(handle);
  }
  return undefined;
};

const GlobalPrecacheHelper = ({
  user,
  forceValidation,
  needSystemEnums,
  systemEnumsBackingOff,
  listSystemEnums,
  checkListSystemEnumsBackoff,
  needGlobalSurveys,
  globalSurveysBackingOff,
  listGlobalSurveys,
  checkListGlobalSurveysBackoff,
}) => {
  const { signedIn, needsValidation } = user;
  const canListGlobalSurveys = signedIn && !(forceValidation && needsValidation);
  const [loadEnums, { submitting: enumsLoading }] = useApiCall(listSystemEnums);
  const [loadGlobalSurveys, { submitting: globalSurveysLoading }] = useApiCall(listGlobalSurveys);

  const shouldLoadSystemEnums = needSystemEnums && !enumsLoading && !systemEnumsBackingOff;
  const shouldLoadGlobalSurveys = canListGlobalSurveys && needGlobalSurveys && !globalSurveysLoading && !globalSurveysBackingOff;

  useEffect(() => {
    if (shouldLoadSystemEnums) {
      loadEnums();
    }
  }, [shouldLoadSystemEnums, loadEnums]);

  useEffect(() => {
    if (shouldLoadGlobalSurveys) {
      loadGlobalSurveys(user);
    }
  }, [shouldLoadGlobalSurveys, loadGlobalSurveys, user]);

  useEffect(() => handleBackoff(systemEnumsBackingOff, checkListSystemEnumsBackoff), [systemEnumsBackingOff, checkListSystemEnumsBackoff]);
  useEffect(() => handleBackoff(globalSurveysBackingOff, checkListGlobalSurveysBackoff), [globalSurveysBackingOff, checkListGlobalSurveysBackoff]);
  return '';
};

GlobalPrecacheHelper.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  forceValidation: PropTypes.bool.isRequired,
  needSystemEnums: PropTypes.bool.isRequired,
  listSystemEnums: PropTypes.func.isRequired,
  systemEnumsBackingOff: PropTypes.number.isRequired,
  needGlobalSurveys: PropTypes.bool.isRequired,
  listGlobalSurveys: PropTypes.func.isRequired,
  globalSurveysBackingOff: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(GlobalPrecacheHelper);
