/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import numeral from 'numeral';
import moment from 'moment';
import { startCase } from 'lodash';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import {
  Body,
  MainColumn,
  Pill,
  Title,
  Organization,
  MetaCategory,
  MetaGroup,
  MetaSubGroup,
  MetaLabel,
  MetaValue,
  MetaIcon,
  SideBar,
  MetaHeading,
} from '../../../common/styles/detail';
import DetailsStyles from './details_styles';

const propTypes = {
  program: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    estimatedSalary: PropTypes.string,
    gainedSkillTypes: PropTypes.string,
    distance: PropTypes.string,
    applicationMethod: PropTypes.string,
    industryType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    programsOffered: PropTypes.arrayOf(PropTypes.string),
    supportTypes: PropTypes.arrayOf(PropTypes.string),
    cost: PropTypes.string,
    dressCode: PropTypes.string,
    get: PropTypes.func,
    getIn: PropTypes.func,
  }).isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  targetTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {};

const DetailsView = ({ program, serviceTypes, targetTypes }) => (
  <DetailsStyles>
    {program && (
      <React.Fragment>
        <Pill>Program</Pill>
        <Title>{program.get('name')}</Title>
        <Organization>{program.getIn(['postingOrganization', 'name'])}</Organization>
        <Body>
          <MainColumn>
            <MetaCategory>
              <MetaLabel>Description</MetaLabel>
              <MetaValue>
                <div dangerouslySetInnerHTML={{ __html: program.get('description') }} />
              </MetaValue>
            </MetaCategory>

            {program.get('estimatedSalary') && (
              <MetaCategory>
                <MetaLabel>Average Salary</MetaLabel>
                <MetaValue>{numeral(program.get('estimatedSalary')).format('$0,0')}</MetaValue>
              </MetaCategory>
            )}
            {program.get('industryType') && (
              <MetaCategory>
                <MetaLabel>Industry</MetaLabel>
                <MetaValue>{startCase(program.get('industryType'))}</MetaValue>
              </MetaCategory>
            )}
            {program && program.get('gainedJobTypes').size > 0 && (
              <MetaCategory>
                <MetaLabel>Programs Offered</MetaLabel>
                <MetaValue>
                  {program &&
                    program.get('gainedJobTypes').map((offering) => (
                      <React.Fragment key={offering}>
                        {startCase(offering)}
                        <br />
                      </React.Fragment>
                    ))}
                </MetaValue>
              </MetaCategory>
            )}
            {program && program.get('gainedSkillTypes').size > 0 && (
              <MetaCategory>
                <MetaLabel>Skills you will gain</MetaLabel>
                {program && program.get('gainedSkillTypes').map((skill) => <MetaValue key={skill}>{startCase(skill)}</MetaValue>)}
              </MetaCategory>
            )}
            {program.get('applicationMethod') && (
              <MetaCategory>
                <MetaLabel>Application Method</MetaLabel>
                <MetaValue>{program.get('applicationMethod')}</MetaValue>
              </MetaCategory>
            )}
            {program && program.get('supportTypes').size > 0 && (
              <MetaCategory>
                <MetaLabel>Support Offered</MetaLabel>
                <MetaValue>
                  {program &&
                    program.get('supportTypes').map((support) => (
                      <React.Fragment key={support}>
                        {startCase(support)}
                        <br />
                      </React.Fragment>
                    ))}
                </MetaValue>
              </MetaCategory>
            )}
            {program.get('dresscode') && (
              <MetaCategory>
                <MetaLabel>Dress Code</MetaLabel>
                <MetaValue>{program.get('dresscode')}</MetaValue>
              </MetaCategory>
            )}
          </MainColumn>

          <SideBar>
            <MetaGroup>
              <MetaIcon>
                <AttachMoneyIcon />
              </MetaIcon>
              <MetaValue>
                <MetaHeading>Cost:</MetaHeading>
                {program.get('cost') ? numeral(program.get('cost')).format('0.00') : 'FREE'}
              </MetaValue>
            </MetaGroup>
            {(program.get('startDate') || program.get('endDate')) && (
              <MetaGroup>
                <MetaIcon>
                  <CalendarTodayIcon />
                </MetaIcon>
                <MetaSubGroup>
                  <MetaHeading>Date:</MetaHeading>
                  {program.get('startDate') && <MetaValue>{`${moment(program.get('startDate')).format('dddd, LL')}`}</MetaValue>}
                  {program.get('endDate') && <MetaValue>{`${moment(program.get('endDate')).format('dddd, LL')}`}</MetaValue>}
                </MetaSubGroup>
              </MetaGroup>
            )}
            {(program.get('startTime') || program.get('endTime')) && (
              <MetaGroup>
                <MetaIcon>
                  <ScheduleIcon />
                </MetaIcon>
                <MetaSubGroup>
                  <MetaValue>
                    <MetaHeading>Time:</MetaHeading>
                    {program.get('startTime') ? `${program.get('startTime')} ` : '? '}
                    {program.get('endTime') && `– ${program.get('endTime')}`}
                  </MetaValue>
                </MetaSubGroup>
              </MetaGroup>
            )}

            {program.getIn(['address', 'address1']) ? (
              <MetaGroup>
                <MetaIcon>
                  <LocationOnOutlinedIcon />
                </MetaIcon>
                <MetaSubGroup>
                  <MetaValue>
                    <MetaHeading>Address:</MetaHeading>
                  </MetaValue>
                  <MetaValue>{program.getIn(['address', 'address1'])}</MetaValue>
                  {program.getIn(['address', 'address2']) ? <MetaValue>{program.getIn(['address', 'address2'])}</MetaValue> : ''}
                  <MetaValue>{`${program.getIn(['address', 'city'])}, ${program.getIn(['address', 'state'])} ${program.getIn(['address', 'zip'])}`}</MetaValue>
                </MetaSubGroup>
              </MetaGroup>
            ) : (
              <MetaGroup>
                <MetaIcon>
                  <LocationOnOutlinedIcon />
                </MetaIcon>
                <MetaValue>
                  {' '}
                  <MetaHeading>Address:</MetaHeading>
                  {program.getIn(['address', 'zip'])}
                </MetaValue>
              </MetaGroup>
            )}
            {targetTypes && (
              <MetaGroup>
                <MetaIcon>
                  <PersonOutlineOutlinedIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Serving:</MetaHeading>
                  {targetTypes.map((item) => item[1]).join(', ')}
                </MetaValue>
              </MetaGroup>
            )}
            {serviceTypes && (
              <MetaGroup>
                <MetaIcon>
                  <LabelOutlinedIcon />
                </MetaIcon>
                <MetaValue>
                  <MetaHeading>Services Provided:</MetaHeading>
                  {serviceTypes.map((item) => item[1]).join(', ')}
                </MetaValue>
              </MetaGroup>
            )}
          </SideBar>
        </Body>
      </React.Fragment>
    )}
  </DetailsStyles>
);

DetailsView.propTypes = propTypes;
DetailsView.defaultProps = defaultProps;

export default DetailsView;
