import { connect } from 'react-redux';
import RequirementsFormView from './requirements_form_view';
import { getEnumsForFormSelect, getTreatment } from '../../../../reducer';
import { formValueSelector } from '../../../../common/form';
// import { SomeActions } from '../../actions';

const selector = formValueSelector('jobForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  isRisekit: !!getTreatment(state, 'isRisekit', true),
  experienceDurationType: getEnumsForFormSelect(state, 'ExperienceDurationType'),
  educationLevelType: getEnumsForFormSelect(state, 'EducationLevelType'),
  jobResponsibilities: selector(state, 'responsibilities'),
  requiredSkills: selector(state, 'requiredSkillTypes'),
  desiredSkills: selector(state, 'desiredSkillTypes'),
  tradeCertifications: selector(state, 'certifications'),
  opportunityTypes: selector(state, 'opportunityTypes'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const RequirementsFormContainer = connect(mapStateToProps, mapDispatchToProps)(RequirementsFormView);

export default RequirementsFormContainer;
