// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const benefitsFormValidate = (values) => {
  const errors = {};
  // Temp for screening questions
  if (!values.get('transport')) {
    errors.transport = 'Required';
  }
  if (!values.get('childCare')) {
    errors.childCare = 'Required';
  }
  if (!values.get('sponsorship')) {
    errors.sponsorship = 'Required';
  }
  if (!values.get('criminalBackground')) {
    errors.criminalBackground = 'Required';
  }
  if (values.get('criminalBackground') && !values.get('backgroundType')) {
    errors.backgroundType = 'Required';
  }
  if (!values.get('h1b')) {
    errors.h1b = 'Required';
  }
  if (!values.get('healthCare')) {
    errors.healthCare = 'Required';
  }
  return errors;
};

export default benefitsFormValidate;
