import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';

import Button from '@material-ui/core/Button';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import TileStyles, { Body, Actions, Content, Activity, PerformedBy, IconGroup, FlexWrapper, Date } from './tile_styles';
import { OutlineAction, Pill } from '../../../common/styles/card';
import UserModel from '../../../session/models/user_model';
import NotificationHelperFactory from '../../notification_helper_factory';

const notificationStates = {
  new: 'new',
  viewed: 'viewed',
  dismissed: 'dismissed',
};

const getToolbarButtons = (id, dismiss) => (
  <Button color="inherit" size="small" onClick={() => dismiss(id)}>
    Dismiss
  </Button>
);

export const getDescription = (notificationHelper, createLink = true) => {
  const description = notificationHelper.getDescription();
  if (!description) {
    return null;
  }

  const description_arr = description.split('~');
  const description_placeholder = description_arr.length > 2 ? description_arr[1] : null;
  const description_url = description_arr.length > 2 ? description_arr[2] : null;
  const text_arr = description_placeholder ? description_arr[0].split(description_placeholder) : [description_arr[0]];

  return (
    <React.Fragment>
      {text_arr[0]}
      {createLink && description_url && <Link to={description_url}>{description_placeholder.replace(/[<>]/g, '')}</Link>}
      {!createLink && `here: ${window.location.origin}${description_url}`}
      {createLink && description_url && text_arr[1]}
    </React.Fragment>
  );
};

const TileView = ({ notification, notificationLabels, dismiss, currentUser }) => {
  const notificationHelper = NotificationHelperFactory.createHelper(currentUser, notification, notificationLabels);

  return (
    notification.get('state') === notificationStates.new && (
      <TileStyles>
        <Actions>
          <OutlineAction>{getToolbarButtons(notification.get('id'), dismiss)}</OutlineAction>
        </Actions>
        <FlexWrapper>
          <Pill>{notificationHelper.getHeader()}</Pill>
          <Date>{notificationHelper.getCreatedAt()}</Date>
        </FlexWrapper>
        {/* <Header>
          <Person>
            <Icon>
              <img alt="avatar" src="/icons/avatar.svg" />
            </Icon>
          </Person>
        </Header> */}
        <Content>
          <Body>
            <Activity>
              {notificationHelper.getActivity() && (
                <div>
                  <strong>{`${notificationHelper.getActivity()}- `}</strong>
                  <span>{getDescription(notificationHelper)}</span>
                </div>
              )}
            </Activity>
            <PerformedBy>
              {notificationHelper.getPerformedByStaff() && (
                <IconGroup>
                  <PersonOutlineOutlinedIcon />
                  <span>Staff:</span>
                  <p>{notificationHelper.getPerformedByStaff()}</p>
                </IconGroup>
              )}
              {/* {notificationHelper.getPerformedByOrgName() && (
                  <IconGroup>
                    <span>Organization:</span> <p>{notificationHelper.getPerformedByOrgName()}</p>
                  </IconGroup>
                )} */}
              {notificationHelper.getPerformedByCandidate() && (
                <IconGroup>
                  <PersonOutlineOutlinedIcon />
                  <span>Candidate:</span>
                  <p>{notificationHelper.getPerformedByCandidate()}</p>
                </IconGroup>
              )}
            </PerformedBy>
          </Body>
        </Content>
      </TileStyles>
    )
  );
};

TileView.propTypes = {
  dismiss: PropTypes.func.isRequired,
  notification: PropTypes.instanceOf(Map).isRequired,
  notificationLabels: PropTypes.objectOf(PropTypes.string).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
};
TileView.defaultProps = {};

export default TileView;
