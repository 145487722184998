import { connect } from 'react-redux';

import { extractCandidateFullName } from 'util/common';

import ReviewFormView from './review_form_view';
import { formValueSelector, getFormValues } from '../../../../../common/form';
import { getCandidate, getPathway, getCurrentUser } from '../../../../../reducer';

const selector = formValueSelector('selectCandidatesForm');

const mapStateToProps = (state) => {
  const chosenPathwayId = selector(state, 'pathwayType');

  const currentUser = getCurrentUser(state);
  const candidates = selector(state, 'candidates')
    ?.filter(Boolean)
    .keySeq()
    .toList();

  const candidateList = candidates.map((candidateId) => {
    const candidate = getCandidate(state, candidateId);
    return [candidate.get('id'), extractCandidateFullName(candidate.get('user'), currentUser)];
  });

  return {
    pathway: getPathway(state, chosenPathwayId),
    name: selector(state, 'selectCandidatesForm'),
    startsOn: selector(state, 'startDate'),
    description: selector(state, 'description'),
    stepDates: selector(state, 'pathwayStepDates')?.toJS(),
    candidates: candidateList,
    formValues: getFormValues('selectCandidatesForm')(state),
  };
};

const ReviewFormContainer = connect(mapStateToProps)(ReviewFormView);

export default ReviewFormContainer;
