import React from 'react';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

const propTypes = {
  organization: PropTypes.instanceOf(Map).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const ParentAccountEthnicityReportView = ({ reportDetails, organization }) => {
  if (!reportDetails.size) {
    return null;
  }
  return (
    <tr key={organization.get('id')}>
      <th>
        <Link to={`${organization.get('id')}`}>{organization.get('name')}</Link>
      </th>
      <td>{reportDetails.get('black')}</td>
      <td>{reportDetails.get('caucasian')}</td>
      <td>{reportDetails.get('eastAsian')}</td>
      <td>{reportDetails.get('latino')}</td>
      <td>{reportDetails.get('middleEastern')}</td>
      <td>{reportDetails.get('mixed')}</td>
      <td>{reportDetails.get('nativeAmerican')}</td>
      <td>{reportDetails.get('nativeIslander')}</td>
      <td>{reportDetails.get('southAsian')}</td>
      <td>{reportDetails.get('other')}</td>
      <td>{reportDetails.get('noShare')}</td>
    </tr>
  );
};

ParentAccountEthnicityReportView.propTypes = propTypes;
ParentAccountEthnicityReportView.defaultProps = defaultProps;

export default ParentAccountEthnicityReportView;
