import styled from 'styled-components';
import theme from '../../../../common/theme';

const AdditionalQuestionsFormStyles = styled.div`
  padding: 25px 50px;

  @media ${theme.query.desktopMax} {
    & {
      padding: 10px 20px;
    }
  }
`;

export default AdditionalQuestionsFormStyles;
