import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from '../../../../../common/components/button/index';
import { reduxForm, FieldArray } from '../../../../../common/form';

import SelectCandidatesFormStyles from './select_candidates_form_styles';
import SelectCandidatesList from './select_candidates_list';

const SelectCandidatesForm = ({ handleSubmit, pristine, selectedCandidatesCount }) => (
  <SelectCandidatesFormStyles>
    <form onSubmit={handleSubmit}>
      <Button className="button-top" buttonType="secondary-outline" type="submit" disabled={!selectedCandidatesCount}>
        Next Step
      </Button>
      <FieldArray component={SelectCandidatesList} />
      <div className="button-group">
        <div className="button-left">
          <Link to="/staff/pathways">
            <Button buttonType="secondary-outline">Cancel</Button>
          </Link>
        </div>
        <div className="button-right">
          <Button buttonType="secondary" type="submit" disabled={!selectedCandidatesCount}>
            Next Step
          </Button>
        </div>
      </div>
    </form>
  </SelectCandidatesFormStyles>
);

SelectCandidatesForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  selectedCandidatesCount: PropTypes.number.isRequired,
};

SelectCandidatesForm.defaultProps = {};

const SelectCandidatesFormView = reduxForm({
  form: 'selectCandidatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SelectCandidatesForm);

export default SelectCandidatesFormView;
