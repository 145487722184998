import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import { OrderedSet } from 'immutable';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

// import UserModel from '../../../../session/models/user_model';

import { StyledTable } from './pathway_list_styles';
import PathwayRow from '../pathway_row';
import Spinner from '../../../../common/components/spinner';

const PathwayListView = ({ candidatePathwayInstances, loaded }) => {
  if (!candidatePathwayInstances || candidatePathwayInstances.size === 0) {
    if (!loaded) {
      return <Spinner />;
    }
    return <p>You are not currently enrolled in any pathways</p>;
  }

  return (
    <div>
      <TextField
        id="search"
        placeholder="Search"
        margin="dense"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        // value={newSearch}
        // onChange={onSearchChange}
      />
      <StyledTable>
        <thead>
          <tr>
            <th width="100">&nbsp;</th>
            <th>Pathway</th>
            <th width="80">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {candidatePathwayInstances.map((candidatePathwayInstance) => (
            <PathwayRow
              pathwayInstance={candidatePathwayInstance.get('pathwayInstance')}
              key={candidatePathwayInstance.get('id')}
              candidatePathwayInstanceId={candidatePathwayInstance.get('id')}
            />
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

PathwayListView.propTypes = {
  candidatePathwayInstances: PropTypes.instanceOf(OrderedSet).isRequired,
  loaded: PropTypes.bool.isRequired,
};
PathwayListView.defaultProps = {};

export default PathwayListView;
