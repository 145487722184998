import { urls } from './api.urls';
import env from '../app/app.env';
import * as http from './http.api';

import { PathwaySummaryReportPayload, ApiResponse, PathwayListPayload, ModeReportsPayload, ModeReportSignedUrlPayload } from './api.types';

// pathways

// summary
export const getPathwaySummaryReport = ({ token, params }: PathwaySummaryReportPayload): Promise<ApiResponse> => {
  return http.post(urls.pathWay.GENERATE_PATHWAY_SUMMARY_REPORT, undefined, token, params);
};

// list
export const getPathwayList = ({ token, orgId, params }: PathwayListPayload): Promise<ApiResponse> => {
  return http.get(urls.pathWay.LIST_PATHWAY_INSTANCES.replace(':orgId', orgId), params, token);
};

export const refreshAccessToken = () => {
  return http.get(`/auth/refresh-token`);
};

// reports
export const getModeReports = ({ userAuthToken }: ModeReportsPayload): Promise<any> => {
  return http.get(urls.reports.GET_MODE_REPORTS, {}, userAuthToken);
}

export const getModeReportSignedUrl = ({ userAuthToken, reportToken }: ModeReportSignedUrlPayload): Promise<any> => {
  return http.get(urls.reports.GET_MODE_REPORT_SIGNED_URL, { report_token: reportToken }, userAuthToken);
}

// indeed job
export const getIndeedJobById = (indeedJobId: string) => {
  const url = urls.externalJob.GET_INDEED_JOB.replace(':indeedJobId', indeedJobId);
  const params = {};
  const token = '';
  const baseUrl = env.API_URL;

  return http.get(url, params, token, baseUrl);
};
