import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import HowHearFormView from './how_hear_form_view';
import { formValueSelector } from '../../../../common/form';
import { completeOnboarding } from '../../../../onboarding/actions';
import { listFilteredOrganizations, updateStaffMember, updateCandidate } from '../../../../solve/actions';
import { getOrganizations, getCurrentUser, getReferringOrg } from '../../../../reducer';

const selector = formValueSelector('howHearForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const orgs = getOrganizations(state);
  const linkOrgId = getReferringOrg(state);
  let initialValues = fromJS({});
  if (linkOrgId) {
    const org = orgs.find((entry) => entry.get('id') === linkOrgId);
    if (org) {
      switch (org.get('orgType')) {
        case 'nonprofit-pay':
        case 'nonprofit':
          initialValues = initialValues.set('howFoundReferralOrgType', 'nonprofit');
          break;
        case 'employer-pay':
        case 'employer':
          initialValues = initialValues.set('howFoundReferralOrgType', 'employer');
          break;
        default:
          initialValues = initialValues.set('howFoundReferralOrgType', 'other');
      }
      initialValues = initialValues.set('howFoundReferralName', org.get('name'));
    }
  }
  return {
    currentUser,
    initialValues,
    orgTypeSelected: selector(state, 'howFoundReferralOrgType'),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listFilteredOrganizations,
  completeOnboarding,
  updateCandidate,
  updateStaffMember,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: (values) => {
    if (stateProps.currentUser.candidateId) {
      dispatchProps.updateCandidate(
        stateProps.currentUser,
        stateProps.currentUser.candidateId,
        fromJS({
          id: stateProps.currentUser.candidateId,
          howFoundReferralOrgType: values.get('howFoundReferralOrgType'),
          howFoundReferralName: values.get('howFoundReferralName'),
        }),
      );
    } else if (stateProps.currentUser.staffId) {
      dispatchProps.updateStaffMember(
        stateProps.currentUser,
        stateProps.currentUser.staffId,
        fromJS({
          id: stateProps.currentUser.staffId,
          howFoundReferralOrgType: values.get('howFoundReferralOrgType'),
          howFoundReferralName: values.get('howFoundReferralName'),
        }),
      );
    }
    dispatchProps.completeOnboarding();
  },
  ...dispatchProps,
});

const HowHearFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(HowHearFormView);

export default HowHearFormContainer;
