import styled from 'styled-components';
import theme from '../../../../../common/theme';

const EditingStyles = styled.div`
  .add-button {
    padding: 5px 20px 5px 15px;
    border-radius: 5px;
    font-size: 1em;
    font-style: italic;
    text-align: left;
    color: ${theme.color.grayDark};
  }
  .add-icon {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25);
    font-size: 24px;
    padding: 2px;
    margin-right: 10px;
  }
  .union-suggestions {
    position: absolute;
    top: 100px;
    background: rgba(255, 255, 255, 0.95);
    list-style-type: none;
    padding: 0;
    z-index: 9;
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    max-width: 31%;
    border: 1px solid rgba(155, 155, 155, 0.5);
    margin-top: 2px;
    border-radius: 1%;
    li {
      line-height: 2;
      padding: 2px 2px 2px 15px;
      &:hover {
        background: rgba(38, 132, 255, 0.2);
      }
    }
  }
  .no-suggestion {
    position: absolute;
    top: 100px;
    margin: 0px;
    font-size: 13px;
    font-style: italic;
  }
  @media ${theme.query.tabletMax} {
    .add-button {
      padding: 10px;
    }
    .union-suggestions {
      max-width: 100%;
    }
  }
`;

export default EditingStyles;
