import styled from 'styled-components';
import theme from '../../../common/theme';

export const SurveyWizardStyles = styled.div`
  padding: 25px 50px;

  form {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(204, 204, 204, 0.5);
  }

  .form-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 220px;
    background-color: #3d5e7e;

    .button-left {
      color: white;
      position: absolute;
      left: 15px;
    }

    .button-right {
      color: white;
      position: absolute;
      right: 15px;
    }
  }

  .challenges-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .challenges-title h2 {
    text-transform: uppercase;
    font-weight: 400;
    margin: 20px 0px 0px;
  }

  .challenges-title img {
    width: 100px;
  }

  .progress {
    margin: 35px 50px;
  }

  .questions {
    margin: 35px 50px;
  }

  .question {
    margin-bottom: 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin: 35px 50px;
    padding-bottom: 50px;
  }

  .button-right {
    float: right;
  }

  button {
    outline: 0;
  }

  @media ${theme.query.desktopMax} {
    & {
      padding: 10px 20px;
    }
    .progress,
    .questions,
    .button-group {
      margin: 35px 25px;
    }
    .button-group {
      flex-direction: row;
      align-items: flex-end;
    }
    .button-right {
      display: flex;
      flex-direction: column;
    }
    .button-right button:first-child {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
  @media ${theme.query.tabletMax} {
    & {
      padding: 0px;
    }
  }
`;

export const QuestionPromptStyles = styled.div`
  margin-top: 75px;
  img {
    width: 35px;
  }
  h3 {
    margin-top: 5px;
    text-transform: uppercase;
  }
  h4 {
    margin-bottom: 0px;
  }
`;
