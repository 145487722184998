import { connect } from 'react-redux';
import ReportDemographicsView from './report_demographics_view';
import { getCurrentUser, getFilteredPathwayInstances, getPathways } from '../../../../reducer';
import { listPathwayInstances } from '../../../../solve/actions';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
  pathways: getPathways(state),
  pathwayInstances: getFilteredPathwayInstances(state),
});

const mapDispatchToProps = {
  listPathwayInstances,
};

const ReportDemographicsContainer = connect(mapStateToProps, mapDispatchToProps)(ReportDemographicsView);

export default ReportDemographicsContainer;
