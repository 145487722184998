/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
// import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import theme from '../../../common/theme';
import { Card } from '../../../common/styles/layout';
import OrganizationStyles from './organization_styles';

const propTypes = {};

const defaultProps = {};

const OrganizationView = () => (
  <OrganizationStyles>
    <h2>Review Your Organization Details</h2>
    <img src="/images/program-logo.png" height="70" alt="Logo" />
    <div style={{ height: 40 }} />
    <Card>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h3>Organization Details</h3>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <h4>Year Up Chicago</h4>
              <cite>Organization Name</cite>

              <h4>www.yearup-chicago.com</h4>
              <cite>Website</cite>

              <h4>www.yearup-chicago.i.com</h4>
              <cite>Secondary Website</cite>

              <h4>123 W Jackson Blvd, IL 60606</h4>
              <cite>Address</cite>
            </Grid>
            <Grid item xs={6}>
              <h4>98-1375982</h4>
              <cite>Employer ID Number</cite>

              <h4>(312) 485-1847</h4>
              <cite>Phone Number</cite>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Link to="#" className="edit-link">
            <EditIcon />
            <br />
            <span>EDIT</span>
          </Link>
          <h3>Description</h3>
          {/* eslint-disable-next-line max-len */}
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
            laborum.
          </p>
        </Grid>
      </Grid>
      <div style={{ height: 40 }} />
    </Card>
    <Card>
      <Link to="#" className="edit-link">
        <EditIcon />
        <br />
        <span>EDIT</span>
      </Link>
      <h3>Category</h3>
      <div className="category">
        <CheckIcon style={{ float: 'right', color: theme.color.action }} />
        <h4>Workforce Development</h4>
        <cite>Job Training Placement/Career Pathway Program/Staffing</cite>
      </div>
      <div style={{ height: 40 }} />
    </Card>
  </OrganizationStyles>
);

OrganizationView.propTypes = propTypes;
OrganizationView.defaultProps = defaultProps;

export default OrganizationView;
