import styled from 'styled-components';
import React, { useState } from 'react';

import JobList from '../job_list';
import Tabber from '../../../ui/components/tabber';
import JobListingsStyles from './job_listings_styles';

// TODO: Find a better way to do this

const StyledTab = styled.div``;

const JobListingsView = () => {
  const [tabIndex, setTabIndex] = useState(0);

  let list;
  switch (tabIndex) {
    case 0:
      list = <JobList mode="all" />;
      break;
    case 1:
      list = <JobList mode="saved" />;
      break;
    case 2:
      list = <JobList mode="applied" />;
      break;
    default:
      list = null;
  }

  return (
    <JobListingsStyles className="container-wrapper">
      <div className="candidate-wrapper">
        {/* <h3 style={{ textAlign: 'center' }}>Jobs</h3> */}
        {/* <RecommendedJobs /> */}
        <Tabber onChange={setTabIndex}>
          <StyledTab tabLabel="All Jobs" />
          <StyledTab tabLabel="Saved" />
          <StyledTab tabLabel="Applied" />
        </Tabber>
      </div>
      {list}
    </JobListingsStyles>
  );
};

JobListingsView.propTypes = {};

export default JobListingsView;
