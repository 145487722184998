import { connect } from 'react-redux';
import QuestionnaireCompletedView from './questionnaire_completed_view';
import { createMockListForSelect } from '../../../../util';
import { formValueSelector } from '../../../../common/form';
// import { SomeSelectors } from '../../../../reducer';
// import { SomeActions } from '../../actions';

const selector = formValueSelector('applyToProgramForm');

const EXAMPLE_JOB_TYPES = createMockListForSelect('job types');
const EXAMPLE_TEAM_MEMBERS = createMockListForSelect('team members');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.2
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  // prop assignments
  jobTypes: EXAMPLE_JOB_TYPES,
  teamMembers: EXAMPLE_TEAM_MEMBERS,
  payment: selector(state, 'payment'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const QuestionnaireCompletedContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionnaireCompletedView);

export default QuestionnaireCompletedContainer;
