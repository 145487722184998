import PropTypes from 'prop-types';
import styled from 'styled-components';
import { darken } from 'polished';
import theme from '../../theme';

/**
 * Our basic button
 * @type {StyledComponentClass<JSX.IntrinsicElements["button"], any, P>}
 */
const Button = styled.button`
  appearance: none;
  border: 0;
  border-radius: 0.5em;
  cursor: pointer;
  display: inline-block;
  font-family: ${theme.font.serif.regular};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1;
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  transition: background-color 150ms ease-in;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  text-transform: uppercase;

  &:hover {
    background-color: ${theme.color.action};
    color: ${theme.color.text.light};
  }

  &:disabled {
    background-color: ${theme.color.secondary.light};
    color: ${theme.color.secondary.default};
    cursor: not-allowed;

    &:hover {
      background-color: ${theme.color.secondary.light};
      color: ${theme.color.secondary.default};
    }
  }

  ${({ buttonType }) => {
    switch (buttonType) {
      case 'primary':
        return `
          background-color: ${theme.color.action};
          color: #fff;

          &:hover {
            background-color: ${darken(0.15, theme.color.action)};
            border: 0;
          }

          &:active {
            background-color: ${darken(0.25, theme.color.action)};
          }
        `;
      case 'primary-outline':
        return `
          background-color: #fff;
          color: ${theme.color.action};
          border: solid 1px ${theme.color.action};

          &:hover {
            background-color: #fff;
            border-color: ${darken(0.15, theme.color.action)};
            color: ${darken(0.15, theme.color.action)};
          }

          &:active {
            border-color: ${darken(0.25, theme.color.action)};
            color: ${darken(0.25, theme.color.action)};
          }
        `;
      case 'secondary':
        return `
          background-color: ${theme.color.secondaryAction};
          color: #fff;

          &:hover {
            background-color: ${darken(0.15, theme.color.secondaryAction)};
            border: 0;
          }

          &:active {
            background-color: ${darken(0.25, theme.color.secondaryAction)};
          }
        `;
      case 'secondary-outline':
        return `
          background-color: transparent;
          color: ${theme.color.secondaryAction};
          border: solid 1px ${theme.color.secondaryAction};

          &:hover {
            background-color: transparent;
            border-color: ${darken(0.15, theme.color.secondaryAction)};
            color: ${darken(0.15, theme.color.secondaryAction)};
          }

          &:active {
            border-color: ${darken(0.25, theme.color.secondaryAction)};
            color: ${darken(0.25, theme.color.secondaryAction)};
          }
        `;
      case 'subtle':
        return `
          background-color: ${theme.color.subtleAction};
          color: #fff;

          &:hover {
            background-color: ${darken(0.15, theme.color.subtleAction)};
            border: 0;
          }

          &:active {
            background-color: ${darken(0.25, theme.color.subtleAction)};
          }
        `;
      case 'subtle-outline':
        return `
          background-color: #fff;
          color: ${theme.color.subtleAction};
          border: solid 1px ${theme.color.subtleAction};

          &:hover {
            background-color: #fff;
            border-color: ${darken(0.15, theme.color.subtleAction)};
            color: ${darken(0.15, theme.color.subtleAction)};
          }

          &:active {
            border-color: ${darken(0.25, theme.color.subtleAction)};
            color: ${darken(0.25, theme.color.subtleAction)};
          }
        `;
      case 'back':
        return `
        display: flex;
        align-items: center;
        padding: 5px !important;
        font-weight: 500;
        letter-spacing: 0.5px;
        background-color: transparent;

        &:hover {
          background-color: rgba(34, 49, 63, 0.08);
          color: rgba(0, 0, 0, 0.87);
        }

        svg {
          height: 18px;
        }
        `;
      case 'text':
        return `
          background-color: transparent;
          color: inherit;
          border-bottom: 2px solid ${theme.color.action};
          border-radius: 0;
          padding: 0;
          margin: 0.75rem 1.5rem;

          &:hover {
            background-color: transparent;
            border-color: ${theme.color.primary.default};
            color: ${theme.color.primary.default};
          }
        `;
      default:
        return null;
    }
  }};

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
          font-size: 18px;
          padding: 0.95rem 2rem;
        `;
      case 'small':
        return `
          font-size: 12px;
          padding: 0.75rem 1.5rem;
        `;
      case 'tiny':
        return `
          font-size: 10px;
          padding: 0.5rem 1.0rem;
          border-width: 1px;
        `;
      default:
        return null;
    }
  }};
`;

Button.propTypes = {
  /** Render the 'primary' style */
  primary: PropTypes.bool,
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'primary-outline', 'secondary-outline', 'subtle', 'subtle-outline', 'text', 'back']),
  size: PropTypes.oneOf(['large', 'normal', 'small', 'tiny']),
};
Button.defaultProps = {
  primary: false,
  buttonType: 'primary',
  type: 'button',
  size: 'normal',
};

/** @component */
export default Button;
