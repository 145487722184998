function failAPI(response) {
  const err = new Error(response.error);
  err.response = response;
  throw err;
}

// const PAGE_SIZE = 25;
const MAX_PAGE = 100;

const buildAggregateResponse = (previous, { result, ...rest }) => ({
  result: previous.concat(result),
  ...rest,
});

/**
 * paginatedAPIAction emits incremental updates as more pages are retrieved, so it’s ideal for populating UI lists
 * (where we want to show page 1 asap and then page 2, 3, …, N as soon as they’re available).
 * `bufferedPaginatedAPIAction may supply a better UX if we need to filter/sort client-side
 * or we don’t want to ever show the user a partial list.
 * @param action {function(pageNum)} Function that will retrieve the requested page of results
 * @returns {function(...[*]=)}
 */
const paginatedAPIAction = (action, actionTypes, errorMessage, meta = {}, after = undefined) => {
  const doFetch = (pageNum, previousResults) => async (dispatch) => {
    dispatch({ type: actionTypes.request, ...meta, pageNum });
    try {
      const response = await action(pageNum);
      if (response.error) {
        failAPI(response);
      }
      const aggregateResponse = buildAggregateResponse(previousResults, response);
      dispatch({
        type: actionTypes.success,
        response: aggregateResponse,
        ...meta,
        pageNum,
      });

      let finalResponse = aggregateResponse;
      if (response.result.length > 0 && pageNum < MAX_PAGE) {
        finalResponse = await dispatch(doFetch(pageNum + 1, aggregateResponse.result));
      }
      if (after) {
        const afterResult = await after(dispatch, finalResponse);
        return afterResult;
      }
      return finalResponse;
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.failure,
          message: err.response.error,
          errors: err.response.error_messages,
          ...meta,
          err: err?.toString(),
          pageNum,
        });
      } else {
        dispatch({
          type: actionTypes.failure,
          message: errorMessage || err.message || err,
          ...meta,
          err: err?.toString(),
          pageNum,
        });
      }
      throw err;
    }
  };

  return doFetch(1, []);
};

export default paginatedAPIAction;
