import { connect } from 'react-redux';
import { Map, fromJS } from 'immutable';

import ExternalView from './external_view';
import { formValueSelector } from '../../../../common/form';
import { createInvitation } from '../../../../solve/actions';
import { getCurrentUser, getCandidate, getTreatment } from '../../../../reducer';

const selector = formValueSelector('inviteExternalApplicant');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })

// const hardCodedValues = Map({ invitationType: 'job-recommendation', })

const mapStateToProps = (state) => {
  const candidateId = selector(state, 'toCandidate');
  return {
    currentUser: getCurrentUser(state),
    toChannel: selector(state, 'channel'),
    candidateId,
    candidate: candidateId && getCandidate(state, candidateId),
    isRisekit: !!getTreatment(state, 'isRisekit', true),
  };
};
// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  createInvitation,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const indeedJob = ownProps.indeedInviteInfo?.get('jobtitle');
  const invitationType = indeedJob ? 'external-job-recommendation' : ownProps.inviteType;
  const recommendedId = indeedJob ? ownProps.indeedInviteInfo?.get('jobkey') : ownProps.inviteInfo?.get('id');

  const externalJob = indeedJob
    ? {
        external_job_source_type: 'indeed',
        external_id: ownProps.indeedInviteInfo.get('jobkey'),
        title: indeedJob,
      }
    : undefined;

  return {
    onSubmit: (values) => {
      let updatedValues;
      if (values.get('channel') === 'email') {
        updatedValues = values.delete('toPhone').merge({ contactMethodType: 'email' });
      } else if (values.get('channel') === 'phone') {
        updatedValues = values.delete('toEmail').merge({ contactMethodType: 'phone' });
      } else if (values.get('channel') === 'existing') {
        switch (values.get('contactMethod')) {
          case 'sendBoth':
            updatedValues = values.merge({
              contactMethodType: 'both',
              toEmail: stateProps.candidate.getIn(['user', 'email']),
              toPhone: stateProps.candidate.getIn(['user', 'phone']),
            });
            break;
          case 'sendPhone':
            updatedValues = values.delete('toEmail').merge({ contactMethodType: 'phone', toPhone: stateProps.candidate.getIn(['user', 'phone']) });
            break;
          default:
          case 'sendEmail':
            updatedValues = values.delete('toPhone').merge({ contactMethodType: 'email', toEmail: stateProps.candidate.getIn(['user', 'email']) });
            break;
        }
        updatedValues = updatedValues
          .delete('contactMethod')
          .delete('toCandidate')
          .merge({
            toUserId: stateProps.candidate.getIn(['user', 'id']),
            firstName: stateProps.candidate.getIn(['user', 'firstName']),
            lastName: stateProps.candidate.getIn(['user', 'lastName']),
          });
      } else {
        updatedValues = values;
      }
      const hardCodedValues = Map({ invitationType, recommendedId, referralSourceType: 'not-applicable', externalJob });
      return dispatchProps.createInvitation(stateProps.currentUser, fromJS(updatedValues.merge(hardCodedValues)));
    },
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

const ExternalContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ExternalView);

export default ExternalContainer;
