import { connect } from 'react-redux';
import NotesListView from './notes_list_view';
import { getCurrentUser, getNotes } from '../../../reducer';
import { listNotes } from '../../../solve/actions';

const mapStateToProps = (state, { candidateId }) => ({
  currentUser: getCurrentUser(state),
  notes: getNotes(state),
  candidateId,
});

const mapDispatchToProps = {
  listNotes,
};

const NotesListContainer = connect(mapStateToProps, mapDispatchToProps)(NotesListView);

export default NotesListContainer;
