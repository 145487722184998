import { connect } from 'react-redux';
import StatusUpdateTileView from './status_update_tile_view';
import { getCurrentUser } from '../../../reducer';

const mapStateToProps = (state, { record, setCandidateState, setStaffState }) => {
  let oldState;
  let newState;
  let setState;
  let canApprove;

  const actor = record?.get('lastStateChangedBy');
  const currentUser = getCurrentUser(state);

  if (!actor || !record || actor.get('id') === record.getIn(['candidate', 'user', 'id'])) {
    return { canApprove: false };
  }

  if (currentUser.candidateId) {
    oldState = record.get('lastApprovedCandidateState')?.substring(2);
    newState = record.get('candidateState')?.substring(2);
    setState = setCandidateState;
    canApprove = true;
  } else {
    oldState = record.get('lastApprovedStaffState')?.substring(2);
    newState = record.get('staffState')?.substring(2);
    setState = setStaffState;
    canApprove = currentUser.getIn(['staffOrganization', 'id']) === record.getIn(['posting_organization', 'id']);
  }
  return {
    staffName: `${actor.get('firstName')} ${actor.get('lastName')}`,
    staffOrg: actor.getIn(['staffOrganization', 'name']),
    oldState,
    newState,
    setState,
    canApprove,
  };
};

const mapDispatchToProps = {};

const StatusUpdateTileContainer = connect(mapStateToProps, mapDispatchToProps)(StatusUpdateTileView);

export default StatusUpdateTileContainer;
