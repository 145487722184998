// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const detailsFormValidate = (values) => {
  const errors = {};
  if (!values.get('estimated_salary')) {
    errors.estimated_salary = 'Required';
  }

  if (values.get('payment') === null || values.get('payment') === undefined) {
    errors.payment = 'Required';
  }
  return errors;
};

export default detailsFormValidate;
