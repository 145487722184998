import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import useAuthToken from 'risekit/common/hooks/useAuthToken';
import CircularSpinner from 'risekit/common/components/spinner';

import { ModeReportsStyles } from './mode-reports-styles';
import useGetModeReports from '../../hooks/useGetModeReports';

const ModeReports = () => {
  const userAuthToken = useAuthToken();

  const { isLoading, error, modeReports } = useGetModeReports({ userAuthToken });

  const renderModeReports = () => {
    if (isLoading || !modeReports) {
      return (
        <>
          <CircularSpinner />
        </>
      );
    }

    return (
      <div className="mode-reports__container">
        <Grid container spacing={2}>
          {modeReports.map(({ name, token }, idx) => (
            <Grid key={idx} item xs={12}>
              <Link to={`/staff/reporting/mode-reports/${token}`} className="mode-report__container">
                <div className="mode-report__title">{name}</div>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    );
  };

  if (error) {
    return <div>Opps something went wrong.</div>;
  }

  return (
    <ModeReportsStyles>
      <div className="title__container">
        <h1>Mode Reports</h1>
        {renderModeReports()}
      </div>
    </ModeReportsStyles>
  );
};

export default ModeReports;
