/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OrderedSet } from 'immutable';
import moment from 'moment';
import { Button } from '../../../common/components';
import UserModel from '../../../session/models/user_model';

import RowStyles, { Pill } from './listing_invitation_row_styles';

const ListingInvitationRowView = ({ currentUser, invitation, resendInvitation }) => {
  let invitationType;
  let recommendedPosting;
  let recommendedPostingName;
  switch (invitation.get('recommendedType')) {
    case 'Program':
      invitationType = 'program';
      recommendedPosting = 'recommendedProgram';
      recommendedPostingName = 'name';
      break;
    case 'Event':
      invitationType = 'event';
      recommendedPosting = 'recommendedEvent';
      recommendedPostingName = 'name';
      break;
    default:
    case 'JobPosting':
      invitationType = 'job';
      recommendedPosting = 'recommendedJobPosting';
      recommendedPostingName = 'title';
      break;
  }

  return (
    <React.Fragment>
      {invitation && (
        <RowStyles>
          <td>
            {invitation.get('firstName')} {invitation.get('lastName')}
          </td>
          <td>
            {invitation.get('toEmail') && <p>{invitation.get('toEmail')}</p>}
            {invitation.get('toPhone') && <p>{invitation.get('toPhone')}</p>}
          </td>
          <td>
            <Link className="invitation-link" to={`/staff/${invitationType}s/${invitation.getIn([recommendedPosting, 'id'])}`}>
              <Pill>{invitationType}</Pill>
              <span className="invitation-posting">{invitation.getIn([recommendedPosting, recommendedPostingName])}</span>
            </Link>
          </td>
          <td>{`${moment(invitation.get('createdAt')).format('MM/DD/YYYY')}`}</td>
          <td>{invitation.get('acceptedInvitationAt') ? `Accepted ${moment(invitation.get('acceptedInvitationAt')).format('MM/DD/YYYY')}` : 'Not accepted'}</td>
          <td>
            <Button buttonType="secondary-outline" onClick={() => resendInvitation(currentUser, invitation.get('id'))}>
              Resend Invite
            </Button>
          </td>
        </RowStyles>
      )}
    </React.Fragment>
  );
};

ListingInvitationRowView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  invitation: PropTypes.instanceOf(OrderedSet).isRequired,
  resendInvitation: PropTypes.func.isRequired,
};
ListingInvitationRowView.defaultProps = {};

export default ListingInvitationRowView;
