import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(() => ({
  stepLabel: {
    cursor: (props) => (props.disabled ? 'default' : 'pointer !important'),
  },
}));

const InteractiveStepperView = ({ steps, activeStep, setActiveStep, onChange, disabled, fullWidth }) => {
  const classes = useStyles({ disabled });
  const inputWidth = fullWidth ? '100%' : 'auto';
  return (
    <Stepper activeStep={activeStep} alternativeLabel style={{ width: inputWidth }}>
      {steps.map(({ label, value }, idx) => (
        <Step
          disabled={disabled}
          key={label}
          onClick={async () => {
            await onChange(value);
            setActiveStep(idx);
          }}
        >
          <StepLabel className={classes.stepLabel}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

InteractiveStepperView.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]) })).isRequired,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

InteractiveStepperView.defaultProps = {
  disabled: false,
  activeStep: 0,
  fullWidth: false,
};

export default InteractiveStepperView;
