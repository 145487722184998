import bufferedPaginatedAPIAction from '../util/buffered_paginated_api_action';
import * as api from '../api';
import { genericAPIActionTypes } from '../util';

const ACTIONS = {
  LIST_SYSTEM_ENUMS: genericAPIActionTypes('Solve:ListSystemEnums'),
  CHECK_LIST_SYSTEM_ENUMS_BACKOFF: 'Solve:ListSystemEnums:BackoffCheck',
};

export const listSystemEnums = () => bufferedPaginatedAPIAction((page) => api.listSystemEnums(page), ACTIONS.LIST_SYSTEM_ENUMS, 'Unable to list system enums');
export const checkListSystemEnumsBackoff = () => ({
  type: ACTIONS.CHECK_LIST_SYSTEM_ENUMS_BACKOFF,
});

export default ACTIONS;
