import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import * as toast from 'risekit/common/components/toast';

import CandidateStepFileUpload from './candidate_step_file_upload';
import { MuiDatePicker } from '../../../../common/form/index';

import { updatePathwayInstanceStep, fetchCandidatePathway, transitionPathwayStepStaffState } from '../../../../solve/actions';
import { getCurrentUser } from '../../../../reducer';

import { MilestoneCard, Step, Banner, Note } from './candidate_step_styles';

const useStyles = makeStyles(() => ({
  bannerContainer: {
    height: '70px',
  },
  descriptionText: {
    width: '80%',
  },
  showMoreButton: {
    minWidth: '100px',
    marginTop: '-6px',
  },
  stepSelect: {
    width: '165px',
  },
}));

const getStepColor = (stepType) => {
  switch (stepType) {
    case 'document':
      return '#9D71D6';
    case 'event':
      return '#FFAB4A';
    case 'assessment':
      return '#67B4FF';
    case 'action':
    default:
      return '#4BC076';
  }
};

const candidateStates = [
  { label: 'Not Started', value: 's_not_started' },
  { label: 'In Progress', value: 's_in_progress' },
  { label: 'Completed', value: 's_complete' },
];

/**
 * NOTE: This will need to display only those pathway instance steps of category
 * 'candidate' for this specific candidate - waiting on api/seed to verify candidate model
 * */
const SingleStepForm = ({ onTransition, step, pathwayInstanceStep, onUpdate, candidatePathwayId }) => {
  const initialStepNote = pathwayInstanceStep.get('note') || '';

  const [stepNote, setStepNote] = useState(initialStepNote);

  useEffect(() => {
    setStepNote(initialStepNote);
  }, [initialStepNote, setStepNote]);

  const classes = useStyles();
  const [showFullDesc, toggleShowFullDesc] = useState(false);
  const [ogTargetCompletionDate, updateOgTargetCompletionDate] = useState(pathwayInstanceStep.get('targetCompletionDate'));
  const [targetCompletionDate, updateTargetCompletionDate] = useState(pathwayInstanceStep.get('targetCompletionDate'));

  const [ogCompletedOn, updateOgCompletedOn] = useState(pathwayInstanceStep.get('completedOn'));
  const [completedOn, updateCompletedOn] = useState(pathwayInstanceStep.get('completedOn'));

  if (pathwayInstanceStep.get('completedOn') !== ogCompletedOn) {
    updateCompletedOn(pathwayInstanceStep.get('completedOn'));
    updateOgCompletedOn(pathwayInstanceStep.get('completedOn'));
  }

  if (pathwayInstanceStep.get('targetCompletionDate') !== ogTargetCompletionDate) {
    updateOgTargetCompletionDate(pathwayInstanceStep.get('targetCompletionDate'));
    updateTargetCompletionDate(pathwayInstanceStep.get('targetCompletionDate'));
  }

  const [assessmentScore, updateAssessmentScore] = useState(pathwayInstanceStep.getIn(['extra', 'assessmentScore']));

  const truncatedDesc = (desc) => {
    if (desc.length > 100) {
      return `${desc.substring(0, 100)}...`;
    }
    return desc;
  };

  const showSuccessToast = () => toast.success({ title: 'Success', message: 'Candidate pathways step has been updated.' });

  const changeTargetCompletionDate = (value) => {
    updateTargetCompletionDate(value);
    onUpdate(candidatePathwayId, pathwayInstanceStep.get('id'), { targetCompletionDate: value })
    showSuccessToast();
  };

  const changeCompletedOn = (value) => {
    updateCompletedOn(value);
    // onUpdate(candidatePathwayId, pathwayInstanceStep.get('id'), { completedOn: value });
    onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), 's_complete', { completedOn: value });
    showSuccessToast();
  };

  const onNoteSave = () => {
    const currentStepState = pathwayInstanceStep.get('staffState');
    onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), currentStepState, { note: stepNote });
    showSuccessToast();
  };

  const stepColor = getStepColor(step.get('pathwayStepType'));

  const changeAssessmentScore = (event) => {
    const score = event.target.value;
    updateAssessmentScore(score);
    const todays_date = new Date();
    onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), 's_complete', { completedOn: todays_date, extra: { assessmentScore: score } });
  };

  const handleNoteChange = (e) => setStepNote(e.target.value);
  const resetNoteValue = () => setStepNote(initialStepNote);

  return (
    <form>
      <Grid item container wrap="nowrap">
        <Grid item>
          <AssignmentTurnedInIcon style={{ marginRight: '12px', color: stepColor }} />
        </Grid>
        <Grid item container direction="column">
          <Grid item container wrap="nowrap">
            <Grid item style={{ fontWeight: 'bold', color: stepColor, fontSize: '1rem' }}>
              {step.get('name')}
            </Grid>
          </Grid>
          <Grid item container wrap="nowrap" justify="space-between">
            <Grid item className={classes.descriptionText}>
              <Typography variant="body2" color="primary">
                {showFullDesc ? step.get('description') : truncatedDesc(step.get('description'))}
              </Typography>
            </Grid>
            <Grid item>
              {step.get('description').length > 100 && (
                <Button size="small" color="secondary" className={classes.showMoreButton} onClick={() => toggleShowFullDesc(!showFullDesc)}>
                  {showFullDesc ? 'show less' : 'show more'}
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Step>
        <div className="step-container">
          <div className="step-item">
            {step.get('pathwayStepType') === 'assessment' && (
              <FormControl variant="outlined" style={{ float: 'left' }}>
                <InputLabel>Assessment Score</InputLabel>
                <OutlinedInput margin="dense" onBlur={changeAssessmentScore} value={assessmentScore} />
              </FormControl>
            )}
            {step.get('pathwayStepType') === 'document' && (
              <FormControl variant="outlined" style={{ float: 'left' }}>
                <InputLabel>Upload Document</InputLabel>
                <CandidateStepFileUpload
                  candidatePathwayId={candidatePathwayId}
                  pisId={pathwayInstanceStep.get('id')}
                  form={`step-upload-${step.get('id')}-${candidatePathwayId}`}
                />
                {pathwayInstanceStep.get('fileUrl') !== null && (
                  <a target="_blank" rel="noopener noreferrer" href={pathwayInstanceStep.get('fileUrl')}>
                    Download doc
                  </a>
                )}
              </FormControl>
            )}
            {(step.get('pathwayStepType') === 'action' || step.get('pathwayStepType') === 'event') && <div />}
          </div>
          <div className="step-info">
            <div style={{ display: 'flex', flex: 1, padding: '5px 20px 5px 0px' }}>
              <div className="step-date">
                <MuiDatePicker
                  input={{ onChange: changeTargetCompletionDate, value: targetCompletionDate }}
                  variant="outlined"
                  name={`targetCompletionDate-${step.get('id')}-${candidatePathwayId}`}
                  margin="dense"
                  fullWidth
                  label="Target End Date"
                  className={classes.stepSelect}
                />
              </div>
              <ArrowRightAltIcon className="step-arrow" />
              <div item className="step-date">
                <MuiDatePicker
                  input={{ onChange: changeCompletedOn, value: completedOn }}
                  variant="outlined"
                  name={`completedOn-${step.get('id')}-${candidatePathwayId}`}
                  margin="dense"
                  fullWidth
                  label="Actual End Date"
                  className={classes.stepSelect}
                />
              </div>

              <Note>
                <div item className="note-container">
                  <InputLabel>Note</InputLabel>
                  <TextField multiline rowsMax={4} value={stepNote} variant="outlined" onChange={handleNoteChange} />
                  {stepNote !== initialStepNote && (
                    <div className="btn-container">
                      <Button onClick={onNoteSave} size="small" variant="contained" color="primary">
                        Save Note
                      </Button>
                      <Button onClick={resetNoteValue} size="small">Reset</Button>
                    </div>
                  )}
                </div>
              </Note>
            </div>
            <div className="status-container">
              {pathwayInstanceStep.get('staffState') === 's_complete' && <img src="/icons/check-green-circle.png" alt="status-indicator" />}

              <FormControl>
                <InputLabel>Status</InputLabel>
                {/* <Select variant="outlined" color="primary" margin="dense" className={classes.stepSelect} value="applied">
                  <MenuItem value="applied">Applied</MenuItem>
                  <MenuItem value="inProgress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="withdrawn">Withdrawn</MenuItem>
                </Select> */}
                <Select
                  className={classes.stepSelect}
                  color="primary"
                  margin="dense"
                  variant="outlined"
                  value={pathwayInstanceStep.get('staffState')}
                  onChange={(e) => onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), e.target.value)}
                >
                  {candidateStates.map((candidateState) => (
                    <MenuItem key={candidateState.value} value={candidateState.value}>
                      {candidateState.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
      </Step>
    </form>
  );
};

SingleStepForm.propTypes = {
  step: PropTypes.instanceOf(Map).isRequired,
  pathwayInstanceStep: PropTypes.instanceOf(Map).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTransition: PropTypes.func.isRequired,
  candidatePathwayId: PropTypes.string.isRequired,
};
SingleStepForm.defaultProps = {};

// const SingleStep = reduxForm({ form: 'candidateStepForm' })(SingleStepForm);

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});
const mapDispatchToProps = {
  updatePathwayInstanceStep,
  transitionPathwayStepStaffState,
  fetchCandidatePathway,
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onUpdate: (candidatePathwayId, id, pis) => {
    dispatchProps
      .updatePathwayInstanceStep(stateProps.currentUser, id, pis)
      .then(() => dispatchProps.fetchCandidatePathway(stateProps.currentUser, candidatePathwayId));
  },
  onTransition: (candidatePathwayId, id, state, extra) => {
    dispatchProps
      .transitionPathwayStepStaffState(stateProps.currentUser, id, state.substring(2), extra)
      .then(() => dispatchProps.fetchCandidatePathway(stateProps.currentUser, candidatePathwayId));
  },
  ...ownProps,
  ...stateProps,
});
export const SingleStep = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SingleStepForm);

const instanceMatchesStep = (pathwayInstanceStep, pathwayStep) =>
  pathwayInstanceStep.get('pathwayStepId') === pathwayStep.get('id') &&
  pathwayInstanceStep.getIn(['pathwayMilestone', 'id']) === pathwayStep.get('pathwayMilestoneId');

const CandidateStepView = ({ milestone, pathwayInstanceSteps, candidatePathwayId }) => {
  const classes = useStyles();

  // check if this is going to be a blank one, and if so, don't render it.
  const somethingVisible = milestone
    .getIn(['pathwayMilestone', 'pathwaySteps'])
    .find((pathwayStep) => pathwayInstanceSteps.find((pathwayInstanceStep) => instanceMatchesStep(pathwayInstanceStep, pathwayStep)));

  if (!somethingVisible) {
    return null;
  }

  return (
    <div>
      <MilestoneCard elevation={2}>
        <Grid container direction="column">
          <Grid item className={classes.bannerContainer}>
            <Banner>{milestone.getIn(['pathwayMilestone', 'name'])}</Banner>
          </Grid>
          <Grid item container spacing={3} direction="column">
            {milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map((step) => {
              const foundStep = pathwayInstanceSteps.find((pathwayInstanceStep) => instanceMatchesStep(pathwayInstanceStep, step));
              if (foundStep && step) {
                return <SingleStep step={step} candidatePathwayId={candidatePathwayId} pathwayInstanceStep={foundStep} key={step.get('id')} />;
              }
              return '';
            })}
          </Grid>
        </Grid>
      </MilestoneCard>
    </div>
  );
};

CandidateStepView.propTypes = {
  milestone: PropTypes.instanceOf(Map).isRequired,
  candidatePathwayId: PropTypes.string.isRequired,
  pathwayInstanceSteps: PropTypes.instanceOf(List).isRequired,
};
CandidateStepView.defaultProps = {};

export default CandidateStepView;
