/* eslint-disable react/jsx-no-target-blank */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import React, { Fragment, useEffect } from 'react';

import PublicContainerStyles, { TopBar, Footer, Logo } from './public_container_styles';

const propTypes = {
  children: PropTypes.node.isRequired,
  signedIn: PropTypes.bool.isRequired,
  isRisekit: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
  resetRedirectPath: PropTypes.func,
  setOnboardingMode: PropTypes.func,
};

const defaultProps = {
  redirectPath: '/',
  resetRedirectPath: () => {},
  setOnboardingMode: () => {},
};

const PublicContainerView = ({ signedIn, children, isRisekit, redirectPath, resetRedirectPath, setOnboardingMode }) => {
  useEffect(() => {
    return () => {
      resetRedirectPath();
    };
  }, [resetRedirectPath]);

  let authLinks;
  if (signedIn) {
    authLinks = (
      <li>
        <Link to="/signout">
          <Button variant="outlined" className="secondary-outlined" size="large">
            Log Out
          </Button>
        </Link>
      </li>
    );
  } else {
    authLinks = (
      <Fragment>
        <li>
          <Link
            to={{
              pathname: '/signin',
              state: { returnTo: redirectPath },
            }}
          >
            <Button variant="outlined" className="secondary-outlined" size="large">
              Log in
            </Button>
          </Link>
        </li>
        <li>
          <Link to="/register">
            <Button onClick={() => setOnboardingMode('')} variant="outlined" className="action-outlined" size="large">
              Join for FREE
            </Button>
          </Link>
        </li>
      </Fragment>
    );
  }
  return (
    <PublicContainerStyles>
      <TopBar className={isRisekit && 'risekit-bar'}>
        <Link to="/">
          <Logo>
            {isRisekit ? (
              <img src="/images/risekit.png" alt="Risekit Logo" height="30" />
            ) : (
              <img src="/images/solve_logo_white@2x.png" alt="RiseKit Logo" height="40" />
            )}
          </Logo>
        </Link>
        <ul>{authLinks}</ul>
      </TopBar>
      {children}
      <Footer>
        <ul>
          <li>
            <a href="https://calendly.com/solveis/demo-for-solve" target="_blank">
              Request a Demo
            </a>
          </li>
          <li>
            <a href="https://www.youtube.com/channel/UClzojKXuB0EnBYc8j2moCWA" target="_blank">
              Testimonials
            </a>
          </li>
          <li>
            <a href="https://www.risekit.co/terms">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://www.risekit.co/privacy">Privacy Policy</a>
          </li>
        </ul>
        <ul className="social">
          <li>
            <a href="https://www.facebook.com/risekit" target="_blank">
              Facebook
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/16164498" target="_blank">
              LinkedIn
            </a>
          </li>
          <li>
            <a href="http://www.twitter.com/rise_kit" target="_blank">
              Twitter
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/rise.kit/" target="_blank">
              Instagram
            </a>
          </li>
        </ul>
      </Footer>
    </PublicContainerStyles>
  );
};

PublicContainerView.propTypes = propTypes;
PublicContainerView.defaultProps = defaultProps;

export default PublicContainerView;
