import React, { forwardRef, useMemo, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import Badge from '@material-ui/core/Badge';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// icons
import MenuIcon from '@material-ui/icons/Menu';
// import HelpIcon from '@material-ui/icons/Help';
// import HomeIcon from '@material-ui/icons/Home';
// import NotificationsIcon from '@material-ui/icons/Notifications';
// import PeopleIcon from '@material-ui/icons/People';
// import SearchIcon from '@material-ui/icons/Search';
// import WorkIcon from '@material-ui/icons/Work';
// import ShowChartIcon from '@material-ui/icons/ShowChart';
// import MailIcon from '@material-ui/icons/Mail';

// styles & themes
import appTheme from '../../../common/theme';
import ContainerStyles, { DrawerStyles } from './container_styles';
import UpgradeWidget from '../../../staff/components/upgrade_widget';
import UserModel from '../../../session/models/user_model';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: drawerWidth,
    background: appTheme.color.primary,
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  risekitAppBar: {
    marginLeft: drawerWidth,
    background: appTheme.color.riseKitBlack,
    boxShadow: 'none',
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: appTheme.color.secondary,
    color: '#fff',
    iconColor: '#fff',
  },
  menuPaper: {
    top: '55px !important',
    right: '25px',
    maxWidth: '200px',
    width: '100%',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
}));

const BASE_CANDIDATE_MENU = [
  { title: 'Home/Dashboard', img: '/icons/mobile/home.png', to: '/candidate/dashboard' },
  { title: 'Jobs', img: '/icons/mobile/jobs.png', to: '/candidate/jobs' },
  { title: 'Resources', img: '/icons/mobile/programs.png', to: '/candidate/resources' },
  { title: 'Profile', img: '/icons/mobile/profile.png', to: '/candidate/profile' },
  { title: 'Messages', img: '/icons/mobile/messages.png', to: '/candidate/messages' },
];

const BASE_STAFF_MENU = [
  { title: 'Home', img: '/icons/home.png', to: '/staff/dashboard' },
  { title: 'Inbox', img: '/icons/inbox.png', to: '/staff/messages' },
  { title: 'Candidates', img: '/icons/clients.png', to: '/staff/candidates' },
  { title: 'Jobs', img: '/icons/jobs.png', to: '/staff/jobs' },
  { title: 'Resources', img: '/icons/programs.png', to: '/staff/resources' },
  { title: 'Reporting', img: '/icons/chart.png', to: '/staff/reporting' },
  { title: 'Invite Staff', img: '/icons/clients.png', to: '/staff/invite' },
  // { title: 'Pathways', img: '/icons/impact.png', to: '/staff/pathways' },
];

const OPTIONAL_MENU_ITEMS = {
  staffPathwayMenuItem: { title: 'Pathways', img: '/icons/impact.png', to: '/staff/pathways' },
  candidatePathwayMenuItem: { title: 'Pathways', img: '/icons/impact.png', to: '/candidate/pathways' },
};

const BASE_EMPLOYER_MENU = [
  { title: 'Home', img: '/icons/home.png', to: '/staff/dashboard' },
  { title: 'Inbox', img: '/icons/inbox.png', to: '/staff/messages' },
  { title: 'Candidates', img: '/icons/clients.png', to: '/staff/candidates' }, // TODO: redirect elsewhere for employers?
  { title: 'Jobs', img: '/icons/jobs.png', to: '/staff/jobs' },
  { title: 'Resources', img: '/icons/programs.png', to: '/staff/resources' },
  { title: 'Reporting', img: '/icons/chart.png', to: '/staff/reporting' },
  { title: 'Invite Staff', img: '/icons/invite.png', to: '/staff/invite' },
];

const DrawerItem = ({ title, img, to }) => {
  // eslint-disable-next-line react/jsx-props-no-spreading,react/display-name
  const renderMyLink = useMemo(() => forwardRef((linkProps, ref) => <Link to={to} {...linkProps} innerRef={ref} />), [to]);
  return (
    <ListItem button key="Home" component={renderMyLink}>
      <ListItemIcon style={{ display: 'block' }}>
        <img src={img} alt={title} className="icon" />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};
DrawerItem.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

function ContainerView({
  userType,
  userName,
  currentUser,
  companyName,
  onboarding,
  signedIn,
  children,
  showPathways,
  candidate,
  fetchCandidate,
  isRisekit,
  cobrandingLogoUrl,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  useEffect(() => {
    if (userType === 'candidate' && currentUser.get('candidateId') && !candidate) {
      fetchCandidate(currentUser, currentUser.get('candidateId'));
    }
  }, [userType, candidate, fetchCandidate, currentUser]);

  useLayoutEffect(() => {
    if (signedIn) {
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);
      return () => {
        document.body.removeChild(script);
      };
    }
    return undefined;
  }, [signedIn]);

  // Send user info to FullStory integration
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_USE_FULL_STORY === 'yes' && global.FullStory && signedIn) {
      const fullStoryUserType = userType === 'staff' ? 'nonprofit' : userType;
      global.FullStory.identify(currentUser.get('id'), {
        userType: fullStoryUserType,
        displayName: userName,
        email: currentUser.get('email'),
      });
      if (userType === 'staff' || userType === 'employer') {
        global.FullStory.setUserVars({
          organizationName: currentUser.getIn(['staffOrganization', 'name']),
        });
      }
      if (userType === 'candidate' && candidate?.get('howFoundReferralName')) {
        global.FullStory.setUserVars({
          referringOrganizationName: candidate.get('howFoundReferralName'),
        });
      }
    }
  }, [signedIn, currentUser, userName, candidate, userType]);

  const [profileAnchorEl, setProfileAnchorEl] = React.useState(undefined);

  const handleProfileMenuOpen = (e) => {
    setProfileAnchorEl(e.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  function buildMenu(baseMenu, optionalItems) {
    let menu = baseMenu;
    if (showPathways) {
      menu = menu.concat(optionalItems);
    }
    return menu;
  }

  const buildStaffMenu = () => buildMenu(BASE_STAFF_MENU, OPTIONAL_MENU_ITEMS.staffPathwayMenuItem);

  const buildCandidateMenu = () => buildMenu(BASE_CANDIDATE_MENU, OPTIONAL_MENU_ITEMS.candidatePathwayMenuItem);

  const buildEmployerMenu = () => buildMenu(BASE_EMPLOYER_MENU, OPTIONAL_MENU_ITEMS.staffPathwayMenuItem);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  let drawerItems;
  let helpLink;
  let upgradeWidget = null;
  switch (userType) {
    case 'candidate':
      drawerItems = buildCandidateMenu();
      helpLink = 'https://risekit.zendesk.com/hc/en-us';
      break;
    case 'staff':
      drawerItems = buildStaffMenu();
      upgradeWidget = <UpgradeWidget />;
      helpLink = 'https://risekit.zendesk.com/hc/en-us';
      break;
    case 'employer':
      drawerItems = buildEmployerMenu();
      upgradeWidget = <UpgradeWidget />;
      helpLink = 'https://risekit.zendesk.com/hc/en-us';
      break;
    default:
      drawerItems = BASE_STAFF_MENU;
      helpLink = 'https://risekit.zendesk.com/hc/en-us';
  }

  const helpIcon = (
    <div className="bottom">
      <a href={helpLink} target="blank" rel="noopener noreferrer">
        <img src="/icons/help.png" alt="Help" />
      </a>
    </div>
  );

  const drawer = (
    <DrawerStyles onClick={() => setMobileOpen(false)}>
      <div className="drawerTop">
        {cobrandingLogoUrl ? (
          <div className={`${classes.toolbar} ${classes.sectionDesktop} logo cobranding-logo`}>
            <Link to="/">
              <img src={cobrandingLogoUrl} alt="Powered by Risekit" />
            </Link>
          </div>
        ) : (
          <div className={isRisekit ? `${classes.toolbar} ${classes.sectionDesktop} logo risekit-logo` : `${classes.toolbar} ${classes.sectionDesktop} logo`}>
            <Link to="/">{isRisekit ? <img src="/images/risekit.png" alt="Risekit" /> : <img src="/images/logo.png" alt="RiseKit" />}</Link>
          </div>
        )}
        <Divider className={classes.sectionDesktop} />
        {onboarding ? null : (
          <List>
            {drawerItems.map((item) => (
              <DrawerItem key={item.title} title={item.title} img={item.img} to={item.to} />
            ))}
          </List>
        )}
        {!onboarding && upgradeWidget}
      </div>
      <div className="drawerBottom">
        {helpIcon}
        {userType === 'candidate' ? (
          <div>
            <p style={{ color: 'white' }}>Preferred language</p>
            <div id="google_translate_element" />
          </div>
        ) : null}
      </div>
    </DrawerStyles>
  );

  return (
    <ContainerStyles className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={isRisekit ? classes.risekitAppBar : classes.appBar}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <div className={classes.sectionMobile}>
            {cobrandingLogoUrl ? (
              <div className={`${classes.toolbar} logo cobranding-logo`}>
                <img src={cobrandingLogoUrl} alt="Powered by Risekit" />
              </div>
            ) : (
              <div className={`${classes.toolbar} logo`}>
                {isRisekit ? <img src="/images/risekit.png" alt="Risekit" /> : <img src="/images/logo.png" alt="RiseKit" />}
              </div>
            )}
          </div>
          <div className={classes.grow} />

          {/* <Notifications>
            <Link to="/">
              <img src="/icons/flag.png" alt="notifications" />
              <div className="bubble">7</div>
            </Link>
          </Notifications> */}

          {signedIn && (
            <div>
              {/* <IconButton aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="secondary">
                <MailIcon />
              </Badge>
            </IconButton> */}
              {/* <IconButton aria-label="show 17 new notifications" color="inherit"> */}
              {/*  <Badge badgeContent={17} color="secondary" anchorOrigin={{ horizontal: 'left', vertical: 'top' }}> */}
              {/*    <img src="/icons/flag.png" alt="Notifications" height="22" style={{ marginLeft: 12 }} /> */}
              {/*  </Badge> */}
              {/* </IconButton> */}
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls="account-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                style={{ borderRadius: '5px' }}
              >
                <AccountCircle style={{ fontSize: 34 }} />
                <div className={`member-info ${classes.sectionDesktop}`}>
                  <div className="member-name">{userName === 'TBD' ? '' : userName}</div>
                  {companyName && <div className="member-company">{companyName}</div>}
                  {/* <div className="member-tagline">Leave No Adult Behind...</div> */}
                </div>
              </IconButton>
              <Menu
                id="profile-menu"
                anchorEl={profileAnchorEl}
                keepMounted
                open={Boolean(profileAnchorEl)}
                onClose={handleProfileMenuClose}
                classes={{ paper: classes.menuPaper }}
              >
                <MenuItem>
                  <Link to="/settings" style={{ width: '100%' }}>
                    Profile Settings
                  </Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/notifications-settings" style={{ width: '100%' }}>
                    Notifications Settings
                  </Link>
                </MenuItem>
                {currentUser?.isOrgAdmin && (
                  <React.Fragment>
                    <MenuItem>
                      <Link to="/staff/manage" style={{ width: '100%' }}>
                        Manage Staff
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link to="/staff/organization-profile" style={{ width: '100%' }}>
                        Organization Profile
                      </Link>
                    </MenuItem>
                  </React.Fragment>
                )}
                <MenuItem>
                  <Link to="/signout" style={{ width: '100%' }}>
                    Sign Out
                  </Link>
                </MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            className={isRisekit ? 'risekit-drawer' : ''}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            className={isRisekit ? 'risekit-drawer' : ''}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className="ContainerContent">
        <div className={classes.toolbar} />
        {children}
      </main>
    </ContainerStyles>
  );
}

ContainerView.propTypes = {
  userType: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map),
  fetchCandidate: PropTypes.func.isRequired,
  companyName: PropTypes.string,
  children: PropTypes.node,
  onboarding: PropTypes.bool,
  signedIn: PropTypes.bool,
  showPathways: PropTypes.bool,
  isRisekit: PropTypes.bool.isRequired,
  cobrandingLogoUrl: PropTypes.string,
};

ContainerView.defaultProps = {
  candidate: undefined,
  children: undefined,
  companyName: undefined,
  onboarding: false,
  signedIn: false,
  showPathways: false,
  cobrandingLogoUrl: undefined,
};

export default ContainerView;
