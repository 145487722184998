// import { fromJS } from 'immutable';
import validator from 'validator';

const externalValidate = (values) => {
  const errors = {};

  if (values.get('channel') === null || values.get('channel') === undefined) {
    errors.channel = 'Required';
  }

  if (values.get('channel') === 'phone') {
    if (!values.get('toPhone')) {
      errors.toPhone = 'Required';
    } else if (!values.get('toPhone').match(/^\d+$/) || values.get('toPhone').length !== 10) {
      errors.toPhone = 'Phone number must be 10 digits';
    } else if (!values.get('toPhone').match(/^([2-9][0-9]{2}){2}[0-9]{4}$/)) {
      errors.toPhone = 'Phone is invalid';
    }
  }

  if (values.get('channel') === 'email') {
    if (!values.get('toEmail')) {
      errors.toEmail = 'Required';
    } else if (!validator.isEmail(values.get('toEmail'))) {
      errors.toEmail = 'Invalid Email';
    }
  }

  if (values.get('channel') === 'existing') {
    if (!values.get('toCandidate')) {
      errors.toCandidate = 'Required';
    }
    if (!values.get('contactMethod')) {
      errors.contactMethod = 'Required';
    }
  }

  return errors;
};

export default externalValidate;
