import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const propTypes = {
  userType: PropTypes.string.isRequired,
};

const defaultProps = {};

const DashboardView = ({ userType }) => {
  let destination;
  switch (userType) {
    case 'candidate':
      destination = '/candidate/dashboard';
      break;
    case 'staff':
      destination = '/staff/dashboard';
      break;
    case 'employer':
      destination = '/staff/dashboard';
      break;
    default:
      destination = '/welcome';
  }
  return <Redirect to={destination} />;
};

DashboardView.propTypes = propTypes;
DashboardView.defaultProps = defaultProps;

export default DashboardView;
