import styled from 'styled-components';
// import theme from '../../../common/theme';

const MessagesListInboxStyles = styled.div`
  .new-message {
    float: right;
  }
  .count-heading {
    color: #7dbf9d;
    margin: 20px 0px;
  }
`;

export const ReadSection = styled.div`
  margin-bottom: 50px;
`;

export default MessagesListInboxStyles;
