import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OrderedSet } from 'immutable';
import CheckedIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ReplyIcon from '@material-ui/icons/Reply';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Tile from '../../../messaging/components/tile';
import { TextHeading, Button } from '../../../common/components';
import MessagesListInboxStyles, { ReadSection } from './messages_list_inbox_styles';
import { useDebouncedEffect } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  unreadMessages: PropTypes.instanceOf(OrderedSet).isRequired,
  unreadMessagesCount: PropTypes.number.isRequired,
  readMessages: PropTypes.instanceOf(OrderedSet).isRequired,
  readMessagesCount: PropTypes.number.isRequired,
  listMessages: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

const defaultProps = {};

function sortMessages(messages, newSearch) {
  return messages.filter((message) =>
    `${message.get('messageSubject')} ${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`
      .toLowerCase()
      .includes(newSearch.toLocaleLowerCase()),
  );
}

function useSearch(search, setSearch) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const MessagesListInboxView = ({ listMessages, currentUser, unreadMessages, unreadMessagesCount, readMessages, readMessagesCount, onUpdate }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [unreadMessagesResults, setUnreadMessagesResults] = useState([]);
  const [readMessagesResults, setReadMessagesResults] = useState([]);

  const { newSearch, onSearchChange } = useSearch(searchTerm, setSearchTerm);

  useEffect(() => {
    listMessages(currentUser);
  }, [listMessages, currentUser]);

  useEffect(() => {
    if (!searchTerm) {
      setReadMessagesResults(readMessages);
      setUnreadMessagesResults(unreadMessages);
      return;
    }
    const unreadResults = sortMessages(unreadMessages, searchTerm);
    const readResults = sortMessages(readMessages, searchTerm);
    setReadMessagesResults(readResults);
    setUnreadMessagesResults(unreadResults);
  }, [searchTerm, readMessages, unreadMessages]);

  return (
    <MessagesListInboxStyles>
      <Link to="/candidate/messages/new" className="new-message">
        <Button buttonType="primary">+ Send A New Message</Button>
      </Link>
      <TextField
        id="search"
        placeholder="Search Messages"
        margin="dense"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 10 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={newSearch}
        onChange={onSearchChange}
      />

      <ReadSection>
        {unreadMessagesCount > 0 ? (
          <TextHeading size="large" weight="bold" className="count-heading">
            {unreadMessagesCount} Unread Message{unreadMessagesCount <= 1 ? '' : 's'}
          </TextHeading>
        ) : (
          <TextHeading size="large" weight="bold" className="count-heading">
            No Unread Messages
          </TextHeading>
        )}
        {unreadMessagesResults &&
          unreadMessagesResults.map((message) => (
            <Tile
              key={message.get('id')}
              backgroundColor="#F7FCFF"
              color="#7DBF9D"
              icon="/icons/avatar.svg"
              iconColor="#F1F0F0"
              border="solid 1px #b8c0bc"
              title={`${message.getIn(['toUser', 'firstName'])} ${message.getIn(['toUser', 'lastName'])}`}
              subTitle={`${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`}
              subject={message.get('messageSubject')}
              body={message.get('messageBody')}
              createdAt={message.get('createdAt')}
              toolbarButtons={[
                <IconButton color="inherit" size="small" key={`${message.get('id')} check`} onClick={() => onUpdate(message.get('id'), { isRead: true })}>
                  <CheckIcon />
                </IconButton>,
                <IconButton
                  color="inherit"
                  size="small"
                  key={`${message.get('id')} star`}
                  onClick={() => onUpdate(message.get('id'), { isStarred: !message.get('isStarred') })}
                >
                  {message.get('isStarred') ? <StarIcon style={{ color: '#52CA5B' }} /> : <StarBorderIcon style={{ color: '#b8c0bc' }} />}
                </IconButton>,
                <Link key={`${message.get('id')} reply`} to={`/candidate/messages/${message.get('id')}`}>
                  <IconButton size="small" style={{ minWidth: '40px', padding: '3px', borderRadius: '0px', color: '#b8c0bc' }}>
                    <ReplyIcon />
                  </IconButton>
                </Link>,
                <IconButton key={`${message.get('id')} delete`} color="inherit" size="small" onClick={() => onUpdate(message.get('id'), { isDeleted: true })}>
                  <DeleteForeverIcon />
                </IconButton>,
              ]}
            />
          ))}
      </ReadSection>
      <ReadSection>
        {readMessagesCount > 0 ? (
          <TextHeading size="large" weight="bold" className="count-heading">
            {readMessagesCount} Old Message{readMessagesCount <= 1 ? '' : 's'}
          </TextHeading>
        ) : (
          <TextHeading size="large" weight="bold" className="count-heading">
            No Read Messages
          </TextHeading>
        )}
        {readMessagesResults &&
          readMessagesResults.map((message) => (
            <Tile
              key={message.get('id')}
              backgroundColor="#F7FCFF"
              color="#7DBF9D"
              icon="/icons/avatar.svg"
              iconColor="#F1F0F0"
              border="solid 1px #b8c0bc"
              title={`${message.getIn(['toUser', 'firstName'])} ${message.getIn(['toUser', 'lastName'])} `}
              subTitle={`${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])} `}
              subject={message.get('messageSubject')}
              body={message.get('messageBody')}
              createdAt={message.get('createdAt')}
              toolbarButtons={[
                <IconButton color="inherit" size="small" key={`${message.get('id')} check`} onClick={() => onUpdate(message.get('id'), { isRead: false })}>
                  <CheckedIcon style={{ color: '#52CA5B' }} />
                </IconButton>,
                <IconButton
                  color="inherit"
                  size="small"
                  key={`${message.get('id')} star`}
                  onClick={() => onUpdate(message.get('id'), { isStarred: !message.get('isStarred') })}
                >
                  {message.get('isStarred') ? <StarIcon style={{ color: '#52CA5B' }} /> : <StarBorderIcon style={{ color: '#b8c0bc' }} />}
                </IconButton>,
                <Link key={`${message.get('id')} reply`} to={`/candidate/messages/new?id=${message.get('id')}`}>
                  <IconButton size="small" style={{ minWidth: '40px', padding: '3px', borderRadius: '0px', color: '#b8c0bc' }}>
                    <ReplyIcon />
                  </IconButton>
                </Link>,
                <IconButton color="inherit" size="small" key={`${message.get('id')} delete`} onClick={() => onUpdate(message.get('id'), { isDeleted: true })}>
                  <DeleteForeverIcon />
                </IconButton>,
              ]}
            />
          ))}
      </ReadSection>
    </MessagesListInboxStyles>
  );
};

MessagesListInboxView.propTypes = propTypes;
MessagesListInboxView.defaultProps = defaultProps;

export default MessagesListInboxView;
