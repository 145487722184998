import { connect } from 'react-redux';
import ApplyToServiceView from './apply_to_service_view';
import { getCurrentUser, getService, getExternalCandidateServiceByResourceId, getCandidate } from '../../../reducer';
import { fetchSupportiveService, createExternalCandidateService, fetchABService, fetchExternalCandidateServicesServices } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: serviceId },
    },
  },
) => {
  const externalCandidateService = getExternalCandidateServiceByResourceId(state, serviceId);
  const currentUser = getCurrentUser(state);
  const candidate = getCandidate(state, currentUser.get('candidateId'));

  return {
    currentUser,
    candidate,
    service: getService(state, serviceId),
    serviceId,
    externalCandidateService,
  };
};

const mapDispatchToProps = {
  fetchSupportiveService,
  fetchABService,
  createExternalCandidateService,
  listExternalCandidateServices: fetchExternalCandidateServicesServices,
};

const ApplyToServiceContainer = connect(mapStateToProps, mapDispatchToProps)(ApplyToServiceView);

export default ApplyToServiceContainer;
