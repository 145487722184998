import styled from 'styled-components';
// import theme from '../../../common/theme';

const SelectOnboardingStyles = styled.div`
  min-height: 200px;
  margin: 0 auto;
  text-align: center;

  .button-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  button {
    margin: 10px 10px 10px;
  }
  @media only screen and (max-width: 800px) {
    .button-group {
      flex-direction: column;
    }
    button {
      margin: 10px auto 10px;
      max-width: 300px;
      width: 100%;
    }
  }
`;

export default SelectOnboardingStyles;
