import { connect } from 'react-redux';
import ApplicationListItemView from './application_list_item_view';
import { getCandidateApplication, getCurrentUser } from '../../reducer';
import { transitionCandidateApplicationByStaff } from '../../solve/actions';

const mapStateToProps = (state, { candidateApplicationId }) => ({
  currentUser: getCurrentUser(state),
  application: getCandidateApplication(state, candidateApplicationId),
  canEdit: getCurrentUser(state).permissions.includes('edit_applications'),
});

const mapDispatchToProps = {
  transitionCandidateApplicationByStaff,
};

const ApplicationListItemContainer = connect(mapStateToProps, mapDispatchToProps)(ApplicationListItemView);

export default ApplicationListItemContainer;
