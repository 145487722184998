/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import React, { useEffect } from 'react';

import UserModel from 'session/models/user_model';

import ProgramPreviewStyles from './program_preview_styles';
import { MainStyles, MainCard, Logo, MainActions } from '../../../../common/styles/detail';
import getListingImage from '../../../../common/components/get_listing_image';
import ProgramDetails from '../../../../programs/components/program_details';
import PreviewButtons from '../../posting_previews/preview_buttons';

const propTypes = {
  invitation: PropTypes.instanceOf(Map).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  setRedirectPath: PropTypes.func.isRequired,
};

const defaultProps = {};

const ProgramPreviewView = ({ invitation, currentUser, setRedirectPath }) => {
  const isCurrentUserSignedIn = currentUser?.signedIn;

  const program = invitation.get('recommendedProgram') || new Map();

  const logoUrl = getListingImage(program, 'program');

  const redirectPath = `/candidate/programs/${program.get('id')}`;

  useEffect(() => {
    setRedirectPath(redirectPath);
  }, [redirectPath, setRedirectPath]);

  if (isCurrentUserSignedIn) {
    return <Redirect to={redirectPath} />;
  }

  return (
    <ProgramPreviewStyles>
      {program && (
        <MainStyles noMargin>
          <div className="candidate-wrapper">
            <MainCard>
              <MainActions>
                <Logo>
                  <img src={logoUrl} alt="Program Logo" />
                </Logo>
                <PreviewButtons previewType="program" redirectPath={redirectPath} />
              </MainActions>

              <ProgramDetails program={program} />
            </MainCard>
          </div>
        </MainStyles>
      )}
    </ProgramPreviewStyles>
  );
};

ProgramPreviewView.propTypes = propTypes;
ProgramPreviewView.defaultProps = defaultProps;

export default ProgramPreviewView;
