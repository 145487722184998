import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Map, List } from 'immutable';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { reduxForm } from '../../../../../common/form';

import { Card } from '../../../../../common/styles/layout';
import { Button, TextHeading } from '../../../../../common/components';

import ReviewFormStyles from './review_form_styles';

const ReviewForm = ({ handleSubmit, previousPage, pathwayInstance, stepDates, candidates }) => {
  const pathway = pathwayInstance.get('pathway');
  return (
    <ReviewFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <TextHeading size="medium" weight="bold" margin="both" uppercase>
              Review your pathway details
            </TextHeading>
            <Card>
              <div>
                <Typography variant="h6" color="primary" gutterBottom>
                  Candidates Added
                </Typography>
                <div>
                  {candidates &&
                    candidates.map((candidate) => (
                      <div key={candidate[0]}>
                        <Typography component="span">{candidate[1]}</Typography>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <Typography variant="h6" color="primary" gutterBottom>
                  Pathway Step Information
                </Typography>
                <div>
                  {pathway.get('pathwayMilestones').map((milestone) => (
                    <div key={milestone.getIn(['pathwayMilestone', 'id'])}>
                      {milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map(
                        (step) =>
                          step.get('pathwayStepCategoryType') !== 'pathway' && (
                            <div key={step.get('id')}>
                              <Typography component="span">{`${step.get('name')}:  `}</Typography>
                              <Typography component="span">{stepDates[step.get('id')] && moment(stepDates[step.get('id')]).format('DD MMMM YYYY')}</Typography>
                            </div>
                          ),
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
        <div className="button-group">
          <div className="button-left">
            <Button buttonType="secondary-outline" type="button" onClick={previousPage}>
              Previous
            </Button>
          </div>
          <div className="button-right">
            <Button buttonType="secondary" type="submit">
              Add Candidates
            </Button>
          </div>
        </div>
      </form>
    </ReviewFormStyles>
  );
};

ReviewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  candidates: PropTypes.instanceOf(List).isRequired,
  stepDates: PropTypes.objectOf(Date),
};
ReviewForm.defaultProps = {
  stepDates: new Date(),
};

const ReviewFormView = reduxForm({
  form: 'selectCandidatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ReviewForm);

export default ReviewFormView;
