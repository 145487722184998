import { connect } from 'react-redux';
import EditingView from './editing_view';
import { formValueSelector } from '../../../../../common/form';
import { getEnumsForFormSelect } from '../../../../../reducer';
// import { SomeSelectors } from '../../../../../reducer';
// import { SomeActions } from '../../actions';

const selector = formValueSelector('resumeForm');

// mapStateToProps(state, { prop })
const mapStateToProps = (state, { namespace }) => {
  const isCurrent = selector(state, `${namespace}.education.isCurrent`);

  return {
    educationLevelOptions: getEnumsForFormSelect(state, 'EducationLevelType'),
    isCurrent,
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const EditingContainer = connect(mapStateToProps, mapDispatchToProps)(EditingView);

export default EditingContainer;
