import { connect } from 'react-redux';
import CreateProgramView from './create_program_view';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const CreateProgramContainer = connect(mapStateToProps, mapDispatchToProps)(CreateProgramView);

export default CreateProgramContainer;
