const settingsFormValidate = (values) => {
  const errors = {};
  if (!values.get('firstName')) {
    errors.firstName = 'Please enter your first name';
  }
  if (!values.get('lastName')) {
    errors.lastName = 'Please enter your last name';
  }
  if (values.get('password') && values.get('passwordConfirmation')) {
    if (values.get('password') !== values.get('passwordConfirmation')) {
      errors.passwordConfirmation = 'Both Passwords Must Match';
    }
  }
  return errors;
};

export default settingsFormValidate;
