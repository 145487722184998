import styled from 'styled-components';
// import theme from '../../../common/theme';

const PlanStyles = styled.div`
  padding: 10px calc(0.5vw) 20px calc(0.5vw);
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  min-height: 200px;
  h4,
  h5 {
    margin: 4px 0;
  }
  h4 {
    font-weight: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.secondary};
  }
  h5 {
    font-weight: normal;
    color: #93b0b9;
  }
  h1 {
    /* font-size: calc(1.8vw); */
    letter-spacing: -0.025em;
    margin: 0;
    small {
      font-size: 0.6em;
    }
  }
  button {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export default PlanStyles;
