import { reduxForm } from '../../../../common/form';
import SelectTypeFormView from './select_type_form_view';

const selectTypeFormValidate = (values) => {
  const errors = {};
  if (!values.get('pathwayType')) {
    errors.pathwayType = 'Required';
  }
  if (!values.get('pathwayName')) {
    errors.pathwayName = 'Required';
  }
  if (!values.get('startDate')) {
    errors.pathwayType = 'Required';
  }
  return errors;
};

const SelectTypeFormForm = reduxForm({
  form: 'pathwayForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: selectTypeFormValidate,
})(SelectTypeFormView);

export default SelectTypeFormForm;
