import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import TileView from './tile_view';
import { getCandidateEventForEvent, getCurrentUser, getEvent, getSystemEnumList } from '../../../reducer';
import { transitionCandidateEventByCandidate, createCandidateEvent } from '../../../solve/actions';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

const mapStateToProps = (state, { id }) => {
  const event = getEvent(state, id);
  const candidateEvent = getCandidateEventForEvent(state, id);
  const serviceTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), event?.get('serviceTypes')));
  const targetTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), event?.get('targetParticipantTypes')));

  return {
    id,
    currentUser: getCurrentUser(state),
    event,
    candidateEvent,
    serviceTypes,
    targetTypes,
  };
};

const mapDispatchToProps = {
  createCandidateEvent,
  transitionCandidateEventByCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createCandidateEvent: () => dispatchProps.createCandidateEvent(stateProps.currentUser, stateProps.id),
  deleteCandidateEvent: () => dispatchProps.transitionCandidateEventByCandidate(stateProps.currentUser, stateProps.candidateEvent.get('id'), 'deleted'),
});

const TileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(TileView);

export default TileContainer;
