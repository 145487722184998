import { connect } from 'react-redux';
import SelectOnboardingView from './select_onboarding_view';
import { setOnboardingMode } from '../../../onboarding/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setOnboardingMode,
};

const SelectOnboardingContainer = connect(mapStateToProps, mapDispatchToProps)(SelectOnboardingView);

export default SelectOnboardingContainer;
