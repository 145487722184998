import React from 'react';

import MessagesListInbox from '../messages_list_inbox';
import { TextHeading } from '../../../common/components';
import MessagesListOutbox from '../messages_list_outbox';

const MessageListView = () => (
  <React.Fragment className="container-wrapper">
    <div className="candidate-wrapper">
      <TextHeading size="large" weight="bold" margin="none">
        Inbox
      </TextHeading>
      <MessagesListInbox />
      <TextHeading size="large" weight="bold" margin="both">
        Outbox
      </TextHeading>
      <MessagesListOutbox />
    </div>
  </React.Fragment>
);

export default MessageListView;
