import { useState, useEffect } from 'react';

import { STATUS_CODE } from 'risekit/app/app.status';
import { getModeReportSignedUrl } from 'risekit/api/request.api';
import { ModeReportSignedUrlPayload } from 'risekit/api/api.types';

const useGetModeReportSignedUrl = ({ userAuthToken, reportToken }: ModeReportSignedUrlPayload) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState<boolean>();

  const [signedUrl, setSignedUrl] = useState<string>();

  useEffect(() => {
    setIsLoading(true);

    const fetchModeReportSignedUrl = async () => {
      const { data, error: apiError } = await getModeReportSignedUrl({
        userAuthToken,
        reportToken,
      });

      const isRequestCanceled = apiError?.messages !== STATUS_CODE.REQUEST_CANCEL;

      if (apiError && !isRequestCanceled) {
        setError(apiError);
        setIsLoading(false);
      }

      if (!apiError) {
        setSignedUrl(data);
        setIsLoading(false);
      }
    };

    fetchModeReportSignedUrl();
  }, [userAuthToken, reportToken]);

  return { isLoading, error, signedUrl };
};

export default useGetModeReportSignedUrl;
