import styled from 'styled-components';
import theme from '../../../common/theme';

export const PathwayStepStyles = styled.div``;

export const PathwayStepCandidate = styled.div`
  background-color: #f7f7f7;
  padding: ${theme.padding.base};
  margin-bottom: ${theme.padding.base};

  h4 {
    margin: 0;
    font-family: 'Open Sans', 'Source Sans Pro', sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
  }

  .link-wrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  .candidate-link {
    color: ${theme.color.grayDark};
  }

  .pathway-link {
    margin-left: 25px;
    text-transform: uppercase;
  }

  h4 {
    margin-top: 0;
  }
`;

export const BulkEditPane = styled.div`
  .mr-8 {
    margin-right: 8px;
  }
  .mt-22 {
    margin-top: 22px;
  }
  .edit-pane {
    display: flex;
  }
  .width-140 {
    width: 140px;
  }
  .width-220 {
    width: 220px;
  }
  .edit-pane-container{
    display: flex;
    padding-top: 8px;
    margin-bottom: 16px;
    display: flex;
    height: 100px;
    align-items: flex-start;
  }
  .step-dates-container{
    display: flex;
    width: 80%;
  }
  .edit-pane {
    display: flex;
    width: 100%;
  }
  .status-container {
    margin-right: 80px;
  }
  .step-arrow {
    margin-top: 8px;
    margin: 8px 16px;
  }
`;

export default PathwayStepStyles;
