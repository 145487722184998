import styled from 'styled-components';
// import theme from '../../../common/theme';

const ContactInfoStyles = styled.div`
  .contact-info-note {
    color: rgb(108,122,137);
    font-size: 12px;
    font-style: italic;
    display: flex;
    flex-direction: row-reverse;
  }
`;

export default ContactInfoStyles;
