import moment from 'moment';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import AddPathwayView from './add_pathway_view';
import { getPathwayInstance, getCurrentUser } from '../../../reducer';
import { createPathwayInstance, publishPathwayInstance, bulkCreateCandidatePathways, resetForm } from '../../../solve/actions';

const buildInitialValues = () => fromJS({ startDate: moment(0, 'HH').toDate() });

const buildSubmitValues = (formValues) => ({
  pathwayId: formValues.get('pathwayType'),
  name: formValues.get('pathwayName'),
  startsOn: formValues.get('startDate'),
  description: formValues.get('description'),
  pathwayInstanceStepsAttributes: Object.entries(formValues.get('pathwayStepDates')?.toJS() || {}).map((step) => ({
    pathwayStepId: step[0],
    targetCompletionDate: step[1],
  })),
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  initialValues: buildInitialValues(),
  pathway: id && getPathwayInstance(state, id),
  currentUser: getCurrentUser(state),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
});

const mapDispatchToProps = {
  createPathwayInstance,
  publishPathwayInstance,
  bulkCreateCandidatePathways,
  resetForm,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  handleSaveDraft: (formValues) => {
    const values = buildSubmitValues(formValues);
    return dispatchProps.createPathwayInstance(stateProps.currentUser, stateProps.organizationId, values);
  },
  handleSubmit: (formValues) => {
    const values = buildSubmitValues(formValues);
    return dispatchProps
      .createPathwayInstance(stateProps.currentUser, stateProps.organizationId, values)
      .then((res) => dispatchProps.publishPathwayInstance(stateProps.currentUser, res.result));
  },
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const AddPathwayContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddPathwayView);

export default AddPathwayContainer;
