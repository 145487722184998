import NotificationTemplates from './notification_templates';
import BaseNotificationHelper from './helpers/base_notification_helper';
import TransitionNotificationHelper from './helpers/transition_notification_helper';
import PathwayNotificationHelper from './helpers/pathway_notification_helper';
import CaseNoteNotificationHelper from './helpers/case_note_notification_helper';
import InvitationNotificationHelper from './helpers/invitation_notification_helper';
import QuestionnaireNotificationHelper from './helpers/questionnaire_notification_helper';
import ReplaceNamesNotificationHelper from './helpers/replace_names_notification_helper';
import ResumeNotificationHelper from './helpers/resume_notification_helper';

class NotificationHelperFactory {
  static createHelper(currentUser, notification, notificationLabels) {
    const template = NotificationTemplates.get(notification.get('notificationType'));
    const group = template ? template.group : '';

    const params = { currentUser, notification, notificationLabels };

    switch (group) {
      case 'case_note':
        params.urlTemplate = '<urlNode>/candidates/<id>';
        params.urlIdKey = ['candidate', 'id'];
        return new CaseNoteNotificationHelper(params);
      case 'challenge_transition':
        params.urlTemplate = '<urlNode>/candidate_surveys/<id>';
        params.subjectIdKey = 'candidateSurveyId';
        params.subjectTitleKey = 'challengeName';
        return new TransitionNotificationHelper(params);
      case 'event_transition':
        params.urlTemplate = '<urlNode>/events/<id>';
        params.subjectIdKey = 'eventId';
        params.subjectTitleKey = 'eventTitle';
        return new TransitionNotificationHelper(params);
      case 'external_job_transition':
        params.urlTemplate = '<urlNode>/changeme/<id>';
        params.subjectIdKey = 'externalId';
        params.subjectTitleKey = 'externalTitle';
        return new TransitionNotificationHelper(params);
      case 'helper_transition':
        params.urlTemplate = '<urlNode>/helpers/<id>';
        params.subjectIdKey = 'helperId';
        params.subjectTitleKey = 'helperTitle';
        return new TransitionNotificationHelper(params);
      case 'job_transition':
        params.urlTemplate = '<urlNode>/jobs/<id>';
        params.subjectIdKey = 'jobPostingId';
        params.subjectTitleKey = 'jobPostingTitle';
        return new TransitionNotificationHelper(params);
      case 'pathway_transition':
        params.urlTemplate = '<urlNode>/pathways/<id1>/candidates/<id2>';
        params.subjectIdKey = 'pathwayInstanceId';
        params.subjectTitleKey = 'pathwayInstanceName';
        return new PathwayNotificationHelper(params);
      case 'program_transition':
        params.urlTemplate = '<urlNode>/programs/<id>';
        params.subjectIdKey = 'programId';
        params.subjectTitleKey = 'programTitle';
        return new TransitionNotificationHelper(params);
      case 'event_invitation':
        params.urlTemplate = '<urlNode>/events/<id>';
        params.recommendedParent = 'event';
        return new InvitationNotificationHelper(params);
      case 'external_job_invitation':
        params.urlTemplate = '<urlNode>/changeme/<id>';
        params.recommendedParent = 'externalJobPosting';
        params.recommendedIdKey = 'externalId';
        params.recommendedTitleKey = 'title';
        return new InvitationNotificationHelper(params);
      case 'helper_invitation':
        params.urlTemplate = '<urlNode>/helpers/<id>';
        params.recommendedParent = 'helper';
        return new InvitationNotificationHelper(params);
      case 'job_invitation':
        params.urlTemplate = '<urlNode>/jobs/<id>';
        params.recommendedParent = 'jobPosting';
        params.recommendedTitleKey = 'title';
        return new InvitationNotificationHelper(params);
      case 'program_invitation':
        params.urlTemplate = '<urlNode>/programs/<id>';
        params.recommendedParent = 'program';
        return new InvitationNotificationHelper(params);
      case 'candidate_profile':
        params.urlTemplate = '<urlNode>/candidates/<id>';
        params.namesParent = 'info';
        params.urlIdKey = ['candidate', 'id'];
        return new ReplaceNamesNotificationHelper(params);
      case 'message':
        params.urlTemplate = '<urlNode>/messages';
        params.namesParent = ['info', 'performedBy'];
        return new ReplaceNamesNotificationHelper(params);
      case 'questionnaire':
        return new QuestionnaireNotificationHelper(params);
      case 'resume':
        return new ResumeNotificationHelper(params);
      default:
        return new BaseNotificationHelper(params);
    }
  }
}

export default NotificationHelperFactory;
