import { connect } from 'react-redux';
import SelectCandidateRowView from './select_candidate_row_view';
import { getCurrentUser, getCandidate } from '../../../../../../../reducer';
import { fetchCandidate } from '../../../../../../../solve/actions';

const mapStateToProps = (state, { candidateId }) => ({
  candidateId,
  candidate: getCandidate(state, candidateId),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = { fetchCandidate };

const SelectCandidateRowContainer = connect(mapStateToProps, mapDispatchToProps)(SelectCandidateRowView);

export default SelectCandidateRowContainer;
