import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, fromJS } from 'immutable';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, MuiTextField, MuiTextFieldArray, MuiSelect, MuiCheckbox, MuiRadioGroup, EditorField } from '../../../../common/form';
import { Card } from '../../../../common/styles/layout';
import Button from '../../../../common/components/button/index';
import screenerFormValidate from './screener_form_validate';
import ScreenerFormStyles from './screener_form_styles';
import { getGlobalSurveysOfType } from '../../../../reducer';
import SurveyModel from '../../../../solve/models/survey_model';

const CustomScreeningQuestionsView = ({ surveyTemplate, fields, label, meta: { error }, ...rest }) => {
  const questionTemplateId = surveyTemplate.getIn(['surveyQuestions', 0, 'surveyQuestion', 'questionTemplate', 'id']);
  const blankQuestion = fromJS({ extra: { questionPlaceholders: { '<0>': '' } }, questionTemplateId, questionImportanceType: 'preferred' });
  return (
    <div>
      {fields.map((name, index) => (
        <span key={name}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Field name={`${name}.extra.questionPlaceholders.<0>`} label={`Question ${index + 1}`} component={MuiTextField} {...rest} />
          <IconButton aria-label="cancel" onClick={() => fields.remove(index)}>
            <CancelIcon fontSize="small" />
          </IconButton>
        </span>
      ))}
      {error && <span className="error">{error}</span>}
      {fields.length < 10 && (
        <Fragment>
          <IconButton aria-label="cancel" onClick={() => fields.push(blankQuestion)}>
            <AddIcon fontSize="small" />
          </IconButton>
          {label}
        </Fragment>
      )}
    </div>
  );
};

CustomScreeningQuestionsView.propTypes = {
  fields: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, remove: PropTypes.func, length: PropTypes.number }).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string }),
  surveyTemplate: PropTypes.instanceOf(SurveyModel).isRequired,
};
CustomScreeningQuestionsView.defaultProps = {
  label: '',
  meta: {},
};

// eslint-disable-next-line no-unused-vars
const CustomScreeningQuestions = connect((state, { surveyType }) => ({
  surveyTemplate: getGlobalSurveysOfType(state, `${surveyType}-template`).first(),
}))(CustomScreeningQuestionsView);

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  genderTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  educationTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  allAgesWelcomed: PropTypes.bool,
  allGendersWelcomed: PropTypes.bool,
  participantsCanApplyOnSolve: PropTypes.bool,
};

const defaultProps = {
  allAgesWelcomed: false,
  allGendersWelcomed: false,
  participantsCanApplyOnSolve: false,
};

const ScreenerForm = ({
  handleSubmit,
  previousPage,
  pristine,
  submitting,
  genderTypes,
  educationTypes,
  allAgesWelcomed,
  allGendersWelcomed,
  participantsCanApplyOnSolve,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ScreenerFormStyles>
      <form onSubmit={handleSubmit}>
        <h3>Screener Questions</h3>
        <Card>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={2} className="screener-form">
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid container justify="flex-start" alignItems="flex-start" item md={6} xs={12} spacing={2}>
                  <Grid item xs={12}>
                    <h3>Targeted Participants</h3>
                    <header>What is your target population?</header>
                    <p>Select all that apply</p>
                    <FormGroup>
                      <FormControlLabel
                        control={<Field component={MuiCheckbox} name="targetParticipantTypes.2-year-degree" />}
                        label="2-year Degree College Students"
                      />
                      <FormControlLabel
                        control={<Field component={MuiCheckbox} name="targetParticipantTypes.4-year-degree" />}
                        label="4-Year Degree and Above College Students"
                      />
                      <FormControlLabel
                        control={<Field component={MuiCheckbox} name="targetParticipantTypes.empowerment-zone" />}
                        label="Designated community resident empowerment zone"
                      />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.disability" />} label="Disability" />
                      <FormControlLabel
                        control={<Field component={MuiCheckbox} name="targetParticipantTypes.english-2nd-lang" />}
                        label="English 2nd Language"
                      />
                      <FormControlLabel
                        control={<Field component={MuiCheckbox} name="targetParticipantTypes.was-incarcerated" />}
                        label="Formerly Incarcerated"
                      />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.hs-drop-out" />} label="High School Drop Out" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.hs-student" />} label="High School Students" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.homeless" />} label="Homeless" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.hud" />} label="HUD" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.opportunity-youth" />} label="Opportunity Youth" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.single-moms" />} label="Single Moms" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.single-fathers" />} label="Single Fathers" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.single-parents" />} label="Single Parents" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.snap" />} label="SNAP Recipient" />
                      <FormControlLabel
                        control={<Field component={MuiCheckbox} name="targetParticipantTypes.supplement-social-security" />}
                        label="Supplemental social security income"
                      />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.tanf" />} label="TANF Recipient" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.unemployment" />} label="Unemployment" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.veterans" />} label="Veterans" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.wioa-adult" />} label="WIOA Eligible (ADULT)" />
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.wioa-youth" />} label="WIOA Eligible (YOUTH)" />
                      {/* TODO get slect all working with redux form */}
                      <FormControlLabel control={<Field component={MuiCheckbox} name="targetParticipantTypes.selectAll" />} label="Select All" />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <header style={{ marginTop: 40, marginBottom: 0 }}>Who is your ideal candidate?</header>
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      variant="outlined"
                      component={MuiTextField}
                      label="Minimum Age"
                      name="targetMinimumAge"
                      margin="dense"
                      disabled={allAgesWelcomed === true}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      variant="outlined"
                      component={MuiTextField}
                      label="Maximum Age"
                      name="targetMaximumAge"
                      margin="dense"
                      disabled={allAgesWelcomed === true}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel control={<Field component={MuiCheckbox} name="allAgesWelcomed" />} label="All ages are welcomed" />
                  </Grid>
                  <Grid item xs={10}>
                    <MuiSelect
                      options={genderTypes}
                      label="Target Gender"
                      name="targetGenderType"
                      margin="dense"
                      disabled={allGendersWelcomed === true}
                      fullWidth
                    />
                    <FormControlLabel control={<Field component={MuiCheckbox} name="allGendersWelcomed" />} label="All Genders Welcomed" />
                  </Grid>
                  <Grid item xs={10}>
                    <MuiSelect
                      options={educationTypes}
                      label="Target of minimum education level"
                      name="targetMinimumEducationLevelType"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {/* TODO: We need to add custom zip code masks to these */}
                  <Grid item xs={10}>
                    <header>Do you accept participants from all zip codes or select what zip codes you work with?*</header>
                    <FieldArray component={MuiTextFieldArray} label="Add a Zip Code" name="targetZipCodes" multiline required />
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel control={<Field component={MuiCheckbox} name="allZipCodesWelcomed" />} label="All zip codes are welcomed" />
                  </Grid>
                  <Grid item xs={12}>
                    <h3>Application Process</h3>
                    <header>Where and how do you want your applicants to apply to your program?</header>
                    <Field
                      fullWidth
                      variant="outlined"
                      component={MuiTextField}
                      label="Application URL"
                      name="applicationWebsite"
                      margin="dense"
                      disabled={participantsCanApplyOnSolve === true}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <FormControlLabel
                      control={<Field component={MuiCheckbox} name="participantsCanApplyOnSolve" />}
                      label="I would like the participants to apply on RiseKit."
                    />
                  </Grid>
                </Grid>
                <Grid direction="column" container item md={6} xs={12}>
                  {/*
                  <Grid item>
                    <h3>Custom Screening Questions</h3>
                    <header>Please type in custom questions for your applicants. Note: they will only be able to answer YES/NO to these questions.</header>
                    <div className="custom-questions">
                      <FieldArray
                        component={CustomScreeningQuestions}
                        label="Add a Question"
                        name="surveyQuestionAttributes"
                        multiline
                        fullWidth
                        required
                        margin="dense"
                        variant="outlined"
                        surveyType="program-screening"
                      />
                    </div>
                  </Grid>
                  */}
                  <Grid item>
                    <h3>Resume</h3>
                    <header>Do you require the candidate to submit a resume?*</header>
                    <Field name="isResumeRequired" component={MuiRadioGroup}>
                      <FormGroup row>
                        <FormControlLabel control={<Radio color="primary" />} value="true" label="Yes" />
                        <FormControlLabel control={<Radio color="primary" />} value="false" label="No" />
                      </FormGroup>
                    </Field>
                  </Grid>
                  <Grid item>
                    <h3>Custom message</h3>
                    <header>Please provide a brief custom message for the applicant or a Thank you note.</header>
                    <p>(Optional)</p>
                    <EditorField
                      variant="outlined"
                      component={MuiTextField}
                      label="Please type the custom message here."
                      name="customThankYouMessage"
                      margin="dense"
                      multiline
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <div className="button-group">
          <div className="button-left">
            <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
              Previous
            </Button>
          </div>
          <div className="button-right">
            <Button buttonType="secondary" type="submit">
              Next Step
            </Button>
          </div>
        </div>
      </form>
    </ScreenerFormStyles>
  );
};

ScreenerForm.propTypes = propTypes;
ScreenerForm.defaultProps = defaultProps;

const ScreenerFormView = reduxForm({
  form: 'programForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: screenerFormValidate,
})(ScreenerForm);

export default ScreenerFormView;
