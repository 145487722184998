import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { reduxForm, Field, MuiTextField, MuiFileUpload, MuiSelect, MuiDatePicker, MuiCheckbox, phoneMask } from '../../../common/form';
import SettingsStyles, { Title } from './settings_styles';
import settingsFormValidate from './settings_form_validate';
import Button from '../../../common/components/button/index';
import { Card } from '../../../common/styles/layout';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import Spinner from '../../../common/components/spinner';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  staff: PropTypes.instanceOf(Map),
  fetchStaffMember: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  avatar: PropTypes.instanceOf(FileList),
  genderTypes: PropTypes.instanceOf(List).isRequired,
  staffResponsibilities: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {
  avatar: undefined,
  staff: undefined,
};

const SettingsForm = ({ handleSubmit, pristine, submitting, avatar, genderTypes, staffResponsibilities, user, staff, fetchStaffMember }) => {
  const resolved = useFetchOnce(user, user.get('staffId'), fetchStaffMember, staff);

  if (!resolved && !staff) {
    return <Spinner />;
  }

  return (
    <SettingsStyles>
      <Grid>
        <Title>Settings</Title>
        <form onSubmit={handleSubmit}>
          <div>{avatar && <img src={URL.createObjectURL(avatar[0])} height="70" alt="Avatar" />}</div>
          <Field component={MuiFileUpload} label="Add Your Avatar" name="avatar" margin="dense" />
          <div style={{ height: 20 }} />
          <Card>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <h3>Personal Information</h3>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                      <Grid item xs={6}>
                        <Field
                          component={MuiTextField}
                          label="First Name"
                          name="userAttributes.firstName"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field component={MuiTextField} label="Last Name" name="userAttributes.lastName" margin="dense" variant="outlined" fullWidth required />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={MuiTextField}
                          label="New Password (leave blank to stay the same)"
                          name="userAttributes.password"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Field
                          component={MuiTextField}
                          label="Confirm New Password"
                          name="userAttributes.passwordConfirmation"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Field
                          component={MuiTextField}
                          label="Mobile Phone Number"
                          name="userAttributes.phone"
                          type="cell"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...phoneMask}
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <Field
                          component={MuiTextField}
                          label="Work Phone Number"
                          name="workPhone"
                          type="cell"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...phoneMask}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <Field component={MuiTextField} label="Extension" name="workPhoneExtension" type="number" margin="dense" variant="outlined" fullWidth />
                      </Grid>
                      <Grid item xs={12}>
                        <MuiSelect options={genderTypes} label="Gender" name="genderType" margin="dense" required />
                      </Grid>
                      <Grid item xs={12}>
                        <Field component={MuiDatePicker} label="Birthday (mm-dd-yyyy)" name="birthday" margin="dense" variant="outlined" fullWidth />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={1} />
              <Grid item md={5}>
                <h3>Roles And Responsibilities</h3>
                <cite>Check all that apply</cite>
                {staffResponsibilities.map((role) => (
                  <Grid item xs={12} key={role.get('slug')}>
                    <FormControlLabel
                      control={<Field component={MuiCheckbox} name={`staffResponsibilityTypes.${role.get('slug')}`} />}
                      label={role.get('label')}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <div style={{ height: 30 }} />
          </Card>
          <Button variant="contained" className="action-contained" disabled={pristine || submitting} onClick={handleSubmit} style={{ float: 'right' }}>
            Update Profile
          </Button>
        </form>
      </Grid>
    </SettingsStyles>
  );
};

SettingsForm.propTypes = propTypes;
SettingsForm.defaultProps = defaultProps;

const SettingsView = reduxForm({
  form: 'settingsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate: settingsFormValidate,
})(SettingsForm);

export default SettingsView;
