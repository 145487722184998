import { normalize } from 'normalizr';
import * as schema from './schema';
import * as RealBackend from './real_backend';
import * as MockBackend from './mock_backend';

let SelectedBackend;

if (process.env.REACT_APP_API_ENDPOINT === undefined) {
  SelectedBackend = MockBackend;
} else {
  SelectedBackend = RealBackend;
}

// Normalization
const normalizeCandidate = (response) => normalize(response.data, schema.candidate);

const normalizeCandidates = (response) => {
  const totalCandidates = response.headers['x-total-count'];
  return { totalCandidates, ...normalize(response.data, [schema.candidate]) };
};

const normalizeCandidateOrganizations = (response) => normalize(response.data, [schema.candidateOrganization]);
const normalizeCandidateApplications = (response) => normalize(response.data, [schema.candidateApplication]);
const normalizeCandidateApplication = (response) => normalize(response.data, schema.candidateApplication);
const normalizeIndeedCandidateApplications = (response) => normalize(response.data, [schema.indeedCandidateApplication]);
const normalizeIndeedCandidateApplication = (response) => normalize(response.data, schema.indeedCandidateApplication);
const normalizeCandidateEvents = (response) => normalize(response.data, [schema.candidateEvent]);
const normalizeCandidateEvent = (response) => normalize(response.data, schema.candidateEvent);
const normalizeCandidatePrograms = (response) => normalize(response.data, [schema.candidateProgram]);
const normalizeCandidateProgram = (response) => normalize(response.data, schema.candidateProgram);
const normalizeCandidateServices = (response) => normalize(response.data, [schema.candidateService]);
const normalizeCandidateService = (response) => normalize(response.data, schema.candidateService);
const normalizeExternalCandidateService = (response) => normalize(response.data, schema.externalCandidateService);
const normalizeExternalCandidateServices = (response) => normalize(response.data, [schema.externalCandidateService]);
const normalizeCandidateSurveys = (response) => normalize(response.data, [schema.candidateSurvey]);
const normalizeCandidateSurvey = (response) => normalize(response.data, schema.candidateSurvey);
const normalizeCandidatePathways = (response) => normalize(response.data, [schema.candidatePathway]);
const normalizeCandidatePathway = (response) => normalize(response.data, schema.candidatePathway);

const normalizeJobPostings = (response) => {
  const totalJobs = response.headers['x-total-count'];
  return { totalJobs, ...normalize(response.data, [schema.jobPosting]) };
};

const normalizeJobPostingsV2 = (response) => ({ ...normalize(response.data, [schema.jobPosting]) });
const normalizeIndeedJobPostingsV2 = (response) => ({ ...normalize(response.data.results, [schema.indeedJobPosting]) });

const normalizeJobPosting = (response) => normalize(response.data, schema.jobPosting);
const normalizeIndeedJobPostings = (response) => {
  const totalJobs = response.headers['x-total-count'];
  return { totalJobs, ...normalize(response.data, [schema.indeedJobPosting]) };
};
const normalizeIndeedJobPosting = (response) => normalize(response?.data?.results[0], schema.indeedJobPosting);
const normalizeOrganizations = (response) => normalize(response.data, [schema.organization]);
const normalizeFilteredOrganizations = (response) => {
  const totalFilteredOrgs = response.headers['x-total-count'];
  return { totalFilteredOrgs, ...normalize(response.data, [schema.organization]) };
};

const normalizeOrganization = (response) => normalize(response.data, schema.organization);
const normalizeResumes = (response) => normalize(response.data, [schema.resume]);
const normalizeResume = (response) => normalize(response.data, schema.resume);
const normalizeChallenges = (response) => normalize(response.data, [schema.challenge]);
const normalizeChallenge = (response) => normalize(response.data, schema.challenge);
const normalizeGlobalSurveys = (response) => normalize(response.data, [schema.globalSurvey]);
const normalizeSurveys = (response) => normalize(response.data, [schema.survey]);
const normalizeSupportiveService = (response) => normalize(response.data, schema.supportiveService);
const normalizeSupportiveServices = (response) => {
  const totalCount = response.headers['x-total-count'];

  return { ...normalize(response.data, [schema.supportiveService]), totalCount };
};
const normalizeProgram = (response) => normalize(response.data, schema.program);
const normalizePrograms = (response) => {
  const totalCount = response.headers['x-total-count'];

  return {...normalize(response.data, [schema.program]), totalCount}
};
const normalizeInmate = (response) => normalize(response.data, schema.inmate);

const normalizeInvitations = (response) => {
  const totalInvitations = response.headers['x-total-count'];
  return { totalInvitations, ...normalize(response.data, [schema.invitation]) };
};

const normalizeInvitation = (response) => normalize(response.data, schema.invitation);
const normalizeEvents = (response) => {
  const totalCount = response.headers['x-total-count'];

  return { ...normalize(response.data, [schema.event]), totalCount }
};
const normalizeEvent = (response) => normalize(response.data, schema.event);
const normalizeStaff = (response) => normalize(response.data, [schema.staff]);
const normalizeStaffMember = (response) => normalize(response.data, schema.staff);
const normalizeMessages = (response) => normalize(response.data, [schema.message]);
const normalizeMessage = (response) => normalize(response.data, schema.message);
const normalizeNotes = (response) => normalize(response.data, [schema.note]);
const normalizeNote = (response) => normalize(response.data, schema.note);
const normalizeNotifications = (response) => normalize(response.data, [schema.notification]);
const normalizeNotification = (response) => normalize(response.data, schema.notification);
const normalizeSystemEnums = (response) => normalize(response.data, [schema.systemEnum]);
const normalizePublicLink = (response) => normalize(response.data, schema.publicLink);

// const normalizeInviteLink = (response) => normalize(response.data, schema.inviteLink);
const normalizePathways = (response) => normalize(response.data, [schema.pathway]);
const normalizePathway = (response) => normalize(response.data, schema.pathway);
const normalizePathwayInstances = (response) => normalize(response.data, [schema.pathwayInstance]);
const normalizePathwayInstance = (response) => normalize(response.data, schema.pathwayInstance);
const normalizePathwayInstanceStep = (response) => normalize(response.data, schema.pathwayInstanceStep);

// Session
export const signIn = (email, password) => SelectedBackend.signIn(email, password);
export const finishSignIn = () => SelectedBackend.finishSignIn();
export const register = (info) => SelectedBackend.register(info);
export const confirmEmail = (token) => SelectedBackend.confirmEmail(token);
export const reconfirmEmail = (token) => SelectedBackend.reconfirmEmail(token);
export const refreshAuth = (token) => SelectedBackend.refreshAuth(token);
export const updateCurrentUser = (token, props) => SelectedBackend.updateCurrentUser(token, props);

// Password
export const forgotPassword = (email) => SelectedBackend.forgotPassword(email);
export const resetPassword = (token, password) => SelectedBackend.resetPassword(token, password);

// Candidate
export const createCandidate = (user, candidate) => SelectedBackend.createCandidate(user, candidate).then(normalizeCandidate);
export const fetchCandidate = (user, candidateId) => SelectedBackend.fetchCandidate(user, candidateId).then(normalizeCandidate);
export const updateCandidate = (user, candidateId, candidate) => SelectedBackend.updateCandidate(user, candidateId, candidate).then(normalizeCandidate);
export const listCandidates = (user, organizationId, props) => SelectedBackend.listCandidates(user, organizationId, props).then(normalizeCandidates);

// Candidate Organizations
export const listCandidateOrganizations = (user, candidateId, props) =>
  SelectedBackend.listCandidateOrganizations(user, candidateId, props).then(normalizeCandidateOrganizations);

// Candidate Applications
export const listCandidateApplicationsForCandidate = (user, props) =>
  SelectedBackend.listCandidateApplicationsForCandidate(user, props).then(normalizeCandidateApplications);
export const listCandidateApplications = (user, props) => SelectedBackend.listCandidateApplications(user, props).then(normalizeCandidateApplications);

export const createCandidateApplication = (user, jobPostingId) =>
  SelectedBackend.createCandidateApplication(user, jobPostingId).then(normalizeCandidateApplication);

// external job
export const createExternalCandidateApplication = (user, jobPostingId, jobTitle) =>
  SelectedBackend.createExternalCandidateApplication(user, jobPostingId, jobTitle).then(normalizeCandidateApplication);
export const transitionExternalCandidateApplicationByCandidate = (user, candidateApplicationId, state) =>
  SelectedBackend.transitionExternalCandidateApplicationByCandidate(user, candidateApplicationId, state).then(normalizeCandidateApplication);
export const listExternalCandidateApplicationsForCandidate = (user, props) =>
  SelectedBackend.listExternalCandidateApplicationsForCandidate(user, props).then(normalizeCandidateApplications);

export const fetchCandidateApplication = (user, candidateApplicationId) =>
  SelectedBackend.fetchCandidateApplication(user, candidateApplicationId).then(normalizeCandidateApplication);
export const transitionCandidateApplicationByCandidate = (user, candidateApplicationId, state) =>
  SelectedBackend.transitionCandidateApplicationByCandidate(user, candidateApplicationId, state).then(normalizeCandidateApplication);
export const transitionCandidateApplicationByStaff = (user, candidateApplicationId, state) =>
  SelectedBackend.transitionCandidateApplicationByStaff(user, candidateApplicationId, state).then(normalizeCandidateApplication);
export const listApplicationsToJobPosting = (user, jobPostingId) =>
  SelectedBackend.listApplicationsToJobPosting(user, jobPostingId).then(normalizeCandidateApplications);

// External Candidate Applications
export const listIndeedCandidateApplicationsForCandidate = (user, props) =>
  SelectedBackend.listIndeedCandidateApplicationsForCandidate(user, props).then(normalizeIndeedCandidateApplications);
export const fetchIndeedCandidateApplication = (user, candidateApplicationId) =>
  SelectedBackend.fetchIndeedCandidateApplication(user, candidateApplicationId).then(normalizeIndeedCandidateApplication);

// Candidate Events
export const listCandidateEvents = (user, candidate) => SelectedBackend.listCandidateEvents(user, candidate).then(normalizeCandidateEvents);
export const fetchCandidateEvent = (user, candidateEventId) => SelectedBackend.fetchCandidateEvent(user, candidateEventId).then(normalizeCandidateEvent);
export const listCandidateEventsForCandidate = (user, props) => SelectedBackend.listCandidateEventsForCandidate(user, props).then(normalizeCandidateEvents);
export const listCandidatesForEvent = (user, eventId) => SelectedBackend.listCandidatesForEvent(user, eventId).then(normalizeCandidateEvents);
export const createCandidateEvent = (user, eventId) => SelectedBackend.createCandidateEvent(user, eventId).then(normalizeCandidateEvent);
export const transitionCandidateEventByCandidate = (user, candidateEventId, state) =>
  SelectedBackend.transitionCandidateEventByCandidate(user, candidateEventId, state).then(normalizeCandidateEvent);
export const transitionCandidateEventByStaff = (user, candidateEventId, newState) =>
  SelectedBackend.transitionCandidateEventByStaff(user, candidateEventId, newState).then(normalizeCandidateEvent);

// Candidate Programs
export const listCandidatePrograms = (user, candidate) => SelectedBackend.listCandidatePrograms(user, candidate).then(normalizeCandidatePrograms);
export const fetchCandidateProgram = (user, candidateProgramId) =>
  SelectedBackend.fetchCandidateProgram(user, candidateProgramId).then(normalizeCandidateProgram);
export const listCandidateProgramsForCandidate = (user, props) =>
  SelectedBackend.listCandidateProgramsForCandidate(user, props).then(normalizeCandidatePrograms);
export const createCandidateProgram = (user, programId) => SelectedBackend.createCandidateProgram(user, programId).then(normalizeCandidateProgram);
export const transitionCandidateProgramByCandidate = (user, candidateProgramId, state) =>
  SelectedBackend.transitionCandidateProgramByCandidate(user, candidateProgramId, state).then(normalizeCandidateProgram);
export const transitionCandidateServiceByStaff = (user, candidateServiceId, state) =>
  SelectedBackend.transitionCandidateServiceByStaff(user, candidateServiceId, state).then(normalizeCandidateService);
export const transitionCandidateServiceByCandidate = (user, candidateServiceId, state) =>
  SelectedBackend.transitionCandidateServiceByCandidate(user, candidateServiceId, state).then(normalizeCandidateService);
export const transitionCandidateProgramByStaff = (user, candidateProgramId, newState) =>
  SelectedBackend.transitionCandidateProgramByStaff(user, candidateProgramId, newState).then(normalizeCandidateProgram);
export const listCandidatesForProgram = (user, programId) => SelectedBackend.listCandidatesForProgram(user, programId).then(normalizeCandidatePrograms);

// Candidate Surveys
export const listCandidateSurveys = (user, candidate, props) => SelectedBackend.listCandidateSurveys(user, candidate, props).then(normalizeCandidateSurveys);
export const updateCandidateSurvey = (user, id, props) => SelectedBackend.updateCandidateSurvey(user, id, props).then(normalizeCandidateSurvey);

// Staff Candidates
export const listStaffCandidates = (user, props) => SelectedBackend.listStaffCandidates(user, props).then(normalizeCandidates);
export const listStaffCandidatesForProgram = (user, props) => SelectedBackend.listStaffCandidatesForProgram(user, props).then(normalizeCandidatePrograms);
export const listStaffCandidatesForEvent = (user, props) => SelectedBackend.listStaffCandidatesForEvent(user, props).then(normalizeCandidateEvents);
export const listStaffCandidatesForJob = (user, props) => SelectedBackend.listStaffCandidatesForJob(user, props).then(normalizeCandidateApplications);

// JobPostings
export const listJobPostings = (user, props) => SelectedBackend.listJobPostings(user, props).then(normalizeJobPostings);
export const fetchIndeedJobPostingByIds = (user, jobIds) => SelectedBackend.fetchIndeedJobPostingByIds(user, jobIds).then(normalizeIndeedJobPostingsV2);
export const fetchJobPostingsByIds = (user, jobIds) => SelectedBackend.fetchJobPostingsByIds(user, jobIds).then(normalizeJobPostingsV2);
export const createJobPosting = (user, organizationId, jobPosting) =>
  SelectedBackend.createJobPosting(user, organizationId, jobPosting).then(normalizeJobPosting);
export const fetchJobPosting = (user, id) => SelectedBackend.fetchJobPosting(user, id).then(normalizeJobPosting);
export const updateJobPosting = (user, jobPostingId, jobPosting) => SelectedBackend.updateJobPosting(user, jobPostingId, jobPosting).then(normalizeJobPosting);
export const draftJobPosting = (user, jobPostingId) => SelectedBackend.draftJobPosting(user, jobPostingId).then(normalizeJobPosting);
export const publishJobPosting = (user, jobPostingId) => SelectedBackend.publishJobPosting(user, jobPostingId).then(normalizeJobPosting);
export const closeJobPosting = (user, jobPostingId) => SelectedBackend.closeJobPosting(user, jobPostingId).then(normalizeJobPosting);
export const cancelJobPosting = (user, jobPostingId) => SelectedBackend.cancelJobPosting(user, jobPostingId).then(normalizeJobPosting);
export const activateJobPosting = (user, jobPostingId) => SelectedBackend.activateJobPosting(user, jobPostingId).then(normalizeJobPosting);
export const fetchJobPostingParticipantsReport = (user, jobPostingId) => SelectedBackend.fetchJobPostingParticipantsReport(user, jobPostingId);

// Indeed JobPostings
export const listIndeedJobPostings = (user, props) => SelectedBackend.listIndeedJobPostings(user, props).then(normalizeIndeedJobPostings);
export const fetchIndeedJobPosting = (user, id) => SelectedBackend.fetchIndeedJobPosting(user, id).then(normalizeIndeedJobPosting);

// Organizations
export const listOrganizations = (user, props) => SelectedBackend.listOrganizations(user, props).then(normalizeOrganizations);
export const listFilteredOrganizations = (user, props) => SelectedBackend.listFilteredOrganizations(user, props).then(normalizeFilteredOrganizations);
export const listOrganizationNames = (user, props) => SelectedBackend.listOrganizationNames(user, props);
export const listChildOrganizations = (user, parentOrganizationId, props) =>
  SelectedBackend.listChildOrganizations(user, parentOrganizationId, props).then(normalizeOrganizations);
export const fetchOrganization = (user, id) => SelectedBackend.fetchOrganization(user, id).then(normalizeOrganization);
export const createOrganization = (user, org) => SelectedBackend.createOrganization(user, org).then(normalizeOrganization);
export const updateOrganization = (user, organizationId, org) => SelectedBackend.updateOrganization(user, organizationId, org).then(normalizeOrganization);
export const inviteRolesToOrganization = (user, orgId, args) => SelectedBackend.inviteRolesToOrganization(user, orgId, args);
export const fetchOrganizationCandidatesReport = (user, orgId) => SelectedBackend.fetchOrganizationCandidatesReport(user, orgId);
export const fetchOrganizationNewCandidatesReport = (user, orgId) => SelectedBackend.fetchOrganizationNewCandidatesReport(user, orgId);
export const fetchOrganizationCareerFairReport = (user, orgId) => SelectedBackend.fetchOrganizationCareerFairReport(user, orgId);
export const fetchRecidivismReport = (user, orgId) => SelectedBackend.fetchRecidivismReport(user, orgId);

// Resumes
export const listResumes = (user, candidateId) => SelectedBackend.listResumes(user, candidateId).then(normalizeResumes);
export const fetchResume = (user, id) => SelectedBackend.fetchResume(user, id).then(normalizeResume);
export const createResume = (user, candidateId, props) => SelectedBackend.createResume(user, candidateId, props).then(normalizeResume);
export const updateResume = (user, id, props) => SelectedBackend.updateResume(user, id, props).then(normalizeResume);
export const approveResumeChanges = (user, id) => SelectedBackend.approveResumeChanges(user, id).then(normalizeResume);
export const declineResumeChanges = (user, id) => SelectedBackend.declineResumeChanges(user, id).then(normalizeResume);
export const uploadResume = (user, candidateId, props) => SelectedBackend.uploadResume(user, candidateId, props).then(normalizeResume);

// Inmate
export const fetchInmate = (user, candidateId) =>
  SelectedBackend.fetchInmate(user, candidateId)
    .then(normalizeInmate)
    .catch((err) => {
      if (err.response?.status === 404) {
        // Do not bubble up 404 error, just return empty (psuedo-normalized) response
        return { entities: {} };
      }
      throw err;
    });
export const createInmate = (user, candidateId, inmate) => SelectedBackend.createInmate(user, candidateId, inmate).then(normalizeInmate);
export const updateInmate = (user, candidateId, inmate) => SelectedBackend.updateInmate(user, candidateId, inmate).then(normalizeInmate);

// Challenges
export const fetchChallenge = (user, challengeId) => SelectedBackend.fetchChallenge(user, challengeId).then(normalizeChallenge);
export const listChallenges = (user, candidateId) => SelectedBackend.listChallenges(user, candidateId).then(normalizeChallenges);
export const staffChallengeTransition = (user, challengeId) => SelectedBackend.staffChallengeTransition(user, challengeId).then(normalizeChallenge);
export const candidateChallengeTransition = (user, challengeId) => SelectedBackend.candidateChallengeTransition(user, challengeId).then(normalizeChallenge);

// Surveys
export const listGlobalSurveys = (user) => SelectedBackend.listGlobalSurveys(user).then(normalizeGlobalSurveys);
export const listSurveys = (user, organizationId) => SelectedBackend.listSurveys(user, organizationId).then(normalizeSurveys);
export const startSurvey = (user, id) => SelectedBackend.startSurvey(user, id).then(normalizeCandidateSurvey);

// Programs
export const listPrograms = (user, props) => SelectedBackend.listPrograms(user, props).then(normalizePrograms);
export const createProgram = (user, organizationId, values) => SelectedBackend.createProgram(user, organizationId, values).then(normalizeProgram);
export const updateProgram = (user, programId, program) => SelectedBackend.updateProgram(user, programId, program).then(normalizeProgram);
export const draftProgram = (user, programId) => SelectedBackend.draftProgram(user, programId).then(normalizeProgram);
export const publishProgram = (user, programId) => SelectedBackend.publishProgram(user, programId).then(normalizeProgram);
export const closeProgram = (user, programId) => SelectedBackend.closeProgram(user, programId).then(normalizeProgram);
export const cancelProgram = (user, programId) => SelectedBackend.cancelProgram(user, programId).then(normalizeProgram);
export const activateProgram = (user, programId) => SelectedBackend.activateProgram(user, programId).then(normalizeProgram);
export const fetchProgram = (user, programId) => SelectedBackend.fetchProgram(user, programId).then(normalizeProgram);

// Supportive Services
export const listSupportiveServices = (user, props) => SelectedBackend.listSupportiveServices(user, props).then(normalizeSupportiveServices);
export const listABSupportiveServices = (user, props) => SelectedBackend.listABSupportiveServices(user, props).then(normalizeSupportiveServices);
export const listABSupportiveService = (user, serviceId) => SelectedBackend.listABSupportiveService(user, serviceId).then(normalizeSupportiveService);
export const createExternalCandidateService = (user, serviceId) =>
  SelectedBackend.createExternalCandidateService(user, serviceId).then(normalizeExternalCandidateService);
export const candidateApplyToExternalService = (user, serviceId) =>
  SelectedBackend.candidateApplyToExternalService(user, serviceId).then(normalizeExternalCandidateService);
export const transitionExternalCandidateServiceByCandidate = (user, externalCandidateServiceId, newState) =>
  SelectedBackend.transitionExternalCandidateServiceByCandidate(user, externalCandidateServiceId, newState).then(normalizeExternalCandidateService);
export const listCandidateServicesForCandidate = (user, props) =>
  SelectedBackend.listCandidateServicesForCandidate(user, props).then(normalizeCandidateServices);
export const fetchExternalCandidateServicesServices = (user, props) =>
  SelectedBackend.fetchExternalCandidateServicesServices(user, props).then(normalizeSupportiveServices);
export const fetchExternalCandidateServices = (user) => SelectedBackend.fetchExternalCandidateServices(user).then(normalizeExternalCandidateServices);
export const fetchSupportiveService = (user, serviceId) => SelectedBackend.fetchSupportiveService(user, serviceId).then(normalizeSupportiveService);
export const fetchAbService = (user, serviceId) => SelectedBackend.fetchAbService(user, serviceId).then(normalizeSupportiveService);

// Invitations
export const listInvitations = (user, props) => SelectedBackend.listInvitations(user, props).then(normalizeInvitations);
// export const listStaffInvitations = (user, referencedType, referencedId, props) =>
//   SelectedBackend.listStaffInvitations(user, referencedType, referencedId, props);
export const createInvitation = (user, invitation) => SelectedBackend.createInvitation(user, invitation).then(normalizeInvitation);
export const resendInvitation = (user, invitationId) => SelectedBackend.resendInvitation(user, invitationId).then(normalizeInvitation);
export const bulkCreateInvitations = (user, referencedType, referencedId, invitationsFile) =>
  SelectedBackend.bulkCreateInvitations(user, referencedType, referencedId, invitationsFile);
export const acceptInvitation = (user, invitationToken) => SelectedBackend.acceptInvitation(user, invitationToken);
export const fetchInvitation = (user, invitationToken) => SelectedBackend.createInvitation(user, invitationToken).then(normalizeInvitation);

// Event
export const listEvents = (user, props) => SelectedBackend.listEvents(user, props).then(normalizeEvents);
export const fetchEvent = (user, eventId) => SelectedBackend.fetchEvent(user, eventId).then(normalizeEvent);
export const createEvent = (user, orgId, event) => SelectedBackend.createEvent(user, orgId, event).then(normalizeEvent);
export const updateEvent = (user, eventId, event) => SelectedBackend.updateEvent(user, eventId, event).then(normalizeEvent);
export const draftEvent = (user, eventId) => SelectedBackend.draftEvent(user, eventId).then(normalizeEvent);
export const publishEvent = (user, eventId) => SelectedBackend.publishEvent(user, eventId).then(normalizeEvent);
export const closeEvent = (user, eventId) => SelectedBackend.closeEvent(user, eventId).then(normalizeEvent);
export const cancelEvent = (user, eventId) => SelectedBackend.cancelEvent(user, eventId).then(normalizeEvent);
export const activateEvent = (user, eventId) => SelectedBackend.activateEvent(user, eventId).then(normalizeEvent);

// Staff
export const createStaffMember = (user, staff) => SelectedBackend.createStaffMember(user, staff).then(normalizeStaffMember);
export const fetchStaffMember = (user, staffId) => SelectedBackend.fetchStaffMember(user, staffId).then(normalizeStaffMember);
export const updateStaffMember = (user, staffId, staff) => SelectedBackend.updateStaffMember(user, staffId, staff).then(normalizeStaffMember);
export const deleteStaffMember = (user, staffId) => SelectedBackend.deleteStaffMember(user, staffId).then(normalizeStaffMember);
export const listStaff = (user, organizationId, props) => SelectedBackend.listStaff(user, organizationId, props).then(normalizeStaff);
export const listPeerStaff = (user, organizationId, props) => SelectedBackend.listPeerStaff(user, organizationId, props).then(normalizeStaff);

// Enums
export const listSystemEnums = (page) => SelectedBackend.listSystemEnums({ page }).then(normalizeSystemEnums);

// Message
export const fetchMessage = (user, messageId) => SelectedBackend.fetchMessage(user, messageId).then(normalizeMessage);
export const listMessages = (user, props) => SelectedBackend.listMessages(user, props).then(normalizeMessages);
export const createMessage = (user, message) => SelectedBackend.createMessage(user, message).then(normalizeMessage);
export const updateMessage = (user, messageId, message) => SelectedBackend.updateMessage(user, messageId, message).then(normalizeMessage);

// Note
export const fetchNote = (user, noteId) => SelectedBackend.fetchNote(user, noteId).then(normalizeNote);
export const listNotes = (user, props) => SelectedBackend.listNotes(user, props).then(normalizeNotes);
export const createNote = (user, candidateId, note) => SelectedBackend.createNote(user, candidateId, note).then(normalizeNote);
export const updateNote = (user, noteId, note) => SelectedBackend.updateNote(user, noteId, note).then(normalizeNote);

// Notifications
export const listNotifications = (user, props) => SelectedBackend.listNotifications(user, props).then(normalizeNotifications);
export const fetchNotification = (user, notificationId) => SelectedBackend.fetchNotification(user, notificationId).then(normalizeNotification);
export const dismissNotification = (user, notificationId) => SelectedBackend.dismissNotification(user, notificationId).then(normalizeNotification);
export const setNotificationAsViewed = (user, notificationId) => SelectedBackend.setNotificationAsViewed(user, notificationId).then(normalizeNotification);

// Invite Link
export const createPublicLink = (user, linkType, subjectId) => SelectedBackend.createPublicLink(user, linkType, subjectId).then(normalizePublicLink);

// public link for external job
export const createExternalPublicLink = (user, publicLink) => SelectedBackend.createExternalPublicLink(user, publicLink).then(normalizePublicLink);

export const fetchInviteLinkForPreview = (user, id, linkType) => SelectedBackend.fetchInviteLinkForPreview(user, id, linkType).then(normalizeInvitation);
export const fetchPublicLinkForPreview = (user, id) => SelectedBackend.fetchPublicLinkForPreview(user, id).then(normalizePublicLink);
export const usePublicLink = (user, id) => SelectedBackend.usePublicLink(user, id);
export const useInviteLink = (user, id) => SelectedBackend.useInviteLink(user, id);

// Pathways
export const listPathways = (user, organizationId) => SelectedBackend.listPathways(user, organizationId).then(normalizePathways);
export const fetchPathway = (user, pathwayId) => SelectedBackend.fetchPathway(user, pathwayId).then(normalizePathway);
export const listPathwayInstances = (user, organizationId, props) =>
  SelectedBackend.listPathwayInstances(user, organizationId, props).then(normalizePathwayInstances);
export const fetchPathwayInstance = (user, pathwayId) => SelectedBackend.fetchPathwayInstance(user, pathwayId).then(normalizePathwayInstance);
export const createPathwayInstance = (user, organizationId, pathwayInstance) =>
  SelectedBackend.createPathwayInstance(user, organizationId, pathwayInstance).then(normalizePathwayInstance);
export const updatePathwayInstance = (user, pathwayId, pathwayInstance) =>
  SelectedBackend.updatePathwayInstance(user, pathwayId, pathwayInstance).then(normalizePathwayInstance);
export const draftPathwayInstance = (user, pathwayInstanceId) => SelectedBackend.draftPathwayInstance(user, pathwayInstanceId).then(normalizePathwayInstance);
export const publishPathwayInstance = (user, pathwayInstanceId) =>
  SelectedBackend.publishPathwayInstance(user, pathwayInstanceId).then(normalizePathwayInstance);
export const closePathwayInstance = (user, pathwayInstanceId) => SelectedBackend.closePathwayInstance(user, pathwayInstanceId).then(normalizePathwayInstance);
export const cancelPathwayInstance = (user, pathwayInstanceId) => SelectedBackend.cancelPathwayInstance(user, pathwayInstanceId).then(normalizePathwayInstance);

// Pathway Instance Steps
export const transitionPathwayStepStaffState = (user, pathwayInstanceStepId, state, extra) =>
  SelectedBackend.transitionPathwayStepStaffState(user, pathwayInstanceStepId, state, extra).then(normalizePathwayInstance);
export const transitionPathwayStepCandidateState = (user, pathwayInstanceStepId, state, extra) =>
  SelectedBackend.transitionPathwayStepCandidateState(user, pathwayInstanceStepId, state, extra).then(normalizePathwayInstance);
export const updatePathwayInstanceStep = (user, pathwayInstanceStepId, pathwayInstanceStep) =>
  SelectedBackend.updatePathwayInstanceStep(user, pathwayInstanceStepId, pathwayInstanceStep).then(normalizePathwayInstanceStep);
export const bulkUpdatePathwayInstanceStepsStaffStates = (user, pathwayInstanceStepsIds, pathwayInstanceStep) =>
  SelectedBackend.bulkUpdatePathwayInstanceStepsStaffStates(user, pathwayInstanceStepsIds, pathwayInstanceStep);
export const bulkTransitionPathwayInstanceStepsStaffStates = (user, pathwayInstanceStepIds, payload) =>
  SelectedBackend.bulkTransitionPathwayInstanceStepsStaffStates(user, pathwayInstanceStepIds, payload);
// Pathway Candidates
export const fetchCandidatePathwaysForCandidate = (user, candidateId) =>
  SelectedBackend.fetchCandidatePathwaysForCandidate(user, candidateId).then(normalizeCandidatePathways);
export const listCandidatePathwaysForPathwayInstance = (user, pathwayInstanceId, props) =>
  SelectedBackend.listCandidatePathwaysForPathwayInstance(user, pathwayInstanceId, props).then((response) => {
    const totalCount = response?.headers['x-total-count'] || 0;

    return {
      ...normalizeCandidatePathways(response),
      totalCount,
    };
  });
export const listCandidatePathwaysForOrganization = (user, organizationId, props) =>
  SelectedBackend.listCandidatePathwaysForOrganization(user, organizationId, props).then(normalizeCandidatePathways);
export const fetchCandidatePathway = (user, candidatePathwayId) =>
  SelectedBackend.fetchCandidatePathway(user, candidatePathwayId).then(normalizeCandidatePathway);
export const updateCandidatePathway = (user, candidatePathwayId, candidatePathwayInstance) =>
  SelectedBackend.updateCandidatePathway(user, candidatePathwayId, candidatePathwayInstance).then(normalizeCandidatePathway);
export const transitionCandidatePathwayStaffState = (user, candidatePathwayId, state) =>
  SelectedBackend.transitionCandidatePathwayStaffState(user, candidatePathwayId, state).then(normalizeCandidatePathway);
export const transitionCandidatePathwaysStaffState = (user, candidatePathwayIds, state) =>
  SelectedBackend.transitionCandidatePathwaysStaffState(user, candidatePathwayIds, state).then(normalizeCandidatePathways);
export const transitionCandidatePathwayCandidateState = (user, candidatePathwayId, state) =>
  SelectedBackend.transitionCandidatePathwayCandidateState(user, candidatePathwayId, state).then(normalizeCandidatePathway);

export const bulkCreateCandidatePathways = (user, pathwayInstanceId, data) => SelectedBackend.bulkCreateCandidatePathways(user, pathwayInstanceId, data);

// OAUTH
export const { OAUTH } = SelectedBackend;
