import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Redirect } from 'react-router';
import PathwayParentAccountReportsStyles from './pathway_parent_account_reports_styles';
import UserModel from '../../../session/models/user_model';
import Tabber from '../../../ui/components/tabber';
import Spinner from '../../../common/components/spinner';
import ParentAccountReportSummary from './parent_account_report_summary';
import ParentAccountReportDemographics from './parent_account_report_demographics';
import { useFetchOnce } from '../../../util/hooks';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  organizationName: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  childOrganizations: PropTypes.instanceOf(OrderedSet).isRequired,
  listChildOrganizations: PropTypes.func.isRequired,
  pathways: PropTypes.instanceOf(OrderedSet).isRequired,
  listPathways: PropTypes.func.isRequired,
  listCandidatePathwaysForOrganization: PropTypes.func.isRequired,
};

const defaultProps = {};

const PathwayParentAccountReportsView = ({
  currentUser,
  organizationName,
  organizationId,
  childOrganizations,
  listChildOrganizations,
  pathways,
  listPathways,
  listCandidatePathwaysForOrganization,
}) => {
  const childOrganizationsLoaded = useFetchOnce(currentUser, organizationId, listChildOrganizations);
  const pathwaysLoaded = useFetchOnce(currentUser, organizationId, listPathways);

  const childOrgIds = useRef(OrderedSet([]));
  const childOrgIdsRequested = useRef(OrderedSet([]));
  if (childOrganizations) {
    childOrganizations.forEach((child) => {
      childOrgIds.current = childOrgIds.current.add(child.get('id'));
    });
  }

  useEffect(() => {
    childOrgIds.current.forEach((id) => {
      if (!childOrgIdsRequested.current.has(id)) {
        childOrgIdsRequested.current = childOrgIdsRequested.current.add(id);
        listCandidatePathwaysForOrganization(currentUser, id);
      }
    });
  }, [currentUser, childOrganizations, childOrgIds, childOrgIdsRequested, listCandidatePathwaysForOrganization]);

  if ((childOrganizationsLoaded && !childOrganizations) || (pathwaysLoaded && !pathways)) {
    return <Redirect to={`/staff/reporting/pathways/${organizationId}`} />;
  }
  if (!childOrganizations || !pathways) {
    return <Spinner />;
  }

  return (
    <PathwayParentAccountReportsStyles>
      <h2>
        Parent Organization:
        <span style={{ fontWeight: 'normal' }}> {organizationName}</span>
      </h2>
      <Tabber>
        <div tabLabel="Pathway Summary">
          <ParentAccountReportSummary />
        </div>
        <div tabLabel="Pathway Demographics">
          <ParentAccountReportDemographics />
        </div>
      </Tabber>
    </PathwayParentAccountReportsStyles>
  );
};

PathwayParentAccountReportsView.propTypes = propTypes;
PathwayParentAccountReportsView.defaultProps = defaultProps;

export default PathwayParentAccountReportsView;
