// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const detailsFormValidate = (values) => {
  const errors = {};
  if (!values.get('primaryServices')) {
    errors.primaryServices = 'Required';
  }
  // if (!values.get('certificationCredential')) {
  //   errors.certificationCredential = 'Required';
  // }

  // if (!values.get('staffTeam')) {
  //   errors.staffTeam = 'Required';
  // }

  // if (!values.get('careerInterests')) {
  //   errors.careerInterests = 'Required';
  // }
  return errors;
};

export default detailsFormValidate;
