import { connect } from 'react-redux';
import JobView from './job_view';
import { getCurrentUser, getJobPosting, getCandidateApplications } from '../../../reducer';
import { listApplicationsToJobPosting, bulkCreateInvitations } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  currentUser: getCurrentUser(state),
  job: getJobPosting(state, id),
  applications: getCandidateApplications(state),
});

const mapDispatchToProps = {
  listApplicationsToJobPosting,
  bulkCreateInvitations,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  createInvitationsFromFile: (file) => dispatchProps.bulkCreateInvitations(stateProps.currentUser, 'job_postings', stateProps.job.get('id'), file),
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
});
const JobContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(JobView);

export default JobContainer;
