import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import moment from 'moment';
import sizeMe from 'react-sizeme';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import EmailIcon from '@material-ui/icons/Email';
import GradeIcon from '@material-ui/icons/Grade';
import HomeIcon from '@material-ui/icons/Home';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import SchoolIcon from '@material-ui/icons/School';
import WorkIcon from '@material-ui/icons/Work';

import { Card } from '../../../common/styles/layout';
import ResumeBox from './resume_box';
import ResumePage from './resume_page';
import ResumeText from './resume_text';
import ResumeStyles from './resume_styles';
import theme from '../../../common/theme';

const ResumeView = ({ candidate, liveResume, size }) => {
  let address = '';
  if (candidate.getIn(['address', 'city']) && candidate.getIn(['address', 'state'])) {
    address = `${candidate.getIn(['address', 'city'])}, ${candidate.getIn(['address', 'state'])}`;
  } else if (candidate.getIn(['user', 'zip'])) {
    address = `${candidate.getIn(['user', 'zip'])}`;
  }

  // Responsive Icon sizes
  const icon = {
    base: size.width * 0.03,
    small: size.width * 0.001 * 0.8125,
  };

  return (
    <ResumeStyles width={size.width}>
      {candidate && (
        <Card style={{ padding: 0 }}>
          <ResumePage id="capture">
            <Box mb={4}>
              <Grid container>
                <Grid item xs={8}>
                  <Grid container>
                    <Grid item xs={3} />
                    <Grid item xs={9}>
                      <ResumeText>
                        <h1>{`${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`}</h1>
                      </ResumeText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <ul>
                    {candidate.getIn(['user', 'phone']) && (
                      <ResumeBox>
                        <ResumeBox>
                          <LocalPhoneIcon style={{ color: theme.color.primary, width: `${icon.small}em`, height: `${icon.small}em` }} />
                        </ResumeBox>
                        <ResumeText>
                          <span>{candidate.getIn(['user', 'phone'])}</span>
                        </ResumeText>
                      </ResumeBox>
                    )}
                    {candidate.getIn(['user', 'email']) && (
                      <ResumeBox>
                        <ResumeBox>
                          <EmailIcon style={{ color: theme.color.primary, width: `${icon.small}em`, height: `${icon.small}em` }} />
                        </ResumeBox>
                        <ResumeText>
                          <span>{candidate.getIn(['user', 'email'])}</span>
                        </ResumeText>
                      </ResumeBox>
                    )}
                    {address && (
                      <ResumeBox>
                        <ResumeBox>
                          <HomeIcon style={{ color: theme.color.primary, width: `${icon.small}em`, height: `${icon.small}em` }} />
                        </ResumeBox>
                        <ResumeText>
                          <span>{address}</span>
                        </ResumeText>
                      </ResumeBox>
                    )}
                  </ul>
                </Grid>
              </Grid>
            </Box>

            {liveResume.get('workExperience').size > 0 && (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <ResumeText>
                      <h2>Work Experience</h2>
                      <hr />
                    </ResumeText>
                  </Grid>
                </Grid>

                {liveResume.get('workExperience').map((job, index) => {
                  const experience = job.get('experience');
                  return (
                    <Box mb={4} key={experience.get('title').concat(experience.get('position'))}>
                      <Grid container>
                        <Grid item xs={2}>
                          <ResumeBox stretch gutters="right">
                            <ResumeBox>
                              <div style={{ fontSize: `${icon.base}px` }}>
                                {index > 0 ? '' : <WorkIcon fontSize="inherit" style={{ color: theme.color.primary, marginTop: 5 }} />}
                              </div>
                            </ResumeBox>
                            <ResumeText align="right" topPadding="7px">
                              <span>{moment(experience.get('startDate')).format('MM.YYYY')}</span>
                              <br />
                              <span>{experience.get('isCurrent') ? 'present' : moment(experience.get('endDate')).format('MM.YYYY')}</span>
                            </ResumeText>
                          </ResumeBox>
                        </Grid>
                        <Grid item xs={10}>
                          <ResumeText>
                            <h3>{experience.get('title')}</h3>
                          </ResumeText>
                          <ResumeText>
                            <h4>
                              {experience.get('company')}
                              {experience.get('city') && ',\u00A0'}
                            </h4>
                            {experience.get('city') && <em>{`${experience.get('city')}, ${experience.get('state')}`}</em>}
                          </ResumeText>
                          <ul>
                            {experience.get('descriptions') &&
                              experience.get('descriptions').map((description) => (
                                <ResumeText key={description}>
                                  <li>{description}</li>
                                </ResumeText>
                              ))}
                          </ul>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </React.Fragment>
            )}

            {liveResume.get('education').size > 0 && (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={2} />
                      <Grid item xs={10}>
                        <ResumeText>
                          <h2>Education</h2>
                          <hr />
                        </ResumeText>
                      </Grid>
                    </Grid>
                    {liveResume.get('education').map((school, index) => {
                      const education = school.get('education');
                      return (
                        <Box mb={4} key={education.get('school').concat(education.get('educationLevel'))}>
                          <Grid container>
                            <Grid item xs={2}>
                              <ResumeBox stretch gutters="right">
                                <ResumeBox>
                                  <div style={{ fontSize: `${icon.base}px` }}>
                                    {index > 0 ? '' : <SchoolIcon fontSize="inherit" style={{ color: theme.color.primary, marginTop: 5 }} />}
                                  </div>
                                </ResumeBox>
                                <ResumeText align="right" topPadding="7px">
                                  <span>{moment(education.get('startDate')).format('MM.YYYY')}</span>
                                  <br />
                                  <span>{education.get('isCurrent') ? 'present' : moment(education.get('endDate')).format('MM.YYYY')}</span>
                                </ResumeText>
                              </ResumeBox>
                            </Grid>
                            <Grid item xs={10}>
                              <ResumeText>
                                <h3>{education.get('school')}</h3>
                              </ResumeText>
                              <ResumeText>
                                <h4>{education.get('major')}</h4>
                              </ResumeText>
                              <ResumeText>
                                <p>{education.get('educationLevel')}</p>
                              </ResumeText>
                              <ul>
                                {education.get('descriptions') &&
                                  education.get('descriptions').map((achievement) => (
                                    <ResumeText key={achievement}>
                                      <li>{achievement}</li>
                                    </ResumeText>
                                  ))}
                              </ul>
                            </Grid>
                          </Grid>
                        </Box>
                      );
                    })}
                  </Grid>
                </Grid>
              </React.Fragment>
            )}

            <Box mb={4}>
              <Grid container>
                <Grid item xs={2} />
                {(liveResume.get('certifications').size > 0 || liveResume.get('unions').size > 0) && (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <ResumeText>
                        <h2>Certifications &amp; Unions</h2>
                        <hr />
                      </ResumeText>
                    </Grid>
                  </React.Fragment>
                )}
                {liveResume.get('skills').size > 0 && (
                  <Grid item xs={4}>
                    <ResumeText>
                      <h2>Skills</h2>
                      <hr />
                    </ResumeText>
                  </Grid>
                )}
              </Grid>

              {(liveResume.get('certifications').size > 0 || liveResume.get('unions').size > 0 || liveResume.get('skills').size > 0) && (
                <Grid container>
                  <Grid item xs={2}>
                    <ResumeBox>
                      <div style={{ fontSize: `${icon.base}px` }}>
                        <BeenhereIcon fontSize="inherit" style={{ color: theme.color.primary }} />
                      </div>
                    </ResumeBox>
                  </Grid>
                  {(liveResume.get('certifications').size > 0 || liveResume.get('unions').size > 0) && (
                    <Grid item xs={6}>
                      {liveResume.get('certifications').size > 0 && (
                        <React.Fragment>
                          <ResumeText>
                            <h4>Certifications</h4>
                          </ResumeText>
                          <ul>
                            {liveResume.get('certifications') &&
                              liveResume.get('certifications').map((certificate) => (
                                <ResumeText key={certificate}>
                                  <li>{certificate.getIn(['certification', 'name'])}</li>
                                </ResumeText>
                              ))}
                          </ul>
                        </React.Fragment>
                      )}

                      {liveResume.get('unions').size > 0 && (
                        <React.Fragment>
                          <ResumeText>
                            <h4>Unions</h4>
                          </ResumeText>
                          <ul>
                            {liveResume.get('unions').map((union) => (
                              <ResumeText key={union}>
                                <li>{union?.getIn(['union', 'name']) || union}</li>
                              </ResumeText>
                            ))}
                          </ul>
                        </React.Fragment>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={4}>
                    {liveResume.get('skills').size > 0 && (
                      <ResumeBox flex="column">
                        {liveResume.get('skills').map((skill) => (
                          <ResumeText key={skill}>
                            <p>{skill?.getIn(['skill', 'name']) || skill}</p>
                          </ResumeText>
                        ))}
                      </ResumeBox>
                    )}
                  </Grid>
                </Grid>
              )}
            </Box>

            {liveResume.get('trainingPrograms').size > 0 && (
              <React.Fragment>
                <Grid container>
                  <Grid item xs={2} />
                  <Grid item xs={10}>
                    <ResumeText>
                      <h2>Training Programs</h2>
                      <hr />
                    </ResumeText>
                  </Grid>
                </Grid>
                {liveResume.get('trainingPrograms').map((trainingProgram, index) => {
                  const program = trainingProgram.get('program');
                  return (
                    <Box mb={4} key={`program-${program.get('title')}`}>
                      <Grid container>
                        <Grid item xs={2}>
                          <ResumeBox stretch gutters="right">
                            <ResumeBox>
                              <div style={{ fontSize: `${icon.base}px` }}>
                                {index > 0 ? '' : <GradeIcon fontSize="inherit" style={{ color: theme.color.primary, marginTop: 5 }} />}
                              </div>
                            </ResumeBox>
                            <ResumeText align="right" topPadding="7px">
                              <span>{moment(program.get('startDate')).format('MM.YYYY')}</span>
                              <br />
                              <span>{program.get('isCurrent') ? 'present' : moment(program.get('endDate')).format('MM.YYYY')}</span>
                            </ResumeText>
                          </ResumeBox>
                        </Grid>
                        <Grid item xs={10}>
                          <ResumeText>
                            <h3>{program.get('title')}</h3>
                          </ResumeText>
                          <ResumeText>
                            <em>
                              {program.get('city')}, {program.get('state')}
                            </em>
                          </ResumeText>
                          <ul>
                            {program.get('descriptions') &&
                              program.get('descriptions').map((description) => (
                                <ResumeText key={description}>
                                  <li>{description}</li>
                                </ResumeText>
                              ))}
                          </ul>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </React.Fragment>
            )}
          </ResumePage>
        </Card>
      )}
    </ResumeStyles>
  );
};

ResumeView.propTypes = {
  candidate: PropTypes.instanceOf(Iterable),
  liveResume: PropTypes.instanceOf(Iterable),
  size: PropTypes.shape({
    width: PropTypes.number.isRequired,
  }),
};

ResumeView.defaultProps = {
  candidate: undefined,
  liveResume: undefined,
  size: undefined,
};

export default sizeMe()(ResumeView);
