import styled from 'styled-components';
import theme from '../../../common/theme';

const JobListStyles = styled.div`
  table {
    width: 100%;
    border-spacing: 0px;
    th {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.1em;
      color: ${theme.color.text.medium};
      text-align: left;
    }
    td {
      border-top: solid 1px #ccc;
    }
    tr:hover td {
      border-color: ${theme.color.text.medium};
    }
    td {
      h4 {
        color: #394148;
      }
      h4,
      p {
        margin: 0;
      }
      color: #777;
      font-size: 14px;
    }
  }
`;

export const ResultsPageNavigator = styled.div`
  display: flex;
  align-content: flex-start;
  margin: 16px 0px;
  .prev-next-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    & > span {
      margin: 0px 12px;
    }
  }
`;

export default JobListStyles;
