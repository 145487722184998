import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { startCase } from 'lodash';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import DisplayStyles from './display_styles';
import formatPhoneNumber from '../../../../../util/format_phone_number';

const propTypes = {
  contactInfo: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const DisplayView = ({ contactInfo }) => {
  return (
    <DisplayStyles>
      <div className="field">
        <span className="label">First Name: </span>
        <span className="value">{contactInfo.getIn(['user', 'firstName'])}</span>
      </div>

      <div className="field">
        <span className="label">Last Name: </span>
        <span className="value">{contactInfo.getIn(['user', 'lastName'])}</span>
      </div>

      <div className="field">
        <span className="label">Email: </span>
        <span className="value">{contactInfo.getIn(['user', 'email'])}</span>
      </div>

      <div className="field">
        <span className="label">Cell Phone: </span>
        <span className="value">{formatPhoneNumber(contactInfo.getIn(['user', 'phone']))}</span>
      </div>

      <div className="field">
        <span className="label">Gender: </span>
        <span className="value">{startCase(contactInfo.get('genderType'))} </span>
        <span className="visible_icon" style={{ verticalAlign: 'middle' }}>
          <VisibilityOff style={{ fontSize: '15px' }} />{' '}
        </span>
        <span className="visible_label">Not visible on your resume </span>
      </div>

      <div className="field">
        <span className="label">Ethnicity: </span>
        <span className="value">{startCase(contactInfo.get('ethnicityType'))} </span>
        <span className="visible_icon" style={{ verticalAlign: 'middle' }}>
          <VisibilityOff style={{ fontSize: '15px' }} />{' '}
        </span>
        <span className="visible_label">Not visible on your resume </span>
      </div>

      <div className="field">
        <span className="label">LinkedIn: </span>
        <span className="value">{contactInfo.get('linkedInUrl')}</span>
      </div>

      <div className="field">
        <span className="label">Have you been in the military? </span>
        <span className="value">{contactInfo.get('military') ? 'Yes' : 'No'}</span>
      </div>
    </DisplayStyles>
  );
};

DisplayView.propTypes = propTypes;
DisplayView.defaultProps = defaultProps;

export default DisplayView;
