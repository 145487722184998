import styled from 'styled-components';
import theme from '../theme';

export const DashboardCard = styled.div`
  position: relative;
  margin: 10px 0px 20px;
  color: ${theme.color.grayDark};
  background-color: white;
  // box-shadow: 5px 5px 40px 0 rgba(0, 0, 0, 0.05);
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 35px;
  border-bottom: ${(props) => (props.noBorder ? '' : 'solid 7px #f7f7f7')};
  h2 {
    margin: ${(props) => (props.padding ? '10px 0px 10px' : '10px 0px 0px')};
    font-size: 1rem;
    line-height: 1.25;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }
  .select-dropdown {
    margin-top: 0px !important;
  }
`;

export const CardBody = styled.div`
  padding: 0px 35px 0px;
  padding-top: ${(props) => (props.paddingTop ? '20px' : '0px')};
  padding-bottom: ${(props) => (props.paddingBottom ? '20px' : '0px')};
  border-bottom: ${(props) => (props.border ? 'solid 7px #f7f7f7' : '')};
`;

export const CardItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;

  p,
  span {
    display: flex;
    align-items: center;
    margin: 0;
    color: ${theme.color.gray};
    font-size: 0.9rem;
    font-weight: 700;
    line-height: 1.45em;
  }
  a {
    display: flex;
    align-items: center;
  }
  svg {
    color: ${theme.color.grayLight};
    margin-right: 10px;
  }
  .chev-right {
    color: ${theme.color.blue};
    width: 20px;
  }

  &.not-started p,
  &.complete p {
    font-weight: 400;
  }

  &.complete p,
  &.complete span,
  &.complete svg {
    color: ${theme.color.grayLight};
  }
`;

export const CardSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  P {
    margin: 0;
  }
  span {
    text-transform: uppercase;
    font-size: 0.8em;
    line-height: 20px;
  }
  .dot {
    margin: 0px 10px;
  }
`;

export const Pill = styled.span`
  height: 22px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px;
  border: solid 1px ${theme.color.grayLight};
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 11px;
  margin-right: 15px;
  color: ${theme.color.gray};
`;

export const OutlineAction = styled.button`
  padding: 0;
  border: 0;
  outline: 0;
  button {
    border-radius: 5px;
    outline: 0;
    color: ${theme.color.blue} !important;
    padding: 0px;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0px 35px;
  padding: 15px 0px 20px;

  a {
    display: flex;
    align-items: center;
    font-weight: 700;
  }
  svg {
    margin-right: -7px;
    width: 20px;
  }
`;

export const JobItem = styled.div`
  .company {
    margin-left: 5px;
    color: ${theme.color.gray};
  }

  .job-info {
    padding-bottom: 15px;
    border-bottom: solid 1px #e5e5e5;
    margin-bottom: 15px;
  }

  &:last-child .job-info {
    padding-bottom: 5px;
    border-bottom: none;
    margin-bottom: 0px;
  }

  button {
    float: right;
    border-radius: 5px;
    outline: 0;
    color: ${theme.color.blue};
    padding: 0px 0px 0px 10px;
  }

  button svg {
    width: 20px;
  }
`;

export const JobHeader = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const Date = styled.div`
  margin-right: 15px;
  padding: 2px 0px;
  color: #bdc3c7;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 20px;
`;

export const Location = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 0px;
  color: #bdc3c7;
  text-transform: uppercase;
  font-size: 0.8em;
  line-height: 20px;

  svg {
    width: 20px;
    margin-right: 2px;
    color: ${theme.color.grayLight};
  }
`;

export const Wrapper = styled.div`
  margin: 0px 35px;
  padding: 20px 0px 10px;
`;
