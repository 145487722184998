import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const DEFAULT_STATE = fromJS({
  jobCandidatesLoading: false,
  jobCandidatesLoaded: 0,
  jobCandidatesList: EMPTY_SET,
  filters: {},
});

const StaffJobCandidatesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_STAFF_JOB_CANDIDATES_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          jobCandidatesLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_JOB_CANDIDATES_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          jobCandidatesLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_JOB_CANDIDATES_FOR_STAFF.request:
      return state.merge({
        jobCandidatesLoading: true,
      });
    case ACTIONS.LIST_FILTERED_JOB_CANDIDATES_FOR_STAFF.success:
      return state.merge({
        jobCandidatesList: OrderedSet(action.response.result),
        jobCandidatesLoading: false,
        jobCandidatesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_JOB_CANDIDATES_FOR_STAFF.failure:
      return state.merge({
        jobCandidatesLoading: false,
        jobCandidatesLoaded: false,
        jobCandidatesList: EMPTY_SET,
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search');

export const getLoaded = (state) => state.get('jobCandidatesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getCandidateIds = (state) => state.get('jobCandidatesList') || DEFAULT_STATE.get('jobCandidatesList');

export default StaffJobCandidatesReducer;
