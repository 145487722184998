import styled from 'styled-components';
import theme from '../../../common/theme';

const EventParticipantsStyles = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 10px;

  h2 {
    margin: 0px;
  }

  .header-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media ${theme.query.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default EventParticipantsStyles;
