import styled from 'styled-components';
// import theme from '../../../common/theme';

const AddProgramStyles = styled.div`
  h3 {
    color: ${(props) => props.theme.color.secondary};
    letter-spacing: 0;
  }
  h4 {
    margin-bottom: 0px;
  }
  header {
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 40px;
  }
`;

export default AddProgramStyles;
