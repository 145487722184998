import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { makeStyles } from '@material-ui/core/styles';
import { MuiDatePicker } from '../../../../common/form/index';

const useStyles = makeStyles(() => ({
  stepSelect: {
    width: '165px',
  },
  stepArrow: {
    color: '#979797',
    margin: '7px 10px',
  },
}));

const PathwayStepStatusView = ({ onTransition, step, pathwayInstanceStep, onUpdate }) => {
  const classes = useStyles();
  const [ogTargetCompletionDate, updateOgTargetCompletionDate] = useState(pathwayInstanceStep.get('targetCompletionDate'));
  const [targetCompletionDate, updateTargetCompletionDate] = useState(pathwayInstanceStep.get('targetCompletionDate'));

  const [ogCompletedOn, updateOgCompletedOn] = useState(pathwayInstanceStep.get('completedOn'));
  const [completedOn, updateCompletedOn] = useState(pathwayInstanceStep.get('completedOn'));

  if (pathwayInstanceStep.get('completedOn') !== ogCompletedOn) {
    updateCompletedOn(pathwayInstanceStep.get('completedOn'));
    updateOgCompletedOn(pathwayInstanceStep.get('completedOn'));
  }

  if (pathwayInstanceStep.get('targetCompletionDate') !== ogTargetCompletionDate) {
    updateOgTargetCompletionDate(pathwayInstanceStep.get('targetCompletionDate'));
    updateTargetCompletionDate(pathwayInstanceStep.get('targetCompletionDate'));
  }

  const changeTargetCompletionDate = (value) => {
    updateTargetCompletionDate(value);
    onUpdate(pathwayInstanceStep.get('id'), { targetCompletionDate: value });
  };

  const changeCompletedOn = (value) => {
    updateCompletedOn(value);
    onTransition(pathwayInstanceStep.get('id'), 's_complete', { completedOn: value });
  };

  return (
    <div
      style={{
        display: 'flex',
        marginRight: '20px',
      }}
    >
      <div className="step-date">
        <MuiDatePicker
          input={{ onChange: changeTargetCompletionDate, value: targetCompletionDate }}
          variant="outlined"
          name={`targetCompletionDate-${step.get('id')}-${pathwayInstanceStep}`}
          margin="dense"
          fullWidth
          label="Target End Date"
          className={classes.stepSelect}
        />
      </div>
      <ArrowRightAltIcon className={classes.stepArrow} />
      <div item className="step-date">
        <MuiDatePicker
          input={{ onChange: changeCompletedOn, value: completedOn }}
          variant="outlined"
          name={`completedOn-${step.get('id')}-${pathwayInstanceStep}`}
          margin="dense"
          fullWidth
          label="Actual End Date"
          className={classes.stepSelect}
        />
      </div>
    </div>
  );
};

PathwayStepStatusView.propTypes = {
  step: PropTypes.instanceOf(Map).isRequired,
  pathwayInstanceStep: PropTypes.instanceOf(Map).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onTransition: PropTypes.func.isRequired,
};
PathwayStepStatusView.defaultProps = {};

export default PathwayStepStatusView;
