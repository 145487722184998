import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { Button } from '../../../common/components';
import Plan from '../../../pricing/components/plan';
import { Card } from '../../../common/styles/layout';
import UpgradeCheckoutStyles from './upgrade_checkout_styles';

const propTypes = {};

const defaultProps = {};

const UpgradeCheckoutView = () => (
  <UpgradeCheckoutStyles>
    {/*  Switch to use history.goBack with useHistory hook */}

    <Link to="/staff/upgrade">
      <Button buttonType="back" size="small">
        <KeyboardBackspaceIcon />
        Go Back
      </Button>
    </Link>
    <div style={{ height: 20 }} />
    <Card style={{ padding: 30 }}>
      <h2>Check out</h2>
      <h4>
        Your selected <span style={{ color: '#FFAB4A' }}>monthly</span> plan
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={{ backgroundColor: '#EAF9F4', padding: 10 }}>
            <Plan title="Community Influence" tagline="Small Business" price={300} />
          </div>
        </Grid>
        <Grid item xs={5}>
          <Button variant="contained" className="action-contained">
            Checkout with Stripe
          </Button>
          <h4 style={{ marginBottom: 0 }}>* Pay with a check</h4>
          <small>This option is only available for the yearly plan listed below.</small>
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={<Checkbox checked={false} onChange={() => {}} value="checkedA" />}
            label={
              <div>
                <b>I agree with the Terms and Conditions</b>
                <br />
                <small>You can download the Terms and Conditions bellow</small>
              </div>
            }
          />
          <div style={{ height: 20 }} />
          <Button fullWidth variant="outlined" className="action-outlined" startIcon={<CloudDownloadIcon />}>
            Download PDF
          </Button>

          <div style={{ height: 40 }} />
          <FormControlLabel
            control={<Checkbox checked={false} onChange={() => {}} value="checkedA" />}
            label={
              <div>
                <b>I agree with The Contract</b>
                <br />
                <small>You can download the Contract bellow</small>
              </div>
            }
          />
          <div style={{ height: 20 }} />
          <Button fullWidth variant="outlined" className="action-outlined" startIcon={<CloudDownloadIcon />}>
            Download PDF
          </Button>

          <div style={{ height: 20 }} />
          <Button fullWidth variant="outlined" className="secondary-outlined">
            See other plans
          </Button>
        </Grid>
      </Grid>
    </Card>

    <Card style={{ padding: 30 }}>
      <h4>
        By choosing a <span style={{ color: '#FFAB4A' }}>yearly plan</span>, you can ssave 0%
      </h4>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <div style={{ borderColor: '#ccc', borderWidth: 1, borderStyle: 'solid', borderRadius: 3, padding: 10 }}>
            <Plan title="Community Influence" tagline="Small Business" price={3000} term="yr" />
          </div>
        </Grid>
        <Grid item xs={5}>
          <Button variant="contained" className="action-contained">
            Checkout with Stripe
          </Button>
          <h4 style={{ marginBottom: 0 }}>* Pay with a check</h4>
          <small>
            You will need to send a check with the full yearly amount paid to RiseKit and mail the check to
            <b>1400 S. Michigan ave Retail C, Chicago, Il 60605</b>
            <br />
            <br />
            <i>Once we receive the check, we will activate your subscription.</i>
          </small>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant="outlined" className="secondary-outlined">
            See other plans
          </Button>
        </Grid>
      </Grid>
    </Card>
  </UpgradeCheckoutStyles>
);

UpgradeCheckoutView.propTypes = propTypes;
UpgradeCheckoutView.defaultProps = defaultProps;

export default UpgradeCheckoutView;
