import React from 'react';
import { OrderedSet } from 'immutable';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditingStyles from './editing_styles';
import {
  Field,
  FieldArray,
  MuiTextField,
  MuiSelect,
  MuiTextFieldArray,
  MuiCheckbox,
  MuiDatePicker,
  MuiThemeProvider,
  MuiFormTheme,
} from '../../../../../common/form/index';

const propTypes = {
  namespace: PropTypes.string.isRequired,
  educationLevelOptions: PropTypes.instanceOf(OrderedSet).isRequired,
  isCurrent: PropTypes.bool,
  // handleSubmit: PropTypes.func.isRequired,
  // educationLevelOptions: PropTypes.instanceOf(List).isRequired,
  // majorOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {
  isCurrent: false,
};

const EditingView = ({ namespace, educationLevelOptions, isCurrent }) => (
  <MuiThemeProvider theme={MuiFormTheme}>
    <EditingStyles>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="School" name={`${namespace}.education.school`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <MuiSelect options={educationLevelOptions} label="Education Level" name={`${namespace}.education.educationLevel`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          {/* <MuiSelect options={majorOptions} label="Major" name={`${namespace}.education.major`} margin="dense" fullWidth required /> */}
          <Field component={MuiTextField} variant="outlined" label="Major" name={`${namespace}.education.major`} margin="dense" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="City" name={`${namespace}.education.city`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="State" name={`${namespace}.education.state`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={MuiDatePicker}
            variant="outlined"
            label="Date From (MM-DD-YYYY) [Select 1st of the month if unsure about the specific date]"
            name={`${namespace}.education.startDate`}
            margin="dense"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Field component={MuiCheckbox} name={`${namespace}.education.isCurrent`} margin="dense" fullWidth />}
            label="I am currently working on this degree"
          />
        </Grid>
        {!isCurrent && (
          <Grid item xs={12}>
            <Field component={MuiDatePicker} variant="outlined" label="Date To (MM-DD-YYYY) [Select 1st of the month if unsure about the specific date]" name={`${namespace}.education.endDate`} margin="dense" fullWidth />
          </Grid>
        )}

        <Grid item xs={12}>
          <header>Tell us more details about your education and any transferable skills</header>
          <FieldArray
            component={MuiTextFieldArray}
            label="Add a responsibility/achievement"
            name={`${namespace}.education.descriptions`}
            margin="dense"
            multiline
            required
          />
        </Grid>
      </Grid>
    </EditingStyles>
  </MuiThemeProvider>
);

EditingView.propTypes = propTypes;
EditingView.defaultProps = defaultProps;

export default EditingView;
