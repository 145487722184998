import styled from 'styled-components';
import theme from '../../../../common/theme';

const ScreenerFormStyles = styled.div`
  form {
    width: 100%;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  button {
    outline: none;
  }

  .screener-form {
    padding: 5px 10px 25px;
  }

  @media ${theme.query.desktopMax} {
    form {
      padding: 0px 5px;
    }
    .screener-form {
      padding: 0px 0px 20px;
    }
  }

  .custom-questions {
    span {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;

      > div {
        flex: 2;
      }
    }
  }
`;

export default ScreenerFormStyles;
