import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  numberOfQuestions: undefined,
  answered: undefined,
  skipped: undefined,
  unanswered: undefined,
  survey: {
    id: undefined,
    state: undefined,
    title: undefined,
    surveyType: undefined,
    iconUrl: undefined,
    owningOrganizationID: undefined,
  },
  surveyAnswers: undefined,
};

class CandidateSurveyModel extends Record(DEFAULT_ARGS) {
  static _name = 'CandidateSurvey';

  get score() {
    return (parseInt(this.get('answered'), 10) / parseInt(this.get('numberOfQuestions'), 10)) * 100;
  }

  get total() {
    return `${this.get('answered')}/${this.get('numberOfQuestions')}`;
  }
}

export default CandidateSurveyModel;
