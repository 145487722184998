import { Map } from 'immutable';
import ACTIONS from './actions';

const EMPTY_MAP = new Map();

const Reducer = (state = EMPTY_MAP, action) => {
  if (action.type === ACTIONS.DISMISS) {
    return state.set(action.id, true);
  }
  return state;
};

export default Reducer;

export const getDismissed = (state, id) => state.get(id);
