import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import TileView from './tile_view';
import { getCandidateProgramForProgram, getCurrentUser, getProgram, getSystemEnumList } from '../../../reducer';
import { transitionCandidateProgramByCandidate, createCandidateProgram } from '../../../solve/actions';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

const mapStateToProps = (state, { id }) => {
  const program = getProgram(state, id);
  const candidateProgram = getCandidateProgramForProgram(state, id);
  const serviceTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), program?.get('serviceTypes')));
  const targetTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), program?.get('targetParticipantTypes')));

  return {
    id,
    currentUser: getCurrentUser(state),
    program,
    candidateProgram,
    serviceTypes,
    targetTypes,
  };
};

const mapDispatchToProps = {
  createCandidateProgram,
  transitionCandidateProgramByCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  createCandidateProgram: () => dispatchProps.createCandidateProgram(stateProps.currentUser, stateProps.id),
  deleteCandidateProgram: () => dispatchProps.transitionCandidateProgramByCandidate(stateProps.currentUser, stateProps.candidateProgram.get('id'), 'deleted'),
});

const TileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(TileView);

export default TileContainer;
