import styled from 'styled-components';

const TextStyles = styled.div`
  // font-family: ${({ theme }) => theme.font.sansSerif};
  font-weight: 400;

  ${({ size }) => {
    switch (size) {
      case 'large':
        return `
        font-size: 1.4rem;
        p, em, strong {
          font-size: 1.4rem;
        }
      `;

      default:
      case 'medium':
        return `
        font-size: 1rem;
        p, em, strong {
          font-size: 1rem;
        }
      `;

      case 'small':
        return `
        font-size: 0.9rem;
        p, em, strong {
          font-size: 0.9rem;
        }
      `;

      case 'xsmall':
        return `
        font-size: 0.75rem;
        line-height: 1.2em;
        p, em, strong {
          font-size: 0.75rem;
          line-height: 1.2em;
        }
      `;
    }
  }};

  ${({ theme, color }) => {
    switch (color) {
      case 'dark':
        return `
        color: ${theme.color.text.dark};
        p, em, strong {
          color: ${theme.color.text.dark};
        }
      `;

      case 'medium':
        return `
        color: ${theme.color.text.medium};
        p, em, strong {
          color: ${theme.color.text.medium};
        }
      `;

      case 'light':
        return `
        color: ${theme.color.text.light};
        p, em, strong {
          color: ${theme.color.text.light};
        }
      `;

      default:
        return `
          color: ${theme.color.text.dark};
          color: ${color};
          p, em, strong {
            color: ${theme.color.text.dark};
            color: ${color};
          }
      `;
    }
  }};

  ${({ margin }) => {
    switch (margin) {
      default:
      case 'none':
        return '';

      case 'top':
        return `
        margin-top: 1em;
      `;

      case 'bottom':
        return `
        margin-bottom: 1em;
      `;

      case 'both':
        return `
        margin-top: 1em;
        margin-bottom: 1em;
      `;
    }
  }};

  ${({ inline }) => {
    switch (inline) {
      case true:
        return `
        display: inline;
      `;

      default:
      case false:
        return '';
    }
  }};

  ${({ weight }) => {
    switch (weight) {
      case 'bold':
        return `
        font-weight: 700;
      `;

      case 'light':
        return `
        font-weight: 300;
      `;

      default:
        return `
        font-weight: 400;
      `;
    }
  }};

  ${({ italic }) => {
    switch (italic) {
      case true:
        return `
        font-style: italic;
      `;

      default:
      case false:
        return '';
    }
  }};

  ${({ uppercase }) => {
    switch (uppercase) {
      case true:
        return `
          text-transform: uppercase;
        `;

      default:
      case false:
        return '';
    }
  }};
`;

export default TextStyles;
