import React from 'react';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import formatPercent from '../../../../../util/format_percent';

const propTypes = {
  organization: PropTypes.instanceOf(Map).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const OrganizationPathwaySummaryView = ({ reportDetails, organization }) => (
  <tr key={organization.get('id')}>
    <th>
      <Link to={`/staff/reporting/pathways/${organization.get('id')}`}>{organization.get('name')}</Link>
    </th>
    <td>{reportDetails.get('enrolled')}</td>
    <td>{reportDetails.get('active')}</td>
    <td>{reportDetails.get('completed')}</td>
    <td>{formatPercent(reportDetails.get('overallRetention'))}</td>
    <td>{formatPercent(reportDetails.get('adjustedRetention'))}</td>
    <td>{reportDetails.get('totalDropped')}</td>
    <td>{reportDetails.get('involuntarilyDropped')}</td>
    <td>{reportDetails.get('voluntarilyDropped')}</td>
  </tr>
);

OrganizationPathwaySummaryView.propTypes = propTypes;
OrganizationPathwaySummaryView.defaultProps = defaultProps;

export default OrganizationPathwaySummaryView;
