/* eslint-disable max-len */
import React from 'react';
import numeral from 'numeral';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import Grid from '@material-ui/core/Grid';
import LanguageIcon from '@material-ui/icons/Language';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import theme from '../../../common/theme';
import ProgramFlyerStyles from './program_flyer_styles';
import { formatTime } from '../../../util/format_datetimes';
import { Text, TextHeading } from '../../../common/components';
import formatPhoneNumber from '../../../util/format_phone_number';

const propTypes = {
  address: PropTypes.instanceOf(Map),
  classDays: PropTypes.instanceOf(Map),
  description: PropTypes.string,
  endTime: PropTypes.date,
  industryType: PropTypes.string,
  isClass: PropTypes.bool,
  name: PropTypes.string,
  paymentCost: PropTypes.string,
  phone: PropTypes.string,
  primaryServices: PropTypes.instanceOf(Map),
  skillsGained: PropTypes.instanceOf(Map),
  skillsCertifications: PropTypes.instanceOf(Map),
  supportiveServices: PropTypes.instanceOf(Map),
  time: PropTypes.date,
  website: PropTypes.string,
};

const defaultProps = {
  address: Map(),
  classDays: Map(),
  description: '',
  endTime: '',
  industryType: '',
  isClass: false,
  name: '',
  paymentCost: '',
  phone: '',
  primaryServices: Map(),
  skillsGained: Map(),
  skillsCertifications: Map(),
  supportiveServices: Map(),
  time: '',
  website: '',
};

const ProgramFlyerView = ({
  address,
  classDays,
  description,
  endTime,
  industryType,
  isClass,
  name,
  paymentCost,
  phone,
  primaryServices,
  skillsGained,
  skillsCertifications,
  supportiveServices,
  time,
  website,
}) => {
  const selectedPrimaryServices = primaryServices.filter(Boolean);
  const selectedSupportiveServices = supportiveServices.filter(Boolean);

  return (
    <ProgramFlyerStyles>
      <Grid container spacing={2}>
        {/* <Grid item md={2} style={{ paddingRight: 20 }}>
          <img src="/images/program-logo.png" width="100%" alt="Logo" />
        </Grid> */}
        <Grid item md={8}>
          <h1>{name}</h1>
          {/* <em>Housing Program Name</em> */}
        </Grid>
        <Grid item md={4} style={{ boxShadow: 'inset 1px 0 0 lightgray' }}>
          <ul className="contact-info">
            {website && (
              <li>
                <LanguageIcon style={{ color: theme.color.action }} />
                {website}
              </li>
            )}
            {phone && (
              <li>
                <LocalPhoneIcon style={{ color: theme.color.action }} />
                {formatPhoneNumber(phone)}
              </li>
            )}
            <li>
              <BeenhereIcon style={{ color: theme.color.action }} />
              {address.get('address1') && `${address.get('address1')}, `}
              {address.get('city') && `${address.get('city')}, `}
              {address.get('state') && `${address.get('state')} `}
              {address.get('zip') && `${address.get('zip')} `}
            </li>
          </ul>
        </Grid>

        <Grid item md={12} style={{ marginTop: '20px' }}>
          {description && <Text size="small" className="description" dangerouslySetInnerHTML={{ __html: description }} />}

          {/* <h3>Develop skills you need for the job you want.</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </p>
          <h3>Earn while you learn.</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </p>
          <h3>Get ongoing support and guidance.</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </p>
          <h3>Meet your Mentor.</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
            cillum dolore eu fugiat nulla pariatur.
          </p> */}
        </Grid>
        <Grid item md={4}>
          <TextHeading size="small" color="dark" weight="bold" margin="top">
            Industry Type
          </TextHeading>
          {industryType && (
            <ul className="capitalize">
              <li>
                <Text color="medium">{startCase(industryType)}</Text>
              </li>
            </ul>
          )}

          {selectedPrimaryServices && selectedPrimaryServices.size > 0 && (
            <React.Fragment>
              <TextHeading size="small" color="dark" weight="bold" margin="top">
                Primary Services
              </TextHeading>
              <ul className="capitalize">
                {selectedPrimaryServices.keySeq().map((service) => (
                  <li key={service}>
                    <Text color="medium">{service.replace(/([A-Z])/g, ' $1')}</Text>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}

          {selectedSupportiveServices && selectedSupportiveServices.size > 0 && (
            <React.Fragment>
              <TextHeading size="small" color="dark" weight="bold" margin="top">
                Support Services
              </TextHeading>
              <ul className="capitalize">
                {selectedSupportiveServices.keySeq().map((service) => (
                  <li key={service}>
                    <Text color="medium">{service.replace(/([A-Z])/g, ' $1')}</Text>
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
          <br />
        </Grid>
        <Grid item md={1} />

        {isClass && (
          <Grid item md={7}>
            <div className="pdf-class-wrapper">
              <header className="pdf-class-header">
                <TextHeading size="small" color="dark" weight="bold">
                  Class Schedule
                </TextHeading>
                <img className="pdf-class-img img1" src="/images/class-img1.svg" alt="illustration of a briefcase" />
              </header>

              <div className="pdf-class-body">
                {classDays && (
                  <TextHeading size="medium" margin="bottom">
                    {classDays.map((day, index) => (
                      <span className="capitalize" key={day}>
                        {(index ? ' / ' : '') + day}
                      </span>
                    ))}
                  </TextHeading>
                )}

                {time && (
                  <Text size="small" color={theme.color.positive} margin="bottom">
                    {formatTime(time)}
                    {endTime && `–${formatTime(endTime)}`}
                  </Text>
                )}

                <hr />
                {skillsCertifications && (
                  <React.Fragment>
                    <Text size="small" color="dark" weight="bold" margin="top">
                      <CheckCircleIcon style={{ color: theme.color.action, verticalAlign: 'bottom' }} /> Certifications you will gain
                    </Text>
                    <Text size="small" color="medium" margin="bottom" inline>
                      {skillsCertifications.map((skill, index) => (
                        <span className="capitalize" key={skill}>
                          {(index ? ', ' : '') + startCase(skill)}
                        </span>
                      ))}
                    </Text>
                    <br />
                  </React.Fragment>
                )}

                {skillsGained && (
                  <React.Fragment>
                    <Text size="small" color="dark" weight="bold" margin="top">
                      <CheckCircleIcon style={{ color: theme.color.action, verticalAlign: 'bottom' }} /> Skills you will learn
                    </Text>
                    <Text size="small" color="medium" margin="bottom" inline>
                      {skillsGained.map((skill, index) => (
                        <span className="capitalize" key={skill}>
                          {(index ? ', ' : '') + startCase(skill)}
                        </span>
                      ))}
                    </Text>
                    <br />
                  </React.Fragment>
                )}

                {/* <Text size="small" color="dark" weight="bold" margin="top">
                  <CheckCircleIcon style={{ color: theme.color.action, verticalAlign: 'bottom' }} /> Requirements
                </Text>
                <Text size="small" color="medium" margin="bottom" inline>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text>
                <br /> */}
                <br />
                <Text>Price: {(paymentCost && numeral(paymentCost).format('$0')) || 'FREE'}</Text>

                <img className="pdf-class-img img2" src="/images/class-img2.svg" alt="illustration of a clock" />
                <img className="pdf-class-img img3" src="/images/class-img3.svg" alt="illustration of a megaphone" />
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </ProgramFlyerStyles>
  );
};

ProgramFlyerView.propTypes = propTypes;
ProgramFlyerView.defaultProps = defaultProps;

export default ProgramFlyerView;
