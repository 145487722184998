import moment from 'moment';
import { flatten } from 'lodash';
import { reset } from 'redux-form';
import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import PathwayInviteCandidatesView from './pathway_invite_candidates_view';
import { getPathwayInstance, getCurrentUser } from '../../../../reducer';
import { bulkCreateCandidatePathways } from '../../../../solve/actions';

const buildInitialValues = (pathwayInstance) => {
  const milestones = pathwayInstance.getIn(['pathway', 'pathwayMilestones']);

  const getAllMilestonePathwayStepIds = (milestone) =>
    milestone
      .getIn(['pathwayMilestone', 'pathwaySteps'])
      .filter((step) => step.get('pathwayStepCategoryType') !== 'pathway')
      .map((step) => step.get('id'));

  const allPathwayStepIds = flatten(milestones.map(getAllMilestonePathwayStepIds).toJS());

  const initialPathwayStepDates = allPathwayStepIds.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: '',
    }),
    {},
  );

  return fromJS({ startDate: moment(0, 'HH').toDate(), pathwayStepDates: initialPathwayStepDates });
};

const buildSubmitValues = (formValues) => ({
  startsOn: formValues.get('startDate'),
  candidate_ids: formValues
    .get('candidates')
    .filter(Boolean)
    .keySeq()
    .toList(),
  pathwayInstanceStepsAttributes: Object.entries(formValues.get('pathwayStepDates').toJS()).map((step) => ({
    pathwayStepId: step[0],
    targetCompletionDate: step[1],
  })),
});

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const pathwayInstance = id && getPathwayInstance(state, id);

  return {
    initialValues: buildInitialValues(pathwayInstance),
    currentUser: getCurrentUser(state),
    pathwayInstance,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getPathwayInstance,
    bulkCreateCandidatePathways,
    resetCandidateForm: () => dispatch(reset('selectCandidatesForm')),
  };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  handleSubmit: (formValues) => {
    const values = buildSubmitValues(formValues);
    return dispatchProps.bulkCreateCandidatePathways(stateProps.currentUser, stateProps.pathwayInstance.get('id'), values);
  },
  // listAllCandidates: () => dispatchProps.listAllCandidates(stateProps.currentUser, stateProps.organizationId),
  // listAllCandidates: () => dispatchProps.listAllCandidates(stateProps.currentUser, stateProps.organizationId),
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const PathwayInviteCandidatesContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayInviteCandidatesView);

export default PathwayInviteCandidatesContainer;
