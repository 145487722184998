import { Record } from 'immutable';

const DEFAULT_ARGS = {
  city: undefined,
  company: undefined,
  country: undefined,
  date: undefined,
  state: undefined,
  distance: undefined,
  expired: undefined,
  formattedLocation: undefined,
  formattedLocationFull: undefined,
  formattedRelativeTime: undefined,
  indeedApply: undefined,
  jobkey: undefined,
  jobtitle: undefined,
  language: undefined,
  latitude: undefined,
  longitude: undefined,
  onmousedown: undefined,
  refNum: undefined,
  snippet: undefined,
  url: undefined,
};

class IndeedJobPostingModel extends Record(DEFAULT_ARGS) {
  static _name = 'IndeedJobPosting';
}

export default IndeedJobPostingModel;
