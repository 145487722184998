import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import TermsAndConditionStyles from './terms_and_condition_styles';

const TermsAndConditionView = ({ onReadTermsAndCondition }) => {
  const termsAndConditionRef = useRef();

  const onScroll = () => {
    if (termsAndConditionRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = termsAndConditionRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        onReadTermsAndCondition();
      }
    }
  };

  return (
    <TermsAndConditionStyles>
      <div onScroll={onScroll} className="terms-and-condition__wrapper" ref={termsAndConditionRef}>
        <h2>
          <span>User Consent and Acceptance of RiseKit&rsquo;s Terms of Use and Privacy Policy</span>
        </h2>
        <p>
          <span>
            At RiseKit, it is our mission to help you seek and gain long-lasting employment through our software and our partner network. We have certain rules
            and policies in place regarding how our products and services (collectively, &ldquo;Services&rdquo;) and Website are to be used. &nbsp;Please review
            our <a href="https://www.risekit.co/terms">Terms of Use</a>. and <a href="https://www.risekit.co/privacy">Privacy Policy</a>.
          </span>
        </p>
        <p>
          <span>By clicking &ldquo;Register&rdquo; below, you:</span>
        </p>
        <ol start="1">
          <li>
            <span>Certify that you are 16 years of age or older;</span>
          </li>
        </ol>
        <ul>
          <li>
            <span>
              if you are between the ages of 16 and 18, you verify that you have received permission from your parent or guardian to access and use our Services
              and the Website.
            </span>
          </li>
          <li>
            <span>if you are under the age of 16, you should not be registering to use our Services or Website.</span>
          </li>
        </ul>
        <ol start="2">
          <li>
            <span>Verify that you have read, understand, and agree to the Terms of Use and Privacy Policy of RiseKit;</span>
          </li>
          <li>
            <span>
              Verify that your use of the Website and Services, including submitting any information, is not and will not be in violation of our Terms of Use or
              Privacy Policy;
            </span>
          </li>
          <li>
            <span>
              Authorize RiseKit to use and/or disclose your information consistent with our Terms of Use and Privacy Policy, including permitting RiseKit and
              authorized third-party users of the Website and/or our Services to help keep your RiseKit Profile accurate and up-to-date in our system;
            </span>
          </li>
          <li>
            <span>
              Consent to voluntarily sharing employment challenges (i.e. personal data related to potential barriers such as housing, childcare, transportation,
              criminal history, health care matters, etc.) with RiseKit to help it provide Services to you.
            </span>
          </li>
          <li>
            <span>Understand that RiseKit </span>
            <span>does not sell your individual personal data to anyone</span>
            <span>&nbsp;and will take extra care in safeguarding (and </span>
            <span>not sharing with employers</span>
            <span>)</span>
            <span>&nbsp;any identified challenges as part of your journey </span>
            <span>(i.e. potential barriers such as housing, childcare, transportation, criminal history, and health care matters</span>
            <span>). </span>
            <span>
              Only RiseKit personnel and any non-profits with which you have already voluntarily engaged will have visibility to, and ability to update, this
              information.
            </span>
          </li>
          <li>
            <span>
              Consent to, agree to be bound by, and promise to remain in compliance with our Terms of Use and Privacy Policy, including any updates or
              amendments;
            </span>
          </li>
          <li>
            <span>
              Understand that, if you do not fully agree to or with the Terms of Use or Privacy Policy, your only option is to not use our Website or Services;
            </span>
          </li>
          <li>
            <span>
              Understand that, as set forth in Privacy Policy, you have the right to revoke this authorization, for all or any part of your information, at any
              time, by deleting your Profile and/or sending written notification to:{' '}
            </span>
            <span>
              <a href="mailto:INFO@RISEKIT.CO">
                INFO@RISEKIT.CO
              </a>
            </span>
            <span>; and</span>
          </li>
          <li>
            <span>Understand that you have the right to refuse to submit this registration consent.</span>
          </li>
        </ol>
      </div>
    </TermsAndConditionStyles>
  );
};

TermsAndConditionView.defaultProps = {
  onReadTermsAndCondition: () => {},
};

TermsAndConditionView.propTypes = {
  onReadTermsAndCondition: PropTypes.func,
};

export default TermsAndConditionView;
