import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import { makeStyles } from '@material-ui/core/styles';
import CandidateStepFileUpload from './candidate_step_file_upload';
import { MuiDatePicker } from '../../../../common/form/index';

import { updatePathwayInstanceStep, fetchCandidatePathway, transitionPathwayStepCandidateState } from '../../../../solve/actions';
import { getCurrentUser } from '../../../../reducer';

import { CandidateStepStyles, MilestoneCard, Step, Banner } from './candidate_step_styles';

const useStyles = makeStyles(() => ({
  bannerContainer: {
    height: '70px',
  },
  descriptionText: {
    width: '80%',
  },
  showMoreButton: {
    minWidth: '100px',
    marginTop: '-6px',
  },
  stepSelect: {
    width: '165px',
  },
}));

const getStepColor = (stepType) => {
  switch (stepType) {
    case 'document':
      return '#9D71D6';
    case 'event':
      return '#FFAB4A';
    case 'assessment':
      return '#67B4FF';
    case 'action':
    default:
      return '#4BC076';
  }
};

const candidateStates = [
  { label: 'Not Started', value: 's_not_started' },
  { label: 'In Progress', value: 's_in_progress' },
  { label: 'Completed', value: 's_complete' },
];

/**
 * NOTE: This will need to display only those pathway instance steps of category
 * 'candidate' for this specific candidate - waiting on api/seed to verify candidate model
 * */
const SingleStepForm = ({ onTransition, step, pathwayInstanceStep, candidatePathwayId }) => {
  const classes = useStyles();
  const [showFullDesc, toggleShowFullDesc] = useState(false);
  const targetCompletionDate = pathwayInstanceStep.get('targetCompletionDate');

  const [ogCompletedOn, updateOgCompletedOn] = useState(pathwayInstanceStep.get('completedOn'));
  const [completedOn, updateCompletedOn] = useState(pathwayInstanceStep.get('completedOn'));

  if (pathwayInstanceStep.get('completedOn') !== ogCompletedOn) {
    updateCompletedOn(pathwayInstanceStep.get('completedOn'));
    updateOgCompletedOn(pathwayInstanceStep.get('completedOn'));
  }

  const [assessmentScore, updateAssessmentScore] = useState(pathwayInstanceStep.getIn(['extra', 'assessmentScore']));

  const truncatedDesc = (desc) => {
    if (desc.length > 100) {
      return `${desc.substring(0, 100)}...`;
    }
    return desc;
  };

  const changeCompletedOn = (value) => {
    updateCompletedOn(value);
    onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), 's_complete', { completedOn: value });
  };

  const stepColor = getStepColor(step.get('pathwayStepType'));

  const changeAssessmentScore = (event) => {
    const score = event.target.value;
    updateAssessmentScore(score);
    const dateToday = new Date();
    onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), 's_complete', { completedOn: dateToday, extra: { assessmentScore: score } });
  };

  return (
    <CandidateStepStyles>
      <form>
        <Grid item container wrap="nowrap">
          <Grid item>
            <AssignmentTurnedInIcon style={{ marginRight: '12px', color: stepColor }} />
          </Grid>
          <Grid item container direction="column">
            <Grid item container wrap="nowrap">
              <Grid item style={{ fontWeight: 'bold', color: stepColor, fontSize: '1rem' }}>
                {step.get('name')}
              </Grid>
            </Grid>
            <Grid item container justify="space-between">
              <Grid item className={classes.descriptionText}>
                <Typography variant="body2" color="primary">
                  {showFullDesc ? step.get('description') : truncatedDesc(step.get('description'))}
                </Typography>
              </Grid>
              <Grid item>
                {step.get('description').length > 100 && (
                  <Button size="small" color="secondary" className={`${classes.showMoreButton} show-more`} onClick={() => toggleShowFullDesc(!showFullDesc)}>
                    {showFullDesc ? 'show less' : 'show more'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Step>
          <div className="step-container">
            <div className="step-item">
              {step.get('pathwayStepType') === 'assessment' && (
                <FormControl variant="outlined" style={{ float: 'left' }}>
                  <InputLabel>Assessment Score</InputLabel>
                  <OutlinedInput margin="dense" onBlur={changeAssessmentScore} value={assessmentScore} />
                </FormControl>
              )}
              {step.get('pathwayStepType') === 'document' && (
                <FormControl variant="outlined" style={{ float: 'left' }}>
                  <InputLabel>Upload Document</InputLabel>
                  <CandidateStepFileUpload
                    candidatePathwayId={candidatePathwayId}
                    pisId={pathwayInstanceStep.get('id')}
                    form={`step-upload-${step.get('id')}`}
                  />
                  {pathwayInstanceStep.get('fileUrl') !== null && (
                    <a target="_blank" rel="noopener noreferrer" href={pathwayInstanceStep.get('fileUrl')}>
                      Download doc
                    </a>
                  )}
                </FormControl>
              )}
              {(step.get('pathwayStepType') === 'action' || step.get('pathwayStepType') === 'event') && <div />}
            </div>
            <div className="step-info">
              <div className="step-wrapper">
                <div className="step-date">
                  <MuiDatePicker
                    input={{ value: targetCompletionDate }}
                    variant="outlined"
                    name={`targetCompletionDate-${step.get('id')}`}
                    margin="dense"
                    fullWidth
                    label="Target End Date"
                    className={`${classes.stepSelect} step-date-picker`}
                    disabled
                  />
                </div>
                <ArrowRightAltIcon className="step-arrow" />
                <div item className="step-date">
                  <MuiDatePicker
                    input={{ onChange: changeCompletedOn, value: completedOn }}
                    variant="outlined"
                    defaultDate={targetCompletionDate}
                    name={`completedOn-${step.get('id')}`}
                    margin="dense"
                    fullWidth
                    label="Actual End Date"
                    className={`${classes.stepSelect} step-date-picker`}
                  />
                </div>
              </div>
              <div className="status-container">
                <img src="/icons/check-green-circle.png" alt="status-indicator" />
                <FormControl>
                  <InputLabel>Status</InputLabel>
                  {/* <Select variant="outlined" color="primary" margin="dense" className={classes.stepSelect} value="applied">
                  <MenuItem value="applied">Applied</MenuItem>
                  <MenuItem value="inProgress">In Progress</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="withdrawn">Withdrawn</MenuItem>
                </Select> */}
                  <Select
                    className={classes.stepSelect}
                    color="primary"
                    margin="dense"
                    variant="outlined"
                    value={pathwayInstanceStep.get('staffState')}
                    onChange={(e) => onTransition(candidatePathwayId, pathwayInstanceStep.get('id'), e.target.value)}
                  >
                    {candidateStates.map((candidateState) => (
                      <MenuItem key={candidateState.value} value={candidateState.value}>
                        {candidateState.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </Step>
      </form>
    </CandidateStepStyles>
  );
};

SingleStepForm.propTypes = {
  step: PropTypes.instanceOf(Map).isRequired,
  pathwayInstanceStep: PropTypes.instanceOf(Map).isRequired,
  onTransition: PropTypes.func.isRequired,
  candidatePathwayId: PropTypes.string.isRequired,
};
SingleStepForm.defaultProps = {};

// const SingleStep = reduxForm({ form: 'candidateStepForm' })(SingleStepForm);

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});
const mapDispatchToProps = {
  updatePathwayInstanceStep,
  transitionPathwayStepCandidateState,
  fetchCandidatePathway,
};
const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onTransition: (candidatePathwayId, id, state, extra) => {
    dispatchProps
      .transitionPathwayStepCandidateState(stateProps.currentUser, id, state.substring(2), extra)
      .then(() => dispatchProps.fetchCandidatePathway(stateProps.currentUser, candidatePathwayId));
  },
  ...ownProps,
  ...stateProps,
});
export const SingleStep = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SingleStepForm);

const CandidateStepView = ({ milestone, pathwayInstanceSteps, candidatePathwayId }) => {
  const classes = useStyles();

  let letItRender = false;

  // check if this is going to be a blank one, and if so, don't render it.
  milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map((checkstep) => {
    const checkPis = pathwayInstanceSteps.find(
      (pis) =>
        pis.get('pathwayStepId') === checkstep.get('id') &&
        pis.getIn(['pathwayMilestone', 'id']) === checkstep.get('pathwayMilestoneId') &&
        checkstep.get('pathwayStepCategoryType') === 'candidate',
    );
    if (checkPis !== undefined) {
      letItRender = true;
    }
    return letItRender;
  });

  if (!letItRender) {
    return null;
  }

  return (
    <div>
      <MilestoneCard elevation={2}>
        <Grid container direction="column">
          <Grid item className={classes.bannerContainer}>
            <Banner>{milestone.getIn(['pathwayMilestone', 'name'])}</Banner>
          </Grid>
          <Grid item container spacing={3} direction="column">
            {milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map((step) => {
              const pathwayInstanceStep = pathwayInstanceSteps.find(
                (pis) => pis.get('pathwayStepId') === step.get('id') && pis.getIn(['pathwayMilestone', 'id']) === step.get('pathwayMilestoneId'),
              );
              if (pathwayInstanceStep === undefined || step === undefined) {
                return '';
              }
              return <SingleStep step={step} candidatePathwayId={candidatePathwayId} pathwayInstanceStep={pathwayInstanceStep} key={step.get('id')} />;
            })}
          </Grid>
        </Grid>
      </MilestoneCard>
    </div>
  );
};

CandidateStepView.propTypes = {
  milestone: PropTypes.instanceOf(Map).isRequired,
  candidatePathwayId: PropTypes.string.isRequired,
  pathwayInstanceSteps: PropTypes.instanceOf(List).isRequired,
};
CandidateStepView.defaultProps = {};

export default CandidateStepView;
