import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ProfileFormStyles from './profile_form_styles';
import { reduxForm, Field, MuiTextField, MuiFileUpload, MuiSelect, MuiDatePicker, MuiCheckbox, phoneMask } from '../../../common/form';
import Button from '../../../common/components/button/index';
import { Card } from '../../../common/styles/layout';
import profileFormValidate from './profile_form_validate';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  avatar: PropTypes.instanceOf(FileList),
  isEmployer: PropTypes.bool,
  genderTypes: PropTypes.instanceOf(List).isRequired,
  nonProfitStaffResponsibilities: PropTypes.instanceOf(List).isRequired,
  employerStaffResponsibilities: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {
  avatar: undefined,
  isEmployer: false,
};

const ProfileForm = ({
  employerStaffResponsibilities,
  isEmployer,
  handleSubmit,
  pristine,
  submitting,
  avatar,
  genderTypes,
  nonProfitStaffResponsibilities,
}) => {
  let responsibilities;

  if (isEmployer) {
    responsibilities = employerStaffResponsibilities;
  } else {
    responsibilities = nonProfitStaffResponsibilities;
  }

  return (
    <ProfileFormStyles>
      <form onSubmit={handleSubmit}>
        <h2>Create Your Profile</h2>
        <div>{avatar && <img src={URL.createObjectURL(avatar[0])} height="70" alt="Avatar" />}</div>
        <Field component={MuiFileUpload} label="Add Your Avatar" name="avatar" margin="dense" />
        <div style={{ height: 20 }} />
        <Card>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <h3>Personal Information</h3>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={6}>
                      <Field component={MuiTextField} label="First Name" name="userAttributes.firstName" margin="dense" variant="outlined" fullWidth required />
                    </Grid>
                    <Grid item xs={6}>
                      <Field component={MuiTextField} label="Last Name" name="userAttributes.lastName" margin="dense" variant="outlined" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Mobile Phone Number"
                        name="userAttributes.phone"
                        type="cell"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...phoneMask}
                      />
                    </Grid>
                    <Grid item xs={7}>
                      <Field
                        component={MuiTextField}
                        label="Work Phone Number"
                        name="workPhone"
                        type="cell"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...phoneMask}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Field component={MuiTextField} label="Extension" name="workPhoneExtension" type="number" margin="dense" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Primary Email"
                        name="userAttributes.email"
                        type="email"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Alternative Email"
                        name="userAttributes.alternateEmails.a"
                        type="email"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <MuiSelect options={genderTypes} label="Gender" name="genderType" margin="dense" />
                    </Grid>
                    <Grid item xs={12}>
                      <Field component={MuiDatePicker} label="Birthday (mm-dd-yyyy)" name="birthday" margin="dense" variant="outlined" fullWidth />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={1} />
            <Grid item md={5}>
              <h3>Roles And Responsibilities</h3>
              <cite>Check all that apply</cite>
              {responsibilities.map((role) => (
                <Grid item xs={12} key={role.get('slug')}>
                  <FormControlLabel
                    control={<Field component={MuiCheckbox} name={`staffResponsibilityTypes.${role.get('slug')}`} />}
                    label={role.get('label')}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <div style={{ height: 30 }} />
        </Card>
        <Button variant="contained" className="action-contained" disabled={pristine || submitting} onClick={handleSubmit} style={{ float: 'right' }}>
          Create Profile
        </Button>
      </form>
    </ProfileFormStyles>
  );
};

ProfileForm.propTypes = propTypes;
ProfileForm.defaultProps = defaultProps;

const ProfileFormView = reduxForm({
  form: 'staffProfile',
  enableReinitialize: true,
  validate: profileFormValidate,
})(ProfileForm);

export default ProfileFormView;
