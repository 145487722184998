import { useState, useEffect } from 'react';
import { STATUS_CODE } from 'risekit/app/app.status';
import { getPathwayList } from 'risekit/api/request.api';
import { PathwayListPayload } from 'risekit/api/api.types';

const useGetPathwayList = ({ token, orgId, params }: PathwayListPayload) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>();
  const [totalCount, setTotalCount] = useState<number>(0);

  const [pathways, setPathway] = useState<any[]>();

  const { page, size, pathway_instance_name, _sort, _order } = params;

  useEffect(() => {
    setLoading(true);
    const fetchPathwayList = async () => {
      const { data, error: apiError, totalCount: count } = await getPathwayList({
        token,
        orgId,
        params: { page, size, pathway_instance_name, _sort, _order },
      });

      const isRequestCanceled = apiError?.messages !== STATUS_CODE.REQUEST_CANCEL;

      if (apiError && !isRequestCanceled) {
        setError(apiError);
        setLoading(false);
      }
      if (!apiError) {
        setPathway(data);
        setTotalCount(count || 0);
        setLoading(false);
      }
    };

    fetchPathwayList();
  }, [token, orgId, page, size, pathway_instance_name, _sort, _order]);

  return { loading, error, pathways, totalCount };
};

export default useGetPathwayList;
