import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  address: undefined,
  applicationUrl: undefined,
  certifications: undefined,
  createdAt: undefined,
  customFollowUpMessage: undefined,
  description: undefined,
  desiredEducation: undefined,
  desiredSkillTypes: undefined,
  distance: undefined,
  employer: undefined,
  employerVisibilityType: undefined,
  endDate: undefined,
  industryType: undefined,
  jobSharingType: undefined,
  jobType: undefined,
  numberOfOpenings: undefined,
  opportunityTypes: undefined,
  orgCategoryType: undefined,
  payAmount: undefined,
  payLevelType: undefined,
  payType: undefined,
  postExpirationDate: undefined,
  postingOrganization: undefined,
  requiredSkillTypes: undefined,
  responsibilities: undefined,
  sendApplicationUrlToMatches: undefined,
  shiftType: undefined,
  startDate: undefined,
  state: undefined,
  surveys: undefined,
  title: undefined,
};

class JobPostingModel extends Record(DEFAULT_ARGS) {
  static _name = 'JobPosting';
}

export default JobPostingModel;
