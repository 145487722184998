import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { TextHeading } from '../../../common/components';

const DialogBox = styled.div`
  .dialog {
    margin-left: 220px !important;
  }
`;

export default function DialogView({ open, handleClose, handleSubmit, title, children, maxWidth = 'md', isSubmittable }) {
  return (
    <DialogBox>
      <Dialog open={open} onClose={handleClose} className="dialog" maxWidth={maxWidth} fullWidth>
        <DialogActions style={{ padding: '30px 30px 0px' }}>
          <Button onClick={handleClose}>{isSubmittable ? 'Cancel' : <CloseIcon />}</Button>
          {isSubmittable && <Button onClick={handleSubmit}>Submit</Button>}
        </DialogActions>
        <DialogTitle style={{ padding: '0px 75px' }}>
          <TextHeading size="medium" weight="bold" margin="bottom">
            {title}
          </TextHeading>
        </DialogTitle>
        <DialogContent style={{ padding: '0px 75px 75px' }}>{children}</DialogContent>
      </Dialog>
    </DialogBox>
  );
}

DialogView.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func,
  maxWidth: PropTypes.string,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isSubmittable: PropTypes.bool,
};

DialogView.defaultProps = {
  maxWidth: 'md',
  handleSubmit: () => {},
  isSubmittable: false,
};
