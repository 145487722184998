import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OrderedSet } from 'immutable';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import ReplyIcon from '@material-ui/icons/Reply';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Tile from '../../../messaging/components/tile';
import { TextHeading } from '../../../common/components';
import MessagesListOutboxStyles from './messages_list_outbox_styles';
import { useDebouncedEffect } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  messages: PropTypes.instanceOf(OrderedSet).isRequired,
  messagesCount: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  listMessages: PropTypes.func.isRequired,
};

const defaultProps = {};

function sortMessages(messages, newSearch) {
  return messages.filter((message) =>
    `${message.get('messageSubject')} ${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`
      .toLowerCase()
      .includes(newSearch.toLocaleLowerCase()),
  );
}

function useSearch(search, setSearch) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const MessagesListOutboxView = ({ listMessages, currentUser, messages, messagesCount, onUpdate }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [messagesResults, setMessagesResults] = useState([]);

  const { newSearch, onSearchChange } = useSearch(searchTerm, setSearchTerm);

  useEffect(() => {
    listMessages(currentUser);
  }, [listMessages, currentUser]);

  useEffect(() => {
    if (!searchTerm) {
      setMessagesResults(messages);
      return;
    }
    const results = sortMessages(messages, searchTerm);
    setMessagesResults(results);
  }, [messages, searchTerm]);

  return (
    <MessagesListOutboxStyles>
      <TextField
        id="search"
        placeholder="Search Messages"
        margin="dense"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 10 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={newSearch}
        onChange={onSearchChange}
      />

      {messagesCount > 0 ? (
        <TextHeading size="large" weight="bold">
          {messagesCount} Outbound Message{messagesCount <= 1 ? '' : 's'}
        </TextHeading>
      ) : (
        <TextHeading size="large" weight="bold">
          No Outbound Messages
        </TextHeading>
      )}
      {messagesResults &&
        messagesResults.map((message) => (
          <Tile
            key={message.get('id')}
            backgroundColor="#F7FCFF"
            icon="/icons/avatar.svg"
            iconColor="#F1F0F0"
            title={`${message.getIn(['toUser', 'firstName'])} ${message.getIn(['toUser', 'lastName'])}`}
            subTitle={`${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`}
            subject={message.get('messageSubject')}
            body={message.get('messageBody')}
            createdAt={message.get('createdAt')}
            toolbarButtons={[
              <IconButton
                color="inherit"
                size="small"
                key={`${message.get('id')} star`}
                onClick={() => onUpdate(message.get('id'), { isStarred: !message.get('isStarred') })}
              >
                <StarBorderIcon style={{ color: message.get('isStarred') ? '#52CA5B' : '#b8c0bc' }} />
              </IconButton>,
              <Link key={`${message.get('id')} reply`} to={`/staff/messages/${message.get('id')}`}>
                <IconButton color="inherit" size="small">
                  <ReplyIcon />
                </IconButton>
              </Link>,
              <IconButton key={`${message.get('id')} delete`} color="inherit" size="small" onClick={() => onUpdate(message.get('id'), { isDeleted: true })}>
                <DeleteForeverIcon />
              </IconButton>,
            ]}
          />
        ))}
    </MessagesListOutboxStyles>
  );
};

MessagesListOutboxView.propTypes = propTypes;
MessagesListOutboxView.defaultProps = defaultProps;

export default MessagesListOutboxView;
