import React from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SelectOnboardingStyles from './select_onboarding_styles';

const propTypes = {
  setOnboardingMode: PropTypes.func.isRequired,
};

const defaultProps = {};

const SelectOnboardingView = ({ setOnboardingMode }) => (
  <SelectOnboardingStyles>
    <h2>Before we get started, what type of account do you want to create?</h2>
    <div className="button-group">
      <Button variant="contained" className="secondary-contained" size="large" onClick={() => setOnboardingMode('candidate')}>
        Join as Job Seeker
      </Button>
      <Button variant="contained" className="action-contained" size="large" onClick={() => setOnboardingMode('staff')}>
        Join as Nonprofit
      </Button>
      <Button variant="contained" className="cta-contained" size="large" onClick={() => setOnboardingMode('employer')}>
        Join as Employer
      </Button>
    </div>
  </SelectOnboardingStyles>
);

SelectOnboardingView.propTypes = propTypes;
SelectOnboardingView.defaultProps = defaultProps;

export default SelectOnboardingView;
