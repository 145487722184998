import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  Pagination,
} from 'react-admin';
import LocationOnIcon from '@material-ui/icons/LocationOn';

export const Icon = LocationOnIcon;

// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} pagination={<PostPagination />}>
    <Datagrid>
      address1, :address2, :city, :state, :zip
      <TextField source="id" />
      <TextField source="address1" />
      <TextField source="address2" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="zip" />
      <TextField source="latitude" />
      <TextField source="longitude" />
      <EditButton basePath="/addresses" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>Address{record ? ` "${record.id}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zip" />
      <TextInput source="latitude" />
      <TextInput source="longitude" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a Address" {...props}>
    <SimpleForm>
      <TextInput source="address1" />
      <TextInput source="address2" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zip" />
    </SimpleForm>
  </AdminCreate>
);
