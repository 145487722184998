import React from 'react';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import DisplayStyles from './display_styles';

const propTypes = {
  unions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

const DisplayView = ({ unions }) => (
  <DisplayStyles>
    <div className="field">
      <div className="unions-container">
        {unions &&
          unions
            .map((_, idx) => unions.get(idx).get('union'))
            .map((union, idx) => (
              <span key={union.get('slug') || union.get('name')} className="value unions">
                {union.get('name')}
                <IconButton onClick={() => unions.remove(idx)} aria-label="delete">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            ))}
      </div>
    </div>
  </DisplayStyles>
);
DisplayView.propTypes = propTypes;
DisplayView.defaultProps = defaultProps;

export default DisplayView;
