import React from 'react';
import CandidatesListStyles from './candidates_list_styles';
import Tabber from '../../../ui/components/tabber';
import Candidates from '../candidates';
import CandidatesInvited from '../candidates_invited';

const propTypes = {};

const defaultProps = {};

const CandidatesListView = () => (
  <CandidatesListStyles>
    <Tabber>
      <div tabLabel="Active Candidates">
        <Candidates />
      </div>
      <div tabLabel="Invitations">
        <CandidatesInvited />
      </div>
    </Tabber>
  </CandidatesListStyles>
);

CandidatesListView.propTypes = propTypes;
CandidatesListView.defaultProps = defaultProps;

export default CandidatesListView;
