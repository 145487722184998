/* eslint-disable import/no-unresolved */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import FormGroup from '@material-ui/core/FormGroup';
import { fromJS, List, Map, OrderedSet } from 'immutable';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { SUCCESS, OOPS, NOT_SAVED, QUESTIONNAIRE_SUCCESS, CHECK_FIELD } from 'risekit/lang/en/validation';
import { success, error } from 'risekit/common/components/toast';

import { getEnumsForFormSelect } from '../../../reducer';

import CandidateSurveyModel from '../../../solve/models/candidate_survey_model';

import { Clear, Check, QuestionContainer } from './staff_survey_wizard_styles';

import { reduxForm, Field, MuiCheckbox, MuiRadioGroup, MuiSelect, MuiTextField } from '../../../common/form';

const EMPTY_MAP = fromJS({});

const QuestionPrompt = ({ question }) => {
  const templatePrompt = question.getIn(['questionTemplate', 'question']);

  // Convert from Array of Maps [ { "<0>": "banana" }, { "<1>": 28 } ] -> Map { "<0>": "banana", "<1>": 28 }
  // const placeholders = question.getIn(['extra', 'questionPlaceholders'], EMPTY_LIST).reduce((a, b) => a.merge(b), EMPTY_MAP);

  const placeholdersUnfiltered = question.getIn(['extra', 'questionPlaceholders'], EMPTY_MAP);
  const placeholders = List.isList(placeholdersUnfiltered) ? placeholdersUnfiltered.reduce((a, b) => a.merge(b), EMPTY_MAP) : placeholdersUnfiltered;
  let prompt;
  if (placeholders && placeholders.size > 0) {
    prompt = placeholders.reduce((str, value, placeholder) => str.replace(placeholder, value), templatePrompt);
  } else {
    prompt = templatePrompt;
  }
  return <h4 style={{ marginBottom: '0px' }}>{prompt}</h4>;
};
QuestionPrompt.propTypes = {
  question: PropTypes.instanceOf(Map).isRequired,
};

const useChoices = (template) => {
  const enumId = template.getIn(['extra', 'enumId']);
  const enumList = useSelector((state) => getEnumsForFormSelect(state, enumId));

  const templateAttribute = template.getIn(['howToApplyAnswer', 'attribute']);

  if (enumId && templateAttribute === 'current_employment_job_type') {
    const unemployedOption = ['currently-unemployed', 'Currently unemployed'];

    return [...enumList, unemployedOption];
  }

  // If enum available
  if (enumId) {
    return enumList;
  }

  // If yes/no
  return template
    .get('answers')
    .map((x) => (typeof x === 'string' ? [x, x] : x))
    .toList();
};

const choiceRendererPropTypes = {
  template: PropTypes.instanceOf(Map).isRequired,
  answerId: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool.isRequired,
  isMarked: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
};

const SingleChoiceRenderer = ({ template, answerId, isCorrect, isMarked, editing }) => {
  const choices = useChoices(template);
  let markings = '';
  if (isMarked) {
    markings = isCorrect ? <Check /> : <Clear />;
  }
  return (
    <QuestionContainer>
      <Field name={`answer.${answerId}`} component={MuiRadioGroup}>
        <FormGroup>
          {choices.map((choice) => (
            <FormControlLabel
              disabled={!editing}
              key={choice[0]}
              value={choice[0]}
              control={<Radio color="primary" />}
              label={choice[1]}
              labelPlacement="end"
            />
          ))}
        </FormGroup>
      </Field>
      {markings}
    </QuestionContainer>
  );
};
SingleChoiceRenderer.propTypes = choiceRendererPropTypes;

const DropdownYesNoRenderer = ({ answerId, editing }) => {
  const choices = fromJS(['Yes', 'No']).map((x) => [x, x]);

  return (
    <div>
      <Field name={`answer.${answerId}`} component={MuiRadioGroup}>
        <FormGroup>
          {choices.map((choice) => (
            <FormControlLabel
              key={choice[0]}
              value={choice[0]}
              disabled={!editing}
              control={<Radio color="primary" />}
              label={choice[1]}
              labelPlacement="end"
            />
          ))}
        </FormGroup>
      </Field>
    </div>
  );
};
const choiceRendererDropdownPropTypes = {
  answerId: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
};
DropdownYesNoRenderer.propTypes = choiceRendererDropdownPropTypes;

const DropdownChoiceRenderer = ({ template, answerId, isCorrect, isMarked, editing }) => {
  const choices = useChoices(template);
  let markings = '';
  if (isMarked) {
    markings = isCorrect ? <Check style={{ marginLeft: '10px' }} /> : <Clear style={{ marginLeft: '10px' }} />;
  }
  return (
    <QuestionContainer>
      <MuiSelect options={choices} name={`answer.${answerId}`} margin="dense" disabled={!editing} style={{ marginTop: '0px' }} />
      {markings}
    </QuestionContainer>
  );
};
DropdownChoiceRenderer.propTypes = choiceRendererPropTypes;

const MultiChoiceRenderer = ({ template, answerId, isCorrect, isMarked, editing }) => {
  const choices = useChoices(template);
  let markings = '';
  if (isMarked) {
    markings = isCorrect ? <Check /> : <Clear />;
  }
  return (
    <QuestionContainer>
      <FormGroup>
        {choices.map((choice) => (
          <FormControlLabel
            disabled={!editing}
            key={choice[0]}
            control={<Field component={MuiCheckbox} name={`answer.${answerId}.${choice[0]}`} />}
            label={choice[1]}
          />
        ))}
      </FormGroup>
      {markings}
    </QuestionContainer>
  );
};
MultiChoiceRenderer.propTypes = choiceRendererPropTypes;

const FreeTextChoiceRenderer = ({ answerId, editing }) => (
  <div>
    <Field disabled={!editing} name={`answer.${answerId}`} component={MuiTextField} margin="dense" variant="outlined" fullWidth />
  </div>
);
FreeTextChoiceRenderer.propTypes = {
  answerId: PropTypes.string.isRequired,
  editing: PropTypes.bool.isRequired,
};

const CHOICE_RENDERERS = {
  'single-choice': SingleChoiceRenderer,
  dropdown: DropdownChoiceRenderer,
  'dropdown-yes-no': DropdownYesNoRenderer,
  'multi-choice': MultiChoiceRenderer,
  'free-text': FreeTextChoiceRenderer,
};
const unknownAnswerTypeRenderer = (type) => () => `Unknown question type: "${type}"`;

const SingleChoiceIngester = (list) => list.get(0);
const MultiChoiceIngester = (list) => Map(list.map((item) => [item, true]));
const ANSWER_CONVERTERS = {
  'single-choice': SingleChoiceIngester,
  dropdown: SingleChoiceIngester,
  'dropdown-yes-no': SingleChoiceIngester,
  'multi-choice': MultiChoiceIngester,
  'free-text': SingleChoiceIngester,
};

const SurveyQuestionRenderer = ({ question, answerId, isCorrect, isMarked, editing }) => {
  const type = question.getIn(['questionTemplate', 'answerType']);
  const ChoiceRenderer = CHOICE_RENDERERS[type] || unknownAnswerTypeRenderer(type);
  return (
    <div>
      <QuestionPrompt question={question} />
      <ChoiceRenderer template={question.get('questionTemplate')} answerId={answerId} isCorrect={isCorrect} isMarked={isMarked} editing={editing} />
    </div>
  );
};
SurveyQuestionRenderer.propTypes = {
  question: PropTypes.instanceOf(Map).isRequired,
  answerId: PropTypes.string.isRequired,
  isCorrect: PropTypes.bool,
  isMarked: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
};
SurveyQuestionRenderer.defaultProps = {
  isCorrect: true,
};

const SurveyView = ({ survey, isMarked, canEdit, canView, handleSubmit, pristine, submitting }) => {
  const [editing, setEditing] = useState(false);

  const handleSubmitAndToggleEdit = (event) => {
    try {
      handleSubmit(event)
        .then(() => {
          setEditing(false);
          success(SUCCESS, QUESTIONNAIRE_SUCCESS);
        })
        .catch(() => {
          error(OOPS, NOT_SAVED);
        });
    } catch (err) {
      error(OOPS, CHECK_FIELD);
    }
  };

  const handleToggleEdit = () => {
    setEditing(!editing);
  };

  if (!survey.get('surveyAnswers') || !canView) {
    return 'You do not have permission to view these answers';
  }
  const sortedQuestions = survey.get('surveyAnswers').sortBy((item) => item.getIn(['surveyQuestion', 'position']));
  return (
    <form onSubmit={handleSubmitAndToggleEdit}>
      {canEdit ? (
        <Button variant="contained" className="action-contained" startIcon={editing ? <CancelIcon /> : <EditIcon />} onClick={handleToggleEdit}>
          {editing ? 'cancel' : 'Edit Survey'}
        </Button>
      ) : null}

      {sortedQuestions.map((data) => {
        const id = data.getIn(['surveyAnswer', 'id']).toString();
        const isCorrect = data.getIn(['surveyAnswer', 'isCorrectAnswer']);
        return (
          <SurveyQuestionRenderer key={id} question={data.get('surveyQuestion')} answerId={id} isCorrect={isCorrect} isMarked={isMarked} editing={editing} />
        );
      })}

      {editing && (
        <Button disabled={pristine || submitting} variant="contained" className="action-contained" startIcon={<SendIcon />} onClick={handleSubmitAndToggleEdit}>
          Submit
        </Button>
      )}
    </form>
  );
};

SurveyView.propTypes = {
  survey: PropTypes.oneOfType([PropTypes.instanceOf(CandidateSurveyModel), PropTypes.instanceOf(Map), PropTypes.instanceOf(OrderedSet)]).isRequired,
  isMarked: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const buildInitialValues = (survey) => {
  const initial = {};
  const answers = survey.get('surveyAnswers');
  if (answers) {
    answers.forEach((answer) => {
      const id = answer.getIn(['surveyAnswer', 'id']).toString();
      const rawAnswer = answer.getIn(['surveyAnswer', 'answer']);
      const questionType = answer.getIn(['surveyQuestion', 'questionTemplate', 'answerType']);
      const converter = ANSWER_CONVERTERS[questionType] || MultiChoiceIngester;
      const rawAnswerList = List.isList(rawAnswer) ? rawAnswer : List([rawAnswer]);
      initial[id] = converter(rawAnswerList);
    });
  }
  return { answer: initial };
};

const surveyForms = {};
const surveyForm = (x) => {
  if (!surveyForms[x]) {
    surveyForms[x] = reduxForm({ form: `candidateSurvey-${x}` })(SurveyView);
  }
  return surveyForms[x];
};

const StaffSurveyWizard = ({ survey, canEdit, canView, marked, onSubmit }) => {
  const Form = surveyForm(survey.get('id'));
  return <Form survey={survey} initialValues={buildInitialValues(survey)} canEdit={canEdit} canView={canView} isMarked={marked} onSubmit={onSubmit} />;
};

StaffSurveyWizard.propTypes = {
  survey: PropTypes.oneOfType([PropTypes.instanceOf(CandidateSurveyModel), PropTypes.instanceOf(Map), PropTypes.instanceOf(OrderedSet)]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  marked: PropTypes.bool,
  canEdit: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
};
StaffSurveyWizard.defaultProps = {
  marked: true,
};

export default StaffSurveyWizard;
