import React from 'react';
import PropTypes from 'prop-types';
// import { List } from 'immutable';
import Display from './display';
import Editing from './editing';
// import PDFUpload from './pdf_upload';
import UnionStyles from './unions_styles';
import { Card } from '../../../../common/styles/layout';
// import { Field } from 'redux-form/immutable';
// import MuiTextField from '../../../../common/form/material_ui/mui_text_field';
// import IconButton from '@material-ui/core/IconButton';
// import CancelIcon from '@material-ui/icons/Cancel';
// import AddIcon from '@material-ui/icons/Add';

const propTypes = {
  // unions: PropTypes.instanceOf(List),
  // onDeleteUnion: PropTypes.func.isRequired,
  // onUnionSubmit: PropTypes.func.isRequired,
  // onUploadPDFSubmit: PropTypes.func.isRequired,
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
};

const defaultProps = {
  // unions: new List(),
};

// FYI: This has been stripped down to bare-bones, just a FieldArray selector that injects values from an Enum list
const UnionsView = ({ fields }) => (
  <UnionStyles>
    <h3>Unions</h3>
    <Card>
      {/* <div> */}
      {/*  {fields.map((name, index) => ( */}
      {/*    <span key={name}> */}
      {/*  <Field name={name} type="text" component={MuiTextField} {...rest /* eslint-disable-line react/jsx-props-no-spreading *!/ /> */}
      {/*  <IconButton aria-label="cancel" onClick={() => fields.remove(index)}> */}
      {/*    <CancelIcon fontSize="small" /> */}
      {/*  </IconButton> */}
      {/* </span> */}
      {/*  ))} */}
      {/*  {error && <span className="error">{error}</span>} */}
      {/*  <IconButton aria-label="cancel" onClick={() => fields.push()}> */}
      {/*    <AddIcon fontSize="small" /> */}
      {/*  </IconButton> */}
      {/*  {label} */}
      {/* </div> */}

      <div className="editing-container">
        <Editing unions={fields} />
      </div>
      <div className="display-container">
        <Display unions={fields} />
      </div>
      {/* <div className="upload-container"> */}
      {/*  <PDFUpload onSubmit={onUploadPDFSubmit} /> */}
      {/* </div> */}
    </Card>
  </UnionStyles>
);

UnionsView.propTypes = propTypes;
UnionsView.defaultProps = defaultProps;

export default UnionsView;
