import styled from 'styled-components';
import theme from '../../../../common/theme';

const FinalizeFormStyles = styled.div`
  form {
    width: 100%;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }

  .button-right button:nth-child(1),
  .button-right button:nth-child(2) {
    margin-right: 20px;
  }

  button {
    outline: none;
  }

  .finalize-form {
    padding: 5px 10px 25px;
  }

  @media ${theme.query.desktopMax} {
    form {
      padding: 0px 5px;
    }
    .finalize-form {
      padding: 0px 0px 20px;
    }
    .button-group {
      flex-direction: row;
      align-items: flex-start;
    }
    .button-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .button-right button:nth-child(1),
    .button-right button:nth-child(2) {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
  @media ${theme.query.tabletMax} {
    .button-group {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .button-left {
      width: 100%;
    }
    button,
    .button {
      margin: 8px 0px;
      max-width: 210px;
      width: 100%;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }

  .pdf-class-wrapper {
    position: relative;
    padding-bottom: 1rem;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 1px;
      top: 2rem;
      bottom: 0;
      box-shadow: inset 1px 0 0 0 ${theme.color.blueLighter};
    }
  }

  .pdf-class-header {
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    padding-right: 2rem;
    height: 2em;
    line-height: 2em;
    background-color: ${theme.color.blueLighter};
    border-top-left-radius: 1.5em 1em;
    z-index: 1;

    &:before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      z-index: 0;
    }

    &:before {
      background: transparent;
      bottom: -2em;
      left: 0;
      width: 2em;
      height: 2em;
      border-top-left-radius: 1.5em 1em;
      box-shadow: 0 -1em 0 0 ${theme.color.blueLighter};
    }

    &:after {
      bottom: 0;
      right: -2em;
      border: 1em solid ${theme.color.blueLighter};
      border-right-width: 1.5em;
      border-right-color: transparent;
    }
  }

  .pdf-class-body {
    position: relative;
    padding-left: 2rem;
    z-index: 1;
  }

  .pdf-class-img {
    position: absolute;

    &.img1 {
      top: -50px;
      left: -25px;
      z-index: 0;
    }

    &.img2 {
      top: -100px;
      right: -60px;
    }

    &.img3 {
      bottom: -66px;
      right: -60px;
    }
  }
`;

export const ProgramReviewStyles = styled.div`
  .sub-heading {
    font-weight: bold;
    color: #394148;
  }
  p {
    margin: 5px 0px;
  }
  ul {
    padding-left: 30px;
    margin: 0px;
  }
  li {
    font-size: 16px;
    line-height: 24px;
    color: #6c7a89;
  }
`;

export default FinalizeFormStyles;
