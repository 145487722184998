/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import theme from '../../../../common/theme';

export const StyledTableRow = styled.tr`
  &:hover td {
    border-color: ${theme.color.text.medium};
  }
  & td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    & h4 {
      color: #394148;
    }
    & h4,
    p {
      margin: 0;
    }
  }
`;
