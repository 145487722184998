import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import PrivacyAgreementFormView from './privacy_agreement_form_view';
import { getCurrentUser, getCandidate, getTreatment } from '../../../../reducer';
import { fetchCandidate, updateCandidate } from '../../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const candidateId = currentUser.get('candidateId');
  const candidate = getCandidate(state, candidateId);

  return {
    currentUser,
    candidateId,
    isRisekit: !!getTreatment(state, 'isRisekit', true),
    shareChallengesPermitted: candidate.get('shareChallengesPermitted'),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  fetchCandidate,
  updateCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...stateProps,
  ...ownProps,
  onChange: (value) =>
    dispatchProps.updateCandidate(
      stateProps.currentUser,
      stateProps.candidateId,
      fromJS({
        shareChallengesPermitted: value,
      }),
    ),
});

const PrivacyAgreementFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PrivacyAgreementFormView);

export default PrivacyAgreementFormContainer;
