import { connect } from 'react-redux';
import MessageNewView from './message_new_view';
import { getCurrentUser, getCandidate, getReplyableUsers } from '../../../reducer';
import { fetchCandidate, listMessages, listPeerStaff, listAllCandidates, listCandidateOrganizations } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { candidateId },
    },
  },
) => {
  const currentUser = getCurrentUser(state);
  let candidate;
  if (candidateId) {
    candidate = getCandidate(state, candidateId);
  }
  return {
    currentUser,
    candidate,
    sendToOptions: getReplyableUsers(state),
    candidateId: candidateId || currentUser.get('candidateId'),
    organizationId: currentUser.getIn(['staffOrganization', 'id']),
  };
};

const mapDispatchToProps = {
  listMessages,
  listPeerStaff,
  fetchCandidate,
  listAllCandidates,
  listCandidateOrganizations,
};

const MessageNewContainer = connect(mapStateToProps, mapDispatchToProps)(MessageNewView);

export default MessageNewContainer;
