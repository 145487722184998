import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Redirect } from 'react-router';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  publicLinkTokens: PropTypes.instanceOf(OrderedSet),
  activePublicLink: PropTypes.shape({
    subjectType: PropTypes.string,
    subjectId: PropTypes.string,
  }),
  consumePublicLink: PropTypes.func.isRequired,
  inviteLinks: PropTypes.instanceOf(OrderedSet),
  activeInviteLink: PropTypes.shape({
    subjectType: PropTypes.string,
    subjectId: PropTypes.string,
  }),
  consumeInviteLink: PropTypes.func.isRequired,
  onboarding: PropTypes.bool.isRequired,
  flushActive: PropTypes.func.isRequired,
};

const newOrderedSet = new OrderedSet();

const defaultProps = {
  activePublicLink: undefined,
  activeInvitation: undefined,
  publicLinkTokens: newOrderedSet,
  inviteLinks: newOrderedSet,
};

function buildResourceUrl([subjectType, subjectId]) {
  switch (subjectType) {
    case 'JobPosting':
      return `/candidate/jobs/${subjectId}`;
    case 'ExternalJobPosting':
      return `/candidate/indeed-jobs/${subjectId}`;
    case 'Program':
      return `/candidate/programs/${subjectId}`;
    case 'Event':
      return `/candidate/events/${subjectId}`;
    case 'Organization':
      return '/dashboard';
    default:
      throw new Error(`unexpected public link type ${subjectType}`);
  }
}

const PublicLinkHandlerView = ({
  currentUser,
  onboarding,
  publicLinkTokens,
  activePublicLink,
  consumePublicLink,
  inviteLinks,
  activeInviteLink,
  consumeInviteLink,
  flushActive,
}) => {
  useEffect(() => {
    if (!onboarding && currentUser.signedIn) {
      publicLinkTokens.map((token) => consumePublicLink(currentUser, token));
    }
  }, [currentUser, publicLinkTokens, consumePublicLink, onboarding]);

  useEffect(() => {
    if (!onboarding && currentUser.signedIn) {
      inviteLinks.map((token) => consumeInviteLink(currentUser, token));
    }
  }, [currentUser, inviteLinks, consumeInviteLink, onboarding]);

  if (!onboarding && currentUser.signedIn && publicLinkTokens.isEmpty() && inviteLinks.isEmpty()) {
    const redirect = activePublicLink || activeInviteLink;
    if (redirect) {
      flushActive();
      return <Redirect to={buildResourceUrl(redirect)} />;
    }
  }
  return '';
};

PublicLinkHandlerView.propTypes = propTypes;
PublicLinkHandlerView.defaultProps = defaultProps;

export default PublicLinkHandlerView;
