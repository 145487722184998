import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  uid: undefined,
  externalApplicationUid: undefined,
  externalApplicationNumericId: undefined,
  externalResourceUid: undefined,
  externalResourceNumericId: undefined,
  externalProvider: undefined,
  lastSynchronized: undefined,
  candidateId: undefined,
  externalStatus: undefined,
  candidateState: undefined,
  staffState: undefined,
  screeningScore: undefined,
  acceptedInvitationAt: undefined,
  appliedAt: undefined,
  droppedOutAt: undefined,
  joinedAt: undefined,
  confirmedGraduatedAt: undefined,
  allRequiredAnswersMatch: undefined,
  candidateDeletedAt: undefined,
  createdAt: undefined,
  updatedAt: undefined,
};

class ExternalCandidateServiceModel extends Record(DEFAULT_ARGS) {
  static _name = 'ExternalCandidateServices';
}

export default ExternalCandidateServiceModel;
