import styled from 'styled-components';
import theme from '../../../../common/theme';
// import theme from '../../../common/theme';

const JoinSolveAsStaffPreviewStyles = styled.div``;

export const PreviewCard = styled.div`
  background-color: #ffffff;
  box-shadow: 0px 2px 10px rgba(204, 204, 204, 0.5);
  padding: 50px;

  .logo {
    max-width: 200px;
    max-height: 70px;
    width: auto;
    background: transparent;
  }

  .meta-group {
    margin-bottom: 30px;
  }

  .button-group {
    margin: 50px 0px;
    display: flex;
    flex-wrap: wrap;
  }

  button {
    margin: 10px 15px 10px 0px;
  }

  @media ${theme.query.tabletMax} {
    & {
      padding: 25px;
    }
  }
  @media only screen and (max-width: 600px) {
    a {
      width: 100%;
    }
    button {
      width: 100%;
      padding: 0.95rem 0;
    }
  }
`;

export default JoinSolveAsStaffPreviewStyles;
