import styled from 'styled-components';
import theme from '../../../common/theme';

const ProgramListStyles = styled.div`
  table {
    width: 100%;
    border-spacing: 0px;
    margin-top: 10px;
    th {
      padding: 0px 10px 10px;
      text-align: left;
      color: ${theme.color.text.medium};
      text-transform: uppercase;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.1em;
    }
    td {
      padding: 10px;
      vertical-align: top;
      border-top: solid 1px ${theme.color.grayLight};
    }
    .table-heading {
    }
    tr:hover td {
      border-color: ${theme.color.gray};
    }
    td {
      color: ${theme.color.text.medium};
      font-size: 14px;
      h4 {
        color: ${theme.color.grayDark};
      }
      h4,
      p {
        margin: 0;
      }
    }
  }
  button {
    margin-top: 5px;
  }
`;

export const ResultsPageNavigator = styled.div`
  display: flex;
  align-content: flex-start;
  margin: 12px 0px 16px;
  .prev-next-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    & > span {
      margin: 0px 12px;
    }
  }
`;

export default ProgramListStyles;
