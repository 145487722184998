import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { Map } from 'immutable';
import ProgramList from '../program_list';
import ProgramsStyles from './programs_styles';
import Tabber from '../../../ui/components/tabber';
import UserModel from '../../../session/models/user_model';
import ProgramFilters from './program_filters';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  listFilteredProgramsForStaff: PropTypes.func.isRequired,
  listFilteredEventsForStaff: PropTypes.func.isRequired,
  listFilteredServicesForStaff: PropTypes.func.isRequired,
  listFilteredABServicesForStaff: PropTypes.func.isRequired,
  setStaffEPCandidatesFilters: PropTypes.func.isRequired,
  setStaffEPCandidatesZipCode: PropTypes.func.isRequired,
  setStaffEPCandidatesPage: PropTypes.func.isRequired,
  eventsLoaded: PropTypes.bool.isRequired,
  programsLoaded: PropTypes.bool.isRequired,
  servicesLoaded: PropTypes.bool.isRequired,
  aBServicesLoaded: PropTypes.bool.isRequired,
  allStaffProgramsLoaded: PropTypes.bool.isRequired,
  allStaffEventsLoaded: PropTypes.bool.isRequired,
  allStaffServicesLoaded: PropTypes.bool.isRequired,
  listAllStaffPrograms: PropTypes.func.isRequired,
  listAllStaffEvents: PropTypes.func.isRequired,
  listAllStaffServices: PropTypes.func.isRequired,
  showAB: PropTypes.bool.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  search: PropTypes.string,
  zipCode: PropTypes.string,
  clearProgramsAndEvents: PropTypes.func.isRequired,
};

const defaultProps = {
  search: '',
  zipCode: '',
};

// eslint-disable-next-line arrow-body-style
const ProgramsView = ({
  currentUser,
  eventsLoaded,
  programsLoaded,
  servicesLoaded,
  zipCode,
  search,
  filters,
  listFilteredProgramsForStaff,
  listFilteredEventsForStaff,
  listFilteredServicesForStaff,
  listFilteredABServicesForStaff,
  showAB,
  aBServicesLoaded,
  setStaffEPCandidatesFilters,
  setStaffEPCandidatesZipCode,
  setStaffEPCandidatesPage,
  allStaffProgramsLoaded,
  allStaffEventsLoaded,
  allStaffServicesLoaded,
  clearProgramsAndEvents,
}) => {
  const [tab, setTab] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [serviceSelected, setServiceSelected] = useState(filters.get('serviceType'));
  const listAll = tab === 0;
  const isMyOrgPostingsTab = tab === 1;
  const isDraftsTab = tab === 2;
  const [visitedMyOrgPostingsTab, setVisitedMyOrgPostingsTab] = useState(false);

  const [pageShown, setPageShown] = useState(0);

  useEffect(() => {
    clearProgramsAndEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode, search, filters]);

  const userZip = currentUser?.getIn(['staffOrganization', 'zipCode']);
  useEffect(() => {
    if (firstLoad) {
      setStaffEPCandidatesZipCode(userZip);
      if (!filters.get('distance') || filters.get('distance') === '') {
        const defaultFilters = filters.merge({ page: pageShown + 1, distance: '15-miles', sortBy: 'distance' });
        setStaffEPCandidatesFilters(defaultFilters);
      }
    }
  }, [firstLoad, setStaffEPCandidatesZipCode, setStaffEPCandidatesFilters, filters, userZip, zipCode, pageShown]);

  useEffect(() => {
    setStaffEPCandidatesPage(pageShown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageShown]);

  useEffect(() => {
    setPageShown(0);
  }, [filters, search, zipCode]);

  useEffect(() => {
    clearProgramsAndEvents();
    setPageShown(0);
    if (isMyOrgPostingsTab && !visitedMyOrgPostingsTab) {
      const staffOrgZipCode = currentUser.getIn(['staffOrganization', 'zipCode']);
      setVisitedMyOrgPostingsTab(true);
      setStaffEPCandidatesZipCode(staffOrgZipCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!listAll) {
      const queryParams = { page: pageShown + 1, progname: search };
      queryParams.serviceType = '';
      queryParams.distance = '';
      queryParams.zipCode = '';

      if (isDraftsTab) {
        queryParams.state = 'pre_published';
      }
      if (isMyOrgPostingsTab) {
        queryParams.organizationId = currentUser.getIn(['staffOrganization', 'id']);
      }
      const query = filters.merge(queryParams);
      listFilteredProgramsForStaff(currentUser, query);
    }
  }, [listAll, allStaffProgramsLoaded, filters, listFilteredProgramsForStaff, currentUser, search, pageShown, isDraftsTab, isMyOrgPostingsTab, zipCode]);

  useEffect(() => {
    if (!listAll) {
      const queryParams = { page: pageShown + 1, eventname: search };
      queryParams.serviceType = '';
      queryParams.distance = '';
      queryParams.zipCode = '';

      if (isDraftsTab) {
        queryParams.state = 'pre_published';
      }
      if (isMyOrgPostingsTab) {
        queryParams.organizationId = currentUser.getIn(['staffOrganization', 'id']);
      }

      const query = filters.merge(queryParams);
      listFilteredEventsForStaff(currentUser, query);
    }
  }, [listAll, allStaffEventsLoaded, filters, listFilteredEventsForStaff, currentUser, search, pageShown, isDraftsTab, isMyOrgPostingsTab, zipCode]);

  useEffect(() => {
    if (!listAll) {
      const queryParams = { page: pageShown + 1, helperName: search };
      queryParams.serviceType = '';
      queryParams.distance = '';
      queryParams.zipCode = '';

      if (isDraftsTab) {
        queryParams.state = 'pre_published';
      }
      if (isMyOrgPostingsTab) {
        queryParams.organizationId = currentUser.getIn(['staffOrganization', 'id']);
      }

      const query = filters.merge(queryParams);
      listFilteredServicesForStaff(currentUser, query);
    }
  }, [allStaffServicesLoaded, currentUser, filters, isDraftsTab, isMyOrgPostingsTab, listAll, listFilteredServicesForStaff, pageShown, search, zipCode]);

  useEffect(() => {
    if (!firstLoad && serviceSelected && listAll) {
      const query = filters.merge({ page: pageShown + 1, eventname: search, zipCode });
      listFilteredEventsForStaff(currentUser, query);
    }
  }, [listAll, zipCode, search, eventsLoaded, filters, listFilteredEventsForStaff, currentUser, serviceSelected, firstLoad, pageShown]);

  useEffect(() => {
    if (!firstLoad && serviceSelected && listAll) {
      const query = filters.merge({ page: pageShown + 1, progname: search, zipCode });
      listFilteredProgramsForStaff(currentUser, query);
    }
  }, [listAll, zipCode, programsLoaded, search, filters, listFilteredProgramsForStaff, currentUser, serviceSelected, firstLoad, pageShown]);

  useEffect(() => {
    if (!firstLoad && serviceSelected && listAll) {
      const query = filters.merge({ page: pageShown + 1, helperName: search, zipCode });
      listFilteredServicesForStaff(currentUser, query);
    }
  }, [listAll, zipCode, servicesLoaded, search, filters, listFilteredServicesForStaff, currentUser, serviceSelected, firstLoad, pageShown]);

  useEffect(() => {
    if (showAB) {
      const loadABServices = () => {
        if (aBServicesLoaded || !listAll || !filters.get('serviceType') || !zipCode || !serviceSelected) {
          return false;
        }

        const aBServiceTypeList = ['food', 'housing', 'transportation', 'education', 'legal', 'money', 'care', 'goods', 'health', 'work'];
        const aBServiceTypeSelected = aBServiceTypeList.includes(filters.get('serviceType'));
        return aBServiceTypeSelected;
      };

      if (loadABServices()) {
        const query = filters.merge({ page: pageShown + 1, terms: search, zipCode });
        listFilteredABServicesForStaff(currentUser, query);
      }
    }
  }, [tab, filters, search, currentUser, zipCode, aBServicesLoaded, showAB, serviceSelected, listFilteredABServicesForStaff, listAll, pageShown]);

  useEffect(() => {
    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [firstLoad]);

  let programList;
  switch (tab) {
    case 0:
      programList = <ProgramList setPageShown={setPageShown} pageShown={pageShown} />;
      break;
    case 1:
      programList = <ProgramList preFilter="myOrgFilter" setPageShown={setPageShown} pageShown={pageShown} />;
      break;
    case 2:
      programList = <ProgramList preFilter="draftsFilter" setPageShown={setPageShown} pageShown={pageShown} />;
      break;
    default:
      programList = <ProgramList />;
  }

  return (
    <ProgramsStyles>
      <Link to="resources/new">
        <Button variant="outlined" color="secondary" size="large" style={{ float: 'right' }}>
          + Add a Program/Event
        </Button>
      </Link>
      <h1>Programs / Events</h1>
      <Tabber defaultValue={0} onChange={setTab}>
        <div tabLabel="All Program Postings" />
        <div tabLabel="My Organization Postings" />
        <div tabLabel="Drafts" />
      </Tabber>
      <ProgramFilters setServiceSelected={setServiceSelected} listAll={tab === 0} />
      {serviceSelected || !listAll ? programList : <h2>↑ Select a service type to get started ↑</h2>}
    </ProgramsStyles>
  );
};

ProgramsView.propTypes = propTypes;
ProgramsView.defaultProps = defaultProps;

export default ProgramsView;
