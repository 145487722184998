import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import { List } from 'immutable';
import Tabber from '../../../ui/components/tabber';
import ProfileStyles from './profile_styles';
import Questionnaire from '../questionnaire';
import Resume from '../resume';
import Challenges from '../challenges';

// TODO: make connected component, pull out survey results to summarize how many questions were answered, etc.
// eslint-disable-next-line no-unused-vars
const QuestionnaireSummary = ({ surveys, onRestart }) => (
  <div>
    <p>Thank you for answering the questionnnaire!</p>
    <p>
      <Link
        to="/candidate/profile"
        onClick={(e) => {
          e.preventDefault();
          onRestart();
        }}
      >
        Edit / Review Answers
      </Link>
    </p>
    <p>
      <Link to="/dashboard">Back to dashboard</Link>
    </p>
  </div>
);
QuestionnaireSummary.propTypes = {
  surveys: PropTypes.instanceOf(List).isRequired,
  onRestart: PropTypes.func.isRequired,
};

const QuestionnaireTab = () => {
  const [finished, setFinished] = useState(undefined);

  if (finished) {
    return <QuestionnaireSummary surveys={finished} onRestart={() => setFinished(undefined)} />;
  }
  return <Questionnaire onComplete={(x) => setFinished(x)} />;
};

const propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

const defaultProps = {};

// TODO Allow for this implementation to use tabber effects/rewite tabber to use locations instead of state

const ProfileView = ({ location: { pathname } }) => {
  const [tab, setTab] = useState(undefined);

  const defaultPath = '/candidate/profile';
  const resumePath = '/candidate/profile/resume';
  const challengesPath = '/candidate/profile/challenges';
  const questionnairePath = '/candidate/profile/questionnaire';

  if (tab === 0 && pathname !== resumePath) return <Redirect to={resumePath} />;
  if (tab === 1 && pathname !== challengesPath) return <Redirect to={challengesPath} />;
  if (tab === 2 && pathname !== questionnairePath) return <Redirect to={questionnairePath} />;

  return (
    <ProfileStyles className="container-wrapper">
      <div className="candidate-wrapper">
        <h3 style={{ textAlign: 'center' }}>Profile</h3>

        <Switch>
          <Route path={challengesPath}>
            <Tabber defaultValue={1} onChange={setTab}>
              <div tabLabel="Resume" />
              <div tabLabel="Challenges" />
              <div tabLabel="Questionnaire" />
            </Tabber>
            <Challenges />
          </Route>
          <Route path={questionnairePath}>
            <Tabber defaultValue={2} onChange={setTab}>
              <div tabLabel="Resume" />
              <div tabLabel="Challenges" />
              <div tabLabel="Questionnaire" />
            </Tabber>
            <QuestionnaireTab />
          </Route>
          <Route path={[resumePath, defaultPath]}>
            <Tabber defaultValue={0} onChange={setTab}>
              <div tabLabel="Resume" />
              <div tabLabel="Challenges" />
              <div tabLabel="Questionnaire" />
            </Tabber>
            <Resume />
          </Route>
        </Switch>
      </div>
    </ProfileStyles>
  );
};

ProfileView.propTypes = propTypes;
ProfileView.defaultProps = defaultProps;

export default ProfileView;
