import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { useFetchOnce } from '../../../util/hooks';
import { Button, TextHeading } from '../../../common/components';

import MessageNewStyles from './message_new_styles';
import MessageForm from '../message_form';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map),
  candidateId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
  sendToOptions: PropTypes.instanceOf(List).isRequired,
  listMessages: PropTypes.func.isRequired,
  listCandidateOrganizations: PropTypes.func.isRequired,
  listPeerStaff: PropTypes.func.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  listAllCandidates: PropTypes.func.isRequired,
};

const defaultProps = {
  candidate: undefined,
};

const MessageNewView = ({
  currentUser,
  candidate,
  candidateId,
  organizationId,
  sendToOptions,
  listMessages,
  listCandidateOrganizations,
  listPeerStaff,
  fetchCandidate,
  listAllCandidates,
}) => {
  const history = useHistory();

  useFetchOnce(currentUser, candidateId, fetchCandidate, !candidate);

  useEffect(() => {
    listMessages(currentUser);
  }, [currentUser, listMessages]);

  useEffect(() => {
    if (candidateId) {
      listCandidateOrganizations(currentUser, candidateId);
    }
  }, [candidateId, listCandidateOrganizations, currentUser]);

  useEffect(() => {
    if (organizationId) {
      listPeerStaff(currentUser, organizationId);
    }
  }, [listPeerStaff, currentUser, organizationId]);

  useEffect(() => {
    if (organizationId) {
      listAllCandidates(currentUser, organizationId);
    }
  }, [currentUser, listAllCandidates, organizationId]);

  let finalOptions;
  if (candidate) {
    finalOptions = new List([
      {
        value: candidate.getIn(['user', 'id']),
        label: `${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`,
        subLabel: 'Candidate',
      },
    ]);
  } else {
    finalOptions = sendToOptions;
  }

  return (
    <MessageNewStyles className="container-wrapper">
      <div className="candidate-wrapper">
        <Button buttonType="back" onClick={history.goBack} size="small">
          <KeyboardBackspaceIcon />
          Go Back
        </Button>
        <TextHeading size="large" weight="bold" margin="both">
          New Message
        </TextHeading>
        <MessageForm sendToOptions={finalOptions} />
      </div>
    </MessageNewStyles>
  );
};

MessageNewView.propTypes = propTypes;
MessageNewView.defaultProps = defaultProps;

export default MessageNewView;
