import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import CheckIcon from '@material-ui/icons/CheckCircle';
import theme from '../../../common/theme';
import OrganizationTileStyles, { AddressLine } from './organization_tile_styles';
import { TextHeading, Text } from '../../../common/components';
import OrganizationModel from '../../../solve/models/organization_model';

const propTypes = {
  org: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(OrganizationModel)]).isRequired,
  onClick: PropTypes.func,
};

const defaultProps = {
  onClick: undefined,
};

// "address": {
//     "address1": "1514 Macejkovic Crest",
//     "address2": null,
//     "city": "Arashire",
//     "state": "FL",
//     "zip": "15563",
//     "latitude": null,
//     "longitude": null
// }

const PrettyAddress = ({ address }) => {
  if (!address.get('city') || !address.get('state')) {
    return (
      <span>
        <AddressLine>{address.get('zipCode')}</AddressLine>
      </span>
    );
  }
  return (
    <span>
      {address.get('address1') && (
        <Fragment>
          <AddressLine>{address.get('address1')}</AddressLine>,
        </Fragment>
      )}
      {address.get('address2') && (
        <Fragment>
          <AddressLine>{address.get('address2')}</AddressLine>,
        </Fragment>
      )}
      <AddressLine>
        {address.get('city')}, {address.get('state')}
      </AddressLine>
    </span>
  );
};
PrettyAddress.propTypes = {
  address: PropTypes.instanceOf(Map).isRequired,
};

const OrganizationTileView = ({ org, onClick }) => (
  <OrganizationTileStyles onClick={onClick}>
    <div className="icon">
      <img src={org.get('logoUrl') || '/icons/search.png'} width="100%" alt="Logo" />
    </div>
    <Grid container style={{ overflow: 'hidden' }}>
      <Grid item xs={11} className="organization-info">
        <TextHeading className="organization-tile" size="medium" weight="bold" margin="none">
          {org.get('name')}
        </TextHeading>
        {org.get('address') && (
          <Text size="medium" color="medium" uppercase className="ellipsis">
            <PrettyAddress address={org.get('address')} />
          </Text>
        )}
      </Grid>
      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
        <CheckIcon style={{ color: theme.color.action }} />
      </Grid>
    </Grid>
  </OrganizationTileStyles>
);

OrganizationTileView.propTypes = propTypes;
OrganizationTileView.defaultProps = defaultProps;

export default OrganizationTileView;
