import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import NextSteps from './dashboard_steps';
import { useFetchOnce } from '../../../util/hooks';
import RecommendedListings from './recommended_listings';
import UserModel from '../../../session/models/user_model';
import { DashboardCard } from '../../../common/styles/card';
import DashboardStyles, { Header } from './dashboard_styles';
import Notifications from '../../../notification/components/notifications';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  candidateId: PropTypes.string.isRequired,
};

const defaultProps = {};

const DashboardView = ({ currentUser, candidateId, fetchCandidate }) => {
  useFetchOnce(currentUser, candidateId, fetchCandidate);

  return (
    <div className="container-wrapper">
      <DashboardStyles>
        <Header>
          <div className="candidate-wrapper">
            <h2>
              Welcome, {currentUser.get('firstName')} {currentUser.get('lastName')}!
            </h2>
          </div>
        </Header>

        <div className="candidate-wrapper">
          <div className="desktop-view">
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <DashboardCard>
                  <NextSteps />
                </DashboardCard>
                <DashboardCard>
                  <RecommendedListings />
                </DashboardCard>
              </Grid>
              <Grid item xs={12} lg={6}>
                <DashboardCard>
                  <Notifications isLimited />
                </DashboardCard>
              </Grid>
            </Grid>
          </div>
          <div className="mobile-view">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DashboardCard>
                  <NextSteps />
                </DashboardCard>
                <DashboardCard>
                  <Notifications isLimited />
                </DashboardCard>
                <DashboardCard>
                  <RecommendedListings />
                </DashboardCard>
              </Grid>
            </Grid>
          </div>
        </div>
      </DashboardStyles>
    </div>
  );
};

DashboardView.propTypes = propTypes;
DashboardView.defaultProps = defaultProps;

export default DashboardView;
