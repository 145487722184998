import { useState, useEffect } from 'react';

import { STATUS_CODE } from 'risekit/app/app.status';
import { getModeReports } from 'risekit/api/request.api';
import { ModeReportsPayload } from 'risekit/api/api.types';

import { ModeReport } from '../reporting.types';

const useGetModeReports = ({ userAuthToken }: ModeReportsPayload) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState<boolean>();

  const [modeReports, setModeReports] = useState<ModeReport[]>();

  /**
   * Maps API response to ModeReport model.
   *
   * @param data
   * @returns {ModeReport}
   */
  const mapToModel = (report: any): ModeReport => {
    const { name, token, webPreviewImage } = report;

    return {
      name,
      webPreviewImage,
      token,
    };
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchModeReports = async () => {
      const { data, error: apiError } = await getModeReports({
        userAuthToken,
      });

      const isRequestCanceled = apiError?.messages !== STATUS_CODE.REQUEST_CANCEL;

      if (apiError && !isRequestCanceled) {
        setError(apiError);
        setIsLoading(false);
      }

      if (!apiError) {
        const reports = data?.Embedded?.reports || [];

        const formattedReports: ModeReport[] = reports.map(mapToModel);

        setModeReports(formattedReports);
        setIsLoading(false);
      }
    };

    fetchModeReports();
  }, [userAuthToken]);

  return { isLoading, error, modeReports };
};

export default useGetModeReports;
