import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import ResumeView from '../../../resume/components/resume/resume_view';
import { getCandidateResumeForCandidate, getCurrentUser, getCandidate, getCandidateResumeCompletionPercentage } from '../../../reducer';
import { listResumes, createResume, updateResume, uploadResume, approveResumeChanges, declineResumeChanges, fetchCandidate } from '../../../solve/actions';

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file.rawFile) {
      reader.readAsDataURL(file.rawFile);
    } else {
      reader.readAsDataURL(file[0]);
    }

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const buildDateRanges = (list, itemType) =>
  list?.map((educationItem) => {
    const attributes = educationItem.get(itemType);
    const isCurrent = attributes.get('isCurrent');
    const newValues = isCurrent ? attributes.delete('endDate') : attributes.delete('isCurrent');
    return { [itemType]: newValues.toJS() };
  });

function buildSubmitValues(values) {
  const education = values.get('education');
  const experience = values.get('experience');
  const program = values.get('program');
  let newValues = values.delete('education').delete('experience').delete('program');

  const newEducation = buildDateRanges(education, 'education');
  if (newEducation) {
    newValues = newValues.set('education', newEducation);
  }

  const newExperience = buildDateRanges(experience, 'experience');
  if (newExperience) {
    newValues = newValues.set('experience', newExperience);
  }

  const newProgram = buildDateRanges(program, 'program');
  if (newProgram) {
    newValues = newValues.set('program', newProgram);
  }

  return fromJS(newValues);
}

const mapStateToProps = (state, { candidateId: id }) => {
  const currentUser = getCurrentUser(state);
  const candidateId = id || currentUser.get('candidateId');
  const resume = getCandidateResumeForCandidate(state, candidateId);
  const resumeKey = candidateId === currentUser.get('candidateId') ? 'candidatesVersionOfResume' : 'liveResume';
  const contactInfo = getCandidate(state, candidateId);
  const pendingApprovalState = resume?.getIn(['liveResume', 'state']) === 'pending_approval';
  const updatedBy = resume?.getIn(['liveResume', 'mostRecentVersionUpdatedBy', 'staff']);
  const initialValues = pendingApprovalState ? resume?.get('liveResume') : resume?.get(resumeKey) || fromJS({});
  const candidateResumeCompletionPercentage = resume ? getCandidateResumeCompletionPercentage(state, currentUser.get('candidateId')) : 0;

  return {
    currentUser,
    candidateId,
    staff: false,
    resume,
    contactInfo,
    initialValues,
    pendingApprovalState,
    updatedBy,
    candidateResumeCompletionPercentage,
  };
};

const mapDispatchToProps = {
  listResumes,
  createResume,
  updateResume,
  uploadResume,
  fetchCandidate,
  approveResumeChanges,
  declineResumeChanges,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    const cleanValues = buildSubmitValues(values);
    let apiCall;
    if (stateProps.resume && stateProps.resume.get('id') !== null) {
      apiCall = dispatchProps.updateResume(stateProps.currentUser, stateProps.resume.get('id'), cleanValues);
    } else {
      apiCall = dispatchProps.createResume(stateProps.currentUser, stateProps.candidateId, cleanValues);
    }

    if (ownProps.onComplete) {
      apiCall.then(ownProps.onComplete);
    }
    return apiCall;
  },
  onUpload: (values) =>
    convertFileToBase64(values)
      .then((base64) => ({
        src: base64,
      }))
      .then((file) => dispatchProps.uploadResume(stateProps.currentUser, stateProps.candidateId, file)),
  onApproveChanges: () => dispatchProps.approveResumeChanges(stateProps.currentUser, stateProps.resume.get('id')),
  onDeclineChanges: () => dispatchProps.declineResumeChanges(stateProps.currentUser, stateProps.resume.get('id')),
  ...dispatchProps,
  ...stateProps,
  ...ownProps,
});

const ResumeContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ResumeView);

export default ResumeContainer;
