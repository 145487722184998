import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router-dom';
import { MuiFormTheme, MuiThemeProvider } from '../../../common/form';
import ApplyToProgramStyles from './apply_to_program_styles';
import ChallengesForm from './challenges_form';
import PrivacyAgreementForm from './privacy_agreement_form';
import QuestionnaireCompleted from './questionnaire_completed';
import AdditionalQuestionsForm from './additional_questions_form';
import AdditionalQuestionsCompleted from './additional_questions_completed';
import Questionnaire from '../questionnaire';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import Spinner from '../../../common/components/spinner';
import CandidateProgramModel from '../../../solve/models/candidate_program_model';

const propTypes = {
  program: PropTypes.instanceOf(Map),
  fetchProgram: PropTypes.func.isRequired,
  createCandidateProgram: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map).isRequired,
  candidateProgram: PropTypes.oneOfType([PropTypes.instanceOf(CandidateProgramModel), PropTypes.instanceOf(Map)]),
};

const defaultProps = {
  program: undefined,
  candidateProgram: undefined,
};

const ApplyToProgramView = ({ program, fetchProgram, programId, currentUser, candidate, candidateProgram, createCandidateProgram }) => {
  const resolvedProgram = useFetchOnce(currentUser, programId, fetchProgram, program && program.get('surveys'));
  const resolvedCandidateProgram = useFetchOnce(currentUser, programId, createCandidateProgram, candidateProgram);

  const questionnaireStatus = candidate?.get('challengesCompletion');
  const shouldSkipQuestionnaire = questionnaireStatus === 'complete';

  const [page, setPage] = useState(1);

  if (!resolvedProgram || !resolvedCandidateProgram) {
    return <Spinner />;
  }
  if (!program) {
    // Redirect up one level to be handled there.
    return <Redirect to="/candidate/programs-and-events" />;
  }
  if (!candidateProgram) {
    return 'Sorry, we are temporarily unable to process your request.';
  }

  const handlePrivacyAgreementFormSubmit = () => {
    const newPage = shouldSkipQuestionnaire ? 4 : 3;

    setPage(newPage);
  };

  return (
    <ApplyToProgramStyles>
      <div className="content-area">
        <div className="wrapper">
          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {page === 1 && <ChallengesForm onSubmit={() => setPage(page + 1)} />}
              {page === 2 && <PrivacyAgreementForm previousPage={() => setPage(page - 1)} onSubmit={handlePrivacyAgreementFormSubmit} />}
              {page === 3 && <Questionnaire onComplete={() => setPage(page + 1)} />}
              {page === 4 && (!program || !program.get('surveys') || !(program.get('surveys').size > 0)) ? (
                <AdditionalQuestionsCompleted skippedQuestionnaire={shouldSkipQuestionnaire} program={program} candidateProgram={candidateProgram} />
              ) : (
                page === 4 && <QuestionnaireCompleted skippedQuestionnaire={shouldSkipQuestionnaire} onSubmit={() => setPage(page + 1)} />
              )}
              {page === 5 && <AdditionalQuestionsForm onComplete={() => setPage(page + 1)} program={program} />}
              {page === 6 && <AdditionalQuestionsCompleted program={program} candidateProgram={candidateProgram} />}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </ApplyToProgramStyles>
  );
};

ApplyToProgramView.propTypes = propTypes;
ApplyToProgramView.defaultProps = defaultProps;

export default ApplyToProgramView;
