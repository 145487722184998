import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  candidateState: undefined,
  staffState: undefined,
  screeningScore: undefined,
  allRequiredAnswersMatch: undefined,
  acceptedInvitationAt: undefined,
  appliedAt: undefined,
  joinedAt: undefined,
  droppedOutAt: undefined,
  confirmedGraduatedAt: undefined,
  candidateDeletedAt: undefined,
  helpingOrganization: {
    id: undefined,
    name: undefined,
    orgType: undefined,
  },
  candidate: {
    candidateID: undefined,
    user: undefined,
    linkedOrganizations: undefined,
  },
  program: {
    programId: undefined,
    programName: undefined,
    programEndDate: undefined,
    programLogo: undefined,
    screeningSurveys: undefined,
  },
  screeningQuestionsCount: undefined,
  lastApprovedCandidateState: undefined,
  lastApprovedStaffState: undefined,
  lastStateChangedBy: undefined,
};

class CandidateProgramModel extends Record(DEFAULT_ARGS) {
  static _name = 'CandidateApplication';

  get fullName() {
    return this.getIn(['program', 'programName']);
  }
}

export default CandidateProgramModel;
