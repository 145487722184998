const NotificationTemplates = new Map([
  [
    'case-note-created',
    {
      group: 'case_note',
      activity: 'New note for <candidateName>',
      description: "<staffName> added a note to <candidateName>'s profile. See their profile <here>.",
    },
  ],
  [
    'case-note-updated',
    {
      group: 'case_note',
      activity: 'Note updated for <candidateName>',
      description: '<staffName> edited a note for <candidateName>. See their profile <here>.',
    },
  ],
  [
    'challenge-created',
    {
      group: 'challenge_transition',
      activity: '<title> Challenge Identified',
      description: '<candidateName> identified a <title> challenge. See challenges information <here>.',
    },
  ],
  [
    'challenge-candidate-reset',
    {
      group: 'challenge_transition',
      activity: '<title> Challenge Reset',
      description: '<candidateName> reset the <title> challenge. See challenges information <here>.',
    },
  ],
  [
    'challenge-candidate-resolve',
    {
      group: 'challenge_transition',
      activity: '<title> Challenge Resolved',
      description: '<candidateName> resolved their <title> challenge. See challenges information <here>.',
    },
  ],
  [
    'challenge-staff-resolve',
    {
      group: 'challenge_transition',
      activity: '<title> Challenge Resolved',
      description: '<candidateName> resolved their <title> challenge. See challenges information <here>.',
    },
  ],
  [
    'event-candidate-saved',
    {
      group: 'event_transition',
      activity: 'Saved <title>',
      description: '<candidateName> saved the <title> event. See event information <here>.',
    },
  ],
  [
    'event-candidate-registered',
    {
      group: 'event_transition',
      activity: 'Registered for <title>',
      description: '<candidateName> registered for the <title> event. See event information <here>.',
    },
  ],
  [
    'event-candidate-attended',
    {
      group: 'event_transition',
      activity: 'Attended <title>',
      description: '<candidateName> attended the <title> event. See event information <here>.',
    },
  ],
  [
    'event-candidate-deleted',
    {
      group: 'event_transition',
      activity: 'Unregistered for <title>',
      description: '<candidateName> unregistered for the <title> event. See event information <here>.',
    },
  ],
  [
    'event-staff-registered',
    {
      group: 'event_transition',
      activity: 'Registered for <title>',
      description: '<candidateName> registered for the <title> event. See event information <here>.',
    },
  ],
  [
    'event-staff-reminded',
    {
      group: 'event_transition',
      activity: 'Reminded about <title>',
      description: '<candidateName> was sent a reminder about <title> event. See event information <here>.',
    },
  ],
  [
    'event-staff-attended',
    {
      group: 'event_transition',
      activity: 'Attended <title>',
      description: '<candidateName> attended the <title> event. See event information <here>.',
    },
  ],
  [
    'event-staff-deleted',
    {
      group: 'event_transition',
      activity: 'Unregistered for <title>',
      description: '<candidateName> unregistered for the <title> event. See event information <here>.',
    },
  ],
  [
    'event-application-status-updated',
    {
      group: 'event_transition',
      activity: '<title> application status was changed',
      description:
        "<staffName> from <staffOrgName> updated <candidateName>'s application to the <title> event. Please review application and confirm the change.",
    },
  ],
  [
    'event-invitation-sent-to-external',
    {
      group: 'event_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <postingOrgName> <title> event. See event information <here>.',
    },
  ],
  [
    'event-invitation-sent',
    {
      group: 'event_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <postingOrgName> <title> event. See event information <here>.',
    },
  ],
  [
    'event-invitation-resent-to-external',
    {
      group: 'event_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <postingOrgName> <title> event. See event information <here>.',
    },
  ],
  [
    'event-invitation-resent',
    {
      group: 'event_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <postingOrgName> <title> event. See event information <here>.',
    },
  ],
  [
    'event-invitation-accepted',
    {
      group: 'event_invitation',
      activity: 'Invitation accepted for <title>',
      description: '<candidateName> accepted an invitation for the <postingOrgName> <title> event. See event information <here>.',
    },
  ],
  [
    'external-job-candidate-saved',
    {
      group: 'external_job_transition',
      activity: 'Saved <title>',
      description: '<candidateName> saved the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-applied',
    {
      group: 'external_job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-applied-on-website',
    {
      group: 'external_job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-interviewing',
    {
      group: 'external_job_transition',
      activity: 'Interview Requested for <title>',
      description: '<candidateName> was requested to interview for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-received-offer',
    {
      group: 'external_job_transition',
      activity: 'Recieved offer for <title>',
      description: '<candidateName> recieved an offer for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-accepted-offer',
    {
      group: 'external_job_transition',
      activity: 'Accepted offer for <title>',
      description: '<candidateName> accepted an offer for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-declined-offer',
    {
      group: 'external_job_transition',
      activity: 'Declined offer for <title>',
      description: '<candidateName> declined an offer for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-declined',
    {
      group: 'external_job_transition',
      activity: 'Application declined for <title>',
      description: '<candidateName> application was declined for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-candidate-deleted',
    {
      group: 'external_job_transition',
      activity: 'Removed application for <title>',
      description: '<candidateName> removed their application for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-reviewed',
    {
      group: 'external_job_transition',
      activity: 'Reviewed application for <title>',
      description: 'Reviewed <candidateName> application for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-applied',
    {
      group: 'external_job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-applied-on-website',
    {
      group: 'external_job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-interviewing',
    {
      group: 'external_job_transition',
      activity: 'Interview Requested for <title>',
      description: '<candidateName> was requested to interview for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-offer',
    {
      group: 'external_job_transition',
      activity: 'Recieved offer for <title>',
      description: '<candidateName> recieved an offer for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-hired',
    {
      group: 'external_job_transition',
      activity: 'Hired for <title>',
      description: '<candidateName> was hired for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-archived',
    {
      group: 'external_job_transition',
      activity: 'Application declined for <title>',
      description: '<candidateName> application was declined for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-staff-deleted',
    {
      group: 'external_job_transition',
      activity: 'Removed application for <title>',
      description: '<candidateName> application for the <externalSource> <title> job was removed. See job information <here>.',
    },
  ],
  [
    'external-job-application-status-updated',
    {
      group: 'external_job_transition',
      activity: '<title> application status was changed',
      description:
        "<staffName> from <staffOrgName> updated <candidateName>'s application to the <title> job. Please review application and confirm the change.",
    },
  ],
  [
    'external-job-invitation-sent-to-external',
    {
      group: 'external_job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-invitation-sent',
    {
      group: 'external_job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-invitation-resent-to-external',
    {
      group: 'external_job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-invitation-resent',
    {
      group: 'external_job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'external-job-invitation-accepted',
    {
      group: 'external_job_invitation',
      activity: 'Invitation accepted for <title>',
      description: '<candidateName> accepted an invitation for the <externalSource> <title> job. See job information <here>.',
    },
  ],
  [
    'helper-candidate-saved',
    {
      group: 'helper_transition',
      activity: 'Saved <title>',
      description: '<candidateName> saved the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-candidate-applied',
    {
      group: 'helper_transition',
      activity: 'Applied to <title>',
      description: '<candidateName> applied to the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-candidate-declined',
    {
      group: 'helper_transition',
      activity: 'Declined by <title>',
      description: '<candidateName> was declined by the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-candidate-joined',
    {
      group: 'helper_transition',
      activity: 'Joined <title>',
      description: '<candidateName> joined the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-candidate-dropped-out',
    {
      group: 'helper_transition',
      activity: 'Dropped out of <title>',
      description: '<candidateName> dropped out of the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-candidate-graduated',
    {
      group: 'helper_transition',
      activity: 'Graduated from <title>',
      description: '<candidateName> graduated from the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-candidate-deleted',
    {
      group: 'helper_transition',
      activity: 'Removed application from <title>',
      description: '<candidateName> removed their application to the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-staff-applied',
    {
      group: 'helper_transition',
      activity: 'Applied to <title>',
      description: '<candidateName> applied to the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-staff-declined',
    {
      group: 'helper_transition',
      activity: 'Declined by <title>',
      description: '<candidateName> was declined by the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-staff-joined',
    {
      group: 'helper_transition',
      activity: 'Joined <title>',
      description: '<candidateName> joined the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-staff-dropped-out',
    {
      group: 'helper_transition',
      activity: 'Dropped out of <title>',
      description: '<candidateName> dropped out of the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-staff-graduated',
    {
      group: 'helper_transition',
      activity: 'Graduated from <title>',
      description: '<candidateName> graduated from the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-staff-deleted',
    {
      group: 'helper_transition',
      activity: 'Removed application from <title>',
      description: '<candidateName> removed their application to the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-application-status-updated',
    {
      group: 'helper_transition',
      activity: '<title> application status was changed',
      description:
        "<staffName> from <staffOrgName> updated <candidateName>'s application to the <title> helper. Please review application and confirm the change.",
    },
  ],
  [
    'helper-invitation-sent-to-external',
    {
      group: 'helper_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-invitation-sent',
    {
      group: 'helper_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-invitation-resent-to-external',
    {
      group: 'helper_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-invitation-resent',
    {
      group: 'helper_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'helper-invitation-accepted',
    {
      group: 'helper_invitation',
      activity: 'Invitation accepted for <title>',
      description: '<candidateName> accepted an invitation for the <postingOrgName> <title> helper. See helper information <here>.',
    },
  ],
  [
    'job-candidate-saved',
    {
      group: 'job_transition',
      activity: 'Saved <title>',
      description: '<candidateName> saved the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-applied',
    {
      group: 'job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-applied-on-website',
    {
      group: 'job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-interviewing',
    {
      group: 'job_transition',
      activity: 'Interview Requested for <title>',
      description: '<candidateName> was requested to interview for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-received-offer',
    {
      group: 'job_transition',
      activity: 'Recieved offer for <title>',
      description: '<candidateName> recieved an offer for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-accepted-offer',
    {
      group: 'job_transition',
      activity: 'Accepted offer for <title>',
      description: '<candidateName> accepted an offer for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-declined-offer',
    {
      group: 'job_transition',
      activity: 'Declined offer for <title>',
      description: '<candidateName> declined an offer for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-declined',
    {
      group: 'job_transition',
      activity: 'Application declined for <title>',
      description: '<candidateName> application was declined for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-candidate-deleted',
    {
      group: 'job_transition',
      activity: 'Removed application for <title>',
      description: '<candidateName> removed their application for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-reviewed',
    {
      group: 'job_transition',
      activity: 'Reviewed application for <title>',
      description: 'Reviewed <candidateName> application for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-applied',
    {
      group: 'job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-applied-on-website',
    {
      group: 'job_transition',
      activity: 'Applied for <title>',
      description: '<candidateName> applied for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-interviewing',
    {
      group: 'job_transition',
      activity: 'Interview Requested for <title>',
      description: '<candidateName> was requested to interview for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-offer',
    {
      group: 'job_transition',
      activity: 'Recieved offer for <title>',
      description: '<candidateName> recieved an offer for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-hired',
    {
      group: 'job_transition',
      activity: 'Hired for <title>',
      description: '<candidateName> was hired for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-archived',
    {
      group: 'job_transition',
      activity: 'Application declined for <title>',
      description: '<candidateName> application was declined for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-staff-deleted',
    {
      group: 'job_transition',
      activity: 'Removed application for <title>',
      description: '<candidateName> application for the <employerName> <title> job was removed. See job information <here>.',
    },
  ],
  [
    'job-application-status-updated',
    {
      group: 'job_transition',
      activity: '<title> application status was changed',
      description:
        "<staffName> from <staffOrgName> updated <candidateName>'s application to the <title> job. Please review application and confirm the change.",
    },
  ],
  [
    'job-invitation-sent-to-external',
    {
      group: 'job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-invitation-sent',
    {
      group: 'job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-invitation-resent-to-external',
    {
      group: 'job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-invitation-resent',
    {
      group: 'job_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'job-invitation-accepted',
    {
      group: 'job_invitation',
      activity: 'Invitation accepted for <title>',
      description: '<candidateName> accepted an invitation for the <employerName> <title> job. See job information <here>.',
    },
  ],
  [
    'message-candidate-sent',
    {
      group: 'message',
      activity: 'New Message from <candidateName>',
      description: 'You have a new message from <candidateName>. Click <here> to read and reply to the message.',
    },
  ],
  [
    'message-staff-sent',
    {
      group: 'message',
      activity: 'New Message from <staffName> at <staffOrgName>',
      description: 'You have a new message from <staffName> at <staffOrgName>. Click <here> to read and reply to the message.',
    },
  ],
  [
    'new-candidate-with-how-found-org',
    {
      group: 'candidate_profile',
      activity: '<candidateName> joined RiseKit',
      description: '<candidateName> joined RiseKit. See their profile <here>.',
    },
  ],
  [
    'pathway-candidate-saved',
    {
      group: 'pathway_transition',
      activity: 'Saved <title> Pathway',
      description: '<candidateName> saved the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-candidate-applied',
    {
      group: 'pathway_transition',
      activity: 'Applied to <title> Pathway',
      description: '<candidateName> applied to the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-candidate-in-progress',
    {
      group: 'pathway_transition',
      activity: 'Started <title> Pathway',
      description: '<candidateName> started the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-candidate-transferred',
    {
      group: 'pathway_transition',
      activity: 'Transferred from <title> Pathway',
      description: '<candidateName> transferred from the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-candidate-complete',
    {
      group: 'pathway_transition',
      activity: 'Completed <title> Pathway',
      description: '<candidateName> completed the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-candidate-dropped-voluntarily',
    {
      group: 'pathway_transition',
      activity: 'Dropped (voluntarily) from <title> Pathway',
      description: '<candidateName> dropped (voluntarily) from the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-candidate-dropped-involuntarily',
    {
      group: 'pathway_transition',
      activity: 'Dropped (involuntarily) from <title> Pathway',
      description: '<candidateName> dropped (involuntarily) from the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-saved',
    {
      group: 'pathway_transition',
      activity: 'Saved <title> Pathway',
      description: '<candidateName> saved the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-applied',
    {
      group: 'pathway_transition',
      activity: 'Applied to <title> Pathway',
      description: '<candidateName> applied to the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-in-progress',
    {
      group: 'pathway_transition',
      activity: 'Started <title> Pathway',
      description: '<candidateName> started the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-transferred',
    {
      group: 'pathway_transition',
      activity: 'Transferred from <title> Pathway',
      description: '<candidateName> transferred from the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-complete',
    {
      group: 'pathway_transition',
      activity: 'Completed <title> Pathway',
      description: '<candidateName> completed the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-dropped-voluntarily',
    {
      group: 'pathway_transition',
      activity: 'Dropped (voluntarily) from <title> Pathway',
      description: '<candidateName> dropped (voluntarily) from the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-staff-dropped-involuntarily',
    {
      group: 'pathway_transition',
      activity: 'Dropped (involuntarily) from <title> Pathway',
      description: '<candidateName> dropped (involuntarily) from the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-step-candidate-not-started',
    {
      group: 'pathway_transition',
      activity: 'Step not started in <title> Pathway',
      description: '<candidateName> has not started a step in the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-step-candidate-in-progress',
    {
      group: 'pathway_transition',
      activity: 'Started a pathway step in <title> Pathway',
      description: '<candidateName> started a step in the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-step-candidate-complete',
    {
      group: 'pathway_transition',
      activity: 'Completed a pathway step in <title> Pathway',
      description: '<candidateName> completed a step in the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-step-staff-not-started',
    {
      group: 'pathway_transition',
      activity: 'Step not started in <title> Pathway',
      description: '<candidateName> has not started a step in the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-step-staff-in-progress',
    {
      group: 'pathway_transition',
      activity: 'Started a pathway step in <title> Pathway',
      description: '<candidateName> started a step in the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'pathway-step-staff-complete',
    {
      group: 'pathway_transition',
      activity: 'Completed a pathway step in <title> Pathway',
      description: '<candidateName> completed a step in the <title> pathway. See pathway information <here>.',
    },
  ],
  [
    'program-candidate-saved',
    {
      group: 'program_transition',
      activity: 'Saved <title>',
      description: '<candidateName> saved the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-candidate-applied',
    {
      group: 'program_transition',
      activity: 'Applied to <title>',
      description: '<candidateName> applied to the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-candidate-declined',
    {
      group: 'program_transition',
      activity: 'Declined by <title>',
      description: '<candidateName> was declined by the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-candidate-joined',
    {
      group: 'program_transition',
      activity: 'Joined <title>',
      description: '<candidateName> joined the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-candidate-dropped-out',
    {
      group: 'program_transition',
      activity: 'Dropped out of <title>',
      description: '<candidateName> dropped out of the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-candidate-graduated',
    {
      group: 'program_transition',
      activity: 'Graduated from <title>',
      description: '<candidateName> graduated from the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-candidate-deleted',
    {
      group: 'program_transition',
      activity: 'Removed application from <title>',
      description: '<candidateName> removed their application to the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-staff-applied',
    {
      group: 'program_transition',
      activity: 'Applied to <title>',
      description: '<candidateName> applied to the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-staff-declined',
    {
      group: 'program_transition',
      activity: 'Declined by <title>',
      description: '<candidateName> was declined by the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-staff-joined',
    {
      group: 'program_transition',
      activity: 'Joined <title>',
      description: '<candidateName> joined the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-staff-dropped-out',
    {
      group: 'program_transition',
      activity: 'Dropped out of <title>',
      description: '<candidateName> dropped out of the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-staff-graduated',
    {
      group: 'program_transition',
      activity: 'Graduated from <title>',
      description: '<candidateName> graduated from the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-staff-deleted',
    {
      group: 'program_transition',
      activity: 'Removed application from <title>',
      description: '<candidateName> removed their application to the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-application-status-updated',
    {
      group: 'program_transition',
      activity: '<title> application status was changed',
      description:
        "<staffName> from <staffOrgName> updated <candidateName>'s application to the <title> program. Please review application and confirm the change.",
    },
  ],
  [
    'program-invitation-sent-to-external',
    {
      group: 'program_invitation',
      activity: 'Invitation sent for <title>',
      description:
        '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-invitation-sent',
    {
      group: 'program_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-invitation-resent-to-external',
    {
      group: 'program_invitation',
      activity: 'Invitation sent for <title>',
      description:
        '<staffName> from <staffOrgName> invited <candidateInfo> to RiseKit via the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-invitation-resent',
    {
      group: 'program_invitation',
      activity: 'Invitation sent for <title>',
      description: '<staffName> from <staffOrgName> invited <candidateName> to the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'program-invitation-accepted',
    {
      group: 'program_invitation',
      activity: 'Invitation accepted for <title>',
      description: '<candidateName> accepted an invitation for the <postingOrgName> <title> program. See program information <here>.',
    },
  ],
  [
    'questionnaire-staff-updated',
    {
      group: 'questionnaire',
      activity: '<surveyTitle> Questionnaire Updated',
      description: '<staffName> from <staffOrgName> updated your <surveyTitle> questionnaire. See updated challenges questionnaire <here>.',
    },
  ],
  [
    'resume-candidate-updated',
    {
      group: 'resume',
      activity: 'Resume Updated',
      description: '<candidateName> resume was updated.',
    },
  ],
  [
    'resume-staff-updated',
    {
      group: 'resume',
      activity: 'Resume Updated',
      description: '<staffName> from <staffOrgName> has made changes to <candidateName> resume.',
    },
  ],
]);

export default NotificationTemplates;
