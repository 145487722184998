import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanField,
  ImageField,
  BooleanInput,
  Pagination,
} from 'react-admin';
import ListIcon from '@material-ui/icons/List';

export const Icon = ListIcon;
// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="title" />
      <BooleanField source="isCustomQuestion" />
      <TextField source="question" />
      <TextField source="answers" />
      <TextField source="answerType" />
      <TextField source="setupPrompt" />
      <TextField source="setupQuestion" />
      <TextField source="setupAnswers" />
      <TextField source="setupAnswersType" />
      <TextField source="extra" />
      <ImageField label="Icon" source="iconUrl" />

      <TextField source="value" />
      <EditButton basePath="/questionTemplates" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>QuestionTemplate {record ? `"${record.title}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <BooleanInput source="isCustomQuestion" />
      <TextInput source="question" />
      <TextInput source="answers" />
      <TextInput source="answerType" />
      <TextInput source="setupPrompt" />
      <TextInput source="setupQuestion" />
      <TextInput source="setupAnswers" />
      <TextInput source="setupAnswersType" />
      <TextInput source="extra" />
      {/* <ImageInput source="icon" /> */}
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <BooleanInput source="isCustomQuestion" />
      <TextInput source="question" />
      <TextInput source="answers" />
      <TextInput source="answerType" />
      <TextInput source="setupPrompt" />
      <TextInput source="setupQuestion" />
      <TextInput source="setupAnswers" />
      <TextInput source="setupAnswersType" />
      <TextInput source="extra" />
      {/* <ImageInput source="icon" /> */}
    </SimpleForm>
  </AdminCreate>
);
