import { connect } from 'react-redux';
import CandidatesListView from './candidates_list_view';
import {} from '../../../reducer';
import {} from '../../../solve/actions';

const mapStateToProps = () => {};

const mapDispatchToProps = {};

const CandidatesListContainer = connect(mapStateToProps, mapDispatchToProps)(CandidatesListView);

export default CandidatesListContainer;
