import styled from 'styled-components';
import theme from '../../../../common/theme';

const QuestionnaireCompletedStyles = styled.div`
  padding: 25px 50px;

  img {
    margin-top: 25px;
  }
  button {
    margin-top: 75px;
    outline: 0;
  }
  @media ${theme.query.desktopMax} {
    & {
      padding: 10px 20px;
    }
  }
`;

export default QuestionnaireCompletedStyles;
