import React from 'react';
// import PropTypes from 'prop-types';
import UpgradeStyles from './upgrade_styles';
import UpgradeNonprofit from './upgrade_nonprofit';
import UpgradeEmployer from './upgrade_employer';
import Tabber from '../../../ui/components/tabber';

const propTypes = {};

const defaultProps = {};

const UpgradeView = () => (
  <UpgradeStyles>
    <Tabber>
      <UpgradeEmployer tabLabel="Employer" />
      <UpgradeNonprofit tabLabel="Non Profit" />
    </Tabber>
  </UpgradeStyles>
);

UpgradeView.propTypes = propTypes;
UpgradeView.defaultProps = defaultProps;

export default UpgradeView;
