/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

const httpLink = createHttpLink({
  uri: 'https://spacexdata.herokuapp.com/graphql',
});

export default new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
