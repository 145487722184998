import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';

import Header from './header';
import Tabber from '../../../ui/components/tabber';
import JobCandidateResume from '../job_candidate_resume';
import JobCandidateQuestionnaire from '../job_candidate_questionnaire';
// import JobCandidateApplications from '../job_candidate_applications';
// import JobCandidateNotifications from '../job_candidate_notifications';
import UserModel from '../../../session/models/user_model';
import CandidateEventModel from '../../../solve/models/candidate_event_model';
import CandidateSurveyModel from '../../../solve/models/candidate_survey_model';
import ReadOnlySurveyWizard from '../read_only_survey_wizard/read_only_survey_wizard';

const EventApplicantDetailsView = ({ application, currentUser, candidateSurveys, candidateEventId, fetchCandidateEvent, listCandidateSurveys }) => {
  if (!application) {
    fetchCandidateEvent(currentUser, candidateEventId);
  }
  const candidate = application && application.get('candidate');
  const candidateId = application && application.getIn(['candidate', 'candidateId']);

  useEffect(() => {
    listCandidateSurveys(currentUser, candidateId);
  }, [candidateId, currentUser, listCandidateSurveys]);

  const questionnaireSurveys = candidateSurveys.filter(
    (survey) => survey.getIn(['survey', 'surveyType']) === 'challenges' || survey.getIn(['survey', 'surveyType']) === 'readiness',
  );

  return (
    <div>
      {application && (
        <React.Fragment>
          <Header
            name={`${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`}
            email={candidate.getIn(['user', 'email'])}
            phone={candidate.getIn(['user', 'phone'])}
            application={application}
          />
          <Tabber>
            <div tabLabel="Resume">
              <JobCandidateResume candidateId={candidateId} />
            </div>
            {/* <div tabLabel="Applications"> <JobCandidateApplications /> </div> */}
            <div tabLabel="Screening Questions">
              {application.get('candidateSurveys') ? (
                application.get('candidateSurveys').map((survey) => <ReadOnlySurveyWizard survey={survey} key={survey.get('id')} />)
              ) : (
                <p>This event did not have any screening questions</p>
              )}
            </div>
            <div tabLabel="Questionnaires">
              <JobCandidateQuestionnaire surveys={questionnaireSurveys} />
            </div>
            {/* <div tabLabel="Notifications" badge="3">
               <JobCandidateNotifications />
            </div> */}
          </Tabber>
        </React.Fragment>
      )}
    </div>
  );
};
EventApplicantDetailsView.propTypes = {
  application: PropTypes.oneOfType([PropTypes.instanceOf(CandidateEventModel), PropTypes.instanceOf(Map)]),
  candidateSurveys: PropTypes.oneOfType([PropTypes.instanceOf(CandidateSurveyModel), PropTypes.instanceOf(OrderedSet)]).isRequired,
  fetchCandidateEvent: PropTypes.func.isRequired,
  listCandidateSurveys: PropTypes.func.isRequired,
  candidateEventId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
};

EventApplicantDetailsView.defaultProps = {
  application: undefined,
};

export default EventApplicantDetailsView;
