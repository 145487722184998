import React from 'react';
// import PropTypes from 'prop-types';
import NotificationsStyles, { Header } from './notifications_styles';
import { DashboardCard } from '../../../common/styles/card';
import Notifications from '../../../notification/components/notifications';

const propTypes = {};

const defaultProps = {};

const NotificationsView = () => (
  <div className="container-wrapper">
    <NotificationsStyles>
      <Header>
        <div className="candidate-wrapper">
          <h2>Notifications</h2>
        </div>
      </Header>
      <div className="candidate-wrapper">
        <DashboardCard>
          <Notifications />
        </DashboardCard>
      </div>
    </NotificationsStyles>
  </div>
);

NotificationsView.propTypes = propTypes;
NotificationsView.defaultProps = defaultProps;

export default NotificationsView;
