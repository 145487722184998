import { connect } from 'react-redux';
import EventPreviewView from './event_preview_view';
import { getCurrentUser } from '../../../../reducer';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
});

const mapDispatchToProps = {};

const EventPreviewContainer = connect(mapStateToProps, mapDispatchToProps)(EventPreviewView);

export default EventPreviewContainer;
