import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'redux-form-material-ui';

const propTypes = {
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

const defaultProps = {
  label: undefined,
  type: 'text',
  disabled: false,
  required: false,
};

/* eslint-disable react/jsx-props-no-spreading */
const MuiTextField = ({ input, label, type, meta: { error, touched }, disabled, required, ...rest }) => (
  <TextField
    error={touched && error}
    label={label && required ? `${label}*` : label}
    type={type}
    disabled={disabled}
    helperText={touched && error}
    input={input}
    InputLabelProps={{
      shrink: false,
    }}
    {...rest}
  />
);

MuiTextField.propTypes = propTypes;
MuiTextField.defaultProps = defaultProps;

export default MuiTextField;
