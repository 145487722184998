import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { Map, OrderedSet } from 'immutable';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useEffect, useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';

import { useDebouncedEffect } from '../../../util/hooks';

import {
  getEnumsForFormSelect,
  getStaffEPCandidatesFilters,
  getStaffEPCandidatesSearch,
  getStaffEPCandidatesZipCode,
  getStaffEPFilteredEventsLoaded,
  getStaffEPFilteredProgramsLoaded,
  getStaffEPFilteredServicesLoaded,
} from '../../../reducer';
import { setStaffEPCandidatesFilters, setStaffEPCandidatesSearch, setStaffEPCandidatesZipCode } from '../../../solve/actions';

//  auto-populate children from ENUM
const FilterSelect = ({ filters, onChange, name, children }) => (
  <Select
    value={filters.get(name) || ''}
    fullWidth
    onChange={(e) => {
      onChange(filters.set(name, e.target.value));
    }}
  >
    {children}
  </Select>
);
FilterSelect.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const ZipDistanceFilter = ({ filters, onChange, distanceFilter, zipCode, setZipCode }) => {
  const [newZipcode, setNewZipcode] = useState(zipCode);
  const [zipValid, setZipValid] = useState(true);

  // Ensure we set the local state after updating redux
  useEffect(() => {
    setNewZipcode(zipCode);
  }, [zipCode, setNewZipcode]);

  const ifZip = (zip, action) => {
    if (zip === '') {
      setZipValid(true);
      if (action) {
        action(zip);
      }
    } else if (zip.match(/^\d{5}$/)) {
      if (!zipValid) {
        setZipValid(true);
      }
      if (action) {
        action(zip);
      }
    } else if (zipValid) {
      setZipValid(false);
    }
  };

  const onZipSubmit = (e) => {
    e.preventDefault();
    const val = e.target.querySelector('input').value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setZipCode);
  };
  const onZipBlur = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setZipCode);
  };
  const onZipChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (newZipcode === val) {
      return;
    }
    ifZip(val);
    setNewZipcode(val);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: 15 }}>
      <Grid item md={6} xs={6}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Distance</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="distance" variant="outlined">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {distanceFilter &&
              distanceFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
            <MenuItem value="" disabled style={{ opacity: 1, float: 'right' }}>
              <img src="/images/powered_by_google_white.png" alt="powered by Google" />
            </MenuItem>
          </FilterSelect>
        </FormControl>
      </Grid>
      <Grid item md={6} xs={6}>
        <form onSubmit={onZipSubmit}>
          <TextField
            label="Zip Code"
            type="number"
            margin="dense"
            variant="outlined"
            fullWidth
            mask="ddddd"
            onBlur={onZipBlur}
            onChange={onZipChange}
            className={zipValid ? '' : 'error'}
            error={!zipValid}
            value={newZipcode || ''}
            InputLabelProps={{
              shrink: false,
            }}
          />
        </form>
      </Grid>
    </Grid>
  );
};
ZipDistanceFilter.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  distanceFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  zipCode: PropTypes.string,
  setZipCode: PropTypes.func.isRequired,
};

ZipDistanceFilter.defaultProps = {
  zipCode: '',
};

function useSearch(search, setSearch, loaded) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (search !== newSearch) {
        setSearch(newSearch);
      }
    },
    1500,
    [loaded, search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const ProgramFiltersView = ({
  onChange,
  search,
  setSearch,
  filters,
  zipCode,
  distanceFilter,
  setZipCode,
  loaded,
  serviceTypeChoices,
  setServiceSelected,
  listAll,
}) => {
  const { newSearch, onSearchChange } = useSearch(search, setSearch, loaded);

  return (
    <Grid container spacing={2} style={{ marginBottom: 15 }}>
      <Grid item md={listAll ? 6 : 12} xs={12}>
        <TextField
          placeholder="Search programs and events by name"
          margin="dense"
          variant="outlined"
          fullWidth
          style={{ marginBottom: 10 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={newSearch}
          onChange={onSearchChange}
        />
      </Grid>
      {listAll ? (
        <Grid item md={6} xs={12}>
          <ZipDistanceFilter
            distanceFilter={distanceFilter}
            filters={filters}
            setStaffEPCandidatesZipCode={setStaffEPCandidatesZipCode}
            onChange={onChange}
            zipCode={zipCode}
            setZipCode={setZipCode}
          />
        </Grid>
      ) : (
        ''
      )}
      {listAll ? (
        <Grid item xs={12}>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel>Service Type</InputLabel>
            <RadioGroup row aria-label="serviceType" name="serviceType" value={filters.get('serviceType')}>
              {serviceTypeChoices &&
                serviceTypeChoices.map(([slug, label]) => (
                  <Button
                    className={`${filters.get('serviceType') === slug ? 'active' : ''} filter-button-item`}
                    key={slug}
                    onClick={(e) => {
                      e.preventDefault();
                      setServiceSelected(true);
                      onChange(filters.set('serviceType', slug));
                    }}
                    value={slug}
                  >
                    {label}
                  </Button>
                ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      ) : (
        ''
      )}
      <br />
      <br />
    </Grid>
  );
};
ProgramFiltersView.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setZipCode: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  distanceFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  serviceTypeChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  setServiceSelected: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  loaded: PropTypes.bool.isRequired,
  listAll: PropTypes.bool.isRequired,
};
ProgramFiltersView.defaultProps = {
  search: '',
  zipCode: '',
};

const mapStateToProps = (state, { listAll }) => ({
  loaded: getStaffEPFilteredEventsLoaded(state) && getStaffEPFilteredProgramsLoaded(state) && getStaffEPFilteredServicesLoaded(state),
  distanceFilter: getEnumsForFormSelect(state, 'DistanceType'),
  serviceTypeChoices: getEnumsForFormSelect(state, 'ServiceType'),
  filters: getStaffEPCandidatesFilters(state),
  zipCode: getStaffEPCandidatesZipCode(state),
  search: getStaffEPCandidatesSearch(state),
  listAll,
});

const mapDispatchToProps = {
  setZipCode: setStaffEPCandidatesZipCode,
  onChange: setStaffEPCandidatesFilters,
  setSearch: setStaffEPCandidatesSearch,
};

const ProgramFilters = connect(mapStateToProps, mapDispatchToProps)(ProgramFiltersView);

export default ProgramFilters;
