import { Record, Set } from 'immutable';

const EMPTY_SET = new Set();
const DEFAULT_STATE = {
  id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  authToken: undefined,
  phone: undefined,
  alternatePhones: [],
  roles: EMPTY_SET,
  permissions: [],
  isOrgAdmin: undefined,
  zip: undefined,
  candidateId: undefined,
  staffId: undefined,
  staffOrganization: {},
  emailValidatedAt: undefined,
  staffMembershipValidatedAt: undefined,
  sendEmailPermitted: undefined,
  sendTextPermitted: undefined,
  notificationFrequency: undefined,
  notificationSettings: undefined,
  sendRecommendationPermitted: undefined,
};

class UserModel extends Record(DEFAULT_STATE) {
  static _name = 'Session::UserModel';

  get name() {
    if (this.lastName === '(unknown)') {
      return this.firstName;
    }
    return `${this.firstName} ${this.lastName}`;
  }

  get signedIn() {
    return !!this.authToken;
  }

  get isAdmin() {
    return this.roles.includes('Admin');
  }

  get validEmail() {
    return !!this.emailValidatedAt;
  }

  get needsValidation() {
    return !this.validEmail || (this.staffId && !this.staffMembershipValidatedAt);
  }
}

export default UserModel;
