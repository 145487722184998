import styled from 'styled-components';
import theme from '../../../../common/theme';

const ChallengesFormStyles = styled.div`
  padding: 25px 50px;

  .header-section {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .header-section:first-child {
    padding-bottom: 15px;
    border-bottom: solid 1px #d8d8d8;
  }
  .header-section img {
    width: 50px;
    margin-right: 20px;
  }
  .box {
    background-color: #ffffff;
    box-shadow: 0px 2px 10px rgba(204, 204, 204, 0.5);
    padding: 35px 50px;
  }
  h2 {
    background-color: #3d5e7e;
    color: #ffffff;
    padding: 30px;
  }
  .answer {
    color: #222b45;
  }
  .examples {
    margin-left: 30px;
    font-style: italic;
    color: #c0c0c0;
  }
  .button-group {
    margin: 50px 0px;
    display: flex;
    justify-content: flex-end;
  }
  button {
    outline: 0;
  }

  @media ${theme.query.desktopMax} {
    & {
      padding: 10px 20px;
    }
    .box {
      padding: 35px 25px;
    }
  }
`;

export default ChallengesFormStyles;
