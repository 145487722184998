import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TileStyles, { Header, Body, Footer, Icon, Actions, Person, Content } from './tile_styles';
import { Card } from '../../../common/styles/layout';
import theme from '../../../common/theme';

const propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  border: PropTypes.string,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  subjectLabel: PropTypes.string,
  subject: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  createdAt: PropTypes.string,
  toolbarButtons: PropTypes.arrayOf(PropTypes.string),
  primaryButtons: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  backgroundColor: '#F7FCFF',
  color: theme.color.secondaryAction,
  icon: '/icons/avatar.svg',
  iconColor: '#F1F0F0',
  border: 'none',
  toolbarButtons: [],
  primaryButtons: [],
  createdAt: undefined,
  subjectLabel: 'Subject',
};

const TileView = ({
  backgroundColor,
  color,
  icon,
  iconColor,
  border,
  title,
  subTitle,
  subject,
  subjectLabel,
  body,
  createdAt,
  toolbarButtons,
  primaryButtons,
}) => (
  <Card color={backgroundColor}>
    <TileStyles>
      <Header>
        <Person>
          <Icon color={iconColor}>
            <img alt="avatar" src={icon} />
          </Icon>
          <div>
            <h4>{title}</h4>
            <p>{subTitle}</p>
          </div>
        </Person>
        <Actions>
          {toolbarButtons && <ButtonGroup style={{ border, borderRadius: '30px' }}>{toolbarButtons}</ButtonGroup>}
          {createdAt && (
            <p>
              {moment(createdAt).format('h:mma')}
              &nbsp; ({moment(createdAt).fromNow()})
            </p>
          )}
        </Actions>
      </Header>
      <Content>
        <Body color={color}>
          <h4>
            <b>{subjectLabel}: </b>
            {subject}
          </h4>
          <p>{body}</p>
        </Body>
        {primaryButtons && <Footer>{primaryButtons}</Footer>}
      </Content>
    </TileStyles>
  </Card>
);

TileView.propTypes = propTypes;
TileView.defaultProps = defaultProps;

export default TileView;
