import React, { useState } from 'react';
import Pagination from '@material-ui/lab/Pagination';

export interface RRPaginationType {
  count: number;
  page?: number;
  handleChange: (_: any, value: number) => void;
}

export const RRPagination: React.FC<RRPaginationType> = ({ count, page, handleChange }) => {
  return <Pagination count={count} page={page} variant="outlined" color="primary" onChange={handleChange} hideNextButton hidePrevButton />;
};

const usePagination = () => {
  const [page, setPage] = useState<number>();

  const handleChange = (_: any, value: number) => {
    setPage(value);
  };

  return {
    page,
    handleChange,
  };
};

export default usePagination;
