import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router';
import Spinner from '../../../common/components/spinner';

const propTypes = {
  signedIn: PropTypes.bool.isRequired,
  signOut: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-unused-vars
const SignOutView = ({ signedIn, signOut }) => {
  useEffect(() => {
    signOut();
  });
  return signedIn ? <Spinner /> : <Redirect to="/" />;
};

// Global purge redux magic...
global.nuke = () => {
  // eslint-disable-next-line no-undef,no-restricted-globals
  persistor.purge().then(() => location.reload());
};

SignOutView.propTypes = propTypes;

export default SignOutView;
