import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import ResourcesListingsStyles from './resources_listings_styles';
import Tabber from '../../../ui/components/tabber';
import ResourcesList from '../resources_list';
// import RecommendedPrograms from '../recommended_jobs';

const propTypes = {};

const defaultProps = {};

const ResourcesListingsView = () => {
  const [tabIndex, setTabIndex] = useState(0);

  let list;
  switch (tabIndex) {
    case 0:
      list = <ResourcesList mode="all" />;
      break;
    // case 1:
    //   list = <ResourcesList mode="saved" />;
    //   break;
    // case 2:
    //   list = <ResourcesList mode="applied" />;
    //   break;
    default:
      list = null;
  }

  return (
    <ResourcesListingsStyles className="container-wrapper">
      <div className="candidate-wrapper">
        {/* <RecommendedPrograms /> */}
        <Tabber onChange={setTabIndex}>
          <div tabLabel="All" />
          {/* <div tabLabel="Saved" />
          <div tabLabel="Applied / Registered" /> */}
          {/* <div tabLabel="In progress"></div> */}
        </Tabber>
      </div>
      {list}
    </ResourcesListingsStyles>
  );
};

ResourcesListingsView.propTypes = propTypes;
ResourcesListingsView.defaultProps = defaultProps;

export default ResourcesListingsView;
