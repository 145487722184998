import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { getCurrentUser } from '../reducer';

const mapStateToProps = (state) => ({ signedIn: getCurrentUser(state).signedIn });

const RedirectWithoutAuth = (props) => {
  const { signedIn, component: Component, location, authPath } = props;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return signedIn ? <Component {...props} /> : <Redirect to={{ pathname: authPath, state: { returnTo: location } }} />;
};

RedirectWithoutAuth.propTypes = {
  signedIn: PropTypes.bool.isRequired,
  component: PropTypes.elementType.isRequired,
  location: PropTypes.shape({}).isRequired,
  authPath: PropTypes.string.isRequired,
};

const ConnectedRedirectWithoutAuth = connect(mapStateToProps)(RedirectWithoutAuth);

const AuthenticatedRoute = ({ component, authPath, ...props }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Route {...props} component={(innerProps) => <ConnectedRedirectWithoutAuth {...innerProps} component={component} authPath={authPath} />} />
);

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  authPath: PropTypes.string,
};

AuthenticatedRoute.defaultProps = {
  authPath: '/signin',
};

export default AuthenticatedRoute;
