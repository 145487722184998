import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
// import { darken } from 'polished';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import theme from '../theme';

export const AddButtonStyle = withStyles({
  root: {
    textTransform: 'none',
    color: '#222B45',
    borderColor: theme.color.action,
    '&:hover': {
      color: theme.color.action,
      borderColor: theme.color.action,
      backgroundColor: '#fff',
    },
    '&:active': {
      color: 'white',
      backgroundColor: theme.color.action,
      borderColor: theme.color.action,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
  },
})(Button);

export const AddButton = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AddButtonStyle variant="outlined" size="small" color="primary" {...rest}>
    <AddIcon fontSize="inherit" className="add-icon" style={{ color: theme.color.action }} />
    {children}
  </AddButtonStyle>
);

AddButton.propTypes = {
  children: PropTypes.node.isRequired,
};

AddButton.defaultProps = {};

export const ActionButton = ({ children, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AddButtonStyle variant="outlined" size="small" color="primary" {...rest}>
    {children}
  </AddButtonStyle>
);

ActionButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
};

ActionButton.defaultProps = {
  onClick: undefined,
};
