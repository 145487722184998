import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Map, OrderedSet } from 'immutable';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import StarIcon from '@material-ui/icons/Star';
import CheckIcon from '@material-ui/icons/Check';
import {
  TileWrapper,
  TileStyles,
  Header,
  Details,
  Logo,
  Status,
  Body,
  Pill,
  Title,
  Organization,
  Footer,
  Services,
  ButtonGroup,
} from '../../../common/styles/tile';
import Button from '../../../common/components/button/index';
import { ExpandableText } from '../../../common/components';
import JobPostingModel from '../../../solve/models/job_posting_model';
import MyStatus from '../my_status';
import StatusUpdateTile from '../../../solve/components/status_update_tile';
import UserModel from '../../../session/models/user_model';
import getListingImage from '../../../common/components/get_listing_image';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  id: PropTypes.string.isRequired,
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]),
  candidateApplication: PropTypes.shape({
    get: PropTypes.func,
    candidateState: PropTypes.string,
  }),
  createCandidateApplication: PropTypes.func.isRequired,
  deleteCandidateApplication: PropTypes.func.isRequired,
  transitionCandidateApplicationByCandidate: PropTypes.func.isRequired,
  opportunityTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  hideActions: PropTypes.bool,
};

const defaultProps = {
  job: undefined,
  candidateApplication: undefined,
  hideActions: false,
};

const TileView = ({
  currentUser,
  id,
  job,
  candidateApplication,
  createCandidateApplication,
  deleteCandidateApplication,
  transitionCandidateApplicationByCandidate,
  opportunityTypes,
  hideActions,
}) => {
  if (job === undefined) {
    return null;
  }

  const logoUrl = getListingImage(job, 'job');

  const candidateState = candidateApplication && candidateApplication.get('candidateState');
  const applied = ![undefined, 'c_deleted', 'c_saved'].includes(candidateState);

  return (
    <TileWrapper>
      <StatusUpdateTile
        record={candidateApplication}
        setCandidateState={(state) => transitionCandidateApplicationByCandidate(currentUser, candidateApplication.get('id'), state)}
      />
      <TileStyles>
        <Logo>
          <img src={logoUrl} alt="Job Posting Logo" />
          <Status>{applied && <MyStatus candidateApplication={candidateApplication} />}</Status>
        </Logo>

        <Header>
          <Pill>Job</Pill>
          <Details>
            {job.get('createdAt') && job.get('createdAt') !== 'Invalid date' && (
              <span className="detail-info">
                <ScheduleIcon />
                Posted {`${moment.utc(job.get('createdAt')).fromNow()}`}
              </span>
            )}
            {!!job.get('distance') && (
              <span className="detail-info">
                <LocationOnOutlinedIcon />
                {`${Math.round(parseFloat(job.get('distance')) * 10) / 10} mi`}
              </span>
            )}
          </Details>
        </Header>

        <Body>
          <Title>{job.get('title')}</Title>
          <Organization>{job.getIn(['employer', 'name'])}</Organization>
          <ExpandableText>
            <div dangerouslySetInnerHTML={{ __html: job.get('description') }} />
          </ExpandableText>
        </Body>
        <Footer className="jobs">
          <Services>
            {opportunityTypes && (
              <div className="service">
                <LabelOutlinedIcon />
                <span>
                  <span className="heading">Benefits: </span>
                  <span className="body">{opportunityTypes.map((item) => item[1]).join(', ')}</span>
                </span>
              </div>
            )}
          </Services>
          {!hideActions && (
            <ButtonGroup>
              <div className="subtle-buttons">
                <Button
                  buttonType="subtle-outline"
                  onClick={candidateState && candidateState === 'c_saved' ? deleteCandidateApplication : createCandidateApplication}
                >
                  {candidateState && candidateState === 'c_saved' ? (
                    <React.Fragment>
                      <CheckIcon />
                      Saved
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <StarIcon />
                      Save
                    </React.Fragment>
                  )}
                </Button>
              </div>
              <Link to={`/candidate/jobs/${id}`}>
                <Button buttonType="secondary-outline">
                  See Details
                  <ChevronRightIcon />
                </Button>
              </Link>
            </ButtonGroup>
          )}
        </Footer>
      </TileStyles>
    </TileWrapper>
  );
};

TileView.propTypes = propTypes;
TileView.defaultProps = defaultProps;

export default TileView;
