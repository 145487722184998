import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import Button from '@material-ui/core/Button';
import PlanStyles from './plan_styles';

const propTypes = {
  title: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  term: PropTypes.string,
  cta: PropTypes.string,
};

const defaultProps = {
  term: 'mo',
  cta: 'Choose Plan',
};

const PlanView = ({ title, tagline, price, term, cta }) => (
  <PlanStyles>
    {title && <h4>{title}</h4>}
    <h5>{tagline}</h5>
    <h1>
      {price > 0 && <small>$</small>}
      {/* eslint-disable-next-line no-nested-ternary */}
      {price > 0 ? numeral(price).format('0,0') : price !== '' ? 'FREE' : ' '}
      {price > 0 && <small>/{term}</small>}
    </h1>
    <Button variant="contained" color="secondary">
      {cta}
    </Button>
  </PlanStyles>
);

PlanView.propTypes = propTypes;
PlanView.defaultProps = defaultProps;

export default PlanView;
