import React from 'react';
import PropTypes from 'prop-types';
// import { List } from 'immutable';
import SkillsDisplay from './skills_display';
import SkillsEditing from './skills_editing';
import SkillStyles from './skills_styles';
// import PDFUpload from './pdf_upload';
import { Card } from '../../../../common/styles/layout';

const propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  prefixWord: PropTypes.string,
  afterContent: PropTypes.string,
};

const defaultProps = {
  prefixWord: undefined,
  afterContent: undefined,
};

const SkillsView = ({ fields, prefixWord, afterContent }) => (
  <SkillStyles>
    <h3>
      {prefixWord} Skills {afterContent}
    </h3>
    <Card>
      <div className="editing-container">
        <SkillsEditing skills={fields} />
      </div>
      <div className="display-container">
        <SkillsDisplay skills={fields} />
      </div>
    </Card>
  </SkillStyles>
);

SkillsView.propTypes = propTypes;
SkillsView.defaultProps = defaultProps;

export default SkillsView;
