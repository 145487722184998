import styled from 'styled-components';
import theme from '../../../common/theme';

const ContainerStyles = styled.div`
  .logo {
    display: flex;
    align-items: center;
    background: ${theme.color.primary};

    img {
      display: block;
      height: 30px;
    }
  }
  .cobranding-logo img {
    max-width: 170px;
    max-height: 40px;
    width: auto;
    height: auto;
  }
  .risekit-logo {
    background-color: ${theme.color.riseKitBlack};
    background: ${theme.color.riseKitBlack};
  }
  .risekit-drawer > div {
  //   background-color: ${theme.color.riseKitBlue};
    border: none;
  }
  .member-info {
    margin-left: 15px;
    text-align: left;
    font-size: 12px;
    flex-direction: column;
    .member-name {
      text-transform: uppercase;
      font-weight: bold;
    }
    .member-company {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  }

  .ContainerContent {
    flex-grow: 1;
    padding: ${theme.padding.base};
    background: #ffffff;
    width: 100%;

    @media ${theme.query.tabletMin} {
      width: calc(100% - 220px);
    }
  }

  @media only screen and (max-width: 600px) {
    .logo {
      background: transparent;
    }
  }
`;

export const DrawerStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .drawerBottom {
    text-align: center;
    margin-bottom: 20px;
  }

  .logo {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    padding-left: 25px;
    background: ${theme.color.primary};
    img {
      height: 30px;
    }
  }

  .drawerTop .risekit-logo {
    background-color: ${theme.color.riseKitBlack};
    background: ${theme.color.riseKitBlack};
  }
  .icon {
    margin-left: 10px;
    width: 22px;
  }
  .bottom {
    position: relative;
    width: 220px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 60px;

    img {
      width: 60px;
    }
  }
  .cobranding-logo img {
    max-width: 170px;
    max-height: 40px;
    width: auto;
    height: auto;
  }

  @media only screen and (max-width: 600px) {
    .logo {
      background: transparent;
    }
  }
`;

export const Notifications = styled.div`
  position: relative;
  padding-right: 20px;
  border-right: solid 1px ${theme.color.gray};
  img {
    height: 28px;
    padding-top: 3px;
  }
  .bubble {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    left: -7px;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background-color: #52ca5b;
    color: #ffffff;
    font-size: 10px;
  }
`;

export const SidebarListItem = styled.a`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: ${theme.padding.small};
`;

export default ContainerStyles;
