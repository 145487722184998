import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import { getCurrentUser, getGlobalSurveysOfType } from '../../../reducer';
import QuestionnaireView from './questionnaire_view';

const questionnarieTitles = fromJS(['Financial Challenges', 'Background Challenges', 'Workforce Readiness', 'Emotional Challenges']);

const mapStateToProps = (state) => {
  const challengeSurveys = getGlobalSurveysOfType(state, 'challenges');
  const readinessSurveys = getGlobalSurveysOfType(state, 'readiness');

  const allSurveys = challengeSurveys.concat(readinessSurveys);
  const orderSurveys = questionnarieTitles.map((title) => allSurveys.find((survey) => survey.get('title') === title));

  return {
    currentUser: getCurrentUser(state),
    candidateQuestionnaire: orderSurveys,
  };
};

const mapDispatchToProps = {};

const QuestionnaireContainer = connect(mapStateToProps, mapDispatchToProps)(QuestionnaireView);

export default QuestionnaireContainer;
