const ACTIONS = {
  DISMISS: 'ONESHOT::DISMISS',
};

export default ACTIONS;

export const dismiss = (id) => ({
  type: ACTIONS.DISMISS,
  id,
});
