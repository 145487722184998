import React from 'react';
// import PropTypes from 'prop-types';
import NotificationsStyles from './notifications_styles';
import Notifications from '../../../notification/components/notifications';

const propTypes = {};

const defaultProps = {};

const NotificationsView = () => (
  <NotificationsStyles>
    <h2>Notifications</h2>
    <div className="staff-wrapper">
      <Notifications />
    </div>
  </NotificationsStyles>
);

NotificationsView.propTypes = propTypes;
NotificationsView.defaultProps = defaultProps;

export default NotificationsView;
