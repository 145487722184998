import { connect } from 'react-redux';
import NotesNewView from './notes_new_view';
import { getCurrentUser, getCandidate } from '../../../reducer';
import { createNote } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: candidateId },
    },
  },
) => {
  const currentUser = getCurrentUser(state);
  return {
    currentUser,
    candidateId,
    candidate: getCandidate(state, candidateId),
  };
};

const mapDispatchToProps = {
  createNote,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => dispatchProps.createNote(stateProps.currentUser, stateProps.candidateId, values),
  ...ownProps,
  ...stateProps,
});

const NotesNewContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(NotesNewView);

export default NotesNewContainer;
