import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  postingOrganizationID: undefined,
  addressID: undefined,
  state: undefined,
  name: undefined,
  website: undefined,
  phone: undefined,
  description: undefined,
  primaryServiceType: undefined,
  industryType: undefined,
  industrySpecificsType: undefined,
  serviceTypes: undefined,
  supportTypes: undefined,
  careerInterestTypes: undefined,
  dresscode: undefined,
  estimatedSalary: undefined,
  cost: undefined,
  isClass: undefined,
  startDate: undefined,
  endDate: undefined,
  days: undefined,
  startTime: undefined,
  endTime: undefined,
  gainedJobTypes: undefined,
  gainedCertifications: undefined,
  gainedSkillTypes: undefined,
  targetParticipantTypes: undefined,
  targetMinimumAge: undefined,
  targetMaximumAge: undefined,
  targetGenderType: undefined,
  targetMinimumEducationLevelType: undefined,
  targetZipCodes: undefined,
  applicationWebsite: undefined,
  isResumeRequired: undefined,
  distance: undefined,
  customThankYouMessage: undefined,
  logoUrl: undefined,
  postExpirationDate: undefined,
  createdAt: undefined,
  updatedAt: undefined,
};

class ProgramModel extends Record(DEFAULT_ARGS) {
  static _name = 'Program';

  get averageSalary() {
    return this.get('estimatedSalary');
  }
}

export default ProgramModel;
