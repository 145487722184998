import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Grid from '@material-ui/core/Grid';
import { reduxForm, Field, MuiTextField, SelectField } from '../../../common/form';
import { Button } from '../../../common/components';
import messageFormValidate from './message_form_validate';
import MessageFormStyles from './message_form_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  sendToOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

const MessageForm = ({ handleSubmit, pristine, submitting, sendToOptions }) => {
  const history = useHistory();

  const handleSubmitAndRedirect = (event) => {
    handleSubmit(event).then((_results) => {
      history.goBack();
    });
  };

  return (
    <MessageFormStyles>
      <form onSubmit={handleSubmitAndRedirect}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <SelectField options={sendToOptions} label="Send To" name="toUserId" margin="dense" variant="outlined" fullWidth required />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Field component={MuiTextField} label="Subject" name="messageSubject" margin="dense" variant="outlined" fullWidth required />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Field component={MuiTextField} label="Message" name="messageBody" multiline rows={6} margin="dense" variant="outlined" fullWidth required />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Button type="submit" buttonType="primary" disabled={pristine || submitting}>
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
    </MessageFormStyles>
  );
};

MessageForm.propTypes = propTypes;
MessageForm.defaultProps = defaultProps;

const MessageFormView = reduxForm({
  form: 'messageForm',
  enableReinitialize: true,
  validate: messageFormValidate,
})(MessageForm);

export default MessageFormView;
