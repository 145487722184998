import { connect } from 'react-redux';
import EventParticipantsView from './event_participants_view';
import {
  getEvent,
  getCurrentUser,
  getCandidateEvents,
  getStaffCandidateEventIds,
  getStaffCandidatesForEventLoaded,
  getStaffCandidatesForEventFilters,
  getStaffCandidatesForEventSearch,
  // getStaffInvitations,
  getAllInvitationsForListing,
} from '../../../reducer';
import {
  fetchEvent,
  listFilteredCandidatesForEvent,
  setStaffEventCandidatesSearch,
  listInvitations,
  bulkCreateInvitations,
  resendInvitation,
} from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: eventId },
    },
  },
) => {
  const candidiateEventIds = getStaffCandidateEventIds(state);
  const candidateEvents = getCandidateEvents(state, candidiateEventIds);

  return {
    eventId,
    event: getEvent(state, eventId),
    user: getCurrentUser(state),
    candidateEvents,
    loaded: getStaffCandidatesForEventLoaded(state),
    filters: getStaffCandidatesForEventFilters(state),
    search: getStaffCandidatesForEventSearch(state),
    invitations: getAllInvitationsForListing(state, eventId),
  };
};

const mapDispatchToProps = {
  fetchEvent,
  listFilteredCandidatesForEvent,
  setSearch: setStaffEventCandidatesSearch,
  bulkCreateInvitations,
  listInvitations,
  resendInvitation,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  createInvitationsFromFile: (file) => dispatchProps.bulkCreateInvitations(stateProps.user, 'events', stateProps.eventId, file),
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
});

const EventParticipantsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(EventParticipantsView);

export default EventParticipantsContainer;
