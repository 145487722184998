import { Map, fromJS, OrderedSet } from 'immutable';
import { createSelector } from 'reselect';
import { combineImmutableReducers, ResourcefulReducer } from '../../../util';
import ACTIONS from '../../actions';

const EMPTY_LIST = fromJS([]);
const EMPTY_MAP = fromJS({});

export const CandidatePathwayResourceReducer = new ResourcefulReducer('candidatePathways', Map, {
  actionTypes: [
    ACTIONS.LIST_CANDIDATE_PATHWAYS.success,
    ACTIONS.FETCH_CANDIDATE_PATHWAY.success,
    ACTIONS.FETCH_CANDIDATE_PATHWAYS.success,
    ACTIONS.UPDATE_CANDIDATE_PATHWAY.success,
    ACTIONS.TRANSITION_CANDIDATE_PATHWAYS_STAFF_STATE.success,
  ],
});

const byPathwayInstanceIdReducer = (state = EMPTY_MAP, action) => {
  if (action.response?.entities?.candidatePathways) {
    return state.withMutations((mutable) => {
      fromJS(action.response.entities.candidatePathways).forEach((candidatePathway, candidatePathwayId) => {
        mutable.update(candidatePathway.get('pathwayInstance'), OrderedSet([]), (list) => list.toOrderedSet().add(candidatePathwayId));
      });
    });
  }
  return state;
};

const CandidatePathwayReducer = combineImmutableReducers({
  resource: CandidatePathwayResourceReducer,
  byPathwayInstanceId: byPathwayInstanceIdReducer,
});

export const getList = (state, list) => CandidatePathwayResourceReducer.selectors.getList(state.get('resource'), list);
export const getOne = (state, id) => CandidatePathwayResourceReducer.selectors.getOne(state.get('resource'), id);

export const getCandidatePathwaysIdsForPathwayInstance = (state, pathwayInstanceId) => state?.getIn(['byPathwayInstanceId', pathwayInstanceId]) || EMPTY_LIST;

export const getCandidatePathwaysForPathwayInstance = createSelector(
  (state) => state || EMPTY_MAP.set('resource', EMPTY_MAP),
  getCandidatePathwaysIdsForPathwayInstance,
  getList,
);

export const getCandidatePathwaysIdsForPathwayInstances = (state, pathwayInstanceIds) =>
  pathwayInstanceIds?.reduce((idsList, pathwayInstanceId) => idsList.concat(state?.getIn(['byPathwayInstanceId', pathwayInstanceId])), EMPTY_LIST) ||
  EMPTY_LIST;

export const getCandidatePathwaysForPathwayInstances = createSelector(
  (state) => state || EMPTY_MAP.set('resource', EMPTY_MAP),
  getCandidatePathwaysIdsForPathwayInstances,
  getList,
);

export default CandidatePathwayReducer;
