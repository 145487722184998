import React from 'react';
import PropTypes from 'prop-types';
import { Text, Button } from '../../../common/components';
import StatusUpdateTileStyles from './status_update_tile_styles';

const propTypes = {
  staffName: PropTypes.string,
  staffOrg: PropTypes.string,
  oldState: PropTypes.string,
  newState: PropTypes.string,
  setState: PropTypes.func,
  canApprove: PropTypes.bool.isRequired,
};

const defaultProps = {
  staffName: undefined,
  staffOrg: undefined,
  oldState: undefined,
  newState: undefined,
  setState: undefined,
};

const StatusUpdateTileView = ({ staffName, staffOrg, oldState, newState, setState, canApprove }) => {
  if (!canApprove || !setState || oldState === newState) {
    return '';
  }

  return (
    <StatusUpdateTileStyles>
      <Text size="small">
        {staffName} from {staffOrg} has changed status from {oldState} to {newState}
      </Text>
      <div className="status-buttons">
        <Button size="small" buttonType="secondary-outline" onClick={() => setState(newState)}>
          Approve
        </Button>
        <Button size="small" buttonType="secondary-outline" onClick={() => setState(oldState)}>
          Deny
        </Button>
      </div>
    </StatusUpdateTileStyles>
  );
};

StatusUpdateTileView.propTypes = propTypes;
StatusUpdateTileView.defaultProps = defaultProps;

export default StatusUpdateTileView;
