import { connect } from 'react-redux';
import ProgramOverviewFormView from './program_overview_form_view';
import { formValueSelector } from '../../../../common/form';
import { getSystemEnumList, getEnumsForFormSelect, getOrganizations, getCurrentUser } from '../../../../reducer';
import { listOrganizations } from '../../../../solve/actions';

const selector = formValueSelector('programForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  serviceTypes: getSystemEnumList(state, 'ServiceType').map((item) => [item.get('slug'), item.get('label')]),
  industryTypes: getEnumsForFormSelect(state, 'IndustryType'),
  industrySpecifics: getSystemEnumList(state, 'IndustrySpecificsType').map((item) => [item.get('slug'), item.get('label')]),
  certificationCredentialTypes: getSystemEnumList(state, 'CertificationType').map((item) => [item.get('slug'), item.get('label')]),
  skillTypes: getSystemEnumList(state, 'SkillType').map((item) => [item.get('slug'), item.get('label')]),
  noStartDate: selector(state, 'noStartDate'),
  classBased: selector(state, 'isClass'),
  organizations: getOrganizations(state).map((item) => [item.get('id'), item.get('name')]),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listOrganizations,
};

const ProgramOverviewFormContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramOverviewFormView);

export default ProgramOverviewFormContainer;
