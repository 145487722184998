import { change } from 'redux-form';
import { connect } from 'react-redux';
import SelectCandidatesListView from './select_candidates_list_view';
// import { getEnumsForFormSelect } from '../../../../../../../reducer';
import { getStaffCandidateIds, getCurrentUser, getStaffCandidatesLoaded, getStaffCandidatesFilters, getStaffTotalCandidates } from '../../../../../../reducer';
import { listFilteredCandidatesForStaff, setStaffCandidatesFilters } from '../../../../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const candidateIds = getStaffCandidateIds(state);
  const formCandidateIds = state.getIn(['form', 'selectCandidatesForm', 'values', 'candidates']);

  const selectedCandidateIds = !formCandidateIds ?
    [] :
    Object.entries(formCandidateIds.toJS())
      .filter(([, isSelected]) => isSelected)
      .map(([candidateId]) => candidateId);

  return {
    candidateIds,
    currentUser,
    organizationId: currentUser.getIn(['staffOrganization', 'id']),
    loaded: getStaffCandidatesLoaded(state),
    filters: getStaffCandidatesFilters(state),
    totalCandidates: getStaffTotalCandidates(state),
    selectedCandidateIds,
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listFilteredCandidatesForStaff,
  setFilters: setStaffCandidatesFilters,
  changeSelectedCandidateState: (candidateId, newState) => change('selectCandidatesForm', `candidates[${candidateId}]`, newState),
};

const SelectCandidatesListContainer = connect(mapStateToProps, mapDispatchToProps)(SelectCandidatesListView);

export default SelectCandidatesListContainer;
