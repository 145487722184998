import { connect } from 'react-redux';
import PathwayStepDetailsView from './pathway_step_details_view';
import { getCandidatePathwaysForPathwayInstance, getCurrentUser, getPathwayInstance } from '../../../reducer';
import {
  fetchPathwayInstance,
  updatePathwayInstanceV2,
  transitionCandidatePathwayStaffState,
  listCandidatePathwaysForPathwayInstance,
} from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { pathwayInstanceId, pathwayStepId },
    },
  },
) => ({
  pathwayInstanceId,
  pathwayStepId,
  user: getCurrentUser(state),
  pathwayInstance: getPathwayInstance(state, pathwayInstanceId),
  candidatePathways: getCandidatePathwaysForPathwayInstance(state, pathwayInstanceId),
});

const mapDispatchToProps = {
  fetchPathwayInstance,
  updatePathwayInstanceV2,
  transitionCandidatePathwayStaffState,
  listCandidatePathwaysForPathwayInstance,
};

const PathwayStepDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayStepDetailsView);

export default PathwayStepDetailsContainer;
