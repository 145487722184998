import styled from 'styled-components';
import theme from '../../../common/theme';

const ServiceStyles = styled.div`
  .service {
    width: 80px;
  }

  .service-image {
    width: 40px;
  }
`;

export const MetaLabel = styled.div`
  color: ${theme.color.text.medium};
`;

export const MetaValue = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export default ServiceStyles;
