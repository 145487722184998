import styled from 'styled-components';
// import theme from '../../../common/theme';

const IndustriesStyles = styled.div`
  .editing-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .display-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .upload-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export default IndustriesStyles;
