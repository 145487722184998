import React from 'react';
import { useHistory } from 'react-router-dom';
import { OrderedSet, Map, List } from 'immutable';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { green, red } from '@material-ui/core/colors';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CandidateSettingsStyles from './candidate_settings_styles';
import Spinner from '../../../common/components/spinner';
import { Field, MuiDatePicker, MuiSelect, MuiTextField, phoneMask, reduxForm, zipCodeMask } from '../../../common/form';
import { Button } from '../../../common/components';
import { Card } from '../../../common/styles/layout';
import { useFetchOnce } from '../../../util/hooks';
import candidateSettingsFormValidate from '../../../settings/settings_form_validate';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map),
  candidateId: PropTypes.string.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  genderTypes: PropTypes.instanceOf(OrderedSet),
  ethnicityTypes: PropTypes.instanceOf(OrderedSet),
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  emailValidated: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reconfirmEmail: PropTypes.func.isRequired,
  showInmates: PropTypes.bool.isRequired,
};

const defaultProps = {
  candidate: undefined,
  genderTypes: new List(),
  ethnicityTypes: new List(),
};

const CandidateSettingsForm = ({
  user,
  handleSubmit,
  reconfirmEmail,
  fetchCandidate,
  emailValidated,
  genderTypes,
  ethnicityTypes,
  pristine,
  submitting,
  candidate,
  candidateId,
  showInmates,
}) => {
  const history = useHistory();
  useFetchOnce(user, candidateId, fetchCandidate, candidate);

  return (
    <CandidateSettingsStyles>
      {!candidate && <Spinner />}

      {candidate && (
        <form onSubmit={handleSubmit}>
          <Button buttonType="back" onClick={history.goBack} size="small">
            <KeyboardBackspaceIcon />
            Go Back
          </Button>
          <Card style={{ marginTop: '10px', paddingBottom: '30px' }}>
            <h2>
              Settings for {candidate.getIn(['user', 'firstName'])} {candidate.getIn(['user', 'lastName'])}
            </h2>
            <div className="fields-2">
              <Field component={MuiTextField} label="First Name" name="userAttributes.firstName" margin="dense" variant="outlined" fullWidth required />
              <Field component={MuiTextField} label="Last Name" name="userAttributes.lastName" margin="dense" variant="outlined" fullWidth required />
            </div>
            <div className="fields-2">
              <Field component={MuiDatePicker} label="Birthday (mm-dd-yyyy)" name="birthday" margin="dense" variant="outlined" fullWidth required />
              <Field
                variant="outlined"
                component={MuiTextField}
                label="Zip Code"
                name="addressAttributes.zip"
                margin="dense"
                fullWidth
                required
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...zipCodeMask}
              />
            </div>

            <div className="fields-2">
              <Field
                variant="outlined"
                component={MuiTextField}
                label="Phone Number"
                name="userAttributes.phone"
                margin="dense"
                type="cell"
                fullWidth
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...phoneMask}
              />
              <div className="column">
                <Field
                  component={MuiTextField}
                  label="Email"
                  name="userAttributes.email"
                  type="email"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  disabled={!showInmates}
                />
                {emailValidated ? (
                  <div className="email-validation">
                    <CancelIcon style={{ color: red[500] }} /> Email Not Confirmed.{' '}
                    <small>
                      <a
                        href="/"
                        onClick={(event) => {
                          event.preventDefault();
                          reconfirmEmail();
                          alert('Confirmation mail sent!');
                        }}
                      >
                        Resend Link
                      </a>
                    </small>
                  </div>
                ) : (
                  <div className="email-validation">
                    <CheckCircleIcon style={{ color: green[500] }} /> Email Confirmed.
                  </div>
                )}
              </div>
            </div>
            <br />
            <h4>Some additional information will help us suggest even better job opportunities and resources:</h4>
            <div className="fields-2">
              <MuiSelect options={genderTypes} label="Gender" name="genderType" margin="dense" required />
              <MuiSelect options={ethnicityTypes} label="Ethnicity Type" name="ethnicityType" margin="dense" required />
            </div>
            <div className="fields-2">
              <Field component={MuiTextField} label="LinkedIn Url" name="linkedInUrl" margin="dense" variant="outlined" />
              <MuiSelect
                options={[
                  [true, 'Yes'],
                  [false, 'No'],
                ]}
                label="Have you been in the military"
                name="military"
                margin="dense"
              />
            </div>
            <br />
            <Button
              buttonType="primary"
              type="submit"
              disabled={pristine || submitting}
              onClick={() => {
                handleSubmit();
                history.goBack();
              }}
            >
              Submit
            </Button>
          </Card>
        </form>
      )}
    </CandidateSettingsStyles>
  );
};

CandidateSettingsForm.propTypes = propTypes;
CandidateSettingsForm.defaultProps = defaultProps;

const CandidateSettingsView = reduxForm({
  form: 'candidateSettingsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: candidateSettingsFormValidate,
})(CandidateSettingsForm);

export default CandidateSettingsView;
