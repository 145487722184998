// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const reviewFormValidate = (values) => {
  const errors = {};
  // if (!values.get('jobSharingType')) {
  //   errors.jobSharingType = 'Required';
  // }
  if (!values.get('responsibilities')) {
    errors.responsibilities = 'Required';
  }
  if (!values.get('certifications')) {
    errors.certifications = 'Required';
  }
  if (!values.get('desiredEducation')) {
    errors.desiredEducation = 'Required';
  }
  if (!values.get('desiredSkillTypes')) {
    errors.desiredSkillTypes = 'Required';
  }
  if (!values.get('requiredSkillTypes')) {
    errors.requiredSkillTypes = 'Required';
  }
  if (!values.get('opportunityTypes')) {
    errors.opportunityTypes = 'Required';
  }

  return errors;
};

export default reviewFormValidate;
