import styled from 'styled-components';
// import theme from '../../../common/theme';

const UpgradeCheckoutStyles = styled.div`
  .MuiFormControlLabel-root {
    margin-bottom: 10px;
    align-items: flex-start;
  }
`;

export default UpgradeCheckoutStyles;
