import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  TextInput,
  LongTextInput,
  BooleanField,
  BooleanInput,
  NumberField,
  NumberInput,
  Pagination,
} from 'react-admin';
import ListIcon from '@material-ui/icons/List';

export const Icon = ListIcon;

// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="enumName" />
      <TextField source="slug" />
      <TextField source="label" />
      <NumberField source="position" />
      <BooleanField source="isDefault" />
      <TextField source="extra.description" />
      <EditButton basePath="/systemEnums" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>SystemEnum {record ? `"${record.label}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit undoable={false} title={<Title />} {...props}>
    <SimpleForm>
      <DisabledInput source="enumName" />
      <DisabledInput source="slug" />
      <TextInput source="label" />
      <NumberInput source="position" />
      <BooleanInput source="isDefault" />
      <LongTextInput source="extra.description" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <TextInput source="enumName" />
      <TextInput source="slug" />
      <TextInput source="label" />
      <BooleanInput source="position" />
      <TextInput source="isDefault" />
      <LongTextInput source="extra.description" />
    </SimpleForm>
  </AdminCreate>
);
