import { connect } from 'react-redux';
import { kebabCase, memoize } from 'lodash';
import { Map, Set, fromJS, List } from 'immutable';
import AddJobView from './add_job_view';
import { getCurrentUser, getGlobalSurveysOfType, getJobPosting } from '../../../reducer';
import { createJobPosting, updateJobPosting, publishJobPosting, cancelJobPosting, draftJobPosting, fetchJobPosting, resetForm } from '../../../solve/actions';

const DEFAULT_INITIAL_VALUES = fromJS({
  employerVisibilityType: 't',
  addressAttributes: { isPartialAddress: true },
  jobSharingType: 'wholeNetwork',
});

const POTENTIAL_OPPORTUNITY_TYPES = fromJS(['transport', 'childCare', 'healthCare', 'h1b', 'sponsorship']);

const EMPTY_SET = Set();
function buildInitialOpportunityTypes(existingSet = EMPTY_SET) {
  const basicLookup = Map(POTENTIAL_OPPORTUNITY_TYPES.map((oppo) => [oppo, existingSet.includes(oppo) ? 'yes' : 'no']));
  const felony = existingSet.includes('criminalBackground-felony');
  const misdemeanor = existingSet.includes('criminalBackground-misdemeanor');
  if (felony || misdemeanor) {
    let backgroundType;
    if (felony && misdemeanor) {
      backgroundType = 'both';
    } else if (felony) {
      backgroundType = 'felony';
    } else {
      backgroundType = 'misdemeanor';
    }
    return basicLookup.merge({
      criminalBackground: 'yes',
      backgroundType,
    });
  }
  return basicLookup.merge({ criminalBackground: 'No' });
}

const buildInitialValuesForExistingJob = memoize((existingJob, surveyTemplate) => {
  const addressAttributes = fromJS({ isPartialAddress: true }).merge(existingJob.get('address'));
  const employerId = existingJob.getIn(['employer', 'id']);
  const survey = existingJob.getIn(['surveys', 0]);
  const surveyQuestionAttributes = {};
  if (survey) {
    survey.get('surveyQuestions').forEach((item) => {
      const question = item.get('surveyQuestion');
      const firstPlaceholder = question.getIn(['extra', 'questionPlaceholders', '<0>']);
      let extra = question.get('extra');
      if (firstPlaceholder && List.isList(firstPlaceholder)) {
        extra = extra.setIn(['questionPlaceholders', '<0>'], firstPlaceholder.first());
      }
      const values = {
        isIncluded: 'Yes',
        id: question.get('id'),
        position: question.get('position'),
        questionImportanceType: question.get('questionImportanceType'),
        extra,
      };
      surveyQuestionAttributes[question.getIn(['questionTemplate', 'id'])] = values;
    });
    const questionTemplates = surveyTemplate.get('surveyQuestions').map((q) => q.getIn(['surveyQuestion', 'questionTemplate']));
    const additionalQuestionChoices = questionTemplates.filter((qt) => qt.getIn(['extra', 'additionalScreeningQuestion']));
    additionalQuestionChoices.forEach((item) => {
      const id = item.get('id');
      if (!surveyQuestionAttributes[id]) {
        surveyQuestionAttributes[id] = fromJS({ isIncluded: 'No' });
      }
    });
  }

  const certifications = existingJob.get('certifications').map((certificationName) => fromJS({ certification: { name: certificationName } }));
  const opportunityTypes = buildInitialOpportunityTypes(existingJob.get('opportunityTypes'));
  const builtJob = DEFAULT_INITIAL_VALUES.merge(existingJob).merge({
    addressAttributes,
    employerId,
    surveyQuestionAttributes: fromJS(surveyQuestionAttributes),
    certifications,
    opportunityTypes,
  });
  return builtJob;
});

function buildInitialValues(state, existingJob) {
  global.existingJob = existingJob;
  if (existingJob) {
    const surveyTemplate = getGlobalSurveysOfType(state, 'screening-template').first();
    return buildInitialValuesForExistingJob(existingJob, surveyTemplate);
  }
  return DEFAULT_INITIAL_VALUES;
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  id,
  currentUser: getCurrentUser(state),
  initialValues: buildInitialValues(state, id && getJobPosting(state, id)),
});

function buildSubmitValues(values) {
  const certifications =
    values.get('certifications') && values.get('certifications').map((certificationObj) => kebabCase(certificationObj.getIn(['certification', 'name'])));
  return values.merge({ certifications });
}

const mapDispatchToProps = {
  createJobPosting,
  updateJobPosting,
  publishJobPosting,
  cancelJobPosting,
  draftJobPosting,
  fetchJobPosting,
  resetForm,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  handleSaveDraft: (formValues) => {
    const values = buildSubmitValues(formValues);
    if (ownProps.match.params.id) {
      return dispatchProps
        .updateJobPosting(stateProps.currentUser, ownProps.match.params.id, values)
        .then((res) => dispatchProps.draftJobPosting(stateProps.currentUser, res.result));
    }
    return dispatchProps.createJobPosting(stateProps.currentUser, stateProps.currentUser.staffOrganization.id, values);
  },
  handleSubmit: (formValues) => {
    // what about an edit that is intended to just update the form? (looks like the next section)
    // if (ownProps.match.params.id && stateProps.jobPosting.state === 'active') {
    //   return dispatchProps.cancelJobPosting(stateProps.currentUser, ownProps.match.params.id);
    // }

    const values = buildSubmitValues(formValues);
    if (ownProps.match.params.id) {
      return dispatchProps
        .updateJobPosting(stateProps.currentUser, ownProps.match.params.id, values)
        .then((res) => dispatchProps.publishJobPosting(stateProps.currentUser, res.result));
    }
    return dispatchProps
      .createJobPosting(stateProps.currentUser, stateProps.currentUser.staffOrganization.id, values)
      .then((res) => dispatchProps.publishJobPosting(stateProps.currentUser, res.result));
  },
  handleCancel: () => dispatchProps.cancelJobPosting(stateProps.currentUser, ownProps.match.params.id),
  fetchJobPosting: dispatchProps.fetchJobPosting,
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const AddJobContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddJobView);

export default AddJobContainer;
