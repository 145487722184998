import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, fromJS, Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import CandidateSurveyModel from '../../../solve/models/candidate_survey_model';
import UserModel from '../../../session/models/user_model';
import StaffSurveyWizard from '../staff_survey_wizard/staff_survey_wizard';
import { useFetchOnce } from '../../../util/hooks';
import { Spinner } from '../../../common/components';
import { Card } from '../../../common/styles/layout';
import JobCandidateQuestionnaireStyles, { GridTile } from './job_candidate_questionnaire_styles';

const getSectionColor = (currentSurvey) => {
  switch (currentSurvey) {
    case 0:
      return '#67B4FF';
    case 1:
      return '#7DBF9D';
    case 2:
      return '#FFBF76';
    case 3:
      return '#B787F6';
    default:
      return '#000000';
  }
};
getSectionColor.propTypes = {
  currentSurvey: PropTypes.number.isRequired,
};

const buildAPIAnswers = (survey, values) => {
  const surveyAnswersAttributes = survey.get('surveyAnswers').map((data) => {
    const answerId = data.getIn(['surveyAnswer', 'id']).toString();
    const rawAnswer = values.getIn(['answer', answerId]);
    let answer = rawAnswer;
    if (typeof rawAnswer === 'string') {
      answer = [rawAnswer];
    } else if (Map.isMap(rawAnswer)) {
      answer = rawAnswer
        .filter((x) => x)
        .keySeq()
        .toList();
    }
    return {
      id: answerId,
      answer: answer || [],
      isSkipped: answer === undefined,
    };
  });
  return { candidateSurvey: { surveyAnswersAttributes } };
};
const JobCandidateQuestionnaireView = ({ currentUser, challenges, listCandidateSurveys, listChallenges, onSubmit, candidateId, surveys }) => {
  const resolved = useFetchOnce(currentUser, candidateId, listCandidateSurveys);
  useEffect(() => {
    listChallenges(currentUser, candidateId);
  }, [listChallenges, currentUser, candidateId]);

  if (!resolved) {
    return <Spinner />;
  }

  if (!surveys || !surveys.size) {
    return <div>This candidate has not answered any questionnaires</div>;
  }

  const questionnarieTitles = fromJS(['Financial Challenges', 'Workforce Readiness', 'Background Challenges', 'Emotional Challenges']);
  const orderSurveys = questionnarieTitles.map((title) => surveys.find((survey) => survey.getIn(['survey', 'title']) === title));

  return (
    <JobCandidateQuestionnaireStyles>
      <Grid container spacing={2}>
        {challenges && challenges.size >= 1 && (
          <Grid container spacing={2}>
            {challenges.map((challenge) => (
              <Grid xs={6} lg={3} key={`${challenge.get('challengeType')}-key`} item>
                <GridTile className="tile">
                  <img src={challenge.get('iconUrl') || '/images/challenges-housing.png'} alt={`${challenge.get('challengeType')}-icon`} />
                  <p>{challenge.get('challengeType')}</p>
                </GridTile>
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2}>
        {orderSurveys &&
          orderSurveys.map((survey, key) => {
            if (survey) {
              return (
                <Grid item xs={12} md={6} key={survey.getIn(['survey', 'id'])}>
                  <Card style={{ height: '100%' }}>
                    <img src={survey.getIn(['survey', 'iconUrl'])} alt={survey.getIn(['survey', 'title'])} />
                    <h3 style={{ color: getSectionColor(key) }}>{survey.getIn(['survey', 'title'])}</h3>
                    <StaffSurveyWizard
                      survey={survey}
                      canEdit={currentUser.permissions?.includes('edit_challenges')}
                      canView={currentUser.permissions?.includes('view_challenges')}
                      marked={false}
                      key={survey.getIn(['survey', 'id'])}
                      onSubmit={(values) => onSubmit(currentUser, survey.get('id'), buildAPIAnswers(survey, values))}
                    />
                  </Card>
                </Grid>
              );
            }
            return false;
          })}
      </Grid>
    </JobCandidateQuestionnaireStyles>
  );
};

JobCandidateQuestionnaireView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  challenges: PropTypes.instanceOf(OrderedSet).isRequired,
  candidateId: PropTypes.string.isRequired,
  listCandidateSurveys: PropTypes.func.isRequired,
  listChallenges: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  surveys: PropTypes.oneOfType([PropTypes.instanceOf(CandidateSurveyModel), PropTypes.instanceOf(OrderedSet)]),
};

JobCandidateQuestionnaireView.defaultProps = {
  surveys: undefined,
};

export default JobCandidateQuestionnaireView;
