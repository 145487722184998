import { connect } from 'react-redux';
import PathwayCandidateDetailsView from './pathway_candidate_details_view';
import { getCurrentUser, getCandidatePathway, getSystemEnumList } from '../../../reducer';
import { fetchCandidatePathway, transitionCandidatePathwayStaffState, updateCandidatePathway } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  user: getCurrentUser(state),
  candidatePathwayId: id,
  candidatePathway: getCandidatePathway(state, id),
  pathwayDroppedReasonTypes: getSystemEnumList(state, 'PathwayDroppedReasonType').map((item) => [item.get('slug'), item.get('label')]),
});

const mapDispatchToProps = {
  fetchCandidatePathway,
  transitionCandidatePathwayStaffState,
  updateCandidatePathway,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  transitionCandidateState: (candidatePathwayId, state) => {
    dispatchProps.transitionCandidatePathwayStaffState(stateProps.user, candidatePathwayId, state.substring(2));
    // .then(dispatchProps.fetchCandidatePathway(stateProps.user, stateProps.candidatePathwayId))
  },
  onUpdate: (candidatePathwayId, props) => dispatchProps.updateCandidatePathway(stateProps.user, candidatePathwayId, props),
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
});

const PathwayCandidateDetailsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayCandidateDetailsView);

export default PathwayCandidateDetailsContainer;
