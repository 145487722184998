import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useFetchOnce } from '../../../util/hooks';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import NotificationTile from '../tile';
import UserModel from '../../../session/models/user_model';
import Spinner from '../../../common/components/spinner';
import { CardHeader, CardSubHeader, CardFooter, Wrapper } from '../../../common/styles/card';

const NotificationsView = ({ currentUser, listNotifications, notifications, notificationLabels, isLimited, isCandidate }) => {
  const resolved = useFetchOnce(currentUser, undefined, listNotifications);

  const newNotifications = notifications.filter((n) => n.get('state') === 'new');
  const totalCount = notifications.size;
  const newCount = newNotifications.size;
  const unreadCount = totalCount - newCount;

  let shortNotifications;
  if (isLimited) {
    shortNotifications = newNotifications.slice(0, newNotifications.size < 3 ? newNotifications.size : 3);
  }

  return (
    <div>
      <CardHeader>
        <CardSubHeader>
          <h2>Latest Activities</h2>
          <p>
            <span>
              <strong>{newCount}</strong> new
            </span>
            <span className="dot">•</span>
            <span>
              <strong>{unreadCount}</strong> unread
            </span>
            <span className="dot">•</span>
            <span>
              <strong>{totalCount}</strong> total
            </span>
          </p>
        </CardSubHeader>
        {/* <FormControl variant="outlined" margin="dense" className="select-dropdown">
          <Select value={1} onChange={() => {}}>
            <MenuItem value={1}>Last 7 Days</MenuItem>
            <MenuItem value={2}>Last 2 Weeks</MenuItem>
            <MenuItem value={3}>Last Month</MenuItem>
          </Select>
        </FormControl> */}
      </CardHeader>

      {!resolved ? (
        <Wrapper>
          <Spinner />
        </Wrapper>
      ) : (
        <React.Fragment>
          {isLimited ?
            shortNotifications.map((notification) => (
              <NotificationTile notificationLabels={notificationLabels} key={notification.get('id')} notification={notification} />
            )) :
            notifications.map((notification) => (
              <NotificationTile notificationLabels={notificationLabels} key={notification.get('id')} notification={notification} />
            ))}

          {isLimited && (
            <CardFooter>
              <Link to={`/${isCandidate ? 'candidate' : 'staff'}/notifications`}>
                See all {totalCount} notifications <ChevronRightIcon />
              </Link>
            </CardFooter>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

NotificationsView.propTypes = {
  listNotifications: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  notifications: PropTypes.instanceOf(OrderedSet),
  notificationLabels: PropTypes.objectOf(PropTypes.string).isRequired,
  isLimited: PropTypes.bool,
  isCandidate: PropTypes.bool.isRequired,
};
NotificationsView.defaultProps = {
  notifications: undefined,
  isLimited: false,
};

export default NotificationsView;
