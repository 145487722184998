import styled from 'styled-components';
import theme from '../../../common/theme';

const SignInStyles = styled.div`
  width: calc(100% - ${theme.padding.base} * 2);
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${theme.padding.base};

  .status {
    margin: 0 1em;
  }
  .error {
    color: ${(props) => props.theme.color.error};

    input[type='text'],
    input[type='password'] {
      border: thin solid ${(props) => props.theme.color.error};
    }
  }

  .oauth-row {
    margin: auto;
    width: fit-content;
    padding: 1em;
  }

  .oauth-button {
    margin: 5px;
    color: ${(props) => props.theme.color.action};
    &:hover {
      color: ${(props) => props.theme.color.actionHover};
    }
  }

  .google-translate-container {
    margin-bottom: 10px;
  }
`;

export default SignInStyles;
