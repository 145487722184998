/* eslint class-methods-use-this:
  ["error", { "exceptMethods": ["getActivity", "getDescription", "getPerformedByCandidate", "getPerformedByStaff", "getPerformedByOrgName"] }] */

import moment from 'moment';
import NotificationTemplates from '../notification_templates';

class BaseNotificationHelper {
  constructor(params) {
    ({ currentUser: this.currentUser, notification: this.notification, notificationLabels: this.notificationLabels } = params);
    this.notificationType = this.notification.get('notificationType');
    this.template = NotificationTemplates.get(this.notificationType);
  }

  getHeader() {
    return this.notificationLabels[this.notificationType];
  }

  getCreatedAt(format = 'MMM Do h:mma', includeDelta = true) {
    return moment(this.notification.get('createdAt')).format(format) + (includeDelta ? ` (${moment(this.notification.get('createdAt')).fromNow()})` : '');
  }

  getActivity() {
    let activity = null;

    if (this.template) {
      activity = this.template.activity;
    }
    return activity || '';
  }

  getDescription() {}

  getPerformedByOrgName() {}

  getPerformedByStaff() {}

  getPerformedByCandidate() {}

  getUrlNode() {
    return this.currentUser.get('staffId') ? 'staff' : 'candidate';
  }
}

export default BaseNotificationHelper;
