import styled from 'styled-components';
// import theme from '../../../common/theme';

const QuestionnaireStyles = styled.div`
  .MuiFormControlLabel-root {
    margin-bottom: 10px;
    align-items: flex-start;
  }
  .MuiTypography-root {
    margin-top: 7px;
  }
`;

export const Header = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  padding: 20px;
  padding-top: 40px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 5px;
`;

export const Question = styled.div`
  margin: 10px 0;
  margin-bottom: 40px;
  img {
    width: 36px;
  }
`;

export default QuestionnaireStyles;
