import React from 'react';
import PropTypes from 'prop-types';
import { List, OrderedSet } from 'immutable';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormGroup } from '@material-ui/core';
import {
  reduxForm,
  Field,
  MuiTextField,
  MuiSelect,
  MuiRadioGroup,
  MuiCheckbox,
  MuiSelectMultiple,
  MuiDatePicker,
  MuiTimePicker,
  MuiFileUpload,
  EditorField,
  zipCodeMask,
  phoneMask,
} from '../../../../common/form';
import { Button, OrgSearchableSelector } from '../../../../common/components';
import { Card } from '../../../../common/styles/layout';
import programOverviewFormValidate from './program_overview_form_validate';
import ProgramOverviewFormStyles from './program_overview_form_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  classBased: PropTypes.string,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  industryTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  // industrySpecifics: PropTypes.instanceOf(List).isRequired,
  certificationCredentialTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  skillTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  employerTypes: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {
  classBased: 'false',
};

/* eslint-disable react/jsx-props-no-spreading */
const ProgramOverviewForm = ({
  handleSubmit,
  classBased,
  serviceTypes,
  industryTypes,
  // industrySpecifics,
  certificationCredentialTypes,
  skillTypes,
}) => (
  // const required = (value) => (value || typeof value === 'number' ? undefined : 'Required');

  <ProgramOverviewFormStyles>
    <form onSubmit={handleSubmit}>
      <h2>Program Overview</h2>
      <Card>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={3} className="overview-form">
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item md={7} sm={12}>
                <h3>Program Contact Information</h3>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Field component={MuiFileUpload} label="Add A Logo" name="programLogo" margin="dense" optional />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={MuiTextField} variant="outlined" label="Website" name="website" margin="dense" fullWidth required />
                  </Grid>
                  <Grid item lg={8} md={7} xs={12}>
                    <Field component={MuiTextField} variant="outlined" label="Program Name" name="name" margin="dense" fullWidth required />
                  </Grid>
                  <Grid item lg={4} md={5} xs={12}>
                    <Field
                      component={MuiTextField}
                      type="tel"
                      variant="outlined"
                      label="Phone Number"
                      name="phone"
                      margin="dense"
                      fullWidth
                      required
                      {...phoneMask}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={MuiTextField}
                      variant="outlined"
                      label="Street Address 1"
                      name="addressAttributes.address1"
                      margin="dense"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={MuiTextField} variant="outlined" label="Street Address 2" name="addressAttributes.address2" margin="dense" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Field component={MuiTextField} variant="outlined" label="City" name="addressAttributes.city" margin="dense" fullWidth required />
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={5}>
                        <Field
                          component={MuiTextField}
                          variant="outlined"
                          label="State (2-Letter Code)"
                          name="addressAttributes.state"
                          margin="dense"
                          fullWidth
                          required
                        />
                      </Grid>
                      <Grid item xs={7}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <Field
                          component={MuiTextField}
                          variant="outlined"
                          label="Zip Code"
                          name="addressAttributes.zip"
                          margin="dense"
                          fullWidth
                          required
                          {...zipCodeMask}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div style={{ height: 20 }} />
                  <h3 style={{ marginBottom: 0 }}>Expiration Date of the program posting</h3>
                  When is the last date you would like this program to be posted on RiseKit?
                  <Field
                    component={MuiDatePicker}
                    variant="outlined"
                    label="Post Expiration Date (MM-DD-YYYY)"
                    name="postExpirationDate"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item md={5} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} spacing={3}>
                    <MuiSelect variant="outlined" options={serviceTypes} label="Service Type" name="primaryServiceType" margin="dense" fullWidth />
                  </Grid>
                  <Grid item xs={12} spacing={3}>
                    <MuiSelect variant="outlined" options={industryTypes} label="Select Industry" name="industryType" margin="dense" fullWidth />
                  </Grid>

                  {/* <Grid item xs={12}>
                      <MuiSelectMultiple variant="outlined" options={industrySpecifics} label="Select Industry Specifics"
                      name="industrySpecifics" margin="dense" />
                    </Grid> */}

                  <Grid item xs={12}>
                    <h4>Is this a class-based program?*</h4>
                    <Field name="isClass" component={MuiRadioGroup} required>
                      <FormGroup row>
                        <FormControlLabel control={<Radio color="primary" />} value="true" label="Yes" />
                        <FormControlLabel control={<Radio color="primary" />} value="false" label="No" />
                      </FormGroup>
                    </Field>
                  </Grid>
                  <Grid item xs={12} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <div style={classBased === 'true' ? { visibility: 'visible', height: 'auto' } : { visibility: 'hidden', height: '0px' }}>
                      <Grid item xs={12}>
                        <h3>Class Information</h3>
                      </Grid>
                      <Grid item xs={12} container spacing={3}>
                        <Grid item xs={6}>
                          <Field component={MuiDatePicker} variant="outlined" label="Program Date" name="startDate" margin="dense" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                          <Field component={MuiDatePicker} variant="outlined" label="Graduation Date" name="endDate" margin="dense" fullWidth />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <p>Class days</p>
                        <FormGroup row>
                          <FormControlLabel control={<Field component={MuiCheckbox} name="days.monday" />} label="M" />
                          <FormControlLabel control={<Field component={MuiCheckbox} name="days.tuesday" />} label="TU" />
                          <FormControlLabel control={<Field component={MuiCheckbox} name="days.wednesday" />} label="W" />
                          <FormControlLabel control={<Field component={MuiCheckbox} name="days.thursday" />} label="TH" />
                          <FormControlLabel control={<Field component={MuiCheckbox} name="days.friday" />} label="F" />
                          <FormControlLabel control={<Field component={MuiCheckbox} name="days.saturday" />} label="SAT" />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} container spacing={3}>
                        <Grid item xs={6}>
                          <Field component={MuiTimePicker} variant="outlined" label="Start Time" name="startTime" margin="dense" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                          <Field component={MuiTimePicker} variant="outlined" label="End Time" name="endTime" margin="dense" fullWidth />
                        </Grid>
                      </Grid>

                      <Grid item xs={12} container spacing={3} style={{ marginTop: '5px' }}>
                        <Grid item xs={6}>
                          <Field
                            component={MuiSelectMultiple}
                            options={certificationCredentialTypes}
                            label="Certification / Credential Gained"
                            name="gainedCertifications"
                            margin="dense"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Field component={MuiSelectMultiple} options={skillTypes} label="Skills Gained" name="gainedSkillTypes" margin="dense" />
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Program Description</h3>
            Please provide a brief description about your program.
            <EditorField name="description" id="description" style={{ padding: '50px' }} label="Description" margin="dense" fullWidth required />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Add a nonprofit / employer besides your own</h3>
            {/* <FieldArray component={MuiTextFieldArray} label="Add a Nonprofit/Employer" name="nonprofit/Employer" margin="dense" multiline required /> */}
            <Field component={OrgSearchableSelector} name="organizationIds" label="Add a Nonprofit/Employer" />
          </Grid>
        </Grid>
      </Card>
      <div className="button-group">
        <div className="button-left">
          <Link to="/staff/programs">
            <Button buttonType="secondary-outline">Cancel</Button>
          </Link>
        </div>
        <div className="button-right">
          <Button buttonType="secondary" type="submit">
            Next Step
          </Button>
        </div>
      </div>
    </form>
  </ProgramOverviewFormStyles>
);
/* eslint-enable react/jsx-props-no-spreading */

ProgramOverviewForm.propTypes = propTypes;
ProgramOverviewForm.defaultProps = defaultProps;

const ProgramOverviewFormView = reduxForm({
  form: 'programForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: programOverviewFormValidate,
})(ProgramOverviewForm);

export default ProgramOverviewFormView;
