import numeral from 'numeral';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

import ProgramFlyer from '../../program_flyer';
import { reduxForm } from '../../../../common/form';
import { Card } from '../../../../common/styles/layout';
import finalizeFormValidate from './finalize_form_validate';
import { Button, TextHeading } from '../../../../common/components';
import { formatDate, formatTime } from '../../../../util/format_datetimes';
import FinalizeFormStyles, { ProgramReviewStyles } from './finalize_form_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  previousPage: PropTypes.func.isRequired,
  program: PropTypes.instanceOf(Map),
  submitting: PropTypes.bool.isRequired,
  address: PropTypes.string,
  classDays: PropTypes.instanceOf(Map),
  date: PropTypes.instanceOf(Date),
  description: PropTypes.string.isRequired,
  endTime: PropTypes.date,
  industryType: PropTypes.string,
  isClass: PropTypes.string,
  // TODO: Logo throws an error when trying to load
  // logo: PropTypes.instanceOf(FileList).isRequired,
  name: PropTypes.string.isRequired,
  paymentCost: PropTypes.string,
  phone: PropTypes.string.isRequired,
  primaryServices: PropTypes.instanceOf(Map),
  serviceType: PropTypes.string,
  skillsGained: PropTypes.instanceOf(List),
  skillsCertifications: PropTypes.string,
  supportiveServices: PropTypes.instanceOf(Map),
  time: PropTypes.date,
  website: PropTypes.string.isRequired,
  primaryService: PropTypes.string,
  startTime: PropTypes.date,
  targetZipCodes: PropTypes.instanceOf(Map),
  targetMaximumAge: PropTypes.string,
  targetMinimumAge: PropTypes.string,
  targetParticipantTypes: PropTypes.instanceOf(Map),
  isResumeRequired: PropTypes.string,
  dressCode: PropTypes.string,
  payment: PropTypes.string,
  estimatedSalary: PropTypes.string,
  gainedJobTypes: PropTypes.instanceOf(Map),
  targetMinimumEducationLevelType: PropTypes.string,
  postExpirationDate: PropTypes.string,
};

const defaultProps = {
  program: new Map(),
  address: '',
  classDays: '',
  date: '',
  endTime: '',
  industryType: '',
  isClass: '',
  paymentCost: '',
  primaryServices: '',
  serviceType: '',
  skillsGained: '',
  skillsCertifications: '',
  supportiveServices: '',
  time: '',
  primaryService: '',
  startTime: '',
  targetZipCodes: new Map(),
  targetMaximumAge: '',
  targetMinimumAge: '',
  targetParticipantTypes: new Map(),
  isResumeRequired: '',
  dressCode: '',
  payment: '',
  estimatedSalary: '',
  gainedJobTypes: new Map(),
  targetMinimumEducationLevelType: '',
  postExpirationDate: '',
};

const FinalizeForm = ({
  handleSubmit,
  previousPage,
  onSaveDraft,
  pristine,
  submitting,
  program,
  address,
  classDays,
  date,
  description,
  endTime,
  industryType,
  isClass,
  name,
  paymentCost,
  phone,
  primaryServices,
  serviceType,
  skillsGained,
  skillsCertifications,
  supportiveServices,
  time,
  website,
  primaryService,
  startTime,
  targetZipCodes,
  targetMaximumAge,
  targetMinimumAge,
  targetParticipantTypes,
  isResumeRequired,
  dressCode,
  payment,
  estimatedSalary,
  gainedJobTypes,
  targetMinimumEducationLevelType,
  postExpirationDate,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedTargetParticipantTypes = targetParticipantTypes.filter(Boolean);

  return (
    <FinalizeFormStyles>
      <form onSubmit={handleSubmit}>
        <h2>Review</h2>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2} className="finalize-form">
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <ProgramFlyer
                    address={address}
                    classDays={classDays}
                    date={date}
                    description={description}
                    endTime={endTime}
                    industryType={industryType}
                    isClass={isClass}
                    name={name}
                    paymentCost={paymentCost}
                    phone={phone}
                    primaryServices={primaryServices}
                    serviceType={serviceType}
                    skillsGained={skillsGained}
                    skillsCertifications={skillsCertifications}
                    supportiveServices={supportiveServices}
                    time={time}
                    website={website}
                  />
                </Card>
              </Grid>
              {/* <Grid item xs={3}>
                <Card style={{ textAlign: 'center', padding: 20 }}>
                  <img src="/images/resume.png" alt="Resume" width="50" />
                  <h4 style={{ marginBottom: 20 }}>Flyer/Program Posting</h4>
                  <MuiButton fullWidth variant="contained" className="action-contained" startIcon={<CloudDownloadIcon />}>
                    Download PDF
                  </MuiButton>
                </Card>
              </Grid> */}
            </Grid>
            <Grid item xs={12}>
              <TextHeading size="medium" weight="bold" margin="both" uppercase>
                Additional program information (candidate will not see)
              </TextHeading>
              <ProgramReviewStyles>
                <Card>
                  <Grid item xs={12}>
                    <Grid item container>
                      {gainedJobTypes && gainedJobTypes.size > 0 && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Gained Job Types:</span>
                          </p>
                          <ul>
                            {gainedJobTypes.entrySeq().map((skill) => (
                              <li key={skill[0]}>{startCase(skill[1])}</li>
                            ))}
                          </ul>
                        </Grid>
                      )}
                      {postExpirationDate && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Post Expiration Date:</span> {postExpirationDate ? formatDate(postExpirationDate) : ''}
                          </p>
                        </Grid>
                      )}
                      {estimatedSalary && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Estimated Salary:</span> {numeral(estimatedSalary).format('$0')}
                          </p>
                        </Grid>
                      )}
                      {dressCode && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Dress Code:</span> {dressCode}
                          </p>
                        </Grid>
                      )}
                      {payment && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Payment:</span> {startCase(payment.toString())}
                          </p>
                        </Grid>
                      )}
                      {isResumeRequired && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Resume Required:</span> {startCase(isResumeRequired.toString())}
                          </p>
                        </Grid>
                      )}
                      {primaryService && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Primary Service:</span> {startCase(primaryService)}
                          </p>
                        </Grid>
                      )}
                      {startTime && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Start Time:</span> {startTime ? formatTime(startTime) : ''}
                          </p>
                        </Grid>
                      )}
                      {selectedTargetParticipantTypes && selectedTargetParticipantTypes.size > 0 && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Participants:</span>
                          </p>
                          <ul>
                            {selectedTargetParticipantTypes.entrySeq().map((type) => (
                              <li key={type[0]}>{startCase(type[0])}</li>
                            ))}
                          </ul>
                        </Grid>
                      )}
                      {targetZipCodes && targetZipCodes.size > 0 && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Zip Codes:</span>
                          </p>
                          <ul>
                            {targetZipCodes.map((type) => (
                              <li key={type}>{type}</li>
                            ))}
                          </ul>
                        </Grid>
                      )}
                      {targetMinimumAge && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Minimum Age:</span> {targetMinimumAge}
                          </p>
                        </Grid>
                      )}
                      {targetMaximumAge && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Maximum Age:</span> {targetMaximumAge}
                          </p>
                        </Grid>
                      )}
                      {targetMinimumEducationLevelType && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Minimum Education Level:</span> {startCase(targetMinimumEducationLevelType)}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </ProgramReviewStyles>
            </Grid>
          </Grid>
          <div className="button-group">
            <div className="button-left">
              <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
                Previous
              </Button>
            </div>
            {program.get('state') !== 'active' ? (
              <div className="button-right">
                <Button buttonType="secondary-outline" type="button" disabled={submitting} onClick={handleSubmit((values) => onSaveDraft(values))}>
                  Save Draft
                </Button>
                <Button buttonType="secondary" disabled={pristine || submitting} type="submit">
                  List Program Posting
                </Button>
              </div>
            ) : (
              <div className="button-right">
                <Link to="/staff/resources/">
                  <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting}>
                    Discard Changes
                  </Button>
                </Link>
                <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={handleSubmit((values) => onSaveDraft(values))}>
                  Save Program
                </Button>
                <Button buttonType="secondary" disabled={pristine || submitting} type="submit">
                  Publish Program
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </form>
    </FinalizeFormStyles>
  );
};

FinalizeForm.propTypes = propTypes;
FinalizeForm.defaultProps = defaultProps;

const FinalizeFormView = reduxForm({
  form: 'programForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: finalizeFormValidate,
})(FinalizeForm);

export default FinalizeFormView;
