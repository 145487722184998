import styled from 'styled-components';
// import theme from '../../../common/theme';

const DefyFormStyles = styled.div`
  color: #777;
  font-size: 13px;
  margin-top: 18px;
  .defy-form {
    padding: 0px 15px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.1px;
    margin: 10px 0px;
  }
  .save-button {
    margin-top: 20px;
    float: right;
  }
  label {
    color: #6c7a89;
    font-size: 12px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.01em;
    padding-top: 5px;
    text-transform: uppercase;
  }
  form {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
  }
  .contact:nth-child(1) {
    padding-right: 10px;
  }
  .contact:nth-child(2) {
    padding-left: 10px;
  }
  @media only screen and (max-width: 960px) {
    .contact {
      padding: 0px;
    }
  }
`;

export default DefyFormStyles;
