import { connect } from 'react-redux';
import PathwayDetailsView from './pathway_details_view';
import { getCurrentUser, getCandidatePathway } from '../../../reducer';
import { fetchCandidatePathway, transitionCandidatePathwayCandidateState } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  user: getCurrentUser(state),
  candidatePathwayId: id,
  candidatePathway: getCandidatePathway(state, id),
});

const mapDispatchToProps = {
  fetchCandidatePathway,
  transitionCandidatePathwayCandidateState,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  transitionCandidateState: (candidatePathwayId, state) => {
    dispatchProps.transitionCandidatePathwayCandidateState(stateProps.user, candidatePathwayId, state.substring(2));
    // .then(dispatchProps.fetchCandidatePathway(stateProps.user, stateProps.candidatePathwayId))
  },
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
});

const PathwayDetailsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayDetailsView);

export default PathwayDetailsContainer;
