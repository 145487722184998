import styled from 'styled-components';
import theme from '../../../common/theme';

const OrganizationInviteStyles = styled.div`
  h3 {
    color: ${(props) => props.theme.color.secondary};
    letter-spacing: 0;
    margin-bottom: 0;
  }
  h4 {
    margin: 0;
    margin-top: 20px;
  }
  cite {
    font-style: normal;
    text-transform: uppercase;
    color: ${theme.color.text.medium};
    font-size: 12px;
  }
`;

export default OrganizationInviteStyles;
