import BaseNotificationHelper from './base_notification_helper';

class QuestionnaireNotificationHelper extends BaseNotificationHelper {
  constructor(params) {
    super(params);

    this.info = this.notification.get('info');
  }

  getActivity() {
    const activity = this.template?.activity;
    if (activity) {
      return activity.replace('<surveyTitle>', this.getSurveyTitle());
    }
    return '';
  }

  getDescription() {
    let description = this.template?.description;
    if (description) {
      const surveyTitle = this.getSurveyTitle();
      const staffName = this.getUpdatedByStaff();
      const staffOrgName = this.getUpdatedByOrgName();
      description = description.replace('<surveyTitle>', surveyTitle);
      description = description.replace('<staffName>', staffName);
      description = description.replace('<staffOrgName>', staffOrgName);
      description = description.concat('~<here>');
      description = description.concat(`~/${this.getUrlNode()}/profile/questionnaire`);
      return description;
    }
    return '';
  }

  getSurveyTitle() {
    return this.info?.get('surveyTitle');
  }

  getUpdatedByStaff() {
    const staff = this.info?.getIn(['updatedBy', 'staff']);
    if (staff) {
      return `${staff.get('firstName')} ${staff.get('lastName')}`;
    }
    return '';
  }

  getUpdatedByOrgName() {
    return this.info?.getIn(['updatedBy', 'staff', 'organization', 'name']);
  }
}

export default QuestionnaireNotificationHelper;
