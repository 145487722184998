import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const DEFAULT_STATE = fromJS({
  recommendedList: EMPTY_SET,
});

const CandidateRecommendedListingsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.LIST_RECOMMENDED_POSTINGS_FOR_CANDIDATE.success:
      return state.merge({
        recommendedList: OrderedSet(action.response.result),
      });
    case ACTIONS.LIST_FILTERED_JOB_POSTINGS_FOR_CANDIDATE.failure:
      return state.merge({
        recommendedList: EMPTY_SET,
      });
    default:
      return state || DEFAULT_STATE;
  }
};

export const getJobIds = (state) => state.get('recommendedList') || DEFAULT_STATE.get('jobsList');

export default CandidateRecommendedListingsReducer;
