import { Map } from 'immutable';
import ACTIONS from '../actions';
import { combineImmutableReducers } from '../../util';
import SolveActions from '../../solve/actions';

const DEFAULT_EXPERIMENT_MAP = new Map();

function byKey(state = DEFAULT_EXPERIMENT_MAP, action) {
  let staff;
  let candidate;
  switch (action.type) {
    case ACTIONS.SIGN_IN.success:
    case ACTIONS.FINISH_SIGN_IN.success:
    case ACTIONS.REFRESH_AUTH.success:
    case ACTIONS.RECOGNIZE_USER.success:
    case ACTIONS.REGISTER.success:
      if (action.response.data.treatments) {
        return new Map(action.response.data.treatments);
      }
      return state;
    case SolveActions.CREATE_CANDIDATE.success:
      candidate = action.response.entities.candidates[action.response.result];
      return state.merge(candidate?.user?.treatments || {});
    case SolveActions.CREATE_STAFF_MEMBER.success:
      staff = action.response.entities.staff[action.response.result];
      return state.merge(staff?.user?.treatments || {});
    case ACTIONS.SET_EXPERIMENT: {
      const { key, treatment } = action;
      return state.set(key, treatment);
    }
    default:
      return state;
  }
}

const experimentReducer = combineImmutableReducers({
  byKey,
});

export default experimentReducer;

// Selectors

export const getTreatment = (state, key, defaultTreatment = undefined) => {
  const treatment = state.getIn(['byKey', key]);
  if (treatment === undefined) {
    return defaultTreatment;
  }
  return treatment;
};
