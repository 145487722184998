import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker } from 'material-ui-pickers';
import 'react-datepicker/dist/react-datepicker.css';

// Material UI Date Time Picker Helpers
// https://material-ui-pickers.firebaseapp.com/
// * These form inputs remove native datepicker functionality
// * Formating of Date is Rails compatible
// * Formating occurs during save into redux store

const propTypes = {
  showErrorsInline: PropTypes.bool,
  input: PropTypes.shape({ onChange: PropTypes.func, value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]) }),
  meta: PropTypes.shape({ touched: PropTypes.bool, error: PropTypes.bool }),
  id: PropTypes.string.isRequired,
};

const defaultProps = {
  showErrorsInline: false,
  input: { onChange: () => {}, value: undefined },
  meta: { touched: false, error: false },
};

export const dateTimeMask = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M'];

function parseTime(time12h) {
  const [time, modifier] = time12h.trim().split(' ');
  // eslint-disable-next-line prefer-const
  let [hours, minutes] = time.split(':');

  if (modifier && hours === '12') {
    hours = '00';
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  const dateTime = new Date();
  dateTime.setHours(parseInt(hours, 10));
  dateTime.setMinutes(parseInt(minutes, 10));

  return dateTime;
}

const getValue = (inputVal) => {
  if (!inputVal) {
    return null;
  }
  if (typeof inputVal === 'string') {
    return parseTime(inputVal);
  }
  return inputVal;
};

const MuiTimePicker = ({ showErrorsInline, input: { onChange, value }, meta: { touched, error }, id, ...others }) => {
  const showError = showErrorsInline || touched;

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <TimePicker
      id={id}
      keyboard
      clearable
      mask={(val) => (val ? dateTimeMask : [])}
      error={!!(showError && error)}
      helperText={showError && error}
      value={getValue(value)}
      onChange={onChange}
      fullWidth
      InputLabelProps={{
        shrink: false,
      }}
      {...others}
    />
  );
};

export default MuiTimePicker;

MuiTimePicker.propTypes = propTypes;
MuiTimePicker.defaultProps = defaultProps;
