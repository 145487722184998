import { connect } from 'react-redux';
import NotesTileView from './notes_tile_view';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const NotesTileContainer = connect(mapStateToProps, mapDispatchToProps)(NotesTileView);

export default NotesTileContainer;
