import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import { reduxForm, Field, MuiRadioGroup } from '../../../../common/form';
import Button from '../../../../common/components/button/index';
import privacyAgreementFormValidate from './privacy_agreement_form_validate';
import PrivacyAgreementFormStyles from './privacy_agreement_form_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  invalid: PropTypes.bool,
  isRisekit: PropTypes.bool.isRequired,
};

const defaultProps = {
  invalid: false,
};

const PrivacyAgreementForm = ({ handleSubmit, previousPage, submitting, invalid, isRisekit }) => (
  <PrivacyAgreementFormStyles>
    <form onSubmit={handleSubmit}>
      <Grid container justify="center" alignItems="flex-start" spacing={2}>
        <h2>Before we start..</h2>
        <Grid item xs={11}>
          <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item xs={11}>
              <h3>Your information is private</h3>
              <header>
                {`I give ${
                  isRisekit ? 'RiseKit' : 'Solve'
                } permission to share the answers to these questions with community-based nonprofits that are interested in being a resource to me. I
                understand that sharing this information helps these nonprofits provide me with better assistance. I also understand that I can uncheck this box
                to opt-out of sharing this information. Lastly, as you grow and develop new skills and overcome challenges, ${
  isRisekit ? 'RiseKit' : 'Solve'
  } will provide status updates of
                the organizations that you work with. `}
              </header>
              <Field name="challenges" component={MuiRadioGroup}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox name="privacyAgreement" variant="outlined" defaultChecked style={{ color: 'black' }} />}
                    label="I agree"
                    className="answer"
                  />
                  <div className="note">
                    If you uncheck this box you do not consent to share this information with nonprofit resources to help you overcome any challenges you may be
                    facing
                  </div>
                </FormGroup>
              </Field>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Button buttonType="secondary-outline" type="button" onClick={previousPage}>
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button buttonType="secondary" disabled={invalid || submitting} type="submit">
                Next Step
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  </PrivacyAgreementFormStyles>
);

PrivacyAgreementForm.propTypes = propTypes;
PrivacyAgreementForm.defaultProps = defaultProps;

const PrivacyAgreementFormView = reduxForm({
  form: 'applyToServiceForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: privacyAgreementFormValidate,
})(PrivacyAgreementForm);

export default PrivacyAgreementFormView;
