import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import DefyFormForm from './defy_form_form';
import { getCurrentUser, getInmate, getCandidate } from '../../../reducer';
import { fetchInmate, createInmate, updateInmate, updateCandidate, fetchCandidate } from '../../../solve/actions';

function buildInitialValues(candidate) {
  const additionalInfo1 = candidate.get('additionalContactInfo').get(0);
  const contact1 = {
    name: additionalInfo1 ? additionalInfo1.getIn(['contact', 'name']) : '',
    relation: additionalInfo1 ? additionalInfo1.getIn(['contact', 'relation']) : '',
    phone: additionalInfo1 ? additionalInfo1.getIn(['contact', 'phone']) : '',
    email: additionalInfo1 ? additionalInfo1.getIn(['contact', 'email']) : '',
  };
  const additionalInfo2 = candidate.get('additionalContactInfo').get(1);
  const contact2 = {
    name: additionalInfo2 ? additionalInfo2.getIn(['contact', 'name']) : '',
    relation: additionalInfo2 ? additionalInfo2.getIn(['contact', 'relation']) : '',
    phone: additionalInfo2 ? additionalInfo2.getIn(['contact', 'phone']) : '',
    email: additionalInfo2 ? additionalInfo2.getIn(['contact', 'email']) : '',
  };
  const isHomeless = additionalInfo1 ? candidate.get('isHomeless').toString() : '';
  const hasFosterCare = additionalInfo1 ? candidate.get('hasFosterCare').toString() : '';
  const values = { contact1, contact2, isHomeless, hasFosterCare };
  return fromJS(values);
}

const mapStateToProps = (state, { candidateId }) => ({
  currentUser: getCurrentUser(state),
  inmate: getInmate(state, candidateId),
  candidate: getCandidate(state, candidateId),
  initialValues: getInmate(state, candidateId) ?
    getInmate(state, candidateId).merge(buildInitialValues(getCandidate(state, candidateId))) :
    buildInitialValues(getCandidate(state, candidateId)),
});

const mapDispatchToProps = {
  fetchInmate,
  createInmate,
  updateInmate,
  updateCandidate,
  fetchCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps,
  onSubmit: (values) => {
    dispatchProps.updateCandidate(
      stateProps.currentUser,
      ownProps.candidateId,
      fromJS({
        additionalContactInfo: [{ contact: values.get('contact1') }, { contact: values.get('contact2') }],
        isHomeless: values.get('isHomeless') === 'true',
        hasFosterCare: values.get('hasFosterCare') === 'true',
      }),
    );
    if (stateProps.inmate) {
      dispatchProps.updateInmate(stateProps.currentUser, ownProps.candidateId, values);
    } else {
      dispatchProps.createInmate(stateProps.currentUser, ownProps.candidateId, values);
    }
  },
});

const DefyFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(DefyFormForm);

export default DefyFormContainer;
