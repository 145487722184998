import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Button from '@material-ui/core/Button';

import AddIcon from '@material-ui/icons/Add';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import { useFetchOnce as pork } from '../../../util/hooks';

import Tabber from '../../../ui/components/tabber';
import Spinner from '../../../common/components/spinner';

import UserModel from '../../../session/models/user_model';

import PathwaySteps from './pathway_steps';
import PathwayCandidates from './pathway_candidates';

// NOTE: This is only to avoid a JS Warning in the browser console
const StyledDiv = styled.div``;

const PathwayDetailsView = ({ user, pathwayInstance, pathwayInstanceId, fetchPathwayInstance, onChange }) => {
  const resolved = pork(user, pathwayInstanceId, fetchPathwayInstance);

  if (!pathwayInstance) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  return (
    <div>
      <Link to="/staff/pathways">
        <Button color="primary" size="small" startIcon={<KeyboardBackspaceIcon />}>
          Back to Pathways
        </Button>
      </Link>
      <Link to={`/staff/pathways/${pathwayInstance.get('id')}/invite`}>
        <Button variant="outlined" color="secondary" size="large" startIcon={<AddIcon />} style={{ float: 'right' }}>
          Invite Candidates
        </Button>
      </Link>
      <h1 style={{margin: "0px"}}>{pathwayInstance.get('name')}</h1>
      <Tabber>
        <StyledDiv tabLabel="Pathway Steps">
          <PathwaySteps onChange={onChange} pathwayInstance={pathwayInstance} steps={pathwayInstance.getIn(['pathway', 'pathwayMilestones'])} />
        </StyledDiv>
        <StyledDiv tabLabel="Candidates">
          <PathwayCandidates pathwayInstance={pathwayInstance} />
        </StyledDiv>
      </Tabber>
    </div>
  );
};

PathwayDetailsView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  pathwayInstance: PropTypes.instanceOf(Map),
  pathwayInstanceId: PropTypes.string.isRequired,
  fetchPathwayInstance: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
PathwayDetailsView.defaultProps = {
  pathwayInstance: undefined,
};

export default PathwayDetailsView;
