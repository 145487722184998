import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../common/components/button/index';
import { reduxForm, Field, MuiDatePicker, FormSection } from '../../../../../common/form';

// import { useFetchOnce } from '../../../../../util/hooks';

// import UserModel from '../../../../../session/models/user_model';
import SetScheduleFormStyles from './set_schedule_form_styles';

const MilestoneStep = ({ step, targetCompletionDate }) => (
  <tr>
    <td>{step.get('name')}</td>
    <td>{step.get('description')}</td>
    <td>{step.get('pathwayStepType')}</td>
    <td>{step.get('pathwayStepCategoryType') === 'candidate' ? 'Yes' : 'No'}</td>
    <td className="date-picker-cell">
      {step.get('pathwayStepCategoryType') === 'pathway' ? (
        <Typography variant="caption">{targetCompletionDate}</Typography>
      ) : (
        <Field component={MuiDatePicker} variant="outlined" name={`${step.get('id')}`} margin="dense" label="Start Date" fullWidth />
      )}
    </td>
  </tr>
);

MilestoneStep.propTypes = {
  step: PropTypes.instanceOf(Map).isRequired,
  targetCompletionDate: PropTypes.string,
};
MilestoneStep.defaultProps = {
  targetCompletionDate: '',
};

const SetScheduleForm = ({ handleSubmit, previousPage, pathwayInstance, pristine }) => {
  const pathway = pathwayInstance && pathwayInstance.get('pathway');
  const milestones = pathway && pathway.get('pathwayMilestones');
  const pathwayInstanceSteps = pathwayInstance && pathwayInstance.get('pathwayInstanceSteps');
  const pathwayInstanceStepsMap = {};
  pathwayInstanceSteps.forEach((x) => {
    pathwayInstanceStepsMap[x.get('pathwayStepId')] = x.get('targetCompletionDate');
  });

  return (
    <SetScheduleFormStyles>
      <form onSubmit={handleSubmit}>
        <div className="button-top">
          <Button buttonType="secondary-outline" type="submit" disabled={pristine}>
            Next Step
          </Button>
        </div>
        <FormSection name="pathwayStepDates">
          <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
            <table>
              <thead>
                <tr>
                  <th width="150">Step Name</th>
                  <th width="300">Description</th>
                  <th width="100">Type</th>
                  <th width="100">Visible to Candidate</th>
                  <th width="150">Target Completion Date</th>
                </tr>
              </thead>
              <tbody>
                {milestones &&
                  milestones.map((milestone) => (
                    <React.Fragment key={milestone.getIn(['pathwayMilestone', 'id'])}>
                      <tr>
                        <td className="milestone-row-header" colSpan="100%">
                          {milestone.getIn(['pathwayMilestone', 'name'])}
                        </td>
                      </tr>
                      {/* eslint-disable-next-line max-len */}
                      {milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map((step) => (
                        <MilestoneStep step={step} key={step.get('id')} targetCompletionDate={pathwayInstanceStepsMap[step.get('id')]} required />
                      ))}
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </Grid>
          <div className="button-group">
            <div className="button-left">
              <Button buttonType="secondary-outline" type="button" onClick={previousPage}>
                Previous
              </Button>
            </div>
            <div className="button-right">
              <Button buttonType="secondary" type="submit" disabled={pristine}>
                Next Step
              </Button>
            </div>
          </div>
        </FormSection>
      </form>
    </SetScheduleFormStyles>
  );
};

SetScheduleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  pristine: PropTypes.bool.isRequired,
};
SetScheduleForm.defaultProps = {};

const SetScheduleFormView = reduxForm({
  form: 'selectCandidatesForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(SetScheduleForm);

export default SetScheduleFormView;
