import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  candidateState: undefined,
  staffState: undefined,
  screeningScore: undefined,
  allRequiredAnswersMatch: undefined,
  acceptedInvitationAt: undefined,
  registeredAt: undefined,
  remindedAt: undefined,
  confirmedAttendedAt: undefined,
  candidateDeletedAt: undefined,
  helpingOrganization: {
    id: undefined,
    name: undefined,
    orgType: undefined,
  },
  candidate: {
    candidateID: undefined,
    user: undefined,
    linkedOrganizations: undefined,
  },
  event: {
    eventId: undefined,
    eventName: undefined,
    eventDate: undefined,
    eventLogo: undefined,
    screeningSurveys: undefined,
  },
  screeningQuestionsCount: undefined,
  lastApprovedCandidateState: undefined,
  lastApprovedStaffState: undefined,
  lastStateChangedBy: undefined,
};

class CandidateEventModel extends Record(DEFAULT_ARGS) {
  static _name = 'CandidateApplication';

  get fullName() {
    return this.getIn(['event', 'eventName']);
  }
}

export default CandidateEventModel;
