import { connect } from 'react-redux';
import JobCandidateDetailsView from './job_candidate_details_view';

import { getCandidateApplication, getCurrentUser } from '../../../reducer';
import { fetchCandidateApplication } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: candidateApplicationId },
    },
  },
) => ({
  candidateApplicationId,
  currentUser: getCurrentUser(state),
  application: getCandidateApplication(state, candidateApplicationId),
});

const mapDispatchToProps = {
  fetchCandidateApplication,
};

const JobCandidateDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(JobCandidateDetailsView);

export default JobCandidateDetailsContainer;
