import styled from 'styled-components';
// import theme from '../../../common/theme';

const BenefitsFormStyles = styled.div`
  form {
    max-width: 1100px;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  button {
    outline: none;
  }

  h2 {
    text-align: left;
    width: 100%;
    padding: 8px;
  }
`;

export default BenefitsFormStyles;
