import { connect } from 'react-redux';

import { formValueSelector } from 'common/form';

import SelectCandidatesFormView from './select_candidates_form_view';
import { getCurrentUser } from '../../../../../reducer';

const selector = formValueSelector('selectCandidatesForm');

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
  selectedCandidatesCount: selector(state, 'candidates')
    ?.filter(Boolean)
    .keySeq()
    .toList()
    .size,
});

const mapDispatchToProps = {};

const SelectCandidatesFormContainer = connect(mapStateToProps, mapDispatchToProps)(SelectCandidatesFormView);

export default SelectCandidatesFormContainer;
