import React from 'react';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import formatPercent from 'util/format_percent';
import { formatDate } from 'util/format_datetimes';

import { PathwayInstance } from '../pathways.type';

interface PathwayInstanceSummaryProps {
  instance: PathwayInstance;
}

export const PathwayInstanceSummary: React.FC<PathwayInstanceSummaryProps> = ({ instance }) => {
  const is = instance.summary;
  return (
    <tr>
      <th>
        <Link to={`/staff/pathways/${instance.id}`}>{instance.name}</Link>
      </th>
      <th>{startCase(instance.state)}</th>
      <td>{formatDate(instance.startsOn)}</td>
      <td>{is.enrolled}</td>
      <td>{is.active}</td>
      <td>{is.completed}</td>
      <td>{formatPercent(is.overallRetention)}</td>
      <td>{formatPercent(is.adjustedRetention)}</td>
      <td>{is.totalDropped}</td>
      <td>{is.involuntarilyDropped}</td>
      <td>{is.voluntarilyDropped}</td>
    </tr>
  );
};
