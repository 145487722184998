import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import JobListView from './job_list_view';

import {
  getCurrentUser,
  getCandidate,
  getCandidateJobListingIds,
  getCandidateTotalJobListings,
  getCandidateJobListingsLoaded,
  getCandidateJobListingsLoading,
  getCandidateJobListingsFilters,
  getCandidateJobListingsSearch,
  getCandidateAppliedJobIds,
  getCandidateSavedJobIds,
  getIndeedCandidateSavedJobIds,
  getCandidateAppliedJobsLoaded,
  getCandidateAppliedJobsLoading,
  getCandidateSavedJobsLoaded,
  getCandidateSavedJobsLoading,
  getCandidateJobListingsZipcode,
  getCandidateJobListingsDistance,
  getIndeedCandidateJobListingIds,
  getIndeedCandidateJobListingsLoaded,
  getIndeedCandidateJobListingsLoading,
  getIndeedCandidateTotalJobListings,
} from '../../../reducer';
import {
  listFilteredJobPostingsForCandidate,
  setCandidateJobListingsSearch,
  listFilteredJobApplicationsForCandidate,
  setCandidateJobListingsZipcode,
  setCandidateJobListingsFilters,
  listFilteredIndeedJobPostingsForCandidate,
  listFilteredIndeedJobApplicationsForCandidate,
  removeFilteredIndeedJobPostingsForCandidate,
  setCandidateJobListingsPage,
  listCandidateApplicationsForCandidate,
  listExternalCandidateApplicationsForCandidate,
  fetchSavedJobPostingsByIds,
  fetchSavedIndeedJobPostingByIds,
  listResumes,
} from '../../../solve/actions';

const EMPTY_SET = OrderedSet();

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state, { mode }) => {
  let jobIds;
  let loaded;
  let loading;
  let indeedJobIds;
  let indeedLoaded;
  let indeedLoading;
  let totalJobs = 0;
  let totalIndeedJobs = 0;
  let allJobs;
  let savedJobs;
  let savedIndeedJobs;
  let savedJobIds;
  let savedIndeedJobIds;

  let jobIdsToBeFetched = [];
  let indeedJobIdsToBeFetched = [];
  let fetchedJobIds = EMPTY_SET;
  let fetchedIndeedJobIds = EMPTY_SET;

  switch (mode) {
    case 'saved':
      savedJobIds = getCandidateSavedJobIds(state);
      savedIndeedJobIds = getIndeedCandidateSavedJobIds(state);

      savedJobs = savedJobIds.map((id) => ({ id, type: 'job' }));
      savedIndeedJobs = savedIndeedJobIds.map((id) => ({ id, type: 'indeed-job' }));

      fetchedJobIds = getCandidateJobListingIds(state);
      fetchedIndeedJobIds = getIndeedCandidateJobListingIds(state);

      jobIdsToBeFetched = savedJobIds.subtract(fetchedJobIds).toJS();
      indeedJobIdsToBeFetched = savedIndeedJobIds.subtract(fetchedIndeedJobIds).toJS();

      jobIds = savedJobIds.union(savedIndeedJobIds);
      loaded = getCandidateSavedJobsLoaded(state);
      loading = getCandidateSavedJobsLoading(state);
      allJobs = savedJobs.union(savedIndeedJobs);

      totalJobs = savedJobs.size;
      totalIndeedJobs = savedIndeedJobIds.size;

      indeedLoaded = false;
      break;
    case 'applied':
      jobIds = getCandidateAppliedJobIds(state);
      loaded = getCandidateAppliedJobsLoaded(state);
      loading = getCandidateAppliedJobsLoading(state);
      totalJobs = getCandidateAppliedJobIds(state).size;
      indeedLoaded = false;
      allJobs = jobIds.map((id) => ({ id, type: 'job' }));
      break;
    case 'all':
    default:
      jobIds = getCandidateJobListingIds(state).filter((x) => !getCandidateAppliedJobIds(state).includes(x));
      loaded = getCandidateJobListingsLoaded(state);
      loading = getCandidateJobListingsLoading(state);
      totalJobs = +getCandidateTotalJobListings(state);
      indeedJobIds = getIndeedCandidateJobListingIds(state);
      indeedLoaded = getIndeedCandidateJobListingsLoaded(state);
      indeedLoading = getIndeedCandidateJobListingsLoading(state);
      totalIndeedJobs = +getIndeedCandidateTotalJobListings(state);
      allJobs = jobIds && jobIds.map((id) => ({ id, type: 'job' })).union(indeedJobIds.map((id) => ({ id, type: 'indeed-job' })));
  }

  // TODO: once you've got the indeed jobs in redux store, combine jobs with indeed jobs

  const currentUser = getCurrentUser(state);
  const currentCandidate = getCandidate(state, currentUser.get('candidateId'));
  return {
    currentCandidate,
    currentUser,
    jobs: allJobs,
    totalStandardJobs: totalJobs,
    totalIndeedJobs,
    loaded,
    loading,
    indeedLoaded,
    indeedLoading,
    jobIdsToBeFetched,
    indeedJobIdsToBeFetched,
    zipCode: getCandidateJobListingsZipcode(state),
    distance: getCandidateJobListingsDistance(state),
    filters: getCandidateJobListingsFilters(state),
    search: getCandidateJobListingsSearch(state),
  };
};

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  listFilteredJobPostingsForCandidate,
  listFilteredJobApplicationsForCandidate,
  listFilteredIndeedJobPostingsForCandidate,
  listFilteredIndeedJobApplicationsForCandidate,
  removeFilteredIndeedJobPostingsForCandidate,
  setCandidateJobListingsZipcode,
  listCandidateApplicationsForCandidate,
  listExternalCandidateApplicationsForCandidate,
  setCandidateJobListingsFilters,
  setCandidateJobListingsPage,
  fetchSavedJobPostingsByIds,
  fetchSavedIndeedJobPostingByIds,
  setSearch: setCandidateJobListingsSearch,
  fetchCandidateResume: listResumes,
};

const JobListContainer = connect(mapStateToProps, mapDispatchToProps)(JobListView);

export default JobListContainer;
