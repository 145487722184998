import { useState, useEffect } from 'react';

import { STATUS_CODE } from 'risekit/app/app.status';
import { getIndeedJobById } from 'risekit/api/request.api';

const useGetIndeedJob = (indeedJobId: string) => {
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>();

  const [indeedJob, setIndeedJob] = useState<any>();

  useEffect(() => {
    setLoading(true);

    const fetchIndeedJob = async () => {
      const { data, error: apiError } = await getIndeedJobById(indeedJobId);

      const isRequestCanceled = apiError?.messages !== STATUS_CODE.REQUEST_CANCEL;

      if (apiError && !isRequestCanceled) {
        setError(apiError);
        setLoading(false);
      }
      if (!apiError) {
        const [indeedJobData] = data?.results || [{}];

        setIndeedJob(indeedJobData);
        setLoading(false);
      }
    };

    fetchIndeedJob();
  }, [indeedJobId]);

  return { loading, error, indeedJob };
};

export default useGetIndeedJob;
