import React from 'react';
// import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import Plan from '../../../pricing/components/plan';
import Feature from '../../../pricing/components/feature';
import Grid, { Row, Col } from '../../../common/styles/grid';
import theme from '../../../common/theme';

const UpgradeNonprofit = () => (
  <Grid>
    <Row>
      <Col />
      <Col selected border>
        <h4 style={{ color: '#FFAB4A' }}>Current Plan</h4>
      </Col>
      <Col />
      <Col />
      <Col />
      <Col />
      <Col />
    </Row>

    <Row>
      <Col />
      <Col border selected>
        <Plan title="Always free access" tagline="" price={0} />
      </Col>
      <Col border>
        <Plan title="Community Influence" tagline="Small Business" price={250} />
      </Col>
      <Col border>
        <Plan title="Making a Difference" tagline="Medium Business" price={600} />
      </Col>
      <Col border>
        <Plan title="Moving the Needle" tagline="Large Business" price={1000} />
      </Col>
      <Col border>
        <Plan title="Changing your City" tagline="Corporate & Large Initiatives" price={2000} />
      </Col>
      <Col border>
        <Plan title="Changing the World" tagline="Corporate & Large Initiatives" price={3000} />
      </Col>
    </Row>

    <Row>
      <Col>
        <Feature title="Participants" body="Upload or invite program participants to create a profile" />
      </Col>
      <Col border selected>
        0-20
      </Col>
      <Col border>20-49</Col>
      <Col border>50-149</Col>
      <Col border>150-299</Col>
      <Col border>300-499</Col>
      <Col border>500+</Col>
    </Row>

    <Row>
      <Col>
        <Feature
          title="Localized Resource"
          body="Use RiseKit as a localized resource to apply to jobs, resources, career fairs, training programs and make a resume"
        />
      </Col>
      <Col border selected>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
    </Row>

    <Row>
      <Col>
        <Feature title="Jobs/Training programs" body="Post Jobs, track applicants, find jobs, see all program participant referrals of subscription amount" />
      </Col>
      <Col border selected>
        3
      </Col>
      <Col border>Unlimited</Col>
      <Col border>Unlimited</Col>
      <Col border>Unlimited</Col>
      <Col border>Unlimited</Col>
      <Col border>Unlimited</Col>
    </Row>

    <Row>
      <Col>
        <Feature title="Career Fair/Events" body="Utilize RiseKit for Career fair & Event tracking and recruiting" />
      </Col>
      <Col border selected>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
    </Row>

    <Row>
      <Col>
        <Feature title="Track Activity" body="Export/Track Outcomes, track the activity of program participants" />
      </Col>
      <Col border selected>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
    </Row>

    <Row>
      <Col>
        <Feature title="Support" body="Ongoing customer support" />
      </Col>
      <Col border selected>
        --
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
      <Col border>
        <CheckIcon style={{ color: theme.color.positive }} />
      </Col>
    </Row>

    <Row>
      <Col>
        <Feature title="Savings" body="Prepaid annual discount" />
      </Col>
      <Col border selected />
      <Col border>0%</Col>
      <Col border>0%</Col>
      <Col border>0%</Col>
      <Col border>0%</Col>
      <Col border>0%</Col>
    </Row>

    <Row>
      <Col />
      <Col border selected>
        <Plan tagline="Includes 0% discount" price={0} term="yr" />
      </Col>
      <Col border>
        <Plan tagline="Includes 0% discount" price={3000} term="yr" />
      </Col>
      <Col border>
        <Plan tagline="Includes 0% discount" price={5000} term="yr" />
      </Col>
      <Col border>
        <Plan tagline="Includes 0% discount" price={10000} term="yr" />
      </Col>
      <Col border>
        <Plan tagline="Includes 0% discount" price={20000} term="yr" />
      </Col>
      <Col border>
        <Plan tagline="Includes 0% discount" price={30000} term="yr" />
      </Col>
    </Row>

    <Row>
      <Col />
      <Col />
      <Col>
        <div style={{ padding: 10 }}>
          <small>
            * Capped if your organization is under <b>$250,000</b> in revenue
          </small>
        </div>
      </Col>
      <Col>
        <div style={{ padding: 10 }}>
          <small>
            * Capped if your organization is under <b>$500,000</b> in revenue
          </small>
        </div>
      </Col>
      <Col>
        <div style={{ padding: 10 }}>
          <small>
            * Capped if your organization is under <b>$2million</b> in revenue
          </small>
        </div>
      </Col>
      <Col>
        <div style={{ padding: 10 }}>
          <small>
            * Capped if your organization is under <b>$3million</b> in revenue
          </small>
        </div>
      </Col>
      <Col>
        <div style={{ padding: 10 }}>
          <small>
            * Capped if your organization is under <b>$4million</b> in revenue
          </small>
        </div>
      </Col>
    </Row>
  </Grid>
);

export default UpgradeNonprofit;
