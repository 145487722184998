import styled from 'styled-components';

const PathwayCandidateDetailsStyles = styled.div`
  .pathway-status {
    img {
      visibility: hidden;
    }
    &.pathway-status-s_complete img {
      visibility: visible;
    }
  }
`;

export default PathwayCandidateDetailsStyles;
