import ACTIONS from '../actions';
import { ResourcefulReducer } from '../../util';
import CandidateProgramModel from '../models/candidate_program_model';

const CandidateProgramReducer = new ResourcefulReducer('candidatePrograms', CandidateProgramModel, {
  actionTypes: [
    ACTIONS.LIST_FILTERED_CANDIDATE_PROGRAMS,
    ACTIONS.LIST_FILTERED_CANDIDATE_PROGRAMS.success,
    ACTIONS.LIST_FILTERED_PROGRAM_CANDIDATES_FOR_STAFF.success,
    ACTIONS.LIST_CANDIDATES_FOR_PROGRAM.success,
    ACTIONS.CREATE_CANDIDATE_PROGRAM.success,
    ACTIONS.FETCH_CANDIDATE_PROGRAM.success,
  ],
});

export default CandidateProgramReducer;
