import { connect } from 'react-redux';
import HomeView from './home_view';
import { getCurrentUser, getOnboarding, getOnboardingMode } from '../../../reducer';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
  onboarding: getOnboarding(state),
  onboadingMode: getOnboardingMode(state),
});

const mapDispatchToProps = {};

const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(HomeView);

export default HomeContainer;
