import { Link, Redirect } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

import Stepper from 'react-stepper-horizontal';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import * as toast from 'risekit/common/components/toast';

import theme from '../../../../common/theme';
import { MuiFormTheme, MuiThemeProvider } from '../../../../common/form';
import PathwayInviteCandidatesStyles from './pathway_invite_candidates_styles';

import ReviewForm from './review_form';
import SetScheduleForm from './set_schedule_form';
import SelectCandidatesForm from './select_candidates_form';
// import SetScheduleForm from './set_schedule_form';

const steps = [{ title: '1. Select Candidates' }, { title: '2. Set Schedule' }, { title: '3. Review' }];

const PathwayInviteCandidatesView = ({ initialValues, handleSubmit, pathwayInstance, resetCandidateForm }) => {
  const [page, setPage] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  /**
   * Reset redux form on component unmount.
   */
  useEffect(() => {
    return () => {
      resetCandidateForm();
    };
  }, [resetCandidateForm]);

  const handleSubmitAndRedirect = async (formValues) => {
    await handleSubmit(formValues);
    setSubmitted(true);
    toast.success({ title: 'Success', message: 'Your pathway candidates have been successfully saved and posted.' });
  };

  const pathwayURL = `/staff/pathways/${pathwayInstance.get('id')}`;

  if (submitted) {
    return <Redirect to={pathwayURL} />;
  }

  return (
    <PathwayInviteCandidatesStyles>
      <div className="content-area">
        <Link to={pathwayURL}>
          <Button color="primary" size="small" startIcon={<KeyboardBackspaceIcon />}>
            Go Back to Pathway
          </Button>
        </Link>
        <div className="wrapper">
          <div className="header">
            <h1>
              Invite: <span>Candidates</span>
            </h1>
            <Stepper
              steps={steps}
              activeStep={page}
              activeColor={theme.color.secondaryAction}
              activeTitleColor={theme.color.secondaryAction}
              completeColor={theme.color.secondaryAction}
              completeBarColor={theme.color.secondaryAction}
              completeTitleColor={theme.color.secondaryAction}
              circleFontSize={0}
              titleFontSize={14}
              titleFontWeight="bold"
              size={8}
            />
          </div>

          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {/* eslint-disable-next-line max-len */}
              {page === 0 && <SelectCandidatesForm onSubmit={() => setPage(page + 1)} initialValues={initialValues} pathwayInstance={pathwayInstance} />}
              {/* eslint-disable-next-line max-len */}
              {page === 1 && (
                <SetScheduleForm
                  previousPage={() => setPage(page - 1)}
                  onSubmit={() => setPage(page + 1)}
                  initialValues={initialValues}
                  pathwayInstance={pathwayInstance}
                />
              )}
              {page === 2 && (
                <ReviewForm previousPage={() => setPage(page - 1)} onSubmit={(values) => handleSubmitAndRedirect(values)} pathwayInstance={pathwayInstance} />
              )}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </PathwayInviteCandidatesStyles>
  );
};

PathwayInviteCandidatesView.propTypes = {
  initialValues: PropTypes.shape({ startDate: PropTypes.string }),
  handleSubmit: PropTypes.func.isRequired,
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  resetCandidateForm: PropTypes.func.isRequired,
};
PathwayInviteCandidatesView.defaultProps = {
  initialValues: {},
};

export default PathwayInviteCandidatesView;
