import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { Text, TextHeading } from '../../../../common/components';
import { reduxForm, Field, MuiCheckbox, MuiRadioGroup } from '../../../../common/form';
import Button from '../../../../common/components/button/index';
import challengesFormValidate from './challenges_form_validate';
import ChallengesFormStyles from './challenges_form_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const ChallengesForm = ({ handleSubmit }) => (
  <ChallengesFormStyles>
    <form onSubmit={handleSubmit}>
      <header>
        <div className="header-section">
          <img src="/images/jobs.png" alt="get-job" />
          <h4>Your goal is to get a job. Our job is to help you.</h4>
        </div>
        <div className="header-section">
          <img src="/icons/color/barrier.png" alt="challenges" />
          <h4>But you have a few challenges...</h4>
        </div>
      </header>
      <div className="box">
        <TextHeading size="small" weight="bold">
          What are the challenges for you?
        </TextHeading>
        <Text color="medium" italic>
          Select all that apply
        </Text>
        <Field name="challenges" component={MuiRadioGroup}>
          <FormGroup>
            <FormControlLabel
              className="answer"
              control={<Field component={MuiCheckbox} name="financialChallenge" variant="outlined" />}
              label="I have a financial challenge which prevents me from getting a job"
            />
            <div className="examples">Some examples: housing, transportation, child care, disability</div>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              className="answer"
              control={<Field component={MuiCheckbox} name="backgroundChallenge" variant="outlined" />}
              label="I have a background which I think prevents me from getting a job"
            />
            <div className="examples">Some examples: alcohol, drugs, crime</div>
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              className="answer"
              control={<Field component={MuiCheckbox} name="experienceChallenge" variant="outlined" />}
              label="I have lack of experience"
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              className="answer"
              control={<Field component={MuiCheckbox} name="emotionalChallenge" variant="outlined" />}
              label="I have an emotional challenge which prevents me from getting a job"
            />
            <div className="examples">Some examples: trauma, depression, anxiety</div>
          </FormGroup>
        </Field>
      </div>
      <div className="button-group">
        <div className="button-right">
          <Button buttonType="secondary" type="submit">
            Next Step
          </Button>
        </div>
      </div>
    </form>
  </ChallengesFormStyles>
);

ChallengesForm.propTypes = propTypes;
ChallengesForm.defaultProps = defaultProps;

const ChallengesFormView = reduxForm({
  form: 'applyToEventForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: challengesFormValidate,
})(ChallengesForm);

export default ChallengesFormView;
