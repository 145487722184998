import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { reduxForm } from '../../../../common/form';
import { Text, TextHeading } from '../../../../common/components';
import Button from '../../../../common/components/button/index';
import additionalQuestionsCompletedValidate from './additional_questions_completed_validate';
import AdditionalQuestionsCompletedStyles from './additional_questions_completed_styles';
import EventTile from '../../../../events/components/tile';
import UserModel from '../../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  service: PropTypes.instanceOf(Map).isRequired,
  fetchExternalCandidateService: PropTypes.func.isRequired,
  listExternalCandidateServices: PropTypes.func.isRequired,
  skippedQuestionnaire: PropTypes.bool,
};

const defaultProps = {
  skippedQuestionnaire: false,
};

const AdditionalQuestionsCompleted = ({ currentUser, skippedQuestionnaire, fetchExternalCandidateService, service, listExternalCandidateServices }) => {
  const [submitted, setSubmitted] = useState(false);
  const customThankYouMessage = service.get('customThankYouMessage');
  useEffect(() => {
    listExternalCandidateServices(currentUser);
  }, [currentUser, fetchExternalCandidateService, listExternalCandidateServices]);

  const handleSubmitAndRedirect = () => {
    setSubmitted(true);
  };

  if (submitted) {
    return <Redirect to="/candidate/resources" />;
  }

  return (
    <AdditionalQuestionsCompletedStyles>
      <EventTile
        id={service.get('id')}
        key={service.get('id')}
        logo={service.get('logoUrl')}
        title={service.get('name')}
        body={service.get('description')}
        distance={service.get('distance')}
        cost={service.get('cost') > 0 ? numeral(service.get('cost')).format('0') : 'FREE'}
      />
      <form onSubmit={handleSubmitAndRedirect}>
        {!skippedQuestionnaire && (
          <TextHeading size="large" weight="bold" margin="25px 0px 10px">
            Thank you for completing the questions.
          </TextHeading>
        )}
        <Text size="large" color="medium">
          Your application was submitted successfully.
        </Text>
        {customThankYouMessage && (
          <div className="message">
            <h4>Note from {service.getIn(['postingOrganization', 'name'])}:</h4>
            <p dangerouslySetInnerHTML={{ __html: customThankYouMessage }} />
          </div>
        )}
        <Button buttonType="primary-outline" type="submit">
          Done
        </Button>
      </form>
    </AdditionalQuestionsCompletedStyles>
  );
};

AdditionalQuestionsCompleted.propTypes = propTypes;
AdditionalQuestionsCompleted.defaultProps = defaultProps;

const AdditionalQuestionsCompletedView = reduxForm({
  form: 'applyToServiceForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: additionalQuestionsCompletedValidate,
})(AdditionalQuestionsCompleted);

export default AdditionalQuestionsCompletedView;
