import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import Button from '../../../../common/components/button/index';
import { Field, MuiTextField, MuiSelect, MuiDatePicker, EditorField } from '../../../../common/form';

import { useFetchOnce } from '../../../../util/hooks';

import UserModel from '../../../../session/models/user_model';
import SelectTypeFormStyles from './select_type_form_styles';
import { Spinner } from '../../../../common/components';

const SelectTypeFormView = ({ handleSubmit, pathwayTypes, listPathways, currentUser, organizationId }) => {
  const loaded = useFetchOnce(currentUser, organizationId, listPathways);

  if (!loaded && !pathwayTypes) {
    return <Spinner />;
  }

  return (
    <SelectTypeFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <MuiSelect variant="outlined" options={pathwayTypes.toList()} label="Type Of Pathway" name="pathwayType" margin="dense" required />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiTextField} variant="outlined" label="Pathway Name" name="pathwayName" margin="dense" fullWidth required />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiDatePicker} variant="outlined" name="startDate" margin="dense" label="Pathway Start Date" fullWidth required />
          </Grid>
          <Grid item xs={12}>
            <Field variant="outlined" component={EditorField} label="Description" name="description" margin="dense" fullWidth multiline />
          </Grid>
        </Grid>
        <div className="button-group">
          <div className="button-left">
            <Link to="/staff/pathways">
              <Button buttonType="secondary-outline">Cancel</Button>
            </Link>
          </div>
          <div className="button-right">
            <Button buttonType="secondary" type="submit">
              Next Step
            </Button>
          </div>
        </div>
      </form>
    </SelectTypeFormStyles>
  );
};

SelectTypeFormView.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  listPathways: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  pathwayTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};
SelectTypeFormView.defaultProps = {};

export default SelectTypeFormView;
