/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { Button } from '../../../common/components';

import theme from '../../../common/theme';
import TileStyles, { Header, Content, Statuses, Status } from './invite_applicant_tile_styles';
import CustomApplicantStepper from '../../../jobs/application_list_item/custom_applicant_stepper';
import { programStaffStatusEnums, candidateStaffStatusEnums, eventStaffStatusEnums } from '../../../enums/staff_status_enums';
import CandidateProgramModel from '../../../solve/models/candidate_program_model';
import UserModel from '../../../session/models/user_model';

const { recommended, appliedOnSolve, appliedOnSite, reviewed, interviewing, offer, hired, archived } = candidateStaffStatusEnums;

const candidateSteps = [
  { label: 'Recommended / Saved', value: recommended },
  { label: 'Applied On RiseKit', value: appliedOnSolve, optional: true },
  { label: 'Applied On Company Website', value: appliedOnSite, optional: true },
  { label: 'Reviewed', value: reviewed },
  { label: 'Interviewing', value: interviewing },
  { label: 'Offer', value: offer },
];

const candidateStepMapping = {
  [recommended]: 0,
  [appliedOnSolve]: 1,
  [appliedOnSite]: 2,
  [reviewed]: 3,
  [interviewing]: 4,
  [offer]: 5,
};

const { invited, applied, joined, graduated, droppedOut, declined } = programStaffStatusEnums;

const programSteps = (invitation) => [
  { label: 'Invited/Unassigned', value: invited },
  { label: `Applied ${invitation.get('appliedAt') ? moment(invitation.get('appliedAt')).format('Do MMM YY') : ''}`, value: applied },
  { label: `Joined ${invitation.get('joinedAt') ? moment(invitation.get('joinedAt')).format('Do MMM YY') : ''}`, value: joined },
];

const programStepMapping = {
  [invited]: 0,
  [applied]: 1,
  [joined]: 2,
};

const { initial, registered, reminded, attended } = eventStaffStatusEnums;

const eventSteps = (invitation) => [
  { label: `Registered ${invitation.get('registeredAt') ? moment(invitation.get('registeredAt')).format('Do MMM YY') : ''}`, value: registered },
  { label: `Reminded ${invitation.get('remindedAt') ? moment(invitation.get('remindedAt')).format('Do MMM YY') : ''}`, value: reminded },
  { label: `Attended ${invitation.get('confirmedAttendedAt') ? moment(invitation.get('confirmedAttendedAt')).format('Do MMM YY') : ''}`, value: attended },
];

const eventStepMapping = {
  [initial]: -1,
  [registered]: 0,
  [reminded]: 1,
  [attended]: 2,
};

const InviteApplicantTileView = ({
  invitation,
  currentUser,
  resendInvitation,
  type,
  transitionCandidateApplicationByStaff,
  transitionCandidateProgramByStaff,
  transitionCandidateEventByStaff,
  statusUpdateError: { errorMessage },
  clearApiError,
}) => {
  let updateStaffStatus;
  let mapping;
  let steps;
  let stepCount;
  switch (type) {
    case 'job':
      updateStaffStatus = (status) => transitionCandidateApplicationByStaff(currentUser, invitation.get('id'), status.substring(2));
      mapping = candidateStepMapping;
      steps = candidateSteps;
      stepCount = 5;
      break;
    case 'event':
      updateStaffStatus = (status) => transitionCandidateEventByStaff(currentUser, invitation.get('id'), status.substring(2));
      mapping = eventStepMapping;
      steps = eventSteps(invitation);
      stepCount = 2;
      break;
    case 'program':
    default:
      updateStaffStatus = (status) => transitionCandidateProgramByStaff(currentUser, invitation.get('id'), status.substring(2));
      mapping = programStepMapping;
      steps = programSteps(invitation);
      stepCount = 2;
  }

  const staffState = invitation.get('staffState');
  // const [activeStep, setActiveStep] = useState(mapping[staffState]);
  const activeStep = mapping[staffState];
  const userDropped = staffState === droppedOut;
  const userGraduated = staffState === graduated;
  const userDeclined = staffState === declined;
  const userArchived = staffState === archived;
  const userHired = staffState === hired;

  const resendInvitationRequest = () => resendInvitation(currentUser, invitation.get('id'));

  let invitationName;
  if (invitation.get('firstName') && invitation.get('lastName')) {
    invitationName = (
      <React.Fragment>
        {invitation.get('firstName')} {invitation.get('lastName')}
      </React.Fragment>
    );
  } else if (invitation.get('toEmail')) {
    invitationName = invitation.get('toEmail');
  } else {
    invitationName = invitation.get('toPhone');
  }

  console.log('invitation', invitation);
  return (
    <TileStyles>
      <Header>
        <div className="applicant">
          <div style={{ width: '175px' }}>
            <p>INVITED</p>
            <h2>{invitationName}</h2>
          </div>
          <div className="table-entry">
            <p>Referred By</p>
            <h4>{invitation.getIn(['owningOrganization', 'name'])}</h4>
          </div>
          <div className="table-entry">
            <p>Invited</p>
            <h4>{invitation.get('createdAt') ? moment(invitation.get('createdAt')).format('Do MMM') : 'Unknown'}</h4>
          </div>
        </div>
        <div>
          <Button buttonType="secondary-outline" onClick={resendInvitationRequest}>
            Resend Invite
          </Button>
        </div>
      </Header>
      <Content>
        {userDropped || userGraduated || userDeclined || userArchived || userHired ? (
          <CustomApplicantStepper
            fullWidth
            steps={steps}
            activeStep={userDeclined ? -1 : stepCount}
            setActiveStep={() => {}}
            onChange={() => {}}
            disabled
            backgroundColor="#f7f7f7"
          />
        ) : (
          <CustomApplicantStepper
            fullWidth
            steps={steps}
            activeStep={activeStep}
            setActiveStep={() => {}}
            onChange={updateStaffStatus}
            disabled
            backgroundColor="#f7f7f7"
          />
        )}
        {type === 'job' && (
          <Statuses>
            <Status color={userArchived ? '#FF6F6F' : null} disabled>
              <CloseIcon /> Archived
            </Status>
            <Status color={userHired ? '#059765' : null} disabled>
              <DoneIcon /> Hired
            </Status>
          </Statuses>
        )}
        {type === 'program' && (
          <React.Fragment>
            <div>
              <FormControlLabel
                disabled={userDropped || userGraduated}
                control={<Checkbox checked={userDeclined} onChange={() => {}} />}
                label="Applicant Declined"
              />
            </div>
            <Statuses>
              <Status disabled>
                <img src={`/icons/dropped_out${userDropped ? '' : '_disabled'}.svg`} height="40" alt="dropped out" />
                <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userDropped ? theme.color.positive : null }} />
                <span style={{ color: userDropped ? theme.color.redLight : null }}>Dropped Out</span>
              </Status>
              <Status disabled>
                <img src={`/icons/graduated${userGraduated ? '' : '_disabled'}.svg`} height="40" alt="graduated" />
                <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userGraduated ? theme.color.positive : null }} />
                <span style={{ color: userGraduated ? theme.color.green : null }}>Graduated</span>
              </Status>
            </Statuses>
          </React.Fragment>
        )}
      </Content>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={Boolean(errorMessage)}
        message={errorMessage}
        onClose={() => clearApiError()}
        autoHideDuration={3000}
      />
    </TileStyles>
  );
};

InviteApplicantTileView.propTypes = {
  invitation: PropTypes.oneOfType([PropTypes.instanceOf(CandidateProgramModel), PropTypes.instanceOf(Iterable)]).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  type: PropTypes.string,
  resendInvitation: PropTypes.func.isRequired,
  transitionCandidateApplicationByStaff: PropTypes.func.isRequired,
  transitionCandidateProgramByStaff: PropTypes.func.isRequired,
  transitionCandidateEventByStaff: PropTypes.func.isRequired,
  clearApiError: PropTypes.func.isRequired,
  statusUpdateError: PropTypes.shape({
    errorMessage: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
};
InviteApplicantTileView.defaultProps = {
  type: '',
};

export default InviteApplicantTileView;
