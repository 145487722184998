import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import CreateProgramStyles, { Title, SubTitle, SupportText, Box } from './create_program_styles';
import theme from '../../../common/theme';

const propTypes = {};

const defaultProps = {};

const useStyles = makeStyles({
  card: { padding: 50 },
});

const CreateProgramView = () => {
  const classes = useStyles();

  return (
    <CreateProgramStyles>
      <Card className={classes.card}>
        <Title>I need to create...</Title>
        <SubTitle>Choose one of the following</SubTitle>
        <Grid container spacing={1}>
          <Link to="/staff/programs/new">
            <Grid item xs={6}>
              <Box
                style={{
                  border: `solid 1px ${theme.color.secondaryAction}`,
                }}
              >
                <img src="/icons/program-listing/job-training-program.svg" alt="Job Training" />
                <SupportText>Program or Resource</SupportText>
              </Box>
            </Grid>
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link to="/staff/events/new">
            <Grid item xs={6}>
              <Box
                style={{
                  border: `solid 1px ${theme.color.secondaryAction}`,
                }}
              >
                <img src="/icons/program-listing/event.svg" alt="Event" />
                <SupportText>An Event</SupportText>
              </Box>
            </Grid>
          </Link>
        </Grid>
      </Card>
    </CreateProgramStyles>
  );
};

CreateProgramView.propTypes = propTypes;
CreateProgramView.defaultProps = defaultProps;

export default CreateProgramView;
