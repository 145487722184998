import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedSet } from 'immutable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { JOB_STATE_OPTIONS } from '../../../constants/options';
import { setStaffJobListingsFilters, setStaffJobListingsZipcode } from '../../../solve/actions';
import { getStaffJobListingsFilters, getEnumsForFormSelect, getStaffJobListingZipCode, getCurrentUser } from '../../../reducer';
// import JobListStyles from './job_list_styles';

// TODO: auto-populate children from ENUM
const FilterSelect = ({ filters, onChange, name, children }) => (
  <Select
    value={filters.get(name) || ''}
    fullWidth
    onChange={(e) => {
      onChange(filters.set(name, e.target.value));
    }}
  >
    {children}
  </Select>
);
FilterSelect.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// TODO: wire up to SystemEnums!
const JobListFiltersView = ({
  filters,
  onChange,
  jobTitleFilter,
  industryTypeFilter,
  shiftTitleFilter,
  salaryFilter,
  distanceFilter,
  newSearch,
  // eslint-disable-next-line no-shadow
  setStaffJobListingsZipcode,
  onSearchChange,
  zipCode,
  isMyOrgTab,
}) => {
  const [newZipcode, setNewZipcode] = useState(zipCode);
  const [zipValid, setZipValid] = useState(true);

  // Ensure we set the local state after updating redux
  useEffect(() => {
    setNewZipcode(zipCode);
    setStaffJobListingsZipcode(zipCode);
  }, [zipCode, setNewZipcode, setStaffJobListingsZipcode]);

  /**
   * Reset zip code on component unmount.
   */
  useEffect(() => () => setStaffJobListingsZipcode(''), [setStaffJobListingsZipcode]);

  const ifZip = (zip, action) => {
    if (zip === '') {
      setZipValid(true);
      if (action) {
        action(zip);
      }
    } else if (zip.match(/^\d{5}$/)) {
      if (!zipValid) {
        setZipValid(true);
      }
      if (action) {
        action(zip);
      }
    } else if (zipValid) {
      setZipValid(false);
    }
  };

  const onZipSubmit = (e) => {
    e.preventDefault();
    const val = e.target.querySelector('input').value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setStaffJobListingsZipcode);
  };
  const onZipBlur = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (zipCode === val) {
      return;
    }
    ifZip(val, setStaffJobListingsZipcode);
  };
  const onZipChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (newZipcode === val) {
      return;
    }
    ifZip(val);
    setNewZipcode(val);
  };

  return (
    <Grid container spacing={2} style={{ marginBottom: 15 }}>
      <Grid item md={3} xs={6}>
        <TextField
          id="search"
          placeholder="Search"
          margin="dense"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            // endAdornment: (
            //   <InputAdornment position="end">
            //     <Button variant="outlined" color="secondary" size="small" style={{ float: 'right', width: 200, margin: -8 }} startIcon={<FilterListIcon />}>
            //       Filter Results
            //     </Button>
            //   </InputAdornment>
            // ),
          }}
          value={newSearch}
          onChange={onSearchChange}
        />
      </Grid>
      {!isMyOrgTab && (
        <Grid item md={3} xs={6}>
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel>Distance</InputLabel>
            <FilterSelect filters={filters} onChange={onChange} name="distance" variant="outlined">
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {distanceFilter &&
                distanceFilter.map(([slug, label]) => (
                  <MenuItem key={slug} value={slug}>
                    {label}
                  </MenuItem>
                ))}
              <MenuItem value="" disabled style={{ opacity: 1, float: 'right' }}>
                <img src="/images/powered_by_google_white.png" alt="powered by Google" />
              </MenuItem>
            </FilterSelect>
          </FormControl>
        </Grid>
      )}
      <Grid item md={3} xs={6}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>State</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="state">
            {Object.entries(JOB_STATE_OPTIONS).map(([slug, label]) => (
              <MenuItem key={slug} value={slug}>
                {label}
              </MenuItem>
            ))}
          </FilterSelect>
        </FormControl>
      </Grid>
      {!isMyOrgTab && (
        <Grid item md={3} xs={6}>
          <form onSubmit={onZipSubmit}>
            <TextField
              id="zipCode"
              label="Zip Code"
              type="number"
              margin="dense"
              variant="outlined"
              fullWidth
              mask="ddddd"
              onBlur={onZipBlur}
              onChange={onZipChange}
              className={zipValid ? '' : 'error'}
              error={!zipValid}
              value={newZipcode || ''}
              InputLabelProps={{
                shrink: false,
              }}
            />
          </form>
        </Grid>
      )}

      <Grid item md={3} xs={6}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Job Type</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="type">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {jobTitleFilter &&
              jobTitleFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item md={3} xs={6}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Industry</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="industry">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {industryTypeFilter &&
              industryTypeFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item md={3} xs={6}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Shift</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="shift">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {shiftTitleFilter &&
              shiftTitleFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>

      <Grid item md={3} xs={6}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Salary</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="salary">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {salaryFilter &&
              salaryFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>
    </Grid>
  );
};
JobListFiltersView.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  newSearch: PropTypes.string.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  setStaffJobListingsZipcode: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  distanceFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  jobTitleFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  industryTypeFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  shiftTitleFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  salaryFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  isMyOrgTab: PropTypes.bool.isRequired,
};

JobListFiltersView.defaultProps = {
  zipCode: '',
};

const mapStateToProps = (state) => ({
  filters: getStaffJobListingsFilters(state),
  zipCode: getStaffJobListingZipCode(state) || getCurrentUser(state).getIn(['staffOrganization', 'zipCode']) || getCurrentUser(state).zip || '10001',
  distanceFilter: getEnumsForFormSelect(state, 'DistanceType'),
  jobTitleFilter: getEnumsForFormSelect(state, 'JobType'),
  industryTypeFilter: getEnumsForFormSelect(state, 'IndustryType'),
  shiftTitleFilter: getEnumsForFormSelect(state, 'ShiftType'),
  salaryFilter: getEnumsForFormSelect(state, 'PayLevelType'),
});

const mapDispatchToProps = {
  onChange: setStaffJobListingsFilters,
  setStaffJobListingsZipcode,
};

const JobListFilters = connect(mapStateToProps, mapDispatchToProps)(JobListFiltersView);

export default JobListFilters;
