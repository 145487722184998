import styled from 'styled-components';
import theme from '../../../common/theme';

const AddEventStyles = styled.div`
  h3 {
    color: ${(props) => props.theme.color.secondary};
    letter-spacing: 0;
  }
  h4 {
    margin-bottom: 0px;
  }
  header {
    font-weight: bold;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 40px;
  }
`;

export const Title = styled.h1``;

export const SubTitle = styled.p`
  text-transform: uppercase;
  color: ${theme.color.subtleAction};
  font-size: 1em;
  font-weight: 700;
`;

export const Box = styled.div`
  padding: 40px;
  background-color: ${theme.color.tile2};
  text-align: center;
  height: 300px;
  width: 300px;
  border-radius: 5px;

  img {
    margin: 0 auto;
  }
`;

export const SupportText = styled.p`
  font-size: 1em;
  color: ${theme.color.primary};
  text-transform: uppercase;
  font-weight: 500;
`;

export default AddEventStyles;
