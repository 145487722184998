import React from 'react';
import PropTypes from 'prop-types';
// import { List } from 'immutable';
import Display from './display';
import Editing from './editing';
// import PDFUpload from './pdf_upload';
import CertificationsStyles from './certifications_styles';
import { Card } from '../../../../common/styles/layout';

const propTypes = {
  fields: PropTypes.shape({
    map: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  prefixWord: PropTypes.string,
};

const defaultProps = {
  prefixWord: '',
};

const CertificationsView = ({ fields, prefixWord }) => (
  <CertificationsStyles>
    <h3>{prefixWord} Certifications</h3>
    <Card>
      <div className="editing-container">
        <p className="additional-info">
          These are official certifications, given by accredited training programs or schools, not general skills. Please add any skills for which you don't
          have a formal certification in the skills section
        </p>
        <Editing certifications={fields} />
      </div>
      <div className="display-container">
        <Display certifications={fields} />
      </div>
    </Card>
  </CertificationsStyles>
);

CertificationsView.propTypes = propTypes;
CertificationsView.defaultProps = defaultProps;

export default CertificationsView;
