import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const DEFAULT_STATE = fromJS({
  eventCandidatesLoading: false,
  eventCandidatesLoaded: 0,
  eventCandidatesList: EMPTY_SET,
  filters: {},
});

const StaffEventCandidatesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_STAFF_EVENT_CANDIDATES_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          eventCandidatesLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_EVENT_CANDIDATES_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          eventCandidatesLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_EVENT_CANDIDATES_FOR_STAFF.request:
      return state.merge({
        eventCandidatesLoading: true,
      });
    case ACTIONS.LIST_FILTERED_EVENT_CANDIDATES_FOR_STAFF.success:
      return state.merge({
        eventCandidatesList: OrderedSet(action.response.result),
        eventCandidatesLoading: false,
        eventCandidatesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_EVENT_CANDIDATES_FOR_STAFF.failure:
      return state.merge({
        eventCandidatesLoading: false,
        eventCandidatesLoaded: false,
        eventCandidatesList: EMPTY_SET,
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search');

export const getLoaded = (state) => state.get('eventCandidatesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getCandidateIds = (state) => state.get('eventCandidatesList') || DEFAULT_STATE.get('eventCandidatesList');

export default StaffEventCandidatesReducer;
