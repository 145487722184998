import { connect } from 'react-redux';
import DashboardStepsView from './dashboard_steps_view';
import { getCurrentUser, getCandidate } from '../../../../reducer';
import {} from '../../../../solve/actions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const candidate = getCandidate(state, currentUser.get('candidateId'));
  return {
    candidate,
  };
};

const mapDispatchToProps = {};

const DashboardStepsContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardStepsView);

export default DashboardStepsContainer;
