import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { OrderedSet } from 'immutable';
import ReportsStyles, { Title, SubTitle, SupportTitle, SupportText, Box } from './reports_styles';
import { useFetchOnce } from '../../../util/hooks';
import { downloadFileFromBlob } from '../../../util/file_operations';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  childOrganizations: PropTypes.instanceOf(OrderedSet).isRequired,
  listChildOrganizations: PropTypes.func.isRequired,
  fetchOrganizationCandidatesReport: PropTypes.func.isRequired,
  fetchOrganizationCareerFairReport: PropTypes.func.isRequired,
  fetchOrganizationNewCandidatesReport: PropTypes.func.isRequired,
  showPathways: PropTypes.bool.isRequired,
  showInmates: PropTypes.bool.isRequired,
  isRisekit: PropTypes.bool.isRequired,
};

const defaultProps = {};

const ReportsView = ({
  user,
  childOrganizations,
  listChildOrganizations,
  fetchOrganizationCandidatesReport,
  fetchOrganizationCareerFairReport,
  fetchOrganizationNewCandidatesReport,
  showPathways,
  showInmates,
  isRisekit,
}) => {
  const orgId = user.getIn(['staffOrganization', 'id']);

  useFetchOnce(user, orgId, listChildOrganizations);

  function downloadAllCandidatesCsvReport() {
    fetchOrganizationCandidatesReport(user, orgId).then((response) => {
      downloadFileFromBlob(response.data, `all_candidates_report_${orgId}.csv`);
    });
  }

  function downloadNewCandidatesCsvReport() {
    fetchOrganizationNewCandidatesReport(user, orgId).then((response) => {
      downloadFileFromBlob(response.data, `new_candidates_report_${orgId}.csv`);
    });
  }

  function downloadEventCsvReport() {
    fetchOrganizationCareerFairReport(user, orgId).then((response) => {
      downloadFileFromBlob(response.data, `career_fair_report${orgId}.csv`);
    });
  }

  return (
    <ReportsStyles>
      <Title>Available Reports</Title>
      <SubTitle>Choose one of the following</SubTitle>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box onClick={downloadAllCandidatesCsvReport}>
            <SupportTitle>Candidates List - CSV Download</SupportTitle>
            <SupportText>List of all candidates associated with your organization</SupportText>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box onClick={downloadNewCandidatesCsvReport}>
            <SupportTitle>New Candidates Summary - CSV Download</SupportTitle>
            <SupportText>Summary of new candidates associated with your organization by month for the last year</SupportText>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box onClick={downloadEventCsvReport}>
            <SupportTitle>Events Summary - CSV Download</SupportTitle>
            <SupportText>{`Summary of your organizations events posted on ${isRisekit ? 'Risekit' : 'Solve'}`}</SupportText>
          </Box>
        </Grid>

        {showPathways && (
          <Grid item xs={12}>
            <Link to={`/staff/reporting/pathways${childOrganizations && childOrganizations.size > 0 ? '' : `/${orgId}`}`}>
              <Box>
                <SupportTitle>Pathways</SupportTitle>
                <SupportText>{`Summary of your organizations pathways posted on ${isRisekit ? 'Risekit' : 'Solve'}`}</SupportText>
              </Box>
            </Link>
          </Grid>
        )}

        {showInmates && (
          <Grid item xs={12}>
            <Link to="/staff/reporting/recidivism">
              <Box>
                <SupportTitle>Recidivism</SupportTitle>
                <SupportText>{`Recidivism report for candidates on ${isRisekit ? 'Risekit' : 'Solve'}`}</SupportText>
              </Box>
            </Link>
          </Grid>
        )}

        <Grid item xs={12}>
          <Link to="/staff/reporting/mode-reports">
            <Box>
              <SupportTitle>Mode Report</SupportTitle>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </ReportsStyles>
  );
};

ReportsView.propTypes = propTypes;
ReportsView.defaultProps = defaultProps;

export default ReportsView;
