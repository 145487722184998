import React from 'react';
import PropTypes from 'prop-types';
import { Iterable } from 'immutable';
import moment from 'moment';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PdfViewStyles, {
  DatesText,
  Em,
  Entry,
  EntryHeading,
  EntrySubHeading,
  HeaderContact,
  HeaderContent,
  HeaderLI,
  HeaderName,
  Heading,
  Row,
  RowContent,
  RowMargin,
  Section,
  SectionHeading,
  SectionHeadingText,
  SectionDates,
  SectionIcon,
  UL,
  LI,
} from './pdf_view_styles';
import IconCheck from './icons/icon-check.png';
import IconEmail from './icons/icon-email.png';
import IconHome from './icons/icon-home.png';
import IconPhone from './icons/icon-phone.png';
import IconSchool from './icons/icon-school.png';
import IconStar from './icons/icon-star.png';
import IconWork from './icons/icon-work.png';
import IconEdit from './icons/icon-edit.png';
// import fontPrata from './fonts/prata-regular.ttf';
// import fontRoboto from './fonts/roboto-regular.ttf';
// import fontRobotoItalic from './fonts/roboto-italic.ttf';
// import fontRobotoBold from './fonts/roboto-bold.ttf';
// import fontRobotoBoldItalic from './fonts/roboto-bold-italic.ttf';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    padding: 40,
  },
});

// const registerFont = () => {
//   Font.register({
//     family: 'Prata',
//     src: fontPrata,
//   });
//   Font.register({
//     family: 'Roboto',
//     fonts: [
//       {
//         src: fontRoboto,
//       },
//       {
//         src: fontRobotoItalic,
//         fontStyle: 'italic',
//       },
//       {
//         src: fontRobotoBold,
//         fontWeight: 'bold',
//         fontStyle: 'normal',
//       },
//       {
//         src: fontRobotoBoldItalic,
//         fontWeight: 'bold',
//         fontStyle: 'italic',
//       },
//     ],
//   });
// };

const ResumeEntry = ({ entry, icon, city, state, title, subtitle, description }) => (
  <Entry wrap={false}>
    <Row>
      <RowMargin>
        <SectionIcon>{icon && <Image src={icon} />}</SectionIcon>
        <SectionDates>
          {entry.get('startDate') && (
            <React.Fragment>
              <DatesText>{moment(entry.get('startDate')).format('MM.YYYY')}</DatesText>
              <DatesText>{moment(entry.get('endDate')).format('MM.YYYY')}</DatesText>
            </React.Fragment>
          )}
        </SectionDates>
      </RowMargin>

      <RowContent>
        {title && <EntryHeading>{title}</EntryHeading>}
        <EntrySubHeading>
          {subtitle && subtitle}
          {city && (
            <Em>
              {subtitle && ', '}
              {`${city}, ${state}`}
            </Em>
          )}
        </EntrySubHeading>
        {description && <Text>{description}</Text>}
        <UL>{entry.get('descriptions') && entry.get('descriptions').map((descItem) => <LI key={descItem}>• {descItem}</LI>)}</UL>
      </RowContent>
    </Row>
  </Entry>
);

ResumeEntry.propTypes = {
  entry: PropTypes.instanceOf(Iterable).isRequired,
  icon: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
};

ResumeEntry.defaultProps = {
  icon: undefined,
  city: undefined,
  state: undefined,
  title: undefined,
  subtitle: undefined,
  description: undefined,
};

const PdfView = ({ candidate, resume }) => {
  // useEffect(() => {
  //   registerFont();
  // }, []);

  let address = '';
  if (candidate.getIn(['address', 'city']) && candidate.getIn(['address', 'state'])) {
    address = `${candidate.getIn(['address', 'city'])}, ${candidate.getIn(['address', 'state'])}`;
  } else if (candidate.getIn(['user', 'zip'])) {
    address = `${candidate.getIn(['user', 'zip'])}`;
  }

  /**
   * Be very careful with this render function. You must never return raw text not wrapped in a <Text> element!
   * */
  return (
    <Document>
      <Page size="Letter" style={styles.page}>
        <PdfViewStyles style={styles.section}>
          <Section wrap={false}>
            <Row>
              <RowMargin />
              <HeaderContent>
                <HeaderName>
                  <Heading>{`${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`}</Heading>
                </HeaderName>
                <HeaderContact>
                  {candidate.getIn(['user', 'phone']) ? (
                    <HeaderLI>
                      <Image src={IconPhone} style={{ marginRight: 3 }} />
                      <Text>{candidate.getIn(['user', 'phone'])}</Text>
                    </HeaderLI>
                  ) : undefined}
                  {candidate.getIn(['user', 'email']) ? (
                    <HeaderLI>
                      <Image src={IconEmail} style={{ marginRight: 3 }} />
                      <Text>{candidate.getIn(['user', 'email'])}</Text>
                    </HeaderLI>
                  ) : undefined}
                  {address ? (
                    <HeaderLI>
                      <Image src={IconHome} style={{ marginRight: 3 }} />
                      <Text>{address}</Text>
                    </HeaderLI>
                  ) : undefined}
                </HeaderContact>
              </HeaderContent>
            </Row>
          </Section>

          <Section>
            {resume.get('workExperience').size > 0 && (
              <View wrap={false}>
                <Row>
                  <RowMargin />
                  <RowContent>
                    <SectionHeading>
                      <SectionHeadingText>Work Experiences</SectionHeadingText>
                    </SectionHeading>
                  </RowContent>
                </Row>

                {resume
                  .get('workExperience')
                  .slice(0, 1)
                  .map((job) => {
                    const experience = job.get('experience');
                    return (
                      <ResumeEntry
                        entry={experience}
                        icon={IconWork}
                        key={experience.get('title').concat(experience.get('position'))}
                        city={experience.get('city')}
                        state={experience.get('state')}
                        title={experience.get('title')}
                        subtitle={experience.get('company')}
                      />
                    );
                  })}
              </View>
            )}

            {resume.get('workExperience') &&
              resume
                .get('workExperience')
                .slice(1)
                .map((job) => {
                  const experience = job.get('experience');
                  return (
                    <ResumeEntry
                      entry={experience}
                      key={experience.get('title').concat(experience.get('position'))}
                      city={experience.get('city')}
                      state={experience.get('state')}
                      title={experience.get('title')}
                      subtitle={experience.get('company')}
                    />
                  );
                })}
          </Section>

          <Section>
            {resume.get('education').size > 0 && (
              <View wrap={false}>
                <Row>
                  <RowMargin />
                  <RowContent>
                    <SectionHeading>
                      <SectionHeadingText>Education</SectionHeadingText>
                    </SectionHeading>
                  </RowContent>
                </Row>

                {resume
                  .get('education')
                  .slice(0, 1)
                  .map((job) => {
                    const education = job.get('education');
                    return (
                      <ResumeEntry
                        entry={education}
                        icon={IconSchool}
                        key={education.get('school').concat(education.get('educationLevel'))}
                        title={education.get('school')}
                        subtitle={education.get('major')}
                        description={education.get('educationLevel')}
                      />
                    );
                  })}
              </View>
            )}

            {resume.get('education') &&
              resume.get('education').length > 1 &&
              resume
                .get('education')
                .slice(1)
                .map((job) => {
                  const education = job.get('education');
                  return (
                    <ResumeEntry
                      entry={education}
                      key={education.get('school').concat(education.get('educationLevel'))}
                      title={education.get('school')}
                      subtitle={education.get('major')}
                      description={education.get('educationLevel')}
                    />
                  );
                })}
          </Section>

          {(resume.get('certifications').size > 0 || resume.get('unions').size > 0) && (
            <Section>
              <View wrap={false}>
                <Row>
                  <RowMargin />
                  <RowContent>
                    <SectionHeading>
                      <SectionHeadingText>Certifications & Unions</SectionHeadingText>
                    </SectionHeading>
                  </RowContent>
                </Row>

                <Row>
                  <RowMargin>
                    <SectionIcon>
                      <Image src={IconCheck} />
                    </SectionIcon>
                    <SectionDates />
                  </RowMargin>
                  <RowContent>
                    {resume.get('certifications').size > 0 && (
                      <Entry wrap={false}>
                        <EntrySubHeading>Certifications</EntrySubHeading>
                        <UL>
                          {resume.get('certifications') &&
                            resume.get('certifications').map((certificate) => <Text key={certificate}>• {certificate.getIn(['certification', 'name'])}</Text>)}
                        </UL>
                      </Entry>
                    )}

                    {resume.get('unions').size > 0 && (
                      <Entry wrap={false}>
                        <EntrySubHeading>Unions</EntrySubHeading>
                        <UL>
                          {resume
                            .get('unions')
                            .map((union) => union?.getIn(['union', 'name']) || union)
                            .map((union) => (
                              <Text key={union}>• {union}</Text>
                            ))}
                        </UL>
                      </Entry>
                    )}
                  </RowContent>
                </Row>
              </View>
            </Section>
          )}

          {resume.get('skills').size > 0 && (
            <Section>
              <Row>
                <RowMargin />
                <RowContent>
                  <SectionHeading>
                    <SectionHeadingText>Skills</SectionHeadingText>
                  </SectionHeading>
                </RowContent>
              </Row>
              <RowContent>
                <Entry wrap={false}>
                  <Row>
                    <RowMargin>
                      <SectionIcon>
                        <Image src={IconEdit} />
                      </SectionIcon>
                    </RowMargin>

                    <RowContent>
                      <UL>
                        {resume
                          .get('skills')
                          .map((skill) => skill?.getIn(['skill', 'name']) || skill)
                          .slice(0, 1)
                          .map((skill) => (
                            <Text key={skill}>• {skill}</Text>
                          ))}

                        {resume
                          .get('skills')
                          .map((skill) => skill?.getIn(['skill', 'name']) || skill)
                          .slice(1)
                          .map((skill) => (
                            <Text key={skill}>• {skill}</Text>
                          ))}
                      </UL>
                    </RowContent>
                  </Row>
                </Entry>
              </RowContent>
            </Section>
          )}

          {resume.get('trainingPrograms').size > 0 && (
            <Section>
              <View wrap={false}>
                <Row>
                  <RowMargin />
                  <RowContent>
                    <SectionHeading>
                      <SectionHeadingText>Training Programs</SectionHeadingText>
                    </SectionHeading>
                  </RowContent>
                </Row>

                {resume
                  .get('trainingPrograms')
                  .slice(0, 1)
                  .map((trainingProgram) => {
                    const program = trainingProgram.get('program');
                    return (
                      <ResumeEntry
                        entry={program}
                        icon={IconStar}
                        key={`program-${program.get('title')}`}
                        title={program.get('title')}
                        city={program.get('city')}
                        state={program.get('state')}
                      />
                    );
                  })}
              </View>

              {resume
                .get('trainingPrograms')
                .slice(1)
                .map((trainingProgram) => {
                  const program = trainingProgram.get('program');
                  return (
                    <ResumeEntry
                      entry={program}
                      key={`program-${program.get('title')}`}
                      title={program.get('title')}
                      city={program.get('city')}
                      state={program.get('state')}
                    />
                  );
                })}
            </Section>
          )}
        </PdfViewStyles>
      </Page>
    </Document>
  );
};

PdfView.propTypes = {
  candidate: PropTypes.instanceOf(Iterable).isRequired,
  resume: PropTypes.instanceOf(Iterable).isRequired,
};

export default PdfView;
