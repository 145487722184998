import styled from 'styled-components';
import theme from '../../../common/theme';

const SettingsStyles = styled.div``;

export const Title = styled.h1`
  text-transform: uppercase;

  span {
    font-weight: bold;
  }
`;

export const SectionTitle = styled.h3`
  font-weight: bold;
  color: ${theme.color.primary};
`;

export const Divider = styled.div`
  border-bottom: solid 1px ${theme.color.primary};
`;

export const SupportText = styled.p`
  color: ${theme.color.subtleAction};
`;

export default SettingsStyles;
