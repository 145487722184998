import { connect } from 'react-redux';
import PathwayRowView from './pathway_row_view';
// import { getSystemEnumList } from '../../../../reducer';

const mapStateToProps = () => ({
  // pathwayTypes: getSystemEnumList(state, 'PathwayType').reduce((allTypes, type) => {
  //   allTypes[type.get('slug')] = type.get('label'); //eslint-disable-line
  //   return allTypes;
  // }, {}),
});

const mapDispatchToProps = {};

const PathwayRowContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayRowView);

export default PathwayRowContainer;
