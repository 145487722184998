import styled from 'styled-components';
import theme from '../../../../common/theme';

export const MilestoneCard = styled.div`
  padding: 36px;
  position: relative;
  margin: 20px;
  background-color: #f7f7f7;
  //box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const Step = styled.div`
  border-radius: 5px;
  background-color: white;
  margin: 15px 0px 45px;
  padding: 15px 50px 25px;
  text-align: center;

  .step-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .step-item {
    min-width: 300px;
    width: auto;
    padding: 5px 0px;
  }

  .step-arrow {
    color: #979797;
    margin: 7px 10px;
  }

  .step-info {
    display: flex;
    flex: 1;
    flex-wrap: no-wrap;
    justify-content: space-between;
  }

  .status-container {
    position: relative;
    width: 300px;
    padding: 5px 0px;
    display: flex;
    flex-wrap: no-wrap;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .status-container:before {
    content: '';
    position: absolute;
    border-left: solid 1px #d7d7d7;
    left: 50px;
    top: 5px;
    height: 100%;
  }
  .status-container img {
    position: absolute;
    left: 78px;
    top: 38px;
  }

  .status-container > .MuiFormControl-root {
    position: absolute;
    top: 4px;
  }

  @media ${theme.query.desktopMax} {
    .step-item {
      width: 100%;
    }
    .step-info {
      flex-wrap: wrap;
    }
    .status-container {
      width: auto;
    }
    .status-container:before {
      display: none;
    }
  }

  @media ${theme.query.tabletMax} {
    padding: 20px;
  }
`;

export const Banner = styled.div`
  position: absolute;
  top: 24px;
  left: -10px;
  background-color: ${theme.color.blueDark};
  color: ${theme.color.white};
  padding: 8px 16px;
  font-weight: 600;
  box-shadow: 2px 2px 5px ${theme.color.gray};
`;

export const Note = styled.div`
  margin-left: 18px;
  height: 100px;
  .note-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 18px;
  }

  .MuiFormLabel-root {
    float: left;
    margin-top: 12px;
  }

  .MuiTextField-root {
    margin-top: 0px !important;
  }

  .MuiInputBase-root {
    padding: 11px 10px;
  }

  .btn-container {
    display: flex;
    justify-content: end;
    margin-top: 4px;

    button {
      height: 22px;
      font-size: 12px;
    }
  }
`;
