import styled from 'styled-components';
import theme from '../../../common/theme';
// import theme from '../../../common/theme';

const CandidateSettingsStyles = styled.div`
  max-width: 900px;
  margin: 0 auto;

  .fields-2 {
    margin-bottom: ${theme.padding.small};

    @media ${theme.query.tabletMin} {
      display: flex;
      flex-direction: row;
    }

    > * {
      @media ${theme.query.tabletMin} {
        width: 50%;

        &:first-of-type {
          margin-right: ${theme.padding.xsmall} !important;
        }

        &:last-of-type {
          margin-left: ${theme.padding.xsmall} !important;
        }
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .email-validation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .email-validation svg {
    margin-right: 5px;
  }

  .email-validation small {
    margin-left: 5px;
  }
`;

export default CandidateSettingsStyles;
