import styled from 'styled-components';
import theme from '../../../common/theme';

const fontRatio = 0.01666666667;

const ResumeStyles = styled.div`
  --base-font-size: ${(props) => (props.width ? `${props.width * fontRatio}px` : '18px')};
  color: ${theme.color.gray};
  margin-top: 10px;
  font-size: var(--base-font-size);

  h1,
  h2,
  h3,
  h4 {
    margin: 0;
  }

  h3 {
    text-transform: none;
    letter-spacing: 0;
    cite {
      /* font-size: 14px; */
      font-size: var(--base-font-size);
      font-weight: normal;
    }
    span {
      color: #777;
      font-style: italic;
      font-weight: normal;
      text-transform: capitalize;
    }
  }

  h4 {
    height: calc(var(--base-font-size) * 2);
    vertical-align: baseline;
    line-height: calc(var(--base-font-size) * 2);

    + em {
      display: inline-block;
      height: calc(var(--base-font-size) * 2);
      vertical-align: baseline;
      line-height: calc(var(--base-font-size) * 2);
    }
  }

  cite {
    color: #ccc;
  }

  p,
  li {
    color: #777;
    font-size: var(--base-font-size);
    line-height: calc(var(--base-font-size) * 1.5);
    margin: 2px 0;
  }

  ul {
    padding-left: 15px;
  }

  .grid-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .share-wrapper {
    flex: 1;
    min-width: 250px;
    max-width: 400px;
  }

  .share-card {
    text-align: center;
    padding: 20px;
  }

  .share-card button {
    margin: 15px 0px 5px;
  }

  .paper-container {
    margin-right: 20px;
    padding: 40px;
    width: 900px;
    height: 1165px;
  }

  .resume-name {
    margin-top: 20px;
    font-family: 'Roboto', Helvetica, Arial, san-serif;
    font-size: 40px;
    line-height: 40px;
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 0;
    color: #555;
  }

  .resume-section-header {
    margin-top: 40px;
    margin-bottom: 20px;
    color: ${theme.color.grayDark};
  }

  .resume-section-header:after {
    content: '';
    display: block
    position: relative;
    bottom: -5px;
    left: 0px;
    height: 1px;
    width: 100px;
    background-color: #ccc;
  }

  .resume-skills {
    list-style: none;
    padding-left: 0px;
    li {
      margin: 10px 0;
    }
  }

  .contact-info {
    list-style: none;
    padding-left: 0px;
    li {
      display: flex;
      align-items: center;
      .MuiSvgIcon-root {
        margin-right: 10px;
        font-size: 15px;
      }
    }
  }

  .icon-col {
    width: 50px;
    padding-top: 3px;
  }

  .date-col {
    width: 75px;
    padding-top: 3px;
  }

  .skills-mobile {
    display: none;
  }

  @media only screen and (max-width: 960px) {
    .share-wrapper {
      max-width: none;
    }

    .row-heading .icon-col {
      width: 0px;
    }

    .row-heading .date-col {
      width: 0px;
    }

    .skills-mobile {
      display: block;
    }

    .skills-desktop {
      display: none;
    }
  }
`;

export default ResumeStyles;
