import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form/immutable';

const propTypes = {
  onRemoteSubmit: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const RemoteSubmitRender = ({ onRemoteSubmit, children }) => (
  <div type="submit" tabIndex={0} role="button" onClick={onRemoteSubmit} onKeyPress={onRemoteSubmit}>
    {children}
  </div>
);

const mapDispatchToProps = (dispatch, { formName }) => ({
  onRemoteSubmit: () => {
    dispatch(submit(formName));
  },
});

RemoteSubmitRender.propTypes = propTypes;
RemoteSubmitRender.defaultProps = defaultProps;

const RemoteSubmitWrapper = connect(null, mapDispatchToProps)(RemoteSubmitRender);

export default RemoteSubmitWrapper;
