import { client, tokenAuth } from './config';

const axios = client();

export const createPublicLink = (user, linkType, subjectId) =>
  axios.post('/v1/public_links', { publicLinkType: linkType, subjectId }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createExternalPublicLink = (user, publicLink) =>
  axios.post('/v1/public_links', { publicLink }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchInviteLinkForPreview = (user, id) => axios.get(`/v1/invitations/${id}`);
export const fetchPublicLinkForPreview = (user, id) => axios.get(`/v1/public_links/${id}`);

export const usePublicLink = (user, id) => axios.post(`/v1/public_links/${id}/use`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });
export const useInviteLink = (user, id) => axios.post(`/v1/invitations/${id}/accept`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });
