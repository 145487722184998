import { Map } from 'immutable';

export const generateSummaryReportDetails = (candidatePathways) => {
  const reportDetails = {};
  if (!candidatePathways.size) {
    return new Map(reportDetails);
  }
  const enrolledStates = ['c_in_progress', 'c_complete', 'c_dropped_voluntarily', 'c_dropped_involuntarily'];
  reportDetails.enrolled = candidatePathways.filter((pathway) => enrolledStates.includes(pathway.get('candidateState'))).size;
  const activeStates = ['c_in_progress', 'c_complete'];
  reportDetails.active = candidatePathways.filter((pathway) => activeStates.includes(pathway.get('candidateState'))).size;
  reportDetails.completed = candidatePathways.filter((pathway) => pathway.get('candidateState') === 'c_complete').size;
  reportDetails.overallRetention = reportDetails.active / reportDetails.enrolled || 0;
  reportDetails.adjustedRetention =
    reportDetails.active / (reportDetails.enrolled - candidatePathways.filter((pathway) => pathway.get('candidateState') === 'c_dropped_involuntarily').size) ||
    0;
  const droppedStates = ['c_dropped_involuntarily', 'c_dropped_voluntarily'];
  reportDetails.totalDropped = candidatePathways.filter((pathway) => droppedStates.includes(pathway.get('candidateState'))).size;
  reportDetails.involuntarilyDropped = candidatePathways.filter((pathway) => pathway.get('candidateState') === 'c_dropped_involuntarily').size;
  reportDetails.voluntarilyDropped = candidatePathways.filter((pathway) => pathway.get('candidateState') === 'c_dropped_involuntarily').size;
  return new Map(reportDetails);
};

const inAgeRange = (birthday, lowerRange, upperRange) => {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  const age = Math.abs(ageDate.getUTCFullYear() - 1970);
  return age >= lowerRange && age <= upperRange;
};

export const generateAgeReportDetails = (candidatePathways) => {
  const reportDetails = {};
  if (!candidatePathways.size) {
    return new Map(reportDetails);
  }
  const newCandidatePathways = candidatePathways.filter((pathway) => pathway.get('staffState') !== 's_transferred');

  reportDetails.range18To24 = newCandidatePathways.filter((pathway) => inAgeRange(new Date(pathway.getIn(['candidate', 'birthday'])), 18, 24)).size;
  reportDetails.range25To34 = newCandidatePathways.filter((pathway) => inAgeRange(new Date(pathway.getIn(['candidate', 'birthday'])), 25, 34)).size;
  reportDetails.range35To44 = newCandidatePathways.filter((pathway) => inAgeRange(new Date(pathway.getIn(['candidate', 'birthday'])), 35, 44)).size;
  reportDetails.range45To54 = newCandidatePathways.filter((pathway) => inAgeRange(new Date(pathway.getIn(['candidate', 'birthday'])), 45, 54)).size;
  reportDetails.range55To64 = newCandidatePathways.filter((pathway) => inAgeRange(new Date(pathway.getIn(['candidate', 'birthday'])), 55, 64)).size;
  reportDetails.over65 = newCandidatePathways.filter((pathway) => inAgeRange(new Date(pathway.getIn(['candidate', 'birthday'])), 65, 1000)).size;
  reportDetails.total =
    reportDetails.range18To24 +
    reportDetails.range25To34 +
    reportDetails.range35To44 +
    reportDetails.range45To54 +
    reportDetails.range55To64 +
    reportDetails.over65;
  return new Map(reportDetails);
};

export const generateEthnicityReportDetails = (candidatePathways) => {
  const reportDetails = {};
  if (!candidatePathways.size) {
    return new Map(reportDetails);
  }
  const newCandidatePathways = candidatePathways.filter((pathway) => pathway.get('staffState') !== 's_transferred');

  reportDetails.black = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'black').size;
  reportDetails.caucasian = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'caucasian').size;
  reportDetails.eastAsian = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'east-asian').size;
  reportDetails.latino = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'latino').size;
  reportDetails.middleEastern = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'middle-eastern').size;
  reportDetails.mixed = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'mixed').size;
  reportDetails.nativeAmerican = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'native-american').size;
  reportDetails.nativeIslander = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'native-islander').size;
  reportDetails.southAsian = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'south-asian').size;
  reportDetails.other = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'other').size;
  reportDetails.noShare = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'ethnicityType']) === 'no-share').size;
  reportDetails.total =
    reportDetails.black +
    reportDetails.caucasian +
    reportDetails.eastAsian +
    reportDetails.latino +
    reportDetails.middleEastern +
    reportDetails.mixed +
    reportDetails.nativeAmerican +
    reportDetails.nativeIslander +
    reportDetails.southAsian +
    reportDetails.other +
    reportDetails.noShare;
  return new Map(reportDetails);
};

export const generateGenderReportDetails = (candidatePathways) => {
  const reportDetails = {};
  if (!candidatePathways.size) {
    return new Map(reportDetails);
  }
  const newCandidatePathways = candidatePathways.filter((pathway) => pathway.get('staffState') !== 's_transferred');

  reportDetails.male = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'genderType']) === 'male').size;
  reportDetails.female = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'genderType']) === 'female').size;
  reportDetails.nonBinary = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'genderType']) === 'non-binary').size;
  reportDetails.other = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'genderType']) === 'other').size;
  reportDetails.noShare = newCandidatePathways.filter((pathway) => pathway.getIn(['candidate', 'genderType']) === 'no-share').size;
  reportDetails.total = reportDetails.male + reportDetails.female + reportDetails.nonBinary + reportDetails.other + reportDetails.noShare;
  return new Map(reportDetails);
};
