import { connect } from 'react-redux';
import ReviewFormView from './review_form_view';
import { formValueSelector } from '../../../../common/form';
import { getCurrentUser, getUserType } from '../../../../reducer';
// import { SomeActions } from '../../actions';

const selector = formValueSelector('jobForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })

// const selector = formValueSelector('jobForm');

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  userType: getUserType(state),
  draft: true,
  title: selector(state, 'title'),
  employer: selector(state, 'employer.name'),
  type: selector(state, 'jobType'),
  description: selector(state, 'description'),
  payType: selector(state, 'payType'),
  salary: selector(state, 'payAmount'),
  address: selector(state, 'address.address1'),
  city: selector(state, 'addressAttributes.city'),
  state: selector(state, 'addressAttributes.state'),
  zip: selector(state, 'addressAttributes.zip'),
  startDate: selector(state, 'startDate'),
  endDate: selector(state, 'endDate'),
  openings: selector(state, 'numberOfOpenings'),
  logo: selector(state, 'jobPostingLogo'),
  industryType: selector(state, 'industryType'),
  postExpirationDate: selector(state, 'postExpirationDate'),
  applicationUrl: selector(state, 'applicationUrl'),
  customFollowUpMessage: selector(state, 'customFollowUpMessage'),
  jobResponsibilities: selector(state, 'responsibilities'),
  requiredSkills: selector(state, 'requiredSkillTypes'),
  desiredSkills: selector(state, 'desiredSkillTypes'),
  tradeCertifications: selector(state, 'certifications'),
  desiredEducation: selector(state, 'desiredEducation'),
  benefits: selector(state, 'opportunityTypes') && selector(state, 'opportunityTypes').slice(0, 3),
  opportunities: selector(state, 'opportunityTypes') && selector(state, 'opportunityTypes').slice(3, 6),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const ReviewFormContainer = connect(mapStateToProps, mapDispatchToProps)(ReviewFormView);

export default ReviewFormContainer;
