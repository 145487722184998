import numeral from 'numeral';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import MoneyIcon from '@material-ui/icons/Money';
import { Link, Redirect } from 'react-router-dom';
import React, { useEffect, Fragment } from 'react';
import LocationIcon from '@material-ui/icons/Room';

import theme from '../../../common/theme';
import StatusToggler from '../status_toggler';
import { useFetchOnce } from '../../../util/hooks';
import JobDetailsStyles from './job_details_styles';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { IndeedLabel } from '../../../common/styles/detail';
import JobPostingModel from '../../../solve/models/job_posting_model';
import getListingImage from '../../../common/components/get_listing_image';

const propTypes = {
  canEdit: PropTypes.bool,
  isIndeed: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  publicLink: PropTypes.instanceOf(Map),
  fetchJobPosting: PropTypes.func.isRequired,
  closeJobPosting: PropTypes.func.isRequired,
  draftJobPosting: PropTypes.func.isRequired,
  createPublicLink: PropTypes.func.isRequired,
  publishJobPosting: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]),
};

const defaultProps = {
  job: undefined,
  publicLink: undefined,
  canEdit: false,
  isIndeed: false,
};

// const EMPTY_LIST = fromJS([]);

const formatPay = (type, amount) => {
  if (!numeral(amount).value()) {
    return 'Not Provided';
  }

  const formattedAmount = numeral(amount).format('$0,0');
  switch (type) {
    case 'hourly':
      return `${formattedAmount}/hour`;
    case 'salary':
      return `${formattedAmount}/year`;
    default:
      return `${formattedAmount} ${type}`;
  }
};

const JobDetailsView = ({
  publicLink,
  createPublicLink,
  jobId,
  job,
  fetchJobPosting,
  user,
  publishJobPosting,
  closeJobPosting,
  draftJobPosting,
  canEdit,
  isIndeed,
}) => {
  const resolved = useFetchOnce(user, jobId, fetchJobPosting);

  let benefits;
  if (resolved) {
    benefits =
      job?.get('opportunityTypes') &&
      job?.get('opportunityTypes').filter((value) => value === 'child-care' || value === 'health-care' || value === 'transportation');
  }

  useEffect(() => {
    createPublicLink(user, 'broadcast-job', jobId);
  }, [user, jobId, createPublicLink]);

  if (!job) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  const logoUrl = getListingImage(job, 'job');

  return (
    <JobDetailsStyles>
      &larr; <Link to="/staff/jobs">Back to Job List</Link> &nbsp; | &nbsp; Share link: &nbsp;
      {publicLink && publicLink.get('publicUrl')}
      <div style={{ height: 20 }} />
      <div className="logo-wrapper">
        <img className="logo" src={logoUrl} alt="Job Posting Logo" />
        {isIndeed && <IndeedLabel>Job by Indeed</IndeedLabel>}
      </div>
      {canEdit && (
        <StatusToggler
          user={user}
          listingId={jobId}
          currentState={job.get('state')}
          publish={publishJobPosting}
          close={closeJobPosting}
          save={draftJobPosting}
        />
      )}
      <h1>{job.get('title')}</h1>
      <h4>{job.getIn(['employer', 'name'])}</h4>
      <br />
      {/* <p style={{ color: theme.color.text.medium }}>
        {job
          .get('industries', EMPTY_LIST)
          .push(job.get('industryType'))
          .join(', ')}
      </p> */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
        <LocationIcon style={{ color: theme.color.positive, marginRight: 5 }} />
        <h4>
          {job.getIn(['address', 'city'])}, {job.getIn(['address', 'state'])}
        </h4>
        <MoneyIcon style={{ color: theme.color.positive, marginRight: 5, marginLeft: 10 }} />
        {job.get('payType') === 'no-share' ? <h4>Not Specified</h4> : <h4>{formatPay(job.get('payType'), job.get('payAmount'))}</h4>}
      </div>
      <Chip label={job.get('jobType')} />
      {/* TODO: Other Chips? */}
      <p style={{ marginTop: 30, marginBottom: 60 }}>
        <span dangerouslySetInnerHTML={{ __html: job.get('description') }} />
        {/* TODO: Add "View More" if long */}
      </p>
      {benefits && benefits.size > 0 && (
        <Fragment>
          <p>
            <span className="sub-heading">Benefits:</span>
          </p>
          <ul>
            {benefits.map((benefit) => (
              <li key={benefit}>{startCase(benefit)}</li>
            ))}
          </ul>
        </Fragment>
      )}
      <center>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
          {canEdit && (
            <Link to={`/staff/jobs/${job.get('id')}/candidates`}>
              <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action }}>
                View Applicants
              </Button>
            </Link>
          )}
          <Link to={`/staff/jobs/${job.get('id')}/invite`}>
            <Button variant="contained" color="primary" size="large" style={{ backgroundColor: theme.color.action }}>
              Invite Applicants
            </Button>
          </Link>
        </div>
      </center>
    </JobDetailsStyles>
  );
};

JobDetailsView.propTypes = propTypes;
JobDetailsView.defaultProps = defaultProps;

export default JobDetailsView;
