import styled from 'styled-components';
import theme from '../../../common/theme';

const ListingApplicantDetailsStyles = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background-color: #f2faff;
  align-items: center;
  h4 {
    margin: 0;
  }
  .candidate-info {
    display: flex;
  }
  .candidate-contact {
    display: flex;
    align-items: center;
    font-size: 0.8em;
    margin: 5px 0;
    color: #6c7a89;
  }
  .icon {
    font-size: 0.8em;
    margin-right: 8px;
  }

  .candidate-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .edit-candidate {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-left: 10px;
  }

  .edit-candidate svg {
    width: 18px;
    margin-right: 2px;
    margin-top: -2px;
  }

  @media ${theme.query.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default ListingApplicantDetailsStyles;
