import React from 'react';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

const propTypes = {
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const getPercentageValue = (statNum, totalNum) => Math.round((statNum / totalNum) * 100);

const AgeReportView = ({ reportDetails, pathwayInstance }) => {
  if (!reportDetails.size) {
    return null;
  }
  return (
    <tr key={pathwayInstance.get('id')}>
      <th>
        <Link to={`/staff/pathways/${pathwayInstance.get('id')}`}>{pathwayInstance.get('name')}</Link>
      </th>
      <td>
        {reportDetails.get('range18To24')}{' '}
        {reportDetails.get('range18To24') > 0 && <span>({getPercentageValue(reportDetails.get('range18To24'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('range25To34')}{' '}
        {reportDetails.get('range25To34') > 0 && <span>({getPercentageValue(reportDetails.get('range25To34'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('range35To44')}{' '}
        {reportDetails.get('range35To44') > 0 && <span>({getPercentageValue(reportDetails.get('range35To44'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('range45To54')}{' '}
        {reportDetails.get('range45To54') > 0 && <span>({getPercentageValue(reportDetails.get('range45To54'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('range55To64')}{' '}
        {reportDetails.get('range55To64') > 0 && <span>({getPercentageValue(reportDetails.get('range55To64'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('over65')}{' '}
        {reportDetails.get('over65') > 0 && <span>({getPercentageValue(reportDetails.get('over65'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>{reportDetails.get('total')}</td>
    </tr>
  );
};
AgeReportView.propTypes = propTypes;
AgeReportView.defaultProps = defaultProps;

export default AgeReportView;
