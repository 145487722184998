import { connect } from 'react-redux';
import JobCandidateApplicationsView from './job_candidate_applications_view';
import {
  getCurrentUser,
  getCandidateApplications,
  // getCandidateApplicationLoaded,
  getCandidatePrograms,
  getCandidateEvents,
  getCandidateProgramsFilters,
  getCandidateApplicationIds,
  getCandidateApplicationFilters,
  getCandidateEventsFilters,
  // getCandidateApplicationSearch,
  getCandidateProgramIdsForStaff,
  getCandidateEventIdsForStaff,
  // getCandidateApplicationLoaded,
  getCandidateProgramsLoaded,
  getCandidateEventsLoaded,
  getCandidateServiceIds,
  getCandidateServices,
  getCandidatePathways,
  getTreatment,
  getCandidateExternalApplicationIds,
  getIndeedJobPosting,
} from '../../../reducer';
import {
  listFilteredCandidatePrograms,
  listFilteredCandidateEvents,
  listFilteredCandidateApplications,
  listFilteredCandidateServices,
  fetchCandidatePathwaysForCandidate,
  setCandidateProgramsSearch,
  listExternalCandidateApplicationsForCandidate,
  listFilteredCandidateExternalApplications,
  fetchSavedIndeedJobPostingByIds,
} from '../../../solve/actions';

const mapStateToProps = (state, { candidateId }) => {
  const currentUser = getCurrentUser(state);
  const isStaff = !currentUser.getIn(['staffOrganization', 'isEmployer']);

  const candidateJobIds = getCandidateApplicationIds(state);
  const jobApplications = getCandidateApplications(state, candidateJobIds).toList();

  const candidateExternalJobIds = getCandidateExternalApplicationIds(state);
  const externalJobApplications = getCandidateApplications(state, candidateExternalJobIds).toList();
  const indeedJobIdsToBeFetched = externalJobApplications?.toJS().map(({ externalJobPosting }) => externalJobPosting.externalId);

  const candidateProgramIds = getCandidateProgramIdsForStaff(state);
  const programApplications = getCandidatePrograms(state, candidateProgramIds).toList();

  const candidateEventIds = getCandidateEventIdsForStaff(state);
  const eventApplications = getCandidateEvents(state, candidateEventIds).toList();

  const candidateServiceIds = getCandidateServiceIds(state);
  const services = getCandidateServices(state, candidateServiceIds);

  const pathways = getCandidatePathways(state);

  const jobs = jobApplications.filter(
    (job) =>
      isStaff ||
      job.getIn(['helpingOrganization', 'id']) === currentUser.getIn(['staffOrganization', 'id']) ||
      job.getIn(['jobPosting', 'jobPostingEmployer', 'id']) === currentUser.getIn(['staffOrganization', 'id']),
  );

  const externalJobs = externalJobApplications.map((job) => {
    const jobId = job.getIn(['externalJobPosting', 'externalId']);
    const jobPosting = getIndeedJobPosting(state, jobId)?.toJS();
    const externalJobPosting = job.get('externalJobPosting')?.toJS();

    return job.merge({ externalJobPosting: { ...externalJobPosting, ...jobPosting } });
  });

  const programs = programApplications.filter(
    (program) => isStaff || program.getIn(['helpingOrganization', 'id']) === currentUser.getIn(['staffOrganization', 'id']),
  );

  const events = eventApplications.filter((event) => isStaff || event.getIn(['helpingOrganization', 'id']) === currentUser.getIn(['staffOrganization', 'id']));

  return {
    currentUser,
    candidateId,
    jobs,
    externalJobs,
    programs,
    events,
    services,
    pathways,
    // jobsLoaded: getCandidateApplicationLoaded(state),
    programsLoaded: getCandidateProgramsLoaded(state),
    eventsLoaded: getCandidateEventsLoaded(state),
    programFilters: getCandidateProgramsFilters(state),
    eventFilters: getCandidateEventsFilters(state),
    jobFilters: getCandidateApplicationFilters(state),
    showPathways: !!getTreatment(state, 'showPathways'),
    showSupportiveServices: !!getTreatment(state, 'showSupportiveServices'),
    indeedJobIdsToBeFetched,
  };
};

const mapDispatchToProps = {
  listFilteredCandidatePrograms,
  listFilteredCandidateEvents,
  listFilteredCandidateApplications,
  listFilteredCandidateServices,
  fetchCandidatePathwaysForCandidate,
  setSearch: setCandidateProgramsSearch,
  listExternalCandidateApplicationsForCandidate,
  listFilteredCandidateExternalApplications,
  fetchSavedIndeedJobPostingByIds,
};

const JobCandidateApplicationsContainer = connect(mapStateToProps, mapDispatchToProps)(JobCandidateApplicationsView);

export default JobCandidateApplicationsContainer;
