import { connect } from 'react-redux';
import PathwayCandidatesView from './pathway_candidates_view';
import { getCurrentUser, getCandidatePathways, getSystemEnumList } from '../../../../reducer';
import {
  updateCandidatePathway,
  updateCandidatePathways,
  transitionCandidatePathwayStaffState,
  listCandidatePathwaysForPathwayInstanceByPage,
} from '../../../../solve/actions';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  pathwayCandidates: getCandidatePathways(state),
  pathwayDroppedReasonTypes: getSystemEnumList(state, 'PathwayDroppedReasonType').map((item) => [item.get('slug'), item.get('label')]),
});

const mapDispatchToProps = {
  listCandidatePathwaysForPathwayInstanceByPage,
  transitionCandidatePathwayStaffState,
  updateCandidatePathway,
  updateCandidatePathways,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onUpdate: (candidatePathwayId, props) => dispatchProps.updateCandidatePathway(stateProps.user, candidatePathwayId, props),
  ...stateProps,
  ...ownProps,
  ...dispatchProps,
});

const PathwayCandidatesContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PathwayCandidatesView);

export default PathwayCandidatesContainer;
