import styled from 'styled-components';
import theme from '../../../common/theme';

const MyStatusStyles = styled.div`
  z-index: 10;
  margin-top: -20px;

  @media ${theme.query.tabletMax} {
    position: relative;
    right: auto;
  }
`;

export default MyStatusStyles;
