import { connect } from 'react-redux';
import ListingInvitationRowView from './listing_invitation_row_view';
import { getCurrentUser } from '../../../reducer';
import { resendInvitation } from '../../../solve/actions';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = { resendInvitation };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const ListingInvitationRowContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListingInvitationRowView);

export default ListingInvitationRowContainer;
