import styled from 'styled-components';
// import theme from '../../../common/theme';

const ApplicationListItemStyles = styled.div`
  background-color: #f7f7f7;
  padding: 20px;
  margin-bottom: 10px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 20px;
  font-size: 0.8em;
  p,
  h4,
  h2 {
    margin: 0px;
  }
  button {
    width: 100%;
    &:first-child {
      margin-bottom: 5px;
    }
  }
  .applicant {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-right: 10%;
  }
  .table-entry {
    padding: 0px 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #fff;
`;

export const Statuses = styled.div`
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  margin-right: 10px;
`;

export const Status = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  padding-right: 20px;
  color: ${(props) => props.color || '#ccc'};
  .MuiSvgIcon-root {
    margin-right: 10px;
  }
`;

export const Actions = styled.div`
  justify-self: flex-end;
  align-items: bottom;
  text-align: right;
  color: #b8c0bc;
  button {
    margin-left: 10px;
  }
  .MuiButtonGroup-root {
    /* background-color: #fff; */
    /* border: solid 1px #E4E9F2; */
    /* border-radius: 25px; */
    /* box-shadow: 0px 5px 5px rgba(0,0,0,.03); */
  }
  p {
    margin: 3px 0px 0px 0px;
    color: #c0c0c0;
    font-size: 0.7em;
  }
`;

export default ApplicationListItemStyles;
