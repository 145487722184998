import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { startCase } from 'lodash';
import { Link, Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import theme from '../../../common/theme';
import ServiceStyles, { MetaLabel, MetaValue } from './service_styles';

import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { Text } from '../../../common/components/text';
import formatPhoneNumber from '../../../util/format_phone_number';
import StatusToggler from '../status_toggler';

const propTypes = {
  serviceId: PropTypes.string.isRequired,
  fetchService: PropTypes.func.isRequired,
  fetchAbService: PropTypes.func.isRequired,
  service: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(UserModel).isRequired,
  canEdit: PropTypes.bool,
  createPublicLink: PropTypes.func.isRequired,
  publicLink: PropTypes.instanceOf(Map),
};

const defaultProps = {
  service: undefined,
  publicLink: undefined,
  canEdit: false,
};

const ServiceView = ({ createPublicLink, publicLink, serviceId, user, canEdit, service, fetchService, fetchAbService }) => {
  const isAbService = !!service.get('attributeTags');

  const resolved = useFetchOnce(user, serviceId, !isAbService ? fetchService : fetchAbService);

  useEffect(() => {
    // createPublicLink(user, 'broadcast-service', serviceId);
  }, [user, serviceId, createPublicLink]);

  if (!service) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  let logoUrl;
  logoUrl = service.get('logoUrl');
  if (!logoUrl) {
    logoUrl = service.getIn(['postingOrganization', 'logoUrl']);
  }
  if (!logoUrl) {
    logoUrl = '/images/solve-services.png';
  }

  const renderLocation = () => {
    if (!isAbService) {
      return (
        <React.Fragment>
          <MetaLabel>Location</MetaLabel>
          <MetaValue>{service.getIn(['address', 'address1'])}</MetaValue>
          <MetaValue>{service.getIn(['address', 'address2']) && service.getIn(['address', 'address2'])}</MetaValue>
          <MetaValue>{`${service.getIn(['address', 'city'])}, ${service.getIn(['address', 'state'])} `}</MetaValue>
        </React.Fragment>
      );
    }

    const addresses = service.get('offices').map((office, idx) => (
      <React.Fragment key={idx}>
        <MetaValue>{office.getIn(['address1'])}</MetaValue>
        <MetaValue>{`${office.getIn(['city'])}, ${office.getIn(['state'])} `}</MetaValue>
        <hr />
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        <MetaLabel>Location</MetaLabel>
        {addresses}
      </React.Fragment>
    );
  };

  const renderPhoneNumber = () => {
    if (!isAbService) {
      return (
        <React.Fragment>
          <MetaLabel>Phone</MetaLabel>
          <MetaValue>{formatPhoneNumber(service.get('phone'))}</MetaValue>
        </React.Fragment>
      );
    }

    const phoneNumbers = service.get('offices').map((office, idx) => (
      <React.Fragment key={idx}>
        <MetaValue>{formatPhoneNumber(office.get('phoneNumber'))}</MetaValue>
      </React.Fragment>
    ));

    return (
      <React.Fragment>
        <MetaLabel>Phone</MetaLabel>
        <MetaValue>{phoneNumbers}</MetaValue>
      </React.Fragment>
    );
  };

  return (
    <ServiceStyles>
      <Link to="/staff/resources">
        <Button color="primary" size="small" startIcon={<KeyboardBackspaceIcon />}>
          Back to resources
        </Button>
      </Link>
      {canEdit && (
        <span>
          {' '}
          |{' '}
          <Link to={`/staff/services/${serviceId}/edit`}>
            <Button color="primary" size="small">
              Edit service
            </Button>
          </Link>
        </span>
      )}
      &nbsp; | &nbsp; Share link: &nbsp;
      {publicLink && publicLink.get('publicUrl')}
      {canEdit && <StatusToggler user={user} listingId={serviceId} currentState={service.get('state')} />}
      <hr />
      <div style={{ maxWidth: 800, margin: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            {service.get('logoUrl') && <img style={{width: 40, height: 40}} src={service.get('logoUrl')} className="service-image" alt="service Logo" />}
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'right' }}>
            {canEdit && (
              <Link to={`/staff/services/${serviceId}/participants`}>
                <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action, marginBottom: 10, width: 200 }}>
                  Participants
                </Button>
              </Link>
            )}
            <br />
          </Grid>
        </Grid>
        <hr />
        <h2 style={{ margin: 0 }}>{service.get('name')}</h2>
        <em>{startCase(service.get('primaryServiceType'))}</em>
        <div style={{ height: 30 }} />

        <h3>Service Description</h3>
        <Text size="small" className="description" dangerouslySetInnerHTML={{ __html: service.get('description') }} />

        <div style={{ height: 40 }} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MetaLabel>Hosting Organization</MetaLabel>
            <MetaValue>{service.getIn(['postingOrganization', 'name'])}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Date & Time</MetaLabel>
            <MetaValue>{moment(service.get('serviceDate')).format('dddd, MMMM Do YYYY')}</MetaValue>
            {service.get('startTime') && <MetaValue>{`Starts: ${service.get('startTime')}`}</MetaValue>}
            {service.get('endTime') && <MetaValue>{`Ends: ${service.get('endTime')}`}</MetaValue>}
          </Grid>
          <Grid item xs={6}>
            {renderLocation()}
          </Grid>
          <Grid item xs={6}>
            {renderPhoneNumber()}
          </Grid>
          {service.get('industryType') && (
            <Grid item xs={6}>
              <MetaLabel>Industry</MetaLabel>
              <MetaValue>{startCase(service.get('industryType'))}</MetaValue>
            </Grid>
          )}
          <Grid item xs={6}>
            {service && service.get('gainedCertifications') && service.get('gainedCertifications').size > 0 && (
              <div className="meta-group">
                <MetaLabel>Certifications</MetaLabel>
                {service.get('gainedCertifications').map((certification) => (
                  <MetaValue key={certification}>{certification}</MetaValue>
                ))}
              </div>
            )}
          </Grid>
        </Grid>

        <div style={{ height: 40 }} />

        {/* NOTE: Will need the api to return description for the hosting organization if we want to include that here */}
        {/* <h3>{`Learn More About ${service.getIn(['postingOrganization', 'name'])}`}</h3>
      <p>
        Description Placeholder
      </p> */}

        <div style={{ height: 40 }} />
      </div>
    </ServiceStyles>
  );
};

ServiceView.propTypes = propTypes;
ServiceView.defaultProps = defaultProps;

export default ServiceView;
