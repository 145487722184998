import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();
const EMPTY_MAP = fromJS({});

const DEFAULT_STATE = fromJS({
  programsLoading: false,
  programsLoaded: 0,
  programsList: EMPTY_SET,
  filters: EMPTY_MAP,
  distance: '15-miles',
  zipCode: undefined,
  candidateProgramsLoading: false,
  candidateProgramsLoaded: 0,
  savedList: EMPTY_SET,
  appliedList: EMPTY_SET,
  candidateProgramsList: EMPTY_SET,
  page: 0,
  sortBy: 'distance',
});

const CandidateProgramListingsReducer = (state = DEFAULT_STATE, action) => {
  let candidateProgramsList;
  let newState;
  let programId;

  switch (action.type) {
    case ACTIONS.SET_CANDIDATE_PROGRAMS_ZIP_CODE:
      if (state.get('zipCode') !== action.zipCode) {
        return state.merge({
          programsLoaded: false,
          zipCode: action.zipCode,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          programsLoaded: false,
          candidateProgramsLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_PAGE:
      if (state.get('page') !== action.page) {
        return state.merge({
          page: action.page,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_SORT_BY:
      if (state.get('sortBy') !== action.sortBy) {
        return state.merge({
          sortBy: action.sortBy,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_DISTANCE:
      if (state.get('distance') !== action.distance) {
        return state.merge({
          distance: action.distance,
          programsLoaded: false,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          programsLoaded: false,
          candidateProgramsLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_PROGRAMS_FOR_CANDIDATE.request:
      return state.set('programsLoading', true);
    case ACTIONS.LIST_FILTERED_PROGRAMS_FOR_CANDIDATE.success:
      return state.merge({
        programsList: OrderedSet(action.response.result),
        programsLoading: false,
        programsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_PROGRAMS_FOR_CANDIDATE.failure:
      return state.merge({
        programsLoading: false,
        programsLoaded: false,
        programsList: EMPTY_SET,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATE_PROGRAMS_FOR_CANDIDATE.request:
      return state.set('candidateProgramsLoading', true);
    case ACTIONS.LIST_FILTERED_CANDIDATE_PROGRAMS_FOR_CANDIDATE.success:
      candidateProgramsList = OrderedSet(action.response.result).map((id) => action.response.entities.candidatePrograms[id]);
      return state.merge({
        savedList: candidateProgramsList.filter((app) => app.candidateState === 'c_saved').map((app) => app.program.programId),
        appliedList: candidateProgramsList.filter((app) => !['c_saved', 'c_deleted'].includes(app.candidateState)).map((app) => app.program.programId),
        candidateProgramsLoading: false,
        candidateProgramsLoaded: Date.now(),
        candidateProgramsList: candidateProgramsList.map((app) => app.id),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATE_PROGRAMS_FOR_CANDIDATE.failure:
      return state.merge({
        candidateProgramsLoading: false,
        candidateProgramsLoaded: false,
        savedList: EMPTY_SET,
        appliedList: EMPTY_SET,
      });
    case ACTIONS.CREATE_CANDIDATE_PROGRAM.success:
      programId = action.response.entities.candidatePrograms[action.response.result].program.programId;
      return state.update('savedList', (saved) => saved.add(programId));
    case ACTIONS.TRANSITION_CANDIDATE_PROGRAM_C.success:
      newState = action.response.entities.candidatePrograms[action.response.result].candidateState;
      programId = action.response.entities.candidatePrograms[action.response.result].program.programId;
      if (newState === 'c_saved') {
        return state.merge({
          savedList: state.get('savedList').add(programId),
          appliedList: state.get('appliedList').remove(programId),
        });
      }
      return state.merge({
        savedList: state.get('savedList').remove(programId),
        appliedList: state.get('appliedList').add(programId),
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getZipCode = (state) => state.get('zipCode');
export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getDistance = (state) => state.get('distance') || '';
export const getPage = (state) => state.get('page') || DEFAULT_STATE.get('page');
export const getSortBy = (state) => state.get('sortBy') || DEFAULT_STATE.get('sortBy');
export const getSearch = (state) => state.get('search');
export const getLoading = (state) => state.get('programsLoading');
export const getLoaded = (state) => state.get('programsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getProgramIds = (state) => state.get('programsList') || DEFAULT_STATE.get('programsList');

export const getSavedIds = (state) => state.get('savedList') || DEFAULT_STATE.get('savedList');
export const getSavedLoaded = (state) => state.get('candidateProgramsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getAppliedIds = (state) => state.get('appliedList') || DEFAULT_STATE.get('appliedList');
export const getAppliedLoaded = (state) => state.get('candidateProgramsLoaded') + RELOAD_TIMEOUT > Date.now();
// export const getServiceIds = (state) => state.get('servicesList') || DEFAULT_STATE.get('servicesList');
// export const getServicesLoaded = (state) => state.get('servicesLoaded') + RELOAD_TIMEOUT > Date.now();

export const getCandidateProgramIds = (state) => state.get('candidateProgramsList');

export default CandidateProgramListingsReducer;
