import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import theme from '../../../../common/theme';
import IndeedJobListRowStyles from './indeed_job_list_row_styles';
import { IndeedLabel } from '../../../../common/styles/detail';
import IndeedJobPostingModel from '../../../../solve/models/indeed_job_posting_model';
import getListingImage from '../../../../common/components/get_listing_image';

const propTypes = {
  job: PropTypes.oneOfType([PropTypes.instanceOf(IndeedJobPostingModel), PropTypes.instanceOf(Map)]).isRequired,
  showDrafts: PropTypes.bool.isRequired,
  isIndeed: PropTypes.bool,
};

const defaultProps = {
  isIndeed: false,
};

const IndeedJobListRowView = ({ job, showDrafts, isIndeed }) => {
  const isDraft = job.get('state') === 'draft';
  if (showDrafts !== isDraft) {
    return null;
  }
  const logoUrl = getListingImage(job, 'job');

  const jobDistance = Math.round(parseFloat(job.get('distance')) * 10) / 10;

  return (
    <IndeedJobListRowStyles>
      <td>
        <img src={logoUrl} className="job-logo" alt="Job Posting Logo" style={{ margin: 12 }} />
      </td>
      <td>
        <h4>{job.get('jobtitle')}</h4>
        <span>
          {job.get('city')}, {job.get('state')}
        </span>
        {isIndeed && <IndeedLabel>Job by Indeed</IndeedLabel>}
      </td>
      <td>
        <a href="https://www.indeed.com/" rell="nofollow">
          jobs
        </a>{' '}
        by{' '}
        <a href="https://www.indeed.com/" rell="nofollow" title="Job Search">
          <img src="https://www.indeed.com/p/jobsearch.gif" style={{ border: 0, verticalAlign: 'middle' }} alt="Indeed job search" />
        </a>
      </td>
      {!!jobDistance && <td>{jobDistance} miles</td>}
      <td>Not Specified</td>
      <td />
      <td />
      <td>
        <Link to={`/staff/indeed-jobs/${job.get('jobkey')}/details`}>
          <Button variant="contained" color="secondary" size="small" style={{ backgroundColor: theme.color.secondary }}>
            View
          </Button>
        </Link>
      </td>
    </IndeedJobListRowStyles>
  );
};

IndeedJobListRowView.propTypes = propTypes;
IndeedJobListRowView.defaultProps = defaultProps;

export default IndeedJobListRowView;
