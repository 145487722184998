import { makeStyles } from '@material-ui/core/styles';
import LaunchIcon from '@material-ui/icons/Launch';
import Verified from '@material-ui/icons/VerifiedUserOutlined';
import Unverified from '@material-ui/icons/VerifiedUser';
import PropTypes from 'prop-types';
import React from 'react';

const resumeLinkStyles = makeStyles({
  link: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
  },
  icon: {
    width: '0.5em',
    paddingLeft: 2,
  },
});

// eslint-disable-next-line import/prefer-default-export
export const EditCandidateResumeLinkField = ({ label, source, record = {} }) => {
  const classes = resumeLinkStyles();
  return (
    <a href={`/x/candidate/${record[source]}/resume`} target="_blank" rel="noopener noreferrer" className={classes.link}>
      {label}
      <LaunchIcon className={classes.icon} />
    </a>
  );
};
EditCandidateResumeLinkField.propTypes = { source: PropTypes.string.isRequired, label: PropTypes.string, record: PropTypes.shape({}).isRequired };
EditCandidateResumeLinkField.defaultProps = { label: 'Edit Resume' };

export const IsVerifiedField = ({ source, record = {} }) => {
  const isVerified = record[`${source}ValidatedAt`];
  return (
    <span>
      {record[source]} {isVerified ? <Verified color="primary" titleAccess={new Date(isVerified).toLocaleDateString()} /> : <Unverified color="error" />}
    </span>
  );
};
IsVerifiedField.propTypes = { source: PropTypes.string.isRequired, record: PropTypes.shape({}).isRequired };
