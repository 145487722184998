import { createSelector } from 'reselect';
import { fromJS, OrderedSet, Map } from 'immutable';
import { reducer as FormReducer } from 'redux-form/immutable';
import { connectRouter } from 'connected-react-router/immutable';

import { extractCandidateFullName } from 'util/common';

import { combineImmutableReducers } from './util';
import ErrorReducer, * as ErrorSelect from './errors/reducer';
import EnumReducer, * as EnumSelectors from './enums/reducer';
import SolveReducer, * as SolveSelectors from './solve/reducer';
import SessionReducer, * as SessionSelect from './session/reducer';
import OneShotReducer, * as OneShotSelectors from './oneshot/reducer';
import OnboardingReducer, * as OnboardingSelectors from './onboarding/reducer';
import PublicLinkReducer, * as PublicLinkSelectors from './public_link/reducer';
import HubSpotFormReducer, { getContactFormStatus } from './hub_spot_form/reducer';
import GlobalSettingsReducer, * as GlobalSettingsSelectors from './global_settings/reducer';

const baseReducer = (history) =>
  combineImmutableReducers({
    router: connectRouter(history),
    form: FormReducer,
    session: SessionReducer,
    solve: SolveReducer,
    globalSettings: GlobalSettingsReducer,
    oneShot: OneShotReducer,
    hubSpotForm: HubSpotFormReducer,
    onboard: OnboardingReducer,
    enum: EnumReducer,
    errors: ErrorReducer,
    publicLink: PublicLinkReducer,
  });

const Reducer = (history) => {
  const wrapped = baseReducer(history);
  return (state, action) => {
    if (action?.type === 'SESSION:SIGN_OUT') {
      return wrapped(undefined, { type: '@@INIT' });
    }
    return wrapped(state, action);
  };
};

export default Reducer;

export const getLocation = (state) => state.getIn(['router', 'location', 'pathname']);

// HubSpot Selectors
export const getHubSpotContactFormStatus = (state) => getContactFormStatus(state.get('hubSpotForm'));

// Global Settings
export const getGlobalSetting = (state, key, notSetValue = undefined) => GlobalSettingsSelectors.getSetting(state.get('globalSettings'), key, notSetValue);

// Session
export const getCurrentUser = (state) => SessionSelect.getCurrentUser(state.get('session'));
export const getTreatment = (state, experiment, defaultTreatment) => SessionSelect.getTreatment(state.get('session'), experiment, defaultTreatment);

// Errors
export const getFlashList = (state) => ErrorSelect.getFlashList(state.get('errors'));
export const getApiError = (state, action) => ErrorSelect.getApiError(state.get('errors'), action);

// Solve
export const getSolveFormStatus = (state) => SolveSelectors.getFormStatus(state.get('solve'));

export const getCandidateOrganizations = (state) => SolveSelectors.getCandidateOrganizations(state.get('solve'));
export const getCandidateOrganization = (state, id) => SolveSelectors.getCandidateOrganization(state.get('solve'), id);

export const getCandidateApplications = (state, list) => SolveSelectors.getCandidateApplications(state.get('solve'), list);

export const getCandidateApplicationForJobPosting = (state, jobPostingId) =>
  SolveSelectors.getCandidateApplicationForJobPosting(state.get('solve'), jobPostingId);

export const getStaffCandidateApplicationForJobPosting = (state, jobPostingId) =>
  SolveSelectors.getStaffCandidateApplicationForJobPosting(state.get('solve'), jobPostingId);

export const getStaffCandidateApplicationForJobPostingIds = createSelector(getStaffCandidateApplicationForJobPosting, (applications) =>
  applications.map((application) => application.get('id')),
);

export const getCandidateApplication = (state, id) => SolveSelectors.getCandidateApplication(state.get('solve'), id);

export const getCandidateApplicationLoaded = (state) => SolveSelectors.getCandidateApplicationLoaded(state.get('solve'));
export const getCandidateApplicationIds = (state) => SolveSelectors.getCandidateApplicationIds(state.get('solve'));
export const getCandidateApplicationFilters = (state) => SolveSelectors.getCandidateApplicationFilters(state.get('solve'));
export const getCandidateApplicationSearch = (state) => SolveSelectors.getCandidateApplicationSearch(state.get('solve'));

export const getCandidateExternalApplicationIds = (state) => SolveSelectors.getCandidateExternalApplicationIds(state.get('solve'));

export const getCandidateSurveys = (state, list) => SolveSelectors.getCandidateSurveys(state.get('solve'), list);
export const getCandidateSurvey = (state, id) => SolveSelectors.getCandidateSurvey(state.get('solve'), id);
export const getCandidateSurveyForSurvey = (state, surveyId) => getCandidateSurveys(state).find((x) => x.getIn(['survey', 'id']) === surveyId);
export const getCandidateSurveysForStaff = (state, candidateId) => SolveSelectors.getCandidateSurveysForStaff(state.get('solve'), candidateId);

export const getIncompleteCandidateSurveys = createSelector(getCandidateSurveys, (candidateSurveys) =>
  candidateSurveys.filter((survey) => survey.get('numberOfQuestions') !== survey.get('answered')),
);

export const getCandidateQuestionnairesCompletionPercentage = createSelector(getCandidateSurveys, (allCandidateSurveys) => {
  if (!allCandidateSurveys) return 0;
  const questionnaireSurveys = allCandidateSurveys.filter(
    (survey) => survey.getIn(['survey', 'surveyType']) === 'challenges' || survey.getIn(['survey', 'surveyType']) === 'readiness',
  );
  const questionnaireSurveysQuestions = questionnaireSurveys.map((survey) => survey.get('numberOfQuestions')).reduce((a, b) => a + b, 0);
  const questionnaireSurveysAnswered = questionnaireSurveys.map((survey) => survey.get('answered')).reduce((a, b) => a + b, 0);
  const result = Math.ceil((questionnaireSurveysAnswered / questionnaireSurveysQuestions) * 100);
  if (Number.isNaN(result)) {
    return 0;
  }
  return result;
});

export const getJobPostings = (state) => SolveSelectors.getJobPostings(state.get('solve'));
export const getJobPosting = (state, id) => SolveSelectors.getJobPosting(state.get('solve'), id);
export const getIndeedJobPosting = (state, id) => SolveSelectors.getIndeedJobPosting(state.get('solve'), id);
export const getJobPostingsByOrg = createSelector([getJobPostings, getCurrentUser], (jobs, user) =>
  jobs.filter((job) => user.getIn(['staffOrganization', 'id']) === job.getIn(['postingOrganization', 'id'])),
);
export const getJobPostingsDrafts = createSelector(getJobPostings, (jobs) => jobs.filter((job) => job.get('state') === 'pre_published'));

export const getOrganizationsLoaded = (state) => SolveSelectors.getOrganizationsLoaded(state.get('solve'));
export const getOrganizations = (state) => SolveSelectors.getOrganizations(state.get('solve'));
export const getOrganization = (state, id) => SolveSelectors.getOrganization(state.get('solve'), id);

export const getChildOrganizations = (state, organizationId) => SolveSelectors.getChildOrganizations(state.get('solve'), organizationId);

export const getInmate = (state, candidateId) => SolveSelectors.getInmate(state.get('solve'), candidateId);

export const getResumes = (state) => SolveSelectors.getResumes(state.get('solve'));
export const getResume = (state, id) => SolveSelectors.getResume(state.get('solve'), id);
export const getCandidateResumeForCandidate = (state, candidateId) => SolveSelectors.getCandidateResumeForCandidate(state.get('solve'), candidateId);
export const getCandidateResumesForStaff = (state, candidateId) => SolveSelectors.getCandidateResumesForStaff(state.get('solve'), candidateId);

const checkNonEmpty = (value) => value?.size && !(typeof value.get(0) === 'object' && value.get(0).size === 0);

const resumePercentageCalculation = (resumeValues) => Math.ceil((resumeValues.filter(checkNonEmpty).length / 7) * 100);

// Return percentage completion for the user ( 0-100 )
export const getCandidateResumeCompletionPercentage = createSelector(getCandidateResumeForCandidate, (resume) => {
  if (!resume) return 0;
  const candidateResume = resume.get('candidatesVersionOfResume');

  if (candidateResume.get('unsaved')) {
    // If they haven't saved the changes, do not let them move on.
    return 0;
  }

  const resumeValues = [
    candidateResume.get('workExperience'),
    candidateResume.get('skills'),
    candidateResume.get('industries'),
    candidateResume.get('unions'),
    candidateResume.get('certifications'),
    candidateResume.get('education'),
    candidateResume.get('trainingPrograms'),
  ];

  return resumePercentageCalculation(resumeValues);
});

export const getChallenges = (state) => SolveSelectors.getChallenges(state.get('solve'));
export const getChallenge = (state, id) => SolveSelectors.getChallenge(state.get('solve'), id);

export const getCandidateUnresolvedChallenges = createSelector(getChallenges, (challenges) =>
  challenges.filter((challenge) => challenge.get('candidateState') === 'c_unresolved'),
);

export const getSurveys = (state) => SolveSelectors.getSurveys(state.get('solve'));
export const getSurvey = (state, id) => SolveSelectors.getSurvey(state.get('solve'), id);

export const getPrograms = (state, list) => SolveSelectors.getPrograms(state.get('solve'), list);
export const getServices = (state, list) => SolveSelectors.getServices(state.get('solve'), list);

// export const getAllPrograms = (state) => state.getIn(['solve', 'programs', 'byIds']);
// export const getDraftPrograms = createSelector(getAllPrograms, (programs) => programs.filter((program) => program.get('state') === 'pre_published'));

export const getProgram = (state, id) => SolveSelectors.getProgram(state.get('solve'), id);
export const getService = (state, id) => SolveSelectors.getService(state.get('solve'), id);

export const getEvents = (state, list) => SolveSelectors.getEvents(state.get('solve'), list);
export const getEvent = (state, id) => SolveSelectors.getEvent(state.get('solve'), id);

export const getInvitations = (state, list) => SolveSelectors.getInvitations(state.get('solve'), list);
export const getAllInvitationsForListing = (state, list) => SolveSelectors.getAllInvitationsForListing(state.get('solve'), list);
export const getInvitation = (state, id) => SolveSelectors.getInvitation(state.get('solve'), id);
export const getStaffInvitationIds = (state) => SolveSelectors.getStaffInvitationIds(state.get('solve'));
export const getStaffInvitationsTotal = (state) => SolveSelectors.getStaffInvitationsTotal(state.get('solve'));
// export const getStaffInvitationFilters = (state) => SolveSelectors.getStaffInvitationFilters(state.get('solve'));
// export const getStaffInvitationSearch = (state) => SolveSelectors.getStaffInvitationSearch(state.get('solve'));

export const getPathways = (state) => SolveSelectors.getPathways(state.get('solve'));
export const getPathway = (state, id) => SolveSelectors.getPathway(state.get('solve'), id);
export const getPathwayInstances = (state) => SolveSelectors.getPathwayInstances(state.get('solve'));
export const getPathwayInstance = (state, id) => SolveSelectors.getPathwayInstance(state.get('solve'), id);
export const getCandidatePathways = (state) => SolveSelectors.getCandidatePathways(state.get('solve'));
export const getCandidatePathway = (state, id) => SolveSelectors.getCandidatePathway(state.get('solve'), id);
export const getCandidatePathwaysForPathwayInstance = (state, pathwayInstanceId) =>
  SolveSelectors.getCandidatePathwaysForPathwayInstance(state.get('solve'), pathwayInstanceId);
export const getCandidatePathwaysForPathwayInstances = (state, pathwayInstanceIds) =>
  SolveSelectors.getCandidatePathwaysForPathwayInstances(state.get('solve'), pathwayInstanceIds);
export const getCandidatePathwaysFromOrganizationIdAndPathwayId = (state, organizationId, pathwayId) =>
  SolveSelectors.getCandidatePathwaysFromOrganizationIdAndPathwayId(state.get('solve'), organizationId, pathwayId);

export const getPathwayReportFilters = (state) => SolveSelectors.getPathwayReportFilters(state.get('solve'));

export const getFilteredPathwayInstances = createSelector(getPathwayInstances, getPathwayReportFilters, (pathwayInstances, filters) =>
  pathwayInstances.filter((instance) => {
    if (filters && filters.get('state') && !filters.get('state').isEmpty() && !filters.get('state').includes(instance.get('state'))) {
      return false;
    }
    if (
      filters &&
      filters.get('year') &&
      !filters.get('year').isEmpty() &&
      !filters.get('year').includes(instance.get('startsOn').substr(0, instance.get('startsOn').indexOf('-')))
    ) {
      return false;
    }
    return true;
  }),
);

export const getPathwayInstanceYears = createSelector(
  getPathwayInstances,
  (pathwayInstances) =>
    new OrderedSet(
      pathwayInstances
        .reduce((yearList, pathwayInstance) => {
          if (!yearList.includes(pathwayInstance.get('startsOn').substring(0, 4))) {
            yearList.push(pathwayInstance.get('startsOn').substring(0, 4));
          }
          return yearList;
        }, [])
        .sort(),
    ),
);

// undefined || candidate || staff || employer
export const getUserType = (state) => {
  const user = getCurrentUser(state);
  if (user && user.get('candidateId')) {
    return 'candidate';
  }
  if (user && user.get('staffId')) {
    const orgType = user.getIn(['staffOrganization', 'orgType']);
    if (orgType.indexOf('employer') !== -1) {
      return 'employer';
    }
    return 'staff';
  }
  return '';
};

export const getOnboarding = (state) => OnboardingSelectors.getOnboarding(state.get('onboard'));
export const getOnboardingMode = (state) => OnboardingSelectors.getMode(state.get('onboard'));
export const getOnboardingCreatedProfile = (state) => OnboardingSelectors.getCreatedProfile(state.get('onboard'));
export const getOnboardingFilteredOrgsCount = (state) => OnboardingSelectors.getOrgStats(state.get('onboard'));

export const getOrganizationsForOnboarding = createSelector(getOrganizations, getOnboardingMode, (orgs, mode) => {
  const prefix = mode === 'employer' ? 'employer' : 'nonprofit';
  return orgs.filter((org) => org.get('orgType').startsWith(prefix));
});

export const getUnreadNotificationCount = () => 17;

export const getOneshotDismissed = (state, id) => OneShotSelectors.getDismissed(state.get('oneShot'), id);

export const getSignupInfo = () =>
  fromJS({ firstName: 'Steve', lastName: 'Balmer', cell: '206.552.5553', birthday: '9/5/1995', gender: 'm', ethnicity: 'other' });

export const getSessionReferrer = () => 'org_44';

export const getLatestActivities = () =>
  fromJS([
    {
      type: 'NewJobPosting',
      subject: 'Sales clerk job was added',
      body: 'A new job position for sales clerk was added',
      link: '/candidate/jobs/1',
    },
    {
      type: 'NewProgram',
      subject: 'Housing training program was added',
      body: 'Something interesting and not redundant',
      link: '/candidate/programs/5',
    },
    {
      type: 'IncompleteApplication',
      link: '/candidate/jobs/4/apply',
    },
  ]);

export const getQuestionnairePercentageComplete = () => 70;
export const getMyChallenges = () => fromJS(['housing', 'transportation', 'childcare']);

export const getResumePercentageComplete = () => 56;

export const getCandidateJobListingsZipcode = (state) => SolveSelectors.getCandidateJobListingsZipcode(state.get('solve'));
export const getCandidateJobListingsDistance = (state) => SolveSelectors.getCandidateJobListingsDistance(state.get('solve'));
export const getCandidateJobListingsFilters = (state) => SolveSelectors.getCandidateJobListingsFilters(state.get('solve'));
export const getCandidateJobListingsSearch = (state) => SolveSelectors.getCandidateJobListingsSearch(state.get('solve'));
export const getCandidateJobListingsLoaded = (state) => SolveSelectors.getCandidateJobListingsLoaded(state.get('solve'));
export const getCandidateJobListingsLoading = (state) => SolveSelectors.getCandidateJobListingsLoading(state.get('solve'));
export const getCandidateJobListingIds = (state) => SolveSelectors.getCandidateJobListingIds(state.get('solve'));
export const getCandidateJobListingsPage = (state) => SolveSelectors.getCandidateJobListingsPage(state.get('solve'));
export const getCandidateTotalJobListings = (state) => SolveSelectors.getCandidateTotalJobListings(state.get('solve'));
export const getCandidateAppliedJobIds = (state) => SolveSelectors.getCandidateAppliedJobIds(state.get('solve'));
export const getCandidateAppliedJobsLoaded = (state) => SolveSelectors.getCandidateAppliedJobsLoaded(state.get('solve'));
export const getCandidateAppliedJobsLoading = (state) => SolveSelectors.getCandidateAppliedJobsLoading(state.get('solve'));
export const getCandidateSavedJobIds = (state) => SolveSelectors.getCandidateSavedJobIds(state.get('solve'));
export const getCandidateSavedJobsLoaded = (state) => SolveSelectors.getCandidateSavedJobsLoaded(state.get('solve'));
export const getCandidateSavedJobsLoading = (state) => SolveSelectors.getCandidateSavedJobsLoading(state.get('solve'));

export const getIndeedCandidateJobListingsZipcode = (state) => SolveSelectors.getIndeedCandidateJobListingsZipcode(state.get('solve'));
export const getIndeedCandidateJobListingsDistance = (state) => SolveSelectors.getIndeedCandidateJobListingsDistance(state.get('solve'));
export const getIndeedCandidateJobListingsFilters = (state) => SolveSelectors.getIndeedCandidateJobListingsFilters(state.get('solve'));
export const getIndeedCandidateJobListingsSearch = (state) => SolveSelectors.getIndeedCandidateJobListingsSearch(state.get('solve'));
export const getIndeedCandidateJobListingsLoaded = (state) => SolveSelectors.getIndeedCandidateJobListingsLoaded(state.get('solve'));
export const getIndeedCandidateJobListingsLoading = (state) => SolveSelectors.getIndeedCandidateJobListingsLoading(state.get('solve'));
export const getIndeedCandidateJobListingIds = (state) => SolveSelectors.getIndeedCandidateJobListingIds(state.get('solve'));
export const getIndeedCandidateTotalJobListings = (state) => SolveSelectors.getIndeedCandidateTotalJobListings(state.get('solve'));
export const getIndeedCandidateAppliedJobIds = (state) => SolveSelectors.getIndeedCandidateAppliedJobIds(state.get('solve'));
export const getIndeedCandidateAppliedJobsLoaded = (state) => SolveSelectors.getIndeedCandidateAppliedJobsLoaded(state.get('solve'));
export const getIndeedCandidateAppliedJobsLoading = (state) => SolveSelectors.getIndeedCandidateAppliedJobsLoading(state.get('solve'));
export const getIndeedCandidateSavedJobIds = (state) => SolveSelectors.getIndeedCandidateSavedJobIds(state.get('solve'));
export const getIndeedCandidateSavedJobsLoaded = (state) => SolveSelectors.getIndeedCandidateSavedJobsLoaded(state.get('solve'));
export const getIndeedCandidateSavedJobsLoading = (state) => SolveSelectors.getIndeedCandidateSavedJobsLoading(state.get('solve'));

export const getRecommendedListingIds = (state) => SolveSelectors.getRecommendedListingIds(state.get('solve'));

// Candidate Events
export const getCandidateEvents = (state, list) => SolveSelectors.getCandidateEvents(state.get('solve'), list);
export const getCandidateEvent = (state, id) => SolveSelectors.getCandidateEvent(state.get('solve'), id);
export const getCandidateEventIds = (state) => SolveSelectors.getCandidateEventIds(state.get('solve'));
export const getCandidateAppliedEventIds = (state) => SolveSelectors.getCandidateAppliedEventIds(state.get('solve'));
export const getCandidateAppliedEventsLoaded = (state) => SolveSelectors.getCandidateAppliedEventsLoaded(state.get('solve'));
export const getCandidateEventsZipcode = (state) => SolveSelectors.getCandidateEventsZipcode(state.get('solve'));
export const getCandidateEventsFilters = (state) => SolveSelectors.getCandidateEventsFilters(state.get('solve'));
export const getCandidateEventsSearch = (state) => SolveSelectors.getCandidateEventsSearch(state.get('solve'));
export const getCandidateEventsLoaded = (state) => SolveSelectors.getCandidateEventsLoaded(state.get('solve'));
export const getCandidateSavedEventIds = (state) => SolveSelectors.getCandidateSavedEventIds(state.get('solve'));
export const getCandidateSavedEventsLoaded = (state) => SolveSelectors.getCandidateSavedEventsLoaded(state.get('solve'));
export const getCandidateFilteredCandidateEventIds = (state) => SolveSelectors.getCandidateFilteredCandidateEventIds(state.get('solve'));
export const getCandidateEventForEvent = (state, eventId) => SolveSelectors.getCandidateEventForEvent(state.get('solve'), eventId);

// Candidate Programs
export const getCandidatePrograms = (state, list) => SolveSelectors.getCandidatePrograms(state.get('solve'), list);
export const getCandidateProgram = (state, id) => SolveSelectors.getCandidateProgram(state.get('solve'), id);
export const getCandidateProgramIds = (state) => SolveSelectors.getCandidateProgramIds(state.get('solve'));
export const getCandidateAppliedProgramIds = (state) => SolveSelectors.getCandidateAppliedProgramIds(state.get('solve'));
export const getCandidateAppliedProgramsLoaded = (state) => SolveSelectors.getCandidateAppliedProgramsLoaded(state.get('solve'));
export const getCandidateProgramsZipCode = (state) => SolveSelectors.getCandidateProgramsZipCode(state.get('solve'));

export const getCandidateServices = (state, list) => SolveSelectors.getCandidateServices(state.get('solve'), list);
export const getCandidateService = (state, id) => SolveSelectors.getCandidateService(state.get('solve'), id);

export const getCandidateAppliedServiceIds = (state) => SolveSelectors.getCandidateAppliedServiceIds(state.get('solve'));
export const getCandidateAppliedServicesLoaded = (state) => SolveSelectors.getCandidateAppliedServicesLoaded(state.get('solve'));
export const getCandidateSavedServiceIds = (state) => SolveSelectors.getCandidateSavedServiceIds(state.get('solve'));
export const getCandidateSavedServicesLoaded = (state) => SolveSelectors.getCandidateSavedServicesLoaded(state.get('solve'));
export const getCandidateServicesLoaded = (state) => SolveSelectors.getCandidateServicesLoaded(state.get('solve'));
export const getCandidateServiceIds = (state) => SolveSelectors.getCandidateServiceIds(state.get('solve'));
export const getCandidateServiceForService = (state, serviceId) => SolveSelectors.getCandidateServiceForService(state.get('solve'), serviceId);
export const getExternalCandidateService = (state, serviceId) => SolveSelectors.getExternalCandidateService(state.get('solve'), serviceId);
export const getExternalCandidateServiceByResourceId = (state, serviceId) =>
  SolveSelectors.getExternalCandidateServiceByResourceId(state.get('solve'), serviceId);
export const getCandidateProgramsFilters = (state) => SolveSelectors.getCandidateProgramsFilters(state.get('solve'));
export const getCandidateProgramsPage = (state) => SolveSelectors.getCandidateProgramsPage(state.get('solve'));
export const getCandidateProgramsSortBy = (state) => SolveSelectors.getCandidateProgramsSortBy(state.get('solve'));
export const getCandidateProgramsDistance = (state) => SolveSelectors.getCandidateProgramsDistance(state.get('solve'));
export const getCandidateProgramsSearch = (state) => SolveSelectors.getCandidateProgramsSearch(state.get('solve'));
export const getCandidateProgramsLoaded = (state) => SolveSelectors.getCandidateProgramsLoaded(state.get('solve'));
export const getCandidateSavedProgramIds = (state) => SolveSelectors.getCandidateSavedProgramIds(state.get('solve'));
export const getCandidateSavedProgramsLoaded = (state) => SolveSelectors.getCandidateSavedProgramsLoaded(state.get('solve'));
export const getCandidateFilteredCandidateProgramIds = (state) => SolveSelectors.getCandidateFilteredCandidateProgramIds(state.get('solve'));
export const getCandidateProgramForProgram = (state, programId) => SolveSelectors.getCandidateProgramForProgram(state.get('solve'), programId);

// AB Services
export const getExternalServicesLoaded = (state) => SolveSelectors.getExternalServicesLoaded(state.get('solve'));
export const getExternalServiceIds = (state) => SolveSelectors.getExternalServiceIds(state.get('solve'));

// Events and Programs (EP) Candidates
export const getStaffEPCandidatesFilters = (state) => SolveSelectors.getStaffEPCandidatesFilters(state.get('solve'));
export const getStaffEPCandidatesPage = (state) => SolveSelectors.getStaffEPCandidatesPage(state.get('solve'));
export const getStaffEPCandidatesSearch = (state) => SolveSelectors.getStaffEPCandidatesSearch(state.get('solve'));
export const getStaffEPCandidatesZipCode = (state) => SolveSelectors.getStaffEPCandidatesZipCode(state.get('solve'));
export const getStaffEPCandidatesLoaded = (state) => SolveSelectors.getStaffEPCandidatesLoaded(state.get('solve'));
export const getStaffEPFilteredEventIds = (state) => SolveSelectors.getStaffEPFilteredEventIds(state.get('solve'));
export const getStaffEPFilteredEventsLoaded = (state) => SolveSelectors.getStaffEPFilteredEventsLoaded(state.get('solve'));
export const getStaffEPFilteredProgramIds = (state) => SolveSelectors.getStaffEPFilteredProgramIds(state.get('solve'));
export const getStaffEPFilteredProgramsLoaded = (state) => SolveSelectors.getStaffEPFilteredProgramsLoaded(state.get('solve'));
export const getStaffEPFilteredServiceIds = (state) => SolveSelectors.getStaffEPFilteredServiceIds(state.get('solve'));
export const getStaffEPFilteredServicesLoaded = (state) => SolveSelectors.getStaffEPFilteredServicesLoaded(state.get('solve'));
export const getCandidateProgramIdsForStaff = (state) => SolveSelectors.getCandidateProgramIdsForStaff(state.get('solve'));
export const getCandidateEventIdsForStaff = (state) => SolveSelectors.getCandidateEventIdsForStaff(state.get('solve'));
export const getStaffEPABServicesLoaded = (state) => SolveSelectors.getStaffEPABServicesLoaded(state.get('solve'));
export const getStaffEPABServiceIds = (state) => SolveSelectors.getStaffEPABServiceIds(state.get('solve'));

export const getStaffEPAllProgramsLoaded = (state) => SolveSelectors.getStaffEPAllProgramsLoaded(state.get('solve'));
export const getStaffEPAllProgramIds = (state) => SolveSelectors.getStaffEPAllProgramIds(state.get('solve'));
export const getStaffEPAllEventsLoaded = (state) => SolveSelectors.getStaffEPAllEventsLoaded(state.get('solve'));
export const getStaffEPAllEventIds = (state) => SolveSelectors.getStaffEPAllEventIds(state.get('solve'));
export const getStaffEPAllServicesLoaded = (state) => SolveSelectors.getStaffEPAllServicesLoaded(state.get('solve'));
export const getStaffEPAllServiceIds = (state) => SolveSelectors.getStaffEPAllServiceIds(state.get('solve'));
export const getAllResourceIdsSortedForStaff = (state) => SolveSelectors.getAllResourceIdsSortedForStaff(state.get('solve'));
export const getAllResourcesForStaff = (state) => SolveSelectors.getAllResourcesForStaff(state.get('solve'));
export const getAllResourceIdsPaginatedForStaff = (state) => SolveSelectors.getAllResourceIdsPaginatedForStaff(state.get('solve'));

export const getStaffCandidatesFilters = (state) => SolveSelectors.getStaffCandidatesFilters(state.get('solve'));
export const getStaffCandidatesSearch = (state) => SolveSelectors.getStaffCandidatesSearch(state.get('solve'));
export const getStaffCandidatesLoaded = (state) => SolveSelectors.getStaffCandidatesLoaded(state.get('solve'));
export const getStaffCandidatesLoading = (state) => SolveSelectors.getStaffCandidatesLoading(state.get('solve'));
export const getStaffCandidateIds = (state) => SolveSelectors.getStaffCandidateIds(state.get('solve'));
export const getStaffTotalCandidates = (state) => SolveSelectors.getStaffTotalCandidates(state.get('solve'));

export const getStaffCandidatesForProgramFilters = (state) => SolveSelectors.getStaffCandidatesForProgramFilters(state.get('solve'));
export const getStaffCandidatesForProgramSearch = (state) => SolveSelectors.getStaffCandidatesForProgramSearch(state.get('solve'));
export const getStaffCandidatesForProgramLoaded = (state) => SolveSelectors.getStaffCandidatesForProgramLoaded(state.get('solve'));
export const getStaffCandidateProgramIds = (state) => SolveSelectors.getStaffCandidateProgramIds(state.get('solve'));

export const getStaffCandidatesForEventFilters = (state) => SolveSelectors.getStaffCandidatesForEventFilters(state.get('solve'));
export const getStaffCandidatesForEventSearch = (state) => SolveSelectors.getStaffCandidatesForEventSearch(state.get('solve'));
export const getStaffCandidatesForEventLoaded = (state) => SolveSelectors.getStaffCandidatesForEventLoaded(state.get('solve'));
export const getStaffCandidateEventIds = (state) => SolveSelectors.getStaffCandidateEventIds(state.get('solve'));

export const getStaffCandidatesForJobFilters = (state) => SolveSelectors.getStaffCandidatesForJobFilters(state.get('solve'));
export const getStaffCandidatesForJobSearch = (state) => SolveSelectors.getStaffCandidatesForJobSearch(state.get('solve'));
export const getStaffCandidatesForJobLoaded = (state) => SolveSelectors.getStaffCandidatesForJobLoaded(state.get('solve'));
export const getStaffCandidateJobIds = (state) => SolveSelectors.getStaffCandidateJobIds(state.get('solve'));

export const getStaffJobListingsFilters = (state) => SolveSelectors.getStaffJobListingsFilters(state.get('solve'));
export const getStaffJobListingZipCode = (state) => SolveSelectors.getStaffJobListingZipCode(state.get('solve'));
export const getStaffJobListingsSearch = (state) => SolveSelectors.getStaffJobListingsSearch(state.get('solve'));
export const getStaffJobListingsLoaded = (state) => SolveSelectors.getStaffJobListingsLoaded(state.get('solve'));
export const getStaffJobListingIds = (state) => SolveSelectors.getStaffJobListingIds(state.get('solve'));
export const getStaffIndeedJobListingIds = (state) => SolveSelectors.getStaffIndeedJobListingIds(state.get('solve'));
export const getStaffTotalJobs = (state) => SolveSelectors.getStaffTotalJobs(state.get('solve'));
export const getStaffTotalIndeedJobs = (state) => SolveSelectors.getStaffTotalIndeedJobs(state.get('solve'));
export const getStaffOrgJobIds = (state) => SolveSelectors.getStaffOrgJobIds(state.get('solve'));
export const getStaffOrgJobsLoaded = (state) => SolveSelectors.getStaffOrgJobsLoaded(state.get('solve'));
export const getStaffTotalOrgJobs = (state) => SolveSelectors.getStaffTotalOrgJobs(state.get('solve'));

export const getCandidateJobRecommendations = (state) => SolveSelectors.getCandidateJobRecommendations(state.get('solve'));

export const getStaff = (state, list) => SolveSelectors.getStaff(state.get('solve'), list);
export const getStaffMember = (state, id) => SolveSelectors.getStaffMember(state.get('solve'), id);

export const getCandidates = (state, list) => SolveSelectors.getCandidates(state.get('solve'), list);
export const getCandidate = (state, id) => SolveSelectors.getCandidate(state.get('solve'), id);

export const getActiveStaff = createSelector(getStaff, (staff) => staff.filter((staffMember) => staffMember.get('status') === 'active'));

export const getPeerStaff = (state) => SolveSelectors.getPeerStaff(state.get('solve'));

export const getExistingCandidates = createSelector(
  getStaffCandidateIds,
  (state) => state,
  (candidateIds, state) => {
    const candidates = getCandidates(state, candidateIds);
    const currentUser = getCurrentUser(state);

    const candidateUsers = candidates.map((candidate) => ({
      value: candidate.get('id'),
      label: extractCandidateFullName(candidate.get('user'), currentUser),
    }));
    return candidateUsers;
  },
);

export const getPeerStaffAndCandidates = createSelector(getPeerStaff, getCandidates, (staff, candidates) => {
  const staffUsers = staff.map((staffMember) => ({
    value: staffMember.getIn(['user', 'id']),
    label: `${staffMember.getIn(['user', 'firstName'])} ${staffMember.getIn(['user', 'lastName'])}`,
    subLabel: `Staff, ${staffMember.getIn(['organization', 'name'])}`,
  }));
  const candidatesUsers = candidates.map((candidate) => ({
    value: candidate.getIn(['user', 'id']),
    label: `${candidate.getIn(['user', 'firstName'])} ${candidate.getIn(['user', 'lastName'])}`,
    subLabel: 'Candidate',
  }));
  return staffUsers.concat(candidatesUsers);
});

export const getMessage = (state, id) => SolveSelectors.getMessage(state.get('solve'), id);
export const getMessages = (state) => SolveSelectors.getMessages(state.get('solve'));

export const getNote = (state, id) => SolveSelectors.getNote(state.get('solve'), id);
export const getNotes = (state) => SolveSelectors.getNotes(state.get('solve'));

export const getNotifications = (state) => SolveSelectors.getNotifications(state.get('solve'));
export const getNotification = (state, id) => SolveSelectors.getNotification(state.get('solve'), id);

export const getPublicLink = (state, id) => SolveSelectors.getPublicLink(state.get('solve'), id);
export const getRedirectPath = (state) => state.getIn(['publicLink', 'redirectPath'], '');

export const getCandidateOrgStaff = createSelector(getCandidateOrganizations, (orgs) => {
  const orgStaff = orgs.map((org) => org.getIn(['organization', 'staff']));
  return orgStaff.flatten(true);
});

export const getCandidateOrgStaffUsers = createSelector(getCandidateOrgStaff, (orgStaff) => {
  const staff = orgStaff.map((staffMember) => ({
    value: staffMember.getIn(['user', 'id']),
    label: `${staffMember.getIn(['user', 'firstName'])} ${staffMember.getIn(['user', 'lastName'])}`,
    subLabel: `Staff, ${staffMember.getIn(['user', 'staffOrganization', 'name'])}`,
  }));
  const uniqueStaff = Array.from(new Set(staff.map((a) => a.value))).map((value) => staff.find((a) => a.value === value));
  return new OrderedSet(uniqueStaff);
});

export const getMessageFromUser = createSelector(
  getMessage,
  (message) =>
    message &&
    new OrderedSet([
      {
        value: message.get('fromUserId'),
        label: `${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`,
      },
    ]),
);

export const getInboundMessages = createSelector(getMessages, getCurrentUser, (messages, user) =>
  messages.filter((message) => message.get('toUserId') === user.get('id')),
);

export const getInboundMessagesFromUsers = createSelector(getInboundMessages, (messages) => {
  const users = messages.map((message) => ({
    value: message.get('fromUserId'),
    label: `${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`,
  }));
  const uniqueUsers = Array.from(new Set(users.map((a) => a.value))).map((value) => users.find((a) => a.value === value));
  return new OrderedSet(uniqueUsers);
});

export const getInboundMessagesFromCandidate = createSelector(getInboundMessages, getCandidate, (messages, candidate) =>
  messages.filter((message) => message.get('fromUserId') === candidate.get('id')),
);

export const getOutboundMessages = createSelector(getMessages, getCurrentUser, (messages, user) =>
  messages.filter((message) => message.get('fromUserId') === user.get('id')),
);

export const getOutboundMessagesFromUsers = createSelector(getOutboundMessages, (messages) => {
  const users = messages.map((message) => ({
    value: message.get('fromUserId'),
    label: `${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])}`,
  }));
  const uniqueUsers = Array.from(new Set(users.map((a) => a.value))).map((value) => users.find((a) => a.value === value));
  return new OrderedSet(uniqueUsers);
});

export const getFilteredOutboundMessages = createSelector(getOutboundMessages, (messages) =>
  messages
    .filter((message) => !message.get('isDeleted'))
    .sortBy((message) => {
      if (message.get('isStarred')) return -1;
      return 1;
    })
    .sortBy((message) => {
      if (message.get('createdAt')) return -1;
      return 1;
    }),
);

export const getOutboundMessagesCount = createSelector(getOutboundMessages, (messages) => messages.filter((message) => !message.get('isDeleted')).size);

export const getReadMessages = createSelector(getInboundMessages, (messages) =>
  messages
    .filter((message) => !message.get('isDeleted') && message.get('isRead'))
    .sortBy((message) => {
      if (message.get('isStarred')) return -1;
      return 1;
    })
    .sortBy((message) => {
      if (message.get('createdAt')) return -1;
      return 1;
    }),
);
export const getReadMessagesCount = createSelector(
  getInboundMessages,
  (messages) => messages.filter((message) => !message.get('isDeleted') && message.get('isRead')).size,
);
export const getUnreadMessages = createSelector(getInboundMessages, (messages) =>
  messages
    .filter((message) => !message.get('isDeleted') && !message.get('isRead'))
    .sortBy((message) => {
      if (message.get('isStarred')) return -1;
      return 1;
    })
    .sortBy((message) => {
      if (message.get('createdAt')) return -1;
      return 1;
    }),
);
export const getUnreadMessagesCount = createSelector(
  getInboundMessages,
  (messages) => messages.filter((message) => !message.get('isDeleted') && !message.get('isRead')).size,
);

export const getStaffReplyableUsers = createSelector(
  getInboundMessagesFromUsers,
  getOutboundMessagesFromUsers,
  getPeerStaffAndCandidates,
  (inboundUsers, outboundUsers, staffAndCandidates) => {
    const users = inboundUsers.concat(outboundUsers).concat(staffAndCandidates);
    const uniqueUsers = Array.from(new Set(users.map((a) => a.value))).map((value) => users.find((a) => a.value === value));
    return new OrderedSet(uniqueUsers);
  },
);

export const getCandidateReplyableUsers = createSelector(
  getInboundMessagesFromUsers,
  getOutboundMessagesFromUsers,
  getCandidateOrgStaffUsers,
  (inboundUsers, outboundUsers, orgUsers) => {
    const users = inboundUsers.concat(outboundUsers).concat(orgUsers);
    const uniqueUsers = Array.from(new Set(users.map((a) => a.value))).map((value) => users.find((a) => a.value === value));
    return new OrderedSet(uniqueUsers);
  },
);

export const getReplyableUsers = createSelector(getCurrentUser, getStaffReplyableUsers, getCandidateReplyableUsers, (user, staff, candidate) => {
  if (user.get('candidateId')) return candidate;
  return staff;
});

export const getSystemEnumList = (state, name) => EnumSelectors.getList(state.get('enum'), name);
export const getNeedSystemEnums = (state) => EnumSelectors.needEnums(state.get('enum'));
export const getSystemEnumsBackingoff = (state) => EnumSelectors.getBackingOff(state.get('enum'));
export const getEnumsForFormSelect = createSelector(getSystemEnumList, (enums) =>
  enums.filterNot((item) => item.get('excludeFromVisibleChoices')).map((item) => [item.get('slug'), item.get('label')]),
);
export const getLabelsForNotifications = createSelector(
  (state) => getSystemEnumList(state, 'NotificationType'),
  (enums) => {
    const notifications = {};
    /* eslint-disable-next-line no-return-assign */
    enums.map((item) => (notifications[item.get('slug')] = item.get('label')));
    return notifications;
  },
);

export const getGlobalSurveysOfType = (state, type) => SolveSelectors.getGlobalSurveysOfType(state.get('solve'), type);
export const getNeedGlobalSurveys = (state) => SolveSelectors.getNeedGlobalSurveys(state.get('solve'));
export const getGlobalSurveysBackingOff = (state) => SolveSelectors.getGlobalSurveysBackingOff(state.get('solve'));

export const getFilteredCandidateEvents = createSelector((state) => state, getCandidateEventIdsForStaff, getCandidateEvents);
export const getFilteredCandidatePrograms = createSelector((state) => state, getCandidateProgramIdsForStaff, getCandidatePrograms);

export const getPublicLinkForPosting = (state, postingId) => SolveSelectors.getPublicLinkForPosting(state.get('solve'), postingId);

export const getEPCandidateStates = createSelector(getFilteredCandidateEvents, getFilteredCandidatePrograms, (candidateEvents, candidatePrograms) => {
  const eventStateCounts = candidateEvents.reduce((reduction, event) => {
    const state = event.get('staffState');
    const count = reduction.get(state) || 0;
    return reduction.set(state, count + 1);
  }, Map());

  const programStateCounts = candidatePrograms.reduce((reduction, event) => {
    const state = event.get('staffState');
    const count = reduction.get(state) || 0;
    return reduction.set(state, count + 1);
  }, Map());

  return { eventStateCounts, programStateCounts };
});

export const getResourceIdsSortedForCandidate = (state) => SolveSelectors.getResourceIdsSortedForCandidate(state.get('solve'));
export const getResourceIdsPaginated = (state) => SolveSelectors.getResourceIdsPaginated(state.get('solve'));
export const getResourcesLoadedForCandidate = (state) => SolveSelectors.getResourcesLoadedForCandidate(state.get('solve'));
export const getAppliedResourceIdsSortedForCandidate = (state) => SolveSelectors.getAppliedResourceIdsSortedForCandidate(state.get('solve'));
export const getAppliedResourcesLoadedForCandidate = (state) => SolveSelectors.getAppliedResourcesLoadedForCandidate(state.get('solve'));
export const getAppliedResourceIdsPaginated = (state) => SolveSelectors.getAppliedResourceIdsPaginated(state.get('solve'));
export const getSavedResourceIdsSortedForCandidate = (state) => SolveSelectors.getSavedResourceIdsSortedForCandidate(state.get('solve'));
export const getSavedResourceIdsPaginated = (state) => SolveSelectors.getSavedResourceIdsPaginated(state.get('solve'));
export const getSavedResourcesLoadedForCandidate = (state) => SolveSelectors.getSavedResourcesLoadedForCandidate(state.get('solve'));

export const getResourceIdsSortedForStaff = (state) => SolveSelectors.getResourceIdsSortedForStaff(state.get('solve'));
export const getResourceIdsPaginatedForStaff = (state) => SolveSelectors.getResourceIdsPaginatedForStaff(state.get('solve'));

export const getMyOrgResourceIdsSortedForStaff = (state, myOrgId) => SolveSelectors.getMyOrgResourceIdsSortedForStaff(state.get('solve'), myOrgId);
export const getMyOrgResourceIdsPaginatedForStaff = (state, myOrgId) => SolveSelectors.getMyOrgResourceIdsPaginatedForStaff(state.get('solve'), myOrgId);
export const getMyDraftResourceIdsSortedForStaff = (state) => SolveSelectors.getMyDraftResourceIdsSortedForStaff(state.get('solve'));
export const getMyDraftResourceIdsPaginatedForStaff = (state) => SolveSelectors.getMyDraftResourceIdsPaginatedForStaff(state.get('solve'));

export const getPublicLinks = (state) => SolveSelectors.getPublicLinks(state.get('solve'));
export const getPublicLinkForOrganization = createSelector(getPublicLinks, getCurrentUser, (publicLinks, currentUser) =>
  publicLinks.find((publicLink) => publicLink && publicLink.get('subjectId') === currentUser.getIn(['staffOrganization', 'id'])),
);

export const getPublicLinkTokens = (state) => PublicLinkSelectors.getPublicLinks(state.get('publicLink'));
export const getInvites = (state) => PublicLinkSelectors.getInvites(state.get('publicLink'));
export const getActivePublicLink = (state) => PublicLinkSelectors.getActivePublicLink(state.get('publicLink'));
export const getActiveInviteLink = (state) => PublicLinkSelectors.getActiveInviteLink(state.get('publicLink'));
export const getReferringOrg = (state) => PublicLinkSelectors.getReferringOrg(state.get('publicLink'));

export const getRecidivismReport = (state) => SolveSelectors.getRecidivismReport(state.get('solve'));
