import { Map } from 'immutable';

const validatePlaceholder = (type, value) => {
  if (value === undefined || value === '') {
    return 'Required';
  }
  return undefined;
};

const EMPTY_MAP = Map();

const validateExtra = (extra) => {
  if (!extra || !extra.get('questionPlaceholders_type')) {
    return {};
  }
  const errors = {};
  const types = extra.get('questionPlaceholders_type');
  const placeholders = extra.get('questionPlaceholders', EMPTY_MAP);
  errors.questionPlaceholders = types.map((value, key) => validatePlaceholder(value, placeholders.get(key))).toJS();
  return errors;
};

const validateSurveyQuestion = (question) => {
  if (question.get('isIncluded') === 'No') {
    return {};
  }

  const errors = {};
  if (!question.get('isIncluded')) {
    return { isIncluded: 'Please specify' };
  }
  if (!question.get('questionImportanceType')) {
    errors.questionImportanceType = 'Please specify';
  }
  errors.extra = validateExtra(question.getIn(['extra']));
  return errors;
};

const validate = (surveyQuestionAttributes) => (surveyQuestionAttributes ? surveyQuestionAttributes.map(validateSurveyQuestion).toJS() : {});

export default validate;
