import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ListStaffRowStyles from './list_staff_row_styles';
import { Field, MuiCheckbox } from '../../../../common/form';

const propTypes = {
  staff: PropTypes.instanceOf(Map).isRequired,
  onDelete: PropTypes.func.isRequired,
};

const defaultProps = {};

const ListStaffRowView = ({ staff, onDelete }) => (
  <ListStaffRowStyles>
    <td>
      <h4>
        {staff.getIn(['user', 'firstName'])} {staff.getIn(['user', 'lastName'])}
      </h4>
    </td>
    <td>{staff.getIn(['user', 'email']) ? <a href={`mailto:${staff.getIn(['user', 'email'])}`}>{staff.getIn(['user', 'email'])}</a> : 'N/A'}</td>
    <td>{staff.get('createdAt') ? `${moment(staff.get('createdAt')).format('MM/DD/YYYY')}` : 'N/A'}</td>
    <td>{staff.get('isOrgAdmin') ? 'Yes' : 'No'}</td>
    <td>
      <FormControlLabel className="answer" control={<Field component={MuiCheckbox} name={`${staff.get('id')}.permissions.edit_resume`} variant="outlined" />} />
    </td>
    <td>
      <FormControlLabel
        className="answer"
        control={<Field component={MuiCheckbox} name={`${staff.get('id')}.permissions.edit_applications`} variant="outlined" />}
      />
    </td>
    <td>
      <FormControlLabel
        className="answer"
        control={<Field component={MuiCheckbox} name={`${staff.get('id')}.permissions.view_challenges`} variant="outlined" />}
      />
    </td>
    <td>
      <FormControlLabel
        className="answer"
        control={<Field component={MuiCheckbox} name={`${staff.get('id')}.permissions.edit_challenges`} variant="outlined" />}
      />
    </td>
    <td>
      <Button color="secondary" size="small" onClick={onDelete}>
        Delete
      </Button>
    </td>
  </ListStaffRowStyles>
);

ListStaffRowView.propTypes = propTypes;
ListStaffRowView.defaultProps = defaultProps;

export default ListStaffRowView;
