import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Stepper from 'react-stepper-horizontal';
import { Redirect } from 'react-router-dom';
import { MuiFormTheme, MuiThemeProvider } from '../../../common/form';
import theme from '../../../common/theme';
import AddEventStyles from './add_event_styles';
import EventOverviewForm from './event_overview_form';
import DetailsForm from './details_form';
import ScreenerForm from './screener_form';
import FinalizeForm from './finalize_form';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  event: PropTypes.instanceOf(Map),
  resetForm: PropTypes.func.isRequired,
};

const defaultProps = {
  event: undefined,
};

const AddEventView = ({ handleSubmit, handleSaveDraft, initialValues, event, resetForm }) => {
  const [page, setPage] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [draftSubmitted, setDraftSubmitted] = useState(false);
  const [draftId, setDraftId] = useState(null);
  useEffect(() => {
    resetForm('eventForm');
  }, [resetForm]);

  const handleSubmitAndRedirect = (e) => {
    handleSubmit(e).then((_results) => {
      setSubmitted(true);
      alert('Your event has been successfully saved and posted.');
    });
  };

  const handleSaveDraftAndRedirect = (e) => {
    handleSaveDraft(e).then((_results) => {
      setDraftId(_results.result);
      setDraftSubmitted(true);
      alert('Your event draft has been successfully saved.');
    });
  };

  if (submitted) {
    return <Redirect to="/staff/resources" />;
  }

  if (draftSubmitted) {
    return <Redirect to={`/staff/events/${draftId}`} />;
  }

  const steps = [{ title: '1. Overview' }, { title: '2. Details' }, { title: '3. Screener ' }, { title: '4. Finalize' }];
  return (
    <AddEventStyles>
      <div className="content-area">
        <div className="wrapper">
          <div className="header">
            <h1>Create An Event</h1>
            <Stepper
              steps={steps}
              activeStep={page - 1}
              activeColor={theme.color.secondaryAction}
              activeTitleColor={theme.color.secondaryAction}
              completeColor={theme.color.secondaryAction}
              completeBarColor={theme.color.secondaryAction}
              completeTitleColor={theme.color.secondaryAction}
              circleFontSize={0}
              titleFontSize={14}
              size={8}
            />
          </div>

          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {page === 1 && (
                <EventOverviewForm
                  previousPage={() => setPage(page - 1)}
                  onSubmit={() => setPage(page + 1)}
                  onSaveDraft={handleSaveDraft}
                  initialValues={initialValues}
                />
              )}
              {page === 2 && (
                <DetailsForm
                  previousPage={() => setPage(page - 1)}
                  onSubmit={() => setPage(page + 1)}
                  onSaveDraft={handleSaveDraft}
                  initialValues={initialValues}
                />
              )}
              {page === 3 && (
                <ScreenerForm
                  previousPage={() => setPage(page - 1)}
                  onSubmit={() => setPage(page + 1)}
                  onSaveDraft={handleSaveDraft}
                  initialValues={initialValues}
                />
              )}
              {page === 4 && (
                <FinalizeForm
                  previousPage={() => setPage(page - 1)}
                  event={event}
                  onSubmit={handleSubmitAndRedirect}
                  onSaveDraft={handleSaveDraftAndRedirect}
                />
              )}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </AddEventStyles>
  );
};

AddEventView.propTypes = propTypes;
AddEventView.defaultProps = defaultProps;

export default AddEventView;
