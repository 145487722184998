/* eslint-disable max-len */
import React from 'react';
import moment from 'moment';
import { startCase } from 'lodash';
import PropTypes from 'prop-types';
import { fromJS, Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';

import theme from '../../../common/theme';
import EventFlyerStyles from './event_flyer_styles';
import { Text, TextHeading } from '../../../common/components';
import formatPhoneNumber from '../../../util/format_phone_number';

const propTypes = {
  address: PropTypes.instanceOf(Map).isRequired,
  careerTypes: PropTypes.instanceOf(Map),
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  endTime: PropTypes.date,
  gainedCertifications: PropTypes.string,
  industryType: PropTypes.string,
  logo: PropTypes.string,
  name: PropTypes.string.isRequired,
  phone: PropTypes.number.isRequired,
  primaryService: PropTypes.string,
  serviceTypes: PropTypes.instanceOf(Map),
  startTime: PropTypes.date,
};

const defaultProps = {
  endTime: undefined,
  industryType: undefined,
  logo: undefined,
  primaryService: undefined,
  gainedCertifications: undefined,
  serviceTypes: fromJS({}),
  startTime: undefined,
  careerTypes: fromJS({}),
};

const EventFlyerView = ({
  address,
  careerTypes,
  date,
  description,
  endTime,
  gainedCertifications,
  industryType,
  logo,
  name,
  phone,
  primaryService,
  serviceTypes,
  startTime,
}) => {
  return (
    <EventFlyerStyles>
      <Grid container spacing={2}>
        {logo && (
          <Grid item md={2}>
            <img src={logo} width="100%" alt="Logo" />
          </Grid>
        )}
        <Grid item md={6} style={{ paddingLeft: 20 }}>
          <h1>{name}</h1>
        </Grid>
        <Grid item md={4}>
          <ul className="contact-info">
            <li>
              <LocalPhoneIcon style={{ color: theme.color.action }} />
              {formatPhoneNumber(phone)}
            </li>
            {address && (
              <li style={{ alignItems: 'flex-start' }}>
                <BeenhereIcon style={{ color: theme.color.action }} />
                {address.get('address1')}, {address.get('city')}, {address.get('state')} {address.get('zip')}
              </li>
            )}
          </ul>
        </Grid>
        <Grid item md={12}>
          {description && <Text size="small" color="medium" margin="bottom" dangerouslySetInnerHTML={{ __html: description }} />}
        </Grid>

        <Grid item md={4}>
          <TextHeading margin="both" size="small" weight="bold" uppercase>
            Service Type
          </TextHeading>
          {primaryService && (
            <Text size="small" color="medium" className="capitalize">
              {primaryService.replace(/([A-Z])/g, ' $1')}
            </Text>
          )}
          <br />

          <TextHeading margin="both" size="small" weight="bold" uppercase>
            Industries
          </TextHeading>
          {industryType && (
            <ul className="capitalize">
              <li>
                <Text color="medium">{startCase(industryType)}</Text>
              </li>
            </ul>
          )}
          <br />

          {serviceTypes && (
            <React.Fragment>
              <TextHeading margin="both" size="small" weight="bold" uppercase>
                Additional Services
              </TextHeading>
              <ul className="capitalize">
                {serviceTypes.keySeq().map((service) => (
                  <li key={service}>{service.replace(/([A-Z])/g, ' $1')}</li>
                ))}
              </ul>
              <br />
            </React.Fragment>
          )}
        </Grid>
        <Grid item md={1} />
        <Grid item md={7}>
          <TextHeading margin="both" size="small" weight="bold" uppercase>
            Event Date
          </TextHeading>
          <TextHeading size="medium" margin="top" weight="bold">
            {`${moment(date).format('LL')}`}
            <br />
          </TextHeading>
          {startTime && (
            <Text>
              {`${moment(startTime).format('LT')}`}
              {endTime && ` – ${moment(endTime).format('LT')}`}
            </Text>
          )}
          <hr />
          {gainedCertifications && (
            <React.Fragment>
              <TextHeading size="small" margin="both" weight="bold">
                Certifications you will gain
              </TextHeading>
              <ul>
                {gainedCertifications.map((cert) => (
                  <li className="capitalize" key={cert}>
                    {cert.replace(/([A-Z])/g, ' $1')}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
          <br />

          {gainedCertifications && (
            <React.Fragment>
              <TextHeading size="small" margin="both" weight="bold">
                Potential Careers
              </TextHeading>
              <ul>
                {careerTypes.keySeq().map((career) => (
                  <li className="capitalize" key={career}>
                    {career.replace(/([A-Z])/g, ' $1')}
                  </li>
                ))}
              </ul>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </EventFlyerStyles>
  );
};

EventFlyerView.propTypes = propTypes;
EventFlyerView.defaultProps = defaultProps;

export default EventFlyerView;
