import { combineImmutableReducers } from '../util';
import ACTIONS from './actions';
import SessionActions from '../session/actions';
import SolveActions from '../solve/actions';
import UserModel from '../session/models/user_model';

const onboarding = (state = true, action) => {
  let user;
  switch (action.type) {
    case SessionActions.RECOGNIZE_USER:
      user = new UserModel(action.user);
      break;
    case SessionActions.SIGN_IN.success:
    case SessionActions.FINISH_SIGN_IN.success:
    case SessionActions.REGISTER.success:
    case SessionActions.REFRESH_AUTH.success:
    case SessionActions.UPDATE_CURRENT_USER.success:
      user = new UserModel(action.response.data);
      break;
    case SessionActions.SIGN_OUT:
      return true;
    case ACTIONS.COMPLETE:
      return false;
    default:
      return state;
  }
  return !(user && (user.get('staffId') || user.get('candidateId')));
};

const mode = (state = '', action) => {
  let user;
  switch (action.type) {
    case SessionActions.RECOGNIZE_USER:
      user = new UserModel(action.user);
      break;
    case SessionActions.SIGN_IN.success:
    case SessionActions.FINISH_SIGN_IN.success:
    case SessionActions.REGISTER.success:
    case SessionActions.REFRESH_AUTH.success:
    case SessionActions.UPDATE_CURRENT_USER.success:
      user = new UserModel(action.response.data);
      break;
    case SessionActions.SIGN_OUT:
      return '';
    case ACTIONS.SET_MODE:
      return action.mode;
    default:
      return state;
  }
  if (user) {
    if (user.get('candidateId')) {
      return 'candidate';
    }
    if (user.get('staffId')) {
      // TODO: check orgType to decide staff vs employer
      return 'staff';
    }
  }
  return state;
};

const createdProfile = (state = false, action) => {
  let user;
  switch (action.type) {
    case SolveActions.CREATE_CANDIDATE.success:
    case SolveActions.CREATE_STAFF_MEMBER.success:
      return true;
    case SessionActions.RECOGNIZE_USER:
      user = new UserModel(action.user);
      return user.candidateId || user.staffId;
    case SessionActions.SIGN_IN.success:
    case SessionActions.FINISH_SIGN_IN.success:
    case SessionActions.REGISTER.success:
    case SessionActions.REFRESH_AUTH.success:
    case SessionActions.UPDATE_CURRENT_USER.success:
      user = new UserModel(action.response.data);
      return user.candidateId || user.staffId;
    case SessionActions.SIGN_OUT:
      return false;
    default:
      return state;
  }
};

const orgStats = (state = null, action) => {
  switch (action.type) {
    case SolveActions.LIST_FILTERED_ORGANIZATIONS.success:
      return Number.parseInt(action.response.totalFilteredOrgs, 10) || null;
    default:
      return state;
  }
};

const reducer = combineImmutableReducers({
  onboarding,
  mode,
  createdProfile,
  orgStats,
});

export default reducer;

export const getOnboarding = (state) => state.get('onboarding');
export const getMode = (state) => state.get('mode');
export const getCreatedProfile = (state) => state.get('createdProfile');
export const getOrgStats = (state) => state.get('orgStats');
