import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  ReferenceField,
  DateField,
  BooleanField,
  BooleanInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
  Filter,
  Pagination,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/EmojiPeople';
import { EditCandidateResumeLinkField } from '../fields';

export const Icon = SettingsIcon;

// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

const SearchFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput label="Search by Name" source="userFullName" alwaysOn />
  </Filter>
);

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} filters={<SearchFilter />} pagination={<PostPagination />}>
    <Datagrid>
      <ReferenceField label="User" source="userId" reference="users">
        <FunctionField render={(x) => `${x.firstName} ${x.lastName}`} />
      </ReferenceField>
      <ReferenceField label="Address" source="addressId" reference="addresses">
        <TextField source="id" />
      </ReferenceField>
      <DateField source="createdAt" />
      <DateField source="lastActivatedAt" />
      <TextField label="Status" source="aasmState" />
      <DateField source="birthday" />
      <TextField label="Gender" source="genderType" />
      <TextField label="Ethnicity" source="ethnicityType" />
      <BooleanField source="military" />
      <BooleanField source="hasDriverLicense" />
      <BooleanField source="hasIdentification" />
      <TextField label="Highest Education Level" source="highestEducationLevelType" />
      <BooleanField source="isLookingForJob" />
      <TextField source="currentEmploymentJobType" />
      <TextField source="lookingForJobTypes" />
      <TextField source="programsCompleted" />
      <TextField source="industryTypes" />
      <TextField source="skillTypes" />
      <TextField source="languageTypes" />
      <TextField source="linkedInUrl" />
      <TextField source="howFoundReferralName" />
      <TextField source="howFoundReferralOrgType" />
      <EditCandidateResumeLinkField source="id" />
      <EditButton basePath="/candidates" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>Candidate {record ? `"${record.userId}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <EditCandidateResumeLinkField source="id" />
      <TextInput label="Status" source="aasmState" />
      <DateInput source="birthday" />
      <TextInput label="Gender" source="genderType" />
      <TextInput label="Ethnicity" source="ethnicityType" />
      <BooleanInput source="military" />
      <BooleanInput source="hasDriverLicense" />
      <BooleanInput source="hasIdentification" />
      <TextInput label="Highest Education Level" source="highestEducationLevelType" />
      <BooleanInput source="isLookingForJob" />
      <TextInput source="currentEmploymentJobType" />
      <TextInput source="lookingForJobTypes" />
      <TextInput source="programsCompleted" />
      <TextInput source="industryTypes" />
      <TextInput source="skillTypes" />
      <TextInput source="languageTypes" />
      <TextInput source="linkedInUrl" />
      <TextInput source="howFoundReferralName" />
      <TextInput source="howFoundReferralOrgType" />
      <BooleanInput source="sendEmailPermitted" />
      <BooleanInput source="sendTextPermitted" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <ReferenceInput label="User" source="userId" reference="users">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <DateInput source="createdAt" />
      <TextInput source="addressAttributes.address1" />
      <TextInput source="addressAttributes.addressAttributes2" />
      <TextInput source="addressAttributes.city" />
      <TextInput source="addressAttributes.state" />
      <TextInput source="addressAttributes.zip" />
      <TextInput label="Status" source="aasmState" />
      <DateInput source="birthday" />
      <TextInput label="Gender" source="genderType" />
      <TextInput label="Ethnicity" source="ethnicityType" />
      <BooleanInput source="military" />
      <BooleanInput source="hasDriverLicense" />
      <BooleanInput source="hasIdentification" />
      <TextInput label="Highest Education Level" source="highestEducationLevelType" />
      <BooleanInput source="isLookingForJob" />
      <TextInput source="currentEmploymentJobType" />
      <TextInput source="lookingForJobTypes" />
      <TextInput source="programsCompleted" />
      <TextInput source="industryTypes" />
      <TextInput source="skillTypes" />
      <TextInput source="languageTypes" />
      <TextInput source="linkedInUrl" />
      <TextInput source="howFoundReferralName" />
      <TextInput source="howFoundReferralOrgType" />
    </SimpleForm>
  </AdminCreate>
);
