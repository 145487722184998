import restProvider from 'ra-data-json-server';
import { fetchUtils, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, CREATE, UPDATE } from 'react-admin';
import { map } from 'lodash';
import { AUTH_TOKEN_KEY } from './auth_provider';

const ENDPOINT = `${process.env.REACT_APP_API_V1_URL || 'http://localhost:3000'}/admin
`;

const httpClient = (url, options = {}) => {
  const token = localStorage.getItem(AUTH_TOKEN_KEY);
  const myOptions = {
    ...options,
    user: {
      authenticated: true,
      token: `Bearer ${token}`,
    },
  };
  if (!myOptions.headers) {
    myOptions.headers = new Headers({ Accept: 'application/json', 'Content-Type': 'application/json' });
  }
  const metaCSRF = document.head.querySelector('meta[name="csrf-token"]');
  if (metaCSRF) {
    myOptions.headers.set('X-CSRF-Token', metaCSRF.content);
  }
  return fetchUtils.fetchJson(url, myOptions);
};

function convertExtraToJSON(params) {
  const { data, ...newParams } = params;
  if (data && data.extra) {
    const { extra, ...attrs } = data;
    newParams.data = { extra: JSON.parse(extra), ...attrs };
  } else {
    newParams.data = data;
  }
  return newParams;
}

function stringifyExtra(data) {
  const { extra, ...attrs } = data;
  const stringExtra = JSON.stringify(extra);
  return {
    extra: stringExtra,
    ...attrs,
  };
}

// eslint-disable-next-line no-unused-vars
const stringifyJsonFields = (requestHandler) => (type, resource, params) => {
  if (resource === 'system_enums') {
    console.log({ type, resource, params }, 'WOOT');
    const newParams = convertExtraToJSON(params);
    return requestHandler(type, resource, newParams).then((rez) => {
      const { data, total } = rez;
      console.log(rez, 'RESULT FROM REQUEST HANDLER');
      switch (type) {
        case GET_LIST:
        case GET_MANY:
        case GET_MANY_REFERENCE:
          return {
            data: map(data, (record) => stringifyExtra(record)),
            total,
          };
        case GET_ONE:
        case CREATE:
        case UPDATE:
          return { data: stringifyExtra(data) };
        default:
          return rez;
      }
    });
  }
  return requestHandler(type, resource, params);
};

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.rawFile);

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `logo` sent property, with `src` and `title` attributes.
 */
// eslint-disable-next-line no-unused-vars
const addBase64Uploads = (requestHandler) => (type, resource, params) => {
  if (type === 'UPDATE' && resource === 'organizations') {
    // notice that following condition can be true only when `<ImageInput source="logos" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.logos is not an array, but single object
    if (params.data.logo) {
      // only freshly dropped logos are instance of File
      // const formerPictures = params.data.logos.filter((p) => !(p.rawFile instanceof File));
      // const newPictures = params.data.logos.filter((p) => p.rawFile instanceof File);
      // since 'multiple' is set to false we have a single logo to process
      const newPicture = params.data.logo;

      // return Promise.all(newPictures.map(convertFileToBase64))
      return convertFileToBase64(newPicture)
        .then((base64) => ({
          src: base64,
          title: `${newPicture.title}`,
        }))
        .then((transformedNewPicture) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              logo: transformedNewPicture,
            },
          }),
        );
    }
    if (params.data.cobrandingLogo) {
      // only freshly dropped logos are instance of File
      // const formerPictures = params.data.logos.filter((p) => !(p.rawFile instanceof File));
      // const newPictures = params.data.logos.filter((p) => p.rawFile instanceof File);
      // since 'multiple' is set to false we have a single logo to process
      const newPicture = params.data.cobrandingLogo;

      // return Promise.all(newPictures.map(convertFileToBase64))
      return convertFileToBase64(newPicture)
        .then((base64) => ({
          src: base64,
          title: `${newPicture.title}`,
        }))
        .then((transformedNewPicture) =>
          requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              cobrandingLogo: transformedNewPicture,
            },
          }),
        );
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

// We can use this if we need free-form JSON editing... As is, we can just explicitly call out the fields like extra.description
// const dataProvider = stringifyJsonFields(restProvider(ENDPOINT, httpClient));

const simpleDataProvider = restProvider(ENDPOINT, httpClient);
const dataProvider = addBase64Uploads(simpleDataProvider);
export default dataProvider;
