import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { reduxForm, Field, MuiTextField } from '../../../common/form';
import { Button } from '../../../common/components';
import NotesNewValidate from './notes_new_validate';
import NotesNewStyles from './notes_new_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  candidate: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const NotesNew = ({ handleSubmit, pristine, submitting, candidate }) => {
  const history = useHistory();

  const handleSubmitAndRedirect = (event) => {
    handleSubmit(event).then((_results) => {
      history.goBack();
    });
  };

  return (
    <NotesNewStyles className="container-wrapper">
      <Button className="back-button" buttonType="back" onClick={history.goBack} size="small">
        <KeyboardBackspaceIcon />
        Go Back
      </Button>
      <h2>
        New note for:{' '}
        <span>
          {candidate.getIn(['user', 'firstName'])} {candidate.getIn(['user', 'lastName'])}
        </span>
      </h2>
      <form onSubmit={handleSubmitAndRedirect}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item md={12} sm={12} xs={12}>
            <Field component={MuiTextField} label="Subject" name="noteSubject" margin="dense" variant="outlined" fullWidth required />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Field component={MuiTextField} label="Body" name="noteBody" multiline rows={6} margin="dense" variant="outlined" fullWidth required />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Button type="submit" buttonType="primary" disabled={pristine || submitting}>
              Add note
            </Button>
          </Grid>
        </Grid>
      </form>
    </NotesNewStyles>
  );
};

NotesNew.propTypes = propTypes;
NotesNew.defaultProps = defaultProps;

const NotesNewView = reduxForm({
  form: 'newNote',
  enableReinitialize: true,
  validate: NotesNewValidate,
})(NotesNew);

export default NotesNewView;
