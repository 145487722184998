import styled from 'styled-components';
// import theme from '../../../common/theme';

const OrgSearchableSelectorStyles = styled.div`
  margin-top: 10px;

  input {
    color: rgba(0, 0, 0, 0.87) !important;
    cursor: text;
    font-size: 1rem !important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    width: auto !important;
    margin-bottom: 0px !important;
  }
`;

export const Label = styled.span`
  top: auto !important;
  color: #6c7a89;
  bottom: 100%;
  display: inline !important;
  padding: 0;
  font-size: 12px;
  transform: translate(0px, 0px) !important;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
  padding-bottom: 5px;
  text-transform: uppercase;
`;

export default OrgSearchableSelectorStyles;
