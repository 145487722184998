import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { List } from 'immutable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { reduxForm, Field, MuiCheckbox, MuiSelectMultiple } from '../../../../common/form';
import Button from '../../../../common/components/button/index';
import { Card } from '../../../../common/styles/layout';
import detailsFormValidate from './details_form_validate';
import UserModel from '../../../../session/models/user_model';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  // teamMembers: PropTypes.instanceOf(List).isRequired,
  submitting: PropTypes.bool.isRequired,
  organizationId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  certificationTypes: PropTypes.instanceOf(List).isRequired,
  listStaff: PropTypes.func.isRequired,
};

const defaultProps = {};

const DetailsForm = ({ listStaff, currentUser, organizationId, handleSubmit, previousPage, pristine, submitting, certificationTypes }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    listStaff(currentUser, organizationId);
  }, [listStaff, currentUser, organizationId]);

  return (
    <form onSubmit={handleSubmit}>
      <h2>Event Details</h2>
      <Card>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid container justify="flex-start" alignItems="flex-start" item xs={6}>
                <Grid item xs={12}>
                  <h3>Primary Services</h3>
                  <header>What are the primary services that your event provides? </header>
                  <Grid container spacing={0}>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.soft-skills" />} label="Soft Skills" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.subsidized" />} label="Subsidized Employment" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.work-readiness" />} label="Work Readiness Training" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.internship" />} label="Internships" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.career-counseling" />} label="Career Counseling" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.resume" />} label="Resume Help" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.training" />} label="Job Specific Training" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.mock-interviews" />} label="Mock Interviews" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.mentorship" />} label="Mentorship" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="serviceTypes.ged" />} label="GED" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} item xs={6}>
                <h3>What career interests do you serve?</h3>
                <header>We will be sure to show your listing to candidates looking for your area of career expertise.</header>
                <Grid item xs={12}>
                  <Field
                    component={MuiSelectMultiple}
                    variant="outlined"
                    options={certificationTypes}
                    label="Certification / Credential Gained"
                    name="gainedCertifications"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>
                {/* <Grid item xs={12}>
                  <MuiSelectMultiple options={teamMembers} label="Staff/Team Members Affiliated" name="staffIds" margin="dense" required />
                </Grid> */}
                <Grid item xs={12}>
                  <h3>What career interests do you serve?</h3>
                  <header>We will be sure to show your listing to candidates looking for your area of career expertise.</header>
                  <Grid container spacing={0}>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.administrative" />} label="Administrative" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.carpentry" />} label="Carpentry" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.computer" />} label="Computer" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.construction" />} label="Construction" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.customer-support" />} label="Customer Support" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.env-codes" />} label="Environmental codes" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.health-care" />} label="Health Care" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.info-technology" />} label="IT Training" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.manufacturing" />} label="Manufacturing" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.mechanical" />} label="Mechanical" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.plumbing" />} label="Plumbing" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.sales" />} label="Sales" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.site-supervision" />} label="Site supervision" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.teaching" />} label="Teaching" />
                    </Grid>
                    <Grid item md={6}>
                      <FormControlLabel control={<Field component={MuiCheckbox} name="careerInterestTypes.writing" />} label="Writing" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
      <Grid container>
        <Grid item xs={8}>
          <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
            Previous
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button buttonType="secondary" type="submit">
            Next Step
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

DetailsForm.propTypes = propTypes;
DetailsForm.defaultProps = defaultProps;

const DetailsFormView = reduxForm({
  form: 'eventForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: detailsFormValidate,
})(DetailsForm);

export default DetailsFormView;
