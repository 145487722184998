import { connect } from 'react-redux';
import PersonalInfoFormView from './personal_info_form_view';
import { getCurrentUser, getSystemEnumList } from '../../../../reducer';
import { createCandidate } from '../../../../solve/actions';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  genderTypes: getSystemEnumList(state, 'GenderType').map((item) => [item.get('slug'), item.get('label')]),
  ethnicityTypes: getSystemEnumList(state, 'EthnicityType').map((item) => [item.get('slug'), item.get('label')]),
});

const mapDispatchToProps = {
  createCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onSubmit: (values) => {
    const cleanAddress = values.get('addressAttributes').merge({
      address1: '',
      city: '',
      state: '',
      isPartialAddress: true,
    });
    const cleanValues = values.set('addressAttributes', cleanAddress);
    dispatchProps.createCandidate(stateProps.currentUser, cleanValues);
  },
});

const PersonalInfoFormContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PersonalInfoFormView);

export default PersonalInfoFormContainer;
