import styled from 'styled-components';
import theme from '../../../common/theme';

const CandidatesListStyles = styled.div``;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f6f2fb;
  color: #8752cd;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 24px;
  margin: 10px 0px 0px;

  @media ${theme.query.tabletMax} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export default CandidatesListStyles;
