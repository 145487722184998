import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  status: undefined,
  name: undefined,
  description: undefined,
  orgType: undefined,
  orgCategoryType: undefined,
  primaryWebsite: undefined,
  secondaryWebsite: undefined,
  phone: undefined,
  ein: undefined,
  industryType: undefined,
  willHireBackgrounds: undefined,
  logoUrl: undefined,
  parentOrganization: undefined,
  address: undefined,
  staffCount: 0,
};

class OrganizationModel extends Record(DEFAULT_ARGS) {
  static _name = 'Organization';
}

export default OrganizationModel;
