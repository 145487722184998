import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Hook that wraps useState and leverages useRef and useEffect to track when
 * a component is mounted and short-circuit the setState calls if needed.
 */
export function useAsyncState(initialValue) {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const [value, setValue] = useState(initialValue);
  const maybeSetValue = useCallback(
    (newValue) => {
      if (mounted.current) {
        setValue(newValue);
      }
    },
    [setValue, mounted],
  );

  return [value, maybeSetValue];
}

/**
 * Hook than handles the standard submitting/succeeded/failed state management
 */
export function useApiCall(call) {
  const [status, setStatus] = useAsyncState({ failed: false, submitting: false, success: false });
  const asyncCall = useCallback(
    async (...args) => {
      setStatus({ failed: false, submitting: true, success: status.success });
      try {
        await call(...args);
        setStatus({ submitting: false, failed: false, success: true });
      } catch (e) {
        setStatus({ submitting: false, failed: true, success: false });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [call],
  );
  return [asyncCall, status];
}

export const useDebouncedEffect = (callback, delay, deps = []) => {
  useEffect(() => {
    const timeout = setTimeout(callback, delay);
    return () => clearTimeout(timeout);
  }, [callback, delay, deps]);
};

export function useFetchOnce(user, id, fetch, found = undefined) {
  const [fetchId, setFetchId] = useState(id);
  const [fetching, setFetching] = useState(false);
  const [fetchAttempted, setFetchAttempted] = useState(!!found);

  if (fetchId !== id) {
    setFetching(false);
    setFetchAttempted(false);
    setFetchId(id);
  }

  useEffect(() => {
    if (found) {
      setFetchAttempted(true);
    } else if (!fetchAttempted && !fetching) {
      setFetching(true);
      setFetchAttempted(true);
      fetch(user, id)
        .then(() => setFetching(false))
        .catch(() => {
          setFetching(false);
        });
    }
  }, [user, id, fetch, fetching, fetchAttempted, found]);
  return fetchAttempted && !fetching;
}

/**
  Hook that returns true after a delay. Defaults to starting immediately unless second argument is falsy.
 */
export const useDelayed = (delay, startCountdown = true) => {
  const [isDelayed, setIsDelayed] = useState(true);
  useEffect(() => {
    if (startCountdown && isDelayed) {
      const isDelayedTimeout = setTimeout(() => {
        setIsDelayed(false);
      }, delay);
      return () => clearTimeout(isDelayedTimeout);
    }
    return undefined;
  }, [delay, startCountdown, isDelayed]);

  return !isDelayed;
};
