export const ACTIVE = 'active';
export const CLOSED = 'closed';
export const PRE_PUBLISHED = 'pre_published';
export const CANCELED = 'canceled';

export const JOB_STATE_OPTIONS = {
  [ACTIVE]: 'Active',
  [CLOSED]: 'Closed',
  [PRE_PUBLISHED]: 'Pre published',
  [CANCELED]: 'Canceled',
};
