import { connect } from 'react-redux';
import { fromJS } from 'immutable';
import ResumeView from '../../../resume/components/resume/resume_view';
import { getCandidateResumeForCandidate, getCurrentUser, getCandidate } from '../../../reducer';
import { listResumes, createResume, updateResume, uploadResume, fetchCandidate } from '../../../solve/actions';

const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    if (file.rawFile) {
      reader.readAsDataURL(file.rawFile);
    } else {
      reader.readAsDataURL(file[0]);
    }

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const currentUser = getCurrentUser(state);
  const resume = getCandidateResumeForCandidate(state, id);
  const resumeKey = 'liveResume';
  const contactInfo = getCandidate(state, id);
  const initialValues = (resume && resume.get(resumeKey)) || fromJS({});

  return {
    currentUser,
    candidateId: id,
    staff: true,
    resume,
    contactInfo,
    initialValues,
  };
};

const mapDispatchToProps = {
  listResumes,
  fetchCandidate,
  createResume,
  updateResume,
  uploadResume,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    let apiCall;
    if (stateProps.resume) {
      apiCall = dispatchProps.updateResume(stateProps.currentUser, stateProps.resume.get('id'), values);
    } else {
      apiCall = dispatchProps.createResume(stateProps.currentUser, stateProps.candidateId, values);
    }

    if (ownProps.onComplete) {
      apiCall.then(ownProps.onComplete);
    }
    return apiCall;
  },
  onUpload: (values) =>
    convertFileToBase64(values)
      .then((base64) => ({
        src: base64,
      }))
      .then((file) => dispatchProps.uploadResume(stateProps.currentUser, stateProps.candidateId, file)),
  ...dispatchProps,
  ...stateProps,
  ...ownProps,
});

const ResumeContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ResumeView);

export default ResumeContainer;
