import { connect } from 'react-redux';
import ProgramView from './program_view';
import { getProgram, getCurrentUser, getPublicLinkForPosting } from '../../../reducer';
import { fetchProgram, publishProgram, closeProgram, draftProgram } from '../../../solve/actions';
import { createPublicLink } from '../../../public_link/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const program = getProgram(state, id);
  const user = getCurrentUser(state);
  const canEdit = program && program.getIn(['postingOrganization', 'id']) === user.getIn(['staffOrganization', 'id']);
  return {
    programId: id,
    program,
    user,
    publicLink: getPublicLinkForPosting(state, id),
    canEdit,
  };
};

const mapDispatchToProps = {
  fetchProgram,
  createPublicLink,
  publishProgram,
  closeProgram,
  draftProgram,
};

const ProgramContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramView);

export default ProgramContainer;
