import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Link, Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import * as toast from 'risekit/common/components/toast';

import ResumeStyles from './resume_styles';
import { Center, Card } from '../../../common/styles/layout';
import Education from './education';
import Programs from './programs';
import ContactInfo from './contact_info';
import Experience from './experience';
import Skills from './skills';
import Industries from './industries';
import Unions from './unions';
import Certifications from './certifications';
import { FieldArray, reduxForm, formValueSelector, Field, MuiFileUpload } from '../../../common/form';
import { ActionButton } from '../../../common/components/buttons';
import Progress from '../../../ui/components/progress';
import { Spinner, TextHeading } from '../../../common/components';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import resumeFormValidate from './resume_form_validate';
import ResumeUpdateInfo from './resume_update_info';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  candidateId: PropTypes.string.isRequired,
  contactInfo: PropTypes.shape({}).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  staff: PropTypes.bool,
  updatedBy: PropTypes.instanceOf(Map),
  resume: PropTypes.shape({
    getIn: PropTypes.func,
  }),
  listResumes: PropTypes.func.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  pendingApprovalState: PropTypes.bool,
  onUpload: PropTypes.func.isRequired,
  onApproveChanges: PropTypes.func.isRequired,
  onDeclineChanges: PropTypes.func.isRequired,
  onContinue: PropTypes.func,
  candidateResumeCompletionPercentage: PropTypes.number,
};

const defaultProps = {
  staff: undefined,
  updatedBy: undefined,
  resume: undefined,
  pendingApprovalState: false,
  onContinue: undefined,
  candidateResumeCompletionPercentage: 0,
};

const ProgressViewRender = ({ percentage, percentLabel }) => <Progress percent={percentage} percentLabel={percentLabel} />;

const checkNonEmpty = (value) => value?.size && !(typeof value.get(0) === 'object' && value.get(0).size === 0);

ProgressViewRender.propTypes = {
  percentage: PropTypes.string.isRequired,
  percentLabel: PropTypes.string.isRequired,
};

const selector = formValueSelector('resumeForm');

const ProgressView = connect((state) => {
  const workExperience = selector(state, 'workExperience');
  const skills = selector(state, 'skills');
  const industries = selector(state, 'industries');
  const unions = selector(state, 'unions');
  const certifications = selector(state, 'certifications');
  const education = selector(state, 'education');
  const trainingPrograms = selector(state, 'trainingPrograms');

  const formValues = [workExperience, skills, industries, unions, certifications, education, trainingPrograms];
  const percentage = Math.ceil((formValues.filter(checkNonEmpty).length / 7) * 100);
  const percentLabel = `${formValues.filter(checkNonEmpty).length}/7`;

  return {
    percentage,
    percentLabel,
  };
})(ProgressViewRender);

const Resume = ({
  candidateId,
  contactInfo,
  currentUser,
  staff,
  updatedBy,
  resume,
  pendingApprovalState,
  onApproveChanges,
  onDeclineChanges,
  listResumes,
  fetchCandidate,
  handleSubmit,
  pristine,
  onUpload,
  onContinue,
  candidateResumeCompletionPercentage,
}) => {
  const [redirect, setRedirect] = useState(false);
  const [clearUpload, setClearUpload] = useState(false);
  const fetchDone = useFetchOnce(currentUser, candidateId, listResumes);
  useFetchOnce(currentUser, candidateId, fetchCandidate, contactInfo);
  const isUserCandidate = currentUser?.get('candidateId');

  const SUCCESS_RESUME_INFO_MESSAGE = 'Now that you have completed your resume, you can download a copy to your computer, and use that to apply for jobs.';

  if (!resume && !fetchDone) {
    return <Spinner />;
  }

  if (redirect) {
    return <Redirect to={`/staff/candidates/${candidateId}`} />;
  }

  const handleSubmitAndScrollTop = (event) => {
    try {
      handleSubmit(event).then((_results) => {
        toast.success({ title: 'Success', message: 'Resume saved successfully.' });
        toast.info({
          title: 'Info',
          message: SUCCESS_RESUME_INFO_MESSAGE,
          extraSettingParams: {
            timeout: 8000,
          },
        });
        window.scrollTo(0, 0);
      });
    } catch (err) {
      toast.error({ title: 'Error', message: 'Resume not saved. Please check the fields for errors and try again.' });
    }
  };

  const handleSubmitAndRedirect = (event) => {
    try {
      handleSubmit(event).then((_results) => {
        toast.success({ title: 'Success', message: 'Resume saved successfully.' });
        return setRedirect(true);
      });
    } catch (err) {
      toast.error({ title: 'Error', message: 'Resume not saved. Please check the fields for errors and try again.' });
    }
  };

  const handleUploadAndScrollTop = (event) => {
    try {
      const { type } = event[0];
      if (type.substr(0, type.indexOf('/')) === 'image') {
        toast.error({ title: 'Error', message: 'File type not supported' });
        setClearUpload(true);
      } else {
        onUpload(event).then(() => {
          toast.success({ title: 'Success', message: 'Resume saved successfully.' });
          toast.info({
            title: 'Info',
            message: SUCCESS_RESUME_INFO_MESSAGE,
            extraSettingParams: {
              timeout: 8000,
            },
          });
          window.scrollTo(0, 0);
        });
      }
    } catch (err) {
      toast.error({ title: 'Error', message: 'Failed to upload resume. Please try again' });
    }
  };

  const handleApproveAndScrollTop = (event) => {
    try {
      onApproveChanges(event).then(() => {
        toast.success({ title: 'Success', message: 'Changes Approved' });
        window.scrollTo(0, 0);
      });
    } catch (err) {
      toast.error({ title: 'Error', message: 'Failed to approve changes please try again' });
    }
  };

  const handleDeclineAndScrollTop = (event) => {
    try {
      onDeclineChanges(event).then(() => {
        toast.success({ title: 'Success', message: 'Changes Declined' });
        window.scrollTo(0, 0);
      });
    } catch (err) {
      toast.error({ title: 'Error', message: 'Failed to decline changes please try again' });
    }
  };

  const persistAction = resume ? 'Save Resume' : 'Create Resume';
  const persistButton = (
    <ActionButton
      disabled={pristine && !(resume && resume.getIn(['candidatesVersionOfResume', 'unsaved']))}
      onClick={staff ? handleSubmitAndRedirect : handleSubmitAndScrollTop}
    >
      {persistAction}
    </ActionButton>
  );

  const renderContinueButton = () => {
    if (!onContinue || !resume || candidateResumeCompletionPercentage === 0) {
      return '';
    }

    return (
      <ActionButton className="continue-button" onClick={onContinue}>
        Continue
      </ActionButton>
    );
  };

  const cancelButton = (
    <Link to={`/staff/candidates/${candidateId}`} className="back-link">
      <KeyboardBackspaceIcon />
      Go Back
    </Link>
  );

  const UPDATED_BY_MESSAGE_DEFAULT = 'Changes to your resume are pending';

  const ApproveChangesPrompt = (
    <Card>
      <Grid container justify="center" alignItems="center" spacing={2}>
        <Grid item xs={12}>
          <TextHeading size="large" weight="bold" style={{ textAlign: 'center' }}>
            {updatedBy ? `${updatedBy.get('firstName')} ${updatedBy.get('lastName')} has made changes to your resume` : UPDATED_BY_MESSAGE_DEFAULT}
          </TextHeading>
        </Grid>
        <Grid item xs={12}>
          <TextHeading size="small" weight="bold" style={{ textAlign: 'center' }}>
            Approve or decline changes
          </TextHeading>
        </Grid>
        <Grid item xs={12} container justify="center" alignItems="center" spacing={2}>
          <Grid item>
            <ActionButton onClick={handleApproveAndScrollTop}>Approve</ActionButton>
          </Grid>
          <Grid item>
            <ActionButton onClick={handleDeclineAndScrollTop}>Decline</ActionButton>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );

  const viewResumeButton = (
    <Link to={staff ? `/staff/candidates/${candidateId}` : '/candidate/resume/view'} style={{ float: 'right' }}>
      <ActionButton>View Resume</ActionButton>
    </Link>
  );

  return (
    <ResumeStyles>
      <form onSubmit={handleSubmit}>
        {/* <p> */}
        {/*  <i className="fa fa-download" /> Download PDF */}
        {/* </p> */}
        {pendingApprovalState && ApproveChangesPrompt}
        {staff && cancelButton}

        {isUserCandidate && <ResumeUpdateInfo />}
        <div className="button-group">
          <div className="upload">
            <Field
              component={MuiFileUpload}
              onChange={handleUploadAndScrollTop}
              label="Upload Resume"
              name="uploadResume"
              margin="dense"
              className="upload-button"
              clear={clearUpload}
            />
            <p style={{ marginTop: '3px' }}>
              <small>
                <i>Upload PDF or other document. Images are not currently supported.</i>
              </small>
            </p>
          </div>
          <div className="view-continue-buttons__container">
            {resume && viewResumeButton}
            {renderContinueButton()}
          </div>
        </div>
        <Center>
          {persistButton}
          {staff && cancelButton}
        </Center>
        <ProgressView />
        <ContactInfo contactInfo={contactInfo} />
        <FieldArray name="workExperience" component={Experience} />
        <FieldArray name="skills" component={Skills} />
        <FieldArray name="industries" component={Industries} />
        <FieldArray name="unions" component={Unions} />
        <FieldArray name="certifications" component={Certifications} />
        <FieldArray name="education" component={Education} />
        <FieldArray name="trainingPrograms" component={Programs} />
        <h3>&nbsp;</h3>
        <Center>
          {persistButton}
          {staff && cancelButton}
        </Center>
      </form>
    </ResumeStyles>
  );
};

Resume.propTypes = propTypes;
Resume.defaultProps = defaultProps;

const ResumeView = reduxForm({
  form: 'resumeForm',
  destroyOnUnmount: true,
  enableReinitialize: true,
  validate: resumeFormValidate,
})(Resume);

export default ResumeView;
