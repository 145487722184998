import styled from 'styled-components';
import theme from '../../../common/theme';

const OrganizationFormStyles = styled.div`
  h3 {
    color: ${(props) => props.theme.color.secondary};
    letter-spacing: 0;
    margin-bottom: 0;
  }
  h4 {
    margin: 0;
    margin-top: 20px;
  }
  cite {
    font-style: normal;
    text-transform: uppercase;
    color: ${theme.color.text.medium};
    font-size: 12px;
  }
  .category {
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    border: solid 1px ${(props) => props.theme.color.secondaryAction};
    h4 {
      margin: 0;
    }
  }
  .category-error {
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
    border: solid 1px red;
    h4 {
      margin: 0;
    }
  }
  .button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
  }
  .button-group button {
    margin-left: 15px;
  }
  @media screen and (min-width: 1280px) {
    .MuiGrid-grid-lg-6 {
      margin-top: 15px;
    }
    .MuiGrid-grid-lg-6:nth-child(1) {
      padding-right: 20px;
    }
    .MuiGrid-grid-lg-6:nth-child(2) {
      padding-left: 20px;
      border-left: solid 1px #edf1f7;
    }
  }
`;

export default OrganizationFormStyles;
