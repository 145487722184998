import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';

import { useApiCall } from '../../../../util/hooks';
import { getCurrentUser } from '../../../../reducer';
import Spinner from '../../../../common/components/spinner';
import PersonalInfoFormStyles from './personal_info_form_styles';
import { Text, TextHeading } from '../../../../common/components';
import personalInfoFormValidate from './personal_info_form_validate';
import { reduxForm, Field, MuiTextField, MuiSelect, MuiDatePicker, phoneMask, zipCodeMask } from '../../../../common/form';

const propTypes = {
  onNext: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  genderTypes: PropTypes.instanceOf(OrderedSet),
  ethnicityTypes: PropTypes.instanceOf(OrderedSet),
};

const defaultProps = {
  genderTypes: new OrderedSet(),
  ethnicityTypes: new OrderedSet(),
};

const PersonalInfoForm = ({ handleSubmit, genderTypes, ethnicityTypes }) => {
  const [onSubmit, { submitting, failed }] = useApiCall(handleSubmit);

  return (
    <PersonalInfoFormStyles>
      <form onSubmit={onSubmit}>
        <Text color="medium" size="small">
          We use this information to help you find a job
        </Text>
        <TextHeading size="large" margin="both">
          Personal Info
        </TextHeading>
        <div className="fields-2">
          <Field component={MuiTextField} label="First Name" name="userAttributes.firstName" margin="dense" variant="outlined" fullWidth required />
          <Field component={MuiTextField} label="Last Name" name="userAttributes.lastName" margin="dense" variant="outlined" fullWidth required />
        </div>

        <div className="fields-2">
          <Field component={MuiDatePicker} label="Birthday (mm-dd-yyyy)" name="birthday" margin="dense" variant="outlined" fullWidth required />
          <Field component={MuiTextField} label="Primary Email" name="userAttributes.email" type="email" margin="dense" variant="outlined" fullWidth />
        </div>

        <div className="fields-2">
          <Field
            variant="outlined"
            component={MuiTextField}
            label="Phone Number"
            name="userAttributes.phone"
            margin="dense"
            type="cell"
            fullWidth
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...phoneMask}
          />
          <Field
            variant="outlined"
            component={MuiTextField}
            label="Zip Code"
            name="addressAttributes.zip"
            margin="dense"
            fullWidth
            required
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...zipCodeMask}
          />
        </div>

        <Text color="medium" size="small">
          <p>Some additional information will help us suggest even better job opportunities and resources:</p>
        </Text>

        <div className="fields-2">
          <MuiSelect options={genderTypes} label="Gender" name="genderType" margin="dense" required />
          <MuiSelect options={ethnicityTypes} label="Ethnicity Type" name="ethnicityType" margin="dense" required />
        </div>

        <div className="fields-2">
          <Field component={MuiTextField} label="LinkedIn Url" name="linkedInUrl" margin="dense" variant="outlined" />
          <MuiSelect
            options={[
              [true, 'Yes'],
              [false, 'No'],
            ]}
            label="Have you been in the military"
            name="military"
            margin="dense"
          />
        </div>
        <br />
        <br />

        <input type="submit" className="btn" value="Create an Account" disabled={submitting} />

        <span className="status">
          {failed && <span className="error">Unable to register a new account.</span>}
          {submitting && <Spinner />}
        </span>
      </form>
    </PersonalInfoFormStyles>
  );
};

PersonalInfoForm.propTypes = propTypes;
PersonalInfoForm.defaultProps = defaultProps;

const PersonalInfoReduxForm = reduxForm({
  form: 'personalInfoForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  validate: personalInfoFormValidate,
})(PersonalInfoForm);

// const PersonalInfoFormView = connect(
//   {
//     initialValues: {
//       userAttributes: {
//         email: "test",
//         phone: "",
//       },
//     },
//   },
// )(PersonalInfoReduxForm);

const PersonalInfoFormView = connect((state) => ({
  initialValues: {
    userAttributes: {
      email: getCurrentUser(state).email,
      phone: getCurrentUser(state).phone,
    },
  },
}))(PersonalInfoReduxForm);

export default PersonalInfoFormView;
