import { connect } from 'react-redux';
import StaffProgramTileView from './staff_program_tile_view';
import { getCurrentUser, getEvent, getProgram, getService } from '../../../../reducer';

const mapStateToProps = (state, { id, type }) => {
  let posting;
  let postingType;
  let postingUrl;
  let abService;

  switch (type) {
    case 'event':
      posting = getEvent(state, id);
      postingType = 'events';
      postingUrl = `/staff/events/${id}`;
      break;
    case 'service':
      posting = getService(state, id);
      abService = !!posting.get('attributeTags');
      postingType = 'services';
      postingUrl = `/staff/services/${id}`;
      break;
    default:
    case 'program':
      posting = getProgram(state, id);
      postingType = 'programs';
      postingUrl = `/staff/programs/${id}`;
      break;
  }

  const currentUser = getCurrentUser(state);

  let logoUrl = posting.get('logoUrl');
  if (!logoUrl) {
    logoUrl = posting.getIn(['postingOrganization', 'logoUrl']);
  }
  if (!logoUrl) {
    logoUrl = `/images/solve-${postingType}.png`;
  }
  const status = posting.get('state', 'AB');
  const logo = logoUrl;
  const title = posting.get('name');
  const city = abService ?
    posting
      .get('offices')
      .map((x) => x.get('city'))
      .toSet()
      .join(', ') :
    posting.getIn(['address', 'city']);
  const addressState = abService ?
    posting
      .get('offices')
      .map((x) => x.get('state'))
      .toSet()
      .join(', ') :
    posting.getIn(['address', 'state']);
  const zip = abService ?
    posting
      .get('offices')
      .map((x) => x.get('postal'))
      .toSet()
      .join(', ') :
    posting.getIn(['address', 'zip']);
  const serviceTypes = abService ? posting.get('serviceTags').toSet().toList() : posting.get('serviceTypes');
  const targetParticipantTypes = abService ? posting.get('attributeTags').toSet().toList() : posting.get('targetParticipantTypes');
  const isMyOrg = abService ? false : posting.getIn(['postingOrganization', 'id']) === currentUser.getIn(['staffOrganization', 'id']);
  return {
    id,
    currentUser,
    logo,
    status,
    title,
    city,
    zip,
    serviceTypes,
    targetParticipantTypes,
    isMyOrg,
    addressState,
    postingUrl,
  };
};

const mapDispatchToProps = {};
const StaffProgramTileContainer = connect(mapStateToProps, mapDispatchToProps)(StaffProgramTileView);

export default StaffProgramTileContainer;
