import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map } from 'immutable';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

import CircularSpinner from 'risekit/common/components/spinner';

import UserModel from '../../../session/models/user_model';
import ProgramApplicantTile from '../program_applicant_tile';
import { fetchProgramParticipantsReport } from '../../../api/real_backend';
import { useDebouncedEffect } from '../../../util/hooks';
import { Button } from '../../../common/components';
import { Banner } from '../candidates/candidates_styles';
import { downloadFileFromBlob } from '../../../util/file_operations';
import InviteApplicantTile from '../invite_applicant_tile';
import ImmediateUploadButton from '../../../common/components/immediate_upload_button';
import ProgramParticipantsStyles, { Header } from './program_participants_styles';
import ProgramParticipantsFilters from './program_participants_filters';

function useSearch(search, setSearch) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const ProgramParticipantsView = ({
  user,
  programId,
  program,
  candidatePrograms,
  listFilteredCandidatesForProgram,
  loaded,
  filters,
  search,
  setSearch,
  createInvitationsFromFile,
  fetchProgram,
  listInvitations,
  // listStaffInvitations,
  invitations,
  resendInvitation,
}) => {
  const [isFetchingProgram, setIsFetchingProgram] = useState(true);

  useEffect(() => {
    if (program) {
      setIsFetchingProgram(false);
    } else if (programId) {
      fetchProgram(user, programId);
    }
  }, [programId, program, fetchProgram, user]);

  useEffect(() => {
    // if (!loaded) { // TODO: fix loading issue not retriggering on load program page
    const query = filters.merge({ userName: search });
    listFilteredCandidatesForProgram(user, programId, query);
    // }
  }, [loaded, filters, search, listFilteredCandidatesForProgram, user, programId]);

  useEffect(() => {
    if (!invitations || invitations.size === 0) {
      listInvitations(user);
    }
  }, [invitations, user, listInvitations]);

  // useEffect(() => {
  //   if (!invitations || invitations.size === 0) {
  //     listStaffInvitations(user, 'programs', programId, filters.merge({ userName: search }));
  //   }
  // }, [invitations, listStaffInvitations, user, programId, filters, search]);

  function downloadCsvReport() {
    fetchProgramParticipantsReport(user, programId).then((response) => {
      downloadFileFromBlob(response.data, `program_participants_${programId}.csv`);
    });
  }

  const { newSearch, onSearchChange } = useSearch(search, setSearch);

  if (isFetchingProgram) {
    return <CircularSpinner />;
  }

  return (
    <ProgramParticipantsStyles>
      <Header>
        <h2>
          Program Post:
          <span style={{ fontWeight: 'normal' }}> {program.get('name')} </span>
        </h2>
        <ImmediateUploadButton buttonLabel="+ Invite candidates from a file" onUpload={createInvitationsFromFile} margin="0 10px" />
      </Header>
      <Banner>
        Results: {candidatePrograms.size} Candidates{invitations.size !== 0 ? `, ${invitations.size} Invitations` : ''}
        <Button buttonType="secondary-outline" onClick={downloadCsvReport}>
          Download Candidates Report
        </Button>
      </Banner>

      <TextField
        id="search"
        placeholder="Search for a candidate by name"
        margin="dense"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 30 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={newSearch}
        onChange={onSearchChange}
      />
      <ProgramParticipantsFilters />

      {!candidatePrograms.size && !invitations.size ? (
        <div>This Program Has No Applicants</div>
      ) : (
        <React.Fragment>
          <div>
            {candidatePrograms.map((candidateProgram) => (
              <ProgramApplicantTile key={candidateProgram.get('id')} candidateProgram={candidateProgram} />
            ))}
          </div>
          <div>
            {invitations &&
              invitations.map((invitation) => (
                <InviteApplicantTile key={invitation.get('id')} invitation={invitation} resendInvitation={resendInvitation} type="program" />
              ))}
          </div>
        </React.Fragment>
      )}
    </ProgramParticipantsStyles>
  );
};

ProgramParticipantsView.propTypes = {
  candidatePrograms: PropTypes.instanceOf(OrderedSet),
  listFilteredCandidatesForProgram: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  programId: PropTypes.string.isRequired,
  program: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  createInvitationsFromFile: PropTypes.func.isRequired,
  invitations: PropTypes.instanceOf(OrderedSet),
  listInvitations: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  fetchProgram: PropTypes.func.isRequired,
};

ProgramParticipantsView.defaultProps = {
  candidatePrograms: undefined,
  invitations: undefined,
};

export default ProgramParticipantsView;
