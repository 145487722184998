import { connect } from 'react-redux';
import { fromJS, List, Map } from 'immutable';
import AddProgramView from './add_program_view';
import { getCurrentUser, getGlobalSurveysOfType, getProgram } from '../../../reducer';
import { createProgram, updateProgram, publishProgram, cancelProgram, draftProgram, resetForm } from '../../../solve/actions';

const LEGACY_SERVICE_TYPES_MAPPING = {
  softSkills: 'soft-skills',
  subsidizedEmployment: 'subsidized',
  workReadinessTraining: 'work-readiness',
  internships: 'internship',
  careerCounseling: 'career-counseling',
  resumeHelp: 'resume',
  jobSpecificTraining: 'training',
  mockInterviews: 'mock-interviews',
};

const POTENTIAL_SERVICE_TYPES = [
  'soft-skills',
  'subsidized',
  'work-readiness',
  'internship',
  'career-counseling',
  'resume',
  'training',
  'mock-interviews',
  'mentorship',
  'ged',
];

const LEGACY_SUPPORT_TYPES_MAPPING = {
  housing: 'housing',
  childCare: 'child-care',
  clothingBank: 'clothing',
  education: 'Eommunity',
  foodPantry: 'food',
  healthInsurance: 'health-insurance',
  jobTraining: 'job-training',
  legalServices: 'legal-services',
};

const POTENTIAL_SUPPORT_TYPES = [
  'food',
  'housing',
  'child-care',
  'clothing',
  'community',
  'finances',
  'health-and-support',
  'jobTraining',
  'legal-services',
];

const LEGACY_CAREER_INTEREST_TYPES_MAPPING = {
  customerSupport: 'customer-support',
  envCodes: 'env-codes',
  healthCare: 'health-care',
  infoTechnology: 'info-technology',
  siteSupervision: 'site-supervision',
};

const POTENTIAL_CAREER_INTEREST_TYPES = [
  'administrative',
  'carpentry',
  'computer',
  'construction',
  'customer-support',
  'env-codes',
  'health-care',
  'info-technology',
  'manufacturing',
  'mechanical',
  'plumbing',
  'sales',
  'site-supervision',
  'teaching',
  'writing',
];

const LEGACY_TARGET_PARTICIPANT_TYPES_MAPPING = {
  twoYearDegree: '2-year-degree',
  fourYearDegree: '4-year-degree',
  designatedCommunityResidentEmpowermentZone: 'empowerment-zone',
  englishSecondLanguage: 'english-2nd-lang',
  formerlyIncarcerated: 'was-incarcerated',
  highSchoolDropOut: 'hs-drop-out',
  highSchoolStudents: 'hs-student',
  opportunityYouth: 'opportunity-youth',
  singleMoms: 'single-moms',
  singleFathers: 'single-fathers',
  singleParents: 'single-parents',
  snapRecipient: 'snap',
  supplementalSocialSecurityIncome: 'supplement-social-security',
  tanfRecipient: 'tanf',
  wioaEligibleAdult: 'wioa-adult',
  wioaEligibleYouth: 'wioa-youth',
};

const POTENTIAL_TARGET_PARTICIPANT_TYPES = [
  '2-year-degree',
  '4-year-degree',
  'empowerment-zone',
  'disability',
  'english-2nd-lang',
  'was-incarcerated',
  'hs-drop-out',
  'hs-student',
  'homeless',
  'hud',
  'opportunity-youth',
  'single-moms',
  'single-fathers',
  'single-parents',
  'snap',
  'supplement-social-security',
  'tanf',
  'unemployment',
  'veterans',
  'wioa-adult',
  'wioa-youth',
];

function ingestSimpleList(potential, actual) {
  const basicLookup = Map(potential.map((oppo) => [oppo, actual.includes(oppo) ? 'yes' : undefined]));
  return basicLookup;
}

function digestSimpleList(list) {
  if (!list) {
    return List();
  }
  return list
    .filter((value) => value)
    .keySeq()
    .toList();
}

const DEFAULT_INITIAL_VALUES = fromJS({ addressAttributes: { isPartialAddress: true } });

function buildInitialValues(existingProgram, surveyTemplate) {
  if (existingProgram) {
    const addressAttributes = fromJS({ isPartialAddress: true }).merge(existingProgram.get('address'));
    // TODO: need to convert surveys into custom screening question attributes
    const serviceTypes = ingestSimpleList(
      POTENTIAL_SERVICE_TYPES,
      existingProgram.get('serviceTypes').map((item) => LEGACY_SERVICE_TYPES_MAPPING[item] || item),
    );
    const supportTypes = ingestSimpleList(
      POTENTIAL_SUPPORT_TYPES,
      existingProgram.get('supportTypes').map((item) => LEGACY_SUPPORT_TYPES_MAPPING[item] || item),
    );
    const careerInterestTypes = ingestSimpleList(
      POTENTIAL_CAREER_INTEREST_TYPES,
      existingProgram.get('careerInterestTypes').map((item) => LEGACY_CAREER_INTEREST_TYPES_MAPPING[item] || item),
    );
    const targetParticipantTypes = ingestSimpleList(
      POTENTIAL_TARGET_PARTICIPANT_TYPES,
      existingProgram.get('targetParticipantTypes').map((item) => LEGACY_TARGET_PARTICIPANT_TYPES_MAPPING[item] || item),
    );
    const survey = existingProgram.getIn(['surveys', 0]);
    const surveyQuestionAttributes = [];
    const isClass = existingProgram.get('isClass').toString();
    const isResumeRequired = existingProgram.get('isResumeRequired').toString();
    const payment = existingProgram.get('cost') > 0 ? 'yes' : 'no';
    const allAgesWelcomed = !existingProgram.get('targetMinimumAge') && !existingProgram.get('targetMaximumAge');
    const allGendersWelcomed = !existingProgram.get('targetGenderType');
    const participantsCanApplyOnSolve = !existingProgram.get('applicationWebsite');
    if (survey) {
      const questionTemplateId = surveyTemplate.getIn(['surveyQuestions', 0, 'surveyQuestion', 'questionTemplate', 'id']);
      survey.get('surveyQuestions').forEach((item) => {
        const question = item.get('surveyQuestion');
        const firstPlaceholder = question.getIn(['extra', 'questionPlaceholders', '<0>']);
        let extra = question.get('extra');
        if (firstPlaceholder && List.isList(firstPlaceholder)) {
          extra = extra.setIn(['questionPlaceholders', '<0>'], firstPlaceholder.first());
        }
        const values = {
          id: question.get('id'),
          position: question.get('position'),
          extra,
          questionTemplateId,
          questionImportanceType: question.get('questionImportanceType') || 'preferred',
        };
        surveyQuestionAttributes.push(values);
      });
    }
    return DEFAULT_INITIAL_VALUES.merge(existingProgram).merge({
      isClass,
      isResumeRequired,
      payment,
      allAgesWelcomed,
      allGendersWelcomed,
      participantsCanApplyOnSolve,
      addressAttributes,
      serviceTypes,
      supportTypes,
      careerInterestTypes,
      targetParticipantTypes,
    });
  }
  return DEFAULT_INITIAL_VALUES;
}

function injectSurvey(surveyType, values) {
  const questions = values.get('surveyQuestionAttributes', List()).filter((options) => options.getIn(['extra', 'questionPlaceholders', '<0>']));
  if (questions.size === 0) {
    return values;
  }

  const questionsWithId = questions.map((question, idx) => question.set('position', idx + 1));

  return values.merge({
    surveysAttributes: [
      {
        title: 'Screening Questions',
        id: values.getIn(['surveys', 0, 'id']),
        surveyType,
        surveyQuestionsAttributes: questionsWithId,
      },
    ],
  });
}

function buildSubmitValues(values) {
  const withSurvey = injectSurvey('program-screening', values);

  const withLists = withSurvey.merge({
    serviceTypes: digestSimpleList(values.get('serviceTypes')),
    supportTypes: digestSimpleList(values.get('supportTypes')),
    careerInterestTypes: digestSimpleList(values.get('careerInterestTypes')),
    targetParticipantTypes: digestSimpleList(values.get('targetParticipantTypes')),
  });

  const cost = values.get('payment') === 'yes' ? values.get('cost') : '0';
  const targetMinimumAge = values.get('allAgesWelcomed') ? null : values.get('targetMinimumAge');
  const targetMaximumAge = values.get('allAgesWelcomed') ? null : values.get('targetMaximumAge');
  const targetGenderType = values.get('allGendersWelcomed') ? null : values.get('targetGenderType');
  const applicationWebsite = values.get('participantsCanApplyOnSolve') ? null : values.get('applicationWebsite');

  const withCheckboxValues = withLists.merge({
    cost,
    targetMinimumAge,
    targetMaximumAge,
    targetGenderType,
    applicationWebsite,
  });

  const logo = values.get('programLogo');
  let withLogo = withCheckboxValues.delete('programLogo');
  if (logo) {
    withLogo = withLogo.set('logo', logo[0]);
  }
  if (values.get('days')) {
    const days = values
      .get('days')
      .filter((x) => x)
      .keySeq();
    return withLogo.merge({
      days,
    });
  }
  return withLogo;
}

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => ({
  initialValues: buildInitialValues(id && getProgram(state, id), getGlobalSurveysOfType(state, 'program-screening-template').first()),
  program: id && getProgram(state, id),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  createProgram,
  updateProgram,
  publishProgram,
  cancelProgram,
  draftProgram,
  resetForm,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  handleSaveDraft: (formValues) => {
    const values = buildSubmitValues(formValues);
    if (ownProps.match.params.id) {
      return dispatchProps
        .updateProgram(stateProps.currentUser, ownProps.match.params.id, values)
        .then((res) => dispatchProps.draftProgram(stateProps.currentUser, res.result));
    }
    return dispatchProps.createProgram(stateProps.currentUser, stateProps.currentUser.staffOrganization.id, values);
  },
  handleSubmit: (formValues) => {
    const values = buildSubmitValues(formValues);
    if (ownProps.match.params.id && stateProps.program.state === 'active') {
      return dispatchProps.cancelProgram(stateProps.currentUser, ownProps.match.params.id);
    }
    if (ownProps.match.params.id) {
      return dispatchProps
        .updateProgram(stateProps.currentUser, ownProps.match.params.id, values)
        .then((res) => dispatchProps.publishProgram(stateProps.currentUser, res.result));
    }
    return dispatchProps
      .createProgram(stateProps.currentUser, stateProps.currentUser.staffOrganization.id, values)
      .then((res) => dispatchProps.publishProgram(stateProps.currentUser, res.result));
  },
  handleCancel: () => dispatchProps.cancelProgram(stateProps.currentUser, ownProps.match.params.id),
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const AddProgramContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(AddProgramView);

export default AddProgramContainer;
