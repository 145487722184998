import styled from 'styled-components';
import theme from '../theme';

export const TileWrapper = styled.div`
  margin-top: 10px;
  box-shadow: 5px 5px 40px 0 rgba(0, 0, 0, 0.05);
`;

export const TileStyles = styled.div`
  position: relative;
  margin: 0px 0px 20px;
  padding: 35px;
  color: ${theme.color.grayDark};
  background-color: white;
  @media only screen and (max-width: 768px) {
    padding: 25px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Logo = styled.div`
  float: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: wrap;
  flex-direction: column
  padding-left: 20px;
  padding-bottom: 15px;
  img {
    max-width: 150px;
    max-height: 50px;
    width: auto;
  }
`;

export const Status = styled.div`
  margin-top: 5px;
`;

export const Pill = styled.span`
  height: 22px;
  display: flex;
  align-items: center;
  padding: 0px 10px 0px;
  border: solid 1px ${theme.color.grayLight};
  border-radius: 20px;
  text-transform: uppercase;
  font-size: 11px;
  margin-right: 20px;
  margin-bottom: 10px;
`;

export const Details = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  margin-bottom: 10px;
  flex-wrap: wrap;

  .detail-info {
    display: flex;
    align-items: center;
    margin-right: 20px;
    white-space: nowrap;
  }

  svg {
    margin-right: 5px;
    color: ${theme.color.grayLight};
  }
  .detail-info:nth-child(1) svg {
    margin-right: 8px;
  }
`;

export const Body = styled.div``;

export const Title = styled.div`
  margin: 5px 0px 2px;
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: bold;
`;

export const Organization = styled.div`
  margin-bottom: 10px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  &.jobs {
    margin-top: -20px;
  }
  @media only screen and (max-width: 650px) {
    margin-top: 0px;
  }
`;

export const Services = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 350px;
  margin-top: 15px;
  padding-right: 15px;

  .service {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  .service > span {
    flex: 1;
  }

  .heading {
    font-weight: bold;
    margin-right: 3px;
    white-space: nowrap;
  }

  .body {
    color: ${theme.color.gray};
  }

  svg {
    margin-top: -2px;
    margin-right: 7px;
    color: ${theme.color.grayLight};
  }

  @media only screen and (max-width: 650px) {
    & {
      min-width: auto;
      flex: auto;
      padding-top: 20px;
      margin-top: 0px;
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  align-self: flex-end;
  margin-left: auto;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    padding: 10px 20px;
    height: 46px;
  }

  a {
    width: 100%;
    flex: 1;
  }

  .subtle-buttons {
    display: flex;
  }

  .subtle-buttons button {
    font-size: 13px;
    transition: all 0.1s ease;
  }

  .subtle-buttons button:hover {
    color: ${theme.color.blue};
  }

  .subtle-buttons svg {
    width: 0.8em;
    height: 0.8em;
    margin-right: 3px;
  }

  // @media only screen and (max-width: 768px) {
  //   & {
  //     flex-wrap: wrap;
  //     width: 100%;
  //   }
  //   button {
  //     margin: 5px 0px;
  //     width: 100%;
  //   }
  //   .subtle-buttons {
  //     width: 100%;
  //   }
  //   .subtle-buttons button {
  //     flex: 1;
  //   }
  //   .subtle-buttons button:nth-child(1) {
  //     margin-right: 5px;
  //   }
  //   .subtle-buttons button:nth-child(2) {
  //     margin-left: 5px;
  //   }
  // }
  // @media only screen and (max-width: 600px) {
  //   button {
  //     width: calc(100% - 10px);
  //     flex: 1;
  //     margin: 5px 5px;
  //   }
  //   .subtle-buttons {
  //     width: auto;
  //     flex: 1;
  //   }
  //   .subtle-buttons button {
  //     width: auto;
  //   }
  // }
`;

export const IndeedLabel = styled.div`
  color: ${theme.color.gray};
`;
