import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {
  reduxForm,
  Field,
  EditorField,
  MuiTextField,
  MuiSelect,
  MuiSelectMultiple,
  MuiCheckbox,
  MuiDatePicker,
  zipCodeMask,
  currencyMask,
  // MuiFileUpload,
} from '../../../../common/form';
import { Button, OrgSearchableSelector } from '../../../../common/components';
import jobDetailsFormValidate from './job_details_form_validate';
import JobDetailsFormStyles from './job_details_form_styles';
import UserModel from '../../../../session/models/user_model';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  listStaff: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  organizationId: PropTypes.string.isRequired,
  wageSpecified: PropTypes.bool,
  noStartDate: PropTypes.bool,
  jobTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  payTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  industryTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  // logo: PropTypes.instanceOf(FileList).isRequired,
  // orgCategoryType: PropTypes.instanceOf(List).isRequired,
  contacts: PropTypes.instanceOf(OrderedSet).isRequired,
  employerId: PropTypes.string,
};

const defaultProps = {
  wageSpecified: false,
  noStartDate: false,
  employerId: undefined,
};

const JobDetailsForm = ({
  handleSubmit,
  listStaff,
  currentUser,
  organizationId,
  wageSpecified,
  noStartDate,
  jobTypes,
  payTypes,
  industryTypes,
  employerId,
  // logo,
  // orgCategoryType,
  contacts,
}) => {
  // TODO shouldn't be calling these if the data is recently fetched
  useEffect(() => {
    listStaff(currentUser, organizationId);
  }, [listStaff, currentUser, organizationId]);

  return (
    <JobDetailsFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiTextField} variant="outlined" label="Title Of Job" name="title" margin="dense" fullWidth required />
          </Grid>
          {/* <Grid item md={6} sm={12} xs={12} className="logo-upload">
            <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={0}>
              <Grid container justify="flex-start" alignItems="center">
                <Grid item xs={12}>
                  <div className="form-label">Logo</div>
                </Grid>
              </Grid>
              <Grid container justify="flex-start" alignItems="flex-start">
                <Field component={MuiFileUpload} label="Upload A Logo" name="jobPostingLogo" margin="dense" />
                {logo && <img alt="logoPreview" className="logo-image" src={URL.createObjectURL(logo[0])} height="70" width="auto" />}
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item md={6} sm={12} xs={12}>
            <MuiSelect variant="outlined" options={jobTypes.toList()} label="Type Of Job" name="jobType" margin="dense" required />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <MuiSelect variant="outlined" options={industryTypes.toList()} label="Select Industry" name="industryType" margin="dense" required />
          </Grid>
          {/* <Grid item md={6} sm={12} xs={12}>
            <MuiSelect variant="outlined" options={orgCategoryType} label="Select Category" name="orgCategoryType" margin="dense" required />
          </Grid> */}
          <Grid item md={6} sm={12} xs={12}>
            <Field component={OrgSearchableSelector} name="employerId" label="My Organization/Employer" value={employerId && employerId} required />
            <FormControlLabel control={<Field component={MuiCheckbox} name="employerVisibilityType" />} label="Make Employer Private*" />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field
              component={MuiSelectMultiple}
              variant="outlined"
              options={contacts.toList()}
              label="Contacts (Select multiple if appropriate)"
              name="staffIds"
              margin="dense"
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <MuiSelect
              variant="outlined"
              options={payTypes}
              label="Wage Type (hourly or salary)"
              name="payType"
              disabled={wageSpecified}
              margin="dense"
              required
            />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Field
              component={MuiTextField}
              variant="outlined"
              label="Wage/Salary Amount"
              name="payAmount"
              disabled={wageSpecified}
              margin="dense"
              fullWidth
              {...currencyMask} // eslint-disable-line
            />
            {/* <FormControlLabel control={<Field component={MuiCheckbox} name="wageSpecified" />} label="Not Specified" /> */}
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiTextField} variant="outlined" label="Address" name="addressAttributes.address1" margin="dense" fullWidth />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiTextField} variant="outlined" label="City" name="addressAttributes.city" margin="dense" fullWidth />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiTextField} variant="outlined" label="State" name="addressAttributes.state" margin="dense" fullWidth />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field
              component={MuiTextField}
              variant="outlined"
              label="Zip Code"
              name="addressAttributes.zip"
              margin="dense"
              fullWidth
              required
              {...zipCodeMask} // eslint-disable-line react/jsx-props-no-spreading
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiDatePicker} variant="outlined" label="Start Date" name="startDate" disabled={noStartDate} margin="dense" fullWidth />
            <FormControlLabel control={<Field component={MuiCheckbox} name="noStartDate" />} label="No Exact Start Date" />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiDatePicker} variant="outlined" label="End Date" name="endDate" disabled={noStartDate} margin="dense" fullWidth />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field
              component={MuiTextField}
              variant="outlined"
              label="Number of Openings"
              type="number"
              name="numberOfOpenings"
              margin="dense"
              fullWidth
              required
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Field component={MuiDatePicker} variant="outlined" label="Post Expiration Date" name="postExpirationDate" margin="dense" fullWidth required />
          </Grid>
          <Grid item md={12} style={{ padding: '8px' }}>
            <EditorField name="description" id="description" style={{ padding: '50px' }} label="Enter Description" required />
          </Grid>
        </Grid>
        <div className="button-group">
          <div className="button-left">
            <Link to="/staff/jobs">
              <Button buttonType="secondary-outline">Cancel</Button>
            </Link>
          </div>
          <div className="button-right">
            <Button buttonType="secondary" type="submit">
              Next Step
            </Button>
          </div>
        </div>
      </form>
    </JobDetailsFormStyles>
  );
};

JobDetailsForm.propTypes = propTypes;
JobDetailsForm.defaultProps = defaultProps;

const JobDetailsFormView = reduxForm({
  form: 'jobForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: jobDetailsFormValidate,
})(JobDetailsForm);

export default JobDetailsFormView;
