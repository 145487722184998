import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const styles = () => ({
  stepLabel: {
    cursor: (props) => (props.disabled ? 'default' : 'pointer !important'),
  },
  disabledStep: {
    cursor: 'default',
    '& svg, span': {
      color: '#e4e7e8',
    },
  },
});

// if on step 2, grey out step 3, if on step 3 grey out step 2
const CustomApplicantStepper = ({ steps, activeStep, setActiveStep, onChange, classes, fullWidth, backgroundColor, disabled }) => {
  const inputWidth = fullWidth ? '100%' : 'auto';
  return (
    <Stepper activeStep={activeStep} alternativeLabel disabled={disabled} style={{ width: inputWidth, backgroundColor }}>
      {steps.map(({ label, value }, idx) => {
        if (activeStep === 1) {
          return (
            <Step
              key={label}
              className={idx === 2 ? classes.disabledStep : ''}
              onClick={
                idx === 2 ?
                  () => {} :
                  async () => {
                    await onChange(value);
                    setActiveStep(idx);
                  }
              }
            >
              <StepLabel className={idx === 2 ? classes.disabledStep : classes.stepLabel}>{label}</StepLabel>
            </Step>
          );
        }
        if (activeStep === 2) {
          const stepProps = {};
          if (idx === 1) stepProps.completed = false;
          return (
            <Step
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...stepProps}
              key={label}
              className={idx === 1 ? classes.disabledStep : ''}
              onClick={
                idx === 1 ?
                  () => {} :
                  async () => {
                    await onChange(value);
                    setActiveStep(idx);
                  }
              }
            >
              <StepLabel className={idx === 1 ? classes.disabledStep : classes.stepLabel}>{label}</StepLabel>
            </Step>
          );
        }
        return (
          <Step
            key={label}
            onClick={async () => {
              await onChange(value);
              setActiveStep(idx);
            }}
          >
            <StepLabel className={classes.stepLabel}>{label}</StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

CustomApplicantStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]) })).isRequired,
  activeStep: PropTypes.number,
  setActiveStep: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  backgroundColor: PropTypes.string,
  classes: PropTypes.shape({
    stepLabel: PropTypes.string.isRequired,
    disabledStep: PropTypes.string.isRequired,
  }).isRequired,
};

CustomApplicantStepper.defaultProps = {
  activeStep: 0,
  disabled: false,
  fullWidth: false,
  backgroundColor: '#ffffff',
};

export default withStyles(styles)(CustomApplicantStepper);
