import styled from 'styled-components';
// import theme from '../../../common/theme';

const ProgramFlyerStyles = styled.div`
  padding: 40px;
  h1 {
    margin-top: 20px;
    font-family: 'Prata', serif;
    font-size: 40px;
    line-height: 40px;
    font-weight: 100;
    text-transform: uppercase;
    margin-bottom: 0;
    color: ${(props) => props.theme.color.primary};
  }
  h3 {
    color: #333;
  }
  ul {
    margin: 0;
    padding: 0;
    padding-left: 20px;
  }
  p,
  li {
    font-size: 14px;
    color: #777;
  }
  .contact-info {
    list-style: none;
    li {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      svg {
        margin-right: 15px;
        font-size: 18px;
        margin-top: 3px;
      }
    }
  }
`;

export default ProgramFlyerStyles;
