import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  canUpdate: undefined,
  noteSubject: undefined,
  noteBody: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  owningOrganizationId: undefined,
  candidateId: undefined,
  owningStaff: {},
};

class NoteModel extends Record(DEFAULT_ARGS) {
  static _name = 'Note';
}

export default NoteModel;
