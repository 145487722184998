import styled from 'styled-components';

export const ModeReportsStyles = styled.div`
  .mode-report__container {
    display: flex;
    padding: 20px;
    background-color: #f2f6ff;
    border-radius: 5px;
    cursor: pointer;
  }

  .mode-report__title {
    font-size: 16px;
    font-weight: 600;
    color: #22313f;
  }
`;
