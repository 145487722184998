import styled from 'styled-components';
// import theme from '../../../common/theme';

const TabberStyles = styled.div`
  @media only screen and (max-width: 768px) {
    .styled-tabs {
      overflow: auto;
    }
    .styled-tab {
      flex: auto;
      max-width: none;
      min-width: 90px;
    }
  }
`;

export default TabberStyles;
