import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router-dom';
import { MuiFormTheme, MuiThemeProvider } from '../../../common/form';
import ApplyToEventStyles from './apply_to_event_styles';
import ChallengesForm from './challenges_form';
import PrivacyAgreementForm from './privacy_agreement_form';
import QuestionnaireCompleted from './questionnaire_completed';
import AdditionalQuestionsForm from './additional_questions_form';
import AdditionalQuestionsCompleted from './additional_questions_completed';
import Questionnaire from '../questionnaire';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import Spinner from '../../../common/components/spinner';
import CandidateEventModel from '../../../solve/models/candidate_event_model';

const propTypes = {
  event: PropTypes.instanceOf(Map),
  fetchEvent: PropTypes.func.isRequired,
  createCandidateEvent: PropTypes.func.isRequired,
  eventId: PropTypes.string.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map).isRequired,
  candidateEvent: PropTypes.oneOfType([PropTypes.instanceOf(CandidateEventModel), PropTypes.instanceOf(Map)]),
};

const defaultProps = {
  event: undefined,
  candidateEvent: undefined,
};

const ApplyToEventView = ({ candidateEvent, event, createCandidateEvent, fetchEvent, eventId, currentUser, candidate }) => {
  const resolvedEvent = useFetchOnce(currentUser, eventId, fetchEvent, event && event.get('surveys'));
  const resolvedCandidateEvent = useFetchOnce(currentUser, eventId, createCandidateEvent, candidateEvent);

  const questionnaireStatus = candidate?.get('challengesCompletion');
  const shouldSkipQuestionnaire = questionnaireStatus === 'complete';

  const [page, setPage] = useState(1);

  if (!resolvedEvent || !resolvedCandidateEvent) {
    return <Spinner />;
  }
  if (!event) {
    // Redirect up one level to be handled there.
    return <Redirect to="/candidate/events" />;
  }
  if (!candidateEvent) {
    return 'Sorry, we are temporarily unable to process your request.';
  }

  const handlePrivacyAgreementFormSubmit = () => {
    const newPage = shouldSkipQuestionnaire ? 4 : 3;

    setPage(newPage);
  };

  return (
    <ApplyToEventStyles>
      <div className="content-area">
        <div className="wrapper">
          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {page === 1 && <ChallengesForm onSubmit={() => setPage(page + 1)} />}
              {page === 2 && <PrivacyAgreementForm previousPage={() => setPage(page - 1)} onSubmit={handlePrivacyAgreementFormSubmit} />}
              {page === 3 && <Questionnaire onComplete={() => setPage(page + 1)} />}
              {page === 4 && (!event || !event.get('surveys') || !(event.get('surveys').size > 0)) ? (
                <AdditionalQuestionsCompleted skippedQuestionnaire={shouldSkipQuestionnaire} candidateEvent={candidateEvent} event={event} />
              ) : (
                page === 4 && <QuestionnaireCompleted skippedQuestionnaire={shouldSkipQuestionnaire} onSubmit={() => setPage(page + 1)} />
              )}
              {page === 5 && <AdditionalQuestionsForm onComplete={() => setPage(page + 1)} event={event} />}
              {page === 6 && <AdditionalQuestionsCompleted event={event} candidateEvent={candidateEvent} />}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </ApplyToEventStyles>
  );
};

ApplyToEventView.propTypes = propTypes;
ApplyToEventView.defaultProps = defaultProps;

export default ApplyToEventView;
