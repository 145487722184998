import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import styled from 'styled-components';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const EditorFieldWrapper = styled.div`
  .editor {
    padding: 0 10px;
  }
`;

class EditorField extends Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func.isRequired,
      value: PropTypes.string.isRequired,
    }).isRequired,
    required: PropTypes.bool,
    label: PropTypes.string,
    meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool, initial: PropTypes.string }).isRequired,
    isLoading: PropTypes.bool,
    readOnly: PropTypes.bool,
    defaultValue: PropTypes.string,
  };

  static defaultProps = {
    label: undefined,
    required: false,
    isLoading: false,
    readOnly: false,
    defaultValue: undefined,
  };

  constructor(props) {
    super(props);
    const { value, onChange } = props.input;
    const { initial } = props.meta;
    let editorState;
    const { contentBlocks, entityMap } = convertFromHTML(value || '');
    if (contentBlocks === null || contentBlocks.length === 0 || !initial) {
      editorState = EditorState.createEmpty();
    } else {
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      editorState = EditorState.createWithContent(contentState);
    }
    this.state = { editorState };
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  }

  onEditorStateChange = (editorState) => {
    const { input } = this.props;
    const { onChange, value } = input;

    const newValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    if (value !== newValue) {
      onChange(newValue);
    }

    this.setState({ editorState });
  };

  render() {
    const { editorState } = this.state;
    const {
      label,
      required,
      meta: { error, touched },
      readOnly,
      isLoading,
      defaultValue,
      ...rest
    } = this.props;
    return (
      <EditorFieldWrapper>
        <InputLabel error={touched && error} htmlFor="select-checkbox" style={{ marginBottom: 10 }}>
          {label}
          {required ? '*' : ''}
        </InputLabel>
        <Editor
          editorEnabled={!readOnly && !isLoading}
          editorState={editorState}
          rawContentState={defaultValue}
          wrapperClassName="wrapper"
          editorClassName="editor"
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      </EditorFieldWrapper>
    );
  }
}

export default EditorField;
