import styled from 'styled-components';
// import theme from '../../../common/theme';

const JobListingsStyles = styled.div`
  .MuiFilledInput-root {
    width: calc(29vw);
  }
  .MuiFilledInput-root,
  .MuiSelect-select:focus {
    background: #bcdcd2;
    border-radius: 5px;
  }
  .MuiFormControl-root {
    margin: 5px 5px 0 0;
  }
  .MuiFilledInput-input {
    border: none;
  }
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:after {
    border: none;
  }
`;

export default JobListingsStyles;
