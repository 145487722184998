import { connect } from 'react-redux';
import FinalizeFormView from './finalize_form_view';
import { formValueSelector } from '../../../../common/form';
import { getCurrentUser } from '../../../../reducer';

const selector = formValueSelector('eventForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })

// const selector = formValueSelector('eventForm');

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  address: selector(state, 'addressAttributes'),
  allAges: selector(state, 'allAgesWelcome'),
  allGenders: selector(state, 'AllGendersWelcomed'),
  careerTypes: selector(state, 'careerInterestTypes'),
  customThankYouMessage: selector(state, 'customThankYouMessage'),
  customQuestions: selector(state, 'customScreeningQuestions'),
  date: selector(state, 'eventDate'),
  description: selector(state, 'description'),
  endTime: selector(state, 'endTime'),
  gainedCertifications: selector(state, 'gainedCertifications'),
  gender: selector(state, 'targetGender'),
  industryType: selector(state, 'industryType'),
  logo: selector(state, 'programLogo'),
  name: selector(state, 'name'),
  phone: selector(state, 'phone'),
  primaryService: selector(state, 'primaryServiceType'),
  serviceTypes: selector(state, 'serviceTypes'),
  startTime: selector(state, 'startTime'),
  postExpirationDate: selector(state, 'postExpirationDate'),
  targetZipCodes: selector(state, 'targetZipCodes'),
  targetMaximumAge: selector(state, 'targetMaximumAge'),
  targetMinimumAge: selector(state, 'targetMinimumAge'),
  targetParticipantTypes: selector(state, 'targetParticipantTypes'),
  targetMinimumEducationLevelType: selector(state, 'targetMinimumEducationLevelType'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const FinalizeFormContainer = connect(mapStateToProps, mapDispatchToProps)(FinalizeFormView);

export default FinalizeFormContainer;
