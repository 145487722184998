import 'izitoast/dist/css/iziToast.css';

import iziToast from 'izitoast';

iziToast.settings({
  timeout: 5000,
  resetOnHover: true,
  progressBar: false,
  transitionIn: 'fadeInUp',
  transitionOut: 'fadeOutDown',
  position: 'bottomCenter',
  displayMode: 2,
});

export interface IziToastParam {
  title: string;
  message?: string;
  extraSettingParams?: any;
}

export const success = ({ title, message, extraSettingParams = {} }: IziToastParam) => {
  iziToast.success({
    title,
    message,
    ...extraSettingParams,
  });
};

export const error = ({ title, message, extraSettingParams = {} }: IziToastParam) => {
  iziToast.error({
    title,
    message,
    ...extraSettingParams,
  });
};

export const warning = ({ title, message, extraSettingParams = {} }: IziToastParam) => {
  iziToast.warning({
    title,
    message,
    ...extraSettingParams,
  });
};

export const info = ({ title, message, extraSettingParams = {} }: IziToastParam) => {
  iziToast.info({
    title,
    message,
    ...extraSettingParams,
  });
};
