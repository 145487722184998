import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Map, OrderedSet } from 'immutable';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import UserModel from '../../../session/models/user_model';
import { getCurrentUser, getStaffCandidatesFilters, getEnumsForFormSelect } from '../../../reducer';
import { setStaffCandidatesFilters } from '../../../solve/actions';

// const sortBy = [
//   ['name', 'Name'],
//   ['createdAt', 'Date Created'],
// ];

// TODO: auto-populate children from ENUM
const FilterSelect = ({ filters, onChange, name, children }) => (
  <Select
    value={filters.get(name) || ''}
    fullWidth
    onChange={(e) => {
      onChange(filters.set(name, e.target.value));
    }}
  >
    {children}
  </Select>
);
FilterSelect.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const FilterInput = ({ filters, onChange, name, label, children }) => (
  <TextField
    value={filters.get(name) || ''}
    fullWidth
    onChange={(e) => {
      onChange(filters.set(name, e.target.value));
    }}
    variant="outlined"
    margin="dense"
    label={label}
    InputLabelProps={{
      shrink: false,
    }}
  >
    {children}
  </TextField>
);
FilterInput.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// TODO: wire up to SystemEnums!
const CandidatesFiltersView = ({ user, filters, onChange, ethnicityFilter, skillFilter }) => (
  <Grid container spacing={2} style={{ marginBottom: 15 }}>
    {user.getIn(['staffOrganization', 'orgType']).substring(0, 9) === 'nonprofit' && (
      <Grid item xs={3}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Ethnicity</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="ethnicity" variant="outlined">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {ethnicityFilter &&
              ethnicityFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>
    )}

    <Grid item xs={3}>
      <FilterInput filters={filters} onChange={onChange} name="zipCode" label="Zip Code" />
    </Grid>

    {user.getIn(['staffOrganization', 'orgType']).substring(0, 9) === 'nonprofit' && (
      <Grid item xs={3}>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel>Skill</InputLabel>
          <FilterSelect filters={filters} onChange={onChange} name="skillType" variant="outlined">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {skillFilter &&
              skillFilter.map(([slug, label]) => (
                <MenuItem key={slug} value={slug}>
                  {label}
                </MenuItem>
              ))}
          </FilterSelect>
        </FormControl>
      </Grid>
    )}
  </Grid>
);
CandidatesFiltersView.propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  ethnicityFilter: PropTypes.instanceOf(OrderedSet).isRequired,
  skillFilter: PropTypes.instanceOf(OrderedSet).isRequired,
};

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  filters: getStaffCandidatesFilters(state),
  ethnicityFilter: getEnumsForFormSelect(state, 'EthnicityType'),
  skillFilter: getEnumsForFormSelect(state, 'SkillType'),
});

const mapDispatchToProps = {
  onChange: setStaffCandidatesFilters,
};

const CandidatesFilters = connect(mapStateToProps, mapDispatchToProps)(CandidatesFiltersView);

export default CandidatesFilters;
