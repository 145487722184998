import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { Field } from 'redux-form/immutable';
import { Select } from 'redux-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const propTypes = {
  name: PropTypes.string.isRequired,
  input: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.instanceOf(List),
};

const defaultProps = {
  input: undefined,
  label: undefined,
  required: false,
  options: new List(),
};

const SelectField = ({ input, label, meta: { error, touched }, disabled, required, ...rest }) => (
  <Select
    error={touched && error}
    placeholder={label}
    disabled={disabled}
    helperText={touched && error}
    required={required}
    input={input}
    fullWidth
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...rest}
  />
);

SelectField.propTypes = {
  input: PropTypes.shape({}),
  label: PropTypes.string,
  meta: PropTypes.shape({ error: PropTypes.string, touched: PropTypes.bool }).isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

SelectField.defaultProps = {
  input: undefined,
  label: undefined,
  disabled: false,
  required: false,
};

const MuiSelectMultiple = ({ name, input, options, label, required, ...rest }) => {
  const getValue = (inputProps) => {
    if (!inputProps.value) {
      return [];
    }
    if (!Array.isArray(inputProps.value)) {
      return inputProps.value.toJS();
    }
    return inputProps.value;
  };

  return (
    <FormControl fullWidth margin="dense" variant="outlined">
      <InputLabel
        htmlFor="select-multiple-checkbox"
        InputLabelProps={{
          shrink: false,
        }}
      >
        {label}
        {required ? '*' : ''}
      </InputLabel>
      <Field
        label={label}
        component={SelectField}
        name={name}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...input}
        inputProps={{ value: getValue(input) }}
        // onChange={(event) => fromJS(event)}
        format={(value) => (Array.isArray(value) ? value : [])}
        multiple
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...rest}
      >
        {options &&
          options.map((option) => (
            <MenuItem key={option[0]} value={option[0]}>
              {option[1]}
            </MenuItem>
          ))}
      </Field>
    </FormControl>
  );
};
MuiSelectMultiple.propTypes = propTypes;
MuiSelectMultiple.defaultProps = defaultProps;

export default MuiSelectMultiple;
