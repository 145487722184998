import { useState, useEffect } from 'react';
import { STATUS_CODE } from 'risekit/app/app.status';
import { getPathwaySummaryReport } from 'risekit/api/request.api';
import { PathwaySummaryReportPayload } from 'risekit/api/api.types';

import { PathwayResponseObject } from '../pathways.type';

const useGetPathwaySummary = ({ token, params }: PathwaySummaryReportPayload) => {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [summary, setSummary] = useState<PathwayResponseObject[]>();

  const { pathway_instance_state, pathway_instance_year } = params;

  useEffect(() => {
    setIsLoading(true);
    const fetchSummary = async () => {
      const { data, error: apiError } = await getPathwaySummaryReport({ token, params: { pathway_instance_state, pathway_instance_year } });
      const isRequestCanceled = apiError?.messages !== STATUS_CODE.REQUEST_CANCEL;

      if (apiError && !isRequestCanceled) {
        setError(apiError);
        setIsLoading(false);
      }

      if (!apiError) {
        setSummary(data);
        setIsLoading(false);
      }
    };
    fetchSummary();
  }, [token, pathway_instance_state, pathway_instance_year]);

  return { isLoading, error, summary };
};

export default useGetPathwaySummary;
