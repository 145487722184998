import React from 'react';
import PropTypes from 'prop-types';
import Rollbar from 'rollbar';

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    customError: PropTypes.node,
  };

  static defaultProps = {
    customError: undefined,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: `${error}` };
  }

  componentDidCatch(error, info) {
    if (process.env.NODE_ENV === 'production') {
      try {
        // log to Rolllbar if we can...
        Rollbar.error(`Exception rendering ${this.componentName}`, error, info);
      } catch (e) {
        // if rollbar fails, log to console
        console.log(e); // eslint-disable-line no-console
        console.log(error); // eslint-disable-line no-console
      }
    } else {
      console.log(`Exception rendering ${this.componentName}`, error, info);
    }
  }

  render() {
    const { children, customError } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      if (customError) {
        return customError;
      }
      return (
        <div className="error_boundary">
          <h2>We are sorry, but we seem to be having technical difficulties...</h2>
          <pre style={{ display: 'none' }}>{hasError}</pre>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
