import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { List, fromJS } from 'immutable';
import { downloadFileFromBlob } from '../../../util/file_operations';
import UserModel from '../../../session/models/user_model';
import JobCandidateNotificationsStyles from './job_candidate_notifications_styles';
import TabberSolid from '../../../ui/components/tabber_solid';
import NotificationHelperFactory from '../../../notification/notification_helper_factory';
import NotificationTile, { getNotificationDescription } from '../../../notification/components/tile';
import MessageTile from '../../../messaging/components/tile';
import NotesList from '../notes_list';
// import Button from '@material-ui/core/Button';
import { Button } from '../../../common/components';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateId: PropTypes.string.isRequired,
  messages: PropTypes.instanceOf(List),
  notifications: PropTypes.instanceOf(List),
  notificationLabels: PropTypes.objectOf(PropTypes.string).isRequired,
  listMessages: PropTypes.func.isRequired,
  listNotifications: PropTypes.func.isRequired,
};

const defaultProps = {
  messages: undefined,
  notifications: undefined,
};

const JobCandidateNotificationsView = ({ currentUser, candidateId, messages, notifications, notificationLabels, listMessages, listNotifications }) => {
  useEffect(() => {
    listMessages(currentUser);
  }, [currentUser, listMessages]);
  useEffect(() => {
    listNotifications(currentUser, fromJS({ candidateId }));
  }, [candidateId, currentUser, listNotifications]);

  function downloadCandidateActivitiesCsvReport() {
    let csv = 'Timestamp,Activity,Description\n';
    csv += notifications.map((notification) => {
      const notificationHelper = NotificationHelperFactory.createHelper(currentUser, notification, notificationLabels);
      const description = ReactDOMServer.renderToStaticMarkup(getNotificationDescription(notificationHelper, false));
      return `"${notificationHelper.getCreatedAt('YYYY-MM-DD hh:mm a', false)}","${notificationHelper.getActivity()}","${description}"\n`;
    });
    downloadFileFromBlob(csv, `candidate_activities_report-${candidateId}.csv`);
  }

  return (
    <JobCandidateNotificationsStyles>
      <TabberSolid>
        <div tabLabel="Messages">
          <h1>Messages</h1>
          {messages && messages.length > 0 ? (
            messages.map((message) => (
              <MessageTile
                key={message.get('id')}
                icon="/icons/avatar.svg"
                iconColor="#F1F0F0"
                title={`${message.getIn(['toUser', 'firstName'])} ${message.getIn(['toUser', 'lastName'])} `}
                subTitle={`${message.getIn(['fromUser', 'firstName'])} ${message.getIn(['fromUser', 'lastName'])} `}
                subject={message.get('messageSubject')}
                body={message.get('messageBody')}
                createdAt={message.get('createdAt')}
                toolbarButtons={[
                  <Link key={`${message.get('id')} reply`} to={`/staff/messages/${message.get('id')}`}>
                    <Button buttonType="secondary-outline" className="button">
                      Reply
                    </Button>
                  </Link>,
                ]}
              />
            ))
          ) : (
            <div>No messages from user</div>
          )}
        </div>

        <div tabLabel="Activities">
          <div style={{ float: 'right' }}>
            <Button buttonType="secondary" className="button" onClick={downloadCandidateActivitiesCsvReport}>
              Export as CSV
            </Button>
          </div>
          <h1>Activities</h1>
          {notifications && notifications.length > 0 ? (
            notifications.map((notification) => (
              <NotificationTile notificationLabels={notificationLabels} key={notification.get('id')} notification={notification} />
            ))
          ) : (
            <div>No activities for this user</div>
          )}
        </div>

        <div tabLabel="Notes">
          <h1>Notes</h1>
          <NotesList candidateId={candidateId} />
        </div>
      </TabberSolid>
    </JobCandidateNotificationsStyles>
  );
};

JobCandidateNotificationsView.propTypes = propTypes;
JobCandidateNotificationsView.defaultProps = defaultProps;

export default JobCandidateNotificationsView;
