import React from 'react';
import PropTypes from 'prop-types';
import PillStyles from './pill_styles';
import { TextHeading } from '../text';

const propTypes = {
  value: PropTypes.string,
  color: PropTypes.string,
};

const defaultProps = {
  value: undefined,
  color: undefined,
};

const PillView = ({ value, color }) => (
  <PillStyles color={color}>
    <TextHeading size="xsmall" weight="bold" margin="none">
      {value}
    </TextHeading>
  </PillStyles>
);

PillView.propTypes = propTypes;
PillView.defaultProps = defaultProps;

export default PillView;
