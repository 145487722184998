/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { isNonProfitInmateUser, extractCandidateFullName } from 'util/common';

import { Field, MuiCheckbox } from '../../../../../../../common/form';
import { useFetchOnce } from '../../../../../../../util/hooks';
import UserModel from '../../../../../../../session/models/user_model';

import RowStyles from './select_candidate_row_styles';

const SelectCandidateRowView = ({ currentUser, candidateId, candidate, fetchCandidate, checkbox }) => {
  useFetchOnce(currentUser, candidateId, fetchCandidate, candidate);

  return (
    <React.Fragment>
      {candidate && (
        <RowStyles>
          {checkbox && (
            <td>
              <FormControlLabel
                className="answer"
                control={
                  <Field
                    component={MuiCheckbox}
                    name={candidate.get('id')}
                    variant="outlined"
                    label={`${candidate.get('user').get('firstName')} ${candidate.get('user').get('lastName')}`}
                  />
                }
              />
            </td>
          )}
          <td>
            <td>{extractCandidateFullName(candidate.get('user'), currentUser)}</td>
          </td>
          <td>{candidate.getIn(['user', 'email'])}</td>
          {!isNonProfitInmateUser(currentUser) ? (
            <td>{candidate.getIn(['address', 'zip'])}</td>
          ) : (
            <React.Fragment>
              <td>{candidate.getIn(['inmate', 'facility'])}</td>
              <td>{candidate.getIn(['inmate', 'inmateNumber'])}</td>
            </React.Fragment>
          )}
          <td>{`${moment(candidate.get('createdAt')).format('MM/DD/YYYY')}`}</td>
          {!checkbox && <td>{candidate.get('lastActivityDate') && `${moment(candidate.get('lastActivityDate')).format('MM/DD/YYYY')}`}</td>}
          {!checkbox && (
            <td>
              <Link to={`/staff/candidates/${candidateId}`}>
                View
                <ChevronRightIcon />
              </Link>
            </td>
          )}
        </RowStyles>
      )}
    </React.Fragment>
  );
};

SelectCandidateRowView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidate: PropTypes.instanceOf(Map).isRequired,
  candidateId: PropTypes.string.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  checkbox: PropTypes.bool,
};
SelectCandidateRowView.defaultProps = {
  checkbox: false,
};

export default SelectCandidateRowView;
