import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { fromJS, Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import { startCase } from 'lodash';
import { formatDate, formatTime } from '../../../../util/format_datetimes';

// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import MuiButton from '@material-ui/core/Button';
import { reduxForm } from '../../../../common/form';
import finalizeFormValidate from './finalize_form_validate';
import FinalizeFormStyles, { EventReviewStyles } from './finalize_form_styles';
import { Card } from '../../../../common/styles/layout';
import { Button, TextHeading } from '../../../../common/components';
import EventFlyer from '../../event_flyer';

const propTypes = {
  event: PropTypes.instanceOf(Map).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  address: PropTypes.instanceOf(Map).isRequired,
  allAges: PropTypes.string.bool,
  allGenders: PropTypes.string.bool,
  careerTypes: PropTypes.instanceOf(Map),
  customMessage: PropTypes.string,
  customQuestions: PropTypes.instanceOf(Map),
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  educationLevel: PropTypes.string.isRequired,
  endTime: PropTypes.date,
  gender: PropTypes.string.isRequired,
  industryType: PropTypes.string,
  logo: PropTypes.instanceOf(FileList).isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  primaryService: PropTypes.string,
  serviceTypes: PropTypes.instanceOf(Map),
  gainedCertifications: PropTypes.string.isRequired,
  startTime: PropTypes.date,
  postExpirationDate: PropTypes.string,
  targetZipCodes: PropTypes.instanceOf(Map),
  targetMaximumAge: PropTypes.string,
  targetMinimumAge: PropTypes.string,
  targetParticipantTypes: PropTypes.instanceOf(Map),
  targetMinimumEducationLevelType: PropTypes.string,
};

const defaultProps = {
  allAges: false,
  allGenders: false,
  customMessage: undefined,
  customQuestions: fromJS({}),
  endTime: undefined,
  industryType: undefined,
  primaryService: undefined,
  serviceTypes: fromJS({}),
  startTime: undefined,
  careerTypes: fromJS({}),
  postExpirationDate: '',
  targetZipCodes: Map(),
  targetMaximumAge: '',
  targetMinimumAge: '',
  targetParticipantTypes: Map(),
  targetMinimumEducationLevelType: '',
};

const FinalizeForm = ({
  handleSubmit,
  previousPage,
  onSaveDraft,
  pristine,
  submitting,
  event,
  address,
  allAges,
  allGenders,
  careerTypes,
  customMessage,
  customQuestions,
  date,
  description,
  educationLevel,
  endTime,
  gainedCertifications,
  gender,
  industryType,
  logo,
  name,
  phone,
  startTime,
  primaryService,
  serviceTypes,
  postExpirationDate,
  targetZipCodes,
  targetMaximumAge,
  targetMinimumAge,
  targetParticipantTypes,
  targetMinimumEducationLevelType,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <FinalizeFormStyles>
      <form onSubmit={handleSubmit}>
        <h2>Draft of the Flyer/Event Posting</h2>
        <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <Card>
                  <EventFlyer
                    address={address}
                    allAges={allAges}
                    allGenders={allGenders}
                    customMessage={customMessage}
                    customQuestions={customQuestions}
                    date={date}
                    description={description}
                    educationLevel={educationLevel}
                    endTime={endTime}
                    gainedCertifications={gainedCertifications}
                    gender={gender}
                    industryType={industryType}
                    logo={logo}
                    name={name}
                    phone={phone}
                    primaryService={primaryService}
                    serviceTypes={serviceTypes}
                    startTime={startTime}
                    careerTypes={careerTypes}
                  />
                </Card>
              </Grid>
              {/* <Grid item xs={3}>
              <Card style={{ textAlign: 'center', padding: 20 }}>
                <img src="/images/resume.png" alt="Resume" width="50" />
                <h4 style={{ marginBottom: 20 }}>Flyer/Event Posting</h4>
                <MuiButton fullWidth variant="contained" className="action-contained" startIcon={<CloudDownloadIcon />}>
                  Download PDF
                </MuiButton>
              </Card>
            </Grid> */}
            </Grid>
            <Grid item xs={12}>
              <TextHeading size="medium" weight="bold" margin="both" uppercase>
                Additional event information (candidate will not see)
              </TextHeading>
              <EventReviewStyles>
                <Card>
                  <Grid item xs={12}>
                    <Grid item container>
                      {postExpirationDate && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Post Expiration Date:</span> {postExpirationDate ? formatDate(postExpirationDate) : ''}
                          </p>
                        </Grid>
                      )}
                      {primaryService && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Primary Service:</span> {startCase(primaryService)}
                          </p>
                        </Grid>
                      )}
                      {startTime && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Start Time:</span> {startTime ? formatTime(startTime) : ''}
                          </p>
                        </Grid>
                      )}
                      {targetParticipantTypes && Object.values(targetParticipantTypes.toJS()).indexOf('yes') > -1 && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Participants:</span>
                          </p>
                          <ul>{targetParticipantTypes.entrySeq().map((type) => type[1] === 'yes' && <li key={type[0]}>{startCase(type[0])}</li>)}</ul>
                        </Grid>
                      )}
                      {targetZipCodes && targetZipCodes.size > 0 && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Zip Codes:</span>
                          </p>
                          <ul>
                            {targetZipCodes.map((type) => (
                              <li key={type}>{type}</li>
                            ))}
                          </ul>
                        </Grid>
                      )}
                      {targetMinimumAge && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Minimum Age:</span> {targetMinimumAge}
                          </p>
                        </Grid>
                      )}
                      {targetMaximumAge && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Maximum Age:</span> {targetMaximumAge}
                          </p>
                        </Grid>
                      )}
                      {targetMinimumEducationLevelType && (
                        <Grid item xs={12}>
                          <p>
                            <span className="sub-heading">Target Minimum Education Level:</span> {startCase(targetMinimumEducationLevelType)}
                          </p>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Card>
              </EventReviewStyles>
            </Grid>
          </Grid>
          <div className="button-group">
            <div className="button-left">
              <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
                Previous
              </Button>
            </div>
            {event?.get('state') !== 'active' ? (
              <div className="button-right">
                <Button buttonType="secondary-outline" type="button" disabled={submitting} onClick={handleSubmit((values) => onSaveDraft(values))}>
                  Save Draft
                </Button>
                <Button buttonType="secondary" disabled={pristine || submitting} type="submit">
                  List Event Posting
                </Button>
              </div>
            ) : (
              <div className="button-right">
                <Link to="/staff/resources/">
                  <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting}>
                    Discard Changes
                  </Button>
                </Link>
                <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={handleSubmit((values) => onSaveDraft(values))}>
                  Save Event
                </Button>
                <Button buttonType="secondary" disabled={pristine || submitting} type="submit">
                  Publish Event
                </Button>
              </div>
            )}
          </div>
        </Grid>
      </form>
    </FinalizeFormStyles>
  );
};

FinalizeForm.propTypes = propTypes;
FinalizeForm.defaultProps = defaultProps;

const FinalizeFormView = reduxForm({
  form: 'eventForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: finalizeFormValidate,
})(FinalizeForm);

export default FinalizeFormView;
