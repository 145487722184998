import styled from 'styled-components';
import theme from '../../../common/theme';

const JobListingsStyles = styled.div``;

export const Title = styled.h2`
  text-align: center;
`;

export const SubTitle = styled.h3`
  color: ${theme.color.action};
  font-weight: bold;

  span {
    margin-left: 10px;
    font-style: italic;
    color: ${theme.color.subtleAction};
    font-size: 12px;
    text-transform: lowercase;
  }
`;

export default JobListingsStyles;
