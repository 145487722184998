import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import PostingPreviewsDetailView from './posting_previews_detail_view';
import UserModel from '../../../session/models/user_model';
import PostingPreviewsStyles from './posting_previews_styles';
import PublicContainer from '../public_container';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  publicLink: PropTypes.instanceOf(Map),
  postingId: PropTypes.string.isRequired,
  fetchPublicLink: PropTypes.func.isRequired,
  visitedLink: PropTypes.func.isRequired,
};

const defaultProps = {
  publicLink: undefined,
};

const PostingPreviewsView = ({ currentUser, postingId, publicLink, fetchPublicLink, visitedLink }) => (
  <PublicContainer>
    <PostingPreviewsStyles>
      <PostingPreviewsDetailView
        currentUser={currentUser}
        postingId={postingId}
        publicLink={publicLink}
        fetchPublicLink={fetchPublicLink}
        visitedLink={visitedLink}
      />
    </PostingPreviewsStyles>
  </PublicContainer>
);

PostingPreviewsView.propTypes = propTypes;
PostingPreviewsView.defaultProps = defaultProps;

export default PostingPreviewsView;
