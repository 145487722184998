import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();
const EMPTY_MAP = fromJS({});

const DEFAULT_STATE = fromJS({
  filters: EMPTY_MAP,
  zipcode: undefined,
  servicesLoading: false,
  externalServicesLoading: false,
  externalCandidateServicesLoading: false,
  servicesLoaded: 0,
  externalServicesLoaded: 0,
  servicesList: EMPTY_SET,
  externalServicesList: EMPTY_SET,
  aBServicesAll: EMPTY_SET,
  savedServicesList: EMPTY_SET,
  appliedServicesList: EMPTY_SET,
  candidateServicesLoaded: 0,
  candidateServicesLoading: 0,
  page: 0,
  sortBy: 0,
  distance: '15-miles',
});

const CandidateServiceListingsReducer = (state = DEFAULT_STATE, action) => {
  let candidateServicesList;
  let externalServicesList;
  let programId;

  switch (action.type) {
    case ACTIONS.SET_CANDIDATE_PROGRAMS_ZIP_CODE:
      if (state.get('zipCode') !== action.zipCode) {
        return state.merge({
          servicesLoaded: false,
          externalServicesLoaded: false,
          zipCode: action.zipCode,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          servicesLoaded: false,
          externalServicesLoaded: false,
          candidateServicesLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_PAGE:
      if (state.get('page') !== action.page) {
        return state.merge({
          page: action.page,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_SORT_BY:
      if (state.get('sortBy') !== action.sortBy) {
        return state.merge({
          sortBy: action.sortBy,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_DISTANCE:
      if (state.get('distance') !== action.distance) {
        return state.merge({
          distance: action.distance,
          servicesLoaded: false,
        });
      }
      return state;
    case ACTIONS.SET_CANDIDATE_PROGRAMS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          servicesLoaded: false,
          externalServicesLoaded: false,
          candidateServicesLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_SERVICES_FOR_CANDIDATE.request:
      return state.set('servicesLoading', true);
    case ACTIONS.LIST_FILTERED_SERVICES_FOR_CANDIDATE.success:
      return state.merge({
        servicesList: OrderedSet(action.response.result),
        servicesLoading: false,
        servicesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_SERVICES_FOR_CANDIDATE.failure:
      return state.merge({
        servicesLoading: false,
        servicesLoaded: false,
        servicesList: EMPTY_SET,
      });
    case ACTIONS.LIST_FILTERED_EXTERNAL_SERVICES_FOR_CANDIDATE.request:
      return state.set('externalServicesLoading', true);
    case ACTIONS.LIST_FILTERED_EXTERNAL_SERVICES_FOR_CANDIDATE.success:
      return state.merge({
        externalServicesList: OrderedSet(action.response.result),
        externalServicesLoading: false,
        externalServicesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_EXTERNAL_SERVICES_FOR_CANDIDATE.failure:
      return state.merge({
        externalServicesLoaded: false,
        externalServicesLoading: false,
        externalServicesList: EMPTY_SET,
      });
    case ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES_SERVICES.success:
      externalServicesList = action.response.result.map((id) => action.response.entities.services[id]);
      return state.merge({
        savedServicesList: OrderedSet(externalServicesList),
        appliedServicesList: OrderedSet(externalServicesList),
        externalServicesLoaded: true,
        externalServicesLoading: false,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE.request:
      return state.set('candidateServicesLoading', true);
    case ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE.success:
      candidateServicesList = action.response.result.map((id) => action.response.entities.candidateServices[id]);
      return state.merge({
        servicesList: OrderedSet(action.response.result),
        savedServicesList: candidateServicesList.filter((app) => app.candidateState === 'c_saved').map((app) => app.helper.helperId),
        appliedServicesList: candidateServicesList.filter((app) => !['c_saved', 'c_deleted'].includes(app.candidateState)).map((app) => app.helper.helperId),
        candidateServicesLoading: false,
        candidateServicesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATE_SERVICES_FOR_CANDIDATE.failure:
      return state.merge({
        servicesList: EMPTY_SET,
        candidateServicesLoaded: false,
        candidateServicesLoading: false,
        appliedServicesList: EMPTY_SET,
        savedServicesList: EMPTY_SET,
      });
    case ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES.request:
      return state.merge({
        externalCandidateServicesLoading: true,
      });
    case ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES.success:
      candidateServicesList = action.response.result.map((id) => action.response.entities.externalCandidateServices[id]);
      return state.merge({
        savedServicesList: OrderedSet(candidateServicesList.filter((app) => app.candidateState === 'c_saved').map((app) => app.externalResourceUid)),
        externalCandidateServicesLoading: false,
        externalCandidateServicesLoaded: Date.now(),
        appliedServicesList: candidateServicesList
          .filter((app) => !['c_saved', 'c_deleted'].includes(app.candidateState))
          .map((app) => app.externalResourceUid),
      });
    case ACTIONS.FETCH_EXTERNAL_CANDIDATE_SERVICES.failure:
      return state.merge({
        externalCandidateServicesLoaded: false,
        externalCandidateServicesLoading: false,
        servicesList: EMPTY_SET,
        appliedServicesList: EMPTY_SET,
        savedServicesList: EMPTY_SET,
      });
    case ACTIONS.CREATE_EXTERNAL_CANDIDATE_SERVICE.success:
      programId = action.response.entities.externalCandidateServices[action.response.result].externalResourceUid;
      return state.update('savedServicesList', (saved) => saved.add(programId));
    case ACTIONS.CANDIDATE_APPLY_TO_EXTERNAL_SERVICE.success:
      programId = action.response.entities.externalCandidateServices[action.response.result].externalResourceUid;
      return state.update('appliedServicesList', (applied) => applied.add(programId));
    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getZipcode = (state) => state.get('zipcode');
export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getPage = (state) => state.get('page') || DEFAULT_STATE.get('page');
export const getSortBy = (state) => state.get('sortBy') || DEFAULT_STATE.get('sortBy');
export const getDistance = (state) => state.get('distance') || DEFAULT_STATE.get('distance');
export const getSearch = (state) => state.get('search');

export const getServiceIds = (state) => state.get('servicesList') || DEFAULT_STATE.get('servicesList');
export const getServicesLoaded = (state) => state.get('servicesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getAppliedServiceIds = (state) => state.get('appliedServicesList') || DEFAULT_STATE.get('appliedServicesList');
export const getAppliedServicesLoaded = (state) => state.get('candidateServicesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getSavedServiceIds = (state) => state.get('savedServicesList') || DEFAULT_STATE.get('savedServicesList');
export const getSavedServicesLoaded = (state) => state.get('candidateServicesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getExternalServiceIds = (state) => state.get('externalServicesList') || DEFAULT_STATE.get('externalServicesList');
export const getExternalServicesLoaded = (state) => state.get('externalServicesLoaded') + RELOAD_TIMEOUT > Date.now();

export default CandidateServiceListingsReducer;
