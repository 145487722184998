import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { fromJS } from 'immutable';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import { Field, MuiDatePicker, MuiSelect, MuiTextField, MuiRadioGroup, phoneMask } from '../../../common/form';
import DefyFormStyles from './defy_form_styles';
import { Button } from '../../../common/components';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';

const recidivismStateChoices = fromJS(['Upcoming', 'Ready', 'Unable', 'Pending', 'Cleared', 'Hit']).map((x) => [x.toLowerCase(), x]);

const DefyFormView = ({ currentUser, candidateId, handleSubmit, fetchInmate, fetchCandidate, pristine, valid }) => {
  const resolvedInmate = useFetchOnce(currentUser, candidateId, fetchInmate);
  const resolvedCandidate = useFetchOnce(currentUser, candidateId, fetchCandidate);

  return (
    <DefyFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container>
          <Grid xs={12} md={6} className="defy-form">
            <Field component={MuiTextField} name="ssn" label="Social Security Number" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiTextField} name="facility" label="Facility" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiTextField} name="inmateNumber" label="Inmate Number" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiDatePicker} variant="outlined" name="targetReleaseDate" margin="dense" fullWidth label="Target release date" />
            <Field component={MuiDatePicker} variant="outlined" name="actualReleaseDate" margin="dense" fullWidth label="Actual release date" />
            <Field component={MuiTextField} name="commitmentLength" label="Commitment Length" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiTextField} name="lastKnownAddress" label="Last known address" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiTextField} name="expectedReleaseAddress" label="Expected release address" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiTextField} name="cityOfRelease" label="City of release" margin="dense" variant="outlined" fullWidth />
            <Field component={MuiTextField} name="countyOfRelease" label="County of release" margin="dense" variant="outlined" fullWidth />
          </Grid>
          <Grid xs={12} md={6} className="defy-form">
            <Grid container>
              <Grid xs={12} md={6} className="contact">
                <Field component={MuiTextField} name="contact1.name" label="Primary Contact Name" margin="dense" variant="outlined" fullWidth />
                <Field component={MuiTextField} name="contact1.relation" label="Primary Contact Relation" margin="dense" variant="outlined" fullWidth />
                <Field component={MuiTextField} name="contact1.email" label="Primary Contact Email" margin="dense" variant="outlined" fullWidth />
                {/* eslint-disable react/jsx-props-no-spreading */}
                <Field
                  component={MuiTextField}
                  name="contact1.phone"
                  label="Primary Contact Phone"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  {...phoneMask}
                />
              </Grid>
              <Grid xs={12} md={6} className="contact">
                <Field component={MuiTextField} name="contact2.name" label="Secondary Contact Name" margin="dense" variant="outlined" fullWidth />
                <Field component={MuiTextField} name="contact2.relation" label="Secondary Contact Relation" margin="dense" variant="outlined" fullWidth />
                <Field component={MuiTextField} name="contact2.email" label="Secondary Contact Email" margin="dense" variant="outlined" fullWidth />
                {/* eslint-disable react/jsx-props-no-spreading */}
                <Field
                  component={MuiTextField}
                  name="contact2.phone"
                  label="Secondary Contact Phone"
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  {...phoneMask}
                />
              </Grid>
            </Grid>
            <Field name="isHomeless" component={MuiRadioGroup}>
              <InputLabel htmlFor="homeless">Homeless?</InputLabel>
              <FormGroup row>
                <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
              </FormGroup>
            </Field>
            <Field name="hasFosterCare" component={MuiRadioGroup}>
              <InputLabel htmlFor="fosterCare">Has Foster Care?</InputLabel>
              <FormGroup row>
                <FormControlLabel value="true" control={<Radio color="primary" />} label="Yes" />
                <FormControlLabel value="false" control={<Radio color="primary" />} label="No" />
              </FormGroup>
            </Field>
            <Field component={MuiDatePicker} variant="outlined" name="recidivismRosterDate" margin="dense" fullWidth label="Recidivism roster date" />
            <MuiSelect name="recidivismRosterStatus1" label="Recidivism roster status - 1 year" options={recidivismStateChoices} />
            <MuiSelect name="recidivismRosterStatus3" label="Recidivism roster status - 3 year" options={recidivismStateChoices} />
            <Button className="save-button" buttonType="secondary" type="submit" disabled={!resolvedInmate || !resolvedCandidate || pristine || !valid}>
              Save
            </Button>
          </Grid>
        </Grid>
      </form>
    </DefyFormStyles>
  );
};

DefyFormView.propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateId: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fetchInmate: PropTypes.func.isRequired,
  fetchCandidate: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
};

DefyFormView.defaultProps = {};

export default DefyFormView;
