import React, { useState, useEffect } from 'react';
import { Map, List, fromJS, Set } from 'immutable';
import PropTypes from 'prop-types';
import Stepper from 'react-stepper-horizontal';
import { Redirect, Link } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Button, Spinner } from '../../../common/components';
import { MuiFormTheme, MuiThemeProvider } from '../../../common/form';
import theme from '../../../common/theme';
import AddJobStyles from './add_job_styles';
import JobDetailsForm from './job_details_form/index';
import RequirementsForm from './requirements_form/index';
import BenefitsForm from './benefits_form/index';
import ReviewForm from './review_form/index';

import { useFetchOnce } from '../../../util/hooks';
import JobPostingModel from '../../../solve/models/job_posting_model';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleSaveDraft: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  jobPosting: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]),
  id: PropTypes.string,
  fetchJobPosting: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  resetForm: PropTypes.func.isRequired,
};

const defaultProps = {
  jobPosting: undefined,
  id: undefined,
};

function injectSurvey(surveyType, values) {
  const questions = values.get('surveyQuestionAttributes', Map()).filter((options) => options.get('isIncluded') === 'Yes' || options.get('id'));
  if (questions.size === 0) {
    return values;
  }
  const markedQuestions = questions.map((question) => (question.get('isIncluded') === 'Yes' ? question : question.merge({ _destroy: '1' })));
  const questionsWithId = markedQuestions
    .map((options, id) => options.merge({ questionTemplateId: id }))
    .toList()
    .map((question, idx) => question.set('position', idx + 1));
  const questionsWithAnswers = questionsWithId.map((question) =>
    (question.getIn(['extra', 'scoringAnswerItem']) ?
      question.update('extra', (extra) => extra.set('scoringAnswer', List([extra.get('scoringAnswerItem')])).delete('scoringAnswerItem')) :
      question),
  );

  return values.merge({
    surveysAttributes: [
      {
        title: 'Screening Questions',
        id: values.getIn(['surveys', 0, 'id']),
        surveyType,
        surveyQuestionsAttributes: questionsWithAnswers,
      },
    ],
  });
}

const POTENTIAL_OPPORTUNITY_TYPES = fromJS(['transport', 'childCare', 'healthCare', 'h1b', 'sponsorship']);

const EMPTY_SET = Set();
function simplifyOpportunityTypes(opportunityTypes = EMPTY_SET) {
  const simpleTypes = POTENTIAL_OPPORTUNITY_TYPES.filter((oppo) => opportunityTypes.get(oppo) === 'yes');
  if (opportunityTypes.get('criminalBackground') === 'yes') {
    const backgroundTypes = [];
    switch (opportunityTypes.get('backgroundType')) {
      case 'both':
        backgroundTypes.push('criminalBackground-felony');
        backgroundTypes.push('criminalBackground-misdemeanor');
        break;
      case 'misdemeanor':
        backgroundTypes.push('criminalBackground-misdemeanor');
        break;
      case 'felony':
        backgroundTypes.push('criminalBackground-felony');
        break;
      default:
      // No-op...
    }
    return simpleTypes.merge(backgroundTypes);
  }
  return simpleTypes;
}

function xformForSubmit(values) {
  const valuesWithSurvey = injectSurvey('screening', values);
  const logo = values.get('jobPostingLogo');
  if (logo) {
    return valuesWithSurvey.delete('jobPostingLogo').merge({
      opportunityTypes: simplifyOpportunityTypes(values.get('opportunityTypes')),
      logo: logo[0],
    });
  }
  return valuesWithSurvey.delete('jobPostingLogo').merge({
    opportunityTypes: simplifyOpportunityTypes(values.get('opportunityTypes')),
  });
}

const AddJobView = ({ handleSubmit, handleSaveDraft, initialValues, jobPosting, id, fetchJobPosting, currentUser, resetForm }) => {
  const [page, setPage] = useState(1);
  const [submitted, setSubmitted] = useState(false);
  const [draftSubmitted, setDraftSubmitted] = useState(false);
  const [draftId, setDraftId] = useState(null);
  useEffect(() => {
    resetForm('jobForm');
  }, [resetForm]);

  const postingFound = useFetchOnce(currentUser, id, fetchJobPosting, !id || jobPosting);

  const handleSubmitAndRedirect = (event) => {
    handleSubmit(event).then((_results) => {
      setSubmitted(true);
      alert('Your job has been successfully saved and posted.');
    });
  };

  const handleSaveDraftAndRedirect = (event) => {
    handleSaveDraft(event).then((results) => {
      setDraftId(results.result);
      setDraftSubmitted(true);
      alert('Your job draft has been successfully saved.');
    });
  };

  if (id && !postingFound) {
    return <Spinner />;
  }

  if (submitted) {
    return <Redirect to="/staff/jobs" />;
  }

  if (draftSubmitted) {
    return <Redirect to={`/staff/jobs/${draftId}`} />;
  }

  const steps = [{ title: '1. Job Details' }, { title: '2. Candidate Requirements' }, { title: '3. Benefits and Opportunities' }, { title: '4. Review' }];

  return (
    <AddJobStyles>
      <div className="content-area">
        {/* TODO: Switch to use history.goBack with useHistory hook */}
        <Link to="/staff/jobs">
          <Button buttonType="back" size="small">
            <KeyboardBackspaceIcon />
            Go Back
          </Button>
        </Link>
        <div className="wrapper">
          <div className="header">
            <h1>
              Add: <span>Job Posting</span>
            </h1>
            <em>* Required fields</em>
            <Stepper
              steps={steps}
              activeStep={page - 1}
              activeColor={theme.color.secondaryAction}
              activeTitleColor={theme.color.secondaryAction}
              completeColor={theme.color.secondaryAction}
              completeBarColor={theme.color.secondaryAction}
              completeTitleColor={theme.color.secondaryAction}
              circleFontSize={0}
              titleFontSize={14}
              titleFontWeight="bold"
              size={8}
            />
          </div>

          <div className="form">
            <MuiThemeProvider theme={MuiFormTheme}>
              {page === 1 && <JobDetailsForm previousPage={() => setPage(page - 1)} onSubmit={() => setPage(page + 1)} initialValues={initialValues} />}
              {page === 2 && <RequirementsForm previousPage={() => setPage(page - 1)} onSubmit={() => setPage(page + 1)} initialValues={initialValues} />}
              {page === 3 && <BenefitsForm previousPage={() => setPage(page - 1)} onSubmit={() => setPage(page + 1)} initialValues={initialValues} />}
              {page === 4 && (
                <ReviewForm
                  previousPage={() => setPage(page - 1)}
                  onSubmit={(values) => handleSubmitAndRedirect(xformForSubmit(values))}
                  onSaveDraft={(values) => handleSaveDraftAndRedirect(xformForSubmit(values))}
                  jobPosting={jobPosting}
                />
              )}
            </MuiThemeProvider>
          </div>
        </div>
      </div>
    </AddJobStyles>
  );
};

AddJobView.propTypes = propTypes;
AddJobView.defaultProps = defaultProps;

export default AddJobView;
