import styled from 'styled-components';

// default screen container style
export const Page = styled.div`
  padding: ${(props) => props.theme.padding.large};
`;

// hero style
export const Hero = styled.div`
  padding: ${(props) => props.theme.padding.large};
`;

// footer style
export const Footer = styled.div`
  padding: ${(props) => props.theme.padding.large};
`;

// section style
export const Section = styled.div`
  padding: ${(props) => props.theme.padding.large};
  margin: ${(props) => props.theme.padding.large};
`;

export const Card = styled.div`
  position: relative;
  padding: 10px 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  min-height: 40px;
  margin-bottom: 20px;
  background: ${(props) => props.color || '#fff'};
`;

export const Right = styled.div`
  flex: 1;
  justify-items: flex-end;
  text-align: right;
`;

export const Center = styled.div`
  flex: 1;
  justiy-items: flex-center;
  text-align: center;
`;
