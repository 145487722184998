import { connect } from 'react-redux';
import ListingApplicantDetailsTileView from './listing_applicant_details_tile_view';
import { getCurrentUser, getApiError, getStaffMember } from '../../../reducer';
import { clearApiError } from '../../../errors/actions';
import ACTIONS, {
  transitionCandidateApplicationByStaff,
  transitionCandidateEventByStaff,
  transitionCandidateProgramByStaff,
  transitionCandidateServiceByStaff,
  transitionCandidatePathwayStaffState,
} from '../../../solve/actions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const staffId = currentUser.get('staffId');
  const currentStaff = getStaffMember(state, staffId);
  const canEdit = currentUser?.permissions?.includes('edit_applications') || currentStaff?.get('permissions')?.includes('edit_applications');

  return {
    currentUser,
    canEdit,
    statusUpdateError: getApiError(state, ACTIONS.TRANSITION_CANDIDATE_PROGRAM_S.failure),
  };
};

const mapDispatchToProps = {
  transitionCandidateApplicationByStaff,
  transitionCandidateProgramByStaff,
  transitionCandidateEventByStaff,
  transitionCandidateServiceByStaff,
  transitionCandidatePathwayStaffState,
  clearApiError,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const ListingApplicantDetailsTileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListingApplicantDetailsTileView);

export default ListingApplicantDetailsTileContainer;
