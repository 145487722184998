import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import theme from '../../../common/theme';
import { ButtonGroup } from '../../../common/styles/detail';

const propTypes = {
  previewType: PropTypes.string.isRequired,
  redirectPath: PropTypes.string,
};

const defaultProps = { redirectPath: '' };

const PreviewButtons = ({ previewType, redirectPath }) => (
  <ButtonGroup>
    <Link to="/candidate/welcome">
      <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action, marginBottom: 10, width: 225 }}>
        {`Sign up`}
      </Button>
    </Link>
    <Link
      to={{
        pathname: '/signin',
        state: { returnTo: redirectPath },
      }}
    >
      <Button variant="outlined" color="secondary" size="large" style={{ borderColor: theme.color.action, color: theme.color.action, width: 225 }}>
        {`Log in`}
      </Button>
    </Link>
  </ButtonGroup>
);

PreviewButtons.propTypes = propTypes;
PreviewButtons.defaultProps = defaultProps;

export default PreviewButtons;
