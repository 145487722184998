import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  EditButton,
  DisabledInput,
  DateField,
  DateInput,
  TextInput,
  BooleanInput,
  Filter,
  ReferenceField,
  Pagination,
} from 'react-admin';
import PeopleIcon from '@material-ui/icons/People';
import { IsVerifiedField } from '../fields';

export const Icon = PeopleIcon;

// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

const SearchFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props}>
    <TextInput label="Name" source="qName" alwaysOn />
    <TextInput label="Email" source="qEmail" alwaysOn />
    <TextInput label="Phone" source="qPhone" alwaysOn />
  </Filter>
);

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} filters={<SearchFilter />} pagination={<PostPagination />}>
    <Datagrid>
      <TextField source="id" sortable={false} />
      <DateField source="createdAt" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <IsVerifiedField source="email" />
      <TextField source="phone" />
      <EditButton basePath="/users" />
      <ReferenceField label="Candidate" source="candidateId" reference="candidates" allowEmpty sortable={false}>
        <TextField source="id" sortable={false} />
      </ReferenceField>
      <ReferenceField label="Staff" source="staffId" reference="staffs" allowEmpty sortable={false}>
        <TextField source="id" />
      </ReferenceField>
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>User{record ? ` "${record.firstName} ${record.lastName}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <DateInput source="emailValidatedAt" />
      <TextInput source="phone" />
      <BooleanInput source="sendEmailPermitted" />
      <BooleanInput source="sendTextPermitted" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a User" {...props}>
    <SimpleForm>
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <TextInput source="phone" />
    </SimpleForm>
  </AdminCreate>
);
