import { connect } from 'react-redux';
import OnboardingView from './onboarding_view';
import { getOnboardingMode, getOnboardingCreatedProfile, getCurrentUser, getOnboarding } from '../../../reducer';
import { completeOnboarding } from '../../../onboarding/actions';

// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  const createdProfile = !!getOnboardingCreatedProfile(state);
  return {
    isEmployer: getOnboardingMode(state) === 'employer',
    hasStaff: !!user.staffId || createdProfile,
    isOnboarding: getOnboarding(state),
  };
};

// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {
  completeOnboarding,
};

const OnboardingContainer = connect(mapStateToProps, mapDispatchToProps)(OnboardingView);

export default OnboardingContainer;
