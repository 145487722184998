import isAfter from 'date-fns/isAfter';
import isValid from 'date-fns/isValid';
import addressValidator from '../../../../common/form/helpers/address_validator';

// const REQUIRED = 'Required';

const jobDetailsFormValidate = (values) => {
  const errors = {};
  if (!values.get('title')) {
    errors.title = 'Required';
  }
  if (!values.get('jobType')) {
    errors.jobType = 'Required';
  }
  if (!values.get('industryType')) {
    errors.industryType = 'Required';
  }
  if (!values.get('employerId')) {
    errors.employerId = 'Required';
  }
  if (!values.get('employerVisibilityType') && values.get('employerVisibilityType') !== false) {
    errors.employerVisibilityType = 'Required';
  }

  errors.addressAttributes = addressValidator(values.get('addressAttributes'));

  if (values.get('payAmount') && values.get('payAmount') < 0) {
    errors.payAmount = 'pay greater then 0 required';
  }
  if (!values.get('payType')) {
    errors.payType = 'Required';
  }

  if (!values.get('numberOfOpenings')) {
    errors.numberOfOpenings = 'Required';
  }

  if (values.get('numberOfOpenings') && values.get('numberOfOpenings') < 0) {
    errors.numberOfOpenings = 'Openings greater then 0 required';
  }
  if (values.get('startDate')) {
    if (!isValid(new Date(values.get('startDate')))) {
      errors.startDate = 'A valid date is required';
    } else if (!isAfter(new Date(values.get('startDate')), Date.now())) {
      errors.startDate = 'A date after today is required';
    }
  }

  if (values.get('endDate')) {
    if (!isValid(new Date(values.get('endDate')))) {
      errors.endDate = 'A valid date is required';
    } else if (values.get('startDate') && values.get('endDate') && !isAfter(new Date(values.get('endDate')), new Date(values.get('startDate')))) {
      errors.endDate = 'A date after the start date is required';
    }
  }

  if (!values.get('postExpirationDate')) {
    errors.postExpirationDate = 'Required';
  }

  if (values.get('postExpirationDate')) {
    if (!isValid(new Date(values.get('postExpirationDate')))) {
      errors.postExpirationDate = 'A valid date is required';
    } else if (!isAfter(new Date(values.get('postExpirationDate')), Date.now())) {
      errors.postExpirationDate = 'A date after today is required';
    }
  }
  if (values.get('description') === '<p></p>\n') {
    errors.description = 'Required';
  }
  return errors;
};

export default jobDetailsFormValidate;
