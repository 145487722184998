import { connect } from 'react-redux';
import TileView from './tile_view';

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

const TileContainer = connect(mapStateToProps, mapDispatchToProps)(TileView);

export default TileContainer;
