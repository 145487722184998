/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
// import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
// import Button from '@material-ui/core/Button';
// import Grid from '@material-ui/core/Grid';
import ChallengesStyles from './challenges_styles';
import ChallengeChecklist from '../challenge_checklist';
// import Recommendations from '../recommendations';
// import { Card } from '../../../common/styles/layout';
// import theme from '../../../common/theme';
// import Progress from '../../../ui/components/progress';
// import { ProgressBar, ProgressBarProgress } from '../../../ui/components/progress/progress_styles';

const propTypes = {};

const defaultProps = {};

const ChallengesView = () => (
  <ChallengesStyles>
    <ChallengeChecklist />
    {/* <Recommendations />
    <Link to="/candidate/profile/questionnaire">
      <Button variant="outlined" color="secondary" size="large" fullWidth style={{ borderColor: theme.color.action, color: theme.color.action }}>
        Complete Questionnaire
      </Button>
    </Link>

    <h4>Job Training Questionnaire</h4>

    <Card style={{ textAlign: 'center', paddingTop: 40, paddingBottom: 40 }}>
      <img src="/icons/color/financial-challenges.png" height={50} />
      <h3 style={{ color: '#67B4FF' }}>Financial Challenges</h3>
      <Progress percent={75} label="Completed" color="#67B4FF" />
      <a style={{ color: '#32424B', textDecoration: 'underline' }}>Complete Financial Challenges</a>
    </Card>

    <Card style={{ textAlign: 'center', paddingTop: 40, paddingBottom: 40 }}>
      <img src="/icons/color/background-challenges.png" height={50} />
      <h3 style={{ color: '#FFBF76' }}>Background Challenges</h3>
      <Progress percent={75} label="Completed" color="#FFBF76" />
      <a style={{ color: '#32424B', textDecoration: 'underline' }}>Complete Background Challenges</a>
    </Card>

    <Card style={{ textAlign: 'center', paddingTop: 40, paddingBottom: 40 }}>
      <img src="/icons/color/workforce-readiness.png" height={50} />
      <h3 style={{ color: '#7DBF9D' }}>Workforce Readiness</h3>
      <Progress percent={75} label="Completed" color="#7DBF9D" />
      <a style={{ color: '#32424B', textDecoration: 'underline' }}>Complete Workforce Readiness</a>
    </Card>

    <Card style={{ textAlign: 'center', paddingTop: 40, paddingBottom: 40 }}>
      <img src="/icons/color/emotional-challenges.png" height={50} />
      <h3 style={{ color: '#9D71D6' }}>Emotional Challenges</h3>
      <Progress percent={75} label="Completed" color="#9D71D6" />
      <a style={{ color: '#32424B', textDecoration: 'underline' }}>Complete Emotional Challenges</a>
    </Card>

    <h4>Summary of the Questionnaire</h4>
    <Card>
      <Summaries>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CircleNumber color="#67B4FF">1</CircleNumber>
          </Grid>
          <Grid item xs={10}>
            <h4 style={{ color: '#67B4FF' }}>3/7</h4>
            <ProgressBar>
              <ProgressBarProgress percent={40} color="#67B4FF" />
            </ProgressBar>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CircleNumber color="#FFBF76">2</CircleNumber>
          </Grid>
          <Grid item xs={10}>
            <h4 style={{ color: '#FFBF76' }}>1/2</h4>
            <ProgressBar>
              <ProgressBarProgress percent={50} color="#FFBF76" />
            </ProgressBar>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CircleNumber color="#7DBF9D">3</CircleNumber>
          </Grid>
          <Grid item xs={10}>
            <h4 style={{ color: '#7DBF9D' }}>6/8</h4>
            <ProgressBar>
              <ProgressBarProgress percent={75} color="#7DBF9D" />
            </ProgressBar>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <CircleNumber color="#9D71D6">4</CircleNumber>
          </Grid>
          <Grid item xs={10}>
            <h4 style={{ color: '#9D71D6' }}>1/2</h4>
            <ProgressBar>
              <ProgressBarProgress percent={50} color="#9D71D6" />
            </ProgressBar>
          </Grid>
        </Grid>
      </Summaries>
    </Card> */}
  </ChallengesStyles>
);

ChallengesView.propTypes = propTypes;
ChallengesView.defaultProps = defaultProps;

export default ChallengesView;
