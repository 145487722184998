import { connect } from 'react-redux';
import ListingApplicantRowView from './listing_applicant_row_view';
import { getCurrentUser, getCandidate } from '../../../reducer';
import { fetchCandidate } from '../../../solve/actions';

const mapStateToProps = (state, { candidateId }) => ({
  candidateId,
  candidate: getCandidate(state, candidateId),
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = { fetchCandidate };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
});

const ListingApplicantRowContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ListingApplicantRowView);

export default ListingApplicantRowContainer;
