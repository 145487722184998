import styled from 'styled-components';
// import theme from '../../../common/theme';

const OrganizationTileStyles = styled.div`
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
  height: 100%;
  display: flex;
  b {
    display: block;
    margin: 0;
    margin-top: 5px;
  }
  .icon img {
    width: 80px;
    padding: 5px 20px 5px 10px;
  }
  .organization-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const AddressLine = styled.span`
  white-space: nowrap;
`;

export default OrganizationTileStyles;
