import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
// import TextField from '@material-ui/core/TextField';
// import InputAdornment from '@material-ui/core/InputAdornment';
// import SearchIcon from '@material-ui/icons/Search';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import theme from '../../../common/theme';
import { Spinner } from '../../../common/components';
import CandidatesInvitedStyles, { ResultsPageNavigator, StyledTable, Banner } from './candidates_invited_styles';
import ListingInvitationRow from '../listing_invitation_row';
import UserModel from '../../../session/models/user_model';
// import CandidatesFilters from './candidates_filters';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  invitations: PropTypes.instanceOf(OrderedSet).isRequired,
  total: PropTypes.number.isRequired,
  listFilteredInvitations: PropTypes.func.isRequired,
  // loaded: PropTypes.bool.isRequired,
  // loading: PropTypes.bool.isRequired,
  // filters: PropTypes.instanceOf(Map).isRequired,
  // search: PropTypes.string,
  // setSearch: PropTypes.func.isRequired,
  // setFilters: PropTypes.func.isRequired,
};

const defaultProps = {
  // search: '',
};

const CandidatesInvitedView = ({
  currentUser,
  invitations,
  total,
  listFilteredInvitations,
  // loaded,
  // loading,
  // filters,
  // search,
  // setSearch,
  // setFilters,
}) => {
  const [pageShown, setPageShown] = useState(1);
  const totalPages = Math.ceil(total / 25) || 1;

  useEffect(() => {
    listFilteredInvitations(currentUser, { page: pageShown });
  }, [listFilteredInvitations, currentUser, pageShown]);

  // const { newSearch, onSearchChange } = useSearch(search, setSearch, loaded);

  const scrollTop = () => window.scrollTo(0, 0);

  const getNextPageResults = () => {
    listFilteredInvitations(currentUser, { page: pageShown + 1 }).then(() => scrollTop());
    setPageShown(pageShown + 1);
  };
  const getPrevPageResults = () => {
    listFilteredInvitations(currentUser, { page: pageShown - 1 }).then(() => scrollTop());
    setPageShown(pageShown - 1);
  };

  const PageNavigator = () => {
    if (total < 25 || !invitations || invitations.size === 0) {
      return null;
    }
    return (
      <ResultsPageNavigator>
        <div className="prev-next-container">
          <Button
            style={{ color: pageShown <= 1 ? theme.color.grayLight : theme.color.blue }}
            startIcon={<NavigateBeforeIcon />}
            disabled={pageShown <= 1}
            onClick={() => getPrevPageResults()}
          >
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown} of ${totalPages}`}</Typography>
          <Button
            style={{ color: pageShown >= totalPages ? theme.color.grayLight : theme.color.blue }}
            endIcon={<NavigateNextIcon />}
            disabled={pageShown >= totalPages}
            onClick={() => getNextPageResults()}
          >
            Next
          </Button>
        </div>
      </ResultsPageNavigator>
    );
  };

  return (
    <CandidatesInvitedStyles>
      <h1>Invitations</h1>
      <div>
        {invitations && total && <Banner>Results: {total} Invitations</Banner>}
        {/* <TextField
          id="search"
          placeholder={loading ? 'Loading... ' : 'Search for invitation by name, email, or phone'}
          margin="dense"
          variant="outlined"
          fullWidth
          disabled={loading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={newSearch}
          onChange={onSearchChange}
        />
        <CandidatesFilters /> */}

        <PageNavigator />
        <StyledTable>
          <thead>
            <tr>
              <th width="150">Name</th>
              <th>Email / Phone</th>
              <th>Invitation To</th>
              <th>Date Sent</th>
              <th>Current Status</th>
              <th width="90"> </th>
            </tr>
          </thead>
          <tbody>
            {invitations ? invitations.map((invitation) => <ListingInvitationRow key={invitation.get('id')} invitation={invitation} />) : <Spinner />}
          </tbody>
        </StyledTable>
        <PageNavigator />
      </div>
    </CandidatesInvitedStyles>
  );
};

CandidatesInvitedView.propTypes = propTypes;
CandidatesInvitedView.defaultProps = defaultProps;

export default CandidatesInvitedView;
