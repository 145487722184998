import { connect } from 'react-redux';
import PathwayListView from './pathway_list_view';
// import { getCurrentUser } from '../../../../reducer';
// import { listPathwayInstances } from '../../../../solve/actions';

const mapStateToProps = () => ({
  // user: getCurrentUser(state),
  // pathwayInstances: getPathwayInstances(state),
  // organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
});

const mapDispatchToProps = {
  // listPathwayInstances,
};

const PathwayListContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayListView);

export default PathwayListContainer;
