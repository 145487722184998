import { connect } from 'react-redux';
import ApplyToEventView from './apply_to_event_view';
import { getCurrentUser, getCandidate, getEvent, getCandidateEventForEvent } from '../../../reducer';
import { fetchEvent, createCandidateEvent } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: eventId },
    },
  },
) => ({
  currentUser: getCurrentUser(state),
  candidate: getCandidate(state, getCurrentUser(state).get('candidateId')),
  event: getEvent(state, eventId),
  eventId,
  candidateEvent: getCandidateEventForEvent(state, eventId),
});

const mapDispatchToProps = {
  fetchEvent,
  createCandidateEvent,
};

const ApplyToEventContainer = connect(mapStateToProps, mapDispatchToProps)(ApplyToEventView);

export default ApplyToEventContainer;
