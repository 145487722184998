import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();
const EMPTY_MAP = fromJS({});

const DEFAULT_STATE = fromJS({
  filters: EMPTY_MAP,
  zipCode: '',
  search: '',
  candidatesEventsLoading: false,
  candidatesEventsLoaded: 0,
  candidatesEventsList: EMPTY_SET,
  candidatesProgramsLoading: false,
  candidatesProgramsLoaded: 0,
  candidatesProgramsList: EMPTY_SET,
  filteredEventsLoading: false,
  filteredEventsLoaded: 0,
  filteredEventsList: EMPTY_SET,
  filteredProgramsLoading: false,
  filteredProgramsLoaded: 0,
  filteredProgramsList: EMPTY_SET,
  filteredServicesList: EMPTY_SET,
  filteredServicesLoaded: 0,
  filteredServicesLoading: false,
  aBServicesLoading: false,
  aBServicesLoaded: 0,
  aBServicesList: EMPTY_SET,
  sortBy: 'distance',
  allProgramsLoaded: 0,
  allEventsLoaded: 0,
  allServicesLoaded: 0,
  allProgramsList: EMPTY_SET,
  allEventsList: EMPTY_SET,
  allServicesList: EMPTY_SET,
  page: 0,
  filteredEventsListTotalCount: 0,
  filteredProgramsListTotalCount: 0,
  filteredServicesTotalCount: 0,
});

const StaffEPCandidatesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_STAFF_EP_CANDIDATES_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          candidatesProgramsLoaded: false,
          candidatesEventsLoaded: false,
          filteredProgramsLoaded: false,
          filteredEventsLoaded: false,
          filteredServicesLoaded: false,
          aBServicesLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_EP_CANDIDATES_ZIP_CODE:
      if (state.get('zipCode') !== action.zipCode) {
        return state.merge({
          filteredEventsLoaded: false,
          filteredProgramsLoaded: false,
          filteredServicesLoaded: false,
          aBServicesLoaded: false,
          zipCode: action.zipCode,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_EP_CANDIDATES_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          candidatesProgramsLoaded: false,
          candidatesEventsLoaded: false,
          filteredEventsLoaded: false,
          filteredProgramsLoaded: false,
          filteredServicesLoaded: false,
          allStaffProgramsLoaded: false,
          allStaffEventsLoaded: false,
          allStaffServicesLoaded: false,
          aBServicesLoaded: false,
          search: action.search,
        });
      }
      return state;
    case ACTIONS.SET_STAFF_EP_CANDIDATES_PAGE:
      if (state.get('page') !== action.page) {
        return state.merge({
          page: action.page,
        });
      }
      return state;
    case ACTIONS.LIST_FILTERED_EVENTS_FOR_STAFF.request:
      return state.merge({
        filteredEventsLoading: true,
      });
    case ACTIONS.LIST_FILTERED_EVENTS_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        filteredEventsListTotalCount: action.response.totalCount,
        filteredEventsList: OrderedSet([...state.get('filteredEventsList').toJS(), ...action.response.result]),
        filteredEventsLoading: false,
        filteredEventsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_EVENTS_FOR_STAFF.failure:
      return state.merge({
        filteredEventsList: EMPTY_SET,
        filteredEventsLoading: false,
        filteredEventsLoaded: false,
      });
    case ACTIONS.LIST_FILTERED_SERVICES_FOR_STAFF.request:
      return state.merge({
        filteredServicesLoading: true,
      });
    case ACTIONS.LIST_FILTERED_SERVICES_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        filteredServicesTotalCount: action.response.totalCount,
        filteredServicesList: OrderedSet([...state.get('filteredServicesList').toJS(), ...action.response.result]),
        filteredServicesLoading: false,
        filteredServicesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_SERVICES_FOR_STAFF.failure:
      return state.merge({
        filteredServicesLoading: false,
        filteredServicesLoaded: false,
      });
    case ACTIONS.LIST_FILTERED_AB_SERVICES_FOR_STAFF.request:
      return state.merge({
        aBServicesLoading: true,
      });
    case ACTIONS.LIST_FILTERED_AB_SERVICES_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        aBServicesList: OrderedSet(action.response.result),
        aBServicesLoading: false,
        aBServicesLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_AB_SERVICES_FOR_STAFF.failure:
      return state.merge({
        aBServicesList: EMPTY_SET,
        aBServicesLoading: false,
        aBServicesLoaded: false,
      });
    case ACTIONS.LIST_ALL_PROGRAMS_FOR_STAFF.request:
      return state.merge({
        allProgramsLoading: true,
      });
    case ACTIONS.LIST_ALL_PROGRAMS_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        allProgramsList: OrderedSet(action.response.result),
        allProgramsLoading: false,
        allProgramsLoaded: Date.now(),
      });
    case ACTIONS.LIST_ALL_PROGRAMS_FOR_STAFF.failure:
      return state.merge({
        allProgramsList: EMPTY_SET,
        allProgramsLoading: false,
        allProgramsLoaded: false,
      });
    case ACTIONS.LIST_ALL_EVENTS_FOR_STAFF.request:
      return state.merge({
        allEventsLoading: true,
      });
    case ACTIONS.LIST_ALL_EVENTS_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        allEventsList: OrderedSet(action.response.result),
        allEventsLoading: false,
        allEventsLoaded: Date.now(),
      });
    case ACTIONS.LIST_ALL_EVENTS_FOR_STAFF.failure:
      return state.merge({
        allEventsList: EMPTY_SET,
        allEventsLoading: false,
        allEventsLoaded: false,
      });
    case ACTIONS.LIST_ALL_SERVICES_FOR_STAFF.request:
      return state.merge({
        allServicesLoading: true,
      });
    case ACTIONS.LIST_ALL_SERVICES_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        allServicesList: OrderedSet(action.response.result),
        allServicesLoading: false,
        allServicesLoaded: Date.now(),
      });
    case ACTIONS.LIST_ALL_SERVICES_FOR_STAFF.failure:
      return state.merge({
        allServicesList: EMPTY_SET,
        allServicesLoading: false,
        allServicesLoaded: false,
      });
    case ACTIONS.LIST_FILTERED_PROGRAMS_FOR_STAFF.request:
      return state.merge({
        filteredProgramsLoading: true,
      });
    case ACTIONS.LIST_FILTERED_PROGRAMS_FOR_STAFF.success:
      // TODO: should we just do this client-side?
      return state.merge({
        filteredProgramsListTotalCount: action.response.totalCount,
        filteredProgramsList: OrderedSet([...state.get('filteredProgramsList').toJS(), ...action.response.result]),
        filteredProgramsLoaded: Date.now(),
        filteredProgramsLoading: false,
      });
    case ACTIONS.LIST_FILTERED_PROGRAMS_FOR_STAFF.failure:
      return state.merge({
        filteredProgramsList: EMPTY_SET,
        filteredProgramsLoading: false,
        filteredProgramsLoaded: false,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_PROGRAMS.request:
      return state.merge({
        candidatesProgramsLoading: true,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_PROGRAMS.success:
      return state.merge({
        candidatesProgramsList: OrderedSet(action.response.result),
        candidatesProgramsLoading: false,
        candidatesProgramsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_PROGRAMS.failure:
      return state.merge({
        candidatesProgramsLoading: false,
        candidatesProgramsLoaded: false,
        candidatesProgramsList: EMPTY_SET,
      });

    case ACTIONS.LIST_FILTERED_CANDIDATES_EVENTS.request:
      return state.merge({
        candidatesEventsLoading: true,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_EVENTS.success:
      return state.merge({
        candidatesEventsList: OrderedSet(action.response.result),
        candidatesEventsLoading: false,
        candidatesEventsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_EVENTS.failure:
      return state.merge({
        candidatesEventsLoading: false,
        candidatesEventsLoaded: false,
        candidatesEventsList: EMPTY_SET,
      });

    case ACTIONS.CLEAR_FILTERED_PROGRAMS_AND_EVENTS:
      return state.merge({
        filteredEventsListTotalCount: 0,
        filteredProgramsListTotalCount: 0,
        filteredServicesTotalCount: 0,
        filteredProgramsList: EMPTY_SET,
        filteredServicesList: EMPTY_SET,
        filteredEventsList: EMPTY_SET,
        aBServicesList: EMPTY_SET,
      });
    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search') || DEFAULT_STATE.get('search');
export const getZipCode = (state) => state.get('zipCode') || DEFAULT_STATE.get('zipCode');
export const getPage = (state) => state.get('page') || DEFAULT_STATE.get('page');
export const getSortBy = (state) => state.get('filters').get('sortBy') || DEFAULT_STATE.get('sortBy');

export const getCandidatesEventsLoaded = (state) => state.get('candidatesEventsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getCandidatesEventsIds = (state) => state.get('candidatesEventsList') || DEFAULT_STATE.get('candidatesEventsList');
export const getFilteredEventsLoaded = (state) => state.get('filteredEventsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getFilteredEventIds = (state) => state.get('filteredEventsList') || DEFAULT_STATE.get('filteredEventsList');
export const getFilteredProgramsLoaded = (state) => state.get('filteredProgramsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getFilteredProgramIds = (state) => state.get('filteredProgramsList') || DEFAULT_STATE.get('filteredProgramsList');
export const getCandidatesProgramsLoaded = (state) => state.get('candidatesProgramsLoaded ') + RELOAD_TIMEOUT > Date.now();
export const getCandidatesProgramsIds = (state) => state.get('candidatesProgramsList') || DEFAULT_STATE.get('candidatesProgramsList');
export const getFilteredServicesLoaded = (state) => state.get('filteredServicesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getFilteredServiceIds = (state) => state.get('filteredServicesList') || DEFAULT_STATE.get('filteredServicesList');
export const getABServiceIds = (state) => state.get('aBServicesList') || DEFAULT_STATE.get('aBServicesList');
export const getABServicesLoaded = (state) => state.get('aBServicesLoaded') + RELOAD_TIMEOUT > Date.now();

export const getStaffEPAllProgramsLoaded = (state) => state.get('allProgramsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getStaffEPAllProgramIds = (state) => state.get('allProgramsList') || DEFAULT_STATE.get('allProgramsList');
export const getStaffEPAllEventsLoaded = (state) => state.get('allEventsLoaded') + RELOAD_TIMEOUT > Date.now();
export const getStaffEPAllEventIds = (state) => state.get('allEventsList') || DEFAULT_STATE.get('allEventsList');
export const getStaffEPAllServicesLoaded = (state) => state.get('allServicesLoaded') + RELOAD_TIMEOUT > Date.now();
export const getStaffEPAllServiceIds = (state) => state.get('allServicesList') || DEFAULT_STATE.get('allServicesList');

export default StaffEPCandidatesReducer;
