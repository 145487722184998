// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const additionalQuestionsFormValidate = () => {
  const errors = {};
  return errors;
};

export default additionalQuestionsFormValidate;
