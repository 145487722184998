import styled from 'styled-components';
import theme from '../../../common/theme';

const NotesListStyles = styled.div`
  position: relative;
  .new-note {
    position: absolute;
    top: -60px;
    right: 0;
  }

  h2 {
    margin-bottom: 20px;
    color: ${theme.color.blue};
  }
`;

export default NotesListStyles;
