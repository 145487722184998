import styled from 'styled-components';

const ReviewFormStyles = styled.div`
  form {
    max-width: 1100px;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  button {
    outline: none;
    margin-left: 12px;
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .form-label {
    color: #6c7a89;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
`;

export default ReviewFormStyles;
