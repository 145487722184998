import { connect } from 'react-redux';
import PathwayParentAccountReportsView from './pathway_parent_account_reports_view';
import { listChildOrganizations, listPathways, listCandidatePathwaysForOrganization } from '../../../solve/actions';
import { getCurrentUser, getChildOrganizations, getPathways } from '../../../reducer';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  organizationName: getCurrentUser(state).getIn(['staffOrganization', 'name']),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
  childOrganizations: getChildOrganizations(state, getCurrentUser(state).getIn(['staffOrganization', 'id'])),
  pathways: getPathways(state),
});

const mapDispatchToProps = {
  listChildOrganizations,
  listPathways,
  listCandidatePathwaysForOrganization,
};

const PathwayParentAccountReportsContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayParentAccountReportsView);

export default PathwayParentAccountReportsContainer;
