import styled from 'styled-components';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import theme from '../../../common/theme';

export const Check = styled(CheckIcon)`
  color: ${theme.color.greenLight};
  font-size: 1.7rem !important;
`;

export const Clear = styled(ClearIcon)`
  color: ${theme.color.redLight};
  font-size: 1.7rem !important;
`;

export const QuestionContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  .MuiFormControl-root {
    margin: 15px 0px 15px !important;
  }
`;
