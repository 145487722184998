import { connect } from 'react-redux';
import ApplyToProgramView from './apply_to_program_view';
import { getCurrentUser, getCandidate, getProgram, getCandidateProgramForProgram } from '../../../reducer';
import { fetchProgram, createCandidateProgram } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: programId },
    },
  },
) => ({
  currentUser: getCurrentUser(state),
  candidate: getCandidate(state, getCurrentUser(state).get('candidateId')),
  program: getProgram(state, programId),
  programId,
  candidateProgram: getCandidateProgramForProgram(state, programId),
});

const mapDispatchToProps = {
  fetchProgram,
  createCandidateProgram,
};

const ApplyToProgramContainer = connect(mapStateToProps, mapDispatchToProps)(ApplyToProgramView);

export default ApplyToProgramContainer;
