import differenceInFullYears from 'date-fns/differenceInYears';

const settingsFormValidate = (values) => {
  const errors = { userAttributes: {} };
  if (!values.getIn(['userAttributes', 'firstName'])) {
    errors.userAttributes.firstName = 'Please enter your first name';
  }
  if (!values.getIn(['userAttributes', 'lastName'])) {
    errors.userAttributes.lastName = 'Please enter your last name';
  }
  if (values.getIn(['userAttributes', 'password']) && values.getIn(['userAttributes', 'passwordConfirmation'])) {
    if (values.getIn(['userAttributes', 'password']) !== values.getIn(['userAttributes', 'passwordConfirmation'])) {
      errors.userAttributes.passwordConfirmation = 'Both Passwords Must Match';
    }
  }
  if (!values.get('genderType')) {
    errors.genderType = 'Required';
  }
  if (!values.get('birthday')) {
    errors.birthday = 'Please enter your birthday';
  }
  if (values.get('birthday') && differenceInFullYears(Date.now(), values.get('birthday')) < 14) {
    errors.birthday = 'Age must be 14 or older';
  }
  if (values.getIn(['userAttributes', 'phone']) && values.getIn(['userAttributes', 'phone']).match(/\d/g).length < 10) {
    errors.phone = 'Please enter a valid phone number';
  }
  return errors;
};

export default settingsFormValidate;
