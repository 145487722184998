import React from 'react';
import PropTypes from 'prop-types';
// import { Map } from 'immutable';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MyStatusStyles from './my_status_styles';

const MyStatusView = ({ candidateEvent, onChange }) =>
  candidateEvent && (
    <MyStatusStyles className="event-status">
      <FormControl margin="dense" variant="outlined">
        {/* <InputLabel>Distance</InputLabel> */}
        <Select
          // fullWidth
          value={candidateEvent.get('candidateState')}
          name="candidateEventState"
          variant="outlined"
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          <MenuItem>
            <em>My Status</em>
          </MenuItem>
          {/* <MenuItem value="c_saved">Saved</MenuItem> */}
          <MenuItem value="c_registered">Registered</MenuItem>
          <MenuItem value="c_attended">Attended</MenuItem>
        </Select>
      </FormControl>
    </MyStatusStyles>
  );

MyStatusView.propTypes = {
  onChange: PropTypes.func.isRequired,
  candidateEvent: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
};

export default MyStatusView;
