import { connect } from 'react-redux';
import BackgroundsEditingView from './backgrounds_editing_view';
// import { getEnumsForFormSelect } from '../../../../../reducer';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => ({
  // TODO: Create an enum containing background types
  // backgroundOptions: getEnumsForFormSelect(state, 'BackgroundType'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const BackgroundsEditingContainer = connect(mapStateToProps, mapDispatchToProps)(BackgroundsEditingView);

export default BackgroundsEditingContainer;
