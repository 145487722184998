import styled from 'styled-components';
// import theme from '../../../common/theme';

const PathwayReportsStyles = styled.div`
  h4 {
    margin: 10px 0 5px 0;
  }
  h5 {
    margin: 15px 0 0 0;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

export const SubTabs = styled.div`
  margin: 40px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  background: #fff;
  border: solid 1px #fff;
`;

export const GridTile = styled.div`
  border-radius: 5px;
  border: 1px solid #eee;
  padding: 20px 10px;
  text-align: center;
  .MuiCheckbox-root {
    float: right;
    padding: 0;
    margin: -10px 0 0 -25px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
  }
  img {
    width: 60px;
  }
`;

export default PathwayReportsStyles;
