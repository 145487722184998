import styled from 'styled-components';
import theme from '../../../common/theme';

const SplashScreenStyles = styled.div``;

export const Hero = styled.div`
  background: #000;
  height: calc(85vh);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 650px) {
    height: calc(100vh - 110px);
  }

  div {
    width: calc(80vw);
  }

  h1 {
    font-size: calc(4vw);
    color: #fff;
    font-weight: 100;

    @media only screen and (max-width: 650px) {
      font-size: calc(3.5vh);
    }
  }

  h2 {
    font-size: calc(2vw);
    color: #fff;

    @media only screen and (max-width: 650px) {
      font-size: calc(2.5vh);
    }
  }

  button {
    margin-right: ${theme.padding.base};
    margin-bottom: ${theme.padding.base};

    @media only screen and (max-width: 650px) {
      margin-bottom: 10px;
      width: calc(80vw);
    }
  }

  .bg {
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(85vh);
    opacity: 0.2;
    background: url(/images/Landing_Background_3.jpg) center center;
    background-size: cover;
  }
`;

export default SplashScreenStyles;
