import { startCase } from 'lodash';
import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import LocationIcon from '@material-ui/icons/Room';

import CircularSpinner from 'risekit/common/components/spinner';
import useGetPathwayList from 'risekit/publicLinks/hooks/useGetIndeedJob';

import { IndeedJobStyles } from './indeed-job.styles';

const IndeedJob = (props: any) => {
  const { indeedJobId } = props;

  const { loading, error, indeedJob } = useGetPathwayList(indeedJobId);

  const benefits = indeedJob?.opportunityTypes?.filter((value: any) => ['child-care', 'health-care', 'transportation'].includes(value));

  const navigateToIndeedJobDetail = () => {
    const { url: jobUrl } = indeedJob;

    if (jobUrl) {
      window.open(jobUrl, '_blank');
    }
  };

  if (error) {
    return <div>Opps something went wrong.</div>;
  }

  if (loading || !indeedJob) {
    return <CircularSpinner />;
  }

  return (
    <IndeedJobStyles>
      <div className="job-title-container">
        <div className="job-title">{indeedJob?.jobtitle}</div>
        <div className="job-company">{indeedJob?.company}</div>
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
        <LocationIcon style={{ color: '#3fc380', marginRight: 5 }} />
        <span className="job-location">
          {indeedJob?.city}, {indeedJob?.state}
        </span>
      </div>

      <div className="url-btn-container">
        <div className="url-wrapper">
          <a href="https://www.indeed.com/" rel="nofollow noreferrer" title="Job Search">
            jobs by <img src="https://www.indeed.com/p/jobsearch.gif" style={{ border: 0, verticalAlign: 'middle' }} alt="Indeed job search" />
          </a>
        </div>
        <Button className="view-details-btn" variant="contained" color="primary" onClick={navigateToIndeedJobDetail}>
          View Details
        </Button>
      </div>

      <p style={{ marginTop: 30, marginBottom: 60 }}>
        <span dangerouslySetInnerHTML={{ __html: indeedJob?.snippet }} />
      </p>

      {benefits?.length > 0 && (
        <Fragment>
          <p>
            <span className="sub-heading">Benefits:</span>
          </p>
          <ul>
            {benefits.map((benefit: any) => (
              <li key={benefit}>{startCase(benefit)}</li>
            ))}
          </ul>
        </Fragment>
      )}
    </IndeedJobStyles>
  );
};

export default IndeedJob;
