import React, { useState, useEffect } from 'react';
import numeral from 'numeral';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { reduxForm } from '../../../../common/form';
import { Text, TextHeading } from '../../../../common/components';
import Button from '../../../../common/components/button/index';
import additionalQuestionsCompletedValidate from './additional_questions_completed_validate';
import AdditionalQuestionsCompletedStyles from './additional_questions_completed_styles';
import EventTile from '../../../../events/components/tile';
import CandidateProgramModel from '../../../../solve/models/candidate_program_model';
import UserModel from '../../../../session/models/user_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  program: PropTypes.instanceOf(Map).isRequired,
  candidateProgram: PropTypes.instanceOf(CandidateProgramModel).isRequired,
  transitionCandidateProgramByCandidate: PropTypes.func.isRequired,
  skippedQuestionnaire: PropTypes.bool,
};

const defaultProps = {
  skippedQuestionnaire: false,
};

const AdditionalQuestionsCompleted = ({ currentUser, transitionCandidateProgramByCandidate, candidateProgram, program, skippedQuestionnaire }) => {
  const [submitted, setSubmitted] = useState(false);
  const customThankYouMessage = program.get('customThankYouMessage');

  const currentState = candidateProgram.get('candidateState');
  const cpId = candidateProgram.get('id');
  useEffect(() => {
    if (currentState === 'c_saved' || currentState === 'c_deleted') {
      transitionCandidateProgramByCandidate(currentUser, cpId, 'applied');
    }
  }, [currentState, cpId, currentUser, transitionCandidateProgramByCandidate]);

  const handleSubmitAndRedirect = () => {
    setSubmitted(true);
  };

  if (submitted) {
    return <Redirect to="/candidate/programs-and-events" />;
  }

  return (
    <AdditionalQuestionsCompletedStyles>
      <EventTile
        id={program.get('id')}
        key={program.get('id')}
        logo={program.get('logoUrl')}
        title={program.get('name')}
        body={program.get('description')}
        distance={program.get('distance')}
        cost={program.get('cost') > 0 ? numeral(program.get('cost')).format('0') : 'FREE'}
      />
      <form onSubmit={handleSubmitAndRedirect}>
        {!skippedQuestionnaire && (
          <TextHeading size="large" weight="bold" margin="25px 0px 10px">
            Thank you for completing the questions.
          </TextHeading>
        )}
        <Text size="large" color="medium">
          Your application was submitted successfully.
        </Text>
        {customThankYouMessage && (
          <div className="message">
            <h4>Note from {program.getIn(['postingOrganization', 'name'])}:</h4>
            <p dangerouslySetInnerHTML={{ __html: customThankYouMessage }} />
          </div>
        )}
        <Button buttonType="primary-outline" type="submit">
          Done
        </Button>
      </form>
    </AdditionalQuestionsCompletedStyles>
  );
};

AdditionalQuestionsCompleted.propTypes = propTypes;
AdditionalQuestionsCompleted.defaultProps = defaultProps;

const AdditionalQuestionsCompletedView = reduxForm({
  form: 'applyToProgramForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: additionalQuestionsCompletedValidate,
})(AdditionalQuestionsCompleted);

export default AdditionalQuestionsCompletedView;
