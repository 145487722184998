import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { List } from 'immutable';
import theme from '../../../../common/theme';

const propTypes = {
  status: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  addressState: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  serviceTypes: PropTypes.instanceOf(List).isRequired,
  targetParticipantTypes: PropTypes.instanceOf(List).isRequired,
  isMyOrg: PropTypes.bool.isRequired,
  postingUrl: PropTypes.string.isRequired,
};

const defaultProps = {};

const statusEnums = {
  active: 'Active',
  pre_published: 'Draft',
  closed: 'Closed',
};

const StaffProgramTileView = ({ status, logo, title, city, addressState, zip, serviceTypes, targetParticipantTypes, isMyOrg, postingUrl }) => (
  <tr>
    <td>
      <img src={logo} height="50" style={{ paddingTop: '5px' }} alt="Employer" />
    </td>
    <td>
      <h4>{title}</h4>
      {city}, {addressState}
    </td>
    <td>{serviceTypes && serviceTypes.map((type, index) => <small key={type}>{(index ? ', ' : '') + startCase(type)}</small>)}</td>
    <td>{zip}</td>
    <td>{targetParticipantTypes && targetParticipantTypes.map((type, index) => <small key={type}>{(index ? ', ' : '') + startCase(type)}</small>)}</td>
    <td>{isMyOrg && <small>{statusEnums[status]}</small>}</td>
    <td>
      {isMyOrg && (
        <Link to={`${postingUrl}/edit`}>
          <Button color="secondary" size="small">
            Edit
          </Button>
        </Link>
      )}
    </td>
    <td>
      <Link to={postingUrl}>
        <Button variant="contained" color="secondary" size="small" style={{ backgroundColor: theme.color.secondary }}>
          View
        </Button>
      </Link>
    </td>
  </tr>
);

StaffProgramTileView.propTypes = propTypes;
StaffProgramTileView.defaultProps = defaultProps;

export default StaffProgramTileView;
