import React from 'react';
import { render, hydrate } from 'react-dom';
import './index.css';
import App from './app';
import Admin from './admin';
import * as serviceWorker from './service_worker';

let Root = App;
if (window.location.pathname.startsWith('/admin')) {
  Root = Admin;
}

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (process.env.REACT_APP_USE_SERVICE_WORKER === 'yes') {
  serviceWorker.register();
} else {
  serviceWorker.unregister();
}
