import { connect } from 'react-redux';
import MyStatusView from './my_status_view';
import { transitionCandidateProgramByCandidate } from '../../../solve/actions';
import { getCurrentUser } from '../../../reducer';

const mapStateToProps = (state) => ({
  // prop assignments
  currentUser: getCurrentUser(state),
});

const mapDispatchToProps = {
  transitionCandidateProgramByCandidate,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  onChange: (status) => dispatchProps.transitionCandidateProgramByCandidate(stateProps.currentUser, ownProps.candidateProgram.get('id'), status.substring(2)),
});

const MyStatusContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(MyStatusView);

export default MyStatusContainer;
