import React from 'react';
import {
  List as AdminList,
  Datagrid,
  Edit as AdminEdit,
  Create as AdminCreate,
  SimpleForm,
  TextField,
  DateField,
  EditButton,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  // DisabledInput,
  TextInput,
  Filter,
  LongTextInput,
  Pagination,
} from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

export const Icon = SettingsIcon;

// eslint-disable-next-line react/jsx-props-no-spreading
const PostPagination = (props) => <Pagination rowsPerPageOptions={[5, 10, 25, 50, 100]} {...props} />;

const SearchFilter = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Filter {...props} pagination={<PostPagination />}>
    <ReferenceInput label="Search by Organization" source="postingOrganizationId" reference="organizations" allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Search by Employer" source="employerId" reference="organizations" allowEmpty alwaysOn>
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <TextInput label="Search by Title" source="title" alwaysOn />
  </Filter>
);

export const List = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminList {...props} filters={<SearchFilter />} pagination={<PostPagination />}>
    <Datagrid>
      <ReferenceField label="Posting Organization" source="postingOrganizationId" reference="organizations">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Employer" source="employerId" reference="organizations">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <DateField source="createdAt" />
      <TextField label="State" source="aasmState" />
      <EditButton basePath="/job_postings" />
    </Datagrid>
  </AdminList>
);

// eslint-disable-next-line react/prop-types
const Title = ({ record }) => <span>GlobalSetting {record ? `"${record.name}"` : ''}</span>;

export const Edit = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminEdit title={<Title />} {...props}>
    <SimpleForm>
      <ReferenceInput label="Posting Organization" source="postingOrganizationId" reference="organizations">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <TextInput label="State" source="aasmState" />
    </SimpleForm>
  </AdminEdit>
);

export const Create = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <AdminCreate title="Create a SystemEnum" {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <LongTextInput source="value" />
    </SimpleForm>
  </AdminCreate>
);
