import { PDFDownloadLink } from '@react-pdf/renderer';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import React from 'react';
import PdfDoc from '../pdf';
import ErrorBoundary from '../../util/error_boundary';
import { useDelayed } from '../../util/hooks';

const RENDER_PDF_DELAY = 2000;

const DownloadResumeButton = ({ candidate, resume }) => {
  const hasData = candidate && resume;
  const showPdf = useDelayed(RENDER_PDF_DELAY, hasData);
  if (!showPdf) {
    return null;
  }

  const filename = `resume-${candidate.getIn(['user', 'firstName'])}-${candidate.getIn(['user', 'lastName'])}.pdf`;
  return (
    <ErrorBoundary customError="Download temporarily unavailable...">
      <PDFDownloadLink document={<PdfDoc candidate={candidate} resume={resume} />} filename={filename}>
        {({ loading }) =>
          (loading ? (
            'Loading...'
          ) : (
            <Button className="action-contained" fullWidth startIcon={<CloudDownloadIcon />} variant="contained">
              Download PDF
            </Button>
          ))}
      </PDFDownloadLink>
    </ErrorBoundary>
  );
};

DownloadResumeButton.propTypes = {
  candidate: PropTypes.instanceOf(Map),
  resume: PropTypes.instanceOf(Map),
};

DownloadResumeButton.defaultProps = {
  candidate: undefined,
  resume: undefined,
};

export default DownloadResumeButton;
