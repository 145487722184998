import { connect } from 'react-redux';
import InviteApplicantsView from './invite_applicants_view';
import { getJobPosting, getEvent, getProgram, getIndeedJobPosting } from '../../../reducer';

import {} from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      url,
      params: { id },
    },
  },
) => {
  let inviteType;
  let inviteCategory;
  let inviteInfo;
  let indeedInviteInfo;

  if (url.includes('jobs')) {
    inviteType = 'job-recommendation';
    inviteCategory = 'Job';
    inviteInfo = getJobPosting(state, id);
    indeedInviteInfo = getIndeedJobPosting(state, id);
  } else if (url.includes('programs')) {
    inviteType = 'program-recommendation';
    inviteCategory = 'Program';
    inviteInfo = getProgram(state, id);
  } else if (url.includes('events')) {
    inviteType = 'event-recommendation';
    inviteCategory = 'Event';
    inviteInfo = getEvent(state, id);
  }
  return {
    inviteType,
    inviteInfo,
    inviteCategory,
    indeedInviteInfo,
    invitePath: url.replace('/invite', ''),
  };
};

const mapDispatchToProps = {};

const InviteApplicantsContainer = connect(mapStateToProps, mapDispatchToProps)(InviteApplicantsView);

export default InviteApplicantsContainer;
