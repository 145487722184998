import styled from 'styled-components';
import theme from '../../../../common/theme';

const IndeedJobListRowStyles = styled.tr`
  .job-logo {
    max-width: 70px;
    max-height: 35px;
    width: auto;
  }

  span {
    color: ${theme.color.grayDark};
  }
`;

export default IndeedJobListRowStyles;
