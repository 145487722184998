import styled from 'styled-components';
import theme from '../../../../common/theme';

const DetailsFormStyles = styled.div`
  form {
    width: 100%;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  button {
    outline: none;
  }

  .details-form {
    padding: 5px 10px 25px;
  }

  @media ${theme.query.desktopMax} {
    form {
      padding: 0px 5px;
    }
    .details-form {
      padding: 0px 0px 20px;
    }
  }
`;

export default DetailsFormStyles;
