import { Map } from 'immutable';
import { connect } from 'react-redux';
import FinalizeFormView from './finalize_form_view';
import { formValueSelector } from '../../../../common/form';
// import { SomeActions } from '../../actions';

const selector = formValueSelector('programForm');

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })

// const selector = formValueSelector('programForm');

const mapStateToProps = (state) => ({
  address: selector(state, 'addressAttributes'),
  classDays: selector(state, 'days'),
  date: selector(state, 'startDate'),
  description: selector(state, 'description'),
  endTime: selector(state, 'endTime'),
  time: selector(state, 'startTime'),
  industryType: selector(state, 'industryType'),
  isClass: selector(state, 'isClass'),
  name: selector(state, 'name'),
  paymentCost: selector(state, 'paymentCost'),
  phone: selector(state, 'phone'),
  primaryServices: selector(state, 'serviceTypes') || Map(),
  skillsGained: selector(state, 'gainedSkillTypes'),
  skillsCertifications: selector(state, 'gainedCertifications'),
  supportiveServices: selector(state, 'supportTypes') || Map(),
  website: selector(state, 'website'),
  primaryService: selector(state, 'primaryServiceType'),
  startTime: selector(state, 'startTime'),
  targetZipCodes: !selector(state, 'allZipCodesWelcomed') && selector(state, 'targetZipCodes'),
  targetMaximumAge: !selector(state, 'allAgesWelcomed') && selector(state, 'targetMaximumAge'),
  targetMinimumAge: !selector(state, 'allAgesWelcomed') && selector(state, 'targetMinimumAge'),
  targetParticipantTypes: selector(state, 'targetParticipantTypes') || Map(),
  isResumeRequired: selector(state, 'isResumeRequired'),
  dressCode: selector(state, 'dressCode'),
  payment: selector(state, 'payment'),
  estimatedSalary: selector(state, 'estimatedSalary'),
  gainedJobTypes: selector(state, 'gainedJobTypes'),
  targetMinimumEducationLevelType: selector(state, 'targetMinimumEducationLevelType'),
  postExpirationDate: selector(state, 'postExpirationDate'),
});

// Wire up specific actions using this function. If you directly map an
// action creator, it will be automatically wrapped with `dispatch()`,
// so do not define new functions here unless you need to override or
// explicitly specify a parameter of an action creator based on the
// nature of the component or its props.
//
// mapDispatchToProps(dispatch, { prop })
const mapDispatchToProps = {};

const FinalizeFormContainer = connect(mapStateToProps, mapDispatchToProps)(FinalizeFormView);

export default FinalizeFormContainer;
