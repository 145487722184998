import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  candidateState: undefined,
  staffState: undefined,
  screeningScore: undefined,
  appliedAt: undefined,
  acceptedOfferAt: undefined,
  declinedOfferAt: undefined,
  staffArchivedAt: undefined,
  staffDeletedAt: undefined,
  allRequiredAnswersMatch: undefined,
  helpingOrganization: {
    id: undefined,
    name: undefined,
    orgType: undefined,
  },
  candidateSurveys: undefined,
  candidate: {
    candidateID: undefined,
    user: undefined,
    linkedOrganizations: undefined,
  },
  jobPosting: {
    jobPostingId: undefined,
    jobPostingTitle: undefined,
    screeningSurveys: undefined,
    screeningQuestionsCount: undefined,
  },
  externalJobPosting: {
    id: undefined,
    externalJobSourceType: undefined,
    externalId: undefined,
    title: undefined,
  },
  lastApprovedCandidateState: undefined,
  lastApprovedStaffState: undefined,
  lastStateChangedBy: undefined,
};

class CandidateApplicationModel extends Record(DEFAULT_ARGS) {
  static _name = 'CandidateApplication';

  get fullName() {
    return this.getIn(['jobPosting', 'jobPostingTitle']);
  }
}

export default CandidateApplicationModel;
