import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useFetchOnce } from '../../../../util/hooks';
// import theme from '../../../../common/theme';
import RecommendedListingsStyles from './recommended_listings_styles';
import { CardHeader, CardSubHeader, CardBody, JobItem, Pill, JobHeader, Date } from '../../../../common/styles/card';
import UserModel from '../../../../session/models/user_model';
import Spinner from '../../../../common/components/spinner';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  jobs: PropTypes.instanceOf(OrderedSet),
  listRecommendedPostingsForCandidate: PropTypes.func.isRequired,
  isRisekit: PropTypes.bool.isRequired,
};

const defaultProps = {
  jobs: undefined,
};

const RecommendedListingsView = ({ currentUser, jobs, listRecommendedPostingsForCandidate, isRisekit }) => {
  const resolved = useFetchOnce(currentUser, currentUser.get('id'), listRecommendedPostingsForCandidate);

  return (
    <RecommendedListingsStyles>
      <CardHeader padding>
        <CardSubHeader>
          <h2>{`Recommendations from ${isRisekit ? 'Risekit' : 'Solve'}`}</h2>
        </CardSubHeader>
      </CardHeader>
      <CardBody paddingBottom paddingTop>
        {/* eslint-disable-next-line no-nested-ternary */}
        {jobs && jobs.size > 0 ? (
          jobs.map((job) => (
            <JobItem key={job.get('id')}>
              <Link to={`/candidate/jobs/${job.get('id')}`}>
                <Button size="small">
                  See details <ChevronRightIcon />
                </Button>
              </Link>
              <JobHeader>
                <Pill>Job</Pill>
                {job.get('createdAt') && <Date>Posted {moment(job.get('createdAt')).fromNow()}</Date>}
                {/* {job.get('distance') && (
                  <Location>
                    <LocationOnOutlinedIcon />
                    {Math.round(parseFloat(job.get('distance')) * 10) / 10} miles
                  </Location>
                )} */}
              </JobHeader>
              <div className="job-info">
                <strong>{job.get('title')},</strong>
                <span className="company">by {job.getIn(['postingOrganization', 'name'])}</span>
              </div>
            </JobItem>
          ))
        ) : !resolved ? (
          <Spinner />
        ) : (
          'No recent job postings fit your profile.'
        )}
      </CardBody>
    </RecommendedListingsStyles>
  );
};

RecommendedListingsView.propTypes = propTypes;
RecommendedListingsView.defaultProps = defaultProps;

export default RecommendedListingsView;
