import { createMuiTheme } from '@material-ui/core/styles';
import theme from '../../theme';

// Theme Settings for Material UI Form Helpers

const MuiFormTheme = createMuiTheme({
  palette: {
    primary: {
      light: theme.color.primary,
      main: theme.color.primary,
      dark: theme.color.primary,
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiMenuPaper: {
      top: '55px',
      right: '20px',
      maxWidth: '200px',
      width: '100%',
    },
    MuiFormControl: {
      root: {
        margin: '30px 0px 0px !important',
      },
    },
    MuiFormLabel: {
      root: {
        transform: 'translate(0px, 0px) !important',
        display: 'inline !important',
        top: 'auto !important',
        bottom: '100%',
        paddingBottom: '5px',
        color: theme.color.gray,
        fontSize: '12px',
        textTransform: 'uppercase',
        '&.Mui-focused': {
          color: theme.color.primary,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        position: 'absolute',
        top: '100%',
        margin: '5px 0px 0px !important',
      },
    },
    MuiTypography: {
      root: {
        color: '#777',
      },
    },
    MuiCheckbox: {
      root: {
        color: '#777',
      },
    },
    // MuiCheckbox: {
    //   root: {
    //     padding: '5px',
    //   },
    // },
    // MuiFormControlLabel: {
    //   label: {
    //     display: 'inline',
    //   },
    // },
    // MuiInputLabel: {
    //   root: {
    //     fontSize: '14px',
    //     paddingLeft: '10px',
    //     paddingRight: '10px',
    //     paddingTop: '3px',
    //   },
    // },
    // MuiMenuItem: {
    //   root: {
    //     fontSize: '14px',
    //   },
    // },
    // MuiSelect: {
    //   root: {
    //     borderRadius: '4px',
    //     border: 'solid 1px #979797',
    //     fontSize: '14px',
    //   },
    //   select: {
    //     padding: '10px 5px',
    //   },
    //   selectMenu: {
    //     fontSize: '14px',
    //   },
    // },
  },
});

export default MuiFormTheme;
