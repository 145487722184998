import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import SplashScreenStyles, { Hero } from './splash_screen_styles';
import PublicContainer from '../public_container';

const propTypes = {
  setOnboardingMode: PropTypes.func.isRequired,
  isRisekit: PropTypes.bool.isRequired,
};

const defaultProps = {};

const SplashScreenView = ({ setOnboardingMode, isRisekit }) => (
  <PublicContainer>
    <SplashScreenStyles>
      <Hero>
        <div className="bg" />
        <div>
          <h1>
            {`${
              isRisekit ? 'RiseKit' : 'Solve'
            }'s software network empowers overlooked job seekers to find the right careers, hourly jobs, training programs, career fairs and supportive
            services.`}
          </h1>
          <h2>Let us help connect you. What best describes you?</h2>
          <Link to="/candidate/welcome">
            <Button variant="contained" className="secondary-contained" size="large" onClick={() => setOnboardingMode('candidate')}>
              I am looking for a job
            </Button>
          </Link>
          <Link to="/staff/onboarding/nonprofit">
            <Button variant="contained" className="action-contained" size="large" onClick={() => setOnboardingMode('staff')}>
              I am a Nonprofit Employee
            </Button>
          </Link>
          <Link to="/staff/onboarding/employer">
            <Button variant="contained" className="cta-contained" size="large" onClick={() => setOnboardingMode('employer')}>
              I am an Employer
            </Button>
          </Link>
        </div>
      </Hero>
    </SplashScreenStyles>
  </PublicContainer>
);

SplashScreenView.propTypes = propTypes;
SplashScreenView.defaultProps = defaultProps;

export default SplashScreenView;
