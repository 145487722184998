import isAfter from 'date-fns/isAfter';
import addressValidator from '../../../../common/form/helpers/address_validator';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const programOverviewFormValidate = (values) => {
  const errors = {};
  if (!values.get('name')) {
    errors.name = 'Required';
  }
  if (!values.get('website')) {
    errors.website = 'Required';
  }
  if (!values.get('phone')) {
    errors.phone = 'Required';
  }
  if (!values.get('primaryServiceType')) {
    errors.primaryServiceType = 'Required';
  }
  if (!values.get('industryType')) {
    errors.industryType = 'Required';
  }

  if (!values.get('postExpirationDate')) {
    errors.postExpirationDate = 'Required';
  }

  errors.addressAttributes = addressValidator(values.get('addressAttributes'));

  if (values.get('isClass') === null || values.get('isClass') === undefined) {
    errors.isClass = 'Required';
  }
  if (values.get('isClass') === 'yes') {
    if (!values.get('startDate')) {
      errors.startDate = 'Required';
    }

    if (!values.get('startTime')) {
      errors.startTime = 'Required';
    }

    if (!values.get('endDate')) {
      errors.endDate = 'Required';
    }

    if (!values.get('days') || values.get('days') === undefined) {
      errors.days = 'Required';
    }

    if (!values.get('endTime')) {
      errors.endTime = 'Required';
    }
    if (values.get('startTime') && values.get('endTime') && !isAfter(values.get('endTime'), values.get('startTime'))) {
      errors.endDate = 'A time after the start time is required';
    }
  }

  if (!values.get('description') || values.get('description') === '<p></p>\n') {
    errors.description = 'Required';
  }

  return errors;
};

export default programOverviewFormValidate;
