import ACTIONS from '../actions';
import { ResourcefulReducer } from '../../util';
import CandidateApplicationModel from '../models/candidate_application_model';

const CandidateApplicationReducer = new ResourcefulReducer('candidateApplications', CandidateApplicationModel, {
  actionTypes: [
    ACTIONS.LIST_CANDIDATE_APPLICATIONS_FOR_CANDIDATE,
    ACTIONS.LIST_CANDIDATE_APPLICATIONS_FOR_CANDIDATE.success,
    ACTIONS.LIST_FILTERED_JOB_CANDIDATES_FOR_STAFF.success,
    ACTIONS.CREATE_CANDIDATE_APPLICATION.success,
    ACTIONS.LIST_APPLICATIONS_TO_JOB_POSTING.success,
    ACTIONS.FETCH_CANDIDATE_APPLICATION.success,
    ACTIONS.LIST_EXTERNAL_CANDIDATE_APPLICATIONS_FOR_CANDIDATE.success,
    ACTIONS.CREATE_EXTERNAL_CANDIDATE_APPLICATION.success,
  ],
});

export default CandidateApplicationReducer;
