import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { Map } from 'immutable';
// import StepperSmallView from '../../../ui/components/stepper_small';
import HeaderStyles, { Contact, StatusContainer, Statuses, Status } from './header_styles';
import theme from '../../../../common/theme';
import CustomApplicantStepper from '../../../../jobs/application_list_item/custom_applicant_stepper';
import { programStaffStatusEnums } from '../../../../enums/staff_status_enums';
import UserModel from '../../../../session/models/user_model';
import CandidateApplicationModel from '../../../../solve/models/candidate_application_model';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  step: PropTypes.number,
  hired: PropTypes.bool,
  declined: PropTypes.bool,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  transitionCandidateProgramByStaff: PropTypes.func.isRequired,
  application: PropTypes.oneOfType([PropTypes.instanceOf(CandidateApplicationModel), PropTypes.instanceOf(Map)]).isRequired,
};

const defaultProps = {
  step: -1,
  hired: undefined,
  declined: undefined,
};

const { invited, applied, joined, graduated, droppedOut, declined } = programStaffStatusEnums;

const candidateSteps = [
  { label: 'Invited', value: invited },
  { label: 'Applied', value: applied },
  { label: 'Joined', value: joined },
  { label: 'Graduated', value: graduated },
  { label: 'Dropped out', value: droppedOut },
  { label: 'Declined', value: declined },
];

const candidateStepMapping = {
  [invited]: 0,
  [applied]: 1,
  [joined]: 2,
};

// eslint-disable-next-line no-unused-vars, no-shadow, max-len
const HeaderView = ({ application, currentUser, step, hired, declined, name, email, phone, transitionCandidateProgramByStaff }) => {
  const staffState = application.get('staffState');

  const [activeStep, setActiveStep] = React.useState(candidateStepMapping[staffState]);
  const [screenerOpen, setScreenerOpen] = React.useState(false); // eslint-disable-line no-unused-vars

  const updateStaffStatus = (status) => transitionCandidateProgramByStaff(currentUser, application.get('id'), status.substring(2));
  const getActiveStep = () =>
    // if (staffState === archived) {
    //   return -1;
    // }
    activeStep;
  return (
    <HeaderStyles>
      <Contact>
        <h2>{name}</h2>
        <p>
          <b>@</b> {email}
        </p>
        <p>
          <b>P</b> {phone}
        </p>
      </Contact>
      <StatusContainer>
        <div style={{ flexGrow: 1 }}>
          <h3>Applicant Status Bar for {application.getIn(['program', 'programName'])}</h3>
          <p>Update Bar</p>

          <div style={{ width: '100%' }}>
            <CustomApplicantStepper steps={candidateSteps} activeStep={getActiveStep()} setActiveStep={setActiveStep} onChange={updateStaffStatus} />
          </div>

          {/* <StepperSmallView
            steps={[{ label: 'Reviewed Application' }, { label: 'Request Interview' }, { label: 'Interviewing' }, { label: 'Offer' }]}
            activeStep={step}
          /> */}
        </div>
        <Statuses>
          <Status style={{ color: hired ? theme.color.positive : null }}>
            <CheckIcon />
            Hired
          </Status>
          <Status style={{ color: declined ? theme.color.negative : null }}>
            <CloseIcon />
            Declined
          </Status>
        </Statuses>
      </StatusContainer>
    </HeaderStyles>
  );
};

HeaderView.propTypes = propTypes;
HeaderView.defaultProps = defaultProps;

export default HeaderView;
