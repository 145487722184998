import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  state: undefined,
  title: undefined,
  surveyType: undefined,
  iconUrl: undefined,
  owningOrganizationID: undefined,
  score: undefined,
  total: undefined,
};

class SurveyModel extends Record(DEFAULT_ARGS) {
  static _name = 'Survey';
}

export default SurveyModel;
