import { connect } from 'react-redux';
import CandidatesView from './candidates_view';

import {
  getCurrentUser,
  getStaffCandidateIds,
  getStaffCandidatesLoaded,
  getStaffCandidatesLoading,
  getStaffCandidatesFilters,
  getStaffCandidatesSearch,
  getStaffTotalCandidates,
} from '../../../reducer';

import { listFilteredCandidatesForStaff, setStaffCandidatesSearch, setStaffCandidatesFilters, fetchOrganizationCandidatesReport } from '../../../solve/actions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const candidateIds = getStaffCandidateIds(state);

  return {
    currentUser,
    organizationId: currentUser.getIn(['staffOrganization', 'id']),
    loaded: getStaffCandidatesLoaded(state),
    loading: getStaffCandidatesLoading(state),
    filters: getStaffCandidatesFilters(state),
    search: getStaffCandidatesSearch(state),
    totalCandidates: getStaffTotalCandidates(state),
    candidateIds,
  };
};

const mapDispatchToProps = {
  listFilteredCandidatesForStaff,
  fetchOrganizationCandidatesReport,
  setSearch: setStaffCandidatesSearch,
  setFilters: setStaffCandidatesFilters,
};

// const mergeProps = (stateProps, dispatchProps, ownProps) => ({
//   ...stateProps,
//   ...ownProps,
//   ...dispatchProps,
// });

const CandidatesContainer = connect(mapStateToProps, mapDispatchToProps)(CandidatesView);

export default CandidatesContainer;
