import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';
import DetailsView from './details_view';
// import { } from '../../../solve/actions';
import { getSystemEnumList } from '../../../reducer';
import lookupTableForEnums from '../../../util/lookup_table_for_enums';

const mapStateToProps = (state, { event }) => {
  const serviceTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'ServiceType'), event?.get('serviceTypes')));
  const targetTypes = new OrderedSet(lookupTableForEnums(getSystemEnumList(state, 'TargetParticipantType'), event?.get('targetParticipantTypes')));

  return {
    event,
    serviceTypes,
    targetTypes,
  };
};

const mapDispatchToProps = {};

const DetailsContainer = connect(mapStateToProps, mapDispatchToProps)(DetailsView);

export default DetailsContainer;
