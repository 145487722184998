import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router';
import React, { useEffect } from 'react';

import UserModel from 'session/models/user_model';
import IndeedJobDetails from 'risekit/publicLinks/views/indeedJob/indeed-job';

import JobPreviewStyles from './job_preview_styles';
import JobDetails from '../../../../jobs/components/job_details';
import PreviewButtons from '../../posting_previews/preview_buttons';
import getListingImage from '../../../../common/components/get_listing_image';
import { MainStyles, MainCard, Logo, MainActions } from '../../../../common/styles/detail';

const propTypes = {
  invitation: PropTypes.instanceOf(Map).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  setRedirectPath: PropTypes.func.isRequired,
};

const defaultProps = {};

const JobPreviewView = ({ invitation, currentUser, setRedirectPath }) => {
  const isCurrentUserSignedIn = currentUser?.signedIn;

  const job = invitation.get('recommendedJobPosting') || new Map();

  const logoUrl = getListingImage(job, 'job');

  const isIndeedJob = invitation.get('recommendedExternalJobPosting');
  const indeedJobId = invitation.getIn(['recommendedExternalJobPosting', 'externalId']);

  const redirectPath = isIndeedJob ? `/candidate/indeed-jobs/${indeedJobId}` : `/candidate/jobs/${job.get('id')}`;

  useEffect(() => {
    setRedirectPath(redirectPath);
  }, [redirectPath, setRedirectPath]);

  if (isCurrentUserSignedIn) {
    if (isIndeedJob) {
      return <Redirect to={redirectPath} />;
    }

    return <Redirect to={redirectPath} />;
  }

  const renderJob = () => {
    if (isIndeedJob) {
      return <IndeedJobDetails indeedJobId={indeedJobId} />;
    }

    return <JobDetails job={job} />;
  };

  return (
    <JobPreviewStyles>
      {job && (
        <MainStyles noMargin>
          <div className="candidate-wrapper">
            <MainCard>
              <MainActions>
                <Logo>
                  <img src={logoUrl} alt="Job Logo" />
                </Logo>
                <PreviewButtons previewType="job" redirectPath={redirectPath} />
              </MainActions>

              {renderJob()}
            </MainCard>
          </div>
        </MainStyles>
      )}
    </JobPreviewStyles>
  );
};

JobPreviewView.propTypes = propTypes;
JobPreviewView.defaultProps = defaultProps;

export default JobPreviewView;
