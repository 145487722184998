import React from 'react';
import { Link } from 'react-router-dom';
import { Map } from 'immutable';
import PropTypes from 'prop-types';

const propTypes = {
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  reportDetails: PropTypes.instanceOf(Map).isRequired,
};

const defaultProps = {};

const getPercentageValue = (statNum, totalNum) => Math.round((statNum / totalNum) * 100);

const EthnicityReportView = ({ reportDetails, pathwayInstance }) => {
  if (!reportDetails.size) {
    return null;
  }
  return (
    <tr key={pathwayInstance.get('id')}>
      <th>
        <Link to={`/staff/pathways/${pathwayInstance.get('id')}`}>{pathwayInstance.get('name')}</Link>
      </th>
      <td>
        {reportDetails.get('black')}{' '}
        {reportDetails.get('black') > 0 && <span>({getPercentageValue(reportDetails.get('black'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('caucasian')}{' '}
        {reportDetails.get('caucasian') > 0 && <span>({getPercentageValue(reportDetails.get('caucasian'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('eastAsian')}{' '}
        {reportDetails.get('eastAsian') > 0 && <span>({getPercentageValue(reportDetails.get('eastAsian'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('latino')}{' '}
        {reportDetails.get('latino') > 0 && <span>({getPercentageValue(reportDetails.get('latino'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('middleEastern')}{' '}
        {reportDetails.get('middleEastern') > 0 && <span>({getPercentageValue(reportDetails.get('middleEastern'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('mixed')}{' '}
        {reportDetails.get('mixed') > 0 && <span>({getPercentageValue(reportDetails.get('mixed'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('nativeAmerican')}{' '}
        {reportDetails.get('nativeAmerican') > 0 && <span>({getPercentageValue(reportDetails.get('nativeAmerican'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('nativeIslander')}{' '}
        {reportDetails.get('nativeIslander') > 0 && <span>({getPercentageValue(reportDetails.get('nativeIslander'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('southAsian')}{' '}
        {reportDetails.get('southAsian') > 0 && <span>({getPercentageValue(reportDetails.get('southAsian'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('other')}{' '}
        {reportDetails.get('other') > 0 && <span>({getPercentageValue(reportDetails.get('other'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>
        {reportDetails.get('noShare')}{' '}
        {reportDetails.get('noShare') > 0 && <span>({getPercentageValue(reportDetails.get('noShare'), reportDetails.get('total'))}%)</span>}
      </td>
      <td>{reportDetails.get('total')}</td>
    </tr>
  );
};
EthnicityReportView.propTypes = propTypes;
EthnicityReportView.defaultProps = defaultProps;

export default EthnicityReportView;
