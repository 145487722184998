import React from 'react';
import { CircularProgress } from '@material-ui/core';
import useAuthToken from 'risekit/common/hooks/useAuthToken';

import { ReportSummaryStyles } from './pathway-view.style';
import { PathwayReportSummaryProps } from './pathway-view.type';
import useGetPathwaySummary from '../hooks/useGetPathwaySummary';
import { PathwayInstanceSummary } from '../inc/pathway-instance-summary';
import { PathwayFilterParam, PathwayResponseObject } from '../pathways.type';

const PathwayReportSummary: React.FC<PathwayReportSummaryProps> = ({ selectedFilterYears, selectedFilterState }) => {
  const token = useAuthToken();

  const params: PathwayFilterParam = {
    pathway_instance_state: selectedFilterState,
    pathway_instance_year: selectedFilterYears,
  };

  const { isLoading, error, summary } = useGetPathwaySummary({ token, params });

  if (isLoading || error) {
    return <CircularProgress />;
  }

  if (!summary?.length) {
    return <div>NOT AVAILABLE, SELECT NEW FILTER</div>;
  }

  const instanceSummary = summary.map((pathway, index) => <PathwaySummary key={index} pathway={pathway} />);

  return <div>{instanceSummary}</div>;
};

interface PathwaySummaryProps {
  pathway: PathwayResponseObject;
}

export default PathwayReportSummary;

export const PathwaySummary: React.FC<PathwaySummaryProps> = ({ pathway }) => {
  const pathwayInstance = pathway.pathwayInstances.map((instance, index) => <PathwayInstanceSummary key={index} instance={instance} />);

  return (
    <ReportSummaryStyles>
      <React.Fragment>
        <h2>{pathway.pathwayName} Pathways Report</h2>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Pathway Instance Status</th>
              <th>Pathway Instance Start Date</th>
              <th>Enrolled</th>
              <th>Active</th>
              <th>Completed</th>
              <th>Overall Retention</th>
              <th>Adjusted Retention</th>
              <th>Total Dropped</th>
              <th># dropped (involuntary)</th>
              <th># dropped (voluntary)</th>
            </tr>
          </thead>
          <tbody>{pathwayInstance}</tbody>
        </table>
        <br />
      </React.Fragment>
    </ReportSummaryStyles>
  );
};
