import styled from 'styled-components';
import theme from '../../../common/theme';
// import theme from '../../../common/theme';

const SettingsStyles = styled.div`
  margin: -50px;
  padding: 50px;
  background-color: #f7f7f7;
  min-height: 100vh;

  @media only screen and (max-width: 768px) {
    margin: -25px;
    padding: 25px;
  }

  .fields-2 {
    margin-bottom: ${theme.padding.small};

    @media ${theme.query.tabletMin} {
      display: flex;
      flex-direction: row;
    }

    > * {
      @media ${theme.query.tabletMin} {
        width: 50%;

        &:first-of-type {
          margin-right: ${theme.padding.xsmall} !important;
        }

        &:last-of-type {
          margin-left: ${theme.padding.xsmall} !important;
        }
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .email-validation {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 5px;
  }

  .email-validation svg {
    margin-right: 5px;
  }

  .email-validation small {
    margin-left: 5px;
  }
`;

export const Card = styled.div`
  background: white;
  padding: 35px;
`;

export default SettingsStyles;
