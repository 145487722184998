import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Iterable, OrderedSet } from 'immutable';
// import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '../../../common/components';
import JobCandidatesStyles from './job_candidates_styles';
// import theme from '../../../common/theme';
import JobCandidatesFilters from './job_candidates_filters';
import { useDebouncedEffect } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';
import CandidateApplicationModel from '../../../solve/models/candidate_application_model';
import { Banner } from '../candidates/candidates_styles';
import Candidate from '../../../jobs/application_list_item';
import InviteApplicantTile from '../invite_applicant_tile';
import Spinner from '../../../common/components/spinner';
import { downloadFileFromBlob } from '../../../util/file_operations';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  jobId: PropTypes.string.isRequired,
  listFilteredCandidatesForJob: PropTypes.func.isRequired,
  filters: PropTypes.instanceOf(Iterable).isRequired,
  candidateApplicationIds: PropTypes.oneOfType([PropTypes.instanceOf(Iterable), PropTypes.instanceOf(CandidateApplicationModel)]).isRequired,
  loaded: PropTypes.bool.isRequired,
  search: PropTypes.string,
  invitations: PropTypes.instanceOf(OrderedSet),
  listInvitations: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
  fetchJobPostingParticipantsReport: PropTypes.func.isRequired,
  setSearch: PropTypes.func.isRequired,
};

const defaultProps = {
  search: '',
  invitations: [],
};

function useSearch(search, setSearch) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const JobCandidatesView = ({
  candidateApplicationIds,
  jobId,
  listFilteredCandidatesForJob,
  currentUser,
  filters,
  search,
  setSearch,
  loaded,
  listInvitations,
  invitations,
  resendInvitation,
  fetchJobPostingParticipantsReport,
}) => {
  useEffect(() => {
    if (!loaded) {
      listFilteredCandidatesForJob(currentUser, jobId, filters.merge({ userName: search }));
    }
  }, [loaded, listFilteredCandidatesForJob, jobId, currentUser, filters, search]);

  useEffect(() => {
    if (!invitations || invitations.size === 0) {
      listInvitations(currentUser);
    }
  }, [invitations, currentUser, listInvitations]);

  const { newSearch, onSearchChange } = useSearch(search, setSearch);

  // const anyFiltersApplied = filters.some((x) => x) || search;
  const getCsv = () =>
    fetchJobPostingParticipantsReport(currentUser, jobId).then((response) => {
      downloadFileFromBlob(response.data, `job_posting_participants_${jobId}.csv`);
    });

  return (
    <JobCandidatesStyles>
      {loaded ? (
        <React.Fragment>
          <Banner>
            Results: {candidateApplicationIds.size} Candidates{invitations.size !== 0 ? `, ${invitations.size} Invitations` : ''}
            <Button buttonType="secondary-outline" onClick={getCsv}>
              Download Candidates Report
            </Button>
          </Banner>

          <TextField
            id="search"
            placeholder="Search for applicants by name"
            margin="dense"
            variant="outlined"
            fullWidth
            style={{ marginBottom: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={newSearch}
            onChange={onSearchChange}
          />
          <JobCandidatesFilters />

          {candidateApplicationIds && candidateApplicationIds.map((id) => <Candidate key={id} candidateApplicationId={id} />)}
          <div>
            {invitations &&
              invitations.size !== 0 &&
              invitations.map((invitation) => (
                <InviteApplicantTile key={invitation.get('id')} invitation={invitation} resendInvitation={resendInvitation} type="job" />
              ))}
          </div>
        </React.Fragment>
      ) : (
        <Spinner />
      )}
    </JobCandidatesStyles>
  );
};

JobCandidatesView.propTypes = propTypes;
JobCandidatesView.defaultProps = defaultProps;

export default JobCandidatesView;
