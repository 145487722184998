import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import PageNavigationButton from '@material-ui/core/Button';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Grid from '@material-ui/core/Grid';
import OrganizationTile from '../../../staff/components/organization_tile';
import OrganizationsSelectorStyles, { ResultsPageNavigator } from './organizations_selector_styles';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import theme from '../../../common/theme';
import { useApiCall } from '../../../util/hooks';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  input: PropTypes.shape({ onChange: PropTypes.func.isRequired }).isRequired,
  mode: PropTypes.string.isRequired,
  orgs: PropTypes.instanceOf(OrderedSet).isRequired,
  totalCount: PropTypes.number.isRequired,
  listOrgs: PropTypes.func.isRequired,
};

const defaultProps = {};

// RegExp.escape = (text) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
// const buildSearchMatcher = (string) => new RegExp(RegExp.escape(string), 'i');

const PageNavigator = ({ totalCount, orgs, pageShown, getPrevPageResults, getNextPageResults, totalPages }) => {
  if (totalCount < 25 || !orgs || orgs.size === 0) {
    return null;
  }

  return (
    <ResultsPageNavigator>
      <div className="prev-next-container">
        <PageNavigationButton
          style={{ color: pageShown <= 0 ? theme.color.grayLight : theme.color.blue }}
          startIcon={<NavigateBeforeIcon />}
          disabled={pageShown <= 0}
          onClick={() => getPrevPageResults()}
        >
          Prev
        </PageNavigationButton>
        <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown + 1} of ${totalPages}`}</Typography>
        <PageNavigationButton
          style={{ color: pageShown >= totalPages - 1 ? theme.color.grayLight : theme.color.blue }}
          endIcon={<NavigateNextIcon />}
          disabled={pageShown >= totalPages - 1}
          onClick={() => getNextPageResults()}
        >
          Next
        </PageNavigationButton>
      </div>
    </ResultsPageNavigator>
  );
};
PageNavigator.propTypes = {
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  orgs: PropTypes.instanceOf(OrderedSet),
  pageShown: PropTypes.number,
  getPrevPageResults: PropTypes.func.isRequired,
  getNextPageResults: PropTypes.func.isRequired,
};
PageNavigator.defaultProps = {
  totalCount: undefined,
  totalPages: undefined,
  orgs: undefined,
  pageShown: undefined,
};

const OrganizationsSelectorView = ({ user, input, mode, orgs, listOrgs, totalCount }) => {
  const [orgSearch, setOrgSearch] = useState('');
  const [pageShown, setPageShown] = useState(0);
  const totalPages = Math.ceil(totalCount / 25) || 1;

  const [fetchOrgs, { submitting, success, failed }] = useApiCall(listOrgs);
  const loading = submitting || !(success || failed);

  const { onChange } = input;

  // Reset to first page when mode or search changes
  useEffect(() => {
    setPageShown(0);
  }, [mode, orgSearch]);

  // Load org list when search, mode, or page changes
  useEffect(() => {
    fetchOrgs(user, { page: pageShown + 1, orgname: orgSearch, type: mode });
  }, [fetchOrgs, user, orgSearch, mode, pageShown]);

  const onSearchChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setOrgSearch(value);
    onChange(value);
  };

  let list;
  if (loading) {
    list = <Spinner />;
  } else {
    const getNextPageResults = () => setPageShown(pageShown + 1);
    const getPrevPageResults = () => setPageShown(pageShown - 1);
    list = (
      <Fragment>
        <PageNavigator {...{ totalCount, orgs, pageShown, getPrevPageResults, getNextPageResults, totalPages }} />
        <Grid container spacing={2} className="organization-list">
          {orgs.map((org) => (
            <Grid item xs={12} sm={12} md={6} lg={4} key={org.get('id')}>
              <OrganizationTile
                org={org}
                onClick={() => {
                  setOrgSearch(org.get('name'));
                  onChange(org.get('name'));
                }}
              />
            </Grid>
          ))}
        </Grid>
        <PageNavigator {...{ totalCount, orgs, pageShown, getPrevPageResults, getNextPageResults, totalPages }} />
      </Fragment>
    );
  }

  return (
    <OrganizationsSelectorStyles>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <TextField
            onChange={onSearchChange}
            value={orgSearch}
            id="search"
            placeholder="Search Organizations"
            variant="outlined"
            // disabled={loading}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: orgSearch && (
                <InputAdornment position="end">
                  <CancelIcon
                    onClick={() => {
                      setOrgSearch('');
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <div style={{ height: 20 }} />
      {list}
      <div style={{ height: 40 }} />
    </OrganizationsSelectorStyles>
  );
};

OrganizationsSelectorView.propTypes = propTypes;
OrganizationsSelectorView.defaultProps = defaultProps;

export default OrganizationsSelectorView;
