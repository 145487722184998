import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Map } from 'immutable';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { reduxForm } from '../../../../common/form';

import { Card } from '../../../../common/styles/layout';
import { Button, TextHeading } from '../../../../common/components';

import ReviewFormStyles from './review_form_styles';
import { formatDate } from '../../../../util/format_datetimes';

const ReviewForm = ({ handleSubmit, onSaveDraft, previousPage, pathway, name, startsOn, description, stepDates, formValues }) => (
  <ReviewFormStyles>
    <form onSubmit={handleSubmit}>
      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <TextHeading size="medium" weight="bold" margin="both" uppercase>
            Review your pathway details
          </TextHeading>
          <Card>
            <div>
              <Typography variant="h6" color="primary" gutterBottom>
                Pathway Overview
              </Typography>
              <Typography variant="body1" color="primary">
                {`Pathway Name: ${name}`}
              </Typography>
              <Typography variant="body1" color="primary">
                {`Pathway Type: ${pathway.get('name')}`}
              </Typography>
              <Typography variant="body1" color="primary">
                Description:
                <span dangerouslySetInnerHTML={{ __html: description }} />
              </Typography>
              <Typography variant="body1" color="primary">
                {`Start Date: ${formatDate(startsOn)}`}
              </Typography>
            </div>
            <div>
              <Typography variant="h6" color="primary" gutterBottom>
                Pathway Step Information
              </Typography>
              <div>
                {pathway.get('pathwayMilestones').map((milestone) => (
                  <div key={milestone.getIn(['pathwayMilestone', 'id'])}>
                    {milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map(
                      (step) =>
                        step.get('pathwayStepCategoryType') === 'pathway' && (
                          <div key={step.get('id')}>
                            <Typography component="span">{`${step.get('name')}:  `}</Typography>
                            <Typography component="span">{stepDates[step.get('id')] && moment(stepDates[step.get('id')]).format('DD MMMM YYYY')}</Typography>
                          </div>
                        ),
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
      <div className="button-group">
        <div className="button-left">
          <Button buttonType="secondary-outline" type="button" onClick={previousPage}>
            Previous
          </Button>
        </div>
        <div className="button-right">
          <Button buttonType="secondary-outline" type="button" onClick={() => onSaveDraft(formValues)}>
            Save as Draft
          </Button>
          <Button buttonType="secondary" type="submit">
            Publish
          </Button>
        </div>
      </div>
    </form>
  </ReviewFormStyles>
);

ReviewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onSaveDraft: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pathway: PropTypes.instanceOf(Map),
  name: PropTypes.string,
  startsOn: PropTypes.string,
  description: PropTypes.string,
  stepDates: PropTypes.objectOf(Date),
  formValues: PropTypes.instanceOf(Map),
};
ReviewForm.defaultProps = {
  pathway: new Map(),
  startsOn: undefined,
  name: undefined,
  description: undefined,
  stepDates: new Date(),
  formValues: new Map(),
};

const ReviewFormView = reduxForm({
  form: 'pathwayForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ReviewForm);

export default ReviewFormView;
