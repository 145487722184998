import BaseNotificationHelper from './base_notification_helper';

class ResumeNotificationHelper extends BaseNotificationHelper {
  constructor(params) {
    super(params);

    this.info = this.notification.get('info');
  }

  getDescription() {
    let description = null;

    if (this.template) {
      const candidateName = this.getResumeCandidate();
      const staffName = this.getUpdatedByStaff();
      const staffOrgName = this.getUpdatedByOrgName();

      description = this.template.description;
      if (description) {
        description = description.replace('<candidateName>', candidateName);
        description = description.replace('<staffName>', staffName);
        description = description.replace('<staffOrgName>', staffOrgName);
      }
    }
    return description || '';
  }

  getUpdatedByStaff() {
    let staffName = null;

    if (this.info) {
      const staff = this.info.getIn(['updatedBy', 'staff']);
      if (staff) {
        staffName = `${staff.get('firstName')} ${staff.get('lastName')}`;
      }
    }
    return staffName || '';
  }

  getUpdatedByOrgName() {
    let orgName = null;

    if (this.info) {
      const staff = this.info.getIn(['updatedBy', 'staff']);
      if (staff) {
        orgName = staff.getIn(['organization', 'name']);
      }
    }
    return orgName || '';
  }

  getResumeCandidate() {
    let candidateName = null;

    if (this.info) {
      const candidate = this.info.get('candidate');
      if (candidate) {
        candidateName = `${candidate.get('firstName')} ${candidate.get('lastName')}`;
      }
    }
    return candidateName || '';
  }
}

export default ResumeNotificationHelper;
