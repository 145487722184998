import styled from 'styled-components';
import theme from '../../../../../common/theme';

const SelectCandidatesFormStyles = styled.div`
  form {
    max-width: 1100px;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .button-top {
    position: absolute;
    top: 40px;
    right: 35px;
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  button {
    outline: none;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
  }

  .form-label {
    color: #6c7a89;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
    padding-bottom: 5px;
    text-transform: uppercase;
  }

  @media ${theme.query.desktopMax} {
    .button-top {
      display: none;
    }
  }
`;

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 24px;
  & th {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: ${theme.color.text.medium};
    text-align: left;
    padding: 0px 10px;
  }
  & td {
    border-top: solid 1px #ccc;
    color: #777;
    font-size: 14px;
    padding: 0px 10px;
  }
`;

export default SelectCandidatesFormStyles;
