import styled from 'styled-components';
// import theme from '../../../../common/theme';

const RecidivismReportsStyles = styled.div`
  table {
    border-collapse: collapse;
  }

  thead th {
    border-collapse: collapse;
    padding: 16px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: #6c7a89;
    text-align: center;
    border-bottom: solid 1px #ccc;
  }

  tbody tr {
    border-bottom: solid 1px #ccc;
  }

  tbody th {
    color: #777;
    font-size: 14px;
  }

  tbody td {
    border-collapse: collapse;
    padding: 16px;
    width: 150px;
    text-align: center;
    color: #777;
    font-size: 14px;
  }

  h2 {
    margin: 50px 0px 10px;
  }
`;

export default RecidivismReportsStyles;
