import { connect } from 'react-redux';
import InviteApplicantTileView from './invite_applicant_tile_view';
import { getCurrentUser, getApiError } from '../../../reducer';
import { clearApiError } from '../../../errors/actions';
import ACTIONS, { transitionCandidateApplicationByStaff, transitionCandidateEventByStaff, transitionCandidateProgramByStaff } from '../../../solve/actions';

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state),
  statusUpdateError: getApiError(state, ACTIONS.TRANSITION_CANDIDATE_PROGRAM_S.failure),
});

const mapDispatchToProps = { transitionCandidateProgramByStaff, clearApiError };

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  transitionCandidateApplicationByStaff,
  transitionCandidateProgramByStaff,
  transitionCandidateEventByStaff,
});

const InviteApplicantTileContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(InviteApplicantTileView);

export default InviteApplicantTileContainer;
