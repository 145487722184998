import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import moment from 'moment';
import { startCase } from 'lodash';
import { Link, Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import theme from '../../../common/theme';
import EventStyles, { MetaLabel, MetaValue } from './event_styles';

import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { Text } from '../../../common/components/text';
import formatPhoneNumber from '../../../util/format_phone_number';
import StatusToggler from '../status_toggler';

const propTypes = {
  eventId: PropTypes.string.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  event: PropTypes.instanceOf(Map),
  user: PropTypes.instanceOf(UserModel).isRequired,
  canEdit: PropTypes.bool,
  createPublicLink: PropTypes.func.isRequired,
  publicLink: PropTypes.instanceOf(Map),
  publishEvent: PropTypes.func.isRequired,
  closeEvent: PropTypes.func.isRequired,
  draftEvent: PropTypes.func.isRequired,
};

const defaultProps = {
  event: undefined,
  publicLink: undefined,
  canEdit: false,
};

const EventView = ({ createPublicLink, publicLink, eventId, user, canEdit, event, fetchEvent, publishEvent, closeEvent, draftEvent }) => {
  const resolved = useFetchOnce(user, eventId, fetchEvent);

  useEffect(() => {
    createPublicLink(user, 'broadcast-event', eventId);
  }, [user, eventId, createPublicLink]);

  if (!event) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  let logoUrl;
  logoUrl = event.get('logoUrl');
  if (!logoUrl) {
    logoUrl = event.getIn(['postingOrganization', 'logoUrl']);
  }
  if (!logoUrl) {
    logoUrl = '/images/solve-events.png';
  }

  return (
    <EventStyles>
      <Link to="/staff/resources">
        <Button color="primary" size="small" startIcon={<KeyboardBackspaceIcon />}>
          Back to resources
        </Button>
      </Link>
      {canEdit && (
        <span>
          {' '}
          |{' '}
          <Link to={`/staff/events/${eventId}/edit`}>
            <Button color="primary" size="small">
              Edit Event
            </Button>
          </Link>
        </span>
      )}
      &nbsp; | &nbsp; Share link: &nbsp;
      {publicLink && publicLink.get('publicUrl')}
      {canEdit && (
        <StatusToggler user={user} listingId={eventId} currentState={event.get('state')} publish={publishEvent} close={closeEvent} save={draftEvent} />
      )}
      <hr />
      <div style={{ maxWidth: 800, margin: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            {event.get('logoUrl') && <img src={event.get('logoUrl')} className="event-image" alt="event Logo" />}
          </Grid>
          <Grid item xs={7} style={{ textAlign: 'right' }}>
            {canEdit && (
              <Link to={`/staff/events/${eventId}/participants`}>
                <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action, marginBottom: 10, width: 200 }}>
                  View Applicants
                </Button>
              </Link>
            )}
            <br />
            <Link to={`/staff/events/${eventId}/invite`}>
              <Button variant="outlined" color="secondary" size="large" style={{ borderColor: theme.color.action, color: theme.color.action, width: 200 }}>
                Invite Applicants
              </Button>
            </Link>
          </Grid>
        </Grid>
        <hr />
        <h2 style={{ margin: 0 }}>{event.get('name')}</h2>
        <em>{startCase(event.get('primaryServiceType'))}</em>
        <div style={{ height: 30 }} />

        <h3>Event Description</h3>
        <Text size="small" className="description" dangerouslySetInnerHTML={{ __html: event.get('description') }} />

        <div style={{ height: 40 }} />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MetaLabel>Hosting Organization</MetaLabel>
            <MetaValue>{event.getIn(['postingOrganization', 'name'])}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Date & Time</MetaLabel>
            <MetaValue>{moment(event.get('eventDate')).format('dddd, MMMM Do YYYY')}</MetaValue>
            {event.get('startTime') && <MetaValue>{`Starts: ${event.get('startTime')}`}</MetaValue>}
            {event.get('endTime') && <MetaValue>{`Ends: ${event.get('endTime')}`}</MetaValue>}
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Location</MetaLabel>
            <MetaValue>{event.getIn(['address', 'address1'])}</MetaValue>
            <MetaValue>{event.getIn(['address', 'address2']) && event.getIn(['address', 'address2'])}</MetaValue>
            <MetaValue>{`${event.getIn(['address', 'city'])}, ${event.getIn(['address', 'state'])} `}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Phone</MetaLabel>
            <MetaValue>{formatPhoneNumber(event.get('phone'))}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            <MetaLabel>Industry</MetaLabel>
            <MetaValue>{startCase(event.get('industryType'))}</MetaValue>
          </Grid>
          <Grid item xs={6}>
            {event && event.get('gainedCertifications').size > 0 && (
              <div className="meta-group">
                <MetaLabel>Certifications</MetaLabel>
                {event.get('gainedCertifications').map((certification) => (
                  <MetaValue key={certification}>{certification}</MetaValue>
                ))}
              </div>
            )}
          </Grid>
        </Grid>

        <div style={{ height: 40 }} />

        {/* NOTE: Will need the api to return description for the hosting organization if we want to include that here */}
        {/* <h3>{`Learn More About ${event.getIn(['postingOrganization', 'name'])}`}</h3>
      <p>
        Description Placeholder
      </p> */}

        <div style={{ height: 40 }} />
      </div>
    </EventStyles>
  );
};

EventView.propTypes = propTypes;
EventView.defaultProps = defaultProps;

export default EventView;
