/* eslint-disable import/no-unresolved */
import moment from 'moment';
import { xor, unionBy } from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { OrderedSet, Map } from 'immutable';
import React, { useState, useEffect, useRef } from 'react';

import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import { makeStyles } from '@material-ui/core/styles';

import { extractCandidateFullName } from 'util/common';
import * as toast from 'risekit/common/components/toast';
import { transitionCandidatePathwaysStaffState } from 'api';
import { useDebounce } from 'risekit/common/hooks/useDebounce';
import { ResultsPageNavigator } from 'staff/components/candidates/candidates_styles';

import theme from '../../../../common/theme';
import UserModel from '../../../../session/models/user_model';
import { TextHeading, Button as ThemedButton, Spinner } from '../../../../common/components';
import { PathwayCandidateTile, Header, Content, Actions, BulkEditPane, SearchTextContainer } from './pathway_candidates_styles';

const candidateStates = [
  { label: 'Applied', value: 's_applied' },
  { label: 'In Progress', value: 's_in_progress' },
  { label: 'Completed', value: 's_complete' },
  { label: 'Dropped Voluntarily', value: 's_dropped_voluntarily' },
  { label: 'Dropped Involuntarily', value: 's_dropped_involuntarily' },
  { label: 'Saved', value: 's_saved' },
];

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useStyles = makeStyles(() => ({
  statusContainer: {
    flexGrow: 1,
  },
  stepsContainer: {
    flexGrow: 3,
  },
  progressBar: {
    height: '12px',
    borderRadius: '10px',
    width: '100%',
    backgroundColor: theme.color.grayLighter,
    '& > div': {
      backgroundColor: theme.color.greenLight,
      borderRadius: '10px',
    },
  },
  search: {
    margin: '0px !important',
    width: '50%',
  },
  statusSelect: {
    margin: '0px !important',
    width: '200px',
  },
  stepsCompletedText: {
    fontWeight: 'bold',
    color: theme.color.greenLight,
    marginBottom: '10px',
  },
}));

const PathwayCandidatesView = ({
  listCandidatePathwaysForPathwayInstanceByPage,
  pathwayCandidates,
  user,
  updateCandidatePathways,
  transitionCandidatePathwayStaffState,
  pathwayInstance,
  pathwayDroppedReasonTypes,
  onUpdate,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [activePage, setActivePage] = useState(1);
  const [totalCandidatesPathways, setTotalCandidatePathways] = useState(0);

  const [selectedCandidatePathwaysIds, setSelectedCandidatePathwaysIds] = useState([]);

  const debouncedSearchText = useDebounce(searchText, 600);
  const prevSearchText = usePrevious(debouncedSearchText);

  const maxCandidatesFetchSize = 25;
  const totalPages = Math.ceil(totalCandidatesPathways / maxCandidatesFetchSize);

  useEffect(() => {
    setIsLoading(true);
    setTotalCandidatePathways(0);
    setSelectedCandidatePathwaysIds([]);
    if (prevSearchText !== debouncedSearchText) {
      setActivePage(1);
    }

    const queryParams = { userName: debouncedSearchText || undefined, page: activePage, size: maxCandidatesFetchSize };

    listCandidatePathwaysForPathwayInstanceByPage(user, pathwayInstance.get('id'), queryParams)
      .then(({ totalCount }) => {
        setTotalCandidatePathways(totalCount || 0);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchText, activePage]);

  const scrollTop = () => window.scrollTo(0, 0);
  const getNextPageResults = () => {
    setActivePage(activePage + 1);
    scrollTop();
  };
  const getPrevPageResults = () => {
    setActivePage(activePage - 1);
    scrollTop();
  };

  const PageNavigator = () => {
    if (isLoading || totalCandidatesPathways < maxCandidatesFetchSize) {
      return null;
    }

    return (
      <ResultsPageNavigator>
        <div className="prev-next-container">
          <Button
            style={{ color: activePage <= 0 ? theme.color.grayLight : theme.color.blue }}
            startIcon={<NavigateBeforeIcon />}
            disabled={activePage <= 1}
            onClick={getPrevPageResults}
          >
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>
            {`Page ${activePage} of ${totalPages} (${totalCandidatesPathways} total candidates)`}
          </Typography>
          <Button
            style={{ color: activePage >= totalPages - 1 ? theme.color.grayLight : theme.color.blue }}
            endIcon={<NavigateNextIcon />}
            disabled={activePage >= totalPages}
            onClick={getNextPageResults}
          >
            Next
          </Button>
        </div>
      </ResultsPageNavigator>
    );
  };

  const toggleSelectedCandidatePathwaysId = (pathwayId) => {
    const newSelectedIds = xor(selectedCandidatePathwaysIds, [pathwayId]);

    setSelectedCandidatePathwaysIds([...newSelectedIds]);
  };

  const clearSelectAllCandidatePathwaysIds = () => setSelectedCandidatePathwaysIds([]);

  const isCandidateSelected = (pathwayId) => selectedCandidatePathwaysIds.includes(pathwayId);

  const toggleSelectAllCandidatePathwaysIds = () => {
    const allCandidateIds = pathwayCandidates.map((pathwayCandidate) => pathwayCandidate.get('id')).toJS();

    const newSelectedCandidateIds = selectedCandidatePathwaysIds.length === 0 ? [...allCandidateIds] : [];

    setSelectedCandidatePathwaysIds(newSelectedCandidateIds);
  };

  const isAllCandidateIdsSelected = () => selectedCandidatePathwaysIds.length === pathwayCandidates.size;

  const transitionAllSelectedCandidatesState = (newState) => {
    transitionCandidatePathwaysStaffState(user, selectedCandidatePathwaysIds, newState)
      .then((response) => {
        const previousCandidatePathways = pathwayCandidates.toJS();
        const updatedCandidatePathways = Object.values(response.entities.candidatePathways);
        const mergedCandidatePathways = unionBy(updatedCandidatePathways, previousCandidatePathways, 'id');

        const newCandidatePathways = mergedCandidatePathways.reduce(
          (acc, candidatePathway) => ({ ...acc, [candidatePathway.id]: { ...candidatePathway } }),
          {},
        );

        const newResult = Object.keys(newCandidatePathways);

        const updatedResponse = {
          ...response,
          entries: {
            ...response.entries,
            candidatePathways: newCandidatePathways,
          },
          result: newResult,
        };

        updateCandidatePathways(updatedResponse);

        clearSelectAllCandidatePathwaysIds();

        toast.success({ title: 'Success', message: 'Candidate pathways has been updated.' });
      })
      .catch((e) => {
        toast.error({ title: 'Error', message: e.response.data.error });
      });
  };

  return (
    <div>
      <div>
        <SearchTextContainer>
          <TextField
            id="search"
            className={classes.search}
            placeholder="Search"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            margin="dense"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <PageNavigator />
        </SearchTextContainer>

        {isLoading || pathwayCandidates.size === 0 ? (
          ''
        ) : (
          <BulkEditPane>
            <div className="checkbox">
              {selectedCandidatePathwaysIds.length > 0 && selectedCandidatePathwaysIds.length !== pathwayCandidates.size ? (
                <Checkbox defaultChecked indeterminate onChange={clearSelectAllCandidatePathwaysIds} inputProps={{ 'aria-label': 'indeterminate checkbox' }} />
              ) : (
                <Checkbox
                  checked={isAllCandidateIdsSelected()}
                  onChange={toggleSelectAllCandidatePathwaysIds}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
              <span className="mr-8x">Bulk edit</span>
            </div>
            {selectedCandidatePathwaysIds.length === 0 ? (
              ''
            ) : (
              <FormControl className={classes.statusSelect}>
                <InputLabel>CANDIDATE STATUS</InputLabel>
                <Select margin="dense" variant="outlined" value="" onChange={(e) => transitionAllSelectedCandidatesState(e.target.value.substring(2))}>
                  {candidateStates.map((candidateState) => (
                    <MenuItem key={candidateState.value} value={candidateState.value}>
                      {candidateState.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </BulkEditPane>
        )}
        {isLoading ? (
          <Spinner />
        ) : (
          pathwayCandidates.map((candidate) => {
            const progressPercentage = (100 * parseInt(candidate.get('completedStepsCount'), 10)) / parseInt(candidate.get('totalStepsCount'), 10);

            return (
              <PathwayCandidateTile key={candidate.get('id')}>
                <Header>
                  <div>
                    <Checkbox
                      checked={isCandidateSelected(candidate.get('id'))}
                      onChange={() => toggleSelectedCandidatePathwaysId(candidate.get('id'))}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Link to={`/staff/pathways/${pathwayInstance.get('id')}/candidates/${candidate.get('id')}`}>
                      <TextHeading size="medium" weight="bold">
                        {extractCandidateFullName(candidate.getIn(['candidate', 'user']), user)}
                      </TextHeading>
                    </Link>
                  </div>
                  <div>
                    <Typography>Actual Completion</Typography>
                    <b>{candidate.get('completedOn') ? moment(candidate.get('completedOn')).format('MMM DD YYYY') : ''}</b>
                  </div>

                  <Actions>
                    <Link to={`/staff/messages/new/${candidate.getIn(['candidate', 'candidateId'])}`}>
                      <ThemedButton buttonType="secondary-outline" className="button">
                        Send Message
                      </ThemedButton>
                    </Link>
                    <Link to={`/staff/pathways/${pathwayInstance.get('id')}/candidates/${candidate.get('id')}`}>
                      <ThemedButton buttonType="secondary-outline" className="button">
                        View
                      </ThemedButton>
                    </Link>
                  </Actions>
                </Header>

                <Content>
                  <div className={classes.statusContainer}>
                    <FormControl className={classes.statusSelect}>
                      <Select
                        margin="dense"
                        variant="outlined"
                        value={candidate.get('staffState')}
                        onChange={(e) => transitionCandidatePathwayStaffState(user, candidate.get('id'), e.target.value.substring(2))}
                      >
                        {candidateStates.map((candidateState) => (
                          <MenuItem key={candidateState.value} value={candidateState.value}>
                            {candidateState.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {(candidate.get('staffState') === 's_dropped_voluntarily' || candidate.get('staffState') === 's_dropped_involuntarily') && (
                      <FormControl className={`${classes.statusSelect}`}>
                        <InputLabel>DROPPED REASON</InputLabel>
                        <Select margin="dense" variant="outlined" onChange={(e) => onUpdate(candidate.get('id'), { pathwayDroppedReasonType: e.target.value })}>
                          {pathwayDroppedReasonTypes.map((droppedReason) => (
                            <MenuItem key={droppedReason[0]} value={droppedReason[0]}>
                              {droppedReason[1]}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </div>
                  <div className={classes.stepsContainer}>
                    <Typography variant="h6" className={classes.stepsCompletedText}>
                      Steps Completed:
                      {` ${candidate.get('completedStepsCount')}/${candidate.get('totalStepsCount')}`}
                    </Typography>
                    <LinearProgress variant="determinate" value={progressPercentage} className={classes.progressBar} color="secondary" />
                  </div>
                </Content>
              </PathwayCandidateTile>
            );
          })
        )}
        <PageNavigator />
      </div>
    </div>
  );
};

PathwayCandidatesView.propTypes = {
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  listCandidatePathwaysForPathwayInstanceByPage: PropTypes.func.isRequired,
  pathwayCandidates: PropTypes.instanceOf(OrderedSet).isRequired,
  transitionCandidatePathwayStaffState: PropTypes.func.isRequired,
  pathwayDroppedReasonTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  updateCandidatePathways: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
};
PathwayCandidatesView.defaultProps = {};

export default PathwayCandidatesView;
