import { connect } from 'react-redux';
import SettingsView from './settings_view';
// import { SomeSelectors } from '../../../reducer';
// import { SomeActions } from '../../actions';
import { fetchCandidate, updateCandidate, confirmEmail, reconfirmEmail } from '../../../solve/actions';
import { getCurrentUser, getCandidate, getSystemEnumList } from '../../../reducer';

// Pull specific state out of the Redux store that you need to render
// this component's view using this function.
// Do not retrieve any state that is not used directly by the view.
// Do not associate full objects when all you need are Ids.
// Let child components pull their own properties.
//
// mapStateToProps(state, { prop })
const mapStateToProps = (state) => {
  const user = getCurrentUser(state);
  const candidateId = user.get('candidateId');
  const candidate = getCandidate(state, candidateId);

  return {
    user,
    candidateId,
    candidate: getCandidate(state, candidateId),
    genderTypes: getSystemEnumList(state, 'GenderType').map((item) => [item.get('slug'), item.get('label')]),
    emailValidated: !(user.emailValidatedAt === '' || typeof user.emailValidatedAt === 'undefined'),
    ethnicityTypes: getSystemEnumList(state, 'EthnicityType').map((item) => [item.get('slug'), item.get('label')]),
    initialValues: {
      birthday: candidate && candidate.get('birthday'),
      military: candidate && candidate.get('military'),
      linkedInUrl: candidate && candidate.get('linkedInUrl'),
      ethnicityType: candidate && candidate.get('ethnicityType'),
      genderType: candidate && candidate.get('genderType'),
      addressAttributes: {
        zip: user && user.get('zip'),
      },
      userAttributes: {
        firstName: user && user.get('firstName'),
        lastName: user && user.get('lastName'),
        phone: user && user.get('phone'),
        email: user && user.get('email'),
      },
    },
  };
};

const mapDispatchToProps = {
  fetchCandidate,
  updateCandidate,
  confirmEmail,
  reconfirmEmail,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  onSubmit: (values) => {
    const cleanAddress = values.get('addressAttributes').merge({
      address1: '',
      city: '',
      state: '',
      isPartialAddress: true,
    });
    const cleanValues = values.set('addressAttributes', cleanAddress);
    dispatchProps.updateCandidate(stateProps.user, stateProps.candidateId, cleanValues);
    dispatchProps.fetchCandidate(stateProps.user, stateProps.user.candidateId);
  },
  fetchCandidate: () => dispatchProps.fetchCandidate(stateProps.user, stateProps.user.candidateId),
  confirmEmail: () => dispatchProps.confirmEmail(stateProps.user),
  reconfirmEmail: () => dispatchProps.reconfirmEmail(stateProps.user),
  ...stateProps,
  ...ownProps,
});

const SettingsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(SettingsView);

export default SettingsContainer;
