import styled from 'styled-components';
import theme from '../../../common/theme';

const EventStyles = styled.div`
  .event-image {
    width: 80px;
  }
`;

export const MetaLabel = styled.div`
  color: ${theme.color.text.medium};
`;

export const MetaValue = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

export default EventStyles;
