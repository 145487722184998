import styled from 'styled-components';
import theme from '../../../../common/theme';

const ReviewFormStyles = styled.div`
  form {
    max-width: 1100px;
    margin: 35px auto 50px;
    padding: 0px 35px;
  }

  .sub-heading {
    font-weight: bold;
    color: #394148;
  }
  p {
    margin: 5px 0px;
  }
  ul {
    padding-left: 30px;
    margin: 0px;
  }
  li {
    font-size: 16px;
    line-height: 24px;
    color: #6c7a89;
  }

  .organization {
    color: #394148;
    font-style: italic;
  }

  .requirements {
    margin-top: 20px;
  }

  .requirements ul {
    margin: 0px 0px 25px
    padding-left: 20px;
  }

  .requirements li {
    font-size: 14px;
    line-height: 24px;
    color: #394148;
  }

  .field {
    margin-top: 10px !important;
  }

  .cancel {
    margin-top: 10px;
  }

  button {
    outline: none;
    margin-right: 5px;

    &.upload-button {
      padding: 5px 10px;
      margin-bottom: 10px;
    }
    &.upload-button ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
    }
    &.inline-edit {
      border: 0;
      padding: 0 0 10px 10px;
      font-size: 18px;
      line-height: 1;
      color: ${theme.color.secondaryAction};
      background-color: transparent;
      cursor: pointer;
    }
    &.inline-edit i {
      font-weight: 400;
    }
  }

  .add-item {
    margin-top: 5px;
    font-style: italic;
    color: #394148;
  }

  .left {
    display: flex;
    align-items: space-between;
    flex-direction: column;
  }

  form .right {
    padding-left: 50px;
  }

  @media only screen and (max-width: 1000px) {
    .left {
      flex-direction: column-reverse;
    }
    form .right {
      padding: 16px;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }
  .button-right button:nth-child(1),
  .button-right button:nth-child(2) {
    margin-right: 20px;
  }
  button {
    outline: none;
  }
  @media ${theme.query.desktopMax} {
    .button-group {
      flex-direction: row;
      align-items: flex-start;
    }
    .button-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .button-right button:nth-child(1),
    .button-right button:nth-child(2) {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
  @media ${theme.query.tabletMax} {
    .button-group {
      flex-direction: column !important;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .button-left {
      width: 100%;
    }
    button,
    .button {
      max-width: 210px;
      width: 100%;
    }
  }

  @media ${theme.query.desktopMax} {
    .button-group {
      flex-direction: row;
      align-items: flex-start;
    }
    .button-right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .button-right button:nth-child(1),
    .button-right button:nth-child(2) {
      margin-bottom: 10px;
      margin-right: 0px;
    }
  }
`;

export const JobDetailsStyles = styled.div`
  h1,
  h4 {
    margin: 0px;
  }
  .logo {
    float: right;
    margin: 10px;
    max-width: 200px;
    max-height: 70px;
    width: auto;
    background: transparent;
  }
  .MuiChip-root {
    background-color: #7dbf9d;
    color: #fff;
  }
`;

export const JobReviewStyles = styled.div`
  .sub-heading {
    font-weight: bold;
    color: #394148;
  }
  p {
    margin: 5px 0px;
  }
  ul {
    padding-left: 30px;
    margin: 0px;
  }
  li {
    font-size: 16px;
    line-height: 24px;
    color: #6c7a89;
  }
`;

export default ReviewFormStyles;
