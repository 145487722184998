import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../../../../common/components/button/index';
import UserModel from '../../../../session/models/user_model';
import JoinSolveAsStaffPreviewStyles, { PreviewCard } from './join_solve_as_staff_preview_styles';
// import theme from '../../../../common/theme';

const propTypes = {
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  publicLinkOrInvitation: PropTypes.instanceOf(Map),
};

const defaultProps = {
  publicLinkOrInvitation: undefined,
};

const JoinSolveAsStaffPreviewView = ({ currentUser, publicLinkOrInvitation }) => {
  const organization = publicLinkOrInvitation && publicLinkOrInvitation.get('owningOrganization');
  if (currentUser.signedIn !== false) {
    return <Redirect to="/" />;
  }
  return (
    <JoinSolveAsStaffPreviewStyles>
      <PreviewCard>
        {organization && (
          <div className="program-header">
            {organization.get('logoUrl') && <img src={organization.get('logoUrl')} alt="Organization Logo" className="logo" />}
            <div className="program-right">
              <h4>You were referred by</h4>
              <h2>
                <em style={{ fontSize: 36 }}>{organization.get('name')}</em>
              </h2>
              <div style={{ height: 30 }} />
              <div className="button-group">
                <Link to="/staff/onboarding/nonprofit">
                  <Button buttonType="primary" size="large">
                    Join as nonprofit
                  </Button>
                </Link>
                <Link to="/staff/onboarding/employer">
                  <Button buttonType="primary" size="large">
                    Join as employer
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        )}
      </PreviewCard>
    </JoinSolveAsStaffPreviewStyles>
  );
};

JoinSolveAsStaffPreviewView.propTypes = propTypes;
JoinSolveAsStaffPreviewView.defaultProps = defaultProps;

export default JoinSolveAsStaffPreviewView;
