import styled from 'styled-components';
// import theme from '../../../common/theme';

const PrivacyAgreementFormStyles = styled.div`
  .answer {
    color: #222b45;
  }
  .note {
    margin-left: 30px;
    font-style: italic;
    color: #c0c0c0;
  }
  button {
    outline: 0;
  }
`;

export default PrivacyAgreementFormStyles;
