import { fromJS, OrderedSet, Record, Map } from 'immutable';
import { combineReducers } from 'redux-immutable';

const EMPTY_MAP = fromJS({});
const EMPTY_ORDERED_SET = new OrderedSet([]);
const DEFAULT_STATE = Record({ byId: EMPTY_MAP, allIds: EMPTY_ORDERED_SET, lastUpdated: false });

const getOne = (state, id) => state.getIn(['byId', id]);
const getList = (state, list = state.get('allIds')) => list.map((id) => getOne(state, id)).filter((x) => x !== undefined);
const getLastUpdated = (state) => state.get('lastUpdated');

// Supports read-only resources.
// `actionTypes` are actions that set the allIds list as `action.response.result`
export default function ReadonlyResourcefulReducer(resourceName, model = Map, { actionTypes } = {}) {
  if (!model) {
    throw Error('Missing model');
  }

  const actionTypeList = fromJS(actionTypes || []);
  const ResourceModel = model;
  const asModels = (resources) => fromJS(resources).map((resource) => new ResourceModel(resource));

  const byId = (state = EMPTY_MAP, action) => {
    if (action.response && action.response.entities && action.response.entities[resourceName]) {
      return state.merge(asModels(action.response.entities[resourceName]));
    }
    if (!(state instanceof Map)) {
      return fromJS(state);
    }
    return state;
  };

  const allIds = (state = EMPTY_ORDERED_SET, action) => {
    if (actionTypeList.includes(action.type)) {
      if (typeof action.response.result === 'string') {
        return state.add(action.response.result);
      }
      return new OrderedSet(action.response.result);
    }
    return new OrderedSet(state);
  };

  const lastUpdated = (state = false, action) => {
    if (actionTypeList.includes(action.type)) {
      return Date.now();
    }
    return state;
  };

  const reducer = combineReducers({ byId, allIds, lastUpdated }, DEFAULT_STATE);
  reducer.selectors = { getOne, getList, getLastUpdated };
  return reducer;
}
