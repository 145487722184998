import React from 'react';
import PropTypes from 'prop-types';
// import { Map } from 'immutable';
// import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { fromJS } from 'immutable';
import MyStatusStyles from './my_status_styles';

const CANDIDATE_STATES = fromJS({
  c_applied: 'Applied',
  c_applied_on_website: 'Applied on website',
  c_interviewing: 'Interviewing',
  c_received_offer: 'Received Offer',
  c_accepted_offer: 'Accepted Offer',
  c_declined_offer: 'Declined Offer',
  c_declined: 'Declined',
});

const MyStatusView = ({ candidateApplication, onChange }) =>
  candidateApplication && (
    <MyStatusStyles>
      <FormControl fullWidth margin="dense" variant="outlined">
        {/* <InputLabel>Distance</InputLabel> */}
        <Select
          // fullWidth
          value={candidateApplication.get('candidateState')}
          name="applicationState"
          variant="outlined"
          onChange={(e) => {
            onChange(e.target.value);
          }}
        >
          <MenuItem>
            <em>My Status</em>
          </MenuItem>
          {/* <MenuItem value="c_saved">Saved</MenuItem> */}
          {CANDIDATE_STATES.map((label, state) => (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItem value={state} key={state}>
              {label}
            </MenuItem>
          )).toList()}
        </Select>
      </FormControl>
    </MyStatusStyles>
  );

MyStatusView.propTypes = {
  onChange: PropTypes.func.isRequired,
  candidateApplication: PropTypes.shape({
    get: PropTypes.func,
  }).isRequired,
};

export default MyStatusView;
