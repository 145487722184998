import PathwayReducer from './pathway_reducer';
import PathwayInstanceReducer from './pathway_instance_reducer';
import CandidatePathwayReducer, * as CandidatePathwaySelectors from './candidate_pathway_reducer';
import PathwayReportFiltersReducer, * as PathwayReportFiltersSelectors from './pathway_report_filters_reducer';
import ParentPathwayReportReducer, * as ParentPathwayReportSelectors from './parent_pathway_report_reducer';
import { combineImmutableReducers } from '../../../util';

const pathwaysReducer = combineImmutableReducers({
  pathways: PathwayReducer,
  pathwayInstances: PathwayInstanceReducer,
  candidatePathways: CandidatePathwayReducer,
  parentPathwaysReport: ParentPathwayReportReducer,
  pathwayReportFilters: PathwayReportFiltersReducer,
});

export default pathwaysReducer;

export const getPathways = (state, list) => PathwayReducer.selectors.getList(state.get('pathways'), list);
export const getPathway = (state, id) => PathwayReducer.selectors.getOne(state.get('pathways'), id);

export const getPathwayInstances = (state, list) =>
  PathwayInstanceReducer.selectors
    .getList(state.get('pathwayInstances'), list)
    .map((instance) => instance.update('pathway', (pathwayId) => getPathway(state, pathwayId)));

export const getPathwayInstance = (state, id) =>
  PathwayInstanceReducer.selectors.getOne(state.get('pathwayInstances'), id)?.update('pathway', (pathwayId) => getPathway(state, pathwayId));

export const getCandidatePathways = (state, list) =>
  CandidatePathwaySelectors.getList(state.get('candidatePathways'), list).map((candidatePathway) =>
    candidatePathway.update('pathwayInstance', (instanceId) => getPathwayInstance(state, instanceId)),
  );
export const getCandidatePathway = (state, id) =>
  CandidatePathwaySelectors.getOne(state.get('candidatePathways'), id)?.update('pathwayInstance', (instanceId) => getPathwayInstance(state, instanceId));

export const getCandidatePathwaysForPathwayInstance = (state, pathwayInstanceId) =>
  CandidatePathwaySelectors.getCandidatePathwaysForPathwayInstance(state.get('candidatePathways'), pathwayInstanceId);

export const getCandidatePathwaysForPathwayInstances = (state, pathwayInstanceIds) =>
  CandidatePathwaySelectors.getCandidatePathwaysForPathwayInstances(state.get('candidatePathways'), pathwayInstanceIds);

export const getCandidatePathwaysFromOrganizationIdAndPathwayId = (state, organizationId, pathwayId) =>
  getCandidatePathways(
    state,
    ParentPathwayReportSelectors.getCandidatePathwayIdsFromOrganizationIdAndPathwayId(state.get('parentPathwaysReport'), organizationId, pathwayId) || [],
  );

export const getPathwayReportFilters = (state) => PathwayReportFiltersSelectors.getPathwayReportFilters(state.get('pathwayReportFilters'));
