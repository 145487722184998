import { connect } from 'react-redux';
import { getCurrentUser, getOnboardingMode, getTreatment } from '../../../reducer';
import RegisterForm from './register_form';
import { register } from '../../actions';

const mapStateToProps = (state) => ({
  signedIn: getCurrentUser(state).signedIn,
  onboardingMode: getOnboardingMode(state),
  isRisekit: !!getTreatment(state, 'isRisekit', true),
});

const mapDispatchToProps = {
  onSubmit: (info) => register(info.merge({ name: 'TBD' }).toJS()),
};

const RegisterContainer = connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

export default RegisterContainer;
