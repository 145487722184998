/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Iterable } from 'immutable';
import moment from 'moment';

import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { TextHeading, Button } from '../../../common/components';

import theme from '../../../common/theme';
import TileStyles, { Header, Content, Statuses, Status, Actions } from './program_applicant_tile_styles';

import InteractiveStepper from '../../../ui/components/interactive_stepper';
import { programStaffStatusEnums } from '../../../enums/staff_status_enums';
import CandidateProgramModel from '../../../solve/models/candidate_program_model';
import StatusUpdateTile from '../../../solve/components/status_update_tile';

const { invited, applied, joined, graduated, droppedOut, declined } = programStaffStatusEnums;

const programSteps = (candidateProgram) => [
  {
    label: (
      <div>
        Invited/Unassigned
        <br />
        <span>{candidateProgram.getIn(['helpingOrganization', 'name'])}</span>
      </div>
    ),
    value: invited,
  },
  {
    label: (
      <div>
        Applied
        <br />
        <span>{candidateProgram.get('appliedAt') && moment(candidateProgram.get('appliedAt')).format('Do MMM YY')}</span>
      </div>
    ),
    value: applied,
  },
  {
    label: (
      <div>
        Joined
        <br />
        <span>{candidateProgram.get('joinedAt') && moment(candidateProgram.get('joinedAt')).format('Do MMM YY')}</span>
      </div>
    ),
    value: joined,
  },
];

const candidateStepMapping = {
  [invited]: 0,
  [applied]: 1,
  [joined]: 2,
};

const ProgramApplicantTileView = ({ candidateProgram, updateStaffStatus, statusUpdateError: { errorMessage }, clearApiError }) => {
  const staffState = candidateProgram.get('staffState');
  const [activeStep, setActiveStep] = useState(candidateStepMapping[staffState]);
  const candidate = candidateProgram.getIn(['candidate', 'user']);
  const candidateApplicationId = candidateProgram.get('id');
  const programId = candidateProgram.getIn(['program', 'programId']);
  const userDropped = staffState === droppedOut;
  const userGraduated = staffState === graduated;
  const userDeclined = staffState === declined;

  return (
    <React.Fragment>
      <StatusUpdateTile record={candidateProgram} setStaffState={(state) => updateStaffStatus(`s_${state}`)} />
      <TileStyles>
        <Header>
          <div>
            {/* <Button className="blank delete">Delete applicant</Button> */}
            <TextHeading size="medium" weight="bold">{`${candidate.get('firstName')} ${candidate.get('lastName')}`}</TextHeading>
          </div>
          <div className="blank">{candidate.get('phone')}</div>
          {/* <Button buttonType="text" className="blank" size="small">
          Application Questions
        </Button> */}
          <Actions>
            <Link to={`/staff/messages/new/${candidateProgram.getIn(['candidate', 'candidateId'])}`}>
              <Button buttonType="secondary-outline" className="button">
                Send Message
              </Button>
            </Link>
            <Link to={`/staff/programs/${programId}/participants/${candidateApplicationId}`}>
              <Button buttonType="secondary-outline" className="button">
                View
              </Button>
            </Link>
          </Actions>
        </Header>
        <Content>
          {userDropped || userGraduated || userDeclined ? (
            <InteractiveStepper
              fullWidth
              steps={programSteps(candidateProgram)}
              activeStep={userDeclined ? -1 : 2}
              setActiveStep={() => {}}
              onChange={() => {}}
              disabled
            />
          ) : (
            <InteractiveStepper
              fullWidth
              steps={programSteps(candidateProgram)}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              onChange={updateStaffStatus}
            />
          )}
          <div>
            <FormControlLabel
              disabled={userDropped || userGraduated}
              control={<Checkbox checked={userDeclined} onChange={() => updateStaffStatus(userDeclined ? joined : declined)} />}
              label="Applicant Declined"
            />
          </div>
          <Statuses>
            <Status onClick={() => (userDeclined ? {} : updateStaffStatus(droppedOut))} disabled={userDeclined}>
              <img src={`/icons/dropped_out${userDropped ? '' : '_disabled'}.svg`} height="40" alt="dropped out" />
              <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userDropped ? theme.color.positive : null }} />
              <span style={{ color: userDropped ? theme.color.redLight : null }}>Dropped Out</span>
            </Status>
            <Status onClick={() => (userDeclined ? {} : updateStaffStatus(graduated))} disabled={userDeclined}>
              <img src={`/icons/graduated${userGraduated ? '' : '_disabled'}.svg`} height="40" alt="graduated" />
              <CheckCircleIcon style={{ marginBottom: 24, marginLeft: -5, color: userGraduated ? theme.color.positive : null }} />
              <span style={{ color: userGraduated ? theme.color.green : null }}>Graduated</span>
            </Status>
          </Statuses>
        </Content>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={Boolean(errorMessage)}
          message={errorMessage}
          onClose={() => clearApiError()}
          autoHideDuration={3000}
        />
      </TileStyles>
    </React.Fragment>
  );
};

ProgramApplicantTileView.propTypes = {
  candidateProgram: PropTypes.oneOfType([PropTypes.instanceOf(CandidateProgramModel), PropTypes.instanceOf(Iterable)]).isRequired,
  updateStaffStatus: PropTypes.func.isRequired,
  clearApiError: PropTypes.func.isRequired,
  statusUpdateError: PropTypes.shape({
    errorMessage: PropTypes.string,
    action: PropTypes.string,
  }).isRequired,
};
ProgramApplicantTileView.defaultProps = {};

export default ProgramApplicantTileView;
