import React from 'react';
import theme from 'common/theme';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { StyledTableRow } from '../views/pathway-view.style';

const PathwayRow = ({ pathwayInstance }: any) => {
  return (
    <StyledTableRow>
      <td>
        <img src="/icons/color/skills.png" height="35" alt="Job Posting Logo" style={{ margin: 12 }} />
      </td>
      <td>
        <h4>{pathwayInstance?.name}</h4>
      </td>
      <td>{pathwayInstance?.pathway?.pathwayType?.toUpperCase() || 'Type'}</td>
      <td>{pathwayInstance?.state?.toUpperCase()}</td>
      <td>
        <Link to={`/staff/pathways/${pathwayInstance?.id}`}>
          <Button variant="contained" color="secondary" size="small" style={{ backgroundColor: theme.color.blue }}>
            View
          </Button>
        </Link>
      </td>
    </StyledTableRow>
  );
};

export default PathwayRow;
