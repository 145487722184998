import { connect } from 'react-redux';
import JobCandidateNotificationsView from './job_candidate_notifications_view';
import { getCurrentUser, getCandidate, getNotifications, getLabelsForNotifications, getMessages } from '../../../reducer';
import { listMessages, listNotifications } from '../../../solve/actions';

const mapStateToProps = (state, { candidateId }) => {
  const currentUser = getCurrentUser(state);
  const candidate = getCandidate(state, candidateId);
  const candidateUserId = candidate.getIn(['user', 'id']);

  const allMessages = getMessages(state).toList();
  const conversationWithCandidate = allMessages.reduce((acc, message) => {
    const messageSenderId = message.get('fromUserId');
    const messageRecipientId = message.get('toUserId');

    const isConversationMessage = [messageSenderId, messageRecipientId].includes(candidateUserId);

    if (!isConversationMessage) {
      return acc;
    }

    return [...acc, message];
  }, []);
  const sortMessagesByCreatedDate = (messages) => messages.sort((msg1, msg2) => new Date(msg2.get('createdAt')) - new Date(msg1.get('createdAt')));

  const notifications = getNotifications(state).toList();
  const candidateNotifications = notifications.reduce((total, amount) => {
    if (
      amount.getIn(['info', 'candidate', 'id']) === candidate.getIn(['user', 'candidateId']) ||
      amount.getIn(['info', 'resource', 'candidate', 'id']) === candidate.getIn(['user', 'candidateId'])
    ) {
      total.push(amount);
    }
    return total;
  }, []);

  return {
    currentUser,
    candidateId,
    messages: sortMessagesByCreatedDate(conversationWithCandidate),
    notifications: candidateNotifications,
    notificationLabels: getLabelsForNotifications(state),
  };
};

const mapDispatchToProps = {
  listMessages,
  listNotifications,
};

const JobCandidateNotificationsContainer = connect(mapStateToProps, mapDispatchToProps)(JobCandidateNotificationsView);

export default JobCandidateNotificationsContainer;
