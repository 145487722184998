import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import { useHistory } from 'react-router-dom';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import * as toast from 'risekit/common/components/toast';

import { Card } from '../../../common/styles/layout';
import Button from '../../../common/components/button/index';
import { reduxForm, Field, MuiCheckbox, MuiRadioGroup } from '../../../common/form';
import NotificationsSettingsStyles, { Title, SectionTitle } from './notifications_settings_styles';

const propTypes = {
  isRisekit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};

const NotificationsSettingsForm = ({ isRisekit, handleSubmit, submitting }) => {
  const history = useHistory();

  const onFormSubmit = async (values) => {
    try {
      await handleSubmit(values);
      toast.success({ title: 'Success', message: 'Notification settings have been updated.' });
      history.push('/');
    } catch (error) {
      toast.error({ title: 'Error', message: 'Error while updating notification setting.' });
    }
  };

  return (
    <NotificationsSettingsStyles>
      <Grid>
        <Title>Notification Settings</Title>
        <form onSubmit={onFormSubmit}>
          <Card className="notification-card">
            <div className="notification-section">
              <h2>You can choose which type of notifications you'd like to receive and how you'd like to receive them below:</h2>
              <table>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th>Receive email</th>
                    <th>Receive text message</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="row-header">Job status change</div>
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.email[jobStatus]" variant="outlined" />}
                      />
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.text[jobStatus]" variant="outlined" />}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row-header">Program status change</div>
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.email[programStatus]" variant="outlined" />}
                      />
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.text[programStatus]" variant="outlined" />}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row-header">Challenges questionnaire changes</div>
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.email[challengesQuestionnaire]" variant="outlined" />}
                      />
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.text[challengesQuestionnaire]" variant="outlined" />}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row-header">{`New message on ${isRisekit ? 'RiseKit' : 'Solve'}`}</div>
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.email[message]" variant="outlined" />}
                      />
                    </td>
                    <td>
                      <FormControlLabel
                        className="answer"
                        control={<Field component={MuiCheckbox} name="notificationSettings.text[message]" variant="outlined" />}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="row-header">Receive job recommendations</div>
                    </td>
                    <td>
                      <FormControlLabel className="answer" control={<Field component={MuiCheckbox} name="sendRecommendationPermitted" variant="outlined" />} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="notification-section">
              <SectionTitle>Notification Summaries</SectionTitle>
              <div className="question">{`Send me an email with a summary of all of my notifications from ${isRisekit ? 'RiseKit' : 'Solve'}`}</div>
              <Field name="notificationFrequency" component={MuiRadioGroup}>
                <FormGroup>
                  <FormControlLabel className="answer" control={<Radio value="" color="primary" />} label="Never" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel className="answer" control={<Radio value="daily" color="primary" />} label="Every day" />
                </FormGroup>
                <FormGroup>
                  <FormControlLabel className="answer" control={<Radio value="weekly" color="primary" />} label="Every week" />
                </FormGroup>
              </Field>
            </div>
          </Card>
          <Button variant="contained" className="action-contained" disabled={submitting} onClick={onFormSubmit} style={{ float: 'right' }}>
            Update Notification Settings
          </Button>
        </form>
      </Grid>
    </NotificationsSettingsStyles>
  );
};

NotificationsSettingsForm.propTypes = propTypes;

const NotificationsSettingsView = reduxForm({
  form: 'notificationsSettingsForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
})(NotificationsSettingsForm);

export default NotificationsSettingsView;
