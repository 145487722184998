import { connect } from 'react-redux';
import JobCandidateResumeView from './job_candidate_resume_view';
import { getCandidateResumesForStaff, getCurrentUser, getStaffMember } from '../../../reducer';
import { listResumes } from '../../../solve/actions';

const mapStateToProps = (state, { candidateId }) => {
  const currentUser = getCurrentUser(state);
  const staffId = currentUser.get('staffId');
  const currentStaff = getStaffMember(state, staffId);
  const canEdit = currentUser?.permissions?.includes('edit_resume') || currentStaff?.get('permissions')?.includes('edit_resume');
  const resumes = getCandidateResumesForStaff(state, candidateId);

  return {
    canEdit,
    currentUser,
    resumes,
  };
};

const mapDispatchToProps = {
  listResumes,
};

const JobCandidateResumeContainer = connect(mapStateToProps, mapDispatchToProps)(JobCandidateResumeView);

export default JobCandidateResumeContainer;
