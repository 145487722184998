import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TextStyles from './text_styles';
import theme from '../../theme';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const defaultProps = {};

const ExpandableTextStyles = styled(TextStyles)`
  display: block;
  display: -webkit-box;
  margin: 0 auto;
  max-width: 100%;
  line-height: 1.45em;

  ${({ expand }) => {
    switch (expand) {
      case true:
        return '';
      default:
        return `
          max-height: 2.9em; // 2x line height
          overflow: hidden;
          -webkit-line-clamp: 2; // works in all major browsers except for IE until chromium release
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
        `;
    }
  }}
`;

const ExpandableToggleWrapper = styled.div`
  width: 100%;
  text-align: left;
`;

const ExpandableText = ({ children }) => {
  const [expand, toggleExpand] = useState(false);
  const [truncated, setTruncated] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const textRefHeight = textRef.current.scrollHeight;
    const textRefBoxHeight = textRef.current.getBoundingClientRect().height;

    if (textRefHeight > textRefBoxHeight) {
      setTruncated(true);
    }
  }, []);

  const handleExpandClick = (event) => {
    toggleExpand(!expand);
    event.preventDefault();
  };

  return (
    <TextStyles>
      <ExpandableTextStyles ref={textRef} expand={expand} color={theme.color.text.medium} size="small">
        {children}
      </ExpandableTextStyles>

      {truncated ? (
        <ExpandableToggleWrapper onClick={handleExpandClick} onKeyDown={handleExpandClick}>
          <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', color: theme.color.secondaryAction }}>
            <TextStyles color={theme.color.secondaryAction} size="small" inline style={{ fontWeight: 600 }}>
              {expand ? 'Hide' : 'Show more'}
            </TextStyles>
            {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </span>
        </ExpandableToggleWrapper>
      ) : (
        ''
      )}
    </TextStyles>
  );
};

ExpandableText.propTypes = propTypes;
ExpandableText.defaultProps = defaultProps;

export default ExpandableText;
