import React from 'react';
import PropTypes from 'prop-types';
import { Map, OrderedSet } from 'immutable';
import InputLabel from '@material-ui/core/InputLabel';
// import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';

// const FilterSelect = ({ filters, onChange, name, children, setServiceSelected }) => (
//   <Select
//     value={filters.get(name) || ''}
//     fullWidth
//     onChange={(e) => {
//       setServiceSelected(e.target.value !== '');
//       onChange(filters.set(name, e.target.value));
//     }}
//   >
//     {children}
//   </Select>
// );
// FilterSelect.propTypes = {
//   filters: PropTypes.instanceOf(Map).isRequired,
//   onChange: PropTypes.func.isRequired,
//   setServiceSelected: PropTypes.func.isRequired,
//   name: PropTypes.string.isRequired,
//   children: PropTypes.node.isRequired,
// };

const ResourcesListFilters = ({ filters, serviceTypeChoices, onChange, setServiceSelected }) => (
  <Grid container spacing={2} style={{ marginBottom: 25 }}>
    <Grid item xs={12}>
      <FormControl fullWidth margin="dense" variant="outlined">
        <InputLabel>Service Type</InputLabel>
        <RadioGroup row aria-label="serviceType" name="serviceType" value={filters.get('serviceType')}>
          {serviceTypeChoices &&
            serviceTypeChoices.map(([slug, label]) => (
              <Button
                className={`${filters.get('serviceType') === slug ? 'active' : ''} filter-button-item`}
                key={slug}
                onClick={(e) => {
                  e.preventDefault();
                  setServiceSelected(true);
                  onChange(filters.set('serviceType', slug));
                }}
                value={slug}
              >
                {label}
              </Button>
            ))}
        </RadioGroup>
      </FormControl>
    </Grid>

    {/* <Grid item xs={3}> */}
    {/*  <FormControl fullWidth margin="dense" variant="outlined"> */}
    {/*    <InputLabel>Serving</InputLabel> */}
    {/*    <FilterSelect filters={filters} onChange={onChange} name="targetParticipantType" variant="outlined"> */}
    {/*      <MenuItem value=""> */}
    {/*        <em>None</em> */}
    {/*      </MenuItem> */}
    {/*      {targetTypeChoices && */}
    {/*        targetTypeChoices.map(([slug, label]) => ( */}
    {/*          <MenuItem key={slug} value={slug}> */}
    {/*            {label} */}
    {/*          </MenuItem> */}
    {/*        ))} */}
    {/*    </FilterSelect> */}
    {/*  </FormControl> */}
    {/* </Grid> */}
  </Grid>
);
ResourcesListFilters.propTypes = {
  filters: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  setServiceSelected: PropTypes.func.isRequired,
  serviceTypeChoices: PropTypes.instanceOf(OrderedSet).isRequired,
  // targetTypeChoices: PropTypes.instanceOf(OrderedSet).isRequired,
};
ResourcesListFilters.defaultProps = {};

export default ResourcesListFilters;
