import React from 'react';
import PropTypes from 'prop-types';
import { OrderedSet } from 'immutable';
import ReportDemographicsStyles from './report_demographics_styles';
import GenderReport from './gender_report';
import EthnicityReport from './ethnicity_report';
import AgeReport from './age_report';
import TotalsSummary from './totals_summary';
import { generateGenderReportDetails, generateEthnicityReportDetails, generateAgeReportDetails } from '../../../../util/generate_pathway_reports';

const propTypes = {
  pathways: PropTypes.instanceOf(OrderedSet).isRequired,
  pathwayInstances: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {};

const GENDER_REPORT_COLUMNS = new OrderedSet([
  ['male', 'Male'],
  ['female', 'Female'],
  ['nonBinary', 'Non-binary'],
  ['other', 'Other'],
  ['noShare', 'Prefer not to Share'],
  ['total', 'Total'],
]);

const ETHNICITY_REPORT_COLUMNS = new OrderedSet([
  ['black', 'Black or African American'],
  ['caucasian', 'White or Caucasian'],
  ['eastAsian', 'East Asian'],
  ['latino', 'Latin American'],
  ['middleEastern', 'Middle Eastern'],
  ['mixed', 'Mixed Ethnicity'],
  ['nativeAmerican', 'American Indian or Alaskan Native'],
  ['nativeIslander', 'Native Hawaiian or Pacific Islander'],
  ['southAsian', 'South Asian or Indian'],
  ['other', 'Other'],
  ['noShare', 'Not Specified'],
  ['total', 'Total'],
]);

const AGE_REPORT_COLUMNS = new OrderedSet([
  ['range18To24', '18-24'],
  ['range25To34', '25-34'],
  ['range35To44', '35-44'],
  ['range45To54', '45-54'],
  ['range55To64', '55-64'],
  ['over65', '65+'],
  ['total', 'Total'],
]);

const ReportDemographicsView = ({ pathways, pathwayInstances }) => (
  <ReportDemographicsStyles>
    {pathways.map((pathway) => (
      <React.Fragment key={pathway.get('id')}>
        <h2>{pathway.get('name')} Demographics Report</h2>
        <div className="gender">
          <h4>Gender</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                {GENDER_REPORT_COLUMNS.map(([slug, label]) => (
                  <th key={slug}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TotalsSummary
                key="summary"
                reportColumns={GENDER_REPORT_COLUMNS}
                pathwayInstances={pathwayInstances.filter((pathwayInstance) => pathwayInstance.getIn(['pathway', 'id']) === pathway.get('id'))}
                generateReport={generateGenderReportDetails}
              />
              {pathwayInstances
                .filter((pathwayInstance) => pathwayInstance.getIn(['pathway', 'id']) === pathway.get('id'))
                .map((pathwayInstance) => (
                  <GenderReport key={pathwayInstance.get('id')} pathwayInstance={pathwayInstance} />
                ))}
            </tbody>
          </table>
        </div>
        <div className="ethnicity">
          <h4>Ethnicity Origin</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                {ETHNICITY_REPORT_COLUMNS.map(([slug, label]) => (
                  <th key={slug}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TotalsSummary
                key="summary"
                reportColumns={ETHNICITY_REPORT_COLUMNS}
                pathwayInstances={pathwayInstances.filter((pathwayInstance) => pathwayInstance.getIn(['pathway', 'id']) === pathway.get('id'))}
                generateReport={generateEthnicityReportDetails}
              />
              {pathwayInstances
                .filter((pathwayInstance) => pathwayInstance.getIn(['pathway', 'id']) === pathway.get('id'))
                .map((pathwayInstance) => (
                  <EthnicityReport key={pathwayInstance.get('id')} pathwayInstance={pathwayInstance} />
                ))}
            </tbody>
          </table>
        </div>

        <div className="age">
          <h4>Age</h4>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                {AGE_REPORT_COLUMNS.map(([slug, label]) => (
                  <th key={slug}>{label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <TotalsSummary
                key="summary"
                reportColumns={AGE_REPORT_COLUMNS}
                pathwayInstances={pathwayInstances.filter((pathwayInstance) => pathwayInstance.getIn(['pathway', 'id']) === pathway.get('id'))}
                generateReport={generateAgeReportDetails}
              />
              {pathwayInstances
                .filter((pathwayInstance) => pathwayInstance.getIn(['pathway', 'id']) === pathway.get('id'))
                .map((pathwayInstance) => (
                  <AgeReport key={pathwayInstance.get('id')} pathwayInstance={pathwayInstance} />
                ))}
            </tbody>
          </table>
        </div>
        <br />
      </React.Fragment>
    ))}
  </ReportDemographicsStyles>
);
ReportDemographicsView.propTypes = propTypes;
ReportDemographicsView.defaultProps = defaultProps;

export default ReportDemographicsView;
