import { client, tokenAuth, jsonToFormData, multipartConfig } from './config';

import { OAUTH, signIn, finishSignIn, register, resetPassword, confirmEmail, reconfirmEmail, forgotPassword, refreshAuth, updateCurrentUser } from './user';
import { createPublicLink, createExternalPublicLink, fetchInviteLinkForPreview, fetchPublicLinkForPreview, usePublicLink, useInviteLink } from './public_link';
import { listABSupportiveServices, listABSupportiveService, createExternalCandidateService, candidateApplyToExternalService } from './ab_api';

export { listABSupportiveServices, listABSupportiveService, createExternalCandidateService, candidateApplyToExternalService };
export { OAUTH, signIn, finishSignIn, register, resetPassword, confirmEmail, reconfirmEmail, forgotPassword, refreshAuth, updateCurrentUser };
export { createPublicLink, createExternalPublicLink, fetchInviteLinkForPreview, fetchPublicLinkForPreview, usePublicLink, useInviteLink };

const axios = client();

const toUnderscore = (str) =>
  str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

// Candidate
export const createCandidate = (user, candidate) => axios.post('/v1/candidates', { candidate }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const updateCandidate = (user, candidateId, candidate) =>
  axios.patch(`/v1/candidates/${candidateId}`, jsonToFormData({ candidate: candidate.toJS() }), multipartConfig(user));

export const fetchCandidate = (user, candidateId) => axios.get(`/v1/candidates/${candidateId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listCandidates = (user, organizationId, { page = 1, size = 25 } = {}) =>
  axios.get(`/v1/organizations/${organizationId}/candidates?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// Candidate Organization
export const listCandidateOrganizations = (user, candidateId, { page = 1, size = 25 } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/candidates_organizations`;
  return axios.get(`${baseUrl}?page=${page}&size=${size}&include_staff=true`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

// Candidate Application

export const createCandidateApplication = (user, jobPostingId) =>
  axios.post(
    `/v1/candidates/${user.get('candidateId')}/candidate_applications`,
    { candidateApplication: { jobPostingId } },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const transitionExternalCandidateApplicationByCandidate = (user, candidateApplicationId, state) =>
  axios.post(
    `/v1/external_candidate_applications/${candidateApplicationId}/transition_candidate`,
    { state },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

// external Application
// external_job: { external_job_source_type: 'indeed', external_id: , title: }

// eslint-disable-next-line no-warning-comments
// FIXME: If we are using another job portal rather than 'indeed'

export const createExternalCandidateApplication = (user, jobPostingId, jobTitle) =>
  axios.post(
    `/v1/candidates/${user.get('candidateId')}/external_candidate_applications`,
    { external_job: { external_job_source_type: 'indeed', external_id: jobPostingId, title: jobTitle } },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const fetchCandidateApplication = (user, candidateApplicationId) =>
  axios.get(`/v1/candidate_applications/${candidateApplicationId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const transitionCandidateApplicationByCandidate = (user, candidateApplicationId, state) =>
  axios.post(`/v1/candidate_applications/${candidateApplicationId}/transition_candidate`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const transitionCandidateApplicationByStaff = (user, candidateApplicationId, state) =>
  axios.post(`/v1/candidate_applications/${candidateApplicationId}/transition_staff`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

const CANDIDATE_APPLICATIONS_POSSIBLE_FILTERS = ['userName', 'jobPostingId', 'candidateId'];

const buildCandidateApplicationsFilters = (args) => {
  const filters = [''];
  CANDIDATE_APPLICATIONS_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

export const listCandidateApplications = (user, { page = 1, size = 25, postingOrgId, ...rest } = {}) => {
  const staffRole = user.getIn(['staffOrganization', 'orgType']) === 'nonprofit';
  const baseUrl = staffRole ? `/v1/organizations/${postingOrgId}/candidate_applications` : `/v1/employers/${postingOrgId}/candidate_applications`;
  const filters = buildCandidateApplicationsFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listExternalCandidateApplicationsForCandidate = (user, { page = 1, size = 25, candidateId, ...rest } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/external_candidate_applications`;
  const filters = buildCandidateApplicationsFilters(rest);

  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listCandidateApplicationsForCandidate = (user, { page = 1, size = 25, candidateId, ...rest } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/candidate_applications`;
  const filters = buildCandidateApplicationsFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

// Indeed Candidate Applications

export const listIndeedCandidateApplicationsForCandidate = (user, { candidateId, page = 1, size = 25, ...rest } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/external_candidate_applications`;
  const filters = buildCandidateApplicationsFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchIndeedCandidateApplication = (user, candidateApplicationId) =>
  axios.get(`/v1/external_candidate_applications/${candidateApplicationId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// Candidate Event

const EP_CANDIDATES_POSSIBLE_FILTERS = [
  'userName',
  'eventId',
  'programId',
  'eventname',
  'progname',
  'candidateId',
  'helperName',
  'serviceType',
  'targetParticipantType',
  'state',
];
const EP_CANDIDATES_DISTANCE_FILTERS = ['zipCode', 'distance'];
const buildEPCandidatesFilters = (args) => {
  const filters = [''];
  EP_CANDIDATES_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (args.zipCode) {
    EP_CANDIDATES_DISTANCE_FILTERS.forEach((filter) => {
      if (args[filter]) {
        filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
      }
    });
  }
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

export const fetchEventParticipantsReport = (user, eventId) =>
  axios.post(
    `v1/events/${eventId}/event_participants_report`,
    { orgType: user.staffOrganization?.orgType },
    { headers: { Authorization: tokenAuth(user.authToken) }, responseType: 'blob' },
  );

export const listCandidateEvents = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
  const baseUrl = `/v1/organizations/${organizationId}/candidate_events`;
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listCandidateEventsForCandidate = (user, { page = 1, size = 25, candidateId, ...rest } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/candidate_events`;
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listCandidateProgramsForCandidate = (user, { page = 1, size = 25, candidateId, ...rest } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/candidate_programs`;
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listCandidateServicesForCandidate = (user, { page = 1, size = 25, candidateId, ...rest } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/candidate_helpers`;
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};
export const fetchExternalCandidateServicesServices = (user) => {
  const baseUrl = `/ab/${user.get('candidateId')}/services`;
  return axios.get(`${baseUrl}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchExternalCandidateServices = (user) => {
  const baseUrl = `/v1/candidates/${user.get('candidateId')}/external_candidate_services`;
  return axios.get(`${baseUrl}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listCandidatesForEvent = (user, eventId) =>
  axios.get(`/v1/events/${eventId}/candidate_events`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchCandidateEvent = (user, candidateEventId) =>
  axios.get(`/v1/candidate_events/${candidateEventId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createCandidateEvent = (user, eventId) =>
  axios.post(
    `/v1/candidates/${user.get('candidateId')}/candidate_events`,
    { candidateEvent: { eventId } },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const transitionCandidateEventByCandidate = (user, candidateEventId, state) =>
  axios.post(`/v1/candidate_events/${candidateEventId}/transition_candidate`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });
export const transitionCandidateEventByStaff = (user, candidateEventId, state) =>
  axios.post(`/v1/candidate_events/${candidateEventId}/transition_staff`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

// Candidate Program
export const listCandidatePrograms = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
  const baseUrl = `/v1/organizations/${organizationId}/candidate_programs`;
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listCandidatesForProgram = (user, programId) =>
  axios.get(`/v1/programs/${programId}/candidate_programs`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchCandidateProgram = (user, candidateProgramId) =>
  axios.get(`/v1/candidate_programs/${candidateProgramId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createCandidateProgram = (user, programId) =>
  axios.post(
    `/v1/candidates/${user.get('candidateId')}/candidate_programs`,
    { candidateProgram: { programId } },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const fetchProgramParticipantsReport = (user, programId) =>
  axios.post(
    `v1/programs/${programId}/program_participants_report`,
    { orgType: user.staffOrganization?.orgType },
    { headers: { Authorization: tokenAuth(user.authToken) }, responseType: 'blob' },
  );

export const transitionCandidateProgramByStaff = (user, candidateProgramId, state) =>
  axios.post(`/v1/candidate_programs/${candidateProgramId}/transition_staff`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const transitionCandidateProgramByCandidate = (user, candidateProgramId, state) =>
  axios.post(`/v1/candidate_programs/${candidateProgramId}/transition_candidate`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const transitionExternalCandidateServiceByCandidate = (user, externalCandidateServiceId, state) =>
  axios.post(
    `/v1/external_candidate_services/${externalCandidateServiceId}/transition_candidate`,
    { state },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const transitionCandidateServiceByStaff = (user, candidateServiceId, state) =>
  axios.post(`/v1/candidate_helpers/${candidateServiceId}/transition_staff`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const transitionCandidateServiceByCandidate = (user, candidateServiceId, state) =>
  axios.post(`/v1/candidate_helpers/${candidateServiceId}/transition_candidate`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });
// export const transitionExternalCandidateServiceByCandidate = (user, candidateServiceId, state) =>
//   axios.post(`/v1/candidate_helpers/${candidateServiceId}/transition_candidate`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

// Candidate Surveys
// export const listCandidateSurveys = (user, candidateId) =>
//   axios.get(`/v1/candidates/${candidateId}/candidate_surveys?include_answers=true`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listCandidateSurveys = (user, candidateId, { page = 1, size = 25 } = {}) => {
  const baseUrl = `/v1/candidates/${candidateId}/candidate_surveys`;
  return axios.get(`${baseUrl}?page=${page}&size=${size}&include_answers=true`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const startSurvey = (user, id) =>
  axios.get(`/v1/candidates/${user.get('candidateId')}/start_survey/${id}`, { headers: { Authorization: tokenAuth(user.authToken) } });
export const updateCandidateSurvey = (user, id, props) =>
  axios.patch(`/v1/candidate_surveys/${id}`, props, { headers: { Authorization: tokenAuth(user.authToken) } });

const STAFF_CANDIDATES_POSSIBLE_FILTERS = ['userFullName', 'ethnicity', 'zipCode', 'skillType', '_sort', '_order'];

const buildStaffCandidatesFilters = (args) => {
  const filters = [''];
  STAFF_CANDIDATES_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

const STAFF_LISTING_CANDIDATES_POSSIBLE_FILTERS = ['userName', 'ethnicity', 'zipCode', 'skillType', 'staffState'];

const buildStaffListingCandidatesFilters = (args) => {
  const filters = [''];
  STAFF_LISTING_CANDIDATES_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

// Staff Candidates
export const listStaffCandidates = (user, { page = 1, size = 25, organizationId, ...query } = {}) => {
  const baseUrl = `/v1/organizations/${organizationId}/candidates`;
  const filters = buildStaffCandidatesFilters(query);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listStaffCandidatesForProgram = (user, { page = 1, size = 25, programId, ...query } = {}) => {
  const baseUrl = `/v1/programs/${programId}/candidate_programs`;
  const filters = buildStaffListingCandidatesFilters(query);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listStaffCandidatesForEvent = (user, { page = 1, size = 25, eventId, ...query } = {}) => {
  const baseUrl = `/v1/events/${eventId}/candidate_events`;
  const filters = buildStaffListingCandidatesFilters(query);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listStaffCandidatesForJob = (user, { page = 1, size = 25, jobId, ...query } = {}) => {
  const baseUrl = `/v1/job_postings/${jobId}/candidate_applications`;
  const filters = buildStaffListingCandidatesFilters(query);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

const JOB_POSTING_POSSIBLE_FILTERS = [
  'title',
  'industry',
  'salary',
  'shift',
  'state',
  'type',
  'organizationId',
  'employerId',
  'employerName',
  'contactId',
  'distance',
  'zipCode',
  '_sort',
  '_order',
];

const buildJobPostingFilters = (args) => {
  const filters = [''];
  JOB_POSTING_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

// Job Postings
export const listJobPostings = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
  const baseUrl = organizationId ? `/v1/organizations/${organizationId}/job_postings` : '/v1/job_postings';
  const filters = buildJobPostingFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};
export const fetchJobPosting = (user, id) => axios.get(`/v1/job_postings/${id}`, { headers: { Authorization: tokenAuth(user.authToken) } });

/**
 * TODO: Remove promise.all() and use appropriate query params to fetch postings after API is available.
 */
export const fetchJobPostingsByIds = async (user, ids) => {
  const jobPostingsToFetch = ids.map((id) => axios.get(`/v1/job_postings/${id}`, { headers: { Authorization: tokenAuth(user.authToken) } }));
  const jobPostingsResponses = await Promise.all(jobPostingsToFetch);

  return {
    data: jobPostingsResponses.map(({ data }) => data),
  };
};
export const createJobPosting = (user, organizationId, jobPosting) =>
  axios.post(`/v1/organizations/${organizationId}/job_postings`, jsonToFormData({ jobPosting: jobPosting.toJS() }), multipartConfig(user));

export const updateJobPosting = (user, jobPostingId, jobPosting) =>
  axios.patch(`/v1/job_postings/${jobPostingId}`, jsonToFormData({ jobPosting: jobPosting.toJS() }), multipartConfig(user));

export const draftJobPosting = (user, jobPostingId) =>
  axios.post(`/v1/job_postings/${jobPostingId}/pre_publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const publishJobPosting = (user, jobPostingId) =>
  axios.post(`/v1/job_postings/${jobPostingId}/publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const closeJobPosting = (user, jobPostingId) =>
  axios.post(`/v1/job_postings/${jobPostingId}/close`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const cancelJobPosting = (user, jobPostingId) =>
  axios.post(`/v1/job_postings/${jobPostingId}/cancel`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const activateJobPosting = (user, jobPostingId) =>
  axios.post(`/v1/job_postings/${jobPostingId}/activate`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listApplicationsToJobPosting = (user, jobPostingId) =>
  axios.get(`/v1/job_postings/${jobPostingId}/candidate_applications`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchJobPostingParticipantsReport = (user, jobPostingId) =>
  axios.post(
    `v1/job_postings/${jobPostingId}/job_posting_participants_report`,
    { orgType: user.staffOrganization?.orgType },
    { headers: { Authorization: tokenAuth(user.authToken) }, responseType: 'blob' },
  );

// Indeed Job Postings
export const listIndeedJobPostings = (user, { page = 1, size = 25, ...query } = {}) => {
  const baseUrl = '/indeed/index';
  const filters = buildJobPostingFilters(query);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchIndeedJobPosting = (user, id) => axios.get(`/indeed/show/${id}`, { headers: { Authorization: tokenAuth(user.authToken) } });
export const fetchIndeedJobPostingByIds = (user, ids) => axios.get(`/indeed/show/${ids.join(',')}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// Organizations
export const listOrganizations = (user, { page = 1, size = 25 }) =>
  axios.get(`/v1/organizations?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

const ORGANIZATION_LISTING_POSSIBLE_FILTERS = ['orgname', 'type'];
const buildOrganizationListingFilters = (args) => {
  const filters = [''];
  ORGANIZATION_LISTING_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

export const listFilteredOrganizations = (user, { page = 1, size = 25, ...query }) => {
  const filters = buildOrganizationListingFilters(query);
  return axios.get(`/v1/organizations?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listOrganizationNames = (user, query) => {
  const filters = buildOrganizationListingFilters(query);
  return axios.get(`/v1/organizations/search?${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const listChildOrganizations = (user, parentOrganizationId, { page = 1, size = 25 }) =>
  axios.get(`/v1/organizations?parent_organization_id=${parentOrganizationId}&page=${page}&size=${size}`, {
    headers: { Authorization: tokenAuth(user.authToken) },
  });

export const fetchOrganization = (user, organizationId) =>
  axios.get(`/v1/organizations/${organizationId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createOrganization = (user, org) => axios.post('/v1/organizations', jsonToFormData({ organization: org.toJS() }), multipartConfig(user));

export const updateOrganization = (user, organizationId, org) =>
  axios.patch(`/v1/organizations/${organizationId}`, jsonToFormData({ organization: org.toJS() }), multipartConfig(user));

export const inviteRolesToOrganization = (user, orgId, args) => {
  console.log([user, orgId, args], 'Inviting co-workers');
  return axios.post(`/v1/organizations/${orgId}/staffs/invites`, args, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchOrganizationCandidatesReport = (user, organizationId) =>
  axios.post(
    `/v1/organizations/${organizationId}/candidates_report`,
    { organization_id: organizationId },
    { headers: { Authorization: tokenAuth(user.authToken) }, responseType: 'blob' },
  );

export const fetchOrganizationNewCandidatesReport = (user, organizationId) =>
  axios.post(
    `/v1/organizations/${organizationId}/new_candidates_report`,
    { organization_id: organizationId },
    { headers: { Authorization: tokenAuth(user.authToken) }, responseType: 'blob' },
  );

// General Reports
export const fetchOrganizationCareerFairReport = (user, organizationId) =>
  axios.post(
    `/v1/organizations/${organizationId}/career_fair_report`,
    {},
    {
      headers: { Authorization: tokenAuth(user.authToken) },
      responseType: 'blob',
    },
  );

export const fetchRecidivismReport = (user, organizationId) =>
  axios.post(`/v1/organizations/${organizationId}/recidivism_report`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

// Resumes
export const listResumes = (user, candidateId) =>
  axios.get(`/v1/candidates/${candidateId}/resumes?include_both_versions=true`, { headers: { Authorization: tokenAuth(user.authToken) } });
export const fetchResume = (user, id) => axios.get(`/v1/resumes/${id}`, { headers: { Authorization: tokenAuth(user.authToken) } });
export const createResume = (user, candidateId, resume) =>
  axios.post(`/v1/candidates/${candidateId}/resumes`, { resume }, { headers: { Authorization: tokenAuth(user.authToken) } });
export const updateResume = (user, id, resume) => axios.patch(`/v1/resumes/${id}`, { resume }, { headers: { Authorization: tokenAuth(user.authToken) } });
export const approveResumeChanges = (user, id) => axios.post(`/v1/resumes/${id}/approve`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });
export const declineResumeChanges = (user, id) => axios.post(`/v1/resumes/${id}/decline`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const uploadResume = (user, candidateId, file) =>
  axios.post(`/v1/candidates/${candidateId}/upload_resume`, { file }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchInmate = (user, candidateId) => axios.get(`/v1/candidates/${candidateId}/inmate`, { headers: { Authorization: tokenAuth(user.authToken) } });
export const createInmate = (user, candidateId, inmate) =>
  axios.post(`/v1/candidates/${candidateId}/inmate`, { inmate }, { headers: { Authorization: tokenAuth(user.authToken) } });
export const updateInmate = (user, candidateId, inmate) =>
  axios.patch(`/v1/candidates/${candidateId}/inmate`, { inmate }, { headers: { Authorization: tokenAuth(user.authToken) } });

// Challenges
export const fetchChallenge = (user, challengeId) =>
  axios.get(`/v1/candidate_challenges/${challengeId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listChallenges = (user, candidateId) =>
  axios.get(`/v1/candidates/${candidateId}/candidate_challenges`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const staffChallengeTransition = (user, challengeId) =>
  axios.post(`/v1/candidate_challenges/${challengeId}/transition_staff`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const candidateChallengeTransition = (user, challengeId) =>
  axios.post(`/v1/candidate_challenges/${challengeId}/transition_candidate`, { state: 'resolve' }, { headers: { Authorization: tokenAuth(user.authToken) } });

// Surveys
export const listGlobalSurveys = (user) =>
  axios.get('/v1/global_surveys?include_questions=true&state=active', { headers: { Authorization: tokenAuth(user.authToken) } });

export const listSurveys = (user, organizationId) =>
  axios.get(`/v1/organizations/${organizationId}/surveys`, { headers: { Authorization: tokenAuth(user.authToken) } });

// export const listJobPostings = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
//   const baseUrl = organizationId ? `/v1/organizations/${organizationId}/job_postings` : '/v1/job_postings';
//   const filters = buildJobPostingFilters(rest);
//   return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
// };

// Programs
export const listPrograms = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
  const baseUrl = organizationId ? `/v1/organizations/${organizationId}/programs` : '/v1/programs';
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchProgram = (user, programId) => axios.get(`/v1/programs/${programId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createProgram = (user, organizationId, program) =>
  axios.post(`/v1/organizations/${organizationId}/programs`, jsonToFormData({ program: program.toJS() }), multipartConfig(user));

export const updateProgram = (user, programId, program) =>
  axios.patch(`/v1/programs/${programId}`, jsonToFormData({ program: program.toJS() }), multipartConfig(user));

export const draftProgram = (user, programId) =>
  axios.post(`/v1/programs/${programId}/pre_publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const publishProgram = (user, programId) =>
  axios.post(`/v1/programs/${programId}/publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const closeProgram = (user, programId) => axios.post(`/v1/programs/${programId}/close`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const cancelProgram = (user, programId) => axios.post(`/v1/programs/${programId}/cancel`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const activateProgram = (user, programId) =>
  axios.post(`/v1/programs/${programId}/activate`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

// Supportive Services
export const listSupportiveServices = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
  const baseUrl = organizationId ? `/v1/organizations/${organizationId}/helpers` : '/v1/helpers';
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchSupportiveService = (user, serviceId) => axios.get(`/v1/helpers/${serviceId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchAbService = (user, serviceId) => axios.get(`/ab/services/${serviceId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// Invitations
export const listInvitations = (user, { page = 1, size = 25 } = {}) =>
  axios.get(`/v1/invitations?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// export const listStaffInvitations = (user, referencedType, referencedId, { page = 1, size = 25, ...query } = {}) => {
//   const baseUrl = `/v1/${referencedType}/${referencedId}/candidates/invites`;
//   const filters = buildStaffListingCandidatesFilters(query);
//   return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
// };

export const createInvitation = (user, invitation) => axios.post('/v1/invitations', { invitation }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const resendInvitation = (user, invitationId) =>
  axios.post(`/v1/invitations/${invitationId}/resend_invitation`, { invitation_id: invitationId }, { headers: { Authorization: tokenAuth(user.authToken) } });
export const bulkCreateInvitations = (user, referencedType, referencedId, invitationsFile) =>
  // eslint-disable-next-line max-len
  axios.post(`/v1/${referencedType}/${referencedId}/candidates/invites`, invitationsFile, {
    headers: { Authorization: tokenAuth(user.authToken), 'Content-Type': 'multipart/form-data' },
  });
export const acceptInvitation = (user, invitationToken) =>
  axios.post(`/v1/${invitationToken}/accept`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });
export const fetchInvitation = (user, invitationToken) =>
  axios.get(`/v1/invitations/${invitationToken}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// Events

export const listEvents = (user, { page = 1, size = 25, organizationId, ...rest } = {}) => {
  const baseUrl = organizationId ? `/v1/organizations/${organizationId}/events` : '/v1/events';
  const filters = buildEPCandidatesFilters(rest);
  return axios.get(`${baseUrl}?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchEvent = (user, eventId) => axios.get(`/v1/events/${eventId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createEvent = (user, organization, event) =>
  axios.post(`/v1/organizations/${organization}/events`, jsonToFormData({ event: event.toJS() }), multipartConfig(user));

export const updateEvent = (user, eventId, event) => axios.patch(`/v1/events/${eventId}`, jsonToFormData({ event: event.toJS() }), multipartConfig(user));

export const draftEvent = (user, eventId) => axios.post(`/v1/events/${eventId}/pre_publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const publishEvent = (user, eventId) => axios.post(`/v1/events/${eventId}/publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const closeEvent = (user, eventId) => axios.post(`/v1/events/${eventId}/close`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const cancelEvent = (user, eventId) => axios.post(`/v1/events/${eventId}/cancel`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const activateEvent = (user, eventId) => axios.post(`/v1/events/${eventId}/activate`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

// Staff
export const createStaffMember = (user, staff) => axios.post('/v1/staffs', jsonToFormData({ staff: staff.toJS() }), multipartConfig(user));

export const fetchStaffMember = (user, staffId) => axios.get(`/v1/staffs/${staffId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const updateStaffMember = (user, staffId, staff) => axios.patch(`/v1/staffs/${staffId}`, jsonToFormData({ staff: staff.toJS() }), multipartConfig(user));

export const deleteStaffMember = (user, staffId) => axios.post(`/v1/staffs/${staffId}/shutdown`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listStaff = (user, organizationId, { page = 1, size = 25 } = {}) =>
  axios.get(`/v1/organizations/${organizationId}/staffs?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listPeerStaff = (user, organizationId, { page = 1, size = 25 } = {}) =>
  axios.get(`/v1/organizations/${organizationId}/peer_staff?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// Enums
export const listSystemEnums = ({ page = 1, size = 25 } = {}) => axios.get(`/v1/enums?page=${page}&size=${size}`);

// Messaging

export const fetchMessage = (user, messageId) => axios.get(`/v1/messages/${messageId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listMessages = (user, { page = 1, size = 25 } = {}) =>
  axios.get(`/v1/messages/?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createMessage = (user, message) =>
  axios.post(
    '/v1/messages',
    {
      message,
    },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const updateMessage = (user, messageId, message) =>
  axios.patch(`/v1/messages/${messageId}`, { message }, { headers: { Authorization: tokenAuth(user.authToken) } });

// Notes

export const fetchNote = (user, noteId) => axios.get(`/v1/case_notes/${noteId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const listNotes = (user, { candidateId, page = 1, size = 25 } = {}) =>
  axios.get(`/v1/candidates/${candidateId}/case_notes/?page=${page}&size=${size}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createNote = (user, candidateId, caseNote) =>
  axios.post(
    `/v1/candidates/${candidateId}/case_notes`,
    {
      caseNote,
    },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const updateNote = (user, noteId, caseNote) =>
  axios.patch(`/v1/case_notes/${noteId}`, { caseNote }, { headers: { Authorization: tokenAuth(user.authToken) } });

// Notifications
const NOTIFICATION_POSSIBLE_FILTERS = ['startDatetime', 'candidateId'];

const buildNotificationFilters = (args) => {
  const filters = [''];
  NOTIFICATION_POSSIBLE_FILTERS.forEach((filter) => {
    if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

export const listNotifications = (user, { page = 1, size = 25, ...rest } = {}) => {
  const filters = buildNotificationFilters(rest);
  return axios.get(`/v1/notifications?page=${page}&size=${size}${filters}`, { headers: { Authorization: tokenAuth(user.authToken) } });
};

export const fetchNotification = (user, notificationId) =>
  axios.get(`/v1/notifications/${notificationId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

// export const viewNotification = (user, notificationId) =>
//   axios.post(`/v1/notifications/${notificationId}/view`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const setNotificationAsViewed = (user, notificationId) =>
  axios.post(`/v1/notifications/${notificationId}/view`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const dismissNotification = (user, notificationId) =>
  axios.post(`/v1/notifications/${notificationId}/dismiss`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

// Pathways
export const listPathways = (user, organizationId) =>
  axios.get(`/v1/organizations/${organizationId}/pathways`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchPathway = (user, pathwayId) => axios.get(`/v1/pathways/${pathwayId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

const PATHWAY_INSTANCE_POSSIBLE_FILTERS = ['state', 'pathwayInstanceName'];

const buildPathwayInstanceFilters = (args) => {
  if (!args) return '';
  const filters = [''];
  PATHWAY_INSTANCE_POSSIBLE_FILTERS.forEach((filter) => {
    if (Array.isArray(args[filter])) {
      args[filter].forEach((list_filter, index) => filters.push(`${toUnderscore(filter)}[]=${encodeURIComponent(args[filter][index])}`));
    } else if (args[filter]) {
      filters.push(`${toUnderscore(filter)}=${encodeURIComponent(args[filter])}`);
    }
  });
  if (filters.length === 1) {
    return '';
  }
  return filters.join('&');
};

export const listPathwayInstances = (user, organizationId, { page = 1, size = 25, filters } = {}) => {
  const pathwayInstancesFilters = buildPathwayInstanceFilters(filters);
  return axios.get(`/v1/organizations/${organizationId}/pathway_instances?page=${page}&size=${size}${pathwayInstancesFilters}`, {
    headers: { Authorization: tokenAuth(user.authToken) },
  });
};

export const fetchPathwayInstance = (user, pathwayId) =>
  axios.get(`/v1/pathway_instances/${pathwayId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const createPathwayInstance = (user, organizationId, pathway) =>
  axios.post(`/v1/organizations/${organizationId}/pathway_instances`, jsonToFormData({ pathway_instance: pathway }), multipartConfig(user));

export const updatePathwayInstance = (user, pathwayInstanceId, pathwayInstance) =>
  axios.patch(`/v1/pathway_instances/${pathwayInstanceId}`, { pathwayInstance }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const draftPathwayInstance = (user, pathwayInstanceId) =>
  axios.post(`v1/pathway_instances/${pathwayInstanceId}/pre_publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const publishPathwayInstance = (user, pathwayInstanceId) =>
  axios.post(`v1/pathway_instances/${pathwayInstanceId}/publish`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const closePathwayInstance = (user, pathwayInstanceId) =>
  axios.post(`/v1/pathway_instances/${pathwayInstanceId}/close`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

export const cancelPathwayInstance = (user, pathwayInstanceId) =>
  axios.post(`/v1/pathway_instances/${pathwayInstanceId}/cancel`, {}, { headers: { Authorization: tokenAuth(user.authToken) } });

// Pathway Instance Steps
export const transitionPathwayStepStaffState = (user, pathwayInstanceStepId, state, extra) =>
  axios.post(`/v1/pathway_instance_steps/${pathwayInstanceStepId}/transition_staff`, jsonToFormData({ state, ...extra }), multipartConfig(user));

export const transitionPathwayStepCandidateState = (user, pathwayInstanceStepId, state, extra) =>
  axios.post(`/v1/pathway_instance_steps/${pathwayInstanceStepId}/transition_candidate`, jsonToFormData({ state, ...extra }), multipartConfig(user));

/**
 * TODO: Remove promise.all() and use appropriate API supporting bulk update after API is available.
 */
export const bulkUpdatePathwayInstanceStepsStaffStates = async (user, pathwayInstanceStepIds, pathwayInstanceStep) => {
  const requests = pathwayInstanceStepIds.map((pathwayInstanceStepId) =>
    axios.patch(`/v1/pathway_instance_steps/${pathwayInstanceStepId}`, pathwayInstanceStep, { headers: { Authorization: tokenAuth(user.authToken) } }),
  );

  const responses = await Promise.all(requests);

  return {
    data: responses.map(({ data }) => data),
  };
};

/**
 * TODO: Remove promise.all() and use appropriate API supporting bulk update after API is available.
 */
export const bulkTransitionPathwayInstanceStepsStaffStates = async (user, pathwayInstanceStepIds, pathwayInstanceStep) => {
  const requests = pathwayInstanceStepIds.map((pathwayInstanceStepId) =>
    axios.post(`/v1/pathway_instance_steps/${pathwayInstanceStepId}/transition_staff`, jsonToFormData(pathwayInstanceStep), multipartConfig(user)),
  );

  const responses = await Promise.all(requests);

  return {
    data: responses.map(({ data }) => data),
  };
};

/**
 * TODO: Remove promise.all() and use appropriate API supporting bulk note update after API is available.
 */
export const bulkUpdatePathwayInstanceStepsStaffNotes = async (user, pathwayInstancesToUpdate) => {
  const requests = pathwayInstancesToUpdate.map((pathwayInstanceToUpdate) => {
    const { id: pathwayInstanceStepId, staffState, note } = pathwayInstanceToUpdate;
    const requestBody = { state: staffState.substring(2), note };

    return axios.post(`/v1/pathway_instance_steps/${pathwayInstanceStepId}/transition_staff`, jsonToFormData(requestBody), multipartConfig(user));
  });

  const responses = await Promise.all(requests);

  return {
    data: responses.map(({ data }) => data),
  };
};

// Pathway Candidates
export const listCandidatePathwaysForPathwayInstance = (user, pathwayInstanceId, { page = 1, size = 25, userName = undefined }) =>
  axios.get(`/v1/pathway_instances/${pathwayInstanceId}/candidate_pathway_instances`, {
    headers: { Authorization: tokenAuth(user.authToken) },
    params: {
      page,
      size,
      user_name: userName,
    },
  });

export const listCandidatePathwaysForOrganization = (user, organizationId, { page = 1, size = 25 }) =>
  axios.get(`/v1/organizations/${organizationId}/candidate_pathway_instances?page=${page}&size=${size}`, {
    headers: { Authorization: tokenAuth(user.authToken) },
  });

export const fetchCandidatePathwaysForCandidate = (user, candidateId) =>
  axios.get(`/v1/candidates/${candidateId}/candidate_pathway_instances`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const fetchCandidatePathway = (user, candidatePathwayId) =>
  axios.get(`/v1/candidate_pathway_instances/${candidatePathwayId}`, { headers: { Authorization: tokenAuth(user.authToken) } });

export const updateCandidatePathway = (user, candidatePathwayId, candidatePathwayInstance) =>
  axios.patch(`/v1/candidate_pathway_instances/${candidatePathwayId}`, { candidatePathwayInstance }, { headers: { Authorization: tokenAuth(user.authToken) } });

export const bulkCreateCandidatePathways = (user, pathwayInstanceId, info) =>
  axios.post(
    '/v1/candidate_pathway_instances/create_cohort',
    { pathway_instance_id: pathwayInstanceId, ...info },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const transitionCandidatePathwayStaffState = (user, candidatePathwayId, state) =>
  axios.post(`/v1/candidate_pathway_instances/${candidatePathwayId}/transition_staff`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } });

/**
 * TODO: Remove promise.all() and use appropriate API supporting bulk update after API is available.
 */
export const transitionCandidatePathwaysStaffState = async (user, candidatePathwayIds, state) => {
  const transitionStaffStatusRequests = candidatePathwayIds.map((candidatePathwayId) =>
    axios.post(`/v1/candidate_pathway_instances/${candidatePathwayId}/transition_staff`, { state }, { headers: { Authorization: tokenAuth(user.authToken) } }),
  );

  const responses = await Promise.all(transitionStaffStatusRequests);

  return {
    data: responses.map(({ data }) => data),
  };
};

export const transitionCandidatePathwayCandidateState = (user, candidatePathwayId, state) =>
  axios.post(
    `/v1/candidate_pathway_instances/${candidatePathwayId}/transition_candidate`,
    { state },
    { headers: { Authorization: tokenAuth(user.authToken) } },
  );

export const updatePathwayInstanceStep = (user, pathwayInstanceStepId, pathwayInstanceStep) =>
  axios.patch(`/v1/pathway_instance_steps/${pathwayInstanceStepId}`, { pathwayInstanceStep }, { headers: { Authorization: tokenAuth(user.authToken) } });
