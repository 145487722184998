/* eslint-disable import/no-unresolved */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { info } from 'risekit/common/components/toast';

import VerifyDomainStyles from './verify_domain_styles';
import UserModel from '../../../session/models/user_model';
import PublicContainer from '../../../solve/components/public_container';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  reconfirmEmail: PropTypes.func.isRequired,
  refreshAuth: PropTypes.func.isRequired,
  forceValidation: PropTypes.bool.isRequired,
};

const defaultProps = {};

// Re-check staff verification status every 30 seconds
const RECHECK_VERIFICATION_INTERVAL = 30000;

const VerifyDomainView = ({ user, reconfirmEmail, refreshAuth, forceValidation }) => {
  const orgName = user && user.getIn(['staffOrganization', 'name']);
  useEffect(() => {
    const recheck = setInterval(() => refreshAuth(user.authToken), RECHECK_VERIFICATION_INTERVAL);
    return () => clearInterval(recheck);
  }, [user, refreshAuth]);

  if (!forceValidation || !user.needsValidation) {
    return <Redirect to="/" />;
  }

  if (!user.validEmail) {
    return (
      <PublicContainer>
        <div className="container">
          <div className="auth">
            <VerifyDomainStyles>
              <h3>Please confirm your email</h3>
              <p>
                <em>You must confirm your email address in order to proceed.</em>
              </p>
              <p>
                <em>Please check your email and click on the link provided in the mail to confirm your email address.</em>
              </p>
              <button
                type="button"
                className="btn"
                color="primary"
                onClick={() => {
                  reconfirmEmail(user.get('authToken'));
                  info('Resent Confirmation Email, Please check your Email');
                }}
              >
                Resend Confirmation Mail
              </button>
            </VerifyDomainStyles>
          </div>
        </div>
      </PublicContainer>
    );
  }

  return (
    <PublicContainer>
      <div className="container">
        <div className="auth">
          <VerifyDomainStyles>
            <h3>Please wait while your identity is being verified.</h3>
            <p>
              <em>
                We were unable to automatically verify that your email address is associated with {orgName}. Your account must be manually verified by an
                administrator in order to be granted full access. We appreciate your patience.
              </em>
            </p>
          </VerifyDomainStyles>
        </div>
      </div>
    </PublicContainer>
  );
};

VerifyDomainView.propTypes = propTypes;
VerifyDomainView.defaultProps = defaultProps;

export default VerifyDomainView;
