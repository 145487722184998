import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { reduxForm, Field, MuiRadioGroup } from '../../../../common/form';
import Button from '../../../../common/components/button/index';
import benefitsFormValidate from './benefits_form_validate';
import BenefitsFormStyles from './benefits_form_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  previousPage: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  criminalBackground: PropTypes.string,
};

const defaultProps = {
  criminalBackground: 'no',
};

const BenefitsForm = ({ handleSubmit, previousPage, pristine, submitting, criminalBackground }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BenefitsFormStyles>
      <form onSubmit={handleSubmit}>
        <Grid container justify="center" alignItems="center" spacing={2}>
          <h2>Benefits (Candidate will see)</h2>
          <Grid item xs={12}>
            <header>Do you provide transportation or are you near accessible public transit?</header>
            <Field name="opportunityTypes.transport" component={MuiRadioGroup}>
              <FormGroup row>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" labelPlacement="start" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" labelPlacement="start" />
              </FormGroup>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <header>Do you offer child care?</header>
            <Field name="opportunityTypes.childCare" component={MuiRadioGroup}>
              <FormGroup row>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" labelPlacement="start" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" labelPlacement="start" />
              </FormGroup>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <header>Does this position offer health care benefits?</header>
            <Field name="opportunityTypes.healthCare" component={MuiRadioGroup}>
              <FormGroup row>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" labelPlacement="start" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" labelPlacement="start" />
              </FormGroup>
            </Field>
          </Grid>
          <h2>Opportunities (Candidate will not see)</h2>
          <Grid item xs={12}>
            <header>Do you hire people with backgrounds or felonies? </header>
            <Field name="opportunityTypes.criminalBackground" component={MuiRadioGroup}>
              <FormGroup row>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" labelPlacement="start" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" labelPlacement="start" />
              </FormGroup>
            </Field>
          </Grid>
          {criminalBackground === 'yes' && (
            <Grid item xs={12}>
              <header>What type of background?</header>
              <Field name="opportunityTypes.backgroundType" component={MuiRadioGroup}>
                <FormGroup row>
                  <FormControlLabel value="felony" control={<Radio color="primary" />} label="Felony" labelPlacement="start" />
                  <FormControlLabel value="misdemeanor" control={<Radio color="primary" />} label="Misdemeanor" labelPlacement="start" />
                  <FormControlLabel value="both" control={<Radio color="primary" />} label="Both" labelPlacement="start" />
                </FormGroup>
              </Field>
            </Grid>
          )}
          <Grid item xs={12}>
            <header>Does this job sponsor H1B Visas? </header>
            <Field name="opportunityTypes.h1b" component={MuiRadioGroup}>
              <FormGroup row>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" labelPlacement="start" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" labelPlacement="start" />
              </FormGroup>
            </Field>
          </Grid>
          <Grid item xs={12}>
            <header>Are you open the Government sponsoring the wages of your new potential hire(s)?</header>
            <Field name="opportunityTypes.sponsorship" component={MuiRadioGroup}>
              <FormGroup row>
                <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" labelPlacement="start" />
                <FormControlLabel value="no" control={<Radio color="primary" />} label="No" labelPlacement="start" />
              </FormGroup>
            </Field>
          </Grid>
          <div className="button-group">
            <div className="button-left">
              <Button buttonType="secondary-outline" type="button" disabled={pristine || submitting} onClick={previousPage}>
                Previous
              </Button>
            </div>
            <div className="button-right">
              <Button buttonType="secondary" type="submit">
                Next Step
              </Button>
            </div>
          </div>
        </Grid>
      </form>
    </BenefitsFormStyles>
  );
};

BenefitsForm.propTypes = propTypes;
BenefitsForm.defaultProps = defaultProps;

const BenefitsFormView = reduxForm({
  form: 'jobForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate: benefitsFormValidate,
})(BenefitsForm);

export default BenefitsFormView;
