import { connect } from 'react-redux';
import ProgramApplicantDetailsView from './program_applicant_details_view';
import { getCandidateProgram, getCurrentUser, getCandidateSurveys } from '../../../reducer';
import { fetchCandidateProgram, listCandidateSurveys } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: candidateProgramId },
    },
  },
) => ({
  candidateProgramId,
  currentUser: getCurrentUser(state),
  candidateSurveys: getCandidateSurveys(state),
  application: getCandidateProgram(state, candidateProgramId),
});

const mapDispatchToProps = {
  fetchCandidateProgram,
  listCandidateSurveys,
};

const ProgramApplicantDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ProgramApplicantDetailsView);

export default ProgramApplicantDetailsContainer;
