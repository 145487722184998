import { connect } from 'react-redux';

import JobDetailsView from './job_details_view';
import { createExternalPublicLink } from '../../../public_link/actions';
import { getCurrentUser, getIndeedJobPosting, getPublicLinkForPosting } from '../../../reducer';
import { fetchIndeedJobPosting, updateJobPosting, publishJobPosting, closeJobPosting, draftJobPosting } from '../../../solve/actions';

// mapStateToProps(state, { prop })
const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const job = getIndeedJobPosting(state, id);
  const user = getCurrentUser(state);
  const canEdit =
    job &&
    (job.getIn(['postingOrganization', 'id']) === user.getIn(['staffOrganization', 'id']) ||
      job.getIn(['employer', 'id']) === user.getIn(['staffOrganization', 'id']));

  return {
    jobId: id,
    job,
    user,
    publicLink: getPublicLinkForPosting(state, id),
    canEdit,
  };
};

const mapDispatchToProps = {
  fetchIndeedJobPosting,
  createExternalPublicLink,
  updateJobPosting,
  publishJobPosting,
  closeJobPosting,
  draftJobPosting,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const props = {
    ...dispatchProps,
    ...ownProps,
    ...stateProps,
  };
  return props;
};

const JobDetailsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(JobDetailsView);

export default JobDetailsContainer;
