import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import JobListFiltersView from './job_list_filters_view';
import {
  getCurrentUser,
  getCandidateJobListingsFilters,
  getCandidateJobListingsSearch,
  getCandidateJobListingsZipcode,
  getCandidateJobListingsDistance,
  getEnumsForFormSelect,
} from '../../../../reducer';
import {
  setCandidateJobListingsFilters,
  setCandidateJobListingsSearch,
  setCandidateJobListingsZipcode,
  setCandidateJobListingsDistance,
} from '../../../../solve/actions';

const mapStateToProps = (state) => {
  let zipCode;
  zipCode = getCandidateJobListingsZipcode(state);
  zipCode = zipCode || getCurrentUser(state).zip;
  return {
    filters: getCandidateJobListingsFilters(state),
    distanceFilter: getEnumsForFormSelect(state, 'DistanceType'),
    jobTitleFilter: getEnumsForFormSelect(state, 'JobType'),
    industryTypeFilter: getEnumsForFormSelect(state, 'IndustryType'),
    shiftTitleFilter: getEnumsForFormSelect(state, 'ShiftType'),
    salaryFilter: getEnumsForFormSelect(state, 'PayLevelType'),
    zipCode,
    distance: getCandidateJobListingsDistance(state),
    search: getCandidateJobListingsSearch(state),
    setSearch: PropTypes.func.isRequired,
  };
};

const mapDispatchToProps = {
  onChange: setCandidateJobListingsFilters,
  setSearch: setCandidateJobListingsSearch,
  setCandidateJobListingsZipcode,
  setCandidateJobListingsDistance,
};

const JobListFiltersContainer = connect(mapStateToProps, mapDispatchToProps)(JobListFiltersView);

export default JobListFiltersContainer;
