const validateCustomScreeningQuestion = (question) => {
  const errors = {};
  // TODO: this doesn't seem to be working...
  if (!question.getIn(['extra', 'questionPlaceholders', '<0>'])) {
    errors.extra = { questionPlaceholders: { '<0>': 'Required' } };
  }
  return errors;
};

const screenerFormValidate = (values) => {
  const errors = {};

  if (!values.get('allAgesWelcomed')) {
    const minAge = values.get('minAge');
    const maxAge = values.get('maxAge');
    if (!minAge) {
      errors.minAge = 'Required';
    }
    if (!maxAge) {
      errors.maxAge = 'Required';
    }
    if (minAge && maxAge && maxAge < minAge) {
      errors.maxAge = 'Must be greater than minimum age';
    }
  }

  // if (!values.get('allGendersWelcomed')) {
  //   if (!values.get('targetGender')) {
  //     errors.targetGender = 'Required';
  //   }
  // }

  if (!values.get('isResumeRequired')) {
    errors.isResumeRequired = 'Required';
  }

  if (!values.get('participantsCanApplyOnSolve') && !values.get('applicationWebsite')) {
    errors.applicationWebsite = 'Required';
  }

  if (!values.get('allZipCodesWelcomed')) {
    if (!values.get('customZipCodes')) {
      // TODO: should validate...
      // errors.allZipCodesWelcomed = 'Required';
    }
  }

  if (values.get('customScreeningQuestions')) {
    const list = values.get('customScreeningQuestions');
    errors.customScreeningQuestions = list.map(validateCustomScreeningQuestion);
  }

  return errors;
};

export default screenerFormValidate;
