import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EditingStyles from './editing_styles';
import {
  Field,
  FieldArray,
  MuiTextField,
  MuiTextFieldArray,
  MuiDatePicker,
  MuiCheckbox,
  MuiThemeProvider,
  MuiFormTheme,
} from '../../../../../common/form/index';

const propTypes = {
  namespace: PropTypes.string.isRequired,
  isCurrent: PropTypes.bool,
};

const defaultProps = {
  isCurrent: false,
};

const EditingView = ({ namespace, isCurrent }) => (
  <MuiThemeProvider theme={MuiFormTheme}>
    <EditingStyles>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="Title" name={`${namespace}.experience.title`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="Company" name={`${namespace}.experience.company`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="City" name={`${namespace}.experience.city`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Field component={MuiTextField} variant="outlined" label="State" name={`${namespace}.experience.state`} margin="dense" fullWidth required />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={MuiDatePicker}
            variant="outlined"
            label="Date From (MM-DD-YYYY)"
            name={`${namespace}.experience.startDate`}
            margin="dense"
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Field component={MuiCheckbox} name={`${namespace}.experience.isCurrent`} margin="dense" fullWidth />}
            label="I am currently working in this role"
          />
        </Grid>
        {!isCurrent && (
          <Grid item xs={12}>
            <Field
              component={MuiDatePicker}
              variant="outlined"
              label="Date To (MM-DD-YYYY)"
              name={`${namespace}.experience.endDate`}
              margin="dense"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <header>Tell us what were your responsibilities and achievements</header>
          <FieldArray
            component={MuiTextFieldArray}
            label="Add a responsibility/achievement"
            name={`${namespace}.experience.descriptions`}
            margin="dense"
            multiline
            required
          />
        </Grid>
      </Grid>
    </EditingStyles>
  </MuiThemeProvider>
);

EditingView.propTypes = propTypes;
EditingView.defaultProps = defaultProps;

export default EditingView;
