import { connect } from 'react-redux';
import DashboardView from './dashboard_view';
import { getCurrentUser } from '../../../reducer';
import { fetchCandidate } from '../../../solve/actions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const candidateId = currentUser?.get('candidateId');

  return {
    currentUser,
    candidateId,
  };
};

const mapDispatchToProps = {
  fetchCandidate,
};

const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardView);

export default DashboardContainer;
