import { connect } from 'react-redux';
import ListingApplicantDetailsView from './listing_applicant_details_view';
import { getTreatment, getCandidate, getCurrentUser, getCandidateSurveys } from '../../../reducer';
import { fetchCandidate, listCandidateSurveys } from '../../../solve/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id: candidateId },
    },
  },
) => {
  const candidate = getCandidate(state, candidateId);
  return {
    candidateId,
    candidate,
    currentUser: getCurrentUser(state),
    candidateSurveys: getCandidateSurveys(state),
    showInmates: !!getTreatment(state, 'showInmates'),
  };
};

const mapDispatchToProps = {
  fetchCandidate,
  listCandidateSurveys,
};

const ListingApplicantDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(ListingApplicantDetailsView);

export default ListingApplicantDetailsContainer;
