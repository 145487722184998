/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import React, { useState, useEffect } from 'react';
import { Map, OrderedSet, getIn } from 'immutable';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { EmployeeTypeEnum } from 'risekit/common/enum/employee.enum';

import { isNonProfitInmateUser } from 'util/common';

import theme from '../../../common/theme';
import CandidatesFilters from './candidates_filters';
import { useDebouncedEffect } from '../../../util/hooks';
import ListingApplicantRow from '../listing_applicant_row';
import UserModel from '../../../session/models/user_model';
import SortHeading from '../../../common/components/sort_heading';
import { downloadFileFromBlob } from '../../../util/file_operations';
import { Button as ComponentButton, Spinner } from '../../../common/components';
import CandidatesStyles, { StyledTable, Banner, ResultsPageNavigator } from './candidates_styles';

const propTypes = {
  search: PropTypes.string,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setSearch: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  totalCandidates: PropTypes.number.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  candidateIds: PropTypes.instanceOf(OrderedSet).isRequired,
  listFilteredCandidatesForStaff: PropTypes.func.isRequired,
  fetchOrganizationCandidatesReport: PropTypes.func.isRequired,
};

const defaultProps = {
  search: '',
};

function useSearch(search, setSearch, loaded) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (loaded && search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [loaded, search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const CandidatesView = ({
  loaded,
  search,
  loading,
  filters,
  setSearch,
  setFilters,
  currentUser,
  candidateIds,
  organizationId,
  totalCandidates,
  listFilteredCandidatesForStaff,
  fetchOrganizationCandidatesReport,
}) => {
  const [sortBy, setSortBy] = useState('lastName');
  const [sortOrder, setSortOrder] = useState('ASC');
  const [pageShown, setPageShown] = useState(1);
  const totalPages = Math.ceil(totalCandidates / 25) || 1;

  useEffect(() => {
    setPageShown(1);
  }, [filters, search]);

  const listFilteredCandidatesForStaffAtPage = (page, query) => listFilteredCandidatesForStaff(currentUser, organizationId, query, page);

  useEffect(() => {
    if (!loaded) {
      const query = filters.merge({ userFullName: search });
      listFilteredCandidatesForStaffAtPage(pageShown, query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, filters, search, currentUser, organizationId, pageShown]);

  const scrollTop = () => window.scrollTo(0, 0);

  const getNextPageResults = () => {
    const query = filters.merge({ userFullName: search });
    listFilteredCandidatesForStaffAtPage(pageShown + 1, query).then(() => scrollTop());
    setPageShown(pageShown + 1);
  };

  const getPrevPageResults = () => {
    const query = filters.merge({ userFullName: search });
    listFilteredCandidatesForStaffAtPage(pageShown - 1, query).then(() => scrollTop());
    setPageShown(pageShown - 1);
  };

  function toggleSort(sortByValue, sortOrderValue) {
    if (sortBy !== sortByValue) {
      setSortBy(sortByValue);
    }
    if (sortOrder !== sortOrderValue) {
      setSortOrder(sortOrderValue);
    }
    const filterValues = filters.merge({ _sort: sortByValue, _order: sortOrderValue });
    setFilters(filterValues);
  }

  const { newSearch, onSearchChange } = useSearch(search, setSearch, loaded);

  const isEmployer = getIn(currentUser, ['staffOrganization', 'orgType']) === EmployeeTypeEnum.EMPLOYER;

  const PageNavigator = ({ bottom }) => (
    <ResultsPageNavigator>
      {!bottom && (
        <div>
          <Typography color="secondary">{`Candidates Found: ${totalCandidates}`}</Typography>
        </div>
      )}
      {totalCandidates > 25 && (
        <div className="prev-next-container">
          <Button
            style={{ color: pageShown <= 1 ? theme.color.grayLight : theme.color.blue }}
            startIcon={<NavigateBeforeIcon />}
            disabled={pageShown <= 1}
            onClick={() => getPrevPageResults()}
          >
            Prev
          </Button>
          <Typography component="span" variant="subtitle2" style={{ color: theme.color.grayDark }}>{`Page ${pageShown} of ${totalPages}`}</Typography>
          <Button
            style={{ color: pageShown >= totalPages ? theme.color.grayLight : theme.color.blue }}
            endIcon={<NavigateNextIcon />}
            disabled={pageShown >= totalPages}
            onClick={() => getNextPageResults()}
          >
            Next
          </Button>
        </div>
      )}
    </ResultsPageNavigator>
  );

  PageNavigator.propTypes = {
    bottom: PropTypes.bool,
  };
  PageNavigator.defaultProps = {
    bottom: false,
  };

  const getCsv = () => {
    const currentUserOrgId = currentUser.get('staffOrganization').id;
    return fetchOrganizationCandidatesReport(currentUser, currentUserOrgId).then((response) => {
      downloadFileFromBlob(response.data, `organization_candidates_${currentUserOrgId}.csv`);
    });
  };

  return (
    <CandidatesStyles>
      <h1>Candidates</h1>
      <div>
        {!loading && loaded && (
          <Banner>
            Results: {totalCandidates} Candidates
            <ComponentButton buttonType="secondary-outline" onClick={getCsv}>
              Download Candidates Report
            </ComponentButton>
          </Banner>
        )}
        <TextField
          id="search"
          placeholder={loading ? 'Loading... ' : 'Search for a candidate by name'}
          margin="dense"
          variant="outlined"
          fullWidth
          disabled={loading}
          style={{ marginBottom: 30 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={newSearch}
          onChange={onSearchChange}
        />
        <CandidatesFilters />
        <PageNavigator />
        <StyledTable>
          <thead>
            <tr>
              <th width="200">
                <SortHeading
                  toggleSort={toggleSort}
                  sortBy="lastName"
                  sortOrder={sortBy === 'lastName' ? sortOrder : 'ASC'}
                  columnHeading="Name"
                  type={sortBy === 'lastName' ? 'fill' : 'outline'}
                />
              </th>
              <th>Resume</th>

              {!isEmployer ? <th>Challenges</th> : null}

              {!isNonProfitInmateUser(currentUser) ? (
                <th width="150">Zip</th>
              ) : (
                <React.Fragment>
                  <th width="150">Inmate facility</th>
                  <th width="150">Inmate number</th>
                </React.Fragment>
              )}
              <th width="150">
                <SortHeading
                  toggleSort={toggleSort}
                  sortBy="createdAt"
                  sortOrder={sortBy === 'createdAt' ? sortOrder : 'ASC'}
                  columnHeading="Date Joined"
                  type={sortBy === 'createdAt' ? 'fill' : 'outline'}
                />
              </th>
              <th width="180">Last Activity</th>
              <th width="80">&nbsp;</th>
            </tr>
          </thead>
          <tbody>{candidateIds ? candidateIds.map((candidateId) => <ListingApplicantRow key={candidateId} candidateId={candidateId} />) : <Spinner />}</tbody>
        </StyledTable>
        <PageNavigator bottom />
      </div>
    </CandidatesStyles>
  );
};

CandidatesView.propTypes = propTypes;
CandidatesView.defaultProps = defaultProps;

export default CandidatesView;
