import { connect } from 'react-redux';
import EventView from './event_view';
import { getEvent, getCurrentUser, getPublicLinkForPosting } from '../../../reducer';
import { fetchEvent, publishEvent, closeEvent, draftEvent } from '../../../solve/actions';
import { createPublicLink } from '../../../public_link/actions';

const mapStateToProps = (
  state,
  {
    match: {
      params: { id },
    },
  },
) => {
  const event = getEvent(state, id);
  const user = getCurrentUser(state);
  const canEdit = event && event.getIn(['postingOrganization', 'id']) === user.getIn(['staffOrganization', 'id']);
  return {
    eventId: id,
    event: getEvent(state, id),
    user: getCurrentUser(state),
    publicLink: getPublicLinkForPosting(state, id),
    canEdit,
  };
};

const mapDispatchToProps = {
  fetchEvent,
  publishEvent,
  closeEvent,
  draftEvent,
  createPublicLink,
};

const EventContainer = connect(mapStateToProps, mapDispatchToProps)(EventView);

export default EventContainer;
