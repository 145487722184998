import styled from 'styled-components';

export const IndeedJobStyles = styled.div`
  font-family: Roboto, Helvetica, Arial, sans-serif;

  .job-title-container {
    margin-bottom: 8px;
  }

  .job-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 4px;
  }

  .job-company {
    font-size: 20px;
    font-weight: 600;
  }

  .job-location {
    font-size: 18px;
    font-weight: 600;
  }

  .url-btn-container {
    display: flex;
    align-items: center;
  }

  .view-details-btn {
    margin-left: 8px;
  }
`;
