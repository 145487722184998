import styled from 'styled-components';
// import theme from '../../../common/theme';

const MessageFormStyles = styled.div`
  button {
    margin-top: 25px;
  }
`;

export default MessageFormStyles;
