import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from '../../../../common/form';
import Button from '../../../../common/components/button/index';
import { Text, TextHeading } from '../../../../common/components';
import questionnaireCompletedValidate from './questionnaire_completed_validate';
import QuestionnaireCompletedStyles from './questionnaire_completed_styles';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  skippedQuestionnaire: PropTypes.bool,
};

const defaultProps = {
  skippedQuestionnaire: false,
};

const QuestionnaireCompleted = ({ handleSubmit, skippedQuestionnaire }) => (
  <QuestionnaireCompletedStyles>
    <form onSubmit={handleSubmit}>
      <img src="/images/resume.png" alt="questionnaire complete" />
      {!skippedQuestionnaire && (
        <TextHeading size="large" weight="bold" margin="25px 0px 10px">
          Congratulations, your Questionnaire is completed.
        </TextHeading>
      )}
      <Text color="medium">As a next step you have to answer a few screening questions.</Text>
      <Button buttonType="primary-outline" type="submit">
        Answer Event Registration Questions
      </Button>
    </form>
  </QuestionnaireCompletedStyles>
);

QuestionnaireCompleted.propTypes = propTypes;
QuestionnaireCompleted.defaultProps = defaultProps;

const QuestionnaireCompletedView = reduxForm({
  form: 'applyToEventForm',
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
  validate: questionnaireCompletedValidate,
})(QuestionnaireCompleted);

export default QuestionnaireCompletedView;
