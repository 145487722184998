/* eslint-disable react/jsx-props-no-spreading */
// https://material-ui.com/components/tabs/
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { List } from 'immutable';
import TabberStyles from './tabber_styles';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      // {...other}
    >
      <Box style={{ paddingTop: 10 }}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: undefined,
};

const StyledTabs = withStyles({
  root: {
    flex: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 6,
    '& > div': {
      width: '100%',
      backgroundColor: '#1C94E0',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(() => ({
  root: {
    flex: 1,
    // textTransform: 'none',
    // fontWeight: 'normal',
    backgroundColor: '#fff',
    color: '#C0C0C0',
    fontSize: 15,
    borderBottom: 'solid 6px #DBDBDB',
    boxShadow: 'none',
    letterSpacing: 0,
    maxWidth: 'none',
    minWidth: '100px',
    // opacity: 0.5,
    '&:focus': {
      color: '#1C94E0',
      opacity: 1,
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      right: -20,
      top: 12,
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}));

export default function TabberView({ defaultValue, onChange, children }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(defaultValue || 0);
  const validChildren = typeof children.filter === 'function' ? children.filter((child) => child) : List([children]);

  function handleChange(event, newValue) {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }

  return (
    <TabberStyles>
      <div className={classes.root}>
        <StyledTabs value={value} onChange={handleChange} aria-label="tabs" className="styled-tabs">
          {validChildren.map(
            (child, i) =>
              child?.props?.tabLabel && (
                <StyledTab
                  className="styled-tab"
                  key={child.props.tabLabel}
                  label={
                    <Badge className={classes.padding} color="error" badgeContent={child.props.badge}>
                      {child.props.tabLabel}
                    </Badge>
                  }
                  {...a11yProps(i)}
                />
              ),
          )}
        </StyledTabs>
        {validChildren.map(
          (child, i) =>
            child?.props?.tabLabel && (
              <TabPanel key={child.props.tabLabel} value={value} index={i}>
                {child}
              </TabPanel>
            ),
        )}
      </div>
    </TabberStyles>
  );
}

TabberView.propTypes = {
  defaultValue: PropTypes.number,
  children: PropTypes.node,
  onChange: PropTypes.func,
};

TabberView.defaultProps = {
  defaultValue: 0,
  children: undefined,
  onChange: undefined,
};
