import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/CheckCircle';
import { List, OrderedSet } from 'immutable';
import { reduxForm, Field, FieldArray, MuiTextField, MuiFileUpload, phoneMask, zipCodeMask, MuiSelect } from '../../../common/form';
import theme from '../../../common/theme';
import Backgrounds from './backgrounds';
import { Card } from '../../../common/styles/layout';
import OrganizationFormStyles from './organization_form_styles';
import organizationFormValidate from './organization_form_validate';
import { useFetchOnce } from '../../../util/hooks';
import UserModel from '../../../session/models/user_model';

const propTypes = {
  user: PropTypes.instanceOf(UserModel).isRequired,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  isEmployer: PropTypes.bool,
  isOnboarding: PropTypes.bool.isRequired,
  logo: PropTypes.instanceOf(FileList),
  oldLogo: PropTypes.string,
  showBarriers: PropTypes.bool,
  barriers: PropTypes.instanceOf(List).isRequired,
  industryTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  fetchOrganization: PropTypes.func.isRequired,
  organization: PropTypes.instanceOf(OrderedSet),
  organizationId: PropTypes.string,
};

const defaultProps = {
  isEmployer: true,
  logo: undefined,
  oldLogo: undefined,
  showBarriers: false,
  organization: undefined,
  organizationId: undefined,
};

const OrgCategoryField = ({ title, subtitle, currentValue, value, onChange, error, touched }) => (
  <button type="button" className={touched && error ? 'category-error' : 'category'} onClick={() => onChange(value)}>
    {currentValue === value && <CheckIcon style={{ float: 'right', color: theme.color.action }} />}
    <h4>{title}</h4>
    {subtitle && <cite>{subtitle}</cite>}
  </button>
);

OrgCategoryField.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  currentValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  touched: PropTypes.bool,
};

OrgCategoryField.defaultProps = {
  subtitle: '',
  error: '',
  touched: false,
};

const EMPLOYER_BARRIERS_DESC = [
  'We try to tailor our candidate recommendations so that you get candidates that are a great fit for your company or institution. ',
  'For that reason, tell us a little more about your employment barriers, both with placement and retention with your candidates.',
  'Please choose up to three barriers from below.',
].join(' ');
const ORG_BARRIERS_DESC = 'Please select one primary category for your organization';

// TODO Not sure if "employer details" are implemented in the API"

const OrganizationForm = ({
  user,
  handleSubmit,
  pristine,
  submitting,
  isEmployer,
  isOnboarding,
  logo,
  oldLogo,
  showBarriers,
  barriers,
  industryTypes,
  fetchOrganization,
  organization,
  organizationId,
}) => {
  useFetchOnce(user, organizationId, fetchOrganization, organization || isOnboarding);

  let uploadedImage;
  if (logo) {
    uploadedImage = URL.createObjectURL(logo[0]);
  }

  return (
    <OrganizationFormStyles>
      <form onSubmit={handleSubmit}>
        <h2>{isOnboarding ? `Create a New ${isEmployer ? 'Company' : 'Organization'} Profile` : `Edit ${isEmployer ? 'Company' : 'Organization'} Profile`}</h2>
        <div>{oldLogo && !logo && <img src={oldLogo} height="70" alt="Logo" name="logoUrl" />}</div>
        <div>{logo && <img src={uploadedImage} height="70" alt="Logo" />}</div>
        <Field component={MuiFileUpload} label={oldLogo ? 'Replace logo' : 'Add Your Company logo'} name="logo" margin="dense" />
        <div style={{ height: 20 }} />
        <Card>
          <Grid container spacing={2}>
            <Grid item lg={6} md={12}>
              <h3>Company Details</h3>
              <Grid container spacing={2}>
                <Grid item md={6}>
                  <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Company Name"
                        name="name"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        disabled={!isOnboarding}
                        helperText={!isOnboarding && 'Contact RiseKit to change'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field component={MuiTextField} label="Employer Identification Number" name="ein" margin="dense" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Primary Website"
                        name="primaryWebsite"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                        disabled={!isOnboarding}
                        helperText={!isOnboarding && 'Contact RiseKit to change'}
                      />
                    </Grid>
                    {/* <Grid item xs={12}> */}
                    {/*  <Field component={MuiTextField} label="Secondary Website" name="secondaryWebsite" margin="dense" variant="outlined" fullWidth /> */}
                    {/* </Grid> */}
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Phone Number"
                        name="phone"
                        type="cell"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required // eslint-disable-next-line react/jsx-props-no-spreading
                        {...phoneMask}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6}>
                  <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                    <Grid item xs={12}>
                      <Field
                        component={MuiTextField}
                        label="Address 1"
                        name="addressAttributes.address1"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field component={MuiTextField} label="Address 2" name="addressAttributes.address2" margin="dense" variant="outlined" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <Field component={MuiTextField} label="City" name="addressAttributes.city" margin="dense" variant="outlined" fullWidth required />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item xs={6}>
                          <Field component={MuiTextField} label="State" name="addressAttributes.state" margin="dense" variant="outlined" fullWidth required />
                        </Grid>
                        <Grid item xs={6}>
                          <Field
                            component={MuiTextField}
                            label="Zip"
                            name="addressAttributes.zip"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                            required // eslint-disable-next-line react/jsx-props-no-spreading
                            {...zipCodeMask}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              {isEmployer && (
                <Fragment>
                  <h3>Employer Details</h3>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12}>
                      <Grid container justify="flex-start" alignItems="flex-start" spacing={2}>
                        <Grid item xs={12}>
                          <MuiSelect
                            options={industryTypes}
                            label="What industry is your company in?"
                            name="industryType"
                            margin="dense"
                            variant="outlined"
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={12}>
                      <FieldArray name="willHireBackgrounds" component={Backgrounds} />
                    </Grid>
                  </Grid>
                </Fragment>
              )}
              <h3>Description</h3>
              <Field
                component={MuiTextField}
                name="description"
                label="Tell us a little about your company"
                multiline
                rows={isEmployer ? '2' : '11'}
                margin="dense"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>
          <div style={{ height: 30 }} />
        </Card>

        {((showBarriers && isEmployer) || !isEmployer) && (
          <Card>
            <h3>{isEmployer ? 'Biggest Barrier' : 'Primary Category'}</h3>
            <small>{isEmployer ? EMPLOYER_BARRIERS_DESC : ORG_BARRIERS_DESC}</small>
            <div style={{ height: 20 }} />
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Field
                  component={({ input: { value, onChange }, meta: { error, touched } }) => (
                    <OrgCategoryField
                      currentValue={value}
                      onChange={onChange}
                      error={error}
                      touched={touched}
                      title="Workforce Development"
                      subtitle="Job Training Placement/Career Pathway Program/Staffing"
                      value="workforceDevelopment"
                    />
                  )}
                  name="orgCategoryType"
                />
                <div style={{ height: 20 }} />
                <Field
                  component={({ input: { value, onChange }, meta: { error, touched } }) => (
                    <OrgCategoryField
                      currentValue={value}
                      onChange={onChange}
                      error={error}
                      touched={touched}
                      title="Education Program"
                      subtitle="High School, City College, After School, College Readiness"
                      value="educationProgram"
                    />
                  )}
                  name="orgCategoryType"
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2}>
                  {barriers.map((barrier) => (
                    <Grid item key={barrier.get('slug')} xs={6} md={3} lg={4}>
                      <Field
                        component={({ input: { value, onChange }, meta: { error, touched } }) => (
                          <OrgCategoryField
                            currentValue={value}
                            onChange={onChange}
                            error={error}
                            touched={touched}
                            title={barrier.get('label')}
                            value={barrier.get('slug')}
                          />
                        )}
                        name="orgCategoryType"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <div style={{ height: 30 }} />
          </Card>
        )}
        {isOnboarding ? (
          <Button variant="contained" className="action-contained" disabled={pristine || submitting} onClick={handleSubmit} style={{ float: 'right' }}>
            Confirm Details
          </Button>
        ) : (
          <div className="button-group">
            <Link to="/staff/dashboard">
              <Button type="button">Discard Changes</Button>
            </Link>
            <Button variant="contained" className="action-contained" disabled={pristine || submitting} onClick={handleSubmit}>
              Save Changes
            </Button>
          </div>
        )}
      </form>
    </OrganizationFormStyles>
  );
};

OrganizationForm.propTypes = propTypes;
OrganizationForm.defaultProps = defaultProps;

const OrganizationFormView = reduxForm({
  form: 'orgProfileForm',
  enableReinitialize: true,
  validate: organizationFormValidate,
})(OrganizationForm);

export default OrganizationFormView;
