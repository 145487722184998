import { Record } from 'immutable';

const DEFAULT_ARGS = {
  id: undefined,
  candidate_id: undefined,
  organization_id: undefined,
  organization: {},
};

class CandidateOrganizationModel extends Record(DEFAULT_ARGS) {
  static _name = 'CandidateOrganization';
}

export default CandidateOrganizationModel;
