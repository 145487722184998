// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';

// const REQUIRED = 'Required';

const finalizeFormValidate = (values) => {
  const errors = {};
  if (!values.get('eventSharing')) {
    errors.eventSharing = 'Required';
  }
  return errors;
};

export default finalizeFormValidate;
