import { connect } from 'react-redux';
import CandidatesInvitedView from './candidates_invited_view';

import {
  getCurrentUser,
  getInvitations,
  getStaffInvitationIds,
  getStaffInvitationsTotal,
  // getStaffCandidateIds,
  // getStaffCandidatesLoaded,
  // getStaffCandidatesLoading,
  // getStaffCandidatesFilters,
  // getStaffCandidatesSearch,
} from '../../../reducer';

import { listFilteredInvitations } from '../../../solve/actions';

const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const invitationIds = getStaffInvitationIds(state);
  const invitations = getInvitations(state, invitationIds);
  const totalInvitations = getStaffInvitationsTotal(state);

  return {
    currentUser,
    invitations,
    total: totalInvitations,
    // loaded: getStaffCandidatesLoaded(state),
    // loading: getStaffCandidatesLoading(state),
    // filters: getStaffCandidatesFilters(state),
    // search: getStaffCandidatesSearch(state),
  };
};

const mapDispatchToProps = {
  listFilteredInvitations,
  // setSearch: setStaffCandidatesSearch,
  // setFilters: setStaffCandidatesFilters,
};

// const mergeProps = (stateProps, dispatchProps, ownProps) => ({
//   ...stateProps,
//   ...ownProps,
//   ...dispatchProps,
// });

const CandidatesInvitedContainer = connect(mapStateToProps, mapDispatchToProps)(CandidatesInvitedView);

export default CandidatesInvitedContainer;
