import { genericAPIActionCreator, genericAPIActionTypes } from '../util/generic_api_action';
import * as API from '../api';

const ACTIONS = {
  INVITE_VISITED: 'Invite:Visited',
  FETCH_INVITE: genericAPIActionTypes('Invite:Fetch'),
  USE_INVITE: genericAPIActionTypes('Invite:Use'),
  CREATE_LINK: genericAPIActionTypes('PublicLink:Create'),
  LINK_VISITED: 'PublicLink:Visited',
  FETCH_LINK: genericAPIActionTypes('PublicLink:Fetch'),
  USE_LINK: genericAPIActionTypes('PublicLink:Use'),
  FLUSH_ACTIVE: 'PublicLink:FlushActive',
  SET_REDIRECT_PATH: 'PublicLink:SetRedirectPath',
  RESET_REDIRECT_PATH: 'PublicLink:ReSetRedirectPath',
};

export default ACTIONS;

export const visitedInvite = ({ token, recommendedType, recommendedId }) => ({
  type: ACTIONS.INVITE_VISITED,
  token,
  recommendedType,
  recommendedId,
});
export const visitedLink = ({ token, subjectType, subjectId }) => ({
  type: ACTIONS.LINK_VISITED,
  token,
  subjectType,
  subjectId,
});

export const flushActive = () => ({ type: ACTIONS.FLUSH_ACTIVE });

export const createPublicLink = (user, linkType, subjectId) =>
  genericAPIActionCreator(() => API.createPublicLink(user, linkType, subjectId), ACTIONS.CREATE_LINK, undefined, { linkType, subjectId });

// external job link
export const createExternalPublicLink = (user, publicLink) =>
  genericAPIActionCreator(() => API.createExternalPublicLink(user, publicLink), ACTIONS.CREATE_LINK, undefined, { publicLink });

const silenceError = async (func) => {
  try {
    return await func();
  } catch (e) {
    return {};
  }
};

export const setRedirectPath = (path) => ({ type: ACTIONS.SET_REDIRECT_PATH, payload: path });
export const resetRedirectPath = () => ({ type: ACTIONS.RESET_REDIRECT_PATH });

export const fetchInviteLink = (user, token) =>
  genericAPIActionCreator(() => silenceError(() => API.fetchInviteLinkForPreview(user, token)), ACTIONS.FETCH_INVITE);
export const fetchPublicLink = (user, token) =>
  genericAPIActionCreator(() => silenceError(() => API.fetchPublicLinkForPreview(user, token)), ACTIONS.FETCH_LINK);
export const consumePublicLink = (user, token) =>
  genericAPIActionCreator(() => silenceError(() => API.usePublicLink(user, token)), ACTIONS.USE_LINK, undefined, { token });
export const consumeInviteLink = (user, token) =>
  genericAPIActionCreator(() => silenceError(() => API.useInviteLink(user, token)), ACTIONS.USE_LINK, undefined, { token });
