import React from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PathwayStep from '../pathway_step';
import { StepCard, Banner } from './pathway_steps_styles';
import { formatDate } from '../../../../util/format_datetimes';

const PATHWAY_STATUS_OPTIONS = [
  { label: 'Pre Published', value: 'pre_published' },
  { label: 'Active', value: 'active' },
  { label: 'Canceled', value: 'canceled' },
  { label: 'Closed', value: 'closed' },
];

const useStyles = makeStyles(() => ({
  bannerContainer: {
    height: '70px',
    textAlign: 'right',
  },
}));

const PathwayStepsView = ({ pathwayInstance, steps, onChange }) => {
  const classes = useStyles();
  return (
    <div>
      <Grid container>
        <Grid item xs={12} lg={8}>
          <h4>{`Pathway Start Date: ${formatDate(pathwayInstance.get('startsOn'))} `}</h4>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container>
            <Grid item xs={4} md={3} lg={6} style={{ paddingRight: '20px' }}>
              <h4>Pathway Status: </h4>
            </Grid>
            <Grid item xs={4} md={3} lg={6} style={{ marginTop: '7px' }}>
              <Select
                fullWidth
                value={pathwayInstance.get('state')}
                variant="outlined"
                onChange={(e) => {
                  onChange(e.target.value);
                }}
              >
                {PATHWAY_STATUS_OPTIONS.map((statusOption) => (
                  <MenuItem key={statusOption.value} value={statusOption.value}>
                    {statusOption.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {steps &&
        steps.map((milestone) => (
          <StepCard elevation={2} key={milestone.getIn(['pathwayMilestone', 'id'])}>
            <Grid container direction="column">
              <Grid item className={classes.bannerContainer}>
                <Banner>{milestone.getIn(['pathwayMilestone', 'name'])}</Banner>
              </Grid>
              <Grid item container spacing={3} direction="column">
                {milestone.getIn(['pathwayMilestone', 'pathwaySteps']).map((step) => (
                  <PathwayStep key={step.get('id')} step={step} pathwayInstance={pathwayInstance} />
                ))}
              </Grid>
            </Grid>
          </StepCard>
        ))}
    </div>
  );
};

PathwayStepsView.propTypes = {
  pathwayInstance: PropTypes.instanceOf(Map),
  steps: PropTypes.instanceOf(List),
  onChange: PropTypes.func.isRequired,
};

PathwayStepsView.defaultProps = {
  pathwayInstance: new Map(),
  steps: new List(),
};

export default PathwayStepsView;
