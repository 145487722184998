import Axios from 'axios';
import { List, OrderedSet, Seq, Set } from 'immutable';

export const ENDPOINT = process.env.REACT_APP_API_ENDPOINT || 'http://localhost:5000';

const headers = { 'Content-Type': 'application/json', Accept: 'application/json', 'Key-Inflection': 'camel' };

if (document.head.querySelector('meta[name="csrf-token"]')) {
  headers['X-CSRF-Token'] = document.head.querySelector('meta[name="csrf-token"]').content;
}

export const client = () =>
  Axios.create({
    baseURL: ENDPOINT,
    responseType: 'json',
    headers,
  });

export const tokenAuth = (token) => `Bearer ${token}`;

const isKeyless = (data) => data instanceof List || data instanceof Set || data instanceof OrderedSet || data instanceof Seq || data instanceof Array;

function buildFormData(formData, data, parentKey) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      if (isKeyless(data)) {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[]` : key);
      } else {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      }
    });
  } else {
    const value = data == null ? '' : data;
    formData.append(parentKey, value);
  }
}

export const jsonToFormData = (data) => {
  const formData = new FormData();
  buildFormData(formData, data);
  return formData;
};

export const multipartConfig = (user) => ({
  headers: { Authorization: tokenAuth(user.authToken), 'Content-Type': 'multipart/form-data' },
});
