import { connect } from 'react-redux';
import RecommendedListingsView from './recommended_listings_view';
import { getCurrentUser, getRecommendedListingIds, getJobPosting, getTreatment } from '../../../../reducer';
import { listRecommendedPostingsForCandidate } from '../../../../solve/actions';

// .keySeq();
const mapStateToProps = (state) => {
  const currentUser = getCurrentUser(state);
  const jobIds = getRecommendedListingIds(state);
  const jobs = jobIds.map((jobId) => getJobPosting(state, jobId));
  return {
    currentUser,
    jobs,
    isRisekit: !!getTreatment(state, 'isRisekit', true),
  };
};

const mapDispatchToProps = {
  listRecommendedPostingsForCandidate,
};

const RecommendedListingsContainer = connect(mapStateToProps, mapDispatchToProps)(RecommendedListingsView);

export default RecommendedListingsContainer;
