import { merge } from 'lodash';

function failAPI(response) {
  const err = new Error(response.error);
  err.response = response;
  throw err;
}

// const PAGE_SIZE = 25;
const MAX_PAGE = 100;

const buildAggregateResponse = (previous, { result, entities, ...rest }) => ({
  result: previous.result.concat(result),
  entities: merge(previous.entities, entities),
  ...rest,
});

const fetchPage = async (action, pageNum) => {
  const response = await action(pageNum);
  if (response.error) {
    failAPI(response);
  }
  return response;
};

const fetchAllPages = async (action, pageNum = 1, prevResponse = { result: [], entities: {} }) => {
  const pageResponse = await fetchPage(action, pageNum);
  const response = buildAggregateResponse(prevResponse, pageResponse);
  if (pageResponse.result.length > 0 && pageNum < MAX_PAGE) {
    return fetchAllPages(action, pageNum + 1, response);
  }
  return response;
};

/**
 * bufferedPaginatedAPIAction waits until all pages have been retrieved, then emits a single redux update.
 * You don’t want to keep a user waiting when retrieving 20+ pages, so it’s also best done as a prefetch.
 * `paginatedAPIAction` is usually a better choice for populating UI lists (where we want to show page 1 asap
 * and then page 2, 3, …, N as soon as they’re available).
 * However, if we need to filter/sort client-side or we don’t want to ever show a partial list use this function.
 * @param action {function(pageNum)} Function that will retrieve the requested page of results
 * @returns {function(...[*]=)}
 */
const bufferedPaginatedAPIAction = (action, actionTypes, errorMessage, meta = {}, after = undefined) => async (dispatch) => {
  dispatch({ type: actionTypes.request, ...meta });
  try {
    const response = await fetchAllPages(action);
    if (response.error) {
      failAPI(response);
    }
    dispatch({
      type: actionTypes.success,
      response,
      ...meta,
    });
    if (after) {
      const afterResult = await after(dispatch, response);
      return afterResult;
    }
    return response;
  } catch (err) {
    if (err.response) {
      dispatch({
        type: actionTypes.failure,
        message: err.response.error,
        errors: err.response.error_messages,
        ...meta,
        err: err?.toString(),
      });
    } else {
      dispatch({
        type: actionTypes.failure,
        message: errorMessage || err.message || err,
        ...meta,
        err: err?.toString(),
      });
    }
    throw err;
  }
};

export default bufferedPaginatedAPIAction;
