import { connect } from 'react-redux';
import ReviewFormView from './review_form_view';
import { formValueSelector, getFormValues } from '../../../../common/form';
import { getPathway } from '../../../../reducer';

const selector = formValueSelector('pathwayForm');

const mapStateToProps = (state) => {
  const chosenPathwayId = selector(state, 'pathwayType');

  return {
    pathway: getPathway(state, chosenPathwayId),
    name: selector(state, 'pathwayName'),
    startsOn: selector(state, 'startDate'),
    description: selector(state, 'description'),
    stepDates: selector(state, 'pathwayStepDates')?.toJS() || {},
    formValues: getFormValues('pathwayForm')(state),
  };
};

const ReviewFormContainer = connect(mapStateToProps)(ReviewFormView);

export default ReviewFormContainer;
