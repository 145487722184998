import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import Grid from '@material-ui/core/Grid';
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@material-ui/icons/Add';
import EditingStyles from './editing_styles';
import { MuiThemeProvider, MuiFormTheme } from '../../../../../common/form';
import ReactSelect from '../../../../../common/form/redux_form/react_select';

const propTypes = {
  industries: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, get: PropTypes.func }).isRequired,
  industryOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

// TODO: Why are we including MuiThemeProvider at this point??
const EditingView = ({ industries, industryOptions }) => {
  const [newEntry, setNewEntry] = useState();
  const existingEntries = industries.map((name, index) => industries.get(index));
  const selectOptions = List(industryOptions)
    .filterNot((z) => existingEntries.includes(z[1]))
    .map((x) => ({ value: x[1], label: x[1] }));

  // const addToIndustries = () => {
  //   if (newEntry) {
  //     industries.push(newEntry);
  //     setNewEntry(undefined);
  //   }
  // };

  const handleOnChange = (value) => {
    industries.push(value);
    setNewEntry(value);
  };

  return (
    <MuiThemeProvider theme={MuiFormTheme}>
      <EditingStyles>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <ReactSelect input={{ name: 'addindustry', onChange: handleOnChange, value: newEntry }} options={selectOptions} />
          </Grid>
          {/* <Grid item xs={4} sm={12} md={6}>
            <IconButton disabled={!newEntry} aria-label="submit" onClick={addToIndustries} className="add-button">
              <AddIcon fontSize="small" className="add-icon" /> Add Industry
            </IconButton>
          </Grid> */}
        </Grid>
      </EditingStyles>
    </MuiThemeProvider>
  );
};

EditingView.propTypes = propTypes;
EditingView.defaultProps = defaultProps;

export default EditingView;
