import styled from 'styled-components';
import theme from '../../../common/theme';

const StatusUpdateTileStyles = styled.div`
  padding: 15px 15px 10px;
  background-color: #e8f7ff;
  text-align: center;
  border: solid 1px ${theme.color.grayLighter};
  p {
    color: ;
  }
  .status-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button {
    background: transparent;
    margin: 10px 5px 5px;
  }
`;

export default StatusUpdateTileStyles;
