import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { OrderedSet, Map } from 'immutable';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import UserModel from '../../../session/models/user_model';
import EventApplicantTile from '../event_applicant_tile';
import { Button } from '../../../common/components';
import { Banner } from '../candidates/candidates_styles';
import { fetchEventParticipantsReport } from '../../../api/real_backend';
import { useDebouncedEffect } from '../../../util/hooks';
import { downloadFileFromBlob } from '../../../util/file_operations';
import InviteApplicantTile from '../invite_applicant_tile';
import ImmediateUploadButton from '../../../common/components/immediate_upload_button';
import EventParticipantsStyles, { Header } from './event_participants_styles';
import EventParticipantsFilters from './event_participants_filters';

function useSearch(search, setSearch, loaded) {
  const [newSearch, setNewSearch] = useState(search);
  const onSearchChange = (e) => {
    e.preventDefault();
    setNewSearch(e.target.value);
  };
  useDebouncedEffect(
    () => {
      if (loaded && search !== newSearch) {
        setSearch(newSearch);
      }
    },
    500,
    [loaded, search, newSearch],
  );
  return { newSearch, onSearchChange };
}

const EventParticipantsView = ({
  user,
  eventId,
  event,
  candidateEvents,
  listFilteredCandidatesForEvent,
  loaded,
  filters,
  search,
  setSearch,
  createInvitationsFromFile,
  listInvitations,
  invitations,
  resendInvitation,
}) => {
  useEffect(() => {
    // if (!loaded) {
    const query = filters.merge({ userName: search });
    listFilteredCandidatesForEvent(user, eventId, query);
    // }
  }, [loaded, filters, search, listFilteredCandidatesForEvent, user, eventId]);

  useEffect(() => {
    if (!invitations || invitations.size === 0) {
      listInvitations(user);
    }
  }, [invitations, user, listInvitations]);

  function downloadCsvReport() {
    fetchEventParticipantsReport(user, eventId).then((response) => {
      downloadFileFromBlob(response.data, `event_participants_${eventId}.csv`);
    });
  }

  const { newSearch, onSearchChange } = useSearch(search, setSearch, loaded);

  return (
    <EventParticipantsStyles>
      <Header>
        <h2>
          Event Post:
          <span style={{ fontWeight: 'normal' }}> {event.get('name')} </span>
        </h2>
        <ImmediateUploadButton buttonLabel="+ Invite candidates from a file" onUpload={createInvitationsFromFile} margin="0 10px" />
      </Header>
      <Banner>
        Results: {candidateEvents.size} Candidates{invitations.size !== 0 ? `, ${invitations.size} Invitations` : ''}
        <Button buttonType="secondary-outline" onClick={downloadCsvReport}>
          Download Candidates Report
        </Button>
      </Banner>

      <TextField
        id="eventCandidateSearch"
        placeholder="Search for a candidate by name"
        margin="dense"
        variant="outlined"
        fullWidth
        style={{ marginBottom: 30 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={newSearch}
        onChange={onSearchChange}
      />
      <EventParticipantsFilters />

      {!candidateEvents.size && !invitations.size ? (
        <div>This Event Has No Participants</div>
      ) : (
        <React.Fragment>
          <div>
            {candidateEvents.map((candidateEvent) => (
              <EventApplicantTile key={candidateEvent.get('id')} candidateEvent={candidateEvent} />
            ))}
          </div>
          <div>
            {invitations &&
              invitations.map((invitation) => (
                <InviteApplicantTile key={invitation.get('id')} invitation={invitation} resendInvitation={resendInvitation} type="event" />
              ))}
          </div>
        </React.Fragment>
      )}
    </EventParticipantsStyles>
  );
};

EventParticipantsView.propTypes = {
  candidateEvents: PropTypes.instanceOf(OrderedSet),
  listFilteredCandidatesForEvent: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  filters: PropTypes.instanceOf(Map).isRequired,
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
  event: PropTypes.instanceOf(Map).isRequired,
  eventId: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  createInvitationsFromFile: PropTypes.func.isRequired,
  invitations: PropTypes.instanceOf(OrderedSet),
  listInvitations: PropTypes.func.isRequired,
  resendInvitation: PropTypes.func.isRequired,
};

EventParticipantsView.defaultProps = {
  candidateEvents: undefined,
  invitations: undefined,
};

export default EventParticipantsView;
