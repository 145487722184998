import React from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
// import EditableCardToggle from '../editable_card_toggle';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
// import Display from './display';
import Editing from './editing';
import ProgramsStyles from './programs_styles';
import { Card, Right } from '../../../../common/styles/layout';
import { AddButton } from '../../../../common/components/buttons';
// import ExperienceStyles from '../experience/experience_styles';

const propTypes = {
  // programs: PropTypes.instanceOf(List),
  // onSubmit: PropTypes.func.isRequired,
  // onProgramCreate: PropTypes.func.isRequired,
  // onProgramDelete: PropTypes.func.isRequired,
  fields: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, remove: PropTypes.func, length: PropTypes.number }).isRequired,
};

const defaultProps = {
  // programs: new List(),
};

const ProgramRender = ({ namespace, onDelete }) => (
  // const [editing, setEditing] = useState(false);
  // const activeView = editing ? <Editing initialValues={program.toJS()} onSubmit={onSubmit} /> : <Display program={program} />;
  // const onDelete = () => onProgramDelete(program.get('id'));
  // const onSave = () => {
  //   if (onProgress) {
  //     onProgress();
  //   }
  //   setEditing(false);
  // };
  // const onEdit = () => setEditing(true);
  // const onCancel = () => {
  //   setEditing(false);
  // };

  <Card>
    {/* <EditableCardToggle editing={editing} onDelete={onDelete} onSave={onSave} onEdit={onEdit} onCancel={onCancel} formName="education" /> */}
    {/* {activeView} */}
    <Right>
      <IconButton onClick={onDelete} aria-label="delete">
        <ClearIcon fontSize="small" />
      </IconButton>
    </Right>
    <Editing namespace={namespace} />
  </Card>
);
ProgramRender.propTypes = {
  namespace: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

ProgramRender.defaultProps = {};

const ProgramsView = ({ fields }) => (
  <ProgramsStyles>
    <h3>Programs</h3>
    {fields.map((namespace, index) => (
      <ProgramRender key={namespace} namespace={namespace} onDelete={() => fields.remove(index)} />
    ))}
    <Right>
      <AddButton onClick={() => fields.push(fromJS({ experience: {} }))}>{fields.length <= 0 ? 'Add A Program' : 'Add Another Program'}</AddButton>
    </Right>
  </ProgramsStyles>
);

// const ProgramsView = ({ programs, onSubmit, onProgramCreate, onProgramDelete }) => (
//   <ProgramsStyles>
//     <h3>Training Program (Optional)</h3>
//     {programs && programs.map((program) =>
//       <ProgramRender key={program.get('id')} program={program} onSubmit={onSubmit} onProgramDelete={onProgramDelete} />)}
//     <Right>
//       <AddButton onClick={onProgramCreate}>Add a program</AddButton>
//     </Right>
//   </ProgramsStyles>
// );

ProgramsView.propTypes = propTypes;
ProgramsView.defaultProps = defaultProps;

export default ProgramsView;
