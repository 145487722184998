import React from 'react';
import { startCase } from 'lodash';
import { List } from 'immutable';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import BackgroundsDisplayStyles from './backgrounds_display_styles';

const propTypes = {
  backgrounds: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

const BackgroundsDisplayView = ({ backgrounds }) => (
  <BackgroundsDisplayStyles>
    <div className="field">
      <div className="backgrounds-container">
        {backgrounds &&
          backgrounds
            .map((_, idx) => backgrounds.get(idx).get('background'))
            .map((background, idx) => (
              <span key={background.get('slug') || background.get('name')} className="value backgrounds">
                {startCase(background.get('name'))}
                <IconButton onClick={() => backgrounds.remove(idx)} aria-label="delete">
                  <ClearIcon fontSize="small" />
                </IconButton>
              </span>
            ))}
      </div>
    </div>
  </BackgroundsDisplayStyles>
);

BackgroundsDisplayView.propTypes = propTypes;
BackgroundsDisplayView.defaultProps = defaultProps;

export default BackgroundsDisplayView;
