import styled from 'styled-components';
// import theme from '../../../common/theme';

const OrganizationsSelectorStyles = styled.div`
  .header {
    padding: 40px;
    background-color: #e4f3fc;
    border-radius: 5px;
    h4 {
      margin: 0;
      margin-bottom: 10px;
    }
  }
  h1 {
    margin-bottom: -20px;
  }
  .organization-list {
    margin-top: 20px;
  }
`;

export const ResultsPageNavigator = styled.div`
  display: flex;
  align-content: flex-start;
  margin: 12px 0px 16px;
  .prev-next-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    & > span {
      margin: 0px 12px;
    }
  }
`;

export default OrganizationsSelectorStyles;
