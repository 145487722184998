import { connect } from 'react-redux';
import { OrderedSet } from 'immutable';

import JobListView from './job_list_view';

import {
  getCurrentUser,
  getStaffJobListingsLoaded,
  getStaffJobListingsFilters,
  getStaffJobListingZipCode,
  getStaffJobListingsSearch,
  getEnumsForFormSelect,
  getStaffJobListingIds,
  getStaffTotalIndeedJobs,
  getStaffTotalJobs,
  getStaffOrgJobIds,
  getStaffOrgJobsLoaded,
  getStaffTotalOrgJobs,
  getStaffIndeedJobListingIds,
} from '../../../reducer';
import {
  listFilteredJobPostingsForStaff,
  listFilteredJobPostingsForStaffOrg,
  setStaffJobListingsSearch,
  listFilteredIndeedJobPostingsForStaff,
  removeFilteredIndeedJobPostingsForStaff,
} from '../../../solve/actions';

const mapStateToProps = (state, { mode }) => {
  let standardJobs;
  let loaded;
  let totalStandardJobs = 0;
  let totalIndeedJobs = 0;
  if (mode === 'all') {
    standardJobs = getStaffJobListingIds(state);
    totalStandardJobs = +getStaffTotalJobs(state);
    loaded = getStaffJobListingsLoaded(state);
  } else {
    standardJobs = getStaffOrgJobIds(state);
    totalStandardJobs = +getStaffTotalOrgJobs(state);
    loaded = getStaffOrgJobsLoaded(state);
  }
  totalIndeedJobs = +getStaffTotalIndeedJobs(state);
  const indeedJobs = getStaffIndeedJobListingIds(state);

  let allJobs = OrderedSet();

  if (mode !== 'myOrg') {
    allJobs = standardJobs && standardJobs.map((id) => ({ id, type: 'job' })).union(indeedJobs.map((id) => ({ id, type: 'indeed-job' })));
  } else {
    allJobs = standardJobs && standardJobs.map((id) => ({ id, type: 'job' }));
  }

  const totalJobCount = mode !== 'myOrg' ? totalStandardJobs + totalIndeedJobs : totalStandardJobs;

  return {
    currentUser: getCurrentUser(state),
    jobs: allJobs,
    loaded,
    totalStandardJobs,
    totalIndeedJobs,
    filters: getStaffJobListingsFilters(state),
    search: getStaffJobListingsSearch(state),
    zipCode: getStaffJobListingZipCode(state) || getCurrentUser(state).zip || '10001',
    distanceFilter: getEnumsForFormSelect(state, 'DistanceType'),
    jobTitleFilter: getEnumsForFormSelect(state, 'JobType'),
    industryTypeFilter: getEnumsForFormSelect(state, 'IndustryType'),
    shiftTitleFilter: getEnumsForFormSelect(state, 'ShiftType'),
    salaryFilter: getEnumsForFormSelect(state, 'PayLevelType'),
    totalJobCount,
  };
};

const mapDispatchToProps = {
  listFilteredJobPostingsForStaff,
  listFilteredJobPostingsForStaffOrg,
  listFilteredIndeedJobPostingsForStaff,
  removeFilteredIndeedJobPostingsForStaff,
  setSearch: setStaffJobListingsSearch,
};

const JobListContainer = connect(mapStateToProps, mapDispatchToProps)(JobListView);

export default JobListContainer;
