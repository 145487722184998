import styled from 'styled-components';
// import theme from '../../../common/theme';

const JobListStyles = styled.div`
  .header {
    margin: 0px -50px 0px;
    padding: 0px 50px 35px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px 14px 40px 0 rgba(0, 0, 0, 0.05);
  }

  .posting-wrapper {
    margin: 5px -50px -50px;
    padding: 15px 60px;
    background-color: #f7f7f7;
    min-height: 100vh;
  }

  @media only screen and (max-width: 768px) {
    .header {
      margin: 0px -25px 0px;
      padding: 0px 25px 35px;
    }
    .posting-wrapper {
      margin: 5px -25px -25px;
      padding: 15px 25px;
    }
  }
`;

export const ResultsPageNavigator = styled.div`
  display: flex;
  align-content: flex-start;
  margin: 12px 0px 16px;
  .prev-next-container {
    display: flex;
    align-items: center;
    margin-left: auto;
    & > span {
      margin: 0px 12px;
    }
  }
`;

export default JobListStyles;
