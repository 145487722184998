/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Iterable } from 'immutable';
import moment from 'moment';

import TileStyles, { Header, Content, Actions } from './event_applicant_tile_styles';

import { TextHeading, Button } from '../../../common/components';

import InteractiveStepper from '../../../ui/components/interactive_stepper';
import { eventStaffStatusEnums } from '../../../enums/staff_status_enums';
import CandidateEventModel from '../../../solve/models/candidate_event_model';
import formatPhoneNumber from '../../../util/format_phone_number';
import StatusUpdateTile from '../../../solve/components/status_update_tile';

const { initial, registered, reminded, attended } = eventStaffStatusEnums;

const candidateEventMapping = {
  [initial]: -1,
  [registered]: 0,
  [reminded]: 1,
  [attended]: 2,
};

const eventSteps = (candidateEvent) => [
  {
    label: (
      <div>
        Registered
        <br />
        {candidateEvent.get('registeredAt') && <b>{moment(candidateEvent.get('registeredAt')).format('Do MMM YY')}</b>}
      </div>
    ),
    value: registered,
  },
  {
    label: (
      <div>
        Reminded
        <br />
        {candidateEvent.get('remindedAt') && <b>{moment(candidateEvent.get('remindedAt')).format('Do MMM YY')}</b>}
      </div>
    ),
    value: reminded,
  },
  {
    label: (
      <div>
        Attended
        <br />
        {candidateEvent.get('confirmedAttendedAt') && <b>{moment(candidateEvent.get('confirmedAttendedAt')).format('Do MMM YY')}</b>}
      </div>
    ),
    value: attended,
  },
];

const EventApplicantTileView = ({ candidateEvent, updateStaffStatus }) => {
  const candidate = candidateEvent.getIn(['candidate', 'user']);
  const candidateApplicationId = candidateEvent.get('id');
  const eventId = candidateEvent.getIn(['event', 'eventId']);
  const staffState = candidateEvent.get('staffState');
  const [activeStep, setActiveStep] = useState(candidateEventMapping[staffState]);
  return (
    <React.Fragment>
      <StatusUpdateTile record={candidateEvent} setStaffState={(state) => updateStaffStatus(`s_${state}`)} />
      <TileStyles>
        <Header>
          <div>
            {/* <Button className="blank delete">Delete applicant</Button> */}
            <TextHeading size="medium" weight="bold">{`${candidate.get('firstName')} ${candidate.get('lastName')}`}</TextHeading>
          </div>
          <div className="blank">{formatPhoneNumber(candidate.get('phone'))}</div>
          {/* <Button buttonType="text" className="blank" size="small">
          Application Questions
        </Button> */}
          <Actions>
            <Link to={`/staff/messages/new/${candidateEvent.getIn(['candidate', 'candidateId'])}`}>
              <Button buttonType="secondary-outline" className="button">
                Send Message
              </Button>
            </Link>
            <Link to={`/staff/events/${eventId}/participants/${candidateApplicationId}`}>
              <Button buttonType="secondary-outline" className="button">
                View
              </Button>
            </Link>
          </Actions>
        </Header>
        <Content>
          <InteractiveStepper steps={eventSteps(candidateEvent)} activeStep={activeStep} setActiveStep={setActiveStep} onChange={updateStaffStatus} fullWidth />
        </Content>
      </TileStyles>
    </React.Fragment>
  );
};

EventApplicantTileView.propTypes = {
  candidateEvent: PropTypes.oneOfType([PropTypes.instanceOf(CandidateEventModel), PropTypes.instanceOf(Iterable)]).isRequired,
  updateStaffStatus: PropTypes.func.isRequired,
};

EventApplicantTileView.defaultProps = {};

export default EventApplicantTileView;
