import { reduxForm } from '../../../common/form';
import DefyFormView from './defy_form_view';

const validate = (inmate) => {
  const errors = {};

  if (inmate) {
    if (inmate.get('ssn')) {
      if (!inmate.get('ssn').match('[0-9]{3}-?[0-9]{2}-?[0-9]{4}')) {
        errors.ssn = 'Invalid SSN Number';
      }
    }
  }

  return errors;
};

const defyFormForm = reduxForm({
  form: 'defy',
  validate,
  enableReinitialize: true,
})(DefyFormView);

export default defyFormForm;
