/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import theme from '../../../../common/theme';

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0px;
  margin-top: 24px;
  & th {
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.1em;
    color: ${theme.color.text.medium};
    text-align: left;
  }
`;
