/* eslint-disable react/jsx-props-no-spreading */
// https://material-ui.com/components/tabs/
import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import theme from '../../../common/theme';

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      // {...other}
    >
      <Box>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

TabPanel.defaultProps = {
  children: undefined,
};

const StyledTabs = withStyles({
  root: {
    flex: 1,
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: 6,
    '& > div': {
      width: '100%',
      backgroundColor: '#1C94E0',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(() => ({
  root: {
    flex: 1,
    backgroundColor: '#EAEAEA',
    color: '#9F9F9F',
    fontSize: 15,
    boxShadow: 'none',
    letterSpacing: 0,
    maxWidth: 'none',
    minHeight: 55,
    opacity: 1,
    overflow: 'initial',
    padding: theme.padding.small,
    '&:focus': {
      backgroundColor: '#1C94E0',
      color: '#fff',
      opacity: 1,
    },
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      right: 10,
      top: 10,
      marginLeft: 10,
      position: 'relative',
    },
    '& .MuiBadge-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
}));

export default function TabberSolidView({ children }) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const validChildren = typeof children.filter === 'function' ? children.filter((child) => child) : List([children]);

  function handleChange(event, newValue) {
    setValue(newValue);
  }

  return (
    <div className={classes.root}>
      <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
        {validChildren.map((child, i) => (
          <StyledTab
            key={child.props.tabLabel}
            label={
              <Badge className={classes.padding} color="error" badgeContent={child.props.badge}>
                {child.props.tabLabel}
              </Badge>
            }
            {...a11yProps(i)}
          />
        ))}
      </StyledTabs>
      {validChildren.map((child, i) => (
        <TabPanel key={child.props.tabLabel} value={value} index={i}>
          {child}
        </TabPanel>
      ))}
    </div>
  );
}

TabberSolidView.propTypes = {
  children: PropTypes.node,
};

TabberSolidView.defaultProps = {
  children: undefined,
};
