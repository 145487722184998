import { fromJS, OrderedSet } from 'immutable';
import ACTIONS from '../actions';

const EMPTY_SET = OrderedSet();

const DEFAULT_STATE = fromJS({
  filters: {},
  candidatesApplicationsLoading: false,
  candidatesApplicationsLoaded: 0,
  candidatesApplicationsList: EMPTY_SET,

  candidatesExternalApplicationsLoading: false,
  candidatesExternalApplicationsLoaded: 0,
  candidatesExternalApplicationsList: EMPTY_SET,
});

const StaffCandidateApplicationsReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case ACTIONS.SET_FILTERED_CANDIDATES_APPLICATIONS_FILTERS:
      if (state.get('filters') !== action.filters) {
        return state.merge({
          candidatesApplicationsLoaded: false,
          filters: action.filters,
        });
      }
      return state;
    case ACTIONS.SET_FILTERED_CANDIDATES_APPLICATIONS_SEARCH:
      // TODO: should we just do this client-side?
      if (state.get('search') !== action.search) {
        return state.merge({
          candidatesApplicationsLoaded: false,
          search: action.search,
        });
      }
      return state;

    case ACTIONS.LIST_FILTERED_CANDIDATES_APPLICATIONS.request:
      return state.merge({
        candidatesApplicationsLoading: true,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_APPLICATIONS.success:
      return state.merge({
        candidatesApplicationsList: OrderedSet(action.response.result),
        candidatesApplicationsLoading: false,
        candidatesApplicationsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_APPLICATIONS.failure:
      return state.merge({
        candidatesApplicationsLoading: false,
        candidatesApplicationsLoaded: false,
        candidatesApplicationsList: EMPTY_SET,
      });

    case ACTIONS.LIST_FILTERED_CANDIDATES_EXTERNAL_APPLICATIONS.request:
      return state.merge({
        candidatesExternalApplicationsLoading: true,
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_EXTERNAL_APPLICATIONS.success:
      return state.merge({
        candidatesExternalApplicationsList: OrderedSet(action.response.result),
        candidatesExternalApplicationsLoading: false,
        candidatesExternalApplicationsLoaded: Date.now(),
      });
    case ACTIONS.LIST_FILTERED_CANDIDATES_EXTERNAL_APPLICATIONS.failure:
      return state.merge({
        candidatesExternalApplicationsLoading: false,
        candidatesExternalApplicationsLoaded: false,
        candidatesExternalApplicationsList: EMPTY_SET,
      });

    default:
      return state || DEFAULT_STATE;
  }
};

const RELOAD_TIMEOUT = 60 * 1000; // 60 seconds

export const getFilters = (state) => state.get('filters') || DEFAULT_STATE.get('filters');
export const getSearch = (state) => state.get('search');

export const getCandidateApplicationLoaded = (state) => state.get('candidatesApplicationsLoaded ') + RELOAD_TIMEOUT > Date.now();
export const getCandidateApplicationIds = (state) => state.get('candidatesApplicationsList') || DEFAULT_STATE.get('candidatesApplicationsList');
export const getCandidateExternalApplicationIds = (state) =>
  state.get('candidatesExternalApplicationsList') || DEFAULT_STATE.get('candidatesExternalApplicationsList');

export default StaffCandidateApplicationsReducer;
