import { connect } from 'react-redux';

// eslint-disable-next-line import/no-unresolved
import { PathwayList } from 'risekit/pathways';

// Previous list view
// import PathwayListView from './pathway_list_view';

import { listPathwayInstances } from '../../../../solve/actions';
import { getCurrentUser, getPathwayInstances } from '../../../../reducer';

const mapStateToProps = (state) => ({
  user: getCurrentUser(state),
  pathwayInstances: getPathwayInstances(state),
  organizationId: getCurrentUser(state).getIn(['staffOrganization', 'id']),
});

const mapDispatchToProps = {
  listPathwayInstances,
};

const PathwayListContainer = connect(mapStateToProps, mapDispatchToProps)(PathwayList);

export default PathwayListContainer;
