import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ConfirmEmailView from './confirm_email_view';
import { confirmEmail } from '../../actions';
import { getCurrentUser } from '../../../reducer';

const mapStateToProps = (
  state,
  {
    match: {
      params: { token },
    },
  },
) => ({
  currentUser: getCurrentUser(state),
  token,
});

const mapDispatchToProps = {
  confirmEmail,
};

const ConfirmEmailContainer = connect(mapStateToProps, mapDispatchToProps)(ConfirmEmailView);

ConfirmEmailContainer.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ token: PropTypes.string.isRequired }).isRequired }).isRequired,
};

export default ConfirmEmailContainer;
