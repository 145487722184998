/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { OrderedSet, has } from 'immutable';

import numeral from 'numeral';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import ScheduleIcon from '@material-ui/icons/Schedule';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import {
  MainStyles,
  MainCard,
  Body,
  // Status,
  MobileView,
  DesktopView,
  MainColumn,
  Logo,
  Pill,
  Title,
  Organization,
  MetaCategory,
  MetaGroup,
  MetaSubGroup,
  MetaLabel,
  MetaValue,
  MetaIcon,
  SideBar,
  MetaHeading,
} from '../../../common/styles/detail';
import Button from '../../../common/components/button/index';
import UserModel from '../../../session/models/user_model';
import { useFetchOnce } from '../../../util/hooks';
import Spinner from '../../../common/components/spinner';
// import MyStatus from '../../../programs/components/my_status';
import theme from '../../../common/theme';

const propTypes = {
  id: PropTypes.string.isRequired,
  service: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    estimatedSalary: PropTypes.string,
    gainedSkillTypes: PropTypes.string,
    distance: PropTypes.string,
    applicationMethod: PropTypes.string,
    industryType: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    programsOffered: PropTypes.arrayOf(PropTypes.string),
    attributeTags: PropTypes.arrayOf(PropTypes.string),
    supportTypes: PropTypes.arrayOf(PropTypes.string),
    cost: PropTypes.string,
    dressCode: PropTypes.string,
    get: PropTypes.func,
    getIn: PropTypes.func,
  }).isRequired,
  currentUser: PropTypes.instanceOf(UserModel).isRequired,
  externalService: PropTypes.shape({
    get: PropTypes.func,
    externalStatus: PropTypes.string,
  }),
  fetchService: PropTypes.func.isRequired,
  listExternalCandidateServices: PropTypes.func.isRequired,
  serviceTypes: PropTypes.instanceOf(OrderedSet).isRequired,
  targetTypes: PropTypes.instanceOf(OrderedSet).isRequired,
};

const defaultProps = {
  externalService: undefined,
};

const ServiceView = ({ externalService, id, service, fetchService, currentUser, serviceTypes, targetTypes, listExternalCandidateServices }) => {
  const resolved = useFetchOnce(currentUser, id, fetchService, service);
  useFetchOnce(currentUser, currentUser.get('candidateId'), listExternalCandidateServices, externalService);
  const externalStatus = externalService && externalService.get('externalStatus');
  const candidateState = externalService && externalService.get('candidateState');
  const applied = ![undefined, 'c_deleted', 'c_saved'].includes(candidateState);

  if (!service) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  let logoUrl;
  const aBService = has(service, 'attributeTags');
  if (aBService) {
    logoUrl = '/images/aunt-bertha-logo.png';
  } else {
    logoUrl = service.get('logoUrl');
    if (!logoUrl) {
      logoUrl = service.getIn(['postingOrganization', 'logoUrl']);
    }
  }

  return (
    <MainStyles>
      <div className="candidate-wrapper">
        <Link to="/candidate/resources">
          <Button buttonType="back" size="small">
            <KeyboardBackspaceIcon />
            Back to all resources
          </Button>
        </Link>
      </div>
      {service && (
        <MainCard className="candidate-wrapper">
          <Logo>
            <img src={logoUrl} alt="Service Logo" />
          </Logo>

          <MobileView>
            <Pill>Supportive Service</Pill>
            <Title>{service.get('name')}</Title>
            <Organization>{service.getIn(['postingOrganization', 'name'])}</Organization>
          </MobileView>

          <Body applied={applied}>
            <MainColumn>
              <DesktopView>
                <Pill>Supportive Service</Pill>
                <Title>{service.get('name')}</Title>
                <Organization>{aBService ? service.get('providerName') : service.getIn(['postingOrganization', 'name'])}</Organization>
              </DesktopView>

              <MetaCategory>
                <MetaLabel>Description</MetaLabel>
                <MetaValue>
                  <div dangerouslySetInnerHTML={{ __html: service.get('description') }} />
                </MetaValue>
              </MetaCategory>
            </MainColumn>

            {aBService ? (
              <SideBar>
                {externalStatus && (
                  <Button buttonType="secondary" fullWidth className="button" disabled>
                    {externalService.get('externalStatus') === 'initialized' ? <Spinner /> : externalService.get('externalStatus')}
                  </Button>
                )}
                {!applied && (
                  <Link to={`/candidate/services/${service.get('id')}/apply`}>
                    <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action }}>
                      Apply to service
                    </Button>
                  </Link>
                )}
                {service.get('offices') ?
                  service.get('offices').map((office) => (
                    <MetaGroup key={office.get('urlSafeKey')}>
                      <MetaIcon>
                        <LocationOnOutlinedIcon />
                      </MetaIcon>
                      <MetaSubGroup>
                        <MetaValue>{office.get('address1')}</MetaValue>
                        {office.get('address2') ? <MetaValue>{office.get('address2')}</MetaValue> : ''}
                        <MetaValue>{`${office.get('city')}, ${office.get('state')}`}</MetaValue>
                        <MetaValue>{`${office.getIn(['openNowInfo', 'openNow']) ? 'Open now' : 'Closed'}`}</MetaValue>
                        <MetaValue>{`Opens: ${office.getIn(['openNowInfo', 'openTime'])}, Closes: ${office.getIn(['openNowInfo', 'closeTime'])}`}</MetaValue>
                      </MetaSubGroup>
                    </MetaGroup>
                  )) :
                  ''}
                {service.get('attributeTags') && (
                  <MetaGroup>
                    <MetaIcon>
                      <PersonOutlineOutlinedIcon />
                    </MetaIcon>
                    <MetaValue>
                      {service
                        .get('attributeTags')
                        .map((item) => item)
                        .join(', ')}
                    </MetaValue>
                  </MetaGroup>
                )}
              </SideBar>
            ) : (
              <SideBar>
                {!applied && (
                  <Link to={`/candidate/services/${service.get('id')}/apply`}>
                    <Button buttonType="secondary" fullWidth className="button">
                      Apply to Service <ChevronRightIcon />
                    </Button>
                  </Link>
                )}
                {service.get('cost') && (
                  <MetaGroup>
                    <MetaIcon>
                      <AttachMoneyIcon />
                    </MetaIcon>
                    <MetaValue>
                      <MetaHeading>Cost:</MetaHeading>
                      {numeral(service.get('cost')).format('0.00')}
                    </MetaValue>
                  </MetaGroup>
                )}
                {service.getIn(['address', 'address1']) ? (
                  <MetaGroup>
                    <MetaIcon>
                      <LocationOnOutlinedIcon />
                    </MetaIcon>
                    <MetaSubGroup>
                      <MetaValue>
                        <MetaHeading>Address:</MetaHeading>
                      </MetaValue>
                      <MetaValue>{service.getIn(['address', 'address1'])}</MetaValue>
                      {service.getIn(['address', 'address2']) ? <MetaValue>{service.getIn(['address', 'address2'])}</MetaValue> : ''}
                      <MetaValue>
                        {`${service.getIn(['address', 'city'])}, ${service.getIn(['address', 'state'])} ${service.getIn(['address', 'zip'])}`}
                      </MetaValue>
                    </MetaSubGroup>
                  </MetaGroup>
                ) : (
                  <MetaGroup>
                    <MetaIcon>
                      <LocationOnOutlinedIcon />
                    </MetaIcon>
                    <MetaValue>
                      {' '}
                      <MetaHeading>Address:</MetaHeading>
                      {service.getIn(['address', 'zip'])}
                    </MetaValue>
                  </MetaGroup>
                )}
                {targetTypes && (
                  <MetaGroup>
                    <MetaIcon>
                      <PersonOutlineOutlinedIcon />
                    </MetaIcon>
                    <MetaValue>
                      <MetaHeading>Serving:</MetaHeading>
                      {targetTypes.map((item) => item[1]).join(', ')}
                    </MetaValue>
                  </MetaGroup>
                )}
                {serviceTypes && (
                  <MetaGroup>
                    <MetaIcon>
                      <LabelOutlinedIcon />
                    </MetaIcon>
                    <MetaValue>
                      <MetaHeading>Services Provided:</MetaHeading>
                      {serviceTypes.map((item) => item[1]).join(', ')}
                    </MetaValue>
                  </MetaGroup>
                )}
              </SideBar>
            )}
          </Body>
        </MainCard>
      )}
    </MainStyles>
  );
};

ServiceView.propTypes = propTypes;
ServiceView.defaultProps = defaultProps;

export default ServiceView;
