// import isAfter from 'date-fns/isAfter';
// import isValid from 'date-fns/isValid';
import validator from 'validator';

// const REQUIRED = 'Required';

const emailValidationMapper = (address) => (!address || validator.isEmail(address) ? undefined : 'Invalid email address');

const organizationInviteValidate = (values) => {
  if (values.get('invite')) {
    const invite = values.get('invite');
    const errors = invite.map((value, key) => {
      if (key === 'other') {
        if (!value || value.size === 0 || !value.get(0)) {
          return ['missing'];
        }
        return value.map(emailValidationMapper);
      }
      return emailValidationMapper(value);
    });
    return { invite: errors.toJS() };
  }
  return {};
};

export default organizationInviteValidate;
