import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';

import theme from '../../../../common/theme';
import { StyledTableRow } from './pathway_row_styles';

const PathwayRowView = ({ pathwayInstance, candidatePathwayInstanceId }) => (
  <StyledTableRow>
    <td>
      <img src="/icons/color/skills.png" height="35" alt="Job Posting Logo" style={{ margin: 12 }} />
    </td>
    <td>
      <h4>{pathwayInstance.get('name')}</h4>
    </td>
    <td>
      <Link to={`/candidate/pathways/${candidatePathwayInstanceId}`}>
        <Button variant="contained" color="secondary" size="small" style={{ backgroundColor: theme.color.secondary }}>
          View
        </Button>
      </Link>
    </td>
  </StyledTableRow>
);

PathwayRowView.propTypes = {
  pathwayInstance: PropTypes.instanceOf(Map).isRequired,
  candidatePathwayInstanceId: PropTypes.string.isRequired,
};
PathwayRowView.defaultProps = {};

export default PathwayRowView;
