import { schema } from 'normalizr';

export const candidate = new schema.Entity('candidates', {});
export const candidateOrganization = new schema.Entity('candidateOrganizations', {});
export const candidateApplication = new schema.Entity('candidateApplications', {});
export const indeedCandidateApplication = new schema.Entity('indeedCandidateApplications', {});
export const candidateEvent = new schema.Entity('candidateEvents', {});
export const candidateProgram = new schema.Entity('candidatePrograms', {});
export const candidateService = new schema.Entity('candidateServices', {});
export const externalCandidateService = new schema.Entity('externalCandidateServices', {});
export const candidateSurvey = new schema.Entity('candidateSurveys', {});
export const jobPosting = new schema.Entity('jobPostings', {});
export const indeedJobPosting = new schema.Entity('indeedJobPostings', {}, { idAttribute: (value) => value.jobkey });
export const organization = new schema.Entity('organizations', {});
export const resume = new schema.Entity('resumes', {});
export const challenge = new schema.Entity('challenges', {});
export const globalSurvey = new schema.Entity('globalSurveys', {});
export const survey = new schema.Entity('surveys', {});
export const program = new schema.Entity('programs', {});
export const supportiveService = new schema.Entity('services', {});
export const invitation = new schema.Entity('invitations', {});
export const inmate = new schema.Entity('inmates', {});
export const event = new schema.Entity('events', {});
export const staff = new schema.Entity('staff', {});
export const message = new schema.Entity('messages', {});
export const note = new schema.Entity('notes', {});
export const notification = new schema.Entity('notifications', {});
export const systemEnum = new schema.Entity('systemEnums', {}, { idAttribute: (value) => `${value.enumName}/${value.slug}` });
export const publicLink = new schema.Entity('publicLinks', {});
export const inviteLink = new schema.Entity('inviteLinks', {});

export const pathway = new schema.Entity('pathways', {});
export const pathwayInstance = new schema.Entity('pathwayInstances', { pathway });
export const candidatePathway = new schema.Entity('candidatePathways', { pathwayInstance });
export const pathwayInstanceStep = new schema.Entity('pathwayInstanceSteps', {});
