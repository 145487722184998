import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS, List } from 'immutable';
import Grid from '@material-ui/core/Grid';
// import { Autocomplete } from '@material-ui/core/FormControl';
// import IconButton from '@material-ui/core/IconButton';
// import AddIcon from '@material-ui/icons/Add';
// import VisibilityOff from '@material-ui/icons/VisibilityOff';
import TextField from '@material-ui/core/TextField';
import SkillsEditingStyles from './skills_editing_styles';
import { MuiThemeProvider, MuiFormTheme } from '../../../../../common/form';
import Button from '../../../../../common/components/button/index';
// import ReactSelect from '../../../../../common/form/redux_form/react_select';

const Autocomplete = ({ suggestions, newEntry, setNewEntry, onSubmit }) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(newEntry);
  const onChange = (e) => {
    e.preventDefault();
    const newUserInput = e.currentTarget.value;
    setUserInput(newUserInput);
    const newFilteredSuggestions = suggestions.toJS().filter((suggestion) => suggestion.value.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
    setFilteredSuggestions(newFilteredSuggestions);
    setShowSuggestions(true);
  };

  const onClick = (e) => {
    setShowSuggestions(false);
    setUserInput('');
    onSubmit({ skill: { name: e.currentTarget.innerText, slug: e.currentTarget.getAttribute('slug') } });
    setFilteredSuggestions([]);
  };

  const addNewEntry = () => {
    let name;
    let slug;
    const selectedSuggestion = filteredSuggestions.filter((x) => x.label === userInput)[0];
    if (selectedSuggestion) {
      name = selectedSuggestion.label;
      slug = selectedSuggestion.slug;
      setUserInput(name);
      setNewEntry(name);
    } else {
      name = userInput;
      setUserInput('');
      setNewEntry('');
    }

    onSubmit({ skill: { name, slug } });
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      addNewEntry();
    }
  };

  let suggestionsListComponent;
  let suggestionsButtonComponent;

  if (userInput) {
    if (showSuggestions && filteredSuggestions.length) {
      suggestionsListComponent = (
        <ul className="skill-suggestions">
          {filteredSuggestions.map((suggestion) => (
            <li key={suggestion.value} slug={suggestion.slug} role="presentation" onClick={onClick} onKeyDown={onKeyDown}>
              {suggestion.label}
            </li>
          ))}
        </ul>
      );
    } else {
      suggestionsListComponent = <p className="no-suggestion">Option not found, press return to add.</p>;
      suggestionsButtonComponent = (
        <Button buttonType="subtle" onClick={addNewEntry} style={{ marginTop: '30px', marginLeft: '10px' }}>
          Add
        </Button>
      );
    }
  }

  const hideSuggestions = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  return (
    <Fragment>
      <TextField
        id="skills"
        label="Add skills"
        type="text"
        margin="dense"
        variant="outlined"
        fullWidth
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={userInput}
        onBlur={hideSuggestions}
        InputLabelProps={{
          shrink: false,
        }}
        style={{ flex: 1 }}
      />
      {suggestionsButtonComponent}
      {suggestionsListComponent}
    </Fragment>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(List),
  setNewEntry: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  newEntry: PropTypes.string,
};

Autocomplete.defaultProps = {
  suggestions: [],
  newEntry: '',
};

const propTypes = {
  skills: PropTypes.shape({ map: PropTypes.func, push: PropTypes.func, get: PropTypes.func }).isRequired,
  skillOptions: PropTypes.instanceOf(List).isRequired,
};

const defaultProps = {};

// TODO: Why are we including MuiThemeProvider at this point??
const SkillsEditingView = ({ skills, skillOptions }) => {
  const [newEntry, setNewEntry] = useState();
  const existingEntries = skills.map((name, index) => skills.get(index).get('skill').get('name'));
  const selectOptions = List(skillOptions)
    .filterNot((z) => existingEntries.includes(z[1]))
    .map((x) => ({ value: x[1], label: x[1], slug: x[0] }));

  // const handleInputChange = (e) => {
  //   e.preventDefault();
  //   setNewEntry(e.target.value);
  // };
  const addToSkills = (e) => {
    skills.push(fromJS(e));
    setNewEntry(undefined);
  };

  return (
    <MuiThemeProvider theme={MuiFormTheme}>
      <SkillsEditingStyles>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={4} style={{ display: 'flex' }}>
            {/* <TextField */}
            {/*  id="skills" */}
            {/*  label="Add skills" */}
            {/*  type="test" */}
            {/*  margin="dense" */}
            {/*  variant="outlined" */}
            {/*  fullWidth */}
            {/*  onChange={handleInputChange} */}
            {/*  onSubmit={addToSkills} */}
            {/*  mask="ddddd" */}
            {/*  value={newEntry || ''} */}
            {/*  InputLabelProps={{ */}
            {/*    shrink: false, */}
            {/*  }} */}
            {/* /> */}
            <Autocomplete onSubmit={addToSkills} setNewEntry={setNewEntry} newEntry={newEntry} suggestions={selectOptions} />
          </Grid>
          {/* <Grid item xs={4} sm={12} md={6}>
            <IconButton disabled={!newEntry} aria-label="submit" onClick={addToSkills} className="add-button">
              <AddIcon className="add-icon" /> Add Skill
            </IconButton>
          </Grid> */}
        </Grid>
      </SkillsEditingStyles>
    </MuiThemeProvider>
  );
};

SkillsEditingView.propTypes = propTypes;
SkillsEditingView.defaultProps = defaultProps;

export default SkillsEditingView;
