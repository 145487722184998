export const urls = {
  pathWay: {
    GENERATE_PATHWAY_SUMMARY_REPORT: '/candidate_pathway_instances/pathway_summary_report',
    LIST_PATHWAY_INSTANCES: '/organizations/:orgId/pathway_instances',
  },
  externalJob: {
    GET_INDEED_JOB: 'indeed/show/:indeedJobId',
  },
  reports: {
    GET_MODE_REPORTS: 'reports',
    GET_MODE_REPORT_SIGNED_URL: 'reports/signed_url',
  }
};
