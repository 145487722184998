import actions from '../actions';
import SolveActions from '../../solve/actions';
import UserModel from '../models/user_model';

const DEFAULT_STATE = new UserModel();

// This reducer is responsible for tracking the identity of the user
function UserReducer(state = DEFAULT_STATE, action) {
  let candidate;
  let staff;
  // return DEFAULT_STATE; // for auth testing
  switch (action.type) {
    case actions.RECOGNIZE_USER:
      return new UserModel(action.user);
    case actions.SIGN_IN.success:
    case actions.FINISH_SIGN_IN.success:
    case actions.REGISTER.success:
    case actions.REFRESH_AUTH.success:
    case actions.UPDATE_CURRENT_USER.success:
      return state.merge(action.response.data);
    case actions.CONFIRM_EMAIL.success:
      return state.merge({ emailValidatedAt: Date.now() });
    case actions.SIGN_OUT:
      return DEFAULT_STATE;
    case SolveActions.CREATE_CANDIDATE.success:
      candidate = action.response.entities.candidates[action.response.result];
      return state.merge(candidate.user || {}).merge({ candidateId: action.response.result });
    case SolveActions.CREATE_STAFF_MEMBER.success:
      staff = action.response.entities.staff[action.response.result];
      if (staff.user) {
        return state.merge(staff.user || {}).merge({ staffId: action.response.result, staffOrganization: staff.organization });
      }
      return state;
    case SolveActions.FETCH_STAFF_MEMBER.success:
    case SolveActions.UPDATE_STAFF_MEMBER.success:
      staff = action.response.entities.staff[action.response.result];
      if (state.get('staffId') === staff.id && staff.user) {
        return state.merge(staff.user);
      }
      return state;
    default:
      return state;
  }
}

export default UserReducer;
