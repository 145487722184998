import styled from 'styled-components';
import theme from '../../../../common/theme';

export const StepCard = styled.div`
  padding: 36px;
  position: relative;
  margin: 20px;
  background-color: #f7f7f7;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
`;

export const Banner = styled.div`
  position: absolute;
  top: 24px;
  left: -10px;
  background-color: ${theme.color.blueDark};
  color: ${theme.color.white};
  padding: 8px 16px;
  font-weight: 600;
  box-shadow: 2px 2px 5px ${theme.color.gray};
`;
