import { connect } from 'react-redux';
import SetScheduleFormView from './set_schedule_form_view';
import { formValueSelector } from '../../../../common/form';
import { fetchPathway } from '../../../../solve/actions';
import { getPathway, getCurrentUser } from '../../../../reducer';

const selector = formValueSelector('pathwayForm');

const mapStateToProps = (state) => {
  const chosenPathwayId = selector(state, 'pathwayType');

  return {
    chosenPathwayId,
    currentUser: getCurrentUser(state),
    pathway: getPathway(state, chosenPathwayId),
  };
};

const mapDispatchToProps = {
  fetchPathway,
};

const SetScheduleFormContainer = connect(mapStateToProps, mapDispatchToProps)(SetScheduleFormView);

export default SetScheduleFormContainer;
