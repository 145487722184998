import { Map } from 'immutable';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Link, Redirect } from 'react-router-dom';
import LocationIcon from '@material-ui/icons/Room';

import theme from '../../../common/theme';
import StatusToggler from '../status_toggler';
import { useFetchOnce } from '../../../util/hooks';
import JobDetailsStyles from './job_details_styles';
import Spinner from '../../../common/components/spinner';
import UserModel from '../../../session/models/user_model';
import { IndeedLabel } from '../../../common/styles/detail';
import JobPostingModel from '../../../solve/models/job_posting_model';
import getListingImage from '../../../common/components/get_listing_image';

const propTypes = {
  canEdit: PropTypes.bool,
  isIndeed: PropTypes.bool,
  jobId: PropTypes.string.isRequired,
  closeJobPosting: PropTypes.func.isRequired,
  draftJobPosting: PropTypes.func.isRequired,
  publishJobPosting: PropTypes.func.isRequired,
  user: PropTypes.instanceOf(UserModel).isRequired,
  fetchIndeedJobPosting: PropTypes.func.isRequired,
  createExternalPublicLink: PropTypes.func.isRequired,
  job: PropTypes.oneOfType([PropTypes.instanceOf(JobPostingModel), PropTypes.instanceOf(Map)]),
};

const defaultProps = {
  job: undefined,
  canEdit: false,
  isIndeed: false,
};

// const EMPTY_LIST = fromJS([]);

const JobDetailsView = ({
  job,
  user,
  jobId,
  canEdit,
  isIndeed,
  closeJobPosting,
  draftJobPosting,
  publishJobPosting,
  fetchIndeedJobPosting,
  createExternalPublicLink,
}) => {
  const resolved = useFetchOnce(user, jobId, fetchIndeedJobPosting);

  let benefits;
  if (resolved) {
    benefits =
      job.get('opportunityTypes') &&
      job.get('opportunityTypes').filter((value) => value === 'child-care' || value === 'health-care' || value === 'transportation');
  }

  const [pLink, setPublicLink] = useState();

  const jobTitle = job?.get('jobtitle');

  useEffect(() => {
    const postPublicLink = async () => {
      const res = await createExternalPublicLink(user, {
        public_link_type: 'broadcast-external-job',
        external_job: {
          external_job_source_type: 'indeed',
          external_id: jobId,
          title: jobTitle,
        },
      });

      if (res?.entities?.publicLinks) {
        const [publicLinkObj] = Object.values(res?.entities?.publicLinks);
        setPublicLink(publicLinkObj);
      }
    };

    postPublicLink();
  }, [user, jobId, createExternalPublicLink, jobTitle]);

  if (!job) {
    if (!resolved) {
      return <Spinner />;
    }
    return <Redirect to="/dashboard" />;
  }

  const logoUrl = getListingImage(job, 'job');

  const navigateToIndeedJobDetail = () => {
    const jobUrl = job.get('url');
    if (jobUrl) {
      window.open(jobUrl, '_blank');
    }
    return false;
  };

  return (
    <JobDetailsStyles>
      &larr; <Link to="/staff/jobs">Back to Job List</Link> &nbsp;
      <p>
        <a href={pLink?.publicUrl} target="_blank" rel="noopener noreferrer">
          {pLink && pLink?.publicUrl}
        </a>
      </p>
      <div style={{ height: 20 }} />
      <div className="logo-wrapper">
        <img className="logo" src={logoUrl} alt="Job Posting Logo" />
        {isIndeed && <IndeedLabel>Job by Indeed</IndeedLabel>}
      </div>
      {canEdit && (
        <StatusToggler
          user={user}
          listingId={jobId}
          currentState={job.get('state')}
          publish={publishJobPosting}
          close={closeJobPosting}
          save={draftJobPosting}
        />
      )}
      <h1>{job.get('jobtitle')}</h1>
      <h4>{job.get('company')}</h4>
      <br />
      {/* <p style={{ color: theme.color.text.medium }}>
        {job
          .get('industries', EMPTY_LIST)
          .push(job.get('industryType'))
          .join(', ')}
      </p> */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 20 }}>
        <LocationIcon style={{ color: theme.color.positive, marginRight: 5 }} />
        <h4>
          {job.get('city')}, {job.get('state')}
        </h4>
      </div>
      <div className="url-btn-container">
        <div className="url-wrapper">
          <a href="https://www.indeed.com/" rel="nofollow noreferrer">
            jobs
          </a>{' '}
          by{' '}
          <a href="https://www.indeed.com/" rel="nofollow noreferrer" title="Job Search">
            <img src="https://www.indeed.com/p/jobsearch.gif" style={{ border: 0, verticalAlign: 'middle' }} alt="Indeed job search" />
          </a>
        </div>
        <Button variant="contained" color="primary" onClick={navigateToIndeedJobDetail}>
          View Details
        </Button>
      </div>
      {/* TODO: Other Chips? */}
      <p style={{ marginTop: 30, marginBottom: 60 }}>
        <span dangerouslySetInnerHTML={{ __html: job.get('snippet') }} />
        {/* TODO: Add "View More" if long */}
      </p>
      {benefits && benefits.size > 0 && (
        <Fragment>
          <p>
            <span className="sub-heading">Benefits:</span>
          </p>
          <ul>
            {benefits.map((benefit) => (
              <li key={benefit}>{startCase(benefit)}</li>
            ))}
          </ul>
        </Fragment>
      )}
      <center>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
          {canEdit && (
            <Link to={`/staff/jobs/${job.get('id')}/candidates`}>
              <Button variant="contained" color="secondary" size="large" style={{ backgroundColor: theme.color.action }}>
                View Applicants
              </Button>
            </Link>
          )}

          <Link to={`/staff/jobs/${job.get('jobkey')}/invite`}>
            <Button variant="contained" color="primary" size="large" style={{ backgroundColor: theme.color.action }}>
              Invite Applicants
            </Button>
          </Link>
        </div>
      </center>
    </JobDetailsStyles>
  );
};

JobDetailsView.propTypes = propTypes;
JobDetailsView.defaultProps = defaultProps;

export default JobDetailsView;
