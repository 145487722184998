import styled from 'styled-components';
import theme from '../../../common/theme';

const NotificationsSettingsStyles = styled.div`
  .notification-card {
    padding: 10px 30px;
  }
  .notification-section {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: solid 1px #c0c0c0;
  }
  .notification-section:last-child {
    border-bottom: none;
  }
  table {
    max-width: 550px;
    width: 100%;
  }
  th {
    padding: 0px 5px;
  }
  td {
    text-align: center;
  }
  td label {
    margin-right: 0px !important;
  }
  label span {
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
    font-size: 0.875rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }
  .row-header {
    text-align: left;
  }
  .question {
    padding-bottom: 5px;
  }
  .action-contained {
    margin-bottom: 50px;
  }
`;

export const Title = styled.h1`
  text-transform: uppercase;

  span {
    font-weight: bold;
  }
`;

export const SectionTitle = styled.h3`
  font-weight: bold;
  color: ${theme.color.primary};
`;

export const Divider = styled.div`
  border-bottom: solid 1px ${theme.color.primary};
`;

export const SupportText = styled.p`
  color: ${theme.color.subtleAction};
`;

export default NotificationsSettingsStyles;
